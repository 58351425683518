import HistoryItemModel from '@common/Models/HistoryItemModel';

/**
 * Map remote history item to local
 * @param {object} dtoHistoryItem
 * @returns {HistoryItemModel}
 */
export const HistoryItemResponse = dtoHistoryItem => {
  return new HistoryItemModel(dtoHistoryItem);
};

/**
 * Map remote history items to local
 * @param {object[]} dtoHistoryItems
 * @returns {HistoryItemModel[]}
 */
export const HistoryItemsResponse = dtoHistoryItems => {
  return dtoHistoryItems.map(dto => HistoryItemResponse(dto));
};
