<template>
  <modal
    v-bind="$attrs"
    :accept-callback="
      () => {
        this.anonymiseAccount();
      }
    "
    :decline-callback="() => $emit('close')"
    accept-text="Godkänn"
    height="auto"
  >
    <h5
      class="center-text sub-header"
      v-text="$t('views.settings.anonymiseAccount')"
    ></h5>
    <h5 v-text="$t('generic.metadata')"></h5>
    <p v-text="$t('information.saveMetadata')"></p>
    <p v-text="$t('views.settings.whichInformation')"></p>

    <div v-if="$profile">
      <label for="Age" class="block">
        <input type="checkbox" id="Age" v-model="anonymiseData.Age" />
        <span
          >{{ $t('generic.age') }} ({{
            profileAge > 0 ? profileAge : '?'
          }})</span
        >
      </label>

      <label for="Gender" class="block">
        <input type="checkbox" id="Gender" v-model="anonymiseData.Gender" />
        <span
          >{{ $t('generic.gender') }} ({{
            $t(`views.profile.sexes['${$profile.Gender}']`)
          }})</span
        >
      </label>

      <label for="AreaCode" class="block">
        <input type="checkbox" id="AreaCode" v-model="anonymiseData.AreaCode" />
        <span
          >{{ $t('views.profile.areacode') }} ({{
            $profile.AreaCode || '?'
          }})</span
        >
      </label>

      <label for="City" class="block">
        <input type="checkbox" id="City" v-model="anonymiseData.City" />
        <span>{{ $t('views.profile.city') }} ({{ $profile.City || '?' }})</span>
      </label>

      <label for="Region" class="block">
        <input type="checkbox" id="Region" v-model="anonymiseData.Region" />
        <span
          >{{ $t('views.profile.region') }} ({{ $profile.Region || '?' }})</span
        >
      </label>
    </div>

    <p v-text="$t('information.deleteAgree')"></p>
  </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  CURRENT_USER_ACTIONS,
  CURRENT_USER_GETTERS
} from '@/store/modules/currentuser';
import AnonymizeAccountModel from '@common/Models/AnonymizeAccountModel';
export default {
  props: {
    anonymiseFunction: {
      type: Function
    }
  },
  data() {
    return {
      anonymiseData: new AnonymizeAccountModel({})
    };
  },
  methods: {
    ...mapActions('currentuser', {
      $anonymiseAccount: CURRENT_USER_ACTIONS.ANONYMISE_ACCOUNT
    }),
    anonymiseAccount() {
      if (this.anonymiseFunction) {
        return this.anonymiseFunction(this.anonymiseData);
      }
      this.$anonymiseAccount(this.anonymiseData).then(() => {
        this.$emit('close');
        this.$router.push({ name: 'logout' });
      });
    }
  },
  computed: {
    ...mapGetters('currentuser', {
      $profile: CURRENT_USER_GETTERS.PROFILE,
      $user: CURRENT_USER_GETTERS.USER
    }),
    profileAge() {
      if (!this.$user || !this.$user.Personnummer) {
        return 0;
      }
      const x = new Date();
      return +x.getFullYear() - this.$user.Personnummer.substring(0, 4);
    }
  }
};
</script>

<style></style>
