<template>
  <component :is="divOrLink" :to="link" class="block" v-if="report">
    <info-row :image="false" :popup="showPopup">
      <template v-slot:title>
        <span>{{ $t('generic.SymptomReport') }}</span>
      </template>

      <template v-slot:images>
        <symptom-canvas
          :force-model="report.Model"
          :symptoms="report.Symptoms"
          angle="front"
          :height="100"
          :width="85"
        ></symptom-canvas>
        <symptom-canvas
          :force-model="report.Model"
          :symptoms="report.Symptoms"
          angle="back"
          :height="100"
          :width="85"
        ></symptom-canvas>
      </template>

      <template v-slot:buttons>
        <i
          data-cy="symptomreport-deletebutton"
          class="mdi mdi-trash-can-outline"
          @click.prevent.self="confirmRemove = true"
        ></i>
        <modal-confirm
          :show="confirmRemove"
          :accept-callback="() => removeReport()"
          :accept-text="$t('generic.deleteShort')"
          :show-extras="false"
          :decline-text="$t('generic.cancel')"
          @close="confirmRemove = false"
          >{{ $t('modals.confirmRemoveReport') }}</modal-confirm
        >
      </template>

      <template v-slot:date>
        {{ report.Symptoms.length }}
        {{ $tc('plural.symptom', report.Symptoms.length) }}
        &bull;
        <created-date :report="report"></created-date>
      </template>
    </info-row>
  </component>
</template>

<script>
import { mapActions } from 'vuex';
import InfoRow from '@common/Components/InfoRow/InfoRow';
import { SYMPTOMREPORT_ACTIONS } from '@/store/modules/symptomreports';
import SymptomReportModel from '@common/Models/SymptomReport/SymptomReportModel';
import SymptomCanvas from '@common/Modules/quincy/src/Quincy/components/SymptomCanvas';
import CreatedDate from '@/views/components/InfoRow/CreatedDate.vue';
import CardMixin from './mixins/CardMixin';

export default {
  mixins: [CardMixin],
  components: {
    CreatedDate,
    InfoRow,
    SymptomCanvas
  },
  props: {
    report: {
      type: SymptomReportModel,
      required: true
    }
  },
  data() {
    return {
      removeLoading: false
    };
  },
  methods: {
    ...mapActions('symptomreports', {
      $removeReport: SYMPTOMREPORT_ACTIONS.REMOVE_REPORT,
      $getMySymptomReports: SYMPTOMREPORT_ACTIONS.GET_MY_SYMPTOM_REPORTS
    }),
    removeReport() {
      this.removeLoading = true;
      this.$removeReport(this.report)
        .then(() => {
          this.$emit('delete');
        })
        .catch(error => {
          this.$getMySymptomReports();
        })
        .finally(() => {
          this.removeLoading = false;
          this.showPopup = false;
        });
    }
  },
  computed: {
    link() {
      return {
        name: 'symptomReportEdit',
        params: { symptomreportid: this.report.Id }
      };
    }
  }
};
</script>

<style></style>
