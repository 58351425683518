import { ALERT_ACTIONS, ALERT_GETTERS, ALERT_MUTATIONS } from './definitions';
import ShareModel from '@common/Models/Share/ShareModel';

class State {
  /** @type {String[]} */
  displayed_alerts = [];
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/**  @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  [ALERT_MUTATIONS.SET_DISPLAYED](state, id) {
    state.displayed_alerts.push(id);
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [ALERT_ACTIONS.$PREINIT](context) {},
  [ALERT_ACTIONS.NEW_ALERT](context, item) {
    if (
      item._modelName === 'Share' &&
      !store.state.displayed_alerts.includes(item.ShareId)
    ) {
      context.dispatch(ALERT_ACTIONS.DISPLAY_NEW_SHAREREQUEST, item);
    }
  },
  /** @param {ShareModel} share */
  [ALERT_ACTIONS.DISPLAY_NEW_SHAREREQUEST](context, share) {
    context.commit(ALERT_MUTATIONS.SET_DISPLAYED, share.ShareId);
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {};

export { ALERT_ACTIONS, ALERT_GETTERS, ALERT_MUTATIONS } from './definitions';

export default store;
