import SymptomPointModel from '@common/Models/Symptom/SymptomPointModel';
import { Vector3 } from 'three';

export class SymptomMapper {
  static mapCameraPosition(pos) {
    if (pos && typeof pos === 'string') {
      pos = SymptomMapper.mapVector3Model(JSON.parse(pos));
    }
    return pos;
  }

  static mapSymptomPointModel(point) {
    return new SymptomPointModel({
      X: !point._modelName ? point[0] / 1000.0 - 9 : point.X,
      Y: !point._modelName ? point[1] / 1000.0 - 2 : point.Y,
      Z: !point._modelName ? point[2] / 1000.0 - 2 : point.Z,
      Size: !point._modelName ? point[3] / 100.0 : point.Size
    });
  }

  static mapSymptomPointDTO(point) {
    return [
      Math.trunc((point.X + 9) * 1000),
      Math.trunc((point.Y + 2) * 1000),
      Math.trunc((point.Z + 2) * 1000),
      Math.trunc(point.Size * 100)
    ];
  }

  static mapVector3DTO(point) {
    return [
      Math.trunc((point.x + 9) * 1000),
      Math.trunc((point.y + 2) * 1000),
      Math.trunc((point.z + 2) * 1000)
    ];
  }

  static mapVector3Model(point) {
    return new Vector3(
      point[0] / 1000.0 - 9,
      point[1] / 1000.0 - 2,
      point[2] / 1000.0 - 2
    );
  }
}
