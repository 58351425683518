<template>
  <li :style="styles">
    <component
      :data-answer="answer.Id"
      :is="answerComponent"
      :index="index"
      :answer="answer"
      :answer-input="answerInput"
      :question="question"
      :is-answer="isAnswer"
      :is-not-saved-answer="isNotSavedAnswer"
      :disabled="disabled"
      :fake-answer="fakeAnswer"
      :type="answerType"
      ref="input"
      @setAnswer="setAnswer"
      @removeAnswer="removeAnswer"
    ></component>
  </li>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import CheckRadio from './QuestionTypes/CheckRadio';
import TextInput from './QuestionTypes/TextInput';
import Numbered from './QuestionTypes/Numbered';
import NumberInput from './QuestionTypes/NumberInput';
import DateInput from './QuestionTypes/DateInput';
import Dropdown from './QuestionTypes/Dropdown';
import VasSlider from './QuestionTypes/VasSlider';

export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  props: [
    'answer',
    'index',
    'answerInput',
    'question',
    'isAnswer',
    'isNotSavedAnswer',
    'disabled',
    'fakeAnswerId'
  ],
  components: {
    CheckRadio,
    TextInput,
    Numbered,
    NumberInput,
    DateInput,
    Dropdown,
    VasSlider
  },
  mounted() {
    this.v$.$touch();
  },
  methods: {
    setAnswer(answer, checked = false) {
      this.$emit('setAnswer', answer, checked);
    },
    removeAnswer(answer) {
      this.$emit('removeAnswer', answer);
    }
  },
  computed: {
    fakeAnswer() {
      return this.answer.Id === this.fakeAnswerId;
    },
    answerType() {
      return this.question.MultipleChoice ? 'checkbox' : 'radio';
    },
    answerComponent() {
      switch (this.question.QuestionType) {
        case 'date': {
          return 'DateInput';
        }
        case 'number': {
          return 'NumberInput';
        }
        case 'text': {
          return 'TextInput';
        }
        case 'dropdown':
          return 'Dropdown';
        case 'slider':
          return 'VasSlider';
        default: {
          if (this.question.Style === 'numbered') return 'Numbered';
          return 'CheckRadio';
        }
      }
    },
    styles() {
      const styles = {};

      if (this.answer.Level > 1) {
        styles.marginLeft = '50px';
      }

      return styles;
    }
  }
};
</script>

<style lang="scss" scoped></style>
