<template>
  <main-header-menu-popup-vue
    :parent="parent"
    @close="open = false"
    :open="open"
  >
    <ul>
      <li v-if="settings.modules.news.enabled">
        <router-link
          :to="{ name: 'news' }"
          id="MenuLinkNews"
          data-cy="menu-news"
        >
          <span v-text="$t('components.menuOptions.news')"></span>
          <span
            v-if="$stats.newsItemsSinceLastLogin > 0"
            class="new badge"
            :class="{ red: hasVeryImportantNews }"
            :data-badge-caption="
              $tc('plural.new', $stats.newsItemsSinceLastLogin)
            "
            >{{ $stats.newsItemsSinceLastLogin }}</span
          >
        </router-link>
      </li>
      <li v-if="settings.modules.news.enabled" class="divider"></li>
      <li v-if="settings.modules.auditlogs.enabled">
        <router-link
          :to="{ name: 'auditlogs' }"
          id="MenuLinkAuditlogs"
          data-cy="menu-auditlogs"
          >{{ $t('components.menuOptions.auditlogs') }}</router-link
        >
      </li>
      <li>
        <router-link
          :to="{ name: 'consents' }"
          id="MenuLinkConsents"
          data-cy="menu-consents"
          >{{ $t('components.menuOptions.consents') }}</router-link
        >
      </li>
      <li class="divider"></li>
      <li>
        <router-link
          :to="{ name: 'trash' }"
          id="MenuLinkTrash"
          data-cy="menu-trash"
          >{{ $t('components.menuOptions.trash') }}</router-link
        >
      </li>
      <li v-if="settings.modules.bugreport.enabled">
        <router-link
          :to="{ name: 'bugreport' }"
          id="MenuLinkBugreport"
          data-cy="menu-bugreport"
          >{{ $t('components.menuOptions.bugreport') }}</router-link
        >
      </li>
      <li class="divider"></li>
      <li v-if="settings.modules.invites.enabled">
        <router-link
          :to="{ name: 'invites' }"
          id="MenuLinkInvites"
          data-cy="menu-invites"
          >{{ $t('components.menuOptions.invites') }}</router-link
        >
      </li>
      <li>
        <router-link
          :to="{ name: 'profile' }"
          id="MenuLinkProfile"
          data-cy="menu-profile"
          >{{ $t('components.menuOptions.profile') }}</router-link
        >
      </li>
      <li v-if="settings.modules.shares.enabled">
        <router-link
          :to="{ name: 'shares' }"
          id="MenuLinkShares"
          data-cy="menu-shares"
          >{{ $t('components.menuOptions.shares') }}</router-link
        >
      </li>
      <li>
        <router-link
          :to="{ name: 'about' }"
          id="MenuLinkAbout"
          data-cy="menu-about"
          >{{ $t('components.menuOptions.about') }}</router-link
        >
      </li>
      <li>
        <a @click="goToHelp()">{{ $t('components.menuOptions.help') }}</a>
      </li>
      <li class="divider"></li>
      <li>
        <router-link
          :to="{ name: 'logout' }"
          id="MenuLinkLogout"
          data-cy="menu-logout"
          >{{ $t('components.menuOptions.logout') }}</router-link
        >
      </li>
    </ul>
  </main-header-menu-popup-vue>
</template>

<script>
import { mapGetters } from 'vuex';
import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser';
import settings from '@/../settings.json';
import MainHeaderMenuPopupVue from '@common/Components/MainHeaderMenuPopup.vue';

export default {
  components: { MainHeaderMenuPopupVue },
  props: {
    parent: String
  },
  data() {
    return {
      open: false,
      settings
    };
  },
  computed: {
    ...mapGetters('currentuser', {
      $activeGroup: CURRENT_USER_GETTERS.ACTIVE_GROUP
    })
  },
  methods: {
    goToHelp() {
      window.open(this.$activeGroup.HelpUrl, '_blank');
    }
  }
};
</script>
