<template>
  <div>
    <div class="message" v-if="shouldFollowupsBeShown">
      {{ $t('questions.doYouWantToTellMore') }}
    </div>
    <div
      v-else-if="showFollowups && !shouldFollowupsBeShown"
      class="container center-text"
    >
      {{ $t('views.statusReport.noFollowupThanks') }}
    </div>

    <div class="container">
      <span v-if="shouldFollowupsBeShown">{{
        $t('views.followup.weSuggestTheFollowing')
      }}</span>
    </div>

    <div
      class="center-text mb20"
      v-if="shouldFollowupsBeShown === true && showSkip"
    >
      <s-button
        @click="$router.replace({ name: 'start' })"
        id="ButtonLinkStartPage"
        >{{ `${$t('views.statusReport.noFollowup')} >` }}</s-button
      >
    </div>

    <div class="mb20" v-if="hasPain" data-followup="symptom">
      <router-link :to="{ name: 'symptomReport', query: { followups: true } }">
        <info-row>
          <template v-slot:image>
            <i class="mdi mdi-human"></i>
          </template>
          <template v-slot:title>
            <span
              >{{ $t('generic.draw') }}
              {{
                (!$advancedManikin
                  ? $t('generic.Symptom')
                  : $t('generic.pain')
                ).toLowerCase()
              }}</span
            >
          </template>

          <template v-slot:meta>
            <span>{{ $t('components.menuOptions.manikin') }}</span>
          </template>
        </info-row>
      </router-link>
    </div>

    <template v-if="shouldFollowupsBeShown">
      <template v-for="prom in potentialProms">
        <template v-if="!$findIncomplete(prom.Id)">
          <info-row-prom
            :prom="$findProm(prom.Id)"
            :key="prom.Id"
          ></info-row-prom>
        </template>

        <template v-else>
          <info-row-prom-report
            :key="prom.Id"
            :report="$findIncomplete(prom.Id)"
          ></info-row-prom-report>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import {
  STATUSREPORT_GETTERS,
  STATUSREPORT_MUTATIONS
} from '@/store/modules/statusreports';
import { PROM_GETTERS } from '@/store/modules/proms';
import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser';
import { PROMREPORT_GETTERS } from '@/store/modules/promreports';
import { mapGetters, mapMutations } from 'vuex';
import InfoRowProm from '@/views/components/InfoRow/InfoRowProm.vue';
import InfoRow from '@common/Components/InfoRow/InfoRow';
import InfoRowPromReport from '@/views/components/InfoRow/InfoRowPromReport.vue';

export default {
  components: {
    InfoRowProm,
    InfoRowPromReport,
    InfoRow
  },
  props: {
    showFollowups: {
      type: Boolean,
      default: true
    },
    showSkip: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    shouldFollowupsBeShown: {
      handler: function (show) {
        this.$emit('followups-available', show);
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('statusreports', {
      $clearFollowups: STATUSREPORT_MUTATIONS.CLEAR_FOLLOWUPS
    })
  },
  mounted() {},
  computed: {
    ...mapGetters('statusreports', {
      $followups: STATUSREPORT_GETTERS.FOLLOWUP_ACTIONS
    }),
    ...mapGetters('proms', {
      $findProm: PROM_GETTERS.FIND_PROM
    }),
    ...mapGetters('promreports', {
      $findIncomplete: PROMREPORT_GETTERS.FIND_INCOMPLETE_PROMREPORTS_BY_PROMID
    }),
    ...mapGetters('currentuser', {
      $advancedManikin: CURRENT_USER_GETTERS.ADVANCED_MANIKIN
    }),
    hasPain() {
      return false; // this.$followups.filter(f => f.Type === 'pain').length > 0;
    },
    potentialProms() {
      return this.$followups.filter(f => f.Type === 'prom');
    },
    shouldFollowupsBeShown() {
      return (
        (this.potentialProms.length || this.hasPain) &&
        this.showFollowups === true
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.message {
  text-align: center;
  font-size: 1.4em;
  margin-top: 50px;
}
</style>
