<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="802.000000pt"
    viewBox="0 0 802.000000 200.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path
        d="M4297 1982 c-15 -17 -17 -77 -17 -664 l0 -646 33 -64 c53 -103 137
-148 272 -147 118 0 174 35 160 99 -11 49 -40 62 -125 54 -99 -8 -144 10 -171
70 -17 38 -19 79 -19 656 0 696 4 660 -74 660 -29 0 -48 -6 -59 -18z"
      />
      <path
        d="M232 1565 c-154 -43 -232 -138 -232 -280 0 -97 15 -140 66 -195 44
-47 66 -59 276 -150 129 -55 168 -96 168 -174 0 -144 -144 -199 -343 -132 -42
14 -90 26 -106 26 -62 0 -86 -89 -34 -129 52 -42 157 -66 283 -66 111 0 118 1
183 33 223 110 238 406 27 536 -19 12 -93 46 -165 75 -164 66 -205 103 -205
183 0 122 162 175 346 113 87 -29 102 -30 132 -9 29 20 31 85 3 113 -53 53
-291 87 -399 56z"
      />
      <path
        d="M2003 1565 c-99 -18 -197 -52 -219 -76 -18 -20 -19 -49 -22 -498 -3
-472 -2 -478 18 -504 26 -33 76 -36 111 -8 l24 19 3 450 3 451 69 15 c104 24
216 21 270 -6 88 -46 84 -23 90 -500 5 -401 6 -420 24 -434 30 -22 79 -17 104
9 22 23 22 26 22 454 l0 432 58 27 c80 38 191 45 249 15 50 -25 100 -83 113
-131 6 -20 10 -194 10 -401 0 -355 1 -367 21 -393 25 -32 75 -35 110 -7 l24
19 3 357 c2 218 -1 379 -8 413 -23 125 -96 222 -210 276 -59 28 -74 31 -160
31 -102 0 -168 -17 -250 -63 l-46 -25 -44 31 c-84 59 -213 75 -367 47z"
      />
      <path
        d="M3555 1574 c-16 -2 -72 -14 -124 -25 -74 -17 -101 -28 -123 -49 l-28
-28 0 -710 c0 -667 1 -711 18 -732 22 -28 84 -30 113 -4 18 16 19 32 19 254
l0 238 63 -26 c45 -18 87 -26 153 -30 157 -8 281 46 353 154 54 82 63 128 68
345 8 316 -15 417 -120 516 -72 68 -143 95 -267 98 -52 2 -108 1 -125 -1z
m223 -159 c47 -20 78 -50 105 -100 20 -37 22 -57 25 -265 4 -254 -3 -307 -49
-363 -50 -61 -100 -81 -194 -80 -88 1 -149 19 -202 59 l-33 25 0 354 0 354 63
14 c85 21 237 21 285 2z"
      />
      <path
        d="M4563 1560 c-39 -23 -56 -74 -42 -126 32 -114 209 -83 209 37 0 33
-6 47 -34 75 -40 40 -83 45 -133 14z"
      />
      <path
        d="M5160 1570 c-154 -33 -270 -154 -299 -314 -17 -93 -14 -414 5 -496
42 -187 193 -300 399 -300 168 1 295 69 362 197 49 92 55 142 51 393 -3 202
-6 230 -25 279 -64 158 -183 238 -363 246 -47 2 -105 0 -130 -5z m203 -151
c51 -14 115 -73 140 -129 18 -38 21 -72 25 -220 5 -208 -6 -295 -45 -359 -78
-126 -298 -144 -406 -33 -61 62 -67 95 -67 346 0 212 1 223 24 272 25 55 75
101 128 121 42 15 151 16 201 2z"
      />
      <path
        d="M6103 1565 c-103 -19 -174 -44 -205 -73 l-28 -26 0 -487 0 -488 22
-15 c33 -23 81 -20 106 7 22 23 22 25 22 469 l0 446 69 16 c39 9 107 16 152
16 98 0 157 -23 189 -74 19 -32 20 -48 20 -438 0 -395 1 -406 21 -432 23 -30
72 -35 105 -12 18 14 19 33 24 453 l5 438 60 30 c53 26 70 30 146 30 77 0 90
-3 125 -27 49 -35 80 -80 93 -138 6 -26 11 -196 11 -407 l0 -362 22 -15 c33
-24 81 -20 107 8 l22 23 -3 399 -3 399 -33 67 c-99 202 -341 265 -571 148
l-59 -29 -57 34 c-93 55 -207 68 -362 40z"
      />
      <path
        d="M7603 1570 c-46 -10 -113 -41 -159 -76 -101 -78 -123 -273 -43 -384
39 -54 82 -80 238 -145 74 -30 150 -67 169 -82 63 -47 80 -133 39 -194 -62
-93 -179 -110 -337 -50 -102 39 -160 21 -160 -50 0 -42 29 -67 110 -96 65 -23
91 -27 200 -27 142 0 185 13 260 80 70 63 93 114 98 214 5 99 -11 148 -68 213
-42 48 -89 75 -219 127 -192 76 -221 101 -221 192 0 38 6 54 30 82 17 19 47
40 67 47 52 17 196 6 261 -20 66 -26 83 -26 116 0 48 38 28 106 -40 134 -91
38 -245 54 -341 35z"
      />
      <path
        d="M833 1558 c-35 -17 -46 -56 -30 -108 8 -25 89 -253 181 -508 91 -254
166 -465 166 -469 0 -3 -14 -50 -31 -105 -45 -148 -105 -205 -234 -223 -84
-11 -119 -67 -79 -124 15 -21 22 -23 98 -19 138 6 255 79 315 195 25 51 411
1270 411 1301 0 30 -43 72 -74 72 -37 0 -56 -10 -70 -39 -7 -14 -66 -203 -131
-418 -65 -216 -121 -393 -125 -393 -3 0 -70 184 -149 410 -79 225 -149 414
-156 420 -19 16 -68 20 -92 8z"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
