import ApplicationModel from '@common/Models/ApplicationModel';
import BaseService from '../BaseService';
import {
  ApplicationResponse,
  ApplicationsResponse
} from './mappers/ApplicationResponses';

class ApplicationService extends BaseService {
  /**
   * Create application -- identity
   * @param {ApplicationModel} application
   * @returns {Promise<ApplicationModel>}
   * @memberof ApplicationService
   */
  createApplication(application) {
    return this.identity('post', 'my/group/applications', application).then(
      response => ApplicationResponse(response.data)
    );
  }

  /**
   * Get applications in active user's group -- identity
   * @returns {Promise<ApplicationModel[]>}
   * @memberof ApplicationService
   */
  getApplications() {
    return this.identity('my/group/applications').then(response =>
      ApplicationsResponse(response.data)
    );
  }

  /**
   * Get application in active user's group -- identity
   * @param {string} id
   * @returns {Promise<ApplicationModel>}
   * @memberof ApplicationService
   */
  getApplication(id) {
    return this.identity(`my/group/applications/${id}`).then(response =>
      ApplicationResponse(response.data)
    );
  }

  /**
   * Create application in active user's group -- identity
   * @param {ApplicationModel} application
   * @returns {Promise<ApplicationModel>}
   * @memberof ApplicationService
   */
  updateApplication(application) {
    return this.identity('put', 'my/group/applications', application).then(
      response => ApplicationResponse(response.data)
    );
  }

  /**
   * Delete application in active user's group -- identity
   * @param {ApplicationModel} application
   * @returns {Promise<ApplicationModel>}
   * @memberof ApplicationService
   */
  deleteApplication(application) {
    return this.identity('delete', `my/group/applications/${application.Id}`);
  }
}

export default new ApplicationService();
