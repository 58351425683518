import OAuthTokenService from '@common/Services/OAuthTokens/OAuthTokenService';
import {
  OAUTHTOKEN_ACTIONS,
  OAUTHTOKEN_GETTERS,
  OAUTHTOKEN_MUTATIONS
} from './definitions';
import OAuthTokenModel from '@common/Models/OAuthTokenModel';

class State {
  constructor() {
    /** @type {OAuthTokenModel[]} */
    this.list = [];
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  /** @param {OAuthTokenModel} token */
  [OAUTHTOKEN_MUTATIONS.ADD_TOKEN](state, token) {
    const exists = state.list.find(i => i.Id === token.Id);

    if (!exists) {
      state.list.push(token);
    } else {
      Object.assign(exists, token);
    }
  },
  /** @param {OAuthTokenModel} token */
  [OAUTHTOKEN_MUTATIONS.REMOVE_TOKEN](state, token) {
    const index = state.list.findIndex(x => x.Id === token.Id);

    if (index > -1) {
      state.list.splice(index, 1);
    }
  },
  /** @param {OAuthTokenModel[]} tokens */
  [OAUTHTOKEN_MUTATIONS.SET_TOKENS](state, tokens) {
    state.list = tokens;
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  /** @param {OAuthTokenModel} token */
  [OAUTHTOKEN_ACTIONS.CREATE_TOKEN](context, token) {
    return OAuthTokenService.createToken(token).then(token =>
      context.commit(OAUTHTOKEN_MUTATIONS.ADD_TOKEN, token)
    );
  },
  /** @param {OAuthTokenModel} token */
  [OAUTHTOKEN_ACTIONS.DELETE_TOKEN](context, token) {
    OAuthTokenService.deleteToken(token).then(() =>
      context.commit(OAUTHTOKEN_MUTATIONS.REMOVE_TOKEN, token)
    );
  },
  [OAUTHTOKEN_ACTIONS.GET_TOKENS](context) {
    OAuthTokenService.getTokens().then(tokens =>
      context.commit(OAUTHTOKEN_MUTATIONS.SET_TOKENS, tokens)
    );
  },
  /** @param {OAuthTokenModel} token */
  [OAUTHTOKEN_ACTIONS.UPDATE_TOKEN](context, token) {
    return OAuthTokenService.updateToken(token).then(token => {
      context.commit(OAUTHTOKEN_MUTATIONS.ADD_TOKEN, token);
      return token;
    });
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [OAUTHTOKEN_GETTERS.TOKENS](state) {
    return state.list;
  },
  [OAUTHTOKEN_GETTERS.FIND_TOKEN]: state => tokenId => {
    return state.list.find(t => t.Id === tokenId);
  },
  [OAUTHTOKEN_GETTERS.FIND_TOKENS_BY_APPLICATION]: state => applicationId => {
    return state.list.filter(t => t.ApplicationId === applicationId);
  }
};

export {
  OAUTHTOKEN_ACTIONS,
  OAUTHTOKEN_GETTERS,
  OAUTHTOKEN_MUTATIONS
} from './definitions';

export default store;
