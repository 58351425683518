import { sortByCreatedDate } from '@common/Helpers/sorters';
import BugReportModel from '@common/Models/BugReportModel';
import BugReportService from '@common/Services/BugReports/BugReportService';
import {
  BUGREPORT_ACTIONS,
  BUGREPORT_GETTERS,
  BUGREPORT_MUTATIONS
} from './definitions';

class State {
  constructor() {
    /** @type {BugReportModel[]} */
    this.list = [];
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  /** @param {BugReportModel} bugReport */
  [BUGREPORT_MUTATIONS.ADD_BUG_REPORT](state, bugReport) {
    const exists = state.list.find(i => i.Id === bugReport.Id);
    if (!exists) {
      state.list.push(bugReport);
    } else {
      Object.assign(exists, bugReport);
    }
  },

  /** @param {BugReportModel} bugReport */
  [BUGREPORT_MUTATIONS.REMOVE_BUG_REPORT](state, bugReport) {
    const index = state.list.findIndex(x => x.Id === bugReport.Id);
    if (index > -1) {
      state.list.splice(index, 1);
    }
  },

  /** @param {BugReportModel[]} bugReports */
  [BUGREPORT_MUTATIONS.SET_BUG_REPORTS](state, bugReports) {
    state.list = bugReports;
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [BUGREPORT_ACTIONS.GET_BUG_REPORTS](context) {
    BugReportService.getBugReports().then(bugReports =>
      context.commit(BUGREPORT_MUTATIONS.SET_BUG_REPORTS, bugReports)
    );
  },
  [BUGREPORT_ACTIONS.DELETE_BUG_REPORT](context, bugReport) {
    BugReportService.deleteBugReport(bugReport).then(() =>
      context.commit(BUGREPORT_MUTATIONS.REMOVE_BUG_REPORT, bugReport)
    );
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [BUGREPORT_GETTERS.BUG_REPORTS](state) {
    return sortByCreatedDate(state.list).reverse();
  },
  [BUGREPORT_GETTERS.PROCESSED_BUG_REPORTS](state) {
    const bugReports = state.list.filter(bugreport => bugreport.Processed);
    return sortByCreatedDate(bugReports).reverse();
  },
  [BUGREPORT_GETTERS.UNPROCESSED_BUG_REPORTS](state) {
    const bugReports = state.list.filter(bugreport => !bugreport.Processed);
    return sortByCreatedDate(bugReports).reverse();
  },
  [BUGREPORT_GETTERS.FIND_BUG_REPORT]: state => bugReportId => {
    return state.list.find(b => b.Id === bugReportId);
  }
};

export {
  BUGREPORT_ACTIONS,
  BUGREPORT_GETTERS,
  BUGREPORT_MUTATIONS
} from './definitions';

export default store;
