<template>
  <card
    v-if="symptomReportSymptom"
    :remove-function="getRemoveFunction()"
    :confirm-remove="true"
    :confirm-remove-text="$t('views.statusReport.confirmRemoveSymptomText')"
  >
    <template v-if="showButtons" v-slot:extra-buttons>
      <pen-vue @click="gotoNote"></pen-vue>
      <brush-vue @click="gotoDraw"></brush-vue>
    </template>
    <template v-slot:content>
      <scale
        :index="index"
        :item="symptomReportSymptom"
        :title="symptomReportSymptom.Symptom.Name || $t('generic.Symptom')"
        @update:value="value => updateValue(value)"
        :anchor-left="$t(`scales.best.generic`)"
        :anchor-right="$t(`scales.worst.generic`)"
        :disabled="disabled"
      >
        <div
          class="canvas"
          v-if="symptomReportSymptom.hasDrawing && showDrawings"
        >
          <symptom-canvas
            :symptoms="[symptomReportSymptom]"
            angle="front"
            :model="model"
          ></symptom-canvas>
          <symptom-canvas
            :symptoms="[symptomReportSymptom]"
            angle="back"
            :model="model"
          ></symptom-canvas>
        </div>
      </scale>
    </template>
  </card>
</template>

<script>
import { mapActions } from 'vuex';
import { SYMPTOM_ACTIONS } from '@/store/modules/symptoms';
import Scale from '@views/components/Scale/CheckScale';
import SymptomReportSymptomModel from '@common/Models/SymptomReport/SymptomReportSymptomModel';
import SymptomCanvas from '@common/Modules/quincy/src/Quincy/components/SymptomCanvas';
import BrushVue from '@common/Components/SVG/Brush.vue';
import PenVue from '@common/Components/SVG/Pen.vue';

export default {
  components: {
    Scale,
    SymptomCanvas,
    BrushVue,
    PenVue
  },
  props: {
    symptomReportSymptom: {
      type: SymptomReportSymptomModel,
      required: true
    },
    statusReportId: String,
    symptomReportId: String,
    model: String,
    index: Number,
    disabled: {
      type: Boolean,
      default: false
    },
    showButtons: {
      type: Boolean,
      default: true
    },
    showDrawings: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
  mounted() {
    this.symptomReportSymptom._lastValue = this.symptomReportSymptom.Intensity; //eslint-disable-line
  },
  methods: {
    ...mapActions('symptoms', {
      $closeSymptom: SYMPTOM_ACTIONS.CLOSE_SYMPTOM
    }),
    closeSymptom(symptom) {
      if (symptom.isFromRemote) {
        this.$closeSymptom(symptom).then(() => {
          this.$emit('closed-symptom', symptom);
        });
      } else {
        this.$emit('closed-symptom', symptom);
      }
    },
    gotoNote() {
      this.$router.push({
        name: 'symptomReportNote',
        params: {
          symptomreportid: this.symptomReportId
        },
        query: {
          fromstatus: true,
          symptomid: this.symptomReportSymptom.SymptomId
        }
      });
    },
    gotoDraw() {
      this.$router.push({
        name: 'symptomReportDraw',
        params: {
          symptomreportid: this.symptomReportId
        },
        query: {
          fromstatus: true,
          symptomid: this.symptomReportSymptom.SymptomId,
          statusReportId: this.statusReportId
        }
      });
    },
    getRemoveFunction() {
      if (this.showButtons == true) {
        return () => this.closeSymptom(this.symptomReportSymptom.Symptom);
      } else if (this.showButtons == null) {
        return null;
      }
    },
    updateValue(value) {
      this.symptomReportSymptom.Intensity = value; //eslint-disable-line
      this.symptomReportSymptom._modified = true; //eslint-disable-line
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';

canvas {
  @media only screen and (max-width: $breakpoint-phone) {
    width: 90px !important;
    height: 110px !important;
  }
}
</style>
