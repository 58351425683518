import BaseService from '../BaseService';
import StatusTypeModel from '@common/Models/StatusTypeModel';

class EnvironmentService extends BaseService {
  /**
   * Get constants -- backend
   * @returns {Promise<Object>}
   * @memberof EnvironmentService
   */
  getConstants() {
    return this.backend('env/constants').then(response => response.data);
  }

  /**
   * Get status types -- backend
   * @returns {Promise<StatusTypeModel[]>}
   * @memberof EnvironmentService
   */
  getStatusTypes() {
    return this.backend('env/statustypes').then(response =>
      response.data.map(type => new StatusTypeModel(type))
    );
  }
}

export default new EnvironmentService();
