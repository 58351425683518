<template>
  <div>
    <h4 class="sub-header">
      {{ $t('generic.lastReported') }}:
      {{ $filters.DateTimeFormat($latestReport.CreatedDate) }}
    </h4>
    <div class="container" v-if="$statusReports[0]">
      <i class="mdi mdi-information pointer" @click="showInfo = true"></i>
      <status-graph></status-graph>
    </div>

    <div class="container" v-if="!everythingIsFine">
      <label>
        <input type="checkbox" class="filled-in" v-model="showAllSymptoms" />
        <span v-text="$t('views.statusReport.showAllSymptoms')"></span>
      </label>
    </div>

    <div class="container">
      <edit-lock
        :locked="true"
        :confirm="true"
        @change="
          () =>
            this.$router.push({
              name: 'statusReportEdit',
              params: { statusreportid: $latestReport.Id }
            })
        "
      ></edit-lock>
      <div
        v-if="everythingIsFine"
        v-text="$t('views.statusReport.everythingOk')"
      ></div>
      <template v-else>
        <info-row-details-status-report
          v-for="(item, index) in items"
          :key="index"
          :item="item"
          :data-cy="`info-row-status-report-type-${item.area}`"
        ></info-row-details-status-report>
      </template>
    </div>
    <float-content>
      <button-home></button-home>
      <s-button
        @click="newReport"
        :primary="true"
        id="ButtonNew"
        element-name="statusreport-newbutton"
        >{{ $t('generic.new') }}</s-button
      >
    </float-content>

    <modal-status-graph-info
      @close="showInfo = false"
      :show="showInfo"
    ></modal-status-graph-info>
  </div>
</template>

<script>
import StatusGraph from './components/StatusGraph';
import { mapGetters, mapActions } from 'vuex';
import { STATUSREPORT_GETTERS } from '@/store/modules/statusreports';
import InfoRowDetailsStatusReport from '@/views/components/InfoRow/InfoRowDetailsStatusReport.vue';
import EditLock from '@/views/components/EditLock.vue';
import ModalStatusGraphInfo from '../modals/ModalStatusGraphInfo.vue';

export default {
  components: {
    EditLock,
    StatusGraph,
    InfoRowDetailsStatusReport,
    ModalStatusGraphInfo
  },
  data() {
    return {
      showInfo: false,
      showAllSymptoms: false
    };
  },
  mounted() {},
  methods: {
    newReport() {
      this.$router.push({
        name: 'statusReport'
      });
    }
  },
  computed: {
    ...mapGetters('statusreports', {
      $statusReports: STATUSREPORT_GETTERS.STATUS_REPORTS,
      $hasStatusReport: STATUSREPORT_GETTERS.SUMMARY_GRAPH_HAS_VALUES,
      $graphData: STATUSREPORT_GETTERS.SUMMARY_GRAPH_DATA,
      $latestReport: STATUSREPORT_GETTERS.LATEST_REPORT
    }),
    items() {
      if (!this.$graphData || this.$graphData.length === 0) {
        return;
      }
      const data = this.$graphData;
      let items = [data[0], ...data.slice(1).reverse()];

      if (!this.showAllSymptoms) {
        items = items.filter(data => data.value > 0);
      }

      return items;
    },
    everythingIsFine() {
      return !this.items.filter(i => i.value > 0).length > 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-lock {
  top: -20px;
}
.mdi-square {
  font-size: 14px !important;
  margin-right: 5px;
}
.mdi-information {
  position: absolute;
  right: 20px;
}
</style>
