<template>
  <card color="white">
    <template v-slot:content>
      <div class="note-content">
        <div class="buttons">
          <pen-vue
            data-cy="note-edit-button"
            :active="editNoteToggle"
            @click="editNoteToggle = !editNoteToggle"
          ></pen-vue>
          <trash-vue
            data-cy="note-remove-button"
            @mouseenter="trashActive = true"
            @mouseleave="trashActive = false"
            @click="removeNoteToggle = true"
            :active="trashActive"
          ></trash-vue>
        </div>
        <div class="meta">
          <span class="date">{{
            $filters.CalendarFormat(note.CreatedDate)
          }}</span>
          <span class="time">{{ $filters.TimeFormat(note.CreatedDate) }}</span>
          <span class="modified">
            <modified-object-pen-vue :object="note"></modified-object-pen-vue>
          </span>
        </div>

        <div
          class="text"
          v-if="!editNoteToggle"
          v-html="toHtml(note.Content)"
        />
        <div class="text" v-else>
          <new-note-vue
            :loaded-note="note"
            :display-text="false"
            @reset-note="editNoteToggle = false"
          ></new-note-vue>
        </div>

        <modal-confirm
          :show="removeNoteToggle"
          :accept-callback="
            () => {
              removeNote();
            }
          "
          :accept-text="$t('generic.remove')"
          :show-extras="false"
          :decline-text="$t('generic.cancel')"
          @close="removeNoteToggle = false"
        >
          <template v-slot:title>
            <span v-text="$t('modals.removeNoteTitle')"></span>
          </template>
          {{ $t('modals.removeNote') }}
        </modal-confirm>
      </div>
    </template>
  </card>
</template>

<script>
import NoteModel from '@common/Models/NoteModel';
import TrashVue from '@common/Components/SVG/Trash.vue';
import PenVue from '@common/Components/SVG/Pen.vue';
import { mapGetters, mapActions } from 'vuex';
import { NOTES_ACTIONS } from '@/store/modules/notes';
import ModifiedObjectPenVue from '../ModifiedObjectPen.vue';
import NewNoteVue from '@views/Notes/components/NewNote.vue';
import { toHtml } from '@common/Helpers/markdown';

export default {
  components: {
    TrashVue,
    PenVue,
    ModifiedObjectPenVue,
    NewNoteVue
  },
  props: {
    note: {
      type: NoteModel,
      required: true
    },
    edit: Boolean
  },
  data() {
    return {
      trashActive: false,
      removeLoading: false,
      removeNoteToggle: false,
      editNoteToggle: false
    };
  },
  watch: {
    edit: {
      handler: function (edit) {
        this.editNoteToggle = edit;
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    toHtml,
    ...mapActions('notes', {
      $removeNote: NOTES_ACTIONS.REMOVE_NOTE,
      $getMyNotes: NOTES_ACTIONS.GET_MY_NOTES
    }),
    removeNote() {
      this.removeLoading = true;
      this.$removeNote(this.note)
        .catch(error => {
          this.$getMyNotes();
        })
        .finally(() => {
          this.removeLoading = false;
          this.showPopup = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';
.note-content {
  padding: 20px;
  position: relative;

  .buttons {
    position: absolute;
    right: 20px;
    top: 15px;
    svg {
      width: 35px;
      cursor: pointer;
      &:not(:last-of-type) {
        margin-right: 10px;
      }
      @media only screen and (max-width: $breakpoint-phone-small) {
        width: 30px;
        &:not(:last-of-type) {
          margin-right: 5px;
        }
      }
    }
  }

  .date {
    margin-right: 20px;
  }

  .time {
    color: $symptoms-grey;
  }

  .modified i {
    font-size: inherit;
  }

  .text {
    font-size: larger;
    margin-top: 19px;
    word-break: break-all;
    overflow-wrap: break-word;
  }
}
</style>
