import BaseService from '../BaseService';
import PromSuggestionModel from '@common/Models/PromSuggestionModel';
import { PromSuggestionsResponse } from './mappers/PromSuggestionResponses';

class PromSuggestionService extends BaseService {
  /**
   * Get prom suggestions -- backend
   * @returns {Promise<PromSuggestionModel[]>}
   * @memberof PromSuggestionService
   */
  getOwnSuggestedProms() {
    return this.backend('my/proms/suggestions').then(response =>
      PromSuggestionsResponse(response.data)
    );
  }

  /**
   * Hide prom suggestions -- backend
   * @param {String} statusReportId Id of status report
   * @param {string[]} promIds An array of prom ids
   * @returns {Promise}
   * @memberof StatusReportService
   */
  hideSuggestedProm(statusReportId, promIds) {
    return this.backend(
      'post',
      `my/statusreports/${statusReportId}/suggestions/hide`,
      promIds
    ).then(response => response.data);
  }
}

export default new PromSuggestionService();
