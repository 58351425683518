import moment from 'moment';
import 'moment/locale/sv';
import 'moment/locale/en-gb';
export const utcToLocal = timestamp => {
  return moment.utc(timestamp).utcOffset(moment().utcOffset());
};

export const getPartOfDay = () => {
  const strings = ['NOON', 'AFTERNOON', 'EVENING', 'NIGHT'];
  const hour = +moment().format('H');
  if (hour >= 6 && hour <= 12) {
    return strings[0];
  } else if (hour >= 13 && hour <= 18) {
    return strings[1];
  } else if (hour >= 19 && hour <= 24) {
    return strings[2];
  } else if (hour >= 0 && hour <= 5) {
    return strings[3];
  } else {
    console.error(
      `Could not determine time of day fron hour ${hour}, defaulting to noon`
    );
    return strings[0];
  }
};
