import LanguageService from '@common/Services/Languages/LanguageService';
import {
  LANGUAGE_ACTIONS,
  LANGUAGE_GETTERS,
  LANGUAGE_MUTATIONS
} from './definitions';
import LanguageModel from '@common/Models/LanguageModel';

class State {
  constructor() {
    /** @type {LanguageModel[]} */
    this.list = [];
    this.status = '';
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  /** @param {string[]} languages */
  [LANGUAGE_MUTATIONS.SET_LANGUAGES](state, languages) {
    state.status = 'Success';
    state.list = languages;
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [LANGUAGE_ACTIONS.$PREINIT](context) {
    context.dispatch(LANGUAGE_ACTIONS.GET_LANGUAGES);
  },

  [LANGUAGE_ACTIONS.GET_LANGUAGES](context) {
    return LanguageService.getLanguages()
      .then(languages => {
        context.commit(LANGUAGE_MUTATIONS.SET_LANGUAGES, languages);
      })
      .catch(error => console.log(error));
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [LANGUAGE_GETTERS.LANGUAGES](state) {
    return state.list;
  },
  [LANGUAGE_GETTERS.LANGUAGES_SORTED](state, getters) {
    return getters[LANGUAGE_GETTERS.LANGUAGES].slice().sort((a, b) => {
      if (a.Name < b.Name) {
        return -1;
      }
      if (a.Name > b.Name) {
        return 1;
      }
      return 0;
    });
  }
};

export {
  LANGUAGE_ACTIONS,
  LANGUAGE_GETTERS,
  LANGUAGE_MUTATIONS
} from './definitions';

export default store;
