// @ts-check

import { utcToLocal } from '@common/Helpers/time';
import NoteService from '@common/Services/Notes/NoteService';
import PromReportService from '@common/Services/PromReports/PromReportService';
import SymptomReportService from '@common/Services/SymptomReports/SymptomReportService';
import StatusReportService from '@common/Services/StatusReports/StatusReportService';

export class TrashItemModel {
  constructor({
    Id,
    ResourceType,
    ResourceDescription,
    Research,
    PurgeDate,
    PatientId
  }) {
    this.Id = Id;
    this.ResourceType = ResourceType;
    this.ResourceDescription = ResourceDescription;
    this.Research = Research;
    this.PurgeDate = utcToLocal(PurgeDate);
    this.PatientId = PatientId;
  }

  purge() {
    switch (this.ResourceType) {
      case 'PromReport': {
        return PromReportService.purgeMyPromReport(this.Id);
      }
      case 'SymptomReport': {
        return SymptomReportService.purgeMySymptomReport(this.Id);
      }
      case 'Note': {
        return NoteService.purgeMyNote(this.Id);
      }
      case 'StatusReport': {
        return StatusReportService.purgeMyStatusReport(this.Id);
      }
    }
  }

  restore() {
    switch (this.ResourceType) {
      case 'PromReport': {
        return PromReportService.restoreMyPromReport(this.Id);
      }
      case 'SymptomReport': {
        return SymptomReportService.restoreMySymptomReport(this.Id);
      }
      case 'Note': {
        return NoteService.restoreMyNote(this.Id);
      }
      case 'StatusReport': {
        return StatusReportService.restoreMyStatusReport(this.Id);
      }
    }
  }

  get icon() {
    switch (this.ResourceType) {
      case 'PromReport': {
        return 'mdi-clipboard-text';
      }
      case 'SymptomReport': {
        return 'mdi-human';
      }
      case 'Note': {
        return 'mdi-message-text';
      }
      case 'StatusReport': {
        return 'mdi-graphql';
      }
      default:
        return 'mdi-human';
    }
  }
}

export default TrashItemModel;
