import PatientModel from '@common/Models/PatientModel';
import { PromReportResponse } from '@common/Services/PromReports/mappers/PromReportResponses';
import { StatusReportResponse } from '@common/Services/StatusReports/mappers/StatusReportResponses';
import { SymptomReportResponse } from '@common/Services/SymptomReports/mappers/SymptomReportResponses';
import { sortByCreatedDate } from '@common/Helpers/sorters';
import { SymptomResponse } from '@common/Services/Symptoms/mappers/SymptomResponses';

/**
 * Transforms report from backend to one that works on frontend
 * @param {PatientModel} patient patient from backend
 * @returns {PatientModel}
 */
export const PatientResponse = patient => {
  patient = new PatientModel(patient);
  if (patient.StatusReports.length) {
    patient.StatusReports = patient.StatusReports.map(
      report => new StatusReportResponse(report)
    );
    patient.StatusReports = sortByCreatedDate(patient.StatusReports).reverse();
  }
  if (patient.PromReports.length) {
    patient.PromReports = patient.PromReports.map(
      report => new PromReportResponse(report)
    );
    patient.PromReports = sortByCreatedDate(patient.PromReports).reverse();
  }
  if (patient.SymptomReports.length) {
    patient.SymptomReports = patient.SymptomReports.map(
      report => new SymptomReportResponse(report)
    );
    patient.SymptomReports = sortByCreatedDate(
      patient.SymptomReports
    ).reverse();
  }

  if (patient.Symptoms.length) {
    patient.Symptoms = patient.Symptoms.map(symptom =>
      SymptomResponse(symptom)
    ).filter(x => x !== null);
  }

  return patient;
};
