export const APPVERSION_MUTATIONS = {
  ADD_APP_VERSION: 'ADD_APP_VERSION',
  SET_APP_VERSIONS: 'SET_APP_VERSIONS',
  REMOVE_APP_VERSION: 'REMOVE_APP_VERSION'
};

export const APPVERSION_ACTIONS = {
  GET_APP_VERSIONS: 'GET_APP_VERSIONS',
  DELETE_APP_VERSION: 'DELETE_APP_VERSION',
  CREATE_APP_VERSION: 'CREATE_APP_VERSION',
  UPDATE_APP_VERSION: 'UPDATE_APP_VERSION'
};

export const APPVERSION_GETTERS = {
  APP_VERSIONS: 'APP_VERSIONS',
  FIND_APP_VERSION: 'FIND_APP_VERSION',
  APP_VERSION_OPTIONS: 'APP_VERSION_OPTIONS'
};
