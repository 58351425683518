// @ts-check
export class BankIdCertModel {
  constructor({ notBefore, notAfter }) {
    this._modelName = 'BankIdCert';
    this.notBefore = notBefore;
    this.notAfter = notAfter;
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }
}

export default BankIdCertModel;
