const THREE = require('three');
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

class LoadingManager {
  constructor() {
    this.manager = new THREE.LoadingManager();
    this.manager.onStart = this.onStart;
    this.manager.onLoad = this.onComplete;
    this.loading = false;
  }

  loadObject(url, callback) {
    const loader = new GLTFLoader(this.manager);
    loader.load(
      url,
      object => {
        if (callback) {
          return callback(object.scene);
        }
      },
      xhr => {
        this.loading = {
          percentage: (xhr.loaded / xhr.total) * 100,
          loaded: xhr.loaded,
          total: xhr.total
        };
      },
      error => {
        console.log(error);
      }
    );
  }

  onStart(url, itemsLoaded, itemsTotal) {
    this.loading = true;
  }

  onComplete() {
    this.loading = false;
  }
}

export default new LoadingManager();
