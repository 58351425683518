import { LANGUAGE_STORAGE } from '@common/constants';
import storage from '@common/Helpers/storage';
import moment from 'moment';

export const ShortDateString = 'D/M -YY';
export const DateString = 'YYYY-MM-DD';
export const DayOfMonthString = 'D';
export const TimeString = 'HH:mm';
export const DateTimeString = `${DateString} ${TimeString}`;

export const TimeStringForFile = 'HHmm';
export const DateTimeStringForFile = `${DateString}_${TimeStringForFile}`;

/**
 * Print date including time
 * @param {moment} momentObj
 * @returns
 */
export const DayOfMonthFormat = momentObj => {
  if (isNotMomentObject(momentObj)) {
    try {
      momentObj = moment(momentObj);
    } catch (error) {
      throw 'Input is not a moment object';
    }
  }

  return momentObj.format(DayOfMonthString);
};

/**
 * Print date including time
 * @param {moment} momentObj
 * @returns
 */
export const DateTimeFormat = momentObj => {
  if (isNotMomentObject(momentObj)) {
    try {
      momentObj = moment(momentObj);
    } catch (error) {
      throw 'Input is not a moment object';
    }
  }

  return momentObj.format(DateTimeString);
};

/**
 * Print just date
 * @param {moment} momentObj
 * @returns
 */
export const ShortDateFormat = momentObj => {
  if (isNotMomentObject(momentObj)) {
    try {
      momentObj = moment(momentObj);
    } catch (error) {
      throw 'Input is not a moment object';
    }
  }

  return momentObj.format(ShortDateString);
};

/**
 * Print just date
 * @param {moment} momentObj
 * @returns
 */
export const DateFormat = momentObj => {
  if (isNotMomentObject(momentObj)) {
    try {
      momentObj = moment(momentObj);
    } catch (error) {
      throw 'Input is not a moment object';
    }
  }

  return momentObj.format(DateString);
};

/**
 * Print just time
 * @param {moment} momentObj
 * @returns
 */
export const TimeFormat = momentObj => {
  if (isNotMomentObject(momentObj)) {
    try {
      momentObj = moment(momentObj);
    } catch (error) {
      throw 'Input is not a moment object';
    }
  }

  return momentObj.format(TimeString);
};

/**
 * Print days to time from now
 * @param {moment} momentObj
 * @returns
 */
export const DaysFromNowFormat = momentObj => {
  if (isNotMomentObject(momentObj)) {
    try {
      momentObj = moment(momentObj);
    } catch (error) {
      throw 'Input is not a moment object';
    }
  }

  return momentObj.diff(moment(), 'days');
};

/**
 * Print time since in specified language
 * @param {moment} momentObj
 * @returns
 */
export const FromNowFormat = (momentObj, lang = 'sv') => {
  if (isNotMomentObject(momentObj)) {
    try {
      momentObj = moment(momentObj);
    } catch (error) {
      throw 'Input is not a moment object';
    }
  }

  const chosenLanguage = storage.get(LANGUAGE_STORAGE);
  if (chosenLanguage) {
    lang = chosenLanguage;
  }

  momentObj = momentObj.clone();

  return momentObj.locale(lang).fromNow();
};

/**
 * Print calendar time since in specified language
 * @param {moment} momentObj
 * @returns
 */
export const CalendarFormat = (momentObj, lang = 'sv') => {
  if (isNotMomentObject(momentObj)) {
    try {
      momentObj = moment(momentObj);
    } catch (error) {
      throw 'Input is not a moment object';
    }
  }
  momentObj = momentObj.clone();

  if (momentObj.isBefore(moment().subtract(1, 'd'), 'd')) {
    return momentObj.format(DateString);
  }

  const chosenLanguage = storage.get(LANGUAGE_STORAGE);
  if (chosenLanguage) {
    lang = chosenLanguage;
  }

  return momentObj.locale(lang).calendar();
};

/**
 * Print simple date in specified language
 * @param {moment} momentObj
 * @returns
 */
export const SimpleDateFormat = (momentObj, lang = 'sv') => {
  if (isNotMomentObject(momentObj)) {
    try {
      momentObj = moment(momentObj);
    } catch (error) {
      throw 'Input is not a moment object';
    }
  }

  const chosenLanguage = storage.get(LANGUAGE_STORAGE);
  if (chosenLanguage) {
    lang = chosenLanguage;
  }

  momentObj = momentObj.clone();

  return momentObj.locale(lang).format('dddd D MMM HH:mm');
};

function isNotMomentObject(moment) {
  return (
    moment === null ||
    moment === undefined ||
    !moment.hasOwnProperty('_isAMomentObject') ||
    moment._isAMomentObject === false
  );
}
