<template>
  <component :is="divOrLink" :to="link" class="block" v-if="report">
    <info-row :icon="false">
      <template v-slot:title>
        <div class="pl">
          {{ $t(`constants.SymptomTypes['${report.Type}']`) }}
        </div>
      </template>

      <template v-slot:date>
        <div class="pl">{{ $filters.DateTimeFormat(report.CreatedDate) }}</div>
      </template>

      <template v-slot:intensity>
        <div
          class="colored-background"
          :style="{ 'background-color': backgroundColor }"
        >
          <span>{{ report.Intensity }}</span>
        </div>
      </template>
    </info-row>
  </component>
</template>

<script>
import InfoRow from '@common/Components/InfoRow/InfoRow';
import SymptomReportSymptomModel from '@common/Models/SymptomReport/SymptomReportSymptomModel';
import { SYMPTOM_GETTERS } from '@/store/modules/symptoms';
import { mapGetters } from 'vuex';
import { getColorForPercentage } from '@common/Helpers/color';
import CardMixin from './mixins/CardMixin';

export default {
  mixins: [CardMixin],
  components: {
    InfoRow
  },
  props: {
    report: {
      type: SymptomReportSymptomModel,
      required: true
    }
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapGetters('symptoms', {
      $findSymptom: SYMPTOM_GETTERS.FIND_SYMPTOM
    }),
    symptom() {
      return this.$findSymptom(this.report.Id);
    },
    backgroundColor() {
      return `#${getColorForPercentage(this.report.Intensity / 10, true)}`;
    },
    link() {
      return {
        name: 'symptomReportEdit',
        params: { symptomreportid: this.report.SymptomReportId }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';
@media only screen and (max-width: $breakpoint-phone) {
  .pl {
    padding-left: 10px;
  }
}
</style>
