import BaseService from '../BaseService';
import ShareModel from '@common/Models/Share/ShareModel';
import NewShareModel from '@common/Models/Share/NewShareModel';
import { NewShareRequest } from './mappers/ShareRequests';
import { ShareResponse, SharesResponse } from './mappers/ShareResponses';

class ShareService extends BaseService {
  /**
   * Get shares -- backend
   * @returns {Promise<ShareModel[]>}
   * @memberof ShareService
   */
  getMyShares() {
    return this.backend('my/shares').then(response =>
      SharesResponse(response.data)
    );
  }

  /**
   * Get share by shareId -- backend
   * @param {String} shareId
   * @returns {Promise<ShareModel>}
   * @memberof ShareService
   */
  getMyShareByShareId(shareId) {
    return this.backend(`my/shares/${shareId}`).then(response =>
      ShareResponse(response.data)
    );
  }

  /**
   * Create new share -- backend
   * @param {ShareModel} share
   * @returns {Promise<ShareModel>}
   * @memberof ShareService
   */
  createShare(share) {
    return this.backend('post', 'my/shares', NewShareRequest(share)).then(
      response => ShareResponse(response.data)
    );
  }

  /**
   * Update share -- backend
   * @param {ShareModel} share
   * @returns {Promise<ShareModel>}
   * @memberof ShareService
   */
  updateShare(share) {
    return this.backend(
      'put',
      `my/shares/${share.ShareId}`,
      NewShareRequest(share)
    ).then(response => ShareResponse(response.data));
  }

  /**
   * Delete share -- backend
   * @param {ShareModel} share
   * @returns {Promise<>}
   * @memberof ShareService
   */
  deleteShare(share) {
    return this.backend('delete', `my/shares/${share.ShareId}`);
  }

  /**
   * Enable share -- backend
   * @param {ShareModel} share
   * @returns {Promise<ShareModel>}
   * @memberof ShareService
   */
  acceptShare(share) {
    return this.backend('post', `my/shares/${share.ShareId}/accept`).then(
      response => ShareResponse(response.data)
    );
  }

  /**
   * Enable share -- backend
   * @param {ShareModel} share
   * @returns {Promise<ShareModel>}
   * @memberof ShareService
   */
  enableShare(share) {
    return this.backend('post', `my/shares/${share.ShareId}/enable`).then(
      response => ShareResponse(response.data)
    );
  }

  /**
   * Disable share -- backend
   * @param {ShareModel} share
   * @returns {Promise<ShareModel>}
   * @memberof ShareService
   */
  disableShare(share) {
    return this.backend('post', `my/shares/${share.ShareId}/disable`).then(
      response => ShareResponse(response.data)
    );
  }

  /**
   * Create share request -- backend
   * @param {NewShareModel} newShare
   * @returns {Promise<ShareModel>}
   * @memberof ShareService
   */
  createShareRequest(newShare) {
    return this.backend('post', 'physician/shares', newShare).then(response =>
      ShareResponse(response.data)
    );
  }

  /**
   * Get shares shared with physician -- backend
   * @returns {Promise<ShareModel[]>}
   * @memberof ShareService
   */
  getPhysicianShares() {
    return this.backend('physician/shares').then(response =>
      SharesResponse(response.data)
    );
  }
}

export default new ShareService();
