import { Bus } from '@common';
import { sortByCreatedDate } from '@common/Helpers/sorters';
import InviteModel from '@common/Models/InviteModel';
import InviteService from '@common/Services/Invites/InviteService';
import {
  INVITE_ACTIONS,
  INVITE_GETTERS,
  INVITE_MUTATIONS
} from './definitions';

class State {
  constructor() {
    /** @type {InviteModel[]} */
    this.list = [];
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  [INVITE_MUTATIONS.ADD_INVITE](state, invite) {
    const exists = state.list.find(i => i.Id === invite.Id);
    if (!exists) {
      state.list.push(invite);
    } else {
      Object.assign(exists, invite);
    }
  },
  [INVITE_MUTATIONS.REMOVE_INVITE](state, invite) {
    const index = state.list.findIndex(x => x.Id === invite.Id);
    if (index > -1) {
      state.list.splice(index, 1);
    }
  },
  [INVITE_MUTATIONS.SET_ACTIVE_INVITES](state, invites) {
    state.list = invites;
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [INVITE_ACTIONS.CREATE_INVITE](context, invite) {
    return InviteService.createInvite(invite)
      .then(invite => context.commit(INVITE_MUTATIONS.ADD_INVITE, invite))
      .catch(error => {
        Bus.$emit('toast.display', {
          message: error.Message,
          status: 'failure'
        });
        throw error;
      });
  },
  /** @param {InviteModel} invite */
  [INVITE_ACTIONS.CREATE_INVITE_AS_ADMIN](context, invite) {
    return InviteService.createInviteAsAdmin(invite)
      .then(invite => context.commit(INVITE_MUTATIONS.ADD_INVITE, invite))
      .catch(error => {
        Bus.$emit('toast.display', {
          message: error.Message,
          status: 'error'
        });
        throw error;
      });
  },
  [INVITE_ACTIONS.DELETE_INVITE](context, invite) {
    return InviteService.deleteInvite(invite.Id).then(() =>
      context.commit(INVITE_MUTATIONS.REMOVE_INVITE, invite)
    );
  },
  [INVITE_ACTIONS.GET_ACTIVE_INVITES](context) {
    return InviteService.getActiveInvites().then(invites =>
      context.commit(INVITE_MUTATIONS.SET_ACTIVE_INVITES, invites)
    );
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [INVITE_GETTERS.INVITES](state) {
    return sortByCreatedDate(state.list);
  }
};

export {
  INVITE_ACTIONS,
  INVITE_GETTERS,
  INVITE_MUTATIONS
} from './definitions';

export default store;
