<template>
  <div>
    <div class="container">
      <calendar
        :reports="reports"
        @datechanged="day => setCurrentDate(day)"
      ></calendar>

      <div
        data-cy="calendar__reports"
        v-if="reportsForDay(this.currentDate).length > 0"
      >
        <h4 class="sub-header">
          {{ $t('views.calendar.activitiesForDate') }}
          {{ currentDate.format('YYYY-MM-DD') }}
        </h4>

        <component
          v-for="(report, index) in reportsForDay(this.currentDate)"
          :is="`InfoRow${report._modelName}`"
          :key="index"
          class="pointer"
          :report="report"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { sortByCreatedDate } from '@common/Helpers/sorters';
import { mapActions, mapGetters } from 'vuex';
import InfoRowPromReport from '@/views/components/InfoRow/InfoRowPromReport.vue';
import InfoRowSymptomReport from '@/views/components/InfoRow/InfoRowSymptomReport.vue';
import InfoRowStatusReport from '@/views/components/InfoRow/InfoRowStatusReport.vue';
import { PROMREPORT_GETTERS } from '@/store/modules/promreports';
import { SYMPTOMREPORT_GETTERS } from '@/store/modules/symptomreports';
import { STATUSREPORT_GETTERS } from '@/store/modules/statusreports';
import Calendar from './components/Calendar.vue';

export default {
  components: {
    Calendar,
    InfoRowPromReport,
    InfoRowSymptomReport,
    InfoRowStatusReport
  },
  data() {
    return {
      currentDate: moment()
    };
  },
  mounted() {},
  methods: {
    setCurrentDate(day) {
      this.currentDate = day;
    },
    reportsForDay(momentDate) {
      const index = momentDate.format('YYYY-MM-DD');
      return this.reports.filter(r => r.CreatedDate.isSame(momentDate, 'day'));
    }
  },
  computed: {
    ...mapGetters('promreports', {
      $promReports: PROMREPORT_GETTERS.PROM_REPORTS
    }),
    ...mapGetters('statusreports', {
      $statusReports: STATUSREPORT_GETTERS.STATUS_REPORTS
    }),
    ...mapGetters('symptomreports', {
      $symptomReports: SYMPTOMREPORT_GETTERS.SYMPTOM_REPORTS
    }),
    reports() {
      let reports = [
        ...this.$promReports,
        ...this.$symptomReports,
        ...this.$statusReports
      ];
      reports = sortByCreatedDate(reports).reverse();
      return reports;
    }
  }
};
</script>
<style lang="scss" scoped></style>
