<template>
  <div>
    <div class="container">
      <h4 class="sub-header" v-text="$t('views.invites.inviteFriend')"></h4>
      <create-invites></create-invites>
    </div>
  </div>
</template>

<script>
import CreateInvites from './components/CreateInvites.vue';

export default {
  components: {
    CreateInvites
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {}
};
</script>

<style></style>
