export const STAT_MUTATIONS = {
  SET_DASHBOARD_STATS: 'SET_DASHBOARD_STATS',
  SET_STATS: 'SET_STATS'
};

export const STAT_ACTIONS = {
  GET_DASHBOARD_STATS: 'GET_DASHBOARD_STATS',
  GET_STATS: 'GET_STATS'
};

export const STAT_GETTERS = {
  DASHBOARD_STATS: 'DASHBOARD_STATS',
  STATS: 'STATS',
  GRAPH_DATA: 'GRAPH_DATA'
};
