<template>
  <div v-if="report" class="symptom-report-grade-view container">
    <h5 class="center-text header">
      {{ $t('generic.gradeTheSymptom') }}
    </h5>
    <p class="center-text mt0">
      {{ activeSymptom.Symptom.Name }}
    </p>

    <card-container :full-width="true">
      <card-symptom-update-vue
        :symptom-report-symptom="
          this.report.Symptoms.find(
            symptom => symptom.SymptomId == this.$route.query.symptomid
          )
        "
        :show-buttons="false"
        :show-drawings="false"
      ></card-symptom-update-vue>
    </card-container>

    <float-content>
      <s-button
        @click="goBack()"
        id="ButtonCancel"
        :flat="true"
        :dark="true"
        class="underline"
        element-name="symptomreport-cancelbutton"
        >{{ $t('generic.back') }}</s-button
      >
      <div v-tippy="inactiveText">
        <s-button
          v-if="report.editable"
          @click="saveReportLater"
          :primary="true"
          id="ButtonSave"
          element-name="symptomreport-nextbutton"
          :disabled="nextInactive()"
          >{{ $t('generic.next') }}</s-button
        >
      </div>
    </float-content>

    <modal-confirm
      :show="confirmCancel"
      :accept-callback="
        () => {
          confirmedCancel = true;
          cancelReport();
        }
      "
      :accept-text="$t('generic.leave')"
      :show-extras="false"
      :decline-text="$t('generic.no')"
      @close="confirmCancel = false"
      >{{ $t('modals.confirmLeaveReport') }}</modal-confirm
    >
  </div>
</template>

<script>
import CardSymptomUpdateVue from '@/views/components/Card/CardSymptomUpdateNew.vue';
import { mapGetters, mapMutations } from 'vuex';
import SymptomReportModel from '@common/Models/SymptomReport/SymptomReportModel';
import {
  SYMPTOMREPORT_MUTATIONS,
  SYMPTOMREPORT_GETTERS
} from '@/store/modules/symptomreports';

export default {
  components: {
    CardSymptomUpdateVue
  },
  data() {
    return {
      report: null,
      confirmCancel: false,
      confirmedCancel: false,
      inactiveText: null
    };
  },
  beforeRouteLeave(to, from, next) {
    if (!this.report) {
      next();
      return;
    }

    const keepUnsavedReport = [
      'symptomReport',
      'symptomReportEdit',
      'symptomReportDraw',
      'symptomReportGrade',
      'symptomReportNote',
      'symptomSelect'
    ].includes(to.name);

    if (
      !keepUnsavedReport &&
      this.report?.hasModifiedSymptoms &&
      this.confirmCancel === false &&
      this.confirmedCancel === false
    ) {
      this.confirmCancel = true;
      this.redirectOnCancel = to;
      next(false);
    } else {
      this.$bus.$emit('toggleHeader', true);

      if (!keepUnsavedReport) {
        this.$removeUnsavedReport();
      }

      next();
    }
  },
  mounted() {
    this.loadSymptomReport();
  },
  methods: {
    ...mapMutations('symptomreports', {
      $setUnsavedReport: SYMPTOMREPORT_MUTATIONS.SET_UNSAVED_REPORT,
      $removeUnsavedReport: SYMPTOMREPORT_MUTATIONS.REMOVE_UNSAVED_REPORT
    }),
    cancelReport() {
      this.$removeUnsavedReport();

      if (this.redirectOnCancel) {
        this.$router.push(this.redirectOnCancel.path);
      } else {
        this.$router.replace({ name: 'start' }).catch(() => {});
      }
    },
    loadSymptomReport() {
      if (this.$unsavedReport) {
        this.report = new SymptomReportModel(this.$unsavedReport.__copy());
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    saveReportLater() {
      this.$setUnsavedReport(this.report);

      if (this.report.isFromRemote) {
        this.$router.replace({
          name: 'symptomReportEdit',
          params: { symptomreportid: this.report.Id }
        });
      } else {
        this.$router.replace({
          name: 'symptomReport',
          query: { newSymptom: this.$route.query.newSymptom }
        });
      }
    },
    nextInactive() {
      if (this.activeSymptom.Intensity == null) {
        this.inactiveText = this.$t('generic.theSymptomHasNotBeenGraded');
        return true;
      }
      this.inactiveText = null;
      return false;
    }
  },

  computed: {
    ...mapGetters('symptomreports', {
      $unsavedReport: SYMPTOMREPORT_GETTERS.UNSAVED_REPORT
    }),
    activeSymptom() {
      const symptom = this.report.Symptoms.find(
        symptom => symptom.SymptomId == this.$route.query.symptomid
      );
      return symptom;
    }
  }
};
</script>

<style lang="scss">
.symptom-report-grade-view {
  position: relative;
  margin-top: -20px;
}
</style>
