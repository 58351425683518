export const OAUTHTOKEN_MUTATIONS = {
  ADD_TOKEN: 'ADD_TOKEN',
  SET_TOKENS: 'SET_TOKENS',
  REMOVE_TOKEN: 'REMOVE_TOKEN'
};

export const OAUTHTOKEN_ACTIONS = {
  CREATE_TOKEN: 'CREATE_TOKEN',
  DELETE_TOKEN: 'DELETE_TOKEN',
  GET_TOKENS: 'GET_TOKENS',
  UPDATE_TOKEN: 'UPDATE_TOKEN'
};

export const OAUTHTOKEN_GETTERS = {
  TOKENS: 'TOKENS',
  FIND_TOKEN: 'FIND_TOKEN',
  FIND_TOKENS_BY_APPLICATION: 'FIND_TOKENS_BY_APPLICATION'
};
