<template>
  <div class="container">
    <h4 class="header pb20">
      {{ $t('views.about.title') }}
    </h4>
    <p>
      <b> {{ $t('views.about.contact') }} </b><br />
      <a href="mailto:info@symptoms.se">info@symptoms.se</a><br />
      <br />
      <b> {{ $t('views.about.address') }} </b><br />
      Symptoms Europe AB<br />
      Vaksalagatan 2<br />
      753 20 Uppsala<br />
      <br />
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';
p a {
  text-decoration: underline;
  color: $symptoms-blue;
}
</style>
