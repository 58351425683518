<template>
  <div v-if="reminder" class="container">
    <form>
      <p>
        {{ $t('views.reminder.createReminderForYourCalendar') }}
      </p>

      <div class="input-field col s12">
        <input
          type="text"
          :value="reminder.ReminderDate"
          class="datepicker"
          id="reminderdate"
          @change="setReminderDate"
          readonly
        />
        <errors :field="v$.reminder.ReminderDate" />
      </div>

      <div class="input-field col s12">
        <input
          type="text"
          :value="reminder.ReminderTime"
          class="timepicker"
          id="reminderdatetime"
          @change="setReminderTime"
          readonly
        />
        <errors :field="v$.reminder.ReminderTime" />
      </div>

      <div>
        <label for="isrecurring" class="block">
          <input
            type="checkbox"
            name="isrecurring"
            id="isrecurring"
            v-model="reminder.IsRecurring"
            :checked="true"
          />
          <span>{{ $t('views.reminder.recurringEveryWeek') }}</span>
        </label>
        <errors :field="v$.reminder.IsRecurring" />
      </div>

      <div class="mt20">
        <s-button :primary="true" @click="createReminder()">{{
          $t('generic.save')
        }}</s-button>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { randomString } from '@common/Helpers/strings';
import FormSetup from '@common/Mixins/FormSetup';
import ReminderModel from '@common/Models/ReminderModel';

export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  props: {},
  mixins: [FormSetup],
  data() {
    return {
      reminder: new ReminderModel({})
    };
  },
  validations: {
    reminder: ReminderModel.validations
  },
  mounted() {},
  methods: {
    setReminderDate(event) {
      this.reminder.ReminderDate = event.target.value;
    },
    setReminderTime(event) {
      this.reminder.ReminderTime = event.target.value;
    },
    createReminder() {
      const startTime = moment(this.reminder.ReminderDateTime)
        .utc()
        .format('YYYYMMDDTHHmmss');

      const endTime = moment(this.reminder.ReminderDateTime)
        .add(5, 'minutes')
        .utc()
        .format('YYYYMMDDTHHmmss');

      const now = moment().format('YYYYMMDDTHHmmss');
      const aYearFromNow = moment()
        .utc()
        .add(1, 'year')
        .format('YYYYMMDDTHHmmss');

      // Note: this format seems very brittle when it comes to unwanted newlines etc and the
      // explicit newlines are required by the ICS specification...
      var calendarItem =
        `BEGIN:VCALENDAR\n` +
        `VERSION:2.0\n` +
        `PRODID:-//Symptoms Europe AB//NONSGML Symptoms App//EN\n` +
        `BEGIN:VEVENT\n` +
        `UID:${randomString()}\n` +
        `DTSTAMP:${now}\n` +
        `ATTENDEE;CN=;RSVP=TRUE:MAILTO:\n` +
        `ORGANIZER;CN=Symptoms:MAILTO:noreply@symptoms.se\n` +
        `DTSTART:${startTime}Z\n` +
        `DTEND:${endTime}Z\n` +
        (this.reminder.IsRecurring
          ? `RRULE:FREQ=WEEKLY;UNTIL=${aYearFromNow}Z`
          : '') +
        `LOCATION:\n` +
        `SUMMARY:Symptoms: ${this.$t('views.reminder.reporting')}\n` +
        `DESCRIPTION:${this.$t('views.reminder.timeToReportSymptoms', {
          url: this.$activeGroup.LandingPageUrl
        })}\n` +
        `BEGIN:VALARM\n` +
        `TRIGGER:-PT5M\n` +
        `ACTION:DISPLAY\n` +
        `DESCRIPTION:Reminder\n` +
        `END:VALARM\n` +
        `END:VEVENT\n` +
        `END:VCALENDAR`;

      const blob = new Blob([calendarItem], {
        type: 'text/calendar'
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Symptoms_Reminder.ics';
      link.click();
    }
  },
  computed: {
    ...mapGetters('currentuser', {
      $activeGroup: CURRENT_USER_GETTERS.ACTIVE_GROUP
    })
  }
};
</script>
<style lang="scss" scoped>
// This is to get rid of the clock icon appended to the time input
input[type='time']::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}
</style>
