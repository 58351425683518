import ShareModel from '@common/Models/Share/ShareModel';

/**
 * Transforms Share to backend dto
 * @param {ShareModel} share
 * @returns {Object}
 */
export const NewShareRequest = share => {
  return share;
};
