import { sortByIndex } from '@common/Helpers/sorters';
import { randomString } from '@common/Helpers/strings';
import { required } from '@vuelidate/validators';
import AnswerModel from './AnswerModel';
import ConditionalModel from './ConditionalModel';

// @ts-check
export class QuestionModel {
  constructor({
    Id = null,
    SequenceId,
    Header = '',
    Text = '',
    Index,
    Level = 1,
    LineBreak = false,
    ShowLastInfo = false,
    PromId,
    Answers = [],
    Conditionals = [],
    AnswerType = 'answer',
    QuestionType = 'radiobtn',
    Style,
    ExportKey = '',
    Version = 0,
    Required = false,
    AnswerCountErrorText = null,
    MinAnswerCount = null,
    MinValue = null,
    MaxValue = null,
    Image = null
  }) {
    this._modelName = 'Question';
    this._hidden = false;
    this.Id = Id || null;
    this.SequenceId = SequenceId;
    this.Header = Header;
    this.Text = Text;
    this.Index = Index;
    this.Level = Level;
    this.LineBreak = LineBreak || false;
    this.ShowLastInfo = ShowLastInfo;
    this.PromId = PromId;
    this.Answers = Answers.map(a => new AnswerModel(a)).sort(sortByIndex);
    this.Conditionals = Conditionals.map(c => new ConditionalModel(c));
    this.AnswerType = AnswerType;
    this.QuestionType = QuestionType;
    this.Style = Style;
    this.ExportKey = ExportKey;
    this.Version = Version || 0;

    this.Required = Required;
    this.AnswerCountErrorText = AnswerCountErrorText;
    this.MinAnswerCount = MinAnswerCount;
    this.MinValue = MinValue;
    this.MaxValue = MaxValue;
    this.Image = Image;

    this._sortableKey = randomString();
  }

  get MultipleChoice() {
    return this.QuestionType === 'chbox';
  }

  get specialAnswerId() {
    const useAnswerIds = ['answer'];
    if (useAnswerIds.indexOf(this.AnswerType) === -1) {
      return this.AnswerType;
    }
    return false;
  }

  static get validations() {
    return {
      SequenceId: {
        required
      },
      Id: {
        required
      },
      ExportKey: {
        required
      },
      Index: {
        required
      },
      Header: {
        required
      },
      ShowLastInfo: {},
      Required: {},
      AnswerCountErrorText: {},
      MinAnswerCount: {},
      MinValue: {},
      MaxValue: {},
      Text: {},
      QuestionType: {},
      Image: {}
    };
  }
}

export default QuestionModel;
