import tippy from 'tippy.js';
import config from '../config';

/** @type {import('vue/types/umd').DirectiveOptions} */
export const TippyDirective = {
  mounted(el, binding, vnode) {
    setTippy(binding, el);
  },
  updated(el, binding) {
    removeTippy(el);
    setTippy(binding, el);
  },
  unmounted(el) {
    removeTippy(el);
  }
};

function removeTippy(el) {
  if (el && el._tippy) {
    el._tippy.destroy();
  }
}

function setTippy(binding, el) {
  let options = {
    theme: 'light',
    allowHTML: true
  };

  if (config.tippyDefaults) {
    options = Object.assign(options, config.tippyDefaults);
  }

  if (typeof binding.value === 'string') {
    options.content = binding.value;
  } else {
    const content = el.querySelector('[tippy-content]');
    if (content) {
      options.content = content.innerHTML;
    }
    options = Object.assign(options, binding.value);
  }

  if (!options.content) {
    return;
  }

  tippy(el, options);
}
