import storage from '@common/Helpers/storage';
import moment from 'moment';
import TokenModel from '@common/Models/TokenModel';

/**
 * Checks if user has stored auth details in localstorage
 * @returns {boolean}
 */
export const isAuthed = () => {
  const storedAuth = getStoredAuthorization();
  if (!storedAuth || moment().isAfter(storedAuth.expires)) {
    return false;
  }
  return true;
};

/**
 * Get stored auth details
 * @returns {TokenModel|null} token or null
 */
export const getStoredAuthorization = () => {
  const auth = storage.get('auth') || {};

  if (!auth.access_token) {
    return null;
  } else {
    return new TokenModel(auth);
  }
};

/**
 * Get stored active role
 * @returns {String} token or null
 */
export const getActiveRole = () => {
  return storage.get('activeRole');
};
