<template>
  <div
    class="popup"
    :class="{ open: open }"
    :style="{ 'min-width': minWidth }"
    data-cy="header-menu"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    open: Boolean,
    minWidth: {
      type: String,
      default: '200px'
    },
    parent: String
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';

.popup {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #fff;
  display: none;
  box-shadow: 0 20px 25px 0 rgba(73, 73, 73, 0.1);
  color: #000;
  border-radius: 10px;
  z-index: 999999;

  @media only screen and (min-width: $breakpoint-desktop) {
    right: 17px;
  }

  :slotted(ul) {
    margin: 0;
    li {
      &:first-of-type {
        a,
        .item {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
      &:last-of-type {
        a,
        .item {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
      a,
      .item {
        padding: 10px 20px;
        display: block;
        &.router-link-exact-active {
          background-color: rgb(53, 53, 53);
          color: #fff;
          &:hover {
            background-color: rgb(53, 53, 53);
          }
        }
        &:hover {
          background-color: rgb(231, 231, 231);
        }
        &.active {
          color: #fff;
          background-color: rgb(29, 29, 29);
        }
      }
      &.divider {
        border-bottom: 1px solid #ccc;
      }
    }
  }
  &.open {
    display: block;
  }
}
</style>
