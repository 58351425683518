// @ts-check
export class ConditionalModel {
  constructor({
    Id,
    ComparisonOperator,
    CompareQuestionId,
    CompareAnswerId,
    RedirectQuestionId
  }) {
    this._modelName = 'Conditional';
    this.Id = Id;
    this.ComparisonOperator = ComparisonOperator || '';
    this.CompareQuestionId = CompareQuestionId || '';
    this.CompareAnswerId = CompareAnswerId || '';
    this.RedirectQuestionId = RedirectQuestionId || '';
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }
}

export default ConditionalModel;
