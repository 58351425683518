export class PusherPayloadModel {
  constructor({ Channel, UserId, PatientId, GroupPusherId, EventName, Data }) {
    /** @type {('User'|'Group'|'Patient')} */
    this.Channel = Channel;
    this.UserId = UserId;
    this.PatientId = PatientId;
    this.GroupPusherId = GroupPusherId;
    this.EventName = EventName;
    this.Data = Data;
  }
}

export default PusherPayloadModel;
