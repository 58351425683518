// @ts-check
import { isFromRemote } from '@common/Helpers/api';
import { utcToLocal } from '@common/Helpers/time';
import { PromReportResponse } from '@common/Services/PromReports/mappers/PromReportResponses';
import moment from 'moment';
import AnsweredQuestionModel from './AnsweredQuestionModel';
import AnswerModel from './AnswerModel';
import QuestionModel from './QuestionModel';

export class PromReportModel {
  constructor({
    Id,
    PromId,
    PatientId,
    Note,
    CreatedDate,
    CompletedDate,
    ModifiedDate,
    Answers,
    AnswerCount,
    QuestionCount,
    LanguageId,
    saved,
    Version,
    Score,
    NormalizedScore
  }) {
    this._modelName = 'PromReport';
    this._icon = 'mdi-clipboard-text';
    this.Id = Id || null;
    this.PromId = PromId;
    this.PatientId = PatientId;
    this.Note = Note || null;
    this.CreatedDate = CreatedDate ? utcToLocal(CreatedDate) : moment();
    this.CompletedDate = CompletedDate ? utcToLocal(CompletedDate) : null;
    this.ModifiedDate = ModifiedDate
      ? utcToLocal(ModifiedDate)
      : this.CreatedDate;
    this.saved = saved || false;
    // /** @type {AnsweredQuestionModel[]} */
    this.Answers = Answers || [];
    this.AnswerCount = AnswerCount || 0;
    this.QuestionCount = QuestionCount || 0;
    this.LanguageId = LanguageId;
    this.Version = Version || 0;
    this.Score = Score || 0;
    this.NormalizedScore = NormalizedScore || 0;

    this._locked = false;
  }

  /**
   *
   * @param {QuestionModel} question
   * @param {AnswerModel} answer
   * @param {Boolean} checked
   * @param {Number} time
   * @param {Boolean?} notApplicable
   */
  addAnswer(question, answer, checked, time, notApplicable = false) {
    const isAnsweredPreviously = this.Answers.findIndex(
      a => a.QuestionId === question.Id
    );
    let previousValue = null;
    let newAnswer = null;

    if (isAnsweredPreviously > -1) {
      if (!notApplicable) {
        previousValue = this.Answers[isAnsweredPreviously].AnswerIds.indexOf(
          answer.Id
        );
      }
    } else {
      newAnswer = new AnsweredQuestionModel({
        QuestionId: question.Id,
        AnswerIds: [],
        Milliseconds: time,
        saved: false,
        NotApplicable: notApplicable
      });
    }

    if (question.MultipleChoice) {
      if (checked === true) {
        if (newAnswer) {
          newAnswer.AnswerIds.push(answer.Id);
        } else {
          this.Answers[isAnsweredPreviously].AnswerIds.push(answer.Id);
        }
      } else if (
        isAnsweredPreviously > -1 &&
        previousValue &&
        checked === false
      ) {
        this.Answers[isAnsweredPreviously].AnswerIds.splice(previousValue, 1);
      }

      if (
        isAnsweredPreviously > -1 &&
        this.Answers[isAnsweredPreviously].AnswerIds.length === 0
      ) {
        this.Answers.splice(isAnsweredPreviously, 1);
      }

      if (newAnswer) {
        this.Answers.push(newAnswer);
      }
      return;
    }

    if (
      isAnsweredPreviously &&
      answer !== null &&
      question.MultipleChoice === true
    ) {
      if (previousValue > -1 && checked === false) {
        isAnsweredPreviously.AnswerIds.splice(previousValue, 1);
      } else {
        isAnsweredPreviously.AnswerIds.push(answer.Id);
      }
    } else {
      const answerid = question.specialAnswerId
        ? question.specialAnswerId
        : answer
          ? answer.Id
          : '';

      newAnswer = new AnsweredQuestionModel({
        QuestionId: question.Id,
        AnswerIds: [answerid],
        Milliseconds: time,
        saved: false,
        NotApplicable: notApplicable
      });

      if (question.AnswerType === 'string') {
        newAnswer.Value = answer?.Value || '';
      } else if (question.AnswerType === 'integer') {
        newAnswer.Value = Number(answer?.Value);
      }

      if (isAnsweredPreviously > -1) {
        this.Answers.splice(isAnsweredPreviously, 1, newAnswer);
      } else {
        this.Answers.push(newAnswer);
      }
    }
  }

  prefillAnswersFromReport(report) {
    report.Answers.forEach(a => {
      this.prefillAnswer(a);
    });
  }

  prefillAnswer(oldAnswer) {
    const idx = this.Answers.findIndex(
      x => x.QuestionId === oldAnswer.QuestionId
    );
    const newAnswer = new AnsweredQuestionModel({
      QuestionId: oldAnswer.QuestionId,
      AnswerIds: oldAnswer.AnswerIds,
      Milliseconds: 0,
      Value: oldAnswer.Value,
      saved: false,
      NotApplicable: oldAnswer.NotApplicable
    });

    if (idx === -1) {
      this.Answers.push(newAnswer);
    } else {
      this.Answers.splice(idx, 1, newAnswer);
    }
  }

  __copy() {
    return PromReportResponse(JSON.parse(JSON.stringify(this)));
  }

  get answeredQuestionsCount() {
    return this.AnswerCount || this.Answers.length;
  }

  get percentCompleted() {
    return Math.round((this.answeredQuestionsCount / this.QuestionCount) * 100);
  }

  get isCompleted() {
    return this.percentCompleted === 100;
  }

  get isRecent() {
    return this.ModifiedDate.isAfter(moment().subtract(30, 'day'));
  }

  get needFullReport() {
    return this.AnswerCount > 0 && this.Answers.length < this.AnswerCount;
  }

  get isFromRemote() {
    return isFromRemote(this);
  }
}

export default PromReportModel;
