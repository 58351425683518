<template>
  <div class="container" v-if="$promrequests.length">
    <h4 class="sub-header" v-text="$t('views.start.requestedProms')"></h4>
    <template v-for="(request, index) in $promrequests">
      <info-row-prom
        :key="request.PromId + index"
        v-if="$findProm(request.PromId)"
        :prom="$findProm(request.PromId)"
        :use-active="true"
      ></info-row-prom>
    </template>
  </div>
</template>

<script>
import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser';
import { PROM_GETTERS } from '@/store/modules/proms';
import { mapGetters } from 'vuex';
import PromLink from '@views/PromReport/components/PromLink';
import InfoRowProm from '@/views/components/InfoRow/InfoRowProm.vue';

export default {
  components: { InfoRowProm },
  computed: {
    ...mapGetters('currentuser', {
      $promrequests: CURRENT_USER_GETTERS.INCOMPLETE_PROMREQUESTS
    }),
    ...mapGetters('proms', {
      $findProm: PROM_GETTERS.FIND_PROM
    })
  }
};
</script>
