import LanguageModel from '@common/Models/LanguageModel';

/**
 * Map remote Language to local
 * @param {object} dtoLanguage
 * @returns {LanguageModel}
 */
export const LanguageResponse = dtoLanguage => {
  return new LanguageModel(dtoLanguage);
};

/**
 * Map remote Languages to local
 * @param {object[]} dtoLanguages
 * @returns {LanguageModel[]}
 */
export const LanguagesResponse = dtoLanguages => {
  return dtoLanguages.map(dto => LanguageResponse(dto));
};
