import {
  PatientModel,
  SymptomModel,
  PromReportModel
} from '@common/Models/PromReportModel';
import {
  PATIENT_ACTIVE_ACTIONS,
  PATIENT_ACTIVE_GETTERS,
  PATIENT_ACTIVE_MUTATIONS
} from './definitions';
import PusherService from '@common/Services/PusherService';
import PusherPayloadModel from '@common/Models/Pusher/PusherPayloadModel';
import { sortByCreatedDate } from '@common/Helpers/sorters';
import { StatusReportAreaColors } from '@/constants';
import { STATUSREPORT_GETTERS } from '../../statusreports';
import moment from 'moment';

class State {
  constructor() {
    /** @type {PatientModel} */
    this.patient = null;
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  /** @param {PatientModel} patient */
  [PATIENT_ACTIVE_MUTATIONS.SET_ACTIVE_PATIENT](state, patient) {
    state.patient = patient;
  },
  [PATIENT_ACTIVE_MUTATIONS.PUSHER_CONNECTED_PATIENT](state) {},
  [PATIENT_ACTIVE_MUTATIONS.PUSHER_DISCONNECTED_PATIENT](state) {}
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [PATIENT_ACTIVE_ACTIONS.INIT_PUSHER_PATIENT](context, patientId) {
    PusherService.subscribe(patientId, () => {
      context.commit(
        PATIENT_ACTIVE_MUTATIONS.PUSHER_CONNECTED_PATIENT,
        new PusherPayloadModel({
          Channel: 'Patient',
          PatientId: patientId
        })
      );
    });
  },
  [PATIENT_ACTIVE_ACTIONS.DISCONNECT_PUSHER](context, patientId) {
    PusherService.unsubscribe(patientId, () => {
      context.commit(PATIENT_ACTIVE_MUTATIONS.PUSHER_DISCONNECTED_PATIENT);
    });
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [PATIENT_ACTIVE_GETTERS.PATIENT](state, getters) {
    return state.patient;
  },
  /**
   * Get status item graph data
   * @returns {Object[]}
   */
  [PATIENT_ACTIVE_GETTERS.STATUS_AREA_GRAPH]:
    (state, getters, rootState, rootGetters) => area => {
      const graphData = [];
      const list = state.patient.StatusReports;
      const invertedTypes =
        rootGetters[`statusreports/${STATUSREPORT_GETTERS.INVERTED_TYPES}`];

      for (let i = 0, n = list.length; i < n; i++) {
        const report = list[i];
        const item = report.Items.find(item => area === item.Type);

        if (!item) {
          continue;
        }

        graphData.push({
          value:
            invertedTypes.indexOf(item.Type) === -1
              ? item.Intensity
              : 10 - item.Intensity,
          date: item.CreatedDate.toDate(),
          moment: item.CreatedDate,
          color: StatusReportAreaColors[item.Type]
        });
      }
      return sortByCreatedDate(graphData);
    },
  /**
   * Get symptom graph data
   * @returns {Object[]}
   */
  [PATIENT_ACTIVE_GETTERS.SYMPTOM_GRAPH]:
    (state, getters, rootState, rootGetters) => reports => {
      if (!reports) {
        return [];
      }
      return reports.map(report => {
        return {
          date: report.CreatedDate,
          moment: report.CreatedDate,
          value: +report.Intensity
        };
      });
    },
  /**
   * Find active patient symptom
   * @returns {SymptomModel[]}
   */
  [PATIENT_ACTIVE_GETTERS.FIND_SYMPTOM]: (state, getters) => symptomId => {
    const symptom = state.patient
      ? state.patient.Symptoms.find(x => x.Id === symptomId)
      : null;
    console.log(symptom, symptomId, state.patient.Symptoms);
    return symptom;
  },
  /**
   * Find active patient symptom
   * @returns {SymptomModel[]}
   */ [PATIENT_ACTIVE_GETTERS.FIND_SYMPTOM_REPORT]:
    (state, getters) => reportId => {
      return state.patient
        ? state.patient.SymptomReports.find(x => x.Id === reportId)
        : null;
    },
  /**
   * Find active patient symptomreports for symptom
   * @returns {SymptomModel[]}
   */
  [PATIENT_ACTIVE_GETTERS.FIND_SYMPTOMREPORTS_FOR_SYMPTOM]:
    (state, getters) => symptomId => {
      const reports = state.patient.SymptomReports.map(x =>
        x.Symptoms.find(y => y.Id === symptomId)
      ).filter(x => x !== undefined);
      return reports;
    },
  /**
   * Find proms by PROM id
   * @returns {PromReportModel[]}
   */
  [PATIENT_ACTIVE_GETTERS.FIND_PROMREPORTS_BY_PROMID]:
    (state, getters) => promid => {
      return state.patient.PromReports.filter(
        report => report.PromId === promid
      );
    },
  /**
   * Find prom by id
   * @returns {PromReportModel}
   */
  [PATIENT_ACTIVE_GETTERS.FIND_PROMREPORT]: (state, getters) => reportid => {
    return state.patient.PromReports.find(report => report.Id === reportid);
  },
  /**
   * Get prom graph
   * @returns {Object[]}
   */
  [PATIENT_ACTIVE_GETTERS.PROM_GRAPH]: (state, getters) => promid => {
    return getters[PATIENT_ACTIVE_GETTERS.FIND_PROMREPORTS_BY_PROMID](promid)
      .filter(report => report.isCompleted)
      .map(report => {
        return {
          date: report.ModifiedDate.toDate(),
          moment: report.ModifiedDate,
          value: +report.NormalizedScore
        };
      });
  }
};

export {
  PATIENT_ACTIVE_ACTIONS,
  PATIENT_ACTIVE_GETTERS,
  PATIENT_ACTIVE_MUTATIONS
} from './definitions';

export default store;
