import { isFromRemote } from '@common/Helpers/api';
import { SymptomMapper } from '@common/Models/Symptom/mappers/SymptomMapper';
import SymptomReportModel from '@common/Models/SymptomReport/SymptomReportModel';

/**
 * Maps SymptomReport to backend dto
 * @param {SymptomReportModel} report
 * @returns {object}
 */
export const SymptomReportRequest = report => {
  const newReport = JSON.parse(JSON.stringify(report));
  delete newReport.Id;

  newReport.Symptoms.forEach(symptomReportSymptom => {
    if (!isFromRemote(symptomReportSymptom)) {
      delete symptomReportSymptom.SymptomId;
      delete symptomReportSymptom.Symptom.Id;
    }

    symptomReportSymptom.SymptomPoints = symptomReportSymptom.SymptomPoints.map(
      sp => SymptomMapper.mapSymptomPointDTO(sp)
    );

    if (symptomReportSymptom.CameraPosition) {
      symptomReportSymptom.CameraPosition = JSON.stringify(
        SymptomMapper.mapVector3DTO(symptomReportSymptom.CameraPosition)
      );
    }
  });

  return newReport;
};
