<template>
  <div class="tos-container container">
    <div class="tos" v-if="contract">
      <h4 v-text="contract.Title"></h4>
      <ul v-for="(section, sindex) in contract.Sections" :key="`s${sindex}`">
        <li
          v-for="(paragraph, pindex) in section.Paragraphs"
          :key="`p${pindex}}`"
        >
          {{ paragraph.Text }}
        </li>
      </ul>
      <div ref="bottom"></div>
    </div>
    <div class="buttons">
      <button
        @click="agree"
        id="ButtonAcceptContract"
        class="waves-effect waves-light btn brand-color-background"
      >
        Acceptera
      </button>
    </div>
  </div>
</template>

<script>
import { AUTH_ACTIONS } from '@/store/modules/auth';
import { APP_ACTIONS } from '@/store/modules/app';
import ContractService from '@common/Services/Contracts/ContractService';
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      contract: null
    };
  },
  mounted() {
    ContractService.getContract().then(contract => (this.contract = contract));
  },
  methods: {
    ...mapActions('auth', {
      $requestNewToken: AUTH_ACTIONS.REQUEST_NEW_ACCESS_TOKEN
    }),
    ...mapActions('app', {
      $preinit: APP_ACTIONS.$PREINIT
    }),
    agree() {
      ContractService.consentToContract(0, 0, this.contract.Id).then(() => {
        this.$requestNewToken({ force: true }).then(() => {
          this.$preinit();
          this.$router.push(
            this.$route.query.redirect ? this.$route.query.redirect : '/welcome'
          );
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.tos-container {
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  height: 100%;
  flex-direction: column;

  .tos {
    justify-self: center;
    ul li {
      font-size: 1.3em;
      margin: 30px 0;
    }
  }

  .buttons {
    width: 99%;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-content: center;
    // text-align: center;
  }
}
</style>
