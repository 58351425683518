import {
  PROMREQUEST_ACTIONS,
  PROMREQUEST_GETTERS,
  PROMREQUEST_MUTATIONS
} from './definitions';
import { sortByCreatedDate } from '@common/Helpers/sorters';
import PromRequestService from '@common/Services/PromRequests/PromRequestService';
import PromRequestModel from '@common/Models/PromRequestModel';

class State {
  constructor() {
    /** @type {PromRequestModel[]} */
    this.list = [];
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  /** @param {PromRequestModel} request */
  [PROMREQUEST_MUTATIONS.ADD_REQUEST](state, request) {
    const exists = state.list.find(
      i => i.PromRequestId === request.PromRequestId
    );
    if (!exists) {
      state.list.push(request);
    } else {
      Object.assign(exists, request);
    }
  },
  /** @param {PromRequestModel} request */
  [PROMREQUEST_MUTATIONS.REMOVE_REQUEST](state, request) {
    const index = state.list.findIndex(
      x => x.PromRequestId === request.PromRequestId
    );
    if (index > -1) {
      state.list.splice(index, 1);
    }
  },
  /** @param {PromRequestModel[]} requests */
  [PROMREQUEST_MUTATIONS.SET_REQUESTS](state, requests) {
    state.list = requests;
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  /**
   * @param {object} request
   * @param {PromModel} request.prom
   * @param {string} request.languageId
   * @param {string} request.personnummer
   * @returns {Promise<PromRequestModel>}
   */
  [PROMREQUEST_ACTIONS.CREATE_REQUEST](context, request) {
    return PromRequestService.createPromRequest(request)
      .then(request =>
        context.commit(PROMREQUEST_MUTATIONS.ADD_REQUEST, request)
      )
      .catch(error => {
        this.$bus.$emit('toast.display', {
          message: error.Message,
          status: 'error'
        });
      });
  },
  /** @param {PromRequestModel} request */
  [PROMREQUEST_ACTIONS.DELETE_REQUEST](context, request) {
    PromRequestService.deleteRequest(request.PromRequestId).then(() =>
      context.commit(PROMREQUEST_MUTATIONS.REMOVE_REQUEST, request)
    );
  },
  [PROMREQUEST_ACTIONS.GET_REQUESTS](context) {
    PromRequestService.getAllPromRequests().then(requests =>
      context.commit(PROMREQUEST_MUTATIONS.SET_REQUESTS, requests)
    );
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [PROMREQUEST_GETTERS.REQUESTS](state) {
    return sortByCreatedDate(state.list);
  }
};

export {
  PROMREQUEST_MUTATIONS,
  PROMREQUEST_ACTIONS,
  PROMREQUEST_GETTERS
} from './definitions';

export default store;
