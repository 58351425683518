import {
  STATUSREPORT_GETTERS,
  STATUSREPORT_MUTATIONS
} from '@/store/modules/statusreports';
import { mapGetters, mapMutations } from 'vuex';
export default {
  methods: {
    ...mapMutations('statusreports', {
      $completeFollowup: STATUSREPORT_MUTATIONS.COMPLETE_FOLLOWUP
    }),
    $_completedFollowupMixin_completedFollowup(id) {
      try {
        this.$completeFollowup(id);
      } catch (e) {
        console.error(e);
      }
    }
  },
  computed: {
    ...mapGetters('statusreports', {
      $findReportsByType: STATUSREPORT_GETTERS.FIND_REPORTS_BY_TYPE
    })
  }
};
