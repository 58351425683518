import env from '@/../env.json';

export const config = {
  apiUri: `${env.UriBackendApi}/`,
  identityServerUri: `${env.UriIdentityApi}`,
  tokenUri: `${env.UriIdentityApi}/token`,
  apiRoot: 'api/v1',
  PusherServerAppKey: env.PusherServerAppKey,
  PusherServerAppId: env.PusherServerAppId,
  PusherServerCluster: env.PusherServerCluster,
  PusherDebug: env.PusherDebug,
  Versions: {
    FrontendVersion: env.FrontendVersion,
    BackendVersion: env.BackendVersion,
    IdentityVersion: env.IdentityVersion,
    ReleaseVersion: env.ReleaseVersion
  }
};

export default config;
