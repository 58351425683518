// @ts-check4
import { randomString } from '@common/Helpers/strings';
import { required } from '@vuelidate/validators';

export class LanguageStringModel {
  constructor({ Id = '', LanguageId = '', Description = '', Text = '' }) {
    this._modelName = 'LanguageString';
    this.Id = Id;
    this.LanguageId = LanguageId;
    this.Description = Description;
    this.Text = Text;

    this._sortableKey = randomString();
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }

  static get validations() {
    return {
      LanguageId: {
        required
      },
      Id: {
        required
      },
      Description: {
        required
      },
      Text: {
        required
      }
    };
  }
}

export default LanguageStringModel;
