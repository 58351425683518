// @ts-check
export class LanguageModel {
  constructor({ LanguageId, Name, NativeName, Version }) {
    this._modelName = 'Language';
    this.LanguageId = LanguageId;
    this.Name = Name;
    this.NativeName = NativeName;
    this.Version = Version || 0;
  }
}

export default LanguageModel;
