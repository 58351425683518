<template>
  <div class="numbered" data-cy="prom-answer" :class="{ answer: isAnswer }">
    <input
      :key="answer.Id"
      :name="question.Id"
      :id="answer.Id"
      :value="answer.Id"
      :disabled="disabled"
      class="with-gap"
      :class="{ 'unsaved-question': isNotSavedAnswer }"
      :checked="isAnswer"
      @click="setAnswer($event)"
    />
    <label :for="answer.Id" class="pointer" :class="{ disabled: disabled }">
      <div>
        <span
          class="number"
          :class="[
            { disabled: disabled },
            { 'number--checked': isAnswer },
            { 'number--unsaved': isNotSavedAnswer }
          ]"
          v-text="index"
        ></span>
      </div>
      <div class="separator" v-if="!answer.isEmpty"></div>
      <div>
        <span
          class="text"
          :class="[
            { 'black-text': isAnswer },
            { disabled: disabled && !isAnswer }
          ]"
          v-html="toHtml(answer.Text)"
        ></span>
      </div>
    </label>
  </div>
</template>

<script>
import { toHtml } from '@common/Helpers/markdown';
export default {
  props: [
    'answer',
    'question',
    'disabled',
    'isAnswer',
    'isNotSavedAnswer',
    'type',
    'index'
  ],
  methods: {
    toHtml,
    setAnswer($event) {
      const checked = $event.target.checked;
      if (checked && this.isAnswer) {
        this.$emit('removeAnswer', this.answer);
      } else {
        this.$emit('setAnswer', this.answer, checked);
      }
    }
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@sass/_variables.scss';
$fontSize: math.div(20px, 1.4);
$fontSizeDesktop: math.div(24px, 1.4);

.numbered {
  vertical-align: text-top;
  width: 100%;
  position: relative;
  display: inline-block;
  input {
    display: none;
  }
  &.answer {
    // font-weight: 700;
  }
  label {
    font-size: $fontSize;
    display: flex;
    width: 100%;
    padding-left: 0;
    flex-direction: row;
    align-items: center;
    @media only screen and (min-width: $breakpoint-desktop) {
      font-size: $fontSizeDesktop;
    }

    &.disabled {
      cursor: default !important;
      .separator {
        border-color: #e6e6e6 !important;
      }
      .text {
        color: #e6e6e6;
      }
    }

    div {
      height: 100%;
      display: inline-block;
      max-width: calc(100% - 40px);
    }
    .number {
      width: 35px;
      text-align: center;
      border: 5px solid transparent;
      display: inline-block;
      margin: 0 5px 0 0;
      padding: 0px 5px;
      border-radius: 100%;
      margin-top: -1px;
      font-size: 18px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.6px;
      text-align: center;
      color: #122033;

      &.disabled {
        color: #e6e6e6;
      }

      &--checked {
        background-color: $symptoms-blue;
        border-color: lighten($symptoms-blue, 20%);

        &.disabled {
          color: #fff;
          background-color: #d6d6d6;
          border-color: lighten(#d6d6d6, 5%);
        }
      }

      &--unsaved {
        background-color: $symptoms-orange;
        border-color: lighten($symptoms-orange, 20%);
      }
    }

    &:after {
      display: none !important;
    }
    &:before {
      border: 0;
    }
  }
  input:checked + label:before {
    display: none;
  }
}
</style>
