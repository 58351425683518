<template>
  <div>
    <radar-chart
      :graph-data="gData"
      v-show="$hasStatusReport === true || !!graphData"
      :chart-style="$appVersion.StatusChartVersion"
    ></radar-chart>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser';
import { STATUSREPORT_GETTERS } from '@/store/modules/statusreports';
import RadarChart from '@common/Components/Graphs/RadarChart.vue';
export default {
  components: { RadarChart },
  props: {
    graphData: {
      type: Array
    }
  },
  mounted() {},
  methods: {
    gotoDetails() {
      if (this.isOnDetailsView) {
        return;
      }
      this.$router.push({ name: 'statusReportGraph' });
    }
  },
  computed: {
    ...mapGetters('currentuser', {
      $appVersion: CURRENT_USER_GETTERS.APP_VERSION
    }),
    ...mapGetters('statusreports', {
      $statusReports: STATUSREPORT_GETTERS.STATUS_REPORTS,
      $hasStatusReport: STATUSREPORT_GETTERS.SUMMARY_GRAPH_HAS_VALUES,
      $hasReportedNow: STATUSREPORT_GETTERS.HAS_REPORTED_STATUS,
      $graphData: STATUSREPORT_GETTERS.SUMMARY_GRAPH_DATA_WITHOUT_IGNORED
    }),
    isOnDetailsView() {
      return this.$route.name === 'statusReportGraph';
    },
    gData() {
      return this.graphData ? this.graphData : this.$graphData;
    }
  }
};
</script>
