import OrganizationModel from '@common/Models/OrganizationModel';

/**
 * Map remote organization to local
 * @param {object} dtoOrganization
 * @returns {OrganizationModel}
 */
export const OrganizationResponse = dtoOrganization => {
  return new OrganizationModel(dtoOrganization);
};

/**
 * Map remote organizations to local
 * @param {object[]} dtoOrganizations
 * @returns {OrganizationModel[]}
 */
export const OrganizationsResponse = dtoOrganizations => {
  return dtoOrganizations.map(dto => OrganizationResponse(dto));
};
