<template>
  <div class="prom__info">
    <div
      :id="`${anchorPrefix}-${getCurrentInstance().vnode.key}`"
      class="prom__anchor"
    />
    <div v-html="$filters.NL2BR(toHtml(item.Text))"></div>
  </div>
</template>

<script>
import { toHtml } from '@common/Helpers/markdown';
export default {
  props: ['item', 'anchorPrefix'],
  methods: {
    toHtml
  }
};
</script>

<style lang="css" scoped>
.prom__anchor {
  position: relative;
  top: -85px;
  left: 0;
}
</style>
