import AuditLogService from '@common/Services/AuditLogs/AuditLogService';
import { sortByKey } from '@common/Helpers/sorters';
import {
  AUDITLOGS_MUTATIONS,
  AUDITLOGS_ACTIONS,
  AUDITLOGS_GETTERS
} from './definitions';
import AuditLogModel from '@common/Models/AuditLogModel';

class State {
  constructor() {
    /**  @type {AuditLogModel[]} */
    this.list = [];
    this.start = 0;
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  /**
   * Set audit logs
   * @param {AuditLogModel[]} auditlogs
   */
  [AUDITLOGS_MUTATIONS.SET_AUDITLOGS](state, auditlogs) {
    if (state.list.length) {
      state.list.push(...auditlogs);
    } else {
      state.list = auditlogs;
    }
    state.start = state.list.length;
  },

  /**
   * Add audit log
   * @param {AuditLogModel} auditlog
   */
  [AUDITLOGS_MUTATIONS.ADD_AUDITLOG](state, auditlog) {
    const exists = state.list.find(a => a.Id === auditlog.Id);
    if (!exists) {
      state.list.push(auditlog);
    } else {
      Object.assign(exists, auditlog);
    }
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  /**
   * Get logs
   * @param {Number} take
   */
  [AUDITLOGS_ACTIONS.GET_MY_AUDITLOGS](context, take = 20) {
    return AuditLogService.getMyAuditLogs(context.state.start, take).then(
      auditlogs => context.commit(AUDITLOGS_MUTATIONS.SET_AUDITLOGS, auditlogs)
    );
  },

  /**
   * Get logs excluding those from current user
   */
  [AUDITLOGS_ACTIONS.GET_MY_AUDITLOGS_EXCLUDING_MYSELF](context) {
    return AuditLogService.getMyAuditLogsExcludingSelf()
      .then(auditlogs =>
        auditlogs.forEach(auditlog =>
          context.commit(AUDITLOGS_MUTATIONS.ADD_AUDITLOG, auditlog)
        )
      )
      .catch(error =>
        console.error(
          'error getting auditlogs excluding myself',
          error.toString()
        )
      );
  },

  /**
   * Get log
   * @param {String} guid
   */
  [AUDITLOGS_ACTIONS.GET_AUDITLOG](context, guid) {
    return AuditLogService.findMyAuditLog(guid)
      .then(auditlog =>
        context.commit(AUDITLOGS_MUTATIONS.ADD_AUDITLOG, auditlog)
      )
      .catch(error =>
        console.error('error getting auditlog', error.toString())
      );
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  /**
   * @param {string} auditlogid
   * @returns {AuditLogModel}
   */
  [AUDITLOGS_GETTERS.AUDITLOG]: state => auditlogid => {
    return state.list.find(a => a.Id === auditlogid);
  },
  /**
   * @returns {AuditLogModel[]}
   */
  [AUDITLOGS_GETTERS.AUDITLOGS](state) {
    return sortByKey(state.list, 'TimeStamp').reverse();
  }
};

export {
  AUDITLOGS_MUTATIONS,
  AUDITLOGS_ACTIONS,
  AUDITLOGS_GETTERS
} from './definitions';

export default store;
