import NoteModel from '@common/Models/NoteModel';

/**
 * Map remote note to local
 * @param {object} dtoNote
 * @returns {NoteModel}
 */
export const NoteResponse = dtoNote => {
  return new NoteModel(dtoNote);
};

/**
 * Map remote notes to local
 * @param {object[]} dtoNotes
 * @returns {NoteModel[]}
 */
export const NotesResponse = dtoNotes => {
  return dtoNotes.map(dto => NoteResponse(dto));
};
