<template>
  <card-vue
    image="/images/mountain.jpg"
    image-height="221px"
    :active="active"
    :key="prom.Id"
    @click="gotoReport()"
    :progress="report.percentCompleted"
  >
    <template v-slot:header>{{ prom.Concept }}</template>
    <template v-slot:progress>{{ report.percentCompleted }}</template>
    <template v-slot:text>
      {{ prom.About }}
      <div class="mt10">{{ prom.Id }}</div>
    </template>
    <template v-slot:meta>
      <div class="meta">
        <span>{{ prom.QuestionCount }} {{ $tc('plural.question', 2) }}</span>
        <span
          >{{ report.percentCompleted }}%
          {{ $t('generic.completed').toLowerCase() }}</span
        >
        <span class="right-text orange-text">
          {{ questionsRemaining }}
          {{ $tc('plural.question', questionsRemaining).toLowerCase() }}
          {{ $t('generic.left').toLowerCase() }}!
          <i class="mdi mdi-arrow-right"></i>
        </span>
      </div>
    </template>
  </card-vue>
</template>

<script>
import CardVue from '@common/Components/Cards/Card.vue';
import { mapGetters, mapActions } from 'vuex';
import { PROM_GETTERS } from '@/store/modules/proms';
import PromReportModel from '@common/Models/PromReportModel';
import { PROMREPORT_ACTIONS } from '@/store/modules/promreports';

export default {
  components: { CardVue },
  props: {
    report: {
      type: PromReportModel,
      required: true
    }
  },
  data() {
    return {
      active: true
    };
  },
  methods: {
    ...mapActions('promreports', {
      $removeReport: PROMREPORT_ACTIONS.REMOVE_REPORT,
      $getMyPromReports: PROMREPORT_ACTIONS.GET_MY_PROMREPORTS
    }),
    removeReport() {
      this.removeLoading = true;
      this.$removeReport(this.report)
        .catch(error => {
          this.$getMyPromReports();
        })
        .finally(() => {
          this.removeLoading = false;
          this.showPopup = false;
        });
    },
    gotoReport() {
      if (this.showPopup) {
        return;
      }
      this.$router.push({
        name: 'promReportEdit',
        params: { promid: this.report.PromId, promreportid: this.report.Id }
      });
    }
  },
  computed: {
    ...mapGetters('proms', {
      $findProm: PROM_GETTERS.FIND_PROM
    }),
    answeredQuestions() {
      return this.report.Answers.length || this.report.AnswerCount || 0;
    },
    questionCount() {
      return this.report.QuestionCount || this.prom.QuestionCount || 0;
    },
    questionsRemaining() {
      return this.questionCount - this.answeredQuestions;
    },
    prom() {
      return this.$findProm(this.report.PromId) || {};
    }
  }
};
</script>

<style lang="scss" scoped>
.meta {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  width: 100%;
  white-space: nowrap;
  .mdi {
    font-size: 1.2em;
    line-height: 1.2em;
    display: inline;
  }
}
</style>
