import PromSuggestionModel from '@common/Models/PromSuggestionModel';

/**
 * Map remote PromSuggestion to local
 * @param {object} dtoPromSuggestion
 * @returns {PromSuggestionModel}
 */
export const PromSuggestionResponse = dtoPromSuggestion => {
  return new PromSuggestionModel(dtoPromSuggestion);
};

/**
 * Map remote PromSuggestions to local
 * @param {object[]} dtoPromSuggestions
 * @returns {PromSuggestionModel[]}
 */
export const PromSuggestionsResponse = dtoPromSuggestions => {
  return dtoPromSuggestions.map(dto => PromSuggestionResponse(dto));
};
