// @ts-check
import { utcToLocal } from '@common/Helpers/time';
import moment from 'moment';

export class AuditLogModel {
  constructor({
    Id,
    ActorRole,
    ActorName,
    ResourceId,
    ResourceType,
    ActionType,
    DataBefore,
    DataAfter,
    TimeStamp,
    Version
  }) {
    this._modelName = 'AuditLog';
    this.Id = Id;
    this.ActorRole = ActorRole;
    this.ActorName = ActorName;
    this.ResourceId = ResourceId;
    this.ResourceType = ResourceType;
    this.ActionType = ActionType;
    this.DataBefore = DataBefore ? JSON.parse(DataBefore) : null;
    this.DataAfter = DataAfter ? JSON.parse(DataAfter) : null;
    this.TimeStamp = TimeStamp ? utcToLocal(TimeStamp) : moment();
    this.Version = Version || 0;
  }
}

export default AuditLogModel;
