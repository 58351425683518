import BaseService from '../BaseService';
import SymptomReportSymptomModel from '@common/Models/SymptomReport/SymptomReportSymptomModel';
import { SymptomReportSymptomsResponse } from './mappers/SymptomReportSymptomResponses';

class SymptomReportSymptomService extends BaseService {
  /**
   * Get SymptomReportSymptoms export -- backend
   * @param {string[]} requiredContracts Contracts (GUID's) that users must have accepted to be included in export.
   * @returns {Promise<SymptomReportSymptomModel[]>}
   * @memberof SymptomReportSymptomService
   */
  getSymptomReportSymptomsExport(requiredContracts) {
    return this.backend(
      'post',
      'admin/symptomreportsymptoms/export',
      requiredContracts
    ).then(response => SymptomReportSymptomsResponse(response.data));
  }
}

export default new SymptomReportSymptomService();
