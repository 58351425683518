<template>
  <component :is="divOrLink" :to="link" class="block" v-if="note">
    <info-row
      :popup="showPopup"
      :light-title="true"
      :content-padding="extraPadding"
      :background-color="active ? 'active' : ''"
    >
      <template v-slot:image>
        <i class="mdi mdi-message-text"></i>
      </template>

      <template v-slot:title>
        <span
          data-cy="notes-note-text"
          v-html="$filters.NL2BR(note.Content)"
        ></span>
      </template>

      <template v-slot:date>
        <created-date :report="note"></created-date>
      </template>

      <template v-slot:buttons>
        <i
          data-cy="notes-note-deletebutton"
          class="mdi mdi-trash-can-outline"
          @click.prevent.self="confirmRemove = true"
        ></i>

        <modal-confirm
          :show="confirmRemove"
          :accept-callback="() => removeNote()"
          :accept-text="$t('generic.deleteShort')"
          :show-extras="false"
          :decline-text="$t('generic.cancel')"
          @close="confirmRemove = false"
          >{{ $t('modals.confirmRemoveNote') }}</modal-confirm
        >
      </template>
    </info-row>
  </component>
</template>

<script>
import InfoRow from '@common/Components/InfoRow/InfoRow';
import NoteModel from '@common/Models/NoteModel';
import CreatedDate from '@/views/components/InfoRow/CreatedDate.vue';
import CardMixin from './mixins/CardMixin';
import NoteService from '@common/Services/Notes/NoteService';

export default {
  mixins: [CardMixin],
  components: { InfoRow, CreatedDate },
  props: {
    report: {
      type: NoteModel,
      required: true
    },
    active: {
      type: Boolean
    }
  },
  data() {
    return {
      removeLoading: false
    };
  },
  methods: {
    removeNote() {
      this.removeLoading = true;

      return NoteService.deleteMyNote(this.note.Id).then(() => {
        this.$emit('delete');
      });
    }
  },
  computed: {
    note() {
      return this.report;
    },
    extraPadding() {
      const content = this.note.Content.split('');
      const newlines = content.reduce((n, c) => {
        return n + (c === '\n' ? 1 : 0);
      }, 0);

      return newlines > 0 || content.length > 25;
    },
    link() {
      return {
        name: 'notes',
        params: {
          noteId: this.note.Id
        }
      };
    }
  }
};
</script>

<style></style>
