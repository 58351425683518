import { marked } from 'marked';

export const toHtml = (text, inline = true) => {
  if (!text) {
    return;
  }
  text = text.replace(/\n/g, '<br>');
  text = marked(text);
  if (inline) {
    text = text.replace(/<\/?p>/g, '');
  }
  return text;
};

export const toPlain = text => {
  text = text.replace(/\*\*(.*?)\*\*/g, '$1');
  text = text.replace(/__(.*?)__/g, '$1');
  text = text.replace(/~~(.*?)~~/g, '$1');
  text = text.replace(/--(.*?)--/g, '$1');
  text = text.replace(/<<(.*?)>>/g, '$1');
  return text;
};
