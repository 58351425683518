import store from '@/store';
import { AUTH_GETTERS } from '@/store/modules/auth';
import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser';
import { isAuthed } from '@common/Helpers/auth';
import settings from '../../settings.json';
import { getDefaultLandingPage } from './helpers';

/** @type {import('vue-router').NavigationGuard} */
export const requireAuth = (to, from, next) => {
  let path = to.fullPath;
  if (
    isAuthed() &&
    ((store.getters[`auth/${AUTH_GETTERS.LOGGED_IN}`] === false &&
      store.getters[`currentuser/${CURRENT_USER_GETTERS.PROFILE}`] === null) ||
      to.query?.refreshToken)
  ) {
    if (to.query?.refreshToken) {
      path = path.replace(/\??refreshToken=true/, '');
    }
    return next({
      name: 'login-loading',
      query: { redirect: path }
    });
  } else if (!isAuthed()) {
    return next({
      path: '/login',
      query: { redirect: path }
    });
  }
  next();
};

/** @type {import('vue-router').NavigationGuard} */
export const requireAuthIndependent = (to, from, next) => {
  if (isAuthed() && store.getters[`auth/${AUTH_GETTERS.LOGGED_IN}`] === false) {
    next({
      name: 'login-loading',
      query: { redirect: to.fullPath, onlyAuth: true }
    });
  } else {
    next();
  }
};

/** @type {import('vue-router').NavigationGuard} */
export const requireEnabledModule = (to, from, next) => {
  let module = Object.keys(settings.modules).find(
    module => settings.modules[module].routes.indexOf(to.name) > -1
  );
  if (!module) {
    return next('/welcome');
  }

  module = settings.modules[module];
  if (module.enabled === true) {
    next();
  } else {
    next('/welcome');
  }
};

function operate(guards, from, to, lastNext, i) {
  const guard = guards[i];
  if (guards.length === i + 1) {
    guard(from, to, lastNext);
  } else {
    guard(from, to, function (nextArg) {
      switch (typeof nextArg) {
        case 'undefined':
          operate(guards, from, to, lastNext, i + 1);
          break;
        case 'object':
          lastNext(nextArg);
          break;
        case 'boolean':
          lastNext(nextArg);
          break;
        case 'string':
          lastNext(nextArg);
          break;
      }
    });
  }
}

export function GuardsCheck(ListOfGuards) {
  return function (from, to, next) {
    operate(ListOfGuards, from, to, next, 0);
  };
}
