// @ts-check
export class BugReportScreenshotModel {
  constructor({ Id, FileName, MimeType, Data }) {
    this._modelName = 'BugReportScreenshot';
    this.Id = Id;
    this.FileName = FileName;
    this.MimeType = MimeType;

    if (!Data.startsWith('data:')) {
      Data = `data:${MimeType};base64,${Data}`;
    }

    this.Data = Data;
  }
}

export default BugReportScreenshotModel;
