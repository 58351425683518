<template>
  <div class="container">
    <h4 class="header pb20">
      {{ $t('views.privacy.title') }}
    </h4>
    <p>
      Vi som står bakom denna tjänst är Symptoms Europe AB (www.symptoms.se),
      ett svenskt företag baserat i Uppsala. Syftet med tjänsten är att låta dig
      samla information om din personliga hälsa i ett lättöverskådligt och
      användbart format.
    </p>
    <p>
      För att kunna uppfylla det syftet och skapa värde för dig som användare
      behöver Symptoms samla in och behandla ett antal personuppgifter. Denna
      policy detaljerar vilka uppgifter vi samlar in, hur vi använder dem, på
      vilken laglig grund vi behandlar dem, samt vilka rättigheter du har
      gällande de uppgifter du lämnar till oss.
    </p>

    <h4>Vad är en personuppgift?</h4>
    <p>
      All information som direkt eller indirekt kan kopplas till en levande
      person är personuppgifter. Olika slags elektroniska uppgifter, som t.ex.
      IP-nummer, räknas som personuppgifter om de kan kopplas till fysiska
      personer.
    </p>

    <h4>Vilka personuppgifter behandlar vi?</h4>
    <p>
      För att kunna skapa ett användarkonto hos Symptoms måste du uppge för- och
      efternamn samt personnummer. Du behöver även uppge e-postadress och
      telefonnummer, för att vi ska kunna kontakta dig utanför tjänsten vid
      behov (t.ex. för återställning av lösenord, om vi misstänker att någon
      annan kommit åt ditt konto, vid intrång i vår databas, eller annan akut
      situation).
    </p>
    <p>
      Vidare kan du välja att ange kön, län/kommun/stad/postnummer, samt
      födelseår i din profil, för att få en bättre och mer personlig upplevelse
      av tjänsten och bidra till mer detaljerad statistik. Om du anger dessa
      icke-obligatoriska uppgifter i din profil så samtycker du till att
      Symptoms får lagra, sammanställa och visa dessa uppgifter för dig samt
      använda dem i anonymiserad form i statistiska sammanställningar. Om du
      vill dra tillbaka ditt samtycke till denna behandling kan du när som helst
      radera dessa uppgifter i din profil, så att Symptoms inte längre kan
      behandla dem.
    </p>
    <p>
      När du använder tjänsten kommer du att löpande registrera uppgifter om
      ditt hälsotillstånd (t.ex. smärta, symtom, fysisk funktion, mentalt
      tillstånd, medicinering, hälso- och sjukvård), vid olika tidpunkter.
      Symptoms kommer att lagra dessa uppgifter, och presentera dem för dig i
      form av olika sammanställningar, diagram och vyer. Du kan även välja att
      samtycka till att uppgifterna får användas i anonymiserad form till
      statistik, samt för att tillfråga dig om deltagande i forskningsstudier.
    </p>
    <p>
      För din egen säkerhets skull och för att optimera tjänstens prestanda
      samlar vi in viss teknisk information om din användning, t.ex. IP-nummer,
      utrustning (dator, surfplatta, smartphone), operativsystem, webbläsare,
      land, region, stad.
    </p>
    <p>
      Vi registrerar även vissa metadata om din användning av tjänsten, t.ex.
      utförda aktiviteter i tjänsten, tidpunkter för olika aktiviteter, hur lång
      tid spenderad på olika frågor i formulär, osv. Detta för att kunna
      identifiera var och hur vi kan förbättra din och andra användares
      upplevelse av tjänsten, samt att bättre kunna korrigera eventuella buggar
      i systemet.
    </p>

    <h4>Laglig grund</h4>
    <ul class="list">
      <li>
        <span class="bold">Samtycke</span>: Du har aktivt samtyckt till
        behandlingen, efter att ha blivit tydligt informerad. Ditt samtycke kan
        påvisas (t.ex. genom att du signerat med BankID) och gäller tills du
        meddelar oss att du drar tillbaka det.
      </li>
      <li>
        <span class="bold">Fullgörande av avtal</span>: För att kunna skapa ett
        användarkonto hos Symptoms måste du ingå ett avtal med oss som
        detaljerar vad du och vi har för rättigheter och skyldigheter gentemot
        varandra. Avtalet kan du läsa här. Vi får utföra viss behandling av dina
        personuppgifter som du inte uttryckligen samtyckt till, om behandlingen
        är nödvändig för att vi ska kunna fullgöra vår del av avtalet.
      </li>
      <li>
        <span class="bold">Skydd av intresse</span>: EU:s dataskyddslag säger
        att behandling av personuppgifter är laglig om: "Behandlingen är
        nödvändig för att skydda intressen som är av grundläggande betydelse för
        den registrerade eller för en annan fysisk person." Det är alltså ett
        skydd av dina intressen. Det är den här grunden vi lutar oss mot när vi
        lagrar och verifierar kontaktuppgifter till dig utanför tjänsten
        (e-postadress och telefonnummer), för att vi ska kunna kontakta dig när
        vi bedömer att det ligger i ditt intresse att vi gör det. Exempel på
        sådana situationer nämndes i föregående avsnitt, Vilka personuppgifter
        behandlar vi?, första stycket.
      </li>
      <li>
        <span class="bold">Berättigat intresse</span>: Vi får behandla
        personuppgifter om behandlingen är nödvändig för ändamål som rör våra
        eller en tredje parts berättigade intressen, om inte dina intressen
        eller grundläggande rättigheter och friheter väger tyngre och kräver
        skydd av personuppgifter. Detta innebär bl.a. att vi samlar in
        information om hur du använder tjänsten, i syfte att förbättra tjänsten
        vi tillhandahåller.
      </li>
    </ul>

    <h4>Skydd av dina uppgifter</h4>
    <p>
      Symptoms värnar om din integritet och arbetar aktivt med data- och
      informationssäkerhet i vår dagliga verksamhet. Vi följer alltid "best
      practices" inom IT-säkerhet. Eftersom uppgifter om hälsotillstånd anses
      särskilt känsliga finns dessutom hårdare lagkrav på skydd av sådana
      uppgifter.
    </p>
    <p>
      Gällande barn under 18 år är behandling av personuppgifter endast laglig
      om samtycke finns från den som har föräldraansvar för barnet, eftersom
      barns uppgifter kräver särskilt skydd. Är du under 18 år kan du därför
      endast skapa ett konto hos Symptoms efter inbjudan från en
      förälder/vårdnadshavare.
    </p>
    <p>
      Inga personuppgifter lämnas ut till tredje part (t.ex. vårdinrättningar
      och -personal, forskningsinstitutioner, statistiksammanställningar) utan
      ditt medgivande, såvida inte underlåtelse att lämna ut sådana uppgifter
      medför ett brott mot gällande lagstiftning (t.ex. på begäran av myndighet
      eller domstol).
    </p>
    <p>
      Dina personuppgifter lagras hos oss så länge du har ett användarkonto hos
      Symptoms. Vid avslutandet av ditt konto kan du välja om dina
      hälsouppgifter ska finnas kvar i anonymiserad form (så att de fortfarande
      kan användas till studier och statistik) eller raderas.
    </p>
    <p>
      Symptoms använder aldrig dina personuppgifter för profilering och direkt
      marknadsföring. Symptoms kommer aldrig att sälja identifierande uppgifter
      om dig till andra företag eller personer.
    </p>

    <h4>Dina rättigheter</h4>
    <p>
      När du delgivit oss dina personuppgifter har du ett antal rättigheter som
      ger dig en viss kontroll över dem. Här är de viktigaste:
    </p>
    <ul class="list">
      <li>
        <span class="bold">Rätt till tillgång</span>: Du kan närsomhelst begära
        tillgång till de personuppgifter som finns registrerade hos oss. Du får
        då uppgifterna som en komprimerad .zip-fil som innehåller .json-filer
        med alla uppgifter vi har om dig. Om du inte har möjlighet att ta emot
        uppgifterna i elektronisk form kan du få en kopia av uppgifterna i
        pappersform utan kostnad. För ytterligare kopior utöver det tas en
        administrativ avgift ut som står i proportion till våra administrativa
        kostnader.
      </li>
      <li>
        <span class="bold">Rätt till rättelse</span>: Du kan närsomhelst begära
        att korrigera eller komplettera felaktiga eller ofullständiga
        personuppgifter, varvid en sådan rättelse görs utan onödigt dröjsmål.
      </li>
      <li>
        <span class="bold">Rätt att återkalla samtycke</span>: Du kan
        närsomhelst återkalla ditt samtycke till all eller viss behandling av
        dina personuppgifter, som grundar sig på samtycket i fråga. Ett sådant
        återkallande gäller från den tidpunkt då Symptoms tog del av
        återkallandet och påverkar inte behandling utförd innan denna tidpunkt.
      </li>
      <li>
        <span class="bold">Rätt till radering (rätt att bli "bortglömd")</span>:
        Du kan närsomhelst begära uppgifter om dig raderade, varvid vi raderar
        dessa i den mån det är möjligt. Exempel på när det inte är möjligt är
        t.ex. att radera uppgifterna ur en redan publicerad studie eller
        statistisk sammanställning, eller om en vårdgivare har tagit del av
        uppgifter om din hälsa och vård som då måste lagras i minst 10 år enligt
        gällande lagstiftning. Om du begär radering av uppgifter som krävs för
        att vi ska kunna fullgöra vårt avtal med dig, kommer avtalet att upphöra
        att gälla och du kan inte längre använda tjänsten.
      </li>
    </ul>

    <p>
      Du är alltid välkommen att kontakta oss vid frågor om
      personuppgiftsbehandling generellt, eller behandling av dina specifika
      uppgifter, på
      <a href="mailto:integritet@symptoms.se">integritet@symptoms.se</a>. Vid
      invändningar mot behandling av dina personuppgifter, som inte kan lösas
      genom kommunikation med oss, kan du vända dig till Datainspektionen.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';
p a {
  text-decoration: underline;
  color: $symptoms-blue;
}
</style>
