import {
  CalendarFormat,
  DateFormat,
  DateTimeFormat,
  DayOfMonthFormat,
  DaysFromNowFormat,
  FromNowFormat,
  ShortDateFormat,
  SimpleDateFormat,
  TimeFormat
} from '@common/Filters/Date';
import { NL2BR } from '@common/Filters/String';
import { marked } from 'marked';

export const registerFilters = app => {
  const filters = {
    DayOfMonthFormat,
    DateTimeFormat,
    ShortDateFormat,
    DateFormat,
    TimeFormat,
    DaysFromNowFormat,
    FromNowFormat,
    CalendarFormat,
    SimpleDateFormat,
    NL2BR,
    marked
  };

  app.config.globalProperties.$filters = filters;
};
