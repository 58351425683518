<template>
  <div
    class="report-summary valign-wrapper pointer"
    @click="newPromReport(prom.Id)"
  >
    <div class="report-summary__info valign-wrapper">
      <i class="mdi mdi-clipboard-content small type-icon mr10"></i>
      <div class="">
        <span
          class="report-summary__info__info"
          v-html="highlight(prom.Description)"
        ></span>
        <div
          class="report-summary__info__tag"
          v-for="(tag, i) in prom.Tags"
          :key="i"
          v-html="highlight(tag)"
        ></div>
        <br />
        <span class="report-summary__info__date"
          >{{ prom.QuestionCount }} {{ $tc('plural.questions', 2) }} (<span
            v-html="highlight(prom.Id, true)"
          ></span
          >)</span
        >
      </div>
    </div>
    <div class="report-summary__right-arrow valign-wrapper">
      <i class="mdi mdi-chevron-right"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: ['prom', 'query'],
  methods: {
    newPromReport(promid) {
      this.$router.push({ name: 'promReportEdit', params: { promid } });
    },
    highlight(text, uppercase = false) {
      const regexp = new RegExp(this.query, 'i');
      const query = uppercase ? this.query.toUpperCase() : this.query;
      return text.replace(regexp, `<span class="highlight">${query}</span>`);
    }
  }
};
</script>

<style lang="scss">
@import '@sass/_variables.scss';

.highlight {
  color: $symptoms-yellow;
}
</style>
