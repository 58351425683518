import { State } from './State';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export { MUTATIONS as QUINCYMUTATIONS } from './definitions';
export { GETTERS as QUINCYGETTERS } from './definitions';

export default {
  namespaced: true,
  state: new State(),
  mutations,
  actions,
  getters
};
