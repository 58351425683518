// @ts-check
import { randomString } from '@common/Helpers/strings';
import moment from 'moment';
import { maxLength, required } from '@vuelidate/validators';
import { utcToLocal } from '../Helpers/time';

export class SymptomDefinitionModel {
  constructor({
    Id,
    Name,
    CreatedDate,
    ModifiedDate,
    PatientId,
    Version,
    Index,
    IsFromSnomed
  }) {
    this._modelName = 'SymptomDefinition';

    this.Id = Id;
    this.Name = Name;
    this.CreatedDate = CreatedDate ? utcToLocal(CreatedDate) : moment();
    this.ModifiedDate = ModifiedDate
      ? utcToLocal(ModifiedDate)
      : this.CreatedDate;
    this.PatientId = PatientId;
    this.Version = Version || 0;
    this.Index = Index;
    this.IsFromSnomed = IsFromSnomed;
    this._sortableKey = randomString();
  }

  static get validations() {
    return {
      Name: {
        required,
        maxLength: maxLength(60)
      }
    };
  }

  get isCreatedByPatient() {
    return this.PatientId !== null && this.PatientId !== undefined;
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }
}

export default SymptomDefinitionModel;
