// import Axios from 'axios';

export const ErrorHandler = (err, vm, info) => {
  console.log('~~~~~~~~~~~~~~~~~~ <Error handler> ~~~~~~~~~~~~~~~~~~~');
  console.error(err);
  console.log('~~~~~~~~~~~~~~~~~~ </Error handler> ~~~~~~~~~~~~~~~~~~~');

  // Post to graylog
  // Axios.post(url, { data });
};
