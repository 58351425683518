import ApplicationModel from '@common/Models/ApplicationModel';

/**
 * Map remote Application to local
 * @param {object} dtoApplication
 * @returns {ApplicationModel}
 */
export const ApplicationResponse = dtoApplication => {
  const application = new ApplicationModel(dtoApplication);
  application.saved = true; // TODO: Add a GUID ID or Version field instead that can be used for a saved getter
  return application;
};

/**
 * Map remote Applications to local
 * @param {object[]} dtoApplications
 * @returns {ApplicationModel[]}
 */
export const ApplicationsResponse = dtoApplications => {
  return dtoApplications.map(dto => ApplicationResponse(dto));
};
