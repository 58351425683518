import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser';
import { sortByKey } from '@common/Helpers/sorters';
import NewsItemModel from '@common/Models/NewsItemModel';
import NewsItemService from '@common/Services/NewsItems/NewsItemService';
import { NEWS_ACTIONS, NEWS_GETTERS, NEWS_MUTATIONS } from './definitions';

class State {
  constructor() {
    /** @type {NewsItemModel[]} */
    this.list = [];
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  /**
   * Add  news item
   * @param {NewsItemModel} newsItem
   */
  [NEWS_MUTATIONS.ADD_NEWS_ITEM](state, newsItem) {
    const exists = state.list.find(n => n.Id === newsItem.Id);
    if (!exists) {
      state.list.push(newsItem);
    } else {
      Object.assign(exists, newsItem);
    }
  },

  /**
   * Remove  news item
   * @param {NewsItemModel} newsItem
   */
  [NEWS_MUTATIONS.REMOVE_NEWS_ITEM](state, newsItem) {
    const index = state.list.findIndex(n => n.Id === newsItem.Id);
    if (index > -1) {
      state.list.splice(index, 1);
    }
  },

  /**
   * Set news items
   * @param {NewsItemModel[]} newsItems
   */
  [NEWS_MUTATIONS.SET_NEWS_ITEMS](state, newsItems) {
    state.list = newsItems;
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  /**
   * Get news items
   * @returns {Promise<NewsItemModel[]>}
   */
  [NEWS_ACTIONS.GET_NEWS_ITEMS](context) {
    NewsItemService.getNewsItems().then(newsItems => {
      return context.commit(NEWS_MUTATIONS.SET_NEWS_ITEMS, newsItems);
    });
  },
  /**
   * Get published news items
   * @returns {Promise<NewsItemModel[]>}
   */
  [NEWS_ACTIONS.GET_PUBLISHED_NEWS_ITEMS](context) {
    return NewsItemService.getPublishedNewsItems().then(newsItems => {
      context.commit(NEWS_MUTATIONS.SET_NEWS_ITEMS, newsItems);
      return newsItems;
    });
  },
  /**
   * Create news item
   * @param {NewsItemModel} newsItem
   */
  [NEWS_ACTIONS.CREATE_NEWS_ITEM](context, newsItem) {
    const newNewsItem = { ...newsItem };

    return NewsItemService.createNewsItem(newNewsItem).then(newsItem => {
      context.commit(NEWS_MUTATIONS.ADD_NEWS_ITEM, newsItem);
    });
  },
  /**
   * Update news item
   * @param {NewsItemModel} newsItem
   */
  [NEWS_ACTIONS.UPDATE_NEWS_ITEM](context, newsItem) {
    const updatedNewsItem = { ...newsItem };
    return NewsItemService.updateNewsItem(updatedNewsItem).then(newsItem => {
      context.commit(NEWS_MUTATIONS.ADD_NEWS_ITEM, newsItem);
      return newsItem;
    });
  },
  /**
   * Delete news item
   * @param {NewsItemModel} newsItem
   */
  [NEWS_ACTIONS.DELETE_NEWS_ITEM](context, newsItem) {
    NewsItemService.deleteNewsItem(newsItem).then(() =>
      context.commit(NEWS_MUTATIONS.REMOVE_NEWS_ITEM, newsItem)
    );
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [NEWS_GETTERS.NEWS_ITEMS](state) {
    return sortByKey(state.list, 'PublishDate').reverse();
  },
  [NEWS_GETTERS.NEWS_ITEMS_AFTER]: state => date => {
    return state.list.filter(n => {
      return +n.PublishDate >= +date;
    });
  },
  [NEWS_GETTERS.FIND_NEWS_ITEM]: state => newsItemId => {
    return state.list.find(n => n.Id === newsItemId);
  },
  [NEWS_GETTERS.UNREAD_VERY_IMPORTANT_NEWS](
    state,
    getters,
    rootState,
    rootGetters
  ) {
    const lastLogin =
      rootGetters[`currentuser/${CURRENT_USER_GETTERS.STATS}`].lastLogin;
    const unreadNewsItems = getters
      .NEWS_ITEMS_AFTER(lastLogin)
      .filter(n => n.Priority === 'very-important');
    return unreadNewsItems;
  }
};

export { NEWS_ACTIONS, NEWS_GETTERS, NEWS_MUTATIONS } from './definitions';

/** @type {import('vuex').Module} */
export default store;
