<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <defs>
      <path
        id="a"
        d="M7.755 9.026c-.35.397-.436.682-.893 1.428.295.213.865.706 1.259 1.554.788-.466 1.155-.538 1.573-.9 2.725-2.368 9.4-10.855 9.17-11.1-.243-.261-8.71 6.303-11.11 9.018z"
      />
      <path
        id="b"
        d="M5.471 10.33c-1.161-.226-2.307.58-3.072 2.594-.766 2.014-2.134 2.82-2.399 2.77 1.427.568 5.78 2.018 6.862-3.502-.46-1.324-1.39-1.863-1.39-1.863z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <template v-if="active">
        <circle
          cx="20"
          cy="20"
          r="19.389"
          stroke="#122033"
          stroke-width="1.222"
        />
        <g fill-rule="nonzero">
          <g transform="translate(11 12)">
            <use fill="#F97C5A" xlink:href="#a" />
            <use fill="#122033" xlink:href="#a" />
          </g>
          <g transform="translate(11 12)">
            <use fill="#F97C5A" xlink:href="#b" />
            <use fill="#122033" xlink:href="#b" />
          </g>
        </g>
      </template>
      <template v-else>
        <circle
          cx="20"
          cy="20"
          r="20"
          fill="#ADB2B8"
          opacity=".3"
          class="circle"
        />
        <g fill-rule="nonzero" opacity=".4">
          <g transform="translate(11 12)">
            <use fill="#F97C5A" xlink:href="#a" />
            <use fill="#122033" xlink:href="#a" />
          </g>
          <g transform="translate(11 12)">
            <use fill="#F97C5A" xlink:href="#b" />
            <use fill="#122033" xlink:href="#b" />
          </g>
        </g>
      </template>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    active: Boolean
  }
};
</script>
