<template>
  <div class="contract" v-if="contract">
    <h5 class="center-text sub-header" v-text="contract.Title"></h5>
    <ul v-for="(section, sindex) in contract.Sections" :key="`s${sindex}`">
      <li>
        <h6 v-text="section.Title"></h6>
        <ul>
          <li
            v-for="(paragraph, pindex) in section.Paragraphs"
            :key="`p${pindex}}`"
            v-html="toHtml(paragraph.Text)"
            class="paragraph"
          ></li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { toHtml } from '@common/Helpers/markdown';
import ContractModel from '@common/Models/ContractModel';
export default {
  props: {
    contract: ContractModel
  },
  methods: {
    toHtml
  }
};
</script>
<style lang="scss" scoped>
.contract {
  a {
    border-bottom: 1px dotted black;
  }
  h6 {
    font-weight: 700;
  }
  li {
    line-height: 1.8em;
  }
  .paragraph {
    margin: 20px 0px 0px 0px;
    list-style-type: circle !important;
  }
}
</style>
