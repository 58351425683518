// @ts-check
import { maxLength, minLength, required, url } from '@vuelidate/validators';
import { sortByField } from '@common/Helpers/sorters';
import PromGroupModel from '@common/Models/PromGroupModel';
import SymptomDefinitionModel from '@common/Models/SymptomDefinitionModel';

export class GroupModel {
  constructor({
    Id,
    Name,
    Version,
    SymptomTypes,
    OrganizationId,
    PusherId,
    UrlSlug,
    LandingPageUrl,
    WelcomeText,
    GreetingText,
    IntroText,
    GroupCardPromId,
    InfoText,
    FormTitle,
    FormText,
    FormButtonLabel,
    HelpUrl,
    Link1Url,
    Link1Text,
    Link2Url,
    Link2Text,
    FirstReportSavedText,
    StatusReportTypes,
    InitialPromId = null,
    InitialUri = null,
    PromGroups = [],
    SymptomDefinitions = [],
    Roles = [] // Roles is from intermediary model
  }) {
    this._modelName = 'Group';
    this.Id = Id || null;
    this.Name = Name;
    this.Version = Version;
    this.SymptomTypes = SymptomTypes;
    this.OrganizationId = OrganizationId;
    this.PusherId = PusherId;
    this.UrlSlug = UrlSlug;
    this.LandingPageUrl = LandingPageUrl;
    this.WelcomeText = WelcomeText;
    this.GreetingText = GreetingText;
    this.IntroText = IntroText;
    this.InfoText = InfoText;
    this.FormTitle = FormTitle;
    this.FormText = FormText;
    this.FormButtonLabel = FormButtonLabel;
    this.HelpUrl = HelpUrl;
    this.Link1Url = Link1Url;
    this.Link1Text = Link1Text;
    this.Link2Url = Link2Url;
    this.Link2Text = Link2Text;
    this.FirstReportSavedText = FirstReportSavedText;
    this.StatusReportTypes = StatusReportTypes;
    this.InitialPromId = InitialPromId;
    this.GroupCardPromId = GroupCardPromId;
    this.InitialUri = InitialUri;
    this.PromGroups = PromGroups.map(pg => new PromGroupModel(pg));
    this.SymptomDefinitions = SymptomDefinitions.map(
      sd => new SymptomDefinitionModel(sd)
    ).sort(sortByField('Index'));
    this.Roles = Roles;
  }

  get Saved() {
    return !!this.Version;
  }

  get IsGlobal() {
    return this.OrganizationId == '0' && this.Id == '0';
  }

  get DisplayName() {
    return this.IsGlobal ? `Symptoms` : `${this.Name}`;
  }

  static get validations() {
    return {
      Id: {
        minLength: minLength(1),
        maxLength: maxLength(32),
        required
      },
      Name: {
        maxLength: maxLength(64),
        required
      },
      UrlSlug: {
        maxLength: maxLength(64)
      },
      LandingPageUrl: {
        maxLength: maxLength(255),
        url: url
      },
      InitialUri: {
        maxLength: maxLength(255)
      },
      WelcomeText: {
        maxLength: maxLength(1000)
      },
      GreetingText: {
        maxLength: maxLength(128)
      },
      IntroText: {
        maxLength: maxLength(1000)
      },
      InfoText: {
        maxLength: maxLength(1000)
      },
      FormTitle: {
        maxLength: maxLength(128)
      },
      FormText: {
        maxLength: maxLength(1000)
      },
      FormButtonLabel: {
        maxLength: maxLength(32)
      },
      HelpUrl: {
        maxLength: maxLength(255),
        url: url
      },
      Link1Url: {
        maxLength: maxLength(255),
        url: url
      },
      Link1Text: {
        maxLength: maxLength(64)
      },
      Link2Url: {
        maxLength: maxLength(255),
        url: url
      },
      Link2Text: {
        maxLength: maxLength(64)
      },
      FirstReportSavedText: {
        maxLength: maxLength(1000)
      },
      PromGroups: {},
      SymptomDefinitions: {},
      InitialPromId: {},
      GroupCardPromId: {}
    };
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }
}

export default GroupModel;
