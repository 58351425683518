import config from '@common/config';
import BugReportModel from '@common/Models/BugReportModel';
import BugReportIssueModel from '@common/Models/BugReportIssueModel';
import BaseService from '../BaseService';
import {
  BugReportResponse,
  BugReportsResponse
} from './mappers/BugReportResponses';
import { BugReportRequest } from './mappers/BugReportRequests';
class BugReportService extends BaseService {
  /**
   * Send bug report -- backend
   * @param BugReportModel bugReport
   * @returns {Promise<BugReportModel>}
   * @memberof BugReportService
   */
  sendReport(bugReport) {
    return this.backend('post', 'bugreports', {
      ...BugReportRequest(bugReport),
      ...config.Versions
    }).then(response => BugReportResponse(response.data));
  }

  /**
   * Get bug reports -- backend
   * @returns {Promise<BugReportModel[]>}
   * @memberof BugReportService
   */
  getBugReports() {
    return this.backend('bugreports').then(response =>
      BugReportsResponse(response.data)
    );
  }

  /**
   * Get bug report -- backend
   * @returns {Promise<BugReportModel>}
   * @memberof BugReportService
   */
  getBugReport(guid) {
    return this.backend(`bugreports/${guid}`).then(response =>
      BugReportResponse(response.data)
    );
  }

  /**
   * Create issue from bugreport
   * @param {BugReportIssueModel} issue
   * @returns {Promise<BugReportModel>}
   * @memberof BugReportService
   */
  createBugReportIssue(issue) {
    return this.backend('post', 'bugreports/issue', issue).then(response =>
      BugReportResponse(response.data)
    );
  }

  /**
   * Delete bug report -- backend
   * @param {BugReportModel} bugReport
   * @returns {Promise<>}
   * @memberof BugReportService
   */
  deleteBugReport(bugReport) {
    return this.backend('post', `bugreports/${bugReport.Id}/delete`);
  }
}

export default new BugReportService();
