import { Http } from '@common/Http';
import { AxiosResponse } from 'axios';
const acceptedCalls = ['get', 'post', 'put', 'delete'];
class BaseService {
  constructor() {
    this._backend = new Http('backend');
    this._identity = new Http('identity');
  }

  /**
   * Make a call to backend
   * @param {('get'|'post'|'put'|'delete')} method
   * @param {string} path
   * @param {object} data
   * @returns {Promise<AxiosResponse>}
   * @memberof BaseService
   */
  backend(method, path, data, options = {}) {
    if (acceptedCalls.indexOf(method) === -1) {
      data = path || null;
      path = method;
      method = 'get';
    }

    return this._backend[method]({ path, data, options }).catch(error => {
      throw this._parseError(error);
    });
  }

  /**
   * Make a call to identity
   * @param {('get'|'post'|'put'|'delete')} method
   * @param {string} path
   * @param {object} data
   * @returns {Promise<AxiosResponse>}
   * @memberof BaseService
   */
  identity(method, path, data, options = {}) {
    if (acceptedCalls.indexOf(method) === -1) {
      data = path || null;
      path = method;
      method = 'get';
    }

    return this._identity[method]({ path, data, options }).catch(error => {
      throw this._parseError(error);
    });
  }

  _parseError(error) {
    if (error.response && error.response.data) {
      return error.response.data;
    }
    return error;
  }
}

export default BaseService;
