<template>
  <modal
    :show="show"
    @close="$emit('closed')"
    height="auto"
    accept-text="OK"
    accept-classes="primary"
    :hide-left-button="true"
  >
    <h5 class="center-text sub-header">Tack för dina svar</h5>
    <div
      v-html="toHtml($activeGroup ? $activeGroup.FirstReportSavedText : '')"
    ></div>
  </modal>
</template>

<script>
import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser';
import { mapGetters } from 'vuex';
import { toHtml } from '@common/Helpers/markdown';

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('currentuser', {
      $activeGroup: CURRENT_USER_GETTERS.ACTIVE_GROUP
    })
  },
  methods: {
    toHtml
  }
};
</script>

<style></style>
