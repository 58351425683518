<template>
  <div
    class="header-toast"
    ref="toast"
    :data-cy="`toast-${this.settings.status}`"
    :class="[
      {
        'header-toast--fullwidth': !$showMenu,
        'header-toast--visible': visible
      },
      `header-toast--${this.settings.status}`
    ]"
  >
    {{ settings.message }}
    <div class="header-toast__close" @click="close">
      <i class="mdi mdi-close"></i>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { APP_GETTERS } from '@/store/modules/app';

export default {
  data() {
    return {
      defaultSettings: {
        message: '',
        status: '',
        duration: 2000
      },
      settings: {}
    };
  },
  mounted() {
    this.$bus.$on('toast.display', toast => this.display(toast));
  },
  beforeUnmount() {
    this.$bus.$off('toast.display');
  },
  methods: {
    display(settings) {
      this.settings = Object.assign(this.defaultSettings, settings);
      setTimeout(
        () => this.close(),
        settings.duration || this.defaultSettings.duration
      );
    },
    close() {
      this.settings.message = '';
    }
  },
  computed: {
    ...mapGetters('app', {
      $showMenu: APP_GETTERS.SHOW_MENU
    }),
    visible() {
      return !!this.settings.message;
    },
    status() {
      return `header-toast--${this.settings.status}`;
    }
  }
};
</script>

<style lang="scss">
@import '@sass/_variables.scss';

.header-toast {
  position: fixed;
  top: calc(#{$header-height} * -2);
  right: 0;
  left: $side-menu-width;
  padding: 10px 20px;
  transition: top 0.2s ease-in;
  z-index: 994;
  color: $symptoms-white;

  @media only screen and (min-width: $breakpoint-desktop) {
    top: calc(#{$header-height-desktop} * -2);
  }

  &--success {
    background-color: $symptoms-green;
  }

  &--failure,
  &--error {
    background-color: $symptoms-red;
  }

  &--warning {
    background-color: $symptoms-blue;
  }

  &--visible {
    top: $header-height;

    @media only screen and (min-width: $breakpoint-desktop) {
      top: $header-height-desktop;
    }
  }

  &--fullwidth {
    left: 0;
  }

  &__close {
    position: absolute;
    top: 0px;
    right: 20px;
  }

  @media only screen and (max-width: 992px) {
    left: 0;
  }
}
</style>
