import { orderByKeys } from '@common/Helpers/sorters';
import SymptomDefinitionModel from '@common/Models/SymptomDefinitionModel';
import SymptomDefinitionService from '@common/Services/SymptomDefinitions/SymptomDefinitionService';
import {
  SYMPTOM_DEFINITION_ACTIONS,
  SYMPTOM_DEFINITION_GETTERS,
  SYMPTOM_DEFINITION_MUTATIONS
} from './definitions';

class State {
  constructor() {
    /** @type {SymptomDefinitionModel[]} */
    this.list = [];
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  [SYMPTOM_DEFINITION_MUTATIONS.SET_SYMPTOM_DEFINITIONS](
    state,
    symptomDefinitions
  ) {
    state.list = symptomDefinitions;
  },
  [SYMPTOM_DEFINITION_MUTATIONS.ADD_SYMPTOM_DEFINITION](
    state,
    symptomDefinition
  ) {
    const exists = state.list.findIndex(s => s.Id === symptomDefinition.Id);

    if (exists === -1) {
      state.list.push(symptomDefinition);
    } else {
      state.list[exists] = symptomDefinition;
    }
  },
  [SYMPTOM_DEFINITION_MUTATIONS.REMOVE_SYMPTOM_DEFINITION](
    state,
    symptomDefinition
  ) {
    const index = state.list.findIndex(s => s.Id === symptomDefinition.Id);
    if (index > -1) {
      state.list.splice(index, 1);
    }
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [SYMPTOM_DEFINITION_ACTIONS.CREATE_SYMPTOM_DEFINITION](
    { commit },
    symptomDefinition
  ) {
    return SymptomDefinitionService.addMySymptomDefinition(
      symptomDefinition
    ).then(newSymptomDefinition => {
      commit(
        SYMPTOM_DEFINITION_MUTATIONS.ADD_SYMPTOM_DEFINITION,
        newSymptomDefinition
      );
      return newSymptomDefinition;
    });
  },

  [SYMPTOM_DEFINITION_ACTIONS.GET_MY_SYMPTOM_DEFINITIONS]({ commit }) {
    return SymptomDefinitionService.getMySymptomDefinitions()
      .then(symptomDefinitions =>
        commit(
          SYMPTOM_DEFINITION_MUTATIONS.SET_SYMPTOM_DEFINITIONS,
          symptomDefinitions
        )
      )
      .catch(error => {
        console.error(error);
      });
  },

  [SYMPTOM_DEFINITION_ACTIONS.REMOVE_SYMPTOM_DEFINITION](
    { commit },
    symptomDefinition
  ) {
    commit(
      SYMPTOM_DEFINITION_MUTATIONS.REMOVE_SYMPTOM_DEFINITION,
      symptomDefinition
    );
    return SymptomDefinitionService.deleteMySymptomDefinition(
      symptomDefinition.Id
    );
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [SYMPTOM_DEFINITION_GETTERS.SYMPTOM_DEFINITIONS](state) {
    return orderByKeys(
      state.list,
      ['isCreatedByPatient', 'Index'],
      ['asc', 'asc']
    );
  },
  [SYMPTOM_DEFINITION_GETTERS.SNOMED_SYMPTOM_DEFINITIONS](state) {
    const snomedDefinitions = state.list.filter(sd => sd.IsFromSnomed);

    return orderByKeys(
      snomedDefinitions,
      ['isCreatedByPatient', 'Index'],
      ['asc', 'asc']
    );
  },
  [SYMPTOM_DEFINITION_GETTERS.NON_SNOMED_SYMPTOM_DEFINITIONS](state) {
    const snomedDefinitions = state.list.filter(sd => !sd.IsFromSnomed);

    return orderByKeys(
      snomedDefinitions,
      ['isCreatedByPatient', 'Index'],
      ['asc', 'asc']
    );
  },
  [SYMPTOM_DEFINITION_GETTERS.HAS_SYMPTOM_DEFINITION]:
    state => symptomDefinition => {
      return state.list.find(
        s => s.Name?.toLowerCase() == symptomDefinition.Name?.toLowerCase()
      );
    }
};

export {
  SYMPTOM_DEFINITION_ACTIONS,
  SYMPTOM_DEFINITION_GETTERS,
  SYMPTOM_DEFINITION_MUTATIONS
} from './definitions';

export default store;
