// @ts-check
import { utcToLocal } from '@common/Helpers/time';
import moment from 'moment';
import { maxLength, minLength, required } from '@vuelidate/validators';

export class NewsItemModel {
  constructor({
    Id,
    AuthorName = '',
    CanUpdate,
    Title = '',
    Content = '',
    CreatedDate = null,
    ModifiedDate = null,
    PublishDate = null,
    Priority = 'not-important',
    Version = 0
  }) {
    this._modelName = 'NewsItem';
    this.Id = Id || null;
    this.AuthorName = AuthorName;
    this.CanUpdate = CanUpdate;
    this.Title = Title;
    this.Content = Content;
    this.CreatedDate = CreatedDate ? utcToLocal(CreatedDate) : moment();
    this.ModifiedDate = ModifiedDate ? utcToLocal(ModifiedDate) : moment();
    this.PublishDate = PublishDate ? utcToLocal(PublishDate) : moment();
    this.Priority = Priority;
    this.Version = Version;
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }

  static get validations() {
    return {
      Title: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(128)
      },
      Content: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(2048)
      },
      AuthorName: {}
    };
  }
}

export default NewsItemModel;
