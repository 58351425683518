<template>
  <div>
    <s-text-input
      :label="toHtml(answer.Text)"
      type="number"
      :model-value="answertext"
      :disabled="disabled"
      @keyup="setAnswer($event.target.value)"
    />
    <errors :field="v$.answertext" />
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import useVuelidate from '@vuelidate/core';
import AnswerModel from '@common/Models/AnswerModel';
import { toHtml } from '@common/Helpers/markdown';
import FormSetup from '@common/Mixins/FormSetup';
import { minValue, maxValue } from '@vuelidate/validators';
export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  props: [
    'answer',
    'answerInput',
    'question',
    'disabled',
    'isAnswer',
    'isNotSavedAnswer',
    'type',
    'index'
  ],
  mixins: [FormSetup],
  data() {
    return {};
  },
  mounted() {},
  watch: {
    answertext() {
      this.$_FormSetup_setupForm();
    }
  },
  validations() {
    return {
      answertext: {
        maxValue:
          this.question.MaxValue == null
            ? true
            : maxValue(this.question.MaxValue),
        minValue:
          this.question.MinValue == null
            ? true
            : minValue(this.question.MinValue)
      }
    };
  },
  methods: {
    toHtml,
    setAnswer(value) {
      if (value.length) {
        const editedAnswer = new AnswerModel(this.answer.__copy());
        editedAnswer.Value = Number(value);

        this.$emit('setAnswer', editedAnswer);
      } else {
        this.$emit('removeAnswer', this.answer);
      }
    }
  },
  computed: {
    answertext() {
      return this.answerInput ? this.answerInput.Value : '';
    }
  }
};
</script>

<style lang="css"></style>
