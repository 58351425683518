<template>
  <div>
    <no-login-template-vue>
      <privacy-view-vue></privacy-view-vue>
    </no-login-template-vue>
  </div>
</template>

<script>
import PrivacyViewVue from '@common/views/PrivacyView.vue';
import NoLoginTemplateVue from '@common/Templates/NoLoginTemplate.vue';

export default {
  components: {
    NoLoginTemplateVue,
    PrivacyViewVue
  }
};
</script>

<style lang="scss">
.privacy-no-login {
  .container {
    padding: 0 !important;
  }
}
</style>
