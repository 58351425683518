import InviteModel from '@common/Models/InviteModel';

/**
 * Map remote Invite to local
 * @param {object} dtoInvite
 * @returns {InviteModel}
 */
export const InviteResponse = dtoInvite => {
  return new InviteModel(dtoInvite);
};

/**
 * Map remote Invites to local
 * @param {object[]} dtoInvites
 * @returns {InviteModel[]}
 */
export const InvitesResponse = dtoInvites => {
  return dtoInvites.map(dto => InviteResponse(dto));
};
