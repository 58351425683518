<template>
  <div>
    <alert-share-request-vue
      v-if="DISPLAY_NEW_SHAREREQUEST"
      :show="DISPLAY_NEW_SHAREREQUEST"
      @close="DISPLAY_NEW_SHAREREQUEST = false"
      :share-id="modalData"
    ></alert-share-request-vue>
  </div>
</template>

<script>
import { ALERT_ACTIONS } from '@/store/modules/alerts';
import AlertShareRequestVue from './Types/AlertShareRequest.vue';

export default {
  components: { AlertShareRequestVue },
  data() {
    const types = {};
    types[ALERT_ACTIONS.DISPLAY_NEW_SHAREREQUEST] = false;
    return {
      modalData: null,
      ...types
    };
  },
  mounted() {
    this.$store.subscribeAction((action, state) => {
      const [namespace, type] = action.type.split('/');
      if (namespace !== 'alerts') {
        return;
      }

      this[type] = true;
      if (
        type === ALERT_ACTIONS.DISPLAY_NEW_SHAREREQUEST &&
        this.$route.name !== 'shares'
      ) {
        this.modalData = action.payload.ShareId;
      }
    });
  },
  computed: {}
};
</script>

<style></style>
