<template>
  <card
    v-if="group"
    :image="imageUrl"
    :key="`${group.Id}/${group.OrganizationId}`"
  >
    <template v-slot:image-content>
      <span>{{ group.Name }}</span>
    </template>

    <template v-slot:header>{{ group.FormTitle }}</template>
    <template v-slot:text>{{ group.FormText }}</template>

    <template v-slot:button>
      <div style="text-align: center" v-if="showSymptomsReportButton">
        <s-button
          data-cy="button-how-are-you-feeling"
          class="button--blue"
          @click="reportSymptoms(group)"
          >{{ $t('views.start.reportSymptoms') }}</s-button
        >
      </div>

      <div v-if="group.GroupCardPromId" style="text-align: center" class="mt20">
        <s-button
          data-cy="button-update-covid-form"
          class="button--blue mt-10"
          @click="reportProm(group)"
          >{{ group.FormButtonLabel }}</s-button
        >
      </div>
    </template>
  </card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  CURRENT_USER_ACTIONS,
  CURRENT_USER_GETTERS
} from '@/store/modules/currentuser';

export default {
  props: {
    group: {
      required: true
    },
    showSymptomsReportButton: {
      required: false,
      default: true
    }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions('currentuser', {
      $setActiveGroup: CURRENT_USER_ACTIONS.SET_ACTIVE_GROUP
    }),
    async setGroupIfNotAlreadySet(group) {
      if (
        this.$activeGroup.Id !== group.Id &&
        this.$activeGroup.OrganizationId !== group.OrganizationId
      ) {
        const role = 'pat';
        await this.$setActiveGroup({ group, role });
      }
    },
    reportSymptoms(group) {
      this.setGroupIfNotAlreadySet(group);
      this.$router.push({ name: 'symptomReport' });
    },
    reportProm(group) {
      this.setGroupIfNotAlreadySet(group);
      this.$router.push({
        name: 'promReportEdit',
        params: { promid: group.GroupCardPromId }
      });
    }
  },
  computed: {
    ...mapGetters('currentuser', {
      $activeGroup: CURRENT_USER_GETTERS.ACTIVE_GROUP
    }),
    imageUrl() {
      const orgId = this.group.OrganizationId.toLowerCase().replace(' ', '');
      const groupId = this.group.Id.toLowerCase().replace(' ', '');
      return `/images/groups/${orgId}_${groupId}.jpg`;
    }
  }
};
</script>

<style></style>
