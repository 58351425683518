import ParticipantModel from '@common/Models/ParticipantModel';

/**
 * Map remote Participant to local
 * @param {object} dtoParticipant
 * @returns {ParticipantModel}
 */
export const ParticipantResponse = dtoParticipant => {
  return new ParticipantModel(dtoParticipant);
};

/**
 * Map remote Participants to local
 * @param {object[]} dtoParticipants
 * @returns {ParticipantModel[]}
 */
export const ParticipantsResponse = dtoParticipants => {
  return dtoParticipants.map(dto => ParticipantResponse(dto));
};
