import { isRemoteId } from '@common/Helpers/api';
import { sortByCreatedDate } from '@common/Helpers/sorters';
import storage from '@common/Helpers/storage';
import SymptomReportModel from '@common/Models/SymptomReport/SymptomReportModel';
import SymptomReportService from '@common/Services/SymptomReports/SymptomReportService';
import { SymptomReportResponse } from '@common/Services/SymptomReports/mappers/SymptomReportResponses';
import { SYMPTOM_ACTIONS } from '../symptoms';
import {
  SYMPTOMREPORT_ACTIONS,
  SYMPTOMREPORT_GETTERS,
  SYMPTOMREPORT_MUTATIONS
} from './definitions';

class State {
  constructor() {
    /** @type {SymptomReportModel[]} */
    this.list = [];
    /** @type { Object.<string, SymptomReportModel> } */
    this.unsavedReport = null;
    this.showThankYouModal = false;
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  [SYMPTOMREPORT_MUTATIONS.SET_REPORTS](state, reports) {
    state.list = reports;
  },

  [SYMPTOMREPORT_MUTATIONS.SET_SHOW_THANK_YOU_MODAL](state, value) {
    state.showThankYouModal = value;
  },

  [SYMPTOMREPORT_MUTATIONS.ADD_NEW_SYMPTOM_REPORT](state, report) {
    const exists = state.list.find(s => s.Id === report.Id);

    if (!exists) {
      state.list.push(report);
    } else {
      Object.assign(exists, report);
    }

    if (!report.isFromRemote) {
      storage.setEncrypted(`symptomreport/${report.Id}`, report);
    }
  },

  /**
   *
   * @param {State} state
   * @param {Object} obj
   * @param {String} obj.statusReportId
   * @param {SymptomReportModel} obj.report
   */
  [SYMPTOMREPORT_MUTATIONS.SET_UNSAVED_REPORT](state, report) {
    report.Symptoms.forEach(x => {
      x._lastValue = x.Intensity;
    });

    state.unsavedReport = report;
  },

  /**
   *
   * @param {State} state
   * @param {Object} obj
   * @param {String} obj.statusReportId
   * @param {SymptomReportModel} obj.report
   */
  [SYMPTOMREPORT_MUTATIONS.REMOVE_UNSAVED_REPORT](state) {
    state.unsavedReport = null;
  },

  [SYMPTOMREPORT_MUTATIONS.REMOVE_REPORT](state, report) {
    const index = state.list.findIndex(s => s.Id === report.Id);

    if (index === -1) {
      return;
    }

    state.list.splice(index, 1);
    storage.remove(`symptomreport/${report.Id}`);
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [SYMPTOMREPORT_ACTIONS.$PREINIT]({ commit, dispatch }, symptomreports) {
    let unsavedSymptomReports = storage.find('symptomreport', true);
    if (unsavedSymptomReports.length > 0) {
      unsavedSymptomReports = unsavedSymptomReports.map(report =>
        SymptomReportResponse(report)
      );
      commit(SYMPTOMREPORT_MUTATIONS.SET_REPORTS, unsavedSymptomReports);
    }

    if (symptomreports) {
      commit(SYMPTOMREPORT_MUTATIONS.SET_REPORTS, symptomreports);
    } else {
      dispatch(SYMPTOMREPORT_ACTIONS.GET_MY_SYMPTOM_REPORTS);
    }
  },

  [SYMPTOMREPORT_ACTIONS.GET_MY_SYMPTOM_REPORTS]({ commit }) {
    return SymptomReportService.getMySymptomReportsFromLastMonth().then(
      symptomreports =>
        commit(SYMPTOMREPORT_MUTATIONS.SET_REPORTS, symptomreports)
    );
  },

  [SYMPTOMREPORT_ACTIONS.GET_MY_SYMPTOM_REPORT]({ commit }, id) {
    return SymptomReportService.findMySymptomReport(id).then(symptomreport => {
      commit(SYMPTOMREPORT_MUTATIONS.ADD_NEW_SYMPTOM_REPORT, symptomreport);
      return symptomreport;
    });
  },

  [SYMPTOMREPORT_ACTIONS.ADD_OR_UPDATE_REPORT](
    { commit, state, dispatch },
    report
  ) {
    if (report.isFromRemote) {
      return SymptomReportService.updateMySymptomReport(report).then(report => {
        commit(SYMPTOMREPORT_MUTATIONS.REMOVE_UNSAVED_REPORT);
        return dispatch(`symptoms/${SYMPTOM_ACTIONS.GET_MY_SYMPTOMS}`, null, {
          root: true
        });
      });
    } else {
      return SymptomReportService.addMySymptomReport(report).then(newreport => {
        commit(SYMPTOMREPORT_MUTATIONS.ADD_NEW_SYMPTOM_REPORT, newreport);
        commit(SYMPTOMREPORT_MUTATIONS.REMOVE_REPORT, report);
        commit(SYMPTOMREPORT_MUTATIONS.REMOVE_UNSAVED_REPORT);
        return dispatch(`symptoms/${SYMPTOM_ACTIONS.GET_MY_SYMPTOMS}`, null, {
          root: true
        });
      });
    }
  },

  [SYMPTOMREPORT_ACTIONS.REMOVE_REPORT]({ commit, dispatch }, report) {
    if (!report.isFromRemote) {
      commit(SYMPTOMREPORT_MUTATIONS.REMOVE_REPORT, report);
      return Promise.resolve(report);
    }

    return SymptomReportService.deleteMySymptomReport(report.Id).then(
      response => {
        commit(SYMPTOMREPORT_MUTATIONS.REMOVE_REPORT, report);
        dispatch(`symptoms/${SYMPTOM_ACTIONS.GET_MY_SYMPTOMS}`, null, {
          root: true
        });
      }
    );
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [SYMPTOMREPORT_GETTERS.SYMPTOM_REPORTS](state) {
    return sortByCreatedDate(state.list).reverse();
  },
  [SYMPTOMREPORT_GETTERS.FIND_SYMPTOM_REPORT]: state => Id => {
    if (!isRemoteId(Id) && state.list.find(s => s.Id === Id)) {
      const savedReport = storage.getEncrypted(`symptomreport/${Id}`) || null;
      return SymptomReportResponse(savedReport);
    }
    return state.list.find(s => s.Id === Id) || null;
  },
  [SYMPTOMREPORT_GETTERS.UNSAVED_REPORT](state) {
    return state.unsavedReport;
  },
  [SYMPTOMREPORT_GETTERS.SHOW_THANK_YOU_MODAL](state) {
    return state.showThankYouModal;
  }
};

export {
  SYMPTOMREPORT_ACTIONS,
  SYMPTOMREPORT_GETTERS,
  SYMPTOMREPORT_MUTATIONS
} from './definitions';

export default store;
