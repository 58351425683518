import { randomString } from '@common/Helpers/strings';
import { required } from '@vuelidate/validators';

// @ts-check
export class InfoModel {
  constructor({ Id, Text, Index, Level = 1, Version }) {
    this._modelName = 'Info';
    this.Id = Id;
    this.Text = Text;
    this.Index = Index;
    this.Level = Level;
    this.Version = Version || 0;

    this._sortableKey = randomString();
  }

  static get validations() {
    return {
      Text: {
        required
      },
      Id: {
        required
      },
      Index: {
        required
      }
    };
  }
}

export default InfoModel;
