import axios from 'axios';
export function http(token) {
  const instance = axios.create({
    baseURL: 'https://api.papapi.se/lite',
    timeout: 1000
  });

  // Add token to url
  instance.interceptors.request.use(function (config) {
    config.url += `&apikey=${token}&format=json`;
    return config;
  });

  return instance;
}
