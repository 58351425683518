export const randomString = (len = 30) => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
};

export const deCapitalizeFirstLetter = string => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const validDate = date => {
  return Date.parse(date) > 0;
};

export const compare = (lhs, op, rhs) => {
  switch (op) {
    case -1:
      return lhs < rhs;
    case 0:
      return lhs === rhs;
    case 1:
      return lhs > rhs;
  }
};

/**
 * Fix personnummer
 * @param {String} personnummer
 * @returns
 */
export const fixPersonnummer = personnummer => {
  let pnr = personnummer;
  let fixedPersonnummer = pnr;

  if (!pnr) {
    return;
  }

  if (pnr.length === 12) {
    if (!pnr.match(/-/)) {
      return `${pnr.slice(0, 8)}-${pnr.slice(8)}`;
    }
  }

  // remove any non-numbers or dashes
  for (let i = pnr.length - 1; i >= 0; i--) {
    // dashes are ok at 9th character
    if (i === 8 && pnr[i] === '-') {
      continue;
    }
    if (!'0123456789'.includes(pnr[i])) {
      var result = pnr.split('');
      result.splice(i, i + 1);
      pnr = result.join('');
      fixedPersonnummer = pnr;
    }
  }

  // replace personnummer with '19x' or '20x' as soon as we
  // know the user is not trying to fill out '19' or '20'
  if (pnr.length === 1) {
    if (pnr[0] === 0) {
      fixedPersonnummer = '20' + pnr;
    } else if (pnr[0] > 2) {
      fixedPersonnummer = '19' + pnr;
    }
    return fixedPersonnummer;
  }

  // don't bother with strings that are too short or too long
  if (pnr.length < 2 || pnr.length > 10) {
    return fixedPersonnummer;
  }

  // add dash at index 8 if it's missing
  if (pnr.length > 8 && !pnr.includes('-')) {
    fixedPersonnummer = pnr = pnr.substring(0, 8) + '-' + pnr.substring(8);
  }

  // if personnummer starts with '19' or '20', there's no need to continue here
  if ((pnr[0] === 1 && pnr[1] === 9) || (pnr[0] === 2 && pnr[1] === 0)) {
    return fixedPersonnummer;
  }

  // make substitutions at beginning of string to create '19...' or '20...' personnummer
  if (pnr[0] === 2 && pnr[1] !== 0) {
    fixedPersonnummer = '19' + pnr;
  } else if (pnr[0] === 1 && pnr[1] !== 9) {
    fixedPersonnummer = '20' + pnr;
  } else if (pnr[0] > 2) {
    fixedPersonnummer = '19' + pnr;
  } else if (pnr[0] === 0) {
    fixedPersonnummer = '20' + pnr;
  }

  return fixedPersonnummer;
};

/**
 * @param {String} pnr
 * @returns
 */
export const maskPersonnummer = pnr => {
  const [start, end] = pnr.split('-');
  return `${start}-XXXX`;
};

/**
 * @param {String} pnr
 * @returns
 */
export const trimPersonnummer = pnr => {
  return pnr.replace('-', '');
};

/**
 * @param {String} pnr
 * @returns
 */
export const hasValidControlDigit = pnr => {
  let sum = 0;
  let digit;
  for (let i = 0; i < pnr.length - 1; i++) {
    digit = parseInt(pnr.charAt(i), 10);
    if (i % 2 === 0) {
      digit *= 2;
    }
    if (digit > 9) {
      digit -= 9;
    }
    sum += digit;
  }
  const calculatedControlNumber = (10 - (sum % 10)) % 10;
  const controlNumber = parseInt(pnr.substring(9, 10));

  return calculatedControlNumber === controlNumber;
};

/**
 * Get OS from string
 * @param {String} osString
 */
export const getOs = osString => {
  if (osString.match(/windows/i)) {
    return 'windows';
  } else if (osString.match(/ios/i)) {
    return 'ios';
  } else if (osString.match(/android/i)) {
    return 'android';
  }
};

/**
 * Get Device from string
 * @param {String} deviceString
 */
export const getDevice = deviceString => {
  if (deviceString.match(/other/i)) {
    return 'computer';
  } else if (deviceString.match(/iphone|smartphone/i)) {
    return 'cellphone';
  }
};

/**
 * Get Browser from string
 * @param {String} browserString
 */
export const getBrowser = browserString => {
  if (browserString.match(/chrome/i)) {
    return 'chrome';
  } else if (browserString.match(/safari/i)) {
    return 'safari';
  } else if (browserString.match(/firefox/i)) {
    return 'firefox';
  }
};
