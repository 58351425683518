import { AUTH_GETTERS } from '@/store/modules/auth';
import { CURRENT_USER_ACTIONS } from '@/store/modules/currentuser';
import { NOTES_ACTIONS } from '@/store/modules/notes';
import { PROMREPORT_ACTIONS } from '@/store/modules/promreports';
import { PROM_ACTIONS } from '@/store/modules/proms';
import { SHARE_ACTIONS } from '@/store/modules/shares';
import { SYMPTOMREPORT_ACTIONS } from '@/store/modules/symptomreports';
import { SYMPTOM_ACTIONS } from '@/store/modules/symptoms';
import { sortByValueKey } from '@common/Helpers/sorters';
import storage from '@common/Helpers/storage';
import StatusTypeModel from '@common/Models/StatusTypeModel';
import ManikinModel from '@common/Models/ManikinModel';
import EnvironmentService from '@common/Services/Environments/EnvironmentService';
import ViewService from '@common/Services/Views/ViewService';
import { LANGUAGE_MUTATIONS } from '../languages';
import { NEWS_ACTIONS } from '../news';
import { APP_ACTIONS, APP_GETTERS, APP_MUTATIONS } from './definitions';

class State {
  constructor() {
    this.initialized = false;
    this.constants = {};
    this.statustypes = {};
    this.encryption_key = null;
    this.connection = true;
    this.availableModels = [
      new ManikinModel({
        Model: 'male.gltf',
        Text: 'Male',
        Id: 'male'
      }),
      new ManikinModel({
        Model: 'female.gltf',
        Text: 'Female',
        Id: 'female'
      })
    ];
    this.page_settings = {
      show_header: true,
      show_float_content: false,
      show_modal: false,
      show_large_menu: false,
      show_menu: true
    };
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  /**
   * Set constants
   * @param {Object} constants
   */
  [APP_MUTATIONS.SET_CONSTANTS](state, constants) {
    state.constants = constants;
  },

  /**
   * Set StatusTypes
   * @param {Object} statustypes
   */
  [APP_MUTATIONS.SET_STATUSTYPES](state, statustypes) {
    state.statustypes = statustypes;
  },

  /**
   * Set encryption key
   * @param {string} key
   */
  [APP_MUTATIONS.SET_KEY](state, key) {
    state.encryption_key = key;
  },

  /**
   * Set app init state
   * @param {boolean} initialized
   */
  [APP_MUTATIONS.SET_INITIALIZED](state, initialized) {
    state.initialized = initialized;
  },

  /**
   * Set page settings
   * @param {Object} setting
   * @param {string} setting.option
   * @param {string|boolean} setting.value
   */
  [APP_MUTATIONS.SET_PAGE_SETTING](state, { option, value }) {
    state.page_settings[option] = value;
  },

  /**
   * Set page settings
   * @param {Boolean} connected
   */
  [APP_MUTATIONS.SET_CONNECTION](state, connected) {
    state.connection = connected;
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  /** @param {Boolean} forceInit */
  async [APP_ACTIONS.$PREINIT](context, forceInit = false) {
    if (context.state.initialized === true && forceInit === false) {
      return;
    }
    console.log('app init');

    const environment = await ViewService.getEnvironment().then(
      environment => environment
    );
    await context.commit(
      `languages/${LANGUAGE_MUTATIONS.SET_LANGUAGES}`,
      environment.Languages,
      {
        root: true
      }
    );
    await context.dispatch(
      APP_ACTIONS.GET_STATUSTYPES,
      environment.StatusTypes
    );
    await context.dispatch(APP_ACTIONS.GET_CONSTANTS, environment.Constants);

    await context.dispatch(
      `news/${NEWS_ACTIONS.GET_PUBLISHED_NEWS_ITEMS}`,
      {},
      { root: true }
    );

    const activeRole = context.rootGetters[`auth/${AUTH_GETTERS.ACTIVE_ROLE}`];

    await context.dispatch(
      `currentuser/${CURRENT_USER_ACTIONS.$PREINIT}`,
      null,
      {
        root: true
      }
    );

    await context.dispatch(`proms/${PROM_ACTIONS.$PREINIT}`, null, {
      root: true
    });

    if (activeRole === 'pat') {
      await context.dispatch(`notes/${NOTES_ACTIONS.$PREINIT}`, null, {
        root: true
      });

      await context.dispatch(
        `symptomreports/${SYMPTOMREPORT_ACTIONS.$PREINIT}`,
        null,
        {
          root: true
        }
      );

      await context.dispatch(`symptoms/${SYMPTOM_ACTIONS.$PREINIT}`, null, {
        root: true
      });

      await context.dispatch(
        `promreports/${PROMREPORT_ACTIONS.$PREINIT}`,
        null,
        {
          root: true
        }
      );

      await context.dispatch(
        `shares/${SHARE_ACTIONS.$PREINIT}`,
        {},
        { root: true }
      );
    }

    context.commit(APP_MUTATIONS.SET_INITIALIZED, true);
  },

  [APP_ACTIONS.GET_CONSTANTS]({ commit }, constants) {
    const storedConstants = storage.get('app/constants');

    if (storedConstants) {
      commit(APP_MUTATIONS.SET_CONSTANTS, storedConstants);
      return Promise.resolve();
    }

    if (constants) {
      storage.set('app/constants', constants);
      return commit(APP_MUTATIONS.SET_CONSTANTS, constants);
    }

    return EnvironmentService.getConstants().then(constants => {
      commit(APP_MUTATIONS.SET_CONSTANTS, constants);
      storage.set('app/constants', constants);
    });
  },

  [APP_ACTIONS.GET_STATUSTYPES]({ commit }, statusTypes) {
    const storedTypes = storage.get('app/statustypes');

    if (storedTypes) {
      commit(
        APP_MUTATIONS.SET_STATUSTYPES,
        storedTypes.map(type => new StatusTypeModel(type))
      );
      return Promise.resolve();
    }

    if (statusTypes) {
      storage.set('app/statustypes', statusTypes);
      return commit(APP_MUTATIONS.SET_STATUSTYPES, statusTypes);
    }

    return EnvironmentService.getStatusTypes().then(types => {
      commit(APP_MUTATIONS.SET_STATUSTYPES, types);
      storage.set('app/statustypes', types);
    });
  },

  [APP_ACTIONS.DISPLAY_TOAST]({ commit }, toast) {}
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  /**
   * Get available Manikins
   * @returns {ManikinModel[]}
   */
  [APP_GETTERS.AVAILABLE_MODELS](state) {
    return state.availableModels;
  },

  /**
   * Get constants
   * @returns {Object}
   */
  [APP_GETTERS.CONSTANTS](state) {
    return state.constants;
  },

  /**
   * Show page header
   * @returns {boolean}
   */
  [APP_GETTERS.SHOW_HEADER](state) {
    return state.page_settings.show_header;
  },

  /**
   * Show float content
   * @returns {boolean}
   */
  [APP_GETTERS.SHOW_FLOAT_CONTENT](state) {
    return state.page_settings.show_float_content;
  },

  /**
   * Show modal
   * @returns {boolean}
   */
  [APP_GETTERS.SHOW_MODAL](state) {
    return state.page_settings.show_modal;
  },

  /**
   * Show large menu
   * @returns {boolean}
   */
  [APP_GETTERS.SHOW_LARGE_MENU](state) {
    return state.page_settings.show_large_menu;
  },

  /**
   * Show menu
   * @returns {boolean}
   */
  [APP_GETTERS.SHOW_MENU](state) {
    return state.page_settings.show_menu;
  },

  /**
   * Show new version toast
   * @returns {boolean}
   */
  [APP_GETTERS.SHOW_NEW_VERSION](state) {
    return state.page_settings.show_new_version;
  },

  /**
   * Large viewport
   * @returns {boolean}
   */
  [APP_GETTERS.LARGE_VIEWPORT](state) {
    return window.innerWidth >= 992;
  },

  /**
   * Get StatusReportTypes
   * @returns {Object}
   */
  [APP_GETTERS.STATUS_REPORT_TYPES](state) {
    return state.constants.StatusReportTypes || [];
  },

  /**
   * Get StatusReportTypes as a sorted array
   * @returns {Array}
   */
  [APP_GETTERS.STATUS_REPORT_TYPES_SORTED_ARRAY](state) {
    return sortByValueKey(state.statustypes, 'Index').map(type => type.Name);
  },

  /**
   * Get connection to backend
   * @returns {Boolean}
   */
  [APP_GETTERS.CONNECTED](state) {
    return state.connection;
  },

  [APP_GETTERS.ROLES](state) {
    return state.constants.RoleNames;
  },
  [APP_GETTERS.ROLES_ARRAY](state, getters) {
    if (!getters.roles) {
      return;
    }
    const roles = Object.keys(getters.roles).map(key => ({
      value: key,
      text: getters.roles[key]
    }));

    return sortByValueKey(roles, 'text');
  },
  [APP_GETTERS.SCOPES](state) {
    return state.constants.Scopes;
  },
  [APP_GETTERS.SCOPES_ARRAY](state, getters) {
    if (!getters.SCOPES) {
      return;
    }
    const scopes = Object.keys(getters.SCOPES).map(key => ({
      value: key,
      text: getters.SCOPES[key]
    }));

    return sortByValueKey(scopes, 'text');
  },

  /**
   * Get symptom types
   * @returns {Boolean}
   */
  [APP_GETTERS.SYMPTOM_TYPES](state) {
    const types = state.constants.SymptomTypes || {};
    return Object.keys(types).reduce((p, c) => {
      p.push({ value: c, display: types[c] });
      return p;
    }, []);
  }
};

export { APP_ACTIONS, APP_GETTERS, APP_MUTATIONS } from './definitions';

export default store;
