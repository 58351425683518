import BaseService from '../BaseService';
import LanguageStringModel from '@common/Models/LanguageStringModel';
import {
  LanguageStringResponse,
  LanguageStringsResponse
} from './mappers/LanguageStringResponses';

class LanguageStringService extends BaseService {
  /**
   * Get language strings -- identity
   * @returns {Promise<LanguageStringModel[]>}
   * @memberof LanguageStringService
   */
  getMyGroupsLanguageStrings(languageId) {
    return this.identity(`language-strings/${languageId}`).then(response =>
      LanguageStringsResponse(response.data)
    );
  }

  /**
   * Add new language string
   * @param {LanguageStringModel} string
   * @returns {Promise<LanguageStringModel>}
   * @memberof LanguageStringService
   */
  addLanguageString(string) {
    return this.identity('post', 'language-strings', string).then(response =>
      LanguageStringResponse(response.data)
    );
  }

  /**
   * Update language string
   * @param {LanguageStringModel} string
   * @returns {Promise<LanguageStringResponse}
   * @memberof LanguageStringService
   */
  updateLanguageString(string) {
    return this.identity(
      'put',
      `language-strings/${string.Id}/${string.LanguageId}`,
      string
    ).then(response => LanguageStringResponse(response.data));
  }

  /**
   * Delete language string
   * @param {LanguageStringModel} string
   * @returns {Promise}
   * @memberof LanguageStringService
   */
  deleteLanguageString(string) {
    return this.identity('delete', `language-strings/${string.Id}`);
  }

  /**
   * Delete language string version
   * @param {LanguageStringModel} string
   * @returns {Promise}
   * @memberof LanguageStringService
   */
  deleteLanguageStringVersion(string) {
    return this.identity(
      'delete',
      `language-strings/${string.Id}/${string.LanguageId}`
    );
  }
}

export default new LanguageStringService();
