<template v-if="report">
  <div data-cy="statusreport">
    <status-report-header class="hidden-desktop"></status-report-header>
    <div class="container status-report">
      <h4 class="page-header">
        {{
          newSymptom
            ? $t('generic.save') + ' ' + $tc('generic.Symptom', 2)
            : $t('questions.howDoYouFeelNow')
        }}
      </h4>
      <div class="grey-text">
        {{
          newSymptom
            ? $t('views.statusReport.newSymptomText')
            : report && report.Symptoms.length
              ? $t('views.statusReport.initialHelpText')
              : $t('views.statusReport.emptyList')
        }}
      </div>
      <symptom-list-vue
        v-if="report"
        :edited-report="report"
        ref="symptoms"
        @closed-symptom="saveTempReport()"
        @save-temp="saveTempReport"
        :disabled="report._locked"
      ></symptom-list-vue>
    </div>

    <float-content>
      <s-button
        @click="cancelReport"
        id="ButtonCancel"
        element-name="statusreport-cancelbutton"
        :dark="true"
        :flat="true"
        class="underline"
        >{{ $t('generic.cancel') }}</s-button
      >
      <div v-tippy="inactiveText">
        <s-button
          @click="nextStep()"
          id="ButtonSave"
          element-name="statusreport-savebutton"
          :loading="saveLoading"
          :primary="true"
          :disabled="nextInactive()"
          >{{ $t('generic.save') }}</s-button
        >
      </div>
    </float-content>

    <modal-confirm
      :show="confirmCancel"
      :accept-callback="
        () => {
          confirmedCancel = true;
          cancelReport();
        }
      "
      :accept-text="$t('generic.leave')"
      :show-extras="false"
      :decline-text="$t('generic.stay')"
      @close="confirmCancel = false"
    >
      <template v-slot:title>
        <span v-text="$t('modals.confirmLeaveReportTitle')"></span>
      </template>
      {{ $t('modals.confirmLeaveReport') }}
    </modal-confirm>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { APP_MUTATIONS } from '@/store/modules/app';
import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser';
import StatusReportHeader from '../StatusReport/components/StatusReportHeader.vue';
import SymptomListVue from './components/SymptomList.vue';
import {
  SYMPTOMREPORT_ACTIONS,
  SYMPTOMREPORT_GETTERS,
  SYMPTOMREPORT_MUTATIONS
} from '../../store/modules/symptomreports';
import { SYMPTOM_ACTIONS, SYMPTOM_GETTERS } from '@/store/modules/symptoms';
import SymptomReportModel from '@common/Models/SymptomReport/SymptomReportModel';
import SymptomReportSymptomModel from '@common/Models/SymptomReport/SymptomReportSymptomModel';
import { randomString } from '@common/Helpers/strings';
import { isMobile } from '@common/Helpers/mobile';

export default {
  components: {
    StatusReportHeader,
    SymptomListVue
  },
  props: {},
  data() {
    return {
      report: null,
      saveLoading: false,
      confirmCancel: false,
      confirmedCancel: false,
      redirectOnCancel: null,
      useOldValues: true,
      subscription: null,
      unsavedReport: false,
      inactiveText: null
    };
  },
  beforeRouteLeave(to, from, next) {
    const keepUnsavedReport = [
      'symptomReport',
      'symptomReportEdit',
      'symptomReportDraw',
      'symptomReportGrade',
      'symptomReportNote',
      'symptomSelect'
    ].includes(to.name);

    if (
      !keepUnsavedReport &&
      this.report?.hasModifiedSymptoms &&
      this.confirmCancel === false &&
      this.confirmedCancel === false &&
      this.saveLoading === false
    ) {
      this.confirmCancel = true;
      this.redirectOnCancel = to;
      next(false);
    } else {
      if (keepUnsavedReport) {
        this.$setUnsavedReport(this.report);
      } else {
        this.$removeUnsavedReport();
      }
      next();
    }
  },
  watch: {},
  created() {
    this.getReport();
  },
  mounted() {
    if (isMobile()) {
      this.$setPageSettings({ option: 'show_menu', value: false });
    }
  },
  methods: {
    ...mapActions('symptoms', {
      $getMySymptoms: SYMPTOM_ACTIONS.GET_MY_SYMPTOMS
    }),
    ...mapActions('symptomreports', {
      $addOrUpdateSymptomReport: SYMPTOMREPORT_ACTIONS.ADD_OR_UPDATE_REPORT
    }),
    ...mapMutations('app', {
      $setPageSettings: APP_MUTATIONS.SET_PAGE_SETTING
    }),
    ...mapMutations('symptomreports', {
      $setUnsavedReport: SYMPTOMREPORT_MUTATIONS.SET_UNSAVED_REPORT,
      $removeUnsavedReport: SYMPTOMREPORT_MUTATIONS.REMOVE_UNSAVED_REPORT
    }),
    getReport() {
      if (this.$unsavedSymptomsReport) {
        this.loadTempReport();
      } else {
        this.$getMySymptoms().then(() => {
          this.createSymptomReport();
        });
      }
    },
    loadTempReport() {
      this.report = new SymptomReportModel(
        this.$unsavedSymptomsReport?.__copy()
      );
    },
    saveTempReport() {
      this.$setUnsavedReport(this.report);
      this.loadTempReport();
    },
    createSymptomReport() {
      this.report = new SymptomReportModel({
        Id: randomString(),
        Model: this.$getActiveSymptomModel.Id
      });

      this.$currentSymptoms.forEach(s => {
        const srs = new SymptomReportSymptomModel(s.lastReport.__copy());
        this.report.Symptoms.push(srs);
      });
    },
    nextStep() {
      this.saveLoading = true;
      return this.$addOrUpdateSymptomReport(this.report)
        .then(() => {
          return this.$router.replace({ name: 'start' }).then(() => {
            if (this.isFirstSymptomReport) {
              this.$bus.$emit('show-thank-you-modal', true);
            } else {
              var msg = {
                message: this.$root.$t('generic.saveSuccess'),
                status: 'success'
              };
              this.$bus.$emit('toast.display', msg);
            }
            this.$removeUnsavedReport();
          });
        })
        .catch(err => {
          const codeMsg = this.$root.$t(`error-codes.${err.ErrorCode}`);
          this.$bus.$emit('toast.display', {
            message: codeMsg.match('error-codes.')
              ? this.$root.$t('generic.saveFailure')
              : codeMsg,
            status: 'failure'
          });
        })
        .finally(() => (this.saveLoading = false));
    },
    cancelReport() {
      this.$removeUnsavedReport();

      if (this.redirectOnCancel) {
        this.$router.push(this.redirectOnCancel.path);
      } else {
        this.$router.replace({ name: 'start' }).catch(() => {});
      }
    },
    nextInactive() {
      if (!this.report) {
        this.inactiveText = this.$t('symptom-report.inactiveText.noReport');
        return true;
      }
      if (this.useOldValues === false && !this.report?.hasModifiedSymptoms) {
        this.inactiveText = this.$t('symptom-report.inactiveText.noChanges');
        return true;
      }
      if (this.report.Symptoms.some(x => x.Intensity == null)) {
        this.inactiveText = this.$t(
          'symptom-report.inactiveText.missingIntensity'
        );
        return true;
      }
      this.inactiveText = null;
      return false;
    }
  },
  computed: {
    ...mapGetters('currentuser', {
      $getActiveSymptomModel: CURRENT_USER_GETTERS.GET_ACTIVE_SYMPTOM_MODEL
    }),
    ...mapGetters('symptoms', {
      $currentSymptoms: SYMPTOM_GETTERS.CURRENT_SYMPTOMS
    }),
    ...mapGetters('symptomreports', {
      $unsavedSymptomsReport: SYMPTOMREPORT_GETTERS.UNSAVED_REPORT
    }),
    changedItems() {
      if (!this.report) {
        return;
      }

      return this.report.hasModifiedSymptoms;
    },
    newSymptom() {
      return this.$route.query.newSymptom;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';

.status-report {
  margin-bottom: -50px;
}
h4 {
  margin-bottom: $padding;
}
.box {
  padding: 0;
  padding-top: 0.7em;
  border-radius: 0;

  &:not(:first-of-type) {
    border-top: 1px solid $symptoms-black;
  }

  &:first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-of-type {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}
</style>
<style lang="scss">
.status-report {
  .card__content {
    width: 100% !important;
  }
  .scale-container {
    margin-bottom: 0 !important;
  }
}
</style>
