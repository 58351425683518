import BaseService from '../BaseService';
import AuditLogModel from '@common/Models/AuditLogModel';
import {
  AuditLogResponse,
  AuditLogsResponse
} from './mappers/AuditLogResponses';

class AuditLogService extends BaseService {
  /**
   * Get current user's AuditLogs -- backend
   * @param {number} skip
   * @param {number} take
   * @returns {Promise<AuditLogModel[]>}
   * @memberof AuditLogService
   */
  getMyAuditLogs(skip = 0, take = 20) {
    return this.backend(`my/auditlogs/${skip}/${take}`).then(response =>
      AuditLogsResponse(response.data)
    );
  }

  /**
   * Get AuditLogs by other users on current user's account -- backend
   * @param {number} skip
   * @param {number} take
   * @returns {Promise<AuditLogModel[]>}
   * @memberof AuditLogService
   */
  getMyAuditLogsExcludingSelf(skip = 0, take = 20) {
    return this.backend(`my/auditlogs/others/${skip}/${take}`).then(response =>
      AuditLogsResponse(response.data)
    );
  }

  /**
   * Get an AuditLog -- backend
   * @param {string} id AuditLog id
   * @returns {Promise<AuditLogModel>}
   * @memberof AuditLogService
   */
  findMyAuditLog(id) {
    return this.backend(`my/auditlogs/${id}`).then(response =>
      AuditLogResponse(response.data)
    );
  }
}

export default new AuditLogService();
