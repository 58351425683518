export class DashboardStatisticModel {
  constructor({
    UsersTotal,
    ActiveUsersToday,
    ActiveUsersLastWeek,
    ActiveUsersLastMonth,
    NewUsersToday,
    NewUsersLastWeek,
    NewUsersLastMonth,
    PromReportsTotal,
    PromReportsToday,
    PromReportsLastWeek,
    PromReportsLastMonth,
    StatusReportsTotal,
    StatusReportsToday,
    StatusReportsLastWeek,
    StatusReportsLastMonth,
    SymptomReportsTotal,
    SymptomReportsToday,
    SymptomReportsLastWeek,
    SymptomReportsLastMonth,
    PromStatisticsTotal,
    PromStatisticsToday,
    PromStatisticsLastWeek,
    PromStatisticsLastMonth,
    StatusStatistics
  }) {
    this.UsersTotal = UsersTotal;
    this.ActiveUsersToday = ActiveUsersToday;
    this.ActiveUsersLastWeek = ActiveUsersLastWeek;
    this.ActiveUsersLastMonth = ActiveUsersLastMonth;
    this.NewUsersToday = NewUsersToday;
    this.NewUsersLastWeek = NewUsersLastWeek;
    this.NewUsersLastMonth = NewUsersLastMonth;
    this.PromReportsTotal = PromReportsTotal;
    this.PromReportsToday = PromReportsToday;
    this.PromReportsLastWeek = PromReportsLastWeek;
    this.PromReportsLastMonth = PromReportsLastMonth;
    this.StatusReportsTotal = StatusReportsTotal;
    this.StatusReportsToday = StatusReportsToday;
    this.StatusReportsLastWeek = StatusReportsLastWeek;
    this.StatusReportsLastMonth = StatusReportsLastMonth;
    this.SymptomReportsTotal = SymptomReportsTotal;
    this.SymptomReportsToday = SymptomReportsToday;
    this.SymptomReportsLastWeek = SymptomReportsLastWeek;
    this.SymptomReportsLastMonth = SymptomReportsLastMonth;
    this.PromStatisticsTotal = PromStatisticsTotal;
    this.PromStatisticsToday = PromStatisticsToday;
    this.PromStatisticsLastWeek = PromStatisticsLastWeek;
    this.PromStatisticsLastMonth = PromStatisticsLastMonth;
    this.StatusStatistics = StatusStatistics;
  }
}

export default DashboardStatisticModel;
