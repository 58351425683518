import BaseService from '../BaseService';
import SymptomModel from '@common/Models/Symptom/SymptomModel';
import { SymptomResponse, SymptomsResponse } from './mappers/SymptomResponses';

class SymptomService extends BaseService {
  /**
   * Get current user's Symptoms -- backend
   * @returns {Promise<SymptomModel[]>}
   * @memberof SymptomService
   */
  getMySymptoms() {
    return this.backend('my/symptoms').then(response =>
      SymptomsResponse(response.data)
    );
  }

  /**
   * Get specific Symptom -- backend
   * @param {string} guid Symptom Id
   * @returns {Promise<SymptomModel>}
   * @memberof SymptomService
   */
  findMySymptom(guid) {
    return this.backend(`my/symptoms/${guid}`).then(response =>
      SymptomResponse(response.data)
    );
  }

  /**
   * Update symptom -- backend
   * @param {SymptomModel} symptom
   * @returns {Promise}
   * @memberof SymptomService
   */
  updateMySymptom(symptom) {
    return this.backend('put', `my/symptoms/${symptom.Id}`, symptom).then(
      response => SymptomResponse(response.data)
    );
  }

  /**
   * Mark symptom as inactive -- backend
   * @param {SymptomModel} symptom
   * @returns {Promise}
   * @memberof SymptomService
   */
  closeMySymptom(symptom) {
    return this.backend('post', `my/symptoms/${symptom.Id}/close`).then(
      response => SymptomResponse(response.data)
    );
  }
}

export default new SymptomService();
