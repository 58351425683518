// @ts-check
import { isRemoteId } from '@common/Helpers/api';
import { randomString } from '@common/Helpers/strings';
import { utcToLocal } from '@common/Helpers/time';
import StatusReportItemModel from '@common/Models/StatusReportItemModel';
import { StatusReportResponse } from '@common/Services/StatusReports/mappers/StatusReportResponses';
import moment from 'moment';

export class StatusReportModel {
  constructor({
    Id,
    PatientId,
    Items = [],
    PromSuggestions = [],
    CreatedDate,
    ModifiedDate,
    Version
  }) {
    this._modelName = 'StatusReport';
    this._icon = 'mdi-plus-circle-outline';

    this.Id = Id || randomString();
    this.PatientId = PatientId;
    this.Items = Items.map(i => new StatusReportItemModel(i));
    this.PromSuggestions = PromSuggestions;
    this.CreatedDate = CreatedDate ? utcToLocal(CreatedDate) : moment();
    this.ModifiedDate = ModifiedDate
      ? utcToLocal(ModifiedDate)
      : this.CreatedDate;
    this.Version = Version || 0;
  }

  __copy() {
    return StatusReportResponse(JSON.parse(JSON.stringify(this)));
  }

  get isFromRemote() {
    return isRemoteId(this.Id);
  }
}

export default StatusReportModel;
