// @ts-check
import { sortByCreatedDate } from '@common/Helpers/sorters';
import moment from 'moment';
import { maxLength, minLength } from '@vuelidate/validators';
import PromReportModel from '@common/Models/PromReportModel';
import StatusReportModel from '@common/Models/StatusReportModel';
import SymptomModel from '@common/Models/Symptom/SymptomModel';
import SymptomReportModel from '@common/Models/SymptomReport/SymptomReportModel';

export class PatientModel {
  constructor({
    Id,
    PatientId,
    Gender,
    Model,
    Birthyear,
    Language,
    Country,
    Region,
    County,
    Municipal,
    City,
    AreaCode,
    Version,
    FirstName = '',
    LastName = '',
    LastActive,
    Personnummer,
    PromReports = [],
    StatusReports = [],
    SymptomReports = [],
    Symptoms = [],
    AppVersion,
    TutorialsSeen,
    AdvancedManikin = false
  }) {
    this._modelName = 'Patient';

    this.Id = Id || PatientId || null;
    this.Gender = Gender || undefined;
    this.Model = Model || 'male';
    this.Birthyear = Birthyear || null;
    this.Language = Language || 'sv';
    this.Country = Country || 'Sweden';
    this.Region = Region || null;
    this.County = County || null;
    this.Municipal = Municipal || null;
    this.City = City || null;
    this.AreaCode = AreaCode || null;
    this.Version = Version || 0;

    this.FirstName = FirstName;
    this.LastName = LastName;
    this.LastActive = LastActive;
    this.Personnummer = Personnummer;
    /** @type {PromReportModel[]} */
    this.PromReports = PromReports;
    /** @type {StatusReportModel[]} */
    this.StatusReports = StatusReports;
    /** @type {SymptomReportModel[]} */
    this.SymptomReports = SymptomReports;
    /** @type {SymptomModel[]} */
    this.Symptoms = Symptoms;

    this.AppVersion = AppVersion;

    this.TutorialsSeen = TutorialsSeen || [];
    this.AdvancedManikin = AdvancedManikin;
  }

  static get validations() {
    return {
      AreaCode: {
        minLength: minLength(5),
        maxLength: maxLength(5)
      }
    };
  }

  get FullName() {
    return `${this.FirstName} ${this.LastName}`;
  }

  get Age() {
    const today = moment();
    const birthDate = moment(this.Personnummer.substring(0, 8));
    const age = today.diff(birthDate, 'years');
    return age;
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }

  get Reports() {
    let reports = [
      ...this.PromReports,
      ...this.SymptomReports,
      ...this.StatusReports
    ];
    reports = sortByCreatedDate(reports).reverse();
    return reports;
  }
}

export default PatientModel;
