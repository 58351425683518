<template>
  <router-link
    :to="resumeOrNew"
    :id="`prom-${prom.Id}`"
    class="report-summary row pointer"
  >
    <div class="report-summary__info valign-wrapper">
      <div>
        <span class="report-summary__info__info">
          <span class="mr10"
            >{{ prom.Concept }} ({{ prom.QuestionCount }}
            {{ $tc('plural.questions', prom.QuestionCount) }})</span
          >
        </span>
        <br />
        <span class="report-summary__info__date">{{ prom.Id }}</span>
      </div>
    </div>
    <div
      v-if="removeCallback"
      class="report-summary__remove"
      @click.stop.capture.prevent="removeCallback()"
    >
      <i class="mdi" :class="[removeIcon]"></i>
    </div>
    <div class="report-summary__right-arrow valign-wrapper">
      <i class="mdi mdi-chevron-right"></i>
    </div>
  </router-link>
</template>

<script>
import { PROMREPORT_GETTERS } from '@/store/modules/promreports';
import { mapGetters } from 'vuex';
export default {
  props: {
    useActive: {
      type: Boolean
    },
    prom: {
      type: Object,
      required: true
    },
    removeCallback: {
      type: Function
    },
    removeIcon: {
      type: String,
      default: 'mdi-delete'
    }
  },
  computed: {
    ...mapGetters('promreports', {
      $incompletePromReports: PROMREPORT_GETTERS.FIND_INCOMPLETE_PROMREPORTS
    }),
    resumeOrNew() {
      const newLink = {
        name: 'promReportEdit',
        params: { promid: this.prom.Id }
      };

      if (this.useActive === false) {
        return newLink;
      }

      const incompleteReport = this.$incompletePromReports.find(
        report => report.PromId === this.prom.Id
      );
      if (incompleteReport) {
        return {
          name: 'promReportEdit',
          params: { promid: this.prom.Id, promreportid: incompleteReport.Id }
        };
      } else {
        return newLink;
      }
    }
  }
};
</script>
