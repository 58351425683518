import StatisticModel from '@common/Models/StatisticModel';

/**
 * Map remote Statistic to local
 * @param {object} dtoStatistic
 * @returns {StatisticModel}
 */
export const StatisticResponse = dtoStatistic => {
  return new StatisticModel(dtoStatistic);
};

/**
 * Map remote Statistics to local
 * @param {object[]} dtoStatistics
 * @returns {StatisticModel[]}
 */
export const StatisticsResponse = dtoStatistics => {
  return dtoStatistics.map(dto => StatisticResponse(dto));
};
