import BankIdCompletionDataModel from './BankIdCompletionDataModel';

// @ts-check
export class BankIdCollectResponseModel {
  constructor({ OrderRef, Status, HintCode, CompletionData }) {
    this._modelName = 'BankIdCollectResponse';
    this.OrderRef = OrderRef;
    this.Status = Status;
    this.HintCode = HintCode;
    this.CompletionData = new BankIdCompletionDataModel(CompletionData);
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }
}

export default BankIdCollectResponseModel;
