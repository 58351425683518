<template>
  <div v-if="report">
    <h4 class="sub-header">
      {{ $t('pageTitles.statusReport') }}:
      {{ $filters.DateTimeFormat(report.CreatedDate) }}
    </h4>
    <div class="container">
      <i class="mdi mdi-information pointer" @click="showInfo = true"></i>
      <status-graph :graph-data="graphData"></status-graph>
    </div>
    <div class="container">
      <edit-lock
        @change="
          () =>
            this.$router.push({
              name: 'statusReportEdit',
              params: { statusreportid: this.report.Id }
            })
        "
      ></edit-lock>
      <info-row-details-status-report
        v-for="(item, index) in items"
        :key="index"
        :item="item"
      ></info-row-details-status-report>
    </div>

    <float-content>
      <button-back></button-back>
      <s-button
        @click="newReport"
        :primary="true"
        id="ButtonNew"
        element-name="statusreport-newbutton"
        >{{ $t('generic.new') }}</s-button
      >
    </float-content>

    <modal
      :show="showInfo"
      :hide-left-button="true"
      accept-text="Ok"
      accept-classes
      @close="showInfo = false"
    >
      <h5 class="center-text sub-header">{{ $t('generic.information') }}</h5>
      <p>{{ $t('views.statusReport.infoText1') }}</p>

      <p>{{ $t('views.statusReport.infoText2') }}</p>

      <p>{{ $t('views.statusReport.infoText3') }}</p>
    </modal>
  </div>
</template>

<script>
import StatusGraph from './components/StatusGraph';
import { mapGetters, mapActions } from 'vuex';
import { STATUSREPORT_GETTERS } from '@/store/modules/statusreports';
import InfoRowDetailsStatusReport from '@/views/components/InfoRow/InfoRowDetailsStatusReport.vue';
import EditLock from '@/views/components/EditLock.vue';

export default {
  components: {
    EditLock,
    StatusGraph,
    InfoRowDetailsStatusReport
  },
  props: {
    statusreportid: String
  },
  data() {
    return {
      showInfo: false
    };
  },
  mounted() {},
  methods: {
    newReport() {
      this.$router.push({
        name: 'statusReport'
      });
    }
  },
  computed: {
    ...mapGetters('statusreports', {
      $statusReports: STATUSREPORT_GETTERS.STATUS_REPORTS,
      $reportGraph: STATUSREPORT_GETTERS.REPORT_GRAPH_DATA,
      $findReport: STATUSREPORT_GETTERS.FIND_REPORT
    }),
    items() {
      if (!this.graphData || this.graphData.length === 0) {
        return [];
      }
      const data = this.graphData;
      return [data[0], ...data.slice(1).reverse()];
    },
    graphData() {
      return this.$reportGraph(this.statusreportid);
    },
    report() {
      return this.$findReport(this.statusreportid);
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-lock {
  top: -20px;
}
.mdi-square {
  font-size: 14px !important;
  margin-right: 5px;
}
.mdi-information {
  position: absolute;
  right: 20px;
}
</style>
