<template>
  <div>
    <h4 class="container ml20 header" v-text="$tc('plural.auditLog', 2)"></h4>

    <div class="container center-text">
      <s-button :safe="actorRoleFilter === null" @click.prevent="all">{{
        $t('generic.all')
      }}</s-button>
      <s-button
        :safe="actorRoleFilter === 'pat'"
        @click.prevent="excludeSelf"
        >{{ $t('generic.others') }}</s-button
      >
    </div>

    <div class="container" data-cy="auditlogs-list">
      <info-row-audit-log
        v-for="(auditlog, i) in auditlogs"
        :key="i"
        :audit="auditlog"
        data-cy="auditlogs-auditlog"
      ></info-row-audit-log>

      <scroll-end @visible="loadAuditlogs()" container=".content"></scroll-end>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  AUDITLOGS_ACTIONS,
  AUDITLOGS_GETTERS
} from '@/store/modules/auditlogs';
import InfoRowAuditLog from '@/views/components/InfoRow/InfoRowAuditLog.vue';
import ScrollEnd from '@common/Components/ScrollEnd.vue';

export default {
  components: {
    InfoRowAuditLog,
    ScrollEnd
  },
  data() {
    return {
      actorRoleFilter: null,
      loading: false
    };
  },
  created() {
    this.loadAuditlogs();
  },
  methods: {
    ...mapActions('auditlogs', {
      $getMyAuditLogs: AUDITLOGS_ACTIONS.GET_MY_AUDITLOGS,
      $getMyAuditLogsByOthers:
        AUDITLOGS_ACTIONS.GET_MY_AUDITLOGS_EXCLUDING_MYSELF
    }),
    all() {
      this.actorRoleFilter = null;
    },
    excludeSelf() {
      this.$getMyAuditLogsByOthers();
      this.actorRoleFilter = 'pat';
    },
    loadAuditlogs() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$getMyAuditLogs().then(() => (this.loading = false));
    }
  },
  computed: {
    ...mapGetters('auditlogs', {
      $auditLogs: AUDITLOGS_GETTERS.AUDITLOGS
    }),
    auditlogs() {
      return this.$auditLogs.filter(
        a =>
          this.actorRoleFilter === null || this.actorRoleFilter !== a.ActorRole
      );
    }
  }
};
</script>

<style lang="css"></style>
