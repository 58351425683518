import PatientModel from '@common/Models/PatientModel';
import SymptomModel from '@common/Models/Symptom/SymptomModel';
import PatientService from '@common/Services/Patients/PatientService';
import {
  PATIENT_ACTIONS,
  PATIENT_GETTERS,
  PATIENT_MUTATIONS
} from './definitions';
import { sortByCreatedDate } from '@common/Helpers/sorters';
import { StatusReportAreaColors } from '@/constants';
import { STATUSREPORT_GETTERS } from '../statusreports';
import moment from 'moment';
import active, { PATIENT_ACTIVE_MUTATIONS } from './active';

class State {
  constructor() {
    /** @type {PatientModel[]} */
    this.list = [];
    /** @type {PatientModel[]} */
    this.connectedPatients = [];
    this.status = '';
    /** @type {String} */
    this.activePatient = null;
  }
}

const store = {
  namespaced: true,
  modules: { active },
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  /** @param {PatientModel[]} patients */
  [PATIENT_MUTATIONS.SET_PATIENTS](state, patients) {
    state.status = 'Success';
    state.list = patients;
  },
  /** @param {PatientModel[]} patients */
  [PATIENT_MUTATIONS.SET_CONNECTED_PATIENTS](state, patients) {
    state.connectedPatients = patients;
  },
  /** @param {String} patientId */
  [PATIENT_MUTATIONS.SET_ACTIVE_PATIENT](state, patientId) {
    state.activePatient = patientId;
  },
  /** @param {String} patientId */
  [PATIENT_MUTATIONS.REMOVE_CONNECTED_PATIENT](state, patientId) {
    const idx = state.connectedPatients.findIndex(p => p.Id === patientId);
    if (idx === -1) {
      return;
    }
    state.connectedPatients.splice(idx, 1);
  },
  /** @param {PatientModel} patient */
  [PATIENT_MUTATIONS.ADD_PATIENT](state, patient) {
    const idx = state.list.findIndex(p => p.Id === patient.Id);
    if (idx === -1) {
      state.list.push(patient);
    } else {
      state.list[idx] = patient;
    }
  },
  /** @param {PatientModel} patient */
  [PATIENT_MUTATIONS.REMOVE_PATIENT](state, patient) {
    const index = state.list.findIndex(x => x.Id === patient.Id);
    if (index > -1) {
      state.list.splice(index, 1);
    }
  },
  [PATIENT_MUTATIONS.PUSHER_CONNECTED_PATIENT](state) {},
  [PATIENT_MUTATIONS.PUSHER_DISCONNECTED_PATIENT](state) {}
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [PATIENT_ACTIONS.$PREINIT](context) {
    context.dispatch(PATIENT_ACTIONS.GET_LANGUAGES);
  },
  [PATIENT_ACTIONS.GET_CONNECTED_PATIENTS](context) {
    return PatientService.listPatients().then(patients => {
      context.commit(PATIENT_MUTATIONS.SET_CONNECTED_PATIENTS, patients);
      return patients;
    });
  },
  [PATIENT_ACTIONS.GET_PATIENT](context, guid) {
    return PatientService.findPatient(guid).then(patient => {
      context.commit(PATIENT_MUTATIONS.ADD_PATIENT, patient);
      context.commit(PATIENT_MUTATIONS.SET_ACTIVE_PATIENT, guid);
      context.commit(
        `patients/active/${PATIENT_ACTIVE_MUTATIONS.SET_ACTIVE_PATIENT}`,
        patient,
        { root: true }
      );
      return patient;
    });
  },
  [PATIENT_ACTIONS.GET_PATIENT_BY_PERSONNUMMER](context, personnummer) {
    return PatientService.findPatientByPersonnummer(personnummer).then(
      patient => {
        context.commit(PATIENT_MUTATIONS.ADD_PATIENT, patient);
        return patient;
      }
    );
  },
  [PATIENT_ACTIONS.GET_PATIENTS_IN_GROUP](context) {
    return PatientService.getPatientsInGroup().then(patient => {
      context.commit(PATIENT_MUTATIONS.SET_PATIENTS, patient);
      return patient;
    });
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [PATIENT_GETTERS.PATIENTS](state) {
    return state.list;
  },
  [PATIENT_GETTERS.CONNECTED_PATIENTS](state) {
    return state.connectedPatients;
  },
  [PATIENT_GETTERS.FIND_PATIENT]: state => patientId => {
    return state.list.find(p => p.Id === patientId);
  },
  [PATIENT_GETTERS.FIND_ACTIVE_PATIENT](state, getters) {
    return getters[PATIENT_GETTERS.FIND_PATIENT](state.activePatient);
  },
  /**
   * Get item graph data
   * @returns {Object[]}
   */
  [PATIENT_GETTERS.PATIENT_STATUS_AREA_GRAPH]:
    (state, getters, rootState, rootGetters) => area => {
      const graphData = [];
      /** @type {PatientModel} */
      const patient = getters[PATIENT_GETTERS.FIND_ACTIVE_PATIENT];
      if (!patient) {
        return [];
      }
      const list = patient.StatusReports;
      const invertedTypes =
        rootGetters[`statusreports/${STATUSREPORT_GETTERS.INVERTED_TYPES}`];

      for (let i = 0, n = list.length; i < n; i++) {
        const report = list[i];
        const item = report.Items.find(item => area === item.Type);

        if (!item) {
          continue;
        }

        graphData.push({
          value:
            invertedTypes.indexOf(item.Type) === -1
              ? item.Intensity
              : 10 - item.Intensity,
          date: item.CreatedDate.toDate(),
          moment: item.CreatedDate,
          color: StatusReportAreaColors[item.Type]
        });
      }
      return sortByCreatedDate(graphData);
    },
  /**
   * Get item graph data
   * @returns {Object[]}
   */
  [PATIENT_GETTERS.PATIENT_SYMPTOM_GRAPH]:
    (state, getters, rootState, rootGetters) => reports => {
      if (!reports) {
        return [];
      }
      return reports.map(report => {
        return {
          date: report.CreatedDate,
          moment: moment(report.CreatedDate),
          value: +report.Intensity
        };
      });
    },
  /**
   * Find active patient symptom
   * @returns {SymptomModel[]}
   */
  [PATIENT_GETTERS.FIND_ACTIVE_PATIENT_SYMPTOM]:
    (state, getters) => symptomId => {
      /** @type {PatientModel} */
      const patient = getters[PATIENT_GETTERS.FIND_ACTIVE_PATIENT];
      if (!patient) {
        return [];
      }
      return patient.Symptoms.find(x => x.Id === symptomId);
    },
  /**
   * Find active patient symptomreports for symptom
   * @returns {SymptomModel[]}
   */
  [PATIENT_GETTERS.FIND_ACTIVE_PATIENT_SYMPTOMREPORTS_FOR_SYMPTOM]:
    (state, getters) => symptomId => {
      /** @type {PatientModel} */
      const patient = getters[PATIENT_GETTERS.FIND_ACTIVE_PATIENT];
      if (!patient) {
        return [];
      }
      const reports = patient.SymptomReports.map(x =>
        x.Symptoms.find(y => y.Id === symptomId)
      ).filter(x => x !== undefined);
      console.log(reports);

      return reports;
    }
};

export {
  PATIENT_ACTIONS,
  PATIENT_GETTERS,
  PATIENT_MUTATIONS
} from './definitions';

export default store;
