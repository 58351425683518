import { sortByValueKey } from '@common/Helpers/sorters';
import ApplicationService from '@common/Services/Applications/ApplicationService';
import {
  APPLICATION_ACTIONS,
  APPLICATION_GETTERS,
  APPLICATION_MUTATIONS
} from './definitions';
import ApplicationModel from '@common/Models/ApplicationModel';

class State {
  constructor() {
    /** @type {ApplicationModel[]} */
    this.list = [];
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  [APPLICATION_MUTATIONS.ADD_APPLICATION](state, application) {
    const exists = state.list.find(i => i.Id === application.Id);
    if (!exists) {
      state.list.push(application);
    } else {
      Object.assign(exists, application);
    }
  },
  [APPLICATION_MUTATIONS.REMOVE_APPLICATION](state, application) {
    const index = state.list.findIndex(x => x.Id === application.Id);
    if (index > -1) {
      state.list.splice(index, 1);
    }
  },
  [APPLICATION_MUTATIONS.SET_APPLICATIONS](state, applications) {
    state.list = applications;
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [APPLICATION_ACTIONS.CREATE_APPLICATION](context, application) {
    return ApplicationService.createApplication(application).then(application =>
      context.commit(APPLICATION_MUTATIONS.ADD_APPLICATION, application)
    );
  },
  [APPLICATION_ACTIONS.DELETE_APPLICATION](context, application) {
    ApplicationService.deleteApplication(application).then(() =>
      context.commit(APPLICATION_MUTATIONS.REMOVE_APPLICATION, application)
    );
  },
  [APPLICATION_ACTIONS.GET_APPLICATION](context, applicationId) {
    ApplicationService.getApplication(applicationId).then(application => {
      context.commit(APPLICATION_MUTATIONS.ADD_APPLICATION, application);
    });
  },
  [APPLICATION_ACTIONS.GET_APPLICATIONS](context) {
    ApplicationService.getApplications().then(applications =>
      context.commit(APPLICATION_MUTATIONS.SET_APPLICATIONS, applications)
    );
  },
  [APPLICATION_ACTIONS.UPDATE_APPLICATION](context, application) {
    return ApplicationService.updateApplication(application).then(
      application => {
        context.commit(APPLICATION_MUTATIONS.ADD_APPLICATION, application);
        return application;
      }
    );
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [APPLICATION_GETTERS.APPLICATIONS](state) {
    return sortByValueKey(state.list, 'Description');
  },
  [APPLICATION_GETTERS.FIND_APPLICATION]: state => applicationId => {
    return state.list.find(a => a.Id === applicationId);
  }
};

export {
  APPLICATION_ACTIONS,
  APPLICATION_GETTERS,
  APPLICATION_MUTATIONS
} from './definitions';

export default store;
