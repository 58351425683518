<template v-if="!isLoading">
  <div data-cy="history">
    <div class="container">
      <h4
        v-html="$t('generic.history')"
        class="header pb20"
        data-cy="history-text-history"
      ></h4>
      <component
        v-for="(historyItem, index) in historyItems"
        :report="historyItem.ItemObject"
        :key="historyItem.ResourceId"
        :is="`InfoRow${historyItem._modelName}`"
        :data-cy="`history-item-${historyItem._modelName}`"
        @delete="() => removeItem(index)"
      ></component>
      <div v-if="historyItems.length == 0">
        {{ $t('views.history.noReportsFound') }}
      </div>
    </div>
  </div>
</template>

<script>
import InfoRowPromReport from '@views/components/InfoRow/InfoRowPromReport';
import InfoRowSymptomReport from '@views/components/InfoRow/InfoRowSymptomReport';
import InfoRowNote from '@views/components/InfoRow/InfoRowNote';
import HistoryItemService from '@common/Services/HistoryItems/HistoryItemService';

export default {
  components: {
    InfoRowNote,
    InfoRowPromReport,
    InfoRowSymptomReport
  },
  data() {
    return {
      historyItems: [],
      isLoading: false
    };
  },
  mounted() {
    this.getMyHistory();
  },
  methods: {
    getMyHistory() {
      this.isLoading = true;
      HistoryItemService.getMyHistoryItems()
        .then(historyItems => {
          this.historyItems = historyItems;
        })
        .finally(() => (this.isLoading = false));
    },
    removeItem(index) {
      this.historyItems.splice(index, 1);
    }
  }
};
</script>

<style lang="css"></style>
