import BaseService from '../BaseService';
import OAuthTokenModel from '@common/Models/OAuthTokenModel';
import {
  OAuthTokenResponse,
  OAuthTokensResponse
} from './mappers/OAuthTokenResponses';

class OAuthTokenService extends BaseService {
  /**
   * Create token in active user's group -- identity
   * @param {OAuthTokenModel} OAuthToken
   * @returns {Promise<OAuthTokenModel>}
   * @memberof OAuthTokenService
   */
  createToken(data) {
    return this.identity('post', 'my/group/oauthtokens', data).then(response =>
      OAuthTokenResponse(response.data)
    );
  }

  /**
   * Get tokens in active user's group -- identity
   * @returns {Promise<OAuthTokenModel[]>}
   * @memberof OAuthTokenService
   */
  getTokens() {
    return this.identity('my/group/oauthtokens').then(response =>
      OAuthTokensResponse(response.data)
    );
  }

  /**
   * Get token in active user's group -- identity
   * @returns {Promise<OAuthTokenModel>}
   * @memberof OAuthTokenService
   */
  updateToken(token) {
    return this.identity('put', `my/group/oauthtokens/${token.Id}`, token).then(
      response => OAuthTokenResponse(response.data)
    );
  }

  /**
   * Delete token in active user's group -- identity
   * @returns {Promise}
   * @memberof OAuthTokenService
   */
  deleteToken(token) {
    return this.identity('delete', `my/group/oauthtokens/${token.Id}`);
  }
}

export default new OAuthTokenService();
