const devEnv = process.env.NODE_ENV === 'development';
const env = require('@/../env.json');
const debugOn = env.VuexDebug === true;

export const debugPlugin = store => {
  if (!devEnv || !debugOn) {
    return;
  }
  store.subscribe(mutation => {
    console.log('[debug:VUEX/MUTATION]', mutation.type, mutation.payload);
  });
  store.subscribeAction(mutation => {
    console.log('[debug:VUEX/ACTION]', mutation.type, mutation.payload);
  });
};
