<template>
  <div
    class="flex-grid"
    :class="{
      'full-width': fullWidth,
      'force-row': forceRow
    }"
    :style="{
      padding: `${padding}px`,
      'justify-content': justify,
      'align-items': alignItems
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    padding: {
      default: 20
    },
    fullWidth: Boolean,
    justify: {
      type: String,
      default: 'space-around'
    },
    forceRow: {
      type: Boolean,
      default: false
    },
    alignItems: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';

.flex-grid {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  max-width: 1045px;
  margin: 0 auto;
  padding-left: 20px;

  &.full-width {
    margin: unset;
    max-width: unset;
  }

  @media only screen and (max-width: $breakpoint-desktop) {
    padding-left: 0;
    flex-wrap: nowrap;
    &:not(.force-row) {
      flex-direction: column;
    }
  }

  @media only screen and (min-width: $breakpoint-desktop) {
    .card-scroller {
      padding: 0 0 20px 0;
    }
  }
}
</style>

<style lang="scss">
@import '@sass/_variables.scss';
.flex-grid {
  @media only screen and (min-width: $breakpoint-desktop) {
    .card-scroller {
      padding: 0 0 20px 0;
    }
  }
}
</style>
