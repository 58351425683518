<template>
  <card-container-vue
    v-if="question && prom"
    :full-width="true"
    :data-num="itemNum"
  >
    <card-vue :full-width="true">
      <template v-slot:content v-if="isVisible">
        <div
          class="question"
          :id="`question-sequence-${question.SequenceId}`"
          :class="{ pointer: hidden }"
          @click="hidden ? (show = !show) : undefined"
          data-cy="prom-question"
        >
          <div :id="`question-${question.SequenceId}`" class="prom__anchor" />
          <p class="question__number">
            {{ $tc('plural.question', 1) }} {{ question.SequenceId }}
            {{ $t('generic.of') }} {{ prom.QuestionCount }}
          </p>
          <span class="question__title" v-html="toHtml(title)"></span>

          <div class="question__info" v-if="info" v-html="toHtml(info)"></div>

          <img
            v-if="question.Image"
            class="question-image mt30"
            v-bind:src="question.Image"
          />

          <component
            :is="ulOrOl"
            start="0"
            data-cy="promreport-answers"
            class="answers spacing"
            :class="[
              { linebreak: question.LineBreak && question.Style !== 'ol' }
            ]"
          >
            <answer
              v-if="oneAnswerQuestion"
              :index="1"
              :answer="question.Answers[0]"
              :answer-input="answerInput"
              :question="question"
              :disabled="readonly || hidden"
              @setAnswer="setAnswer"
              @removeAnswer="removeAnswer"
              @toggleSkippables="toggleSkippables"
            ></answer>

            <answer
              v-else
              v-for="(answer, index) in question.Answers"
              :answer="answer"
              :index="index"
              :answer-input="answerInput"
              :is-answer="isAnswer(answer)"
              :is-not-saved-answer="isNotSavedAnswer(answer)"
              :question="question"
              :key="index"
              :disabled="readonly || hidden"
              ref="answers"
              @setAnswer="setAnswer"
              @removeAnswer="removeAnswer"
              @toggleSkippables="toggleSkippables"
              :fake-answer-id="fakeAnswerId"
            ></answer>
          </component>

          <div
            class="question__hidden valign-wrapper"
            v-if="
              showHiddenQuestionsText &&
              hiddenQuestionCounter &&
              hiddenQuestionCounter.count > 0 &&
              !hidden
            "
            @click="displayHiddenQuestions(hiddenQuestionCounter)"
            :class="`question--level${question.Level}`"
          >
            <span
              v-html="
                `${hiddenQuestionCounter.count} ${$t(
                  'views.promReport.hiddenQuestions'
                )}`
              "
            ></span>
            <i
              class="mdi"
              :class="showHidden ? 'mdi-chevron-down' : 'mdi-chevron-right'"
            ></i>
          </div>
          <slot></slot>
          <slot name="cancel"></slot>
        </div>
      </template>
      <template v-slot:content v-else>
        <div
          class="question--hidden"
          @click="show = !show"
          v-html="toHtml(title)"
        ></div>
      </template>
    </card-vue>
  </card-container-vue>
</template>

<script>
import CardContainerVue from '../Cards/CardContainer.vue';
import CardVue from '../Cards/Card.vue';
import Answer from './Answer';
import { PROM_GETTERS } from '@/store/modules/proms';
import { mapGetters } from 'vuex';
import { toHtml } from '../../Helpers/markdown';

export default {
  components: { CardContainerVue, CardVue, Answer },
  props: {
    report: Object,
    item: Object,
    hasSubQuestion: Boolean,
    displayTitle: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    },
    readonly: Boolean,
    itemNum: Number,
    showHiddenQuestionsText: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      show: false,
      hiddenQuestionCounter: null,
      showHidden: false,
      fakeAnswerId: null
    };
  },
  mounted() {
    this.$bus.$on('promreport.hiddenQuestionCounter', count => {
      if (count.skip == true && this.question.Index == count.from) {
        this.hiddenQuestionCounter = count;
      } else if (count.skip == false && this.question.Index == count.from) {
        this.hiddenQuestionCounter = null;
      }
    });

    this.$bus.$on('promreport.toggleHiddenQuestions', hiddenQuestions => {
      if (
        this.question.Index > hiddenQuestions.from &&
        this.question.Index < hiddenQuestions.to
      ) {
        this.show = !this.show;
      }
      if (this.question.Index === hiddenQuestions.from) {
        this.showHidden = !this.showHidden;
      }
    });
    this.checkAndTriggerConditionals();
  },
  watch: {
    'report.Answers': {
      handler(newValue, oldValue) {
        if (
          !oldValue ||
          !oldValue.length ||
          newValue.length > oldValue.length
        ) {
          this.checkAndTriggerConditionals();
        }
      },
      immediate: true
    }
    // answerInput: {
    //   handler: function (answer) {
    //     console.log('a', answer);
    //     if (answer && answer.Level > 1) {
    //       const parentA = this.getParentAnswerFromAnswer(answer);
    //       console.log('p', parentA);
    //       if (parentA && !this.fakeAnswerId) {
    //         this.fakeAnswerId = parentA.Id;
    //       }
    //     }
    //   },
    //   immediate: true
    // }
  },
  methods: {
    toHtml,
    setAnswer(answer, checked, notApplicable) {
      if (answer) {
        this.checkConditionals(answer);
      }
      if (this.fakeAnswerId) {
        this.fakeAnswerId = null;
      }
      this.$emit('setAnswer', this.question, answer, checked, notApplicable);

      if (answer) {
        if (answer.Level === 1) {
          return;
        }

        const parentA = this.getParentAnswerFromAnswer(answer);

        if (
          this.question.AnswerType === 'answer' &&
          this.question.QuestionType == 'radiobtn'
        ) {
          this.fakeAnswerId = parentA.Id;
          this.$emit('setAnswer', {
            question: this.question,
            answer: answer,
            checked: checked,
            notApplicable: notApplicable
          });
        } else {
          if (
            this.report.Answers.find(
              x => x.QuestionId === this.question.Id
            ).AnswerIds?.includes(parentA.Id)
          ) {
            return;
          }

          this.$emit('setAnswer', {
            question: this.question,
            answer: parentA,
            checked: checked,
            notApplicable: notApplicable
          });
        }
      }
    },
    removeAnswer(answer) {
      this.$emit('removeAnswer', this.question, answer);

      if (answer) {
        if (answer.Level === 1) {
          return;
        }

        if (this.question.AnswerType === 'answer') {
          this.fakeAnswerId = null;
        }
      }
    },
    toggleSkippables(fromQuestion, toQuestion, skip) {
      this.$debug('toggleSkippables', fromQuestion, toQuestion, skip);
      this.$emit('toggleSkippables', fromQuestion, toQuestion, skip);
    },
    getParentAnswerFromAnswer(answer) {
      const q = this.question;
      const ai = q.Answers.findIndex(x => x.Id === answer.Id);
      const ans = q.Answers.slice(0, ai).reverse();
      let par;
      ans.forEach(x => {
        if (x.Level > 1 || par) {
          return;
        }
        par = x;
      });
      return par;
    },
    isAnswer(answer) {
      return (
        this.report.Answers.findIndex(
          a => a.AnswerIds && a.AnswerIds.findIndex(b => b === answer.Id) !== -1
        ) !== -1
      );
    },
    displayHiddenQuestions(hiddenQuestions) {
      this.$bus.$emit('promreport.toggleHiddenQuestions', hiddenQuestions);
    },
    isNotSavedAnswer(answerToCheck) {
      const answeredQuestion = this.findAnsweredQuestion(answerToCheck);
      if (answeredQuestion) {
        return !answeredQuestion.saved;
      }
    },
    findAnsweredQuestion(answerToFind) {
      if (!this.report) {
        return;
      }

      if (this.question.AnswerType === 'string') {
        return this.report.Answers.find(answer => {
          return answer.QuestionId === this.question.Id;
        });
      }

      return this.report.Answers.find(answer => {
        return (
          answer.AnswerIds &&
          answer.AnswerIds.indexOf(answerToFind.Id) > -1 &&
          answer.NotApplicable !== true
        );
      });
    },
    checkConditionals(answer) {
      let condition = this.question.Conditionals.find(
        c => c.CompareAnswerId === answer.Id
      );
      if (!condition && !this.question.Conditionals.length) {
        return false;
      }

      let skip = false;
      if (condition) {
        skip = true;
      } else if (this.question.Conditionals.length) {
        condition = this.question.Conditionals[0];
        skip = false;
      }
      setTimeout(() => {
        this.$emit(
          'toggleSkippables',
          this.question.Index,
          condition.RedirectQuestionId,
          skip
        );
      }, 0);
    },
    checkAndTriggerConditionals() {
      this.question.Answers.forEach((answer, i) => {
        if (this.isAnswer(answer)) {
          this.checkConditionals(answer);

          if (answer.Level > 1 && this.question.AnswerType === 'answer') {
            if (!this.fakeAnswerId) {
              this.fakeAnswerId = this.question.Answers.slice()
                .slice(0, i)
                .reverse()
                .filter(x => x.Level === 1)[0].Id;
            }
          }
        }
      });
    }
  },
  computed: {
    ...mapGetters('proms', {
      $findProm: PROM_GETTERS.FIND_PROM
    }),
    title() {
      return this.question.Header || this.question.Text;
    },
    info() {
      if (this.question.Header && this.question.Text) {
        return this.question.Text;
      }
      return undefined;
    },
    isVisible() {
      if (this.hidden == true) {
        if (this.show == true) {
          return true;
        }
        return false;
      }
      return true;
    },
    question() {
      return this.item;
    },
    answerInput() {
      return this.report.Answers.find(q => q.QuestionId === this.question.Id);
    },
    ulOrOl() {
      return 'ul';
    },
    prom() {
      return this.$findProm(this.report.PromId);
    },
    oneAnswerQuestion() {
      return (
        this.question.QuestionType === 'dropdown' ||
        this.question.QuestionType === 'date'
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';
$padding: 20px;
.question-image {
  width: 100% !important;
  max-width: 100% !important;
}
.question {
  font-size: 1.3rem;
  // margin: 0.82rem 0 0.656rem;
  position: relative;
  color: #000;
  padding: $padding;
  border-radius: 6px;

  @media only screen and (min-width: $breakpoint-desktop) {
    font-size: 1.64rem;
  }

  &__actions {
    font-size: 1rem;
    text-align: right;

    span {
      cursor: pointer;
    }

    i {
      font-size: 1rem;
    }
  }

  &__info {
    margin-bottom: 10px;
  }

  &__answers {
    font-size: 1rem;
    @media only screen and (min-width: $breakpoint-desktop) {
      font-size: 1.24rem;
    }
    li {
      border: 0 !important;
      margin-bottom: 0 !important;
    }
  }
  .answers {
    width: 80%;
    margin-bottom: 0;
    @media only screen and (max-width: $breakpoint-desktop) {
      width: 100%;
    }
    li {
      display: flex;
      align-items: center;
      position: relative;

      label {
        width: 100%;
      }

      &.no-styles {
        border: 0;
        padding: 0;
      }
    }
  }

  &:not(:first-of-type):not(.prom__question--is-subquestion) {
    margin-top: 10px;
  }

  &__title {
    font-weight: 500;
    font-style: italic;
  }

  &__number {
    font-size: 14px;
    color: $symptoms-orange;
    margin: 0;
  }

  &__info {
    font-size: 1rem;
    display: block;
  }

  &--level2 {
    font-size: 1.2rem;
    margin: 0.82rem 0 0.656rem;
    padding-left: 45px !important;
  }

  &--level3 {
    padding-left: 60px !important;
  }

  &--level4 {
    padding-left: 90px !important;
  }

  &--disabled {
    opacity: 0.5;
  }

  &--has-subquestions {
    padding: $padding $padding 0 $padding !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &--is-subquestion {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-top: 0;
  }

  &--hidden {
    padding: 10px 20px;
    font-style: italic;
    cursor: pointer;
  }

  &__hidden {
    cursor: pointer;
    font-style: italic;
    font-size: small;

    .mdi {
      font-size: inherit !important;
      padding-top: 1px;
      line-height: 0;
    }
  }
}
</style>

<style lang="scss">
.question .question__title p {
  display: inline;
  padding: 0;
  margin: 0;
}
.question .separator {
  height: 50%;
  border-top: 1px solid gray;
  min-width: 32px;
  margin-right: 10px;

  &.disabled {
    border-color: #e6e6e6;
  }
}
</style>
