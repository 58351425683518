import { capitalizeFirstLetter } from '@common/Helpers/strings';
import SymptomDefinitionModel from '@common/Models/SymptomDefinitionModel';

/**
 * Map local SymptomDefinition to remote
 * @param {SymptomDefinitionModel} SymptomDefinition
 * @returns {object}
 */
export const SymptomDefinitionRequest = SymptomDefinition => {
  const dtoSymptomDefinition = Object.assign({}, SymptomDefinition);

  dtoSymptomDefinition.Name = capitalizeFirstLetter(dtoSymptomDefinition.Name);

  return dtoSymptomDefinition;
};

/**
 * Map local SymptomDefinitions to remote
 * @param {SymptomDefinitionModel[]} SymptomDefinitions
 * @returns {object}
 */
export const SymptomDefinitionsRequest = SymptomDefinitions => {
  return SymptomDefinitions.map((symptomDefinition, index) => {
    const dtoSymptomDefinition = SymptomDefinitionRequest(symptomDefinition);
    dtoSymptomDefinition.Index = index;
    return dtoSymptomDefinition;
  });
};
