export const ReplaceIconDirective = {
  /**
   * @param {HTMLElement} el
   * @param {*} binding
   */
  inserted: function (el, binding) {
    const DEFAULT_ICON = el.classList.value
      .split(' ')
      .find(c => c.match('mdi-'));
    const REPLACE_ICON = binding.value;

    const CLICK = function (event) {
      el.classList.toggle(DEFAULT_ICON);
      el.classList.add(REPLACE_ICON);
      setTimeout(() => {
        el.classList.remove(REPLACE_ICON);
        el.classList.toggle(DEFAULT_ICON);
      }, 1000);
    };

    el.addEventListener('click', CLICK);
  }
};
