export class ManikinModel {
  constructor({ Id, Model, Text }) {
    this._modelName = 'Manikin';
    this.Id = Id;
    this.Model = Model;
    this.Text = Text;
  }
}

export default ManikinModel;
