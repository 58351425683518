export const AUDITLOGS_MUTATIONS = {
  ADD_AUDITLOG: 'ADD_AUDITLOG',
  SET_AUDITLOGS: 'SET_AUDITLOGS'
};

export const AUDITLOGS_ACTIONS = {
  GET_MY_AUDITLOGS: 'GET_MY_AUDITLOGS',
  GET_MY_AUDITLOGS_EXCLUDING_MYSELF: 'GET_MY_AUDITLOGS_EXCLUDING_MYSELF',
  GET_AUDITLOG: 'GET_AUDITLOG'
};

export const AUDITLOGS_GETTERS = {
  AUDITLOG: 'AUDITLOG',
  AUDITLOGS: 'AUDITLOGS',
  FIND_AUDITLOG: 'FIND_AUDITLOG'
};
