// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/images/brush-cursor.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../public/images/eraser-cursor.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".symptom-report-view{position:relative;margin-top:-20px}.symptom-report-view--bottom-popup{height:82%}.symptom-report-view .title{text-align:center;font-weight:700;background-color:#fff}.symptom-report-view .explanatory-text{text-align:center;font-weight:70;background-color:#fff}.quincy-wrapper #canvas-container.draw{cursor:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),auto}.quincy-wrapper #canvas-container.normal,.quincy-wrapper #canvas-container.not-editable{cursor:default!important}.quincy-wrapper #canvas-container.camera{cursor:pointer!important}.quincy-wrapper #canvas-container.erase{cursor:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "),auto!important}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
