import { sortByValueKey } from '@common/Helpers/sorters';
import OrganizationService from '@common/Services/Organizations/OrganizationService';
import {
  ORGANIZATION_ACTIONS,
  ORGANIZATION_GETTERS,
  ORGANIZATION_MUTATIONS
} from './definitions';
import OrganizationModel from '@common/Models/OrganizationModel';

class State {
  constructor() {
    /** @type {OrganizationModel[]} */
    this.list = [];
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  [ORGANIZATION_MUTATIONS.ADD_ORGANIZATION](state, organization) {
    const exists = state.list.find(i => i.Id === organization.Id);
    if (!exists) {
      state.list.push(organization);
    } else {
      Object.assign(exists, organization);
    }
  },
  [ORGANIZATION_MUTATIONS.REMOVE_ORGANIZATION](state, organization) {
    const index = state.list.findIndex(x => x.Id === organization.Id);
    if (index > -1) {
      state.list.splice(index, 1);
    }
  },
  [ORGANIZATION_MUTATIONS.SET_ORGANIZATIONS](state, organizations) {
    state.list = organizations;
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [ORGANIZATION_ACTIONS.CREATE_ORGANIZATION](context, organization) {
    return OrganizationService.createOrganization(organization).then(
      organization => {
        context.commit(ORGANIZATION_MUTATIONS.ADD_ORGANIZATION, organization);
        return organization;
      }
    );
  },
  [ORGANIZATION_ACTIONS.DELETE_ORGANIZATION](context, organization) {
    return OrganizationService.deleteOrganization(organization.Id).then(() =>
      context.commit(ORGANIZATION_MUTATIONS.REMOVE_ORGANIZATION, organization)
    );
  },
  [ORGANIZATION_ACTIONS.GET_ORGANIZATION](context, organizationId) {
    return OrganizationService.getOrganization(organizationId).then(
      organization => {
        context.commit(ORGANIZATION_MUTATIONS.ADD_ORGANIZATION, organization);
        return organization;
      }
    );
  },
  [ORGANIZATION_ACTIONS.GET_ORGANIZATIONS](context) {
    return OrganizationService.getOrganizations().then(organizations => {
      context.commit(ORGANIZATION_MUTATIONS.SET_ORGANIZATIONS, organizations);
      return organizations;
    });
  },
  [ORGANIZATION_ACTIONS.UPDATE_ORGANIZATION](context, organization) {
    return OrganizationService.updateOrganization(
      organization.Id,
      organization
    ).then(organization => {
      context.commit(ORGANIZATION_MUTATIONS.ADD_ORGANIZATION, organization);
      return organization;
    });
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [ORGANIZATION_GETTERS.ORGANIZATIONS](state) {
    return sortByValueKey(state.list, 'Name');
  },
  [ORGANIZATION_GETTERS.FIND_ORGANIZATION]: state => organizationId => {
    return state.list.find(a => a.Id === organizationId);
  }
};

export {
  ORGANIZATION_ACTIONS,
  ORGANIZATION_GETTERS,
  ORGANIZATION_MUTATIONS
} from './definitions';

export default store;
