export default {
  data() {
    return {
      showPopup: false,
      confirmRemove: false
    };
  },
  computed: {
    divOrLink() {
      if (this.showPopup === true) {
        return 'div';
      }
      return 'router-link';
    }
  }
};
