import { trimPersonnummer } from '@common/Helpers/strings';
import UserModel from '@common/Models/UserModel';
import ParticipantModel from '@common/Models/ParticipantModel';
import TwoFactorDetailsModel from '@common/Models/TwoFactorDetailsModel';
import download from 'downloadjs';
import BaseService from '../BaseService';
import { ParticipantsResponse } from './mappers/ParticipantResponses';
import { NewUserRequest } from './mappers/UserRequests';
import { UserResponse, UsersResponse } from './mappers/UserResponses';

class UserService extends BaseService {
  /**
   * Get user information -- Identity
   * @returns {Promise<UserModel>}
   */
  myUser() {
    return this.identity('my/user').then(response =>
      UserResponse(response.data)
    );
  }

  /**
   * Update user info -- Identity
   * @param {Object} info
   * @param {String} info.Username
   * @param {String} info.FirstName
   * @param {String} info.LastName
   * @param {String} info.Email
   * @param {String} info.PhoneNumber
   * @returns {Promise<UserModel>}
   */
  updateMyUser(info) {
    return this.identity('put', 'my/user', info).then(response =>
      UserResponse(response.data)
    );
  }

  /**
   * Register new user -- identity
   * Identity
   * @param {object} dto
   * @param {string} dto.Username
   * @param {string} dto.Password
   * @param {string} dto.FirstName
   * @param {string} dto.LastName
   * @param {string} dto.Email
   * @param {string} dto.PhoneNumber
   * @param {string[]} dto.ConsentedContracts
   * @param {string} key
   */
  registerUser(dto, key) {
    dto = Object.assign({}, dto);
    if (dto.Personnummer) {
      dto.Personnummer = dto.Personnummer.replace('-', '');
    }

    return this.identity('post', `users/invite/${key}`, dto).then(response =>
      UserResponse(response.data)
    );
  }

  /**
   * Verify new user email -- identity
   * @param {string} code guid
   * @returns {Promise}
   */
  verifyEmail(code) {
    return this.identity('post', `verify-email/${code}`);
  }

  /**
   * Deactivate user accout -- identity
   * @param {string} Password User password
   * @returns {Promise}
   */
  deactivateAccount(Password) {
    return this.identity('post', 'my/user/deactivate', { Password }).then(
      response => response.data
    );
  }

  /**
   * Activate user account -- backend
   * @param {string} Password
   * @param {string} Salt
   *
   * @returns {Promise}
   */
  activateAccount(Password, Salt) {
    return this.identity('post', 'my/user/activate', { Password, Salt }).then(
      response => response.data
    );
  }

  /**
   * Create new user for deactivated account -- identity
   * @returns {Promise}
   */
  createNewPatient() {
    return this.identity('post', 'my/user/connect', {}).then(
      response => response.data
    );
  }

  checkUsername(username) {
    return this.identity(`users/check/username/${username}`).then(
      response => response.data
    );
  }

  // TODO: Move to InviteService
  getInvite(key) {
    return this.identity(`my/group/invites/${key}`).then(
      response => response.data
    );
  }

  // TODO: Move to InviteService
  checkRegisterKey(key) {
    return this.identity(`invites/${key}/valid`).then(
      response => response.data
    );
  }

  /**
   * Remove user account -- identity
   * @returns {Promise} promise
   */
  removeAccount() {
    return this.identity('delete', 'my/user').then(response => response.data);
  }

  /**
   * Anonymise user account -- identity
   * @param {object} anonymiseAccountData
   * @param {string} anonymiseAccountData.Age
   * @param {string} anonymiseAccountData.Gender
   * @param {string} anonymiseAccountData.AreaCode
   * @param {string} anonymiseAccountData.City
   * @param {string} anonymiseAccountData.Region
   * @returns {Promise} promise
   */
  // TODO: Move to InviteService
  anonymiseAccount(anonymiseAccountData) {
    return this.identity('post', 'my/user/anonymize', anonymiseAccountData);
  }

  /**
   * Check invite email -- identity
   * @param {string} InviteId
   * @param {string} Email
   * @returns {Promise}
   */
  // TODO: Move to InviteService
  checkEmail(InviteId, Email) {
    return this.identity('post', `invites/${InviteId}/valid-email`, {
      Email
    }).then(response => response.data);
  }

  /**
   * Get two factor authentication setup details -- identity
   * @returns {Promise<TwoFactorDetailsModel>}
   */
  get2faSetup() {
    return this.identity('post', 'my/user/2fa/setup').then(
      response => new TwoFactorDetailsModel(response.data)
    );
  }

  /**
   * Remove 2fa from account -- identity
   * @returns {Promise<object>}
   */
  remove2fa() {
    return this.identity('post', 'my/user/2fa/disable', {}).then(
      response => response.data
    );
  }

  /**
   * Get two factor authentication setup details -- identity
   * @returns {Promise<TwoFactorDetailsModel>}
   */
  refresh2fa() {
    return this.identity('post', 'my/user/2fa/setup').then(
      response => new TwoFactorDetailsModel(response.data)
    );
  }

  /**
   * Enable two factor authentication -- identity
   * @param {string} Code 2FA code
   * @param {string} SessionId SessionId from identity
   * @returns {Promise<object>}
   */
  authenticate2fa(Code = null, SessionId) {
    return this.identity('post', 'my/user/2fa', { Code, SessionId }).then(
      response => response.data
    );
  }

  /**
   * Enable two factor authentication with backup code -- identity
   * @param {string} BackupCode 2FA backup code
   * @param {string} SessionId SessionId from identity
   * @returns {Promise<object>}
   */
  authenticate2faBackupCode(BackupCode, SessionId) {
    return this.identity('post', 'my/user/2fa', { BackupCode, SessionId }).then(
      response => response.data
    );
  }

  /**
   * Download all data belonging to user -- identity
   */
  downloadData() {
    return this.identity('my/user/data', null, null, {
      responseType: 'blob'
    }).then(response => {
      download(
        response.data,
        'symptoms-userdata.zip',
        response.headers['content-type']
      );
    });
  }

  /**
   * Get user information -- Identity
   * @returns {Promise<UserModel[]>}
   */
  getUsers() {
    return this.identity('/users').then(response =>
      UsersResponse(response.data)
    );
  }

  /**
   * Get user information by id -- Identity
   * @param {String} userId
   * @returns {Promise<UserModel>}
   */
  getUser(userId) {
    return this.identity(`admin/users/id/${userId}`).then(response =>
      UserResponse(response.data)
    );
  }

  /**
   * Get user information by personnummer -- Identity
   * @param {String} pnr
   * @returns {Promise<UserModel[]>}
   */
  getUserByPersonnummer(pnr) {
    return this.identity(`admin/users/pnr/${trimPersonnummer(pnr)}`).then(
      response => UserResponse(response.data)
    );
  }

  /**
   * Get user information by email -- Identity
   * @param {String} email
   * @returns {Promise<UserModel[]>}
   */
  getUserByEmail(email) {
    return this.identity(`admin/users/email/${email}`).then(response =>
      UserResponse(response.data)
    );
  }

  /**
   * Delete user from system
   * @param {UserModel} user
   * @returns {Promise<any>}
   * @memberof UserService
   */
  deleteUser(user) {
    return this.identity('delete', `users/${user.Id}`).then(
      response => response.data
    );
  }

  /**
   * Create new user -- identity
   * Identity
   * @param {UserModel} user
   * @returns {Promise<UserModel>}
   */
  createUser(user) {
    return this.identity('post', '/users', NewUserRequest(user)).then(
      response => UserResponse(response.data)
    );
  }

  /**
   * Update user info -- Identity
   * @param {Object} info
   * @param {String} info.Username
   * @param {String} info.FirstName
   * @param {String} info.LastName
   * @param {String} info.Email
   * @param {String} info.PhoneNumber
   * @returns {Promise<UserModel>}
   */
  updateUser(guid, info) {
    if (info.Personnummer) {
      info.Personnummer = trimPersonnummer(info.Personnummer);
    }

    return this.identity('put', `users/${guid}`, info).then(response =>
      UserResponse(response.data)
    );
  }

  disableTwoFactorForPatient(userId) {
    return this.identity('post', `users/${userId}/2fa/disable`, {});
  }

  resetPasswordForPatient(userId) {
    return this.identity('post', `users/${userId}/reset-password`, userId);
  }

  eraseAllDataForPatient(patientId) {
    return this.backend('delete', `admin/patients/${patientId}/data`);
  }

  anonymizeAccountForPatient(patientId) {
    const url = `admin/patients/${patientId}/?anonymize=true`;
    return this.backend('delete', url);
  }

  removeUser(userId) {
    return this.identity('delete', `users/${userId}`);
  }

  updateUserRoles(userId, roles) {
    return this.identity('put', `users/${userId}/roles`, roles);
  }

  /**
   * Get subject information export -- backend
   * @returns {Promise<ParticipantModel[]>}
   * @memberof UserService
   */
  getSubjectInformationExport(requiredContracts) {
    return this.backend(
      'post',
      'admin/subject-information/export',
      requiredContracts
    ).then(response => ParticipantsResponse(response.data));
  }
}

export default new UserService();
