import SymptomDefinitionModel from '@common/Models/SymptomDefinitionModel';
import BaseService from '../BaseService';
import {
  SymptomDefinitionRequest,
  SymptomDefinitionsRequest
} from './mappers/SymptomDefinitionRequests';
import {
  SymptomDefinitionResponse,
  SymptomDefinitionsResponse
} from './mappers/SymptomDefinitionResponses';

class SymptomDefinitionService extends BaseService {
  /**
   * Get current user's symptom definitions -- backend
   * @returns {Promise<SymptomDefinitionModel[]>}
   * @memberof SymptomDefinitionService
   */
  getMySymptomDefinitions() {
    return this.backend('my/symptom-definitions').then(response =>
      SymptomDefinitionsResponse(response.data)
    );
  }

  /**
   * Get the system's symptom definitions -- backend
   * @returns {Promise<SymptomDefinitionModel[]>}
   * @memberof SymptomDefinitionService
   */
  getSymptomDefinitionsBySystem() {
    return this.backend('system/symptom-definitions').then(response =>
      SymptomDefinitionsResponse(response.data)
    );
  }

  /**
   * Save system symptom definitions -- backend
   * @param {SymptomDefinitionModel[]} symptomDefinitions
   * @returns {Promise<SymptomDefinitionModel[]>}
   * @memberof SymptomDefinitionService
   */
  updateSystemSymptomDefinitions(symptomDefinitions) {
    return this.backend(
      'put',
      'system/symptom-definitions',
      SymptomDefinitionsRequest(symptomDefinitions)
    ).then(response => SymptomDefinitionsResponse(response.data));
  }

  /**
   * Save new symptom definition -- backend
   * @param {SymptomDefinitionModel} symptomDefinition
   * @returns {Promise<SymptomDefinitionModel>}
   * @memberof SymptomDefinitionService
   */
  addMySymptomDefinition(symptomDefinition) {
    return this.backend(
      'post',
      'my/symptom-definitions',
      SymptomDefinitionRequest(symptomDefinition)
    ).then(response => SymptomDefinitionResponse(response.data));
  }

  /**
   * Delete symptom definition -- backend
   * @param {string} guid SymptomDefinition guid
   * @returns {Promise}
   * @memberof SymptomDefinitionService
   */
  deleteMySymptomDefinition(guid) {
    return this.backend('delete', `my/symptom-definitions/${guid}`);
  }
}

export default new SymptomDefinitionService();
