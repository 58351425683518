<template>
  <div>
    <template v-if="$myGroupsWithPatientRole.length">
      <card-container
        :style="{
          'margin-top': '50px'
        }"
      >
        <component
          v-for="(group, index) in $myGroupsWithPatientRole"
          :is="group.IsGlobal ? 'CardGroupGlobal' : 'CardGroup'"
          :group="group"
          :show-symptoms-report-button="
            !group.IsGlobal ||
            (group.IsGlobal && $myGroupsWithPatientRole.length == 1)
          "
          :key="index"
        />
      </card-container>
    </template>

    <!-- <template v-if="$statusReports.length">
        <div>
          <h4
            class="sub-header"
            style="margin-bottom: 0"
            v-text="$t('views.start.healthOverview')"
          ></h4>
          <card-container>
            <card-health-chart></card-health-chart>
          </card-container>
        </div>
      </template> -->

    <!-- <template v-if="latestSymptomReport">
        <div>
          <h4 class="sub-header">
            <template v-if="!$advancedManikin">
              <span>{{ $t('views.start.symptomDrawings') }}</span>
            </template>
            <template v-else>
              <span
                class="slash-after"
                v-if="activeSymptomTypes.includes('pain')"
                >{{ $t('constants.SymptomTypes.pain') }}</span
              >
              <span
                class="slash-after"
                v-if="activeSymptomTypes.includes('sensoryimpairment')"
                >{{ $t('constants.SymptomTypes.sensoryimpairment') }}</span
              >
              <span v-if="activeSymptomTypes.includes('rash')">{{
                $t('constants.SymptomTypes.rash')
              }}</span>
            </template>
          </h4>
          <card-container>
            <card-symptom-report-status
              :report="latestSymptomReport"
              :previous-report="previousSymptomReport"
            ></card-symptom-report-status>
          </card-container>
        </div>
      </template> -->

    <!-- <template v-if="$incompletePromReports.length">
        <div style="max-width: 100%">
          <h4
            class="sub-header"
            v-text="$t('views.start.incompleteProms')"
          ></h4>
          <card-container>
            <card-scroll>
              <card-prom-report
                v-for="report in $incompletePromReports"
                :key="report.Id"
                :report="report"
              ></card-prom-report>
            </card-scroll>
          </card-container>
        </div>
      </template> -->
    <!-- </flex-grid> -->

    <template v-if="$latestStatus.length">
      <div style="max-width: 100%">
        <h4
          class="sub-header"
          v-text="$tc('plural.symptom', $latestStatus.length)"
        ></h4>
        <card-container>
          <card-scroll :align-bottom="true">
            <template v-for="(statusItem, index) in $latestStatus">
              <card-graph-symptom
                :symptom="statusItem.symptom"
                :key="`statistics-${index}`"
                v-if="statusItem.type === 'symptom'"
              ></card-graph-symptom>

              <!-- <card-graph-prom
                :promid="statusItem.PromId"
                :key="`statistics-${index}`"
                v-else-if="statusItem.type === 'prom'"
              ></card-graph-prom> -->
            </template>
          </card-scroll>
        </card-container>
      </div>

      <div style="text-align: center" class="mt10">
        <s-button
          data-cy="button-update-covid-form"
          class="button--blue"
          @click="
            $router.push({
              name: 'symptomReport'
            })
          "
          >{{ $t('views.start.quickactions.reportSymptoms') }}</s-button
        >
      </div>

      <div style="text-align: center" class="mt20">
        <s-button
          data-cy="button-update-covid-form"
          class="button--blue"
          @click="
            $router.push({
              name: 'symptomSelect'
            })
          "
          >{{ $t('views.start.quickactions.addSymptoms') }}</s-button
        >
      </div>

      <div style="text-align: center" class="mt20">
        <s-button
          data-cy="button-remind-me"
          class="button--blue"
          @click="
            $router.push({
              name: 'reminder'
            })
          "
          >{{ $t('views.start.remindMe') }}</s-button
        >
      </div>
    </template>

    <div
      v-if="!$latestStatus.length && !$statusReports.length"
      class="container"
      v-text="$t('views.start.newUser')"
    ></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import modelNameExtractor from '@common/Helpers/modelNameExtractor';
// import CardSymptomReportStatus from '@common/Components/Cards/CardSymptomReportStatus.vue';
// import CardHealthChart from '@/views/components/Card/CardHealthChart.vue';
// import CardPromReport from '@/views/components/Card/CardPromReport.vue';
import CardGraphSymptom from '@/views/components/Card/CardGraphSymptom.vue';
import CardGroup from '@/views/components/Card/CardGroup.vue';
import CardGroupGlobal from '@/views/components/Card/CardGroupGlobal.vue';
// import CardGraphProm from '@/views/components/Card/CardGraphProm.vue';
import {
  PROMREPORT_GETTERS,
  PROMREPORT_ACTIONS
} from '@/store/modules/promreports';
import { SYMPTOMREPORT_GETTERS } from '@/store/modules/symptomreports';
import { HISTORY_GETTERS } from '@/store/modules/history';
import { STATUSREPORT_GETTERS } from '@/store/modules/statusreports';
import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser';
import { isMobile } from '@common/Helpers/mobile';

export default {
  components: {
    CardGroup,
    CardGroupGlobal,
    // CardSymptomReportStatus,
    // CardHealthChart,
    // CardPromReport,
    CardGraphSymptom
    // CardGraphProm
  },
  data() {
    return {
      expandedPromList: false
    };
  },
  mounted() {
    if (
      this.$route.query.hasOwnProperty('verifiedemail') &&
      this.$route.query.verifiedemail == 1
    ) {
      this.$bus.$emit('toast.display', {
        message: this.$t('views.profile.emailVerified'),
        status: 'success'
      });
    }
  },
  methods: {
    isMobile,
    ...mapActions('promreports', {
      $removeReport: PROMREPORT_ACTIONS.REMOVE_REPORT
    }),
    displayPromList() {
      this.expandedPromList = !this.expandedPromList;
    },
    type(report) {
      return modelNameExtractor(report);
    },
    lastLoginIsInFuture() {
      return new Date() < new Date(this.$stats.lastLogin);
    }
  },
  computed: {
    ...mapGetters('history', {
      $latestStatus: HISTORY_GETTERS.GET_LATEST_STATUS
    }),
    ...mapGetters('promreports', {
      $incompletePromReports: PROMREPORT_GETTERS.FIND_INCOMPLETE_PROMREPORTS,
      $promReportsByProm: PROMREPORT_GETTERS.PROM_REPORTS_GROUPED_BY_PROM
    }),
    ...mapGetters('statusreports', {
      $hasReportedStatus: STATUSREPORT_GETTERS.HAS_REPORTED_STATUS,
      $statusReports: STATUSREPORT_GETTERS.STATUS_REPORTS
    }),
    ...mapGetters('symptomreports', {
      $symptomReports: SYMPTOMREPORT_GETTERS.SYMPTOM_REPORTS
    }),
    ...mapGetters('currentuser', {
      $myGroupsWithPatientRole:
        CURRENT_USER_GETTERS.MY_GROUPS_WITH_PATIENT_ROLE,
      $user: CURRENT_USER_GETTERS.USER,
      $stats: CURRENT_USER_GETTERS.STATS,
      $advancedManikin: CURRENT_USER_GETTERS.ADVANCED_MANIKIN
    }),
    arrowDirection() {
      return this.expandedPromList ? 'mdi-chevron-down' : 'mdi-chevron-right';
    },
    latestSymptomReport() {
      return this.$symptomReports[0];
    },
    previousSymptomReport() {
      return this.$symptomReports[1];
    },
    activeSymptomTypes() {
      const latestTypes = this.latestSymptomReport.Symptoms.map(
        symptom => symptom.Type
      );

      const types = latestTypes.filter((v, i, a) => a.indexOf(v) === i);

      return types;
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 0;
}
.quincy-wrapper {
  height: 500px !important;
}
</style>
