/**
 * Validates parameter given SSN. Returns true if SSN is valid, otherwise false.
 * @param ssn - {String}
 * @returns {boolean}
 */
export const ssn = ssn => {
  if (ssn === undefined || ssn === null) {
    return false;
  }

  const ssnAsArray = parse(ssn);
  if (ssnAsArray.length === 10) {
    const checkDigit = ssnAsArray.pop();
    switch (getChecksum(ssnAsArray)) {
      case checkDigit:
        return true;
      default:
        return false;
    }
  } else {
    return false;
  }
};

const flatMap = (arr, f) => [].concat.apply([], arr.map(f));

function getChecksum(ssn) {
  const luhn = [2, 1, 2, 1, 2, 1, 2, 1, 2];
  const multiplied = ssn.map((e, i) => {
    return e * luhn[i];
  });
  const digits = flatMap(multiplied, n => (n >= 10 ? [sumOfNum(n)] : [n]));

  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const sumOfDigits = digits.reduce(reducer);
  const checksum = Math.floor((sumOfDigits * 9) % 10);
  return checksum;
}

function sumOfNum(num) {
  return Math.floor(num % 10) + Math.floor(num / 10);
}

/**
 * Parse parameter given SSN string. Remove all characters except digits.
 * @param ssn - {String} SSN to parse
 * @returns Int[]
 */
function parse(ssn) {
  const cleaned = ssn.replace(/\D/g, '').split('');
  if (cleaned.length === 12) {
    return cleaned.slice(2, 12).map(Number);
  }
  return cleaned.map(Number);
}
