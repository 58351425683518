import BugReportModel from '@common/Models/BugReportModel';

/**
 * Map remote BugReport to local
 * @typedef BugReportResponse
 * @param {object} dtoBugReport
 * @returns {BugReportModel}
 */
export const BugReportResponse = dtoBugReport => {
  return new BugReportModel(dtoBugReport);
};

/**
 * Map remote BugReports to local
 * @param {object[]} dtoBugReports
 * @returns {BugReportModel[]}
 */
export const BugReportsResponse = dtoBugReports => {
  return dtoBugReports.map(dto => BugReportResponse(dto));
};
