// @ts-check
import { randomString } from '@common/Helpers/strings';
import ContractParagraphModel from '@common/Models/ContractParagraphModel';
import { required } from '@vuelidate/validators';
import { sortByField } from '@common/Helpers/sorters';

export class ContractSectionModel {
  constructor({ Index, Title, Paragraphs = [] }) {
    this.Title = Title;
    this.Index = Index;
    this.Paragraphs = Paragraphs.map(p => new ContractParagraphModel(p)).sort(
      sortByField('Index')
    );

    this._sortableKey = randomString();
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }

  static get validations() {
    return {
      Title: {
        required
      },
      Index: {},
      Paragraphs: {}
    };
  }
}

export default ContractSectionModel;
