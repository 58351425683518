import { PROM_MUTATIONS } from '@/store/modules/proms';
import { PROMEDIT_MUTATIONS } from '@/store/modules/proms/edit';
import isEqual from 'lodash';

const saveOnNamespace = ['proms/edit'];
const skipSaveOn = [
  PROMEDIT_MUTATIONS.SET_ACTIVE_PROM,
  PROMEDIT_MUTATIONS.UPDATE_STATUS_TYPES
];

export const promPlugin = store => {
  store.subscribe((mutation, state) => {
    // eslint-disable-next-line prefer-const
    let [full, namespace, type] = mutation.type.match(/([a-z/]+)([A-Z_]+)/);
    namespace = namespace.replace(/\/$/, '');
    console.log(namespace);
    if (saveOnNamespace.includes(namespace) && !skipSaveOn.includes(type)) {
      if (
        type === PROMEDIT_MUTATIONS.UPDATE_STATUS_TYPES &&
        mutation.payload.length === 0
      ) {
        return;
      }

      const activeProm = state.proms.edit.prom;
      const stateProm = state.proms.unsaved.find(p => p.Id === activeProm.Id);

      if (
        stateProm &&
        (stateProm[mutation.payload.property] ===
          activeProm[mutation.payload.property] ||
          isEqual(
            stateProm[mutation.payload.property],
            activeProm[mutation.payload.property]
          ))
      ) {
        console.log('no change');
      } else if (
        stateProm &&
        (stateProm[mutation.payload.property] ===
          activeProm[mutation.payload.property] ||
          !isEqual(
            stateProm[mutation.payload.property],
            activeProm[mutation.payload.property]
          ))
      ) {
        console.log(
          stateProm[mutation.payload.property],
          activeProm[mutation.payload.property]
        );
        store.commit(`proms/${PROM_MUTATIONS.ADD_IMPORTED_PROM}`, activeProm);
      }
    }
  });
};
