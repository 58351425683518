<template>
  <div
    class="status-report-header"
    data-cy="status-report-header"
    :style="{ backgroundImage }"
  >
    {{ $t('views.start.hi', { name: $user ? $user.FirstName : '' }) }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser';
import { getPartOfDay } from '@common/Helpers/time';

export default {
  methods: {
    getPartOfDay
  },
  computed: {
    ...mapGetters('currentuser', {
      $user: CURRENT_USER_GETTERS.USER,
      $stats: CURRENT_USER_GETTERS.STATS
    }),
    backgroundImage() {
      return `url(/images/${getPartOfDay().toLowerCase()}.jpg)`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';

.status-report-header {
  margin-top: -20px;
  padding: 70px 50px;
  display: flex;
  justify-content: center;
  font-size: 32px;
  align-items: center;
  background-size: cover;
  color: #fff;
  text-shadow: 2px 5px 5px rgba(0, 0, 0, 1);

  @media only screen and (max-width: $breakpoint-phone) {
    padding: 50px 20px;
    font-size: 25px;
  }
}
</style>
