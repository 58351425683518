import { orderBy, sortBy } from 'lodash';
import moment from 'moment';

export const sortByField = field => {
  return (a, b) => {
    if (a[field] < b[field]) return -1;
    if (a[field] > b[field]) return 1;
    return 0;
  };
};

export const sortBySimple = (a, b) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export const sortByIndex = (a, b) => {
  if (a.Index < b.Index) return -1;
  if (a.Index > b.Index) return 1;
  return 0;
};

export const sortByNumber = (a, b) => {
  if (a.Group.Number < b.Group.Number) return -1;
  if (a.Group.Number > b.Group.Number) return 1;
  return 0;
};

export const sortByValue = (a, b) => {
  if (a.Value < b.Value) return -1;
  if (a.Value > b.Value) return 1;
  return 0;
};

export const sortByCreatedDate = collection => {
  return sortBy(collection, item => moment(item.CreatedDate));
};

export const sortByKey = (collection, key) => {
  return sortBy(collection, item => moment(item[key]));
};

export const orderByKeys = (collection, keys, orders) => {
  return orderBy(collection, keys, orders);
};

export const sortByValueKey = (collection, key) => {
  return sortBy(collection, item => {
    if (typeof item[key] === 'string') {
      return item[key].toLowerCase();
    }
    return item[key];
  });
};
