export const STATUSREPORT_MUTATIONS = {
  SET_REPORTS: 'SET_REPORTS',
  ADD_REPORT: 'ADD_REPORT',
  SAVE_SUCCESSFUL: 'SAVE_SUCCESS',
  SAVE_FAILED: 'SAVE_FAILED',
  SET_LATEST_REPORT: 'SET_LATEST_REPORT',
  REMOVE_REPORT: 'REMOVE_REPORT',
  SET_POTENTIAL_FOLLOWUPS: 'SET_POTENTIAL_FOLLOWUPS',
  CLEAR_FOLLOWUPS: 'CLEAR_FOLLOWUPS',
  COMPLETE_FOLLOWUP: 'COMPLETE_FOLLOWUP',
  ADD_SUMMARY: 'ADD_SUMMARY',
  SET_PROM_SUGGESTIONS: 'SET_PROM_SUGGESTIONS',
  ADD_PROM_SUGGESTION: 'ADD_PROM_SUGGESTION',
  REMOVE_PROM_SUGGESTION: 'REMOVE_PROM_SUGGESTION',
  SET_HAS_REPORTED_STATUS: 'SET_HAS_REPORTED_STATUS',
  SET_UNSAVED_REPORT: 'SET_UNSAVED_REPORT',
  REMOVE_UNSAVED_REPORT: 'REMOVE_UNSAVED_REPORT'
};

export const STATUSREPORT_ACTIONS = {
  $PREINIT: '$PREINIT',
  NEW_REPORT: 'NEW_REPORT',
  REMOVE_REPORT: 'REMOVE_REPORT',
  GET_STATUS_REPORT: 'GET_STATUS_REPORT',
  GET_STATUS_REPORTS: 'GET_STATUS_REPORTS',
  GET_STATUS_REPORTS_SUMMARY: 'GET_STATUS_REPORTS_SUMMARY',
  GET_POTENTIAL_FOLLOWUPS: 'GET_POTENTIAL_FOLLOWUPS',
  SAVE_REPORT: 'SAVE_REPORT',
  UPDATE_REPORT: 'UPDATE_REPORT',
  GET_PROM_SUGGESTIONS: 'GET_PROM_SUGGESTIONS',
  HIDE_SUGGESTIONS: 'HIDE_SUGGESTIONS',
  SAVE_TEMP_REPORT: 'SAVE_TEMP_REPORT'
};

export const STATUSREPORT_GETTERS = {
  STATUS_REPORTS: 'STATUS_REPORTS',
  TYPES: 'TYPES',
  FIND_REPORT: 'FIND_REPORT',
  FIND_REPORTS_BY_TYPE: 'FIND_REPORTS_BY_TYPE',
  LATEST_REPORT: 'LATEST_REPORT',
  LATEST_HAS_TYPES: 'LATEST_HAS_TYPES',
  FOLLOWUP_ACTIONS: 'FOLLOWUP_ACTIONS',
  REPORT_GRAPH_DATA: 'REPORT_GRAPH_DATA',
  SUMMARY_GRAPH_DATA: 'SUMMARY_GRAPH_DATA',
  SUMMARY_GRAPH_DATA_WITHOUT_IGNORED: 'SUMMARY_GRAPH_DATA_WITHOUT_IGNORED',
  SUMMARY_GRAPH_HAS_VALUES: 'SUMMARY_GRAPH_HAS_VALUES',
  AREA_GRAPH_DATA: 'AREA_GRAPH_DATA',
  INVERTED_TYPES: 'INVERTED_TYPES',
  SUGGESTIONS: 'SUGGESTIONS',
  HAS_REPORTED_STATUS: 'HAS_REPORTED_STATUS',
  UNSAVED_REPORT: 'UNSAVED_REPORT'
};
