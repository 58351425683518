<template>
  <div
    :data-badge="badge"
    v-if="badge > 0"
    :style="{ right: `${right}px`, top: `${top}px`, left: `${left}px` }"
  ></div>
</template>

<script>
export default {
  props: {
    badge: {
      type: Number,
      default: 0
    },
    right: {
      type: Number
    },
    left: {
      type: Number
    },
    top: {
      type: Number
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';

[data-badge] {
  position: absolute;
  height: 18px;
  top: -5px;
  right: 10px;
}

[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  font-size: 0.7em;
  background: $symptoms-red;
  color: $symptoms-white;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  box-shadow: 0 0 1px #333;
}
</style>
