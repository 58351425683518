import BaseService from '../BaseService';
import { EnvironmentResponse, ProfileResponse } from './mappers/ViewResponses';

class ViewService extends BaseService {
  getEnvironment() {
    return this.backend('my/views/environment').then(response =>
      EnvironmentResponse(response.data)
    );
  }

  getProfile() {
    return this.backend('my/views/profile').then(response =>
      ProfileResponse(response.data)
    );
  }
}

export default new ViewService();
