<template>
  <div>
    <div class="container">
      <h4
        v-html="$t('generic.graphs')"
        class="header pb20"
        data-cy="graphs-text-graphs"
      ></h4>
    </div>
    <div v-for="symptom in symptoms" :key="symptom.Id" class="mb40">
      <div class="container">
        <info-row-symptom-vue
          :symptom="symptom"
          :hide-buttons="true"
        ></info-row-symptom-vue>
        <div v-if="symptoms.length == 0">
          {{ $t('views.history.noReportsFound') }}
        </div>
      </div>
      <history-graph
        :graph-data="[{ data: graphDataSymptom(symptom) }]"
        :height="200"
      ></history-graph>
    </div>
    <div v-for="promId in proms" :key="promId" class="mb40">
      <div class="container">
        <info-row-prom-vue
          :prom="$findProm(promId)"
          :route-to="{
            name: 'promReportsByProm',
            params: { promid: promId }
          }"
          :active="false"
        ></info-row-prom-vue>
        <div v-if="proms.length == 0">
          {{ $t('views.history.noReportsFound') }}
        </div>
      </div>
      <history-graph
        :graph-data="[{ data: graphDataProm(promId) }]"
        :height="200"
      ></history-graph>
    </div>
  </div>
</template>

<script>
import { HISTORY_GETTERS } from '@/store/modules/history';
import { PROM_GETTERS } from '@/store/modules/proms';
import { PROMREPORT_GETTERS } from '@/store/modules/promreports';
import { mapGetters } from 'vuex';
import InfoRowSymptomVue from '../components/InfoRow/InfoRowSymptom.vue';
import InfoRowPromVue from '../components/InfoRow/InfoRowProm.vue';
import HistoryGraph from '@common/Components/Graphs/HistoryGraph.vue';
import { SYMPTOM_GETTERS } from '@/store/modules/symptoms';
import { sortByKey } from '@common/Helpers/sorters';

export default {
  components: { InfoRowPromVue, InfoRowSymptomVue, HistoryGraph },
  methods: {
    graphDataSymptom(symptom) {
      return sortByKey(this.$symptomGraphData(symptom), 'date');
    },
    graphDataProm(prom) {
      return sortByKey(this.$promGraphData(prom), 'date');
    }
  },
  computed: {
    ...mapGetters('history', {
      $latestStatus: HISTORY_GETTERS.GET_LATEST_STATUS_ALL_SYMPTOMS
    }),
    ...mapGetters('symptoms', {
      $symptomGraphData: SYMPTOM_GETTERS.SYMPTOM_GRAPH_DATA
    }),
    ...mapGetters('proms', {
      $findProm: PROM_GETTERS.FIND_PROM
    }),
    ...mapGetters('promreports', {
      $promGraphData: PROMREPORT_GETTERS.PROM_GRAPH_DATA
    }),
    symptoms() {
      return this.$latestStatus
        .filter(x => x.type === 'symptom')
        .map(x => x.symptom);
    },
    proms() {
      return this.$latestStatus
        .filter(x => x.type === 'prom' && !this.$findProm(x.PromId).HideScore)
        .map(x => x.PromId);
    }
  }
};
</script>

<style lang="scss" scoped>
.info-row {
  margin-bottom: -5px;
}
</style>
