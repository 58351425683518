import StatusReportModel from '@common/Models/StatusReportModel';
import StatusReportItemModel from '@common/Models/StatusReportItemModel';
import { StatusReportResponse } from './mappers/StatusReportResponses';
import BaseService from '../BaseService';

class StatusReportService extends BaseService {
  /**
   * Get current user's status reports -- backend
   * @returns {Promise<StatusReportModel[]>}
   * @memberof StatusReportService
   */
  getMyStatusReports() {
    return this.backend('my/statusreports').then(response =>
      StatusReportsResponse(response.data)
    );
  }

  /**
   * Get specific status report for current user -- backend
   *
   * @param {string} guid StatusReport Id
   * @returns {Promise<StatusReportModel>}
   * @memberof StatusReportService
   */
  findMyStatusReport(guid) {
    return this.backend(`my/statusreports/${guid}`).then(response =>
      StatusReportResponse(response.data)
    );
  }

  /**
   * Save StatusReport -- backend
   * @param {StatusReportItemModel[]} reportArray StatusReportItems
   * @returns {Promise<StatusReportModel>}
   * @memberof StatusReportService
   */
  addMyStatusReport(reportArray) {
    return this.backend('post', 'my/statusreports', reportArray).then(
      response => StatusReportResponse(response.data)
    );
  }

  /**
   * Update StatusReport -- backend
   * @param {StatusReportItemModel} report StatusReportItem
   * @returns {Promise<StatusReportModel>}
   * @memberof StatusReportService
   */
  updateMyStatusReport(report) {
    return this.backend('put', 'my/statusreports', report).then(response =>
      StatusReportResponse(response.data)
    );
  }

  /**
   * Delete StatusReport -- backend
   * @param {string} guid StatusReport Id
   * @returns {Promise}
   * @memberof StatusReportService
   */
  deleteMyStatusReport(guid) {
    return this.backend('post', `my/statusreports/${guid}/delete`);
  }

  /**
   * Purge deleted StatusReport -- backend
   * @param {string} guid StatusReport Id
   * @returns {Promise}
   * @memberof StatusReportService
   */
  purgeMyStatusReport(guid) {
    return this.backend('delete', `my/statusreports/${guid}`);
  }

  /**
   * Restore deleted StatusReport -- backend
   * @param {string} guid StatusReport Id
   * @returns {Promise}
   * @memberof StatusReportService
   */
  restoreMyStatusReport(guid) {
    return this.backend('post', `my/statusreports/${guid}/restore`);
  }
}

export default new StatusReportService();
