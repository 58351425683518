import Loader from '@common/Helpers/Loader';
import { Bus } from '@common';

export const ResponseInterceptor = response => {
  Loader.done();
  window.offline = false;

  Bus.$emit('connection', true);

  return response;
};
