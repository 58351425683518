import mitt from 'mitt';

export class Bus {
  constructor() {
    this.bus = mitt();
  }

  $emit() {
    this.bus.emit(...arguments);
  }

  $on() {
    this.bus.on(...arguments);
  }

  $off() {
    this.bus.off(...arguments);
  }
}

export default new Bus();
