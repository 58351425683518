import SymptomReportSymptomModel from '@common/Models/SymptomReport/SymptomReportSymptomModel';

/**
 * Map remote SymptomReportSymptom to local
 * @param {object} dtoSymptomReportSymptom
 * @returns {SymptomReportSymptomModel}
 */
export const SymptomReportSymptomResponse = dtoSymptomReportSymptom => {
  return new SymptomReportSymptomModel(dtoSymptomReportSymptom);
};

/**
 * Map remote SymptomReportSymptoms to local
 * @param {object[]} dtoSymptomReportSymptoms
 * @returns {SymptomReportSymptomModel[]}
 */
export const SymptomReportSymptomsResponse = dtoSymptomReportSymptoms => {
  return dtoSymptomReportSymptoms.map(dto => SymptomReportSymptomResponse(dto));
};
