import { PROMEDIT_MUTATIONS } from '@/store/modules/proms/edit';
import storage from '@common/Helpers/storage';

const saveOnNamespace = ['proms/edit'];
const skipSaveOn = [
  PROMEDIT_MUTATIONS.SET_ACTIVE_PROM,
  PROMEDIT_MUTATIONS.SET_HISTORY_LENGTH,
  PROMEDIT_MUTATIONS.TOGGLE_OPEN_ITEM,
  PROMEDIT_MUTATIONS.UNDO
];

export const localstoragePlugin = store => {
  store.subscribe((mutation, state) => {
    // eslint-disable-next-line prefer-const
    let [full, namespace, type] = mutation.type.match(/([a-z/]+)([A-Z_]+)/);
    namespace = namespace.replace(/\/$/, '');

    if (saveOnNamespace.includes(namespace) && !skipSaveOn.includes(type)) {
      if (
        type === PROMEDIT_MUTATIONS.UPDATE_STATUS_TYPES &&
        mutation.payload.length === 0
      ) {
        return;
      }

      const activeProm = state.proms.edit.prom;
      storage.set(`${namespace}/${activeProm.Id}`, activeProm);
    }
  });
};
