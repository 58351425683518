<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1114"
    viewBox="0 0 1114 200"
    preserveAspectRatio="xMidYMid meet"
  >
    <g id="Layer">
      <path
        id="Layer"
        class="shp0"
        d="M429.7 1.8C428.2 3.5 428 9.5 428 68.2L428 132.8L431.3 139.2C436.6 149.5 445 154 458.5 153.9C470.3 153.9 475.9 150.4 474.5 144C473.4 139.1 470.5 137.8 462 138.6C452.1 139.4 447.6 137.6 444.9 131.6C443.2 127.8 443 123.7 443 66C443 -3.6 443.4 0 435.6 0C432.7 0 430.8 0.6 429.7 1.8Z"
      />
      <path
        id="Layer"
        class="shp0"
        d="M23.2 43.5C7.8 47.8 0 57.3 0 71.5C0 81.2 1.5 85.5 6.6 91C11 95.7 13.2 96.9 34.2 106C47.1 111.5 51 115.6 51 123.4C51 137.8 36.6 143.3 16.7 136.6C12.5 135.2 7.7 134 6.1 134C-0.1 134 -2.5 142.9 2.7 146.9C7.9 151.1 18.4 153.5 31 153.5C42.1 153.5 42.8 153.4 49.3 150.2C71.6 139.2 73.1 109.6 52 96.6C50.1 95.4 42.7 92 35.5 89.1C19.1 82.5 15 78.8 15 70.8C15 58.6 31.2 53.3 49.6 59.5C58.3 62.4 59.8 62.5 62.8 60.4C65.7 58.4 65.9 51.9 63.1 49.1C57.8 43.8 34 40.4 23.2 43.5Z"
      />
      <path
        id="Layer"
        class="shp0"
        d="M200.3 43.5C190.4 45.3 180.6 48.7 178.4 51.1C176.6 53.1 176.5 56 176.2 100.9C175.9 148.1 176 148.7 178 151.3C180.6 154.6 185.6 154.9 189.1 152.1L191.5 150.2L191.8 105.2L192.1 60.1L199 58.6C209.4 56.2 220.6 56.5 226 59.2C234.8 63.8 234.4 61.5 235 109.2C235.5 149.3 235.6 151.2 237.4 152.6C240.4 154.8 245.3 154.3 247.8 151.7C250 149.4 250 149.1 250 106.3L250 63.1L255.8 60.4C263.8 56.6 274.9 55.9 280.7 58.9C285.7 61.4 290.7 67.2 292 72C292.6 74 293 91.4 293 112.1C293 147.6 293.1 148.8 295.1 151.4C297.6 154.6 302.6 154.9 306.1 152.1L308.5 150.2L308.8 114.5C309 92.7 308.7 76.6 308 73.2C305.7 60.7 298.4 51 287 45.6C281.1 42.8 279.6 42.5 271 42.5C260.8 42.5 254.2 44.2 246 48.8L241.4 51.3L237 48.2C228.6 42.3 215.7 40.7 200.3 43.5Z"
      />
      <path
        id="Layer"
        fill-rule="evenodd"
        class="shp0"
        d="M368 42.5C380.4 42.8 387.5 45.5 394.7 52.3C405.2 62.2 407.5 72.3 406.7 103.9C406.2 125.6 405.3 130.2 399.9 138.4C392.7 149.2 380.3 154.6 364.6 153.8C358 153.4 353.8 152.6 349.3 150.8L343 148.2L343 172C343 194.2 342.9 195.8 341.1 197.4C338.2 200 332 199.8 329.8 197C328.1 194.9 328 190.5 328 123.8L328 52.8L330.8 50C333 47.9 335.7 46.8 343.1 45.1C348.3 44 353.9 42.8 355.5 42.6C357.2 42.4 362.8 42.3 368 42.5ZM349.3 58.7L343 60.1L343 130.9L346.3 133.4C351.6 137.4 357.7 139.2 366.5 139.3C375.9 139.4 380.9 137.4 385.9 131.3C390.5 125.7 391.2 120.4 390.8 95C390.5 74.2 390.3 72.2 388.3 68.5C385.6 63.5 382.5 60.5 377.8 58.5C373 56.6 357.8 56.6 349.3 58.7Z"
      />
      <path
        id="Layer"
        class="shp0"
        d="M456.3 44C452.4 46.3 450.7 51.4 452.1 56.6C455.3 68 473 64.9 473 52.9C473 49.6 472.4 48.2 469.6 45.4C465.6 41.4 461.3 40.9 456.3 44Z"
      />
      <path
        id="Layer"
        fill-rule="evenodd"
        class="shp0"
        d="M529 42.5C547 43.3 558.9 51.3 565.3 67.1C567.2 72 567.5 74.8 567.8 95C568.2 120.1 567.6 125.1 562.7 134.3C556 147.1 543.3 153.9 526.5 154C505.9 154 490.8 142.7 486.6 124C484.7 115.8 484.4 83.7 486.1 74.4C489 58.4 500.6 46.3 516 43C518.5 42.5 524.3 42.3 529 42.5ZM516.2 58.3C510.9 60.3 505.9 64.9 503.4 70.4C501.1 75.3 501 76.4 501 97.6C501 122.7 501.6 126 507.7 132.2C518.5 143.3 540.5 141.5 548.3 128.9C552.2 122.5 553.3 113.8 552.8 93C552.4 78.2 552.1 74.8 550.3 71C547.8 65.4 541.4 59.5 536.3 58.1C531.3 56.7 520.4 56.8 516.2 58.3Z"
      />
      <path
        id="Layer"
        class="shp0"
        d="M610.3 43.5C600 45.4 592.9 47.9 589.8 50.8L587 53.4L587 102.1L587 150.9L589.2 152.4C592.5 154.7 597.3 154.4 599.8 151.7C602 149.4 602 149.2 602 104.8L602 60.2L608.9 58.6C612.8 57.7 619.6 57 624.1 57C633.9 57 639.8 59.3 643 64.4C644.9 67.6 645 69.2 645 108.2C645 147.7 645.1 148.8 647.1 151.4C649.4 154.4 654.3 154.9 657.6 152.6C659.4 151.2 659.5 149.3 660 107.3L660.5 63.5L666.5 60.5C671.8 57.9 673.5 57.5 681.1 57.5C688.8 57.5 690.1 57.8 693.6 60.2C698.5 63.7 701.6 68.2 702.9 74C703.5 76.6 704 93.6 704 114.7L704 150.9L706.2 152.4C709.5 154.8 714.3 154.4 716.9 151.6L719.1 149.3L718.8 109.4L718.5 69.5L715.2 62.8C705.3 42.6 681.1 36.3 658.1 48L652.2 50.9L646.5 47.5C637.2 42 625.8 40.7 610.3 43.5Z"
      />
      <path
        id="Layer"
        class="shp0"
        d="M760.3 43C755.7 44 749 47.1 744.4 50.6C734.3 58.4 732.1 77.9 740.1 89C744 94.4 748.3 97 763.9 103.5C771.3 106.5 778.9 110.2 780.8 111.7C787.1 116.4 788.8 125 784.7 131.1C778.5 140.4 766.8 142.1 751 136.1C740.8 132.2 735 134 735 141.1C735 145.3 737.9 147.8 746 150.7C752.5 153 755.1 153.4 766 153.4C780.2 153.4 784.5 152.1 792 145.4C799 139.1 801.3 134 801.8 124C802.3 114.1 800.7 109.2 795 102.7C790.8 97.9 786.1 95.2 773.1 90C753.9 82.4 751 79.9 751 70.8C751 67 751.6 65.4 754 62.6C755.7 60.7 758.7 58.6 760.7 57.9C765.9 56.2 780.3 57.3 786.8 59.9C793.4 62.5 795.1 62.5 798.4 59.9C803.2 56.1 801.2 49.3 794.4 46.5C785.3 42.7 769.9 41.1 760.3 43Z"
      />
      <path
        id="Layer"
        class="shp0"
        d="M83.3 44.2C79.8 45.9 78.7 49.8 80.3 55C81.1 57.5 89.2 80.3 98.4 105.8C107.5 131.2 115 152.3 115 152.7C115 153 113.6 157.7 111.9 163.2C107.4 178 101.4 183.7 88.5 185.5C80.1 186.6 76.6 192.2 80.6 197.9C82.1 200 82.8 200.2 90.4 199.8C104.2 199.2 115.9 191.9 121.9 180.3C124.4 175.2 163 53.3 163 50.2C163 47.2 158.7 43 155.6 43C151.9 43 150 44 148.6 46.9C147.9 48.3 142 67.2 135.5 88.7C129 110.3 123.4 128 123 128C122.7 128 116 109.6 108.1 87C100.2 64.5 93.2 45.6 92.5 45C90.6 43.4 85.7 43 83.3 44.2Z"
      />
      <path
        id="Shape 1"
        class="shp1"
        d="M816 43L1114 43L1114 151L816 151L816 43Z"
      />
      <path
        id="BETA "
        fill="#ffffff"
        d="M827.93 138L870.85 138C882.8 138 891.73 127.68 891.73 114.92C891.73 101 881.18 95.89 873.52 95.89L873.52 95.2C881.76 94.85 888.6 90.21 888.6 77.8C888.6 65.27 880.6 57.03 867.26 57.03L827.93 57.03ZM845.56 104.59L865.86 104.59C870.5 104.59 873.4 107.26 873.4 111.9L873.4 115.84C873.4 120.48 870.5 123.15 865.86 123.15L845.56 123.15ZM845.56 71.88L863.08 71.88C867.72 71.88 870.5 74.66 870.5 79.3L870.5 82.78C870.5 87.42 867.72 90.21 863.08 90.21L845.56 90.21ZM960.4 138L960.4 122.34L922.94 122.34L922.94 104.82L955.07 104.82L955.07 89.28L922.94 89.28L922.94 72.69L960.4 72.69L960.4 57.03L905.3 57.03L905.3 138ZM1031.4 72.69L1031.4 57.03L969.92 57.03L969.92 72.69L991.84 72.69L991.84 138L1009.47 138L1009.47 72.69ZM1104.13 138L1077.8 57.03L1055.99 57.03L1029.31 138L1047.17 138L1052.97 118.74L1080 118.74L1085.8 138ZM1075.71 103.78L1057.15 103.78L1066.08 73.04L1066.66 73.04Z"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
tspan {
  white-space: pre;
}
.shp1 {
  fill: #ff0000;
}
</style>
