<template>
  <div v-if="prom" class="pull-to-top">
    <history-graph
      v-if="!prom.HideScore"
      :graph-data="[{ data: graphData }]"
      :height="200"
    ></history-graph>

    <div class="container">
      <h4 class="sub-header mt0">{{ prom.Title }}: {{ prom.Concept }}</h4>
      <info-row-details-prom-report
        v-for="report in reports.reverse()"
        :key="report.Id"
        :report="report"
      ></info-row-details-prom-report>
    </div>

    <float-content>
      <button-home></button-home>
      <s-button @click="newReport" :primary="true">{{
        $t('generic.fillOut')
      }}</s-button>
    </float-content>
  </div>
</template>

<script>
import {
  PROMREPORT_GETTERS,
  PROMREPORT_ACTIONS
} from '@/store/modules/promreports';
import { PROM_GETTERS } from '@/store/modules/proms';
import HistoryGraph from '@common/Components/Graphs/HistoryGraph';
import { mapGetters, mapActions } from 'vuex';
import { sortByKey } from '@common/Helpers/sorters';
import InfoRowDetailsPromReport from '@/views/components/InfoRow/InfoRowDetailsPromReport.vue';

export default {
  name: 'PromReportsByPromView',
  components: {
    HistoryGraph,
    InfoRowDetailsPromReport
  },
  props: {
    promid: {
      type: String
    }
  },
  data() {
    return {
      confirm: false,
      reportToRemove: null
    };
  },
  methods: {
    ...mapActions('promreports', {
      $removeReport: PROMREPORT_ACTIONS.REMOVE_REPORT
    }),
    gotoReport(report) {
      this.$router.push({
        name: 'promReportEdit',
        params: { promid: report.PromId, promreportid: report.Id }
      });
    },
    removeReport(report) {
      this.$removeReport(report).then(() => {
        if (this.reports.length === 0) {
          this.$router.replace({ name: 'start' });
        }
      });
    },
    newReport() {
      this.$router.push({
        name: 'promReportEdit',
        params: { promid: this.promid }
      });
    }
  },
  computed: {
    ...mapGetters('proms', {
      $findProm: PROM_GETTERS.FIND_PROM
    }),
    ...mapGetters('promreports', {
      $findReportsByPromId: PROMREPORT_GETTERS.FIND_PROMREPORTS_BY_PROMID,
      $graphData: PROMREPORT_GETTERS.PROM_GRAPH_DATA
    }),
    graphData() {
      return sortByKey(this.$graphData(this.promid), 'date');
    },
    reports() {
      return sortByKey(this.$findReportsByPromId(this.promid), 'CreatedDate');
    },
    prom() {
      return this.$findProm(this.promid);
    }
  }
};
</script>
