<template>
  <div class="news-item">
    <div class="news-item__container">
      <div class="news-item__title">
        <slot name="title">{{ newsItem.Title }}</slot>
      </div>
      <div class="news-item__content">
        <slot name="content"></slot>
      </div>
      <div class="news-item__publishdate">
        <slot name="publishdate">{{
          $filters.DateTimeFormat(newsItem.PublishDate)
        }}</slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    newsItem: {
      required: false
    }
  },
  components: {},
  mounted() {},
  methods: {},
  computed: {}
};
</script>

<style lang="scss">
.news-item {
  background-color: #fff;
  color: black;
  border-radius: 6px;
  margin-top: 20px;

  &__container {
    position: relative;
    padding: 20px;
  }

  &__content {
    margin: 10px 0;
  }

  &__title {
    font-weight: 700;
  }

  &__publishdate {
    font-size: smaller;
    color: #777;
  }
}
</style>
