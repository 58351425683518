export const StatusReportAreaColors = {
  anxiety: '#6f6f6f',
  appetite: '#4738f2',
  depression: '#e3e3e3',
  dizziness: '#d7c6cf',
  drowsiness: '#7b72b6',
  hearingdisorders: '#ffa9ef',
  nausea: '#d6ea3a',
  pain: '#ff0000',
  physicalfunctioning: '#7c9d45',
  qualityoflife: '#12b629',
  sexualfunctioning: '#990027',
  shortnessofbreath: '#aaceff',
  skinproblems: '#f9d5d5',
  stomachtrouble: '#d2b48c',
  stress: '#ffab43',
  tiredness: '#cda8d9',
  urinarytractdisorders: '#ffff66',
  visionproblems: '#00cccc',
  wellbeing: '#6ff521'
};

export const HIDE_MENU = ['choosemannequin', 'groupPromView'];

export const HIDE_MENU_MOBILE = [
  'statusReport',
  'symptomreport',
  'symptomReportDraw',
  'symptomReportGrade',
  'symptomReportNote',
  'promReportEdit',
  'symptomSelect'
];
