import AboutView from '@common/views/AboutView';
import PrivacyView from '@common/views/PrivacyView';
import AppView from '@views/AppView';
import AuditLogsView from '@views/AuditLogs/AuditLogsView';
import AuditLogView from '@views/AuditLogs/AuditLogView';
import BugReportView from '@views/BugReportView';
import CalendarView from '@views/Calendar/CalendarView';
import ConsentsView from '@views/Consents/ConsentsView.vue';
import TosView from '@views/Consents/TosView.vue';
import ContractView from '@views/ContractView.vue';
import GroupPromView from '@views/Group/GroupPromView';
import GraphsListView from '@views/Graphs/GraphsListView';
import HistoryView from '@views/History/HistoryView';
import InvitesView from '@views/Invites/InvitesView';
import NewsView from '@views/News/NewsView';
import NotesView from '@views/Notes/NotesView';
import PrivacyNoLoginView from '@views/PrivacyView';
import ProfileView from '@views/Profile/ProfileView';
import PromReportEditView from '@views/PromReport/PromReportEditView';
import PromReportFollowupView from '@views/PromReport/PromReportFollowupView';
import PromReportListView from '@views/PromReport/PromReportListView';
import PromReportsByPromView from '@views/PromReport/PromReportsByPromView';
import PromRequestView from '@views/PromRequest/PromRequestView';
import ReminderView from '@views/Reminder/ReminderView';
import SearchResultView from '@views/Search/SearchResultView';
import SharesView from '@views/Share/SharesView';
import StartView from '@views/Start/StartView';
import StatusReportFollowupView from '@views/StatusReport/StatusReportFollowupView';
import StatusReportItemGraphView from '@views/StatusReport/StatusReportItemGraphView';
import StatusReportReportGraphView from '@views/StatusReport/StatusReportReportGraphView';
import StatusReportSummaryGraphView from '@views/StatusReport/StatusReportSummaryGraphView';
import StatusReportView from '@views/StatusReport/StatusReportView';
import SymptomDetailsView from '@views/Symptom/SymptomDetailsView';
import SymptomSelectView from '@views/Symptom/SymptomSelectView';
import NewSymptomReportView from '@views/SymptomReport/NewSymptomReportView';
import SymptomReportDrawView from '@views/SymptomReport/SymptomReportDrawView';
import SymptomReportNoteView from '@views/SymptomReport/SymptomReportNoteView';
import SymptomReportGradeView from '@views/SymptomReport/SymptomReportGradeView';
import SymptomReportFollowupView from '@views/SymptomReport/SymptomReportFollowupView';
import SymptomReportView from '@views/SymptomReport/SymptomReportView';
import ChooseMannequinView from '@views/ChooseMannequinView';
import TrashView from '@views/Trash/TrashView';
import { logout, verifyEmail } from './functions';
import { GuardsCheck, requireAuth, requireEnabledModule } from './middlewares';
import store from '@/store';
import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser';

const routes = [
  {
    path: '/',
    name: 'appview',
    beforeEnter: GuardsCheck([requireAuth]),
    redirect: '/welcome',
    component: AppView,
    children: [
      {
        path: '/tos',
        name: 'tos',
        component: TosView
      },
      { path: '/about', name: 'about', component: AboutView },
      { path: '/privacy2', name: 'privacy', component: PrivacyView },
      { path: '/contract', name: 'contract', component: ContractView },
      { path: '/welcome', name: 'start', component: StartView },
      { path: '/profile', name: 'profile', component: ProfileView },
      { path: '/consents', name: 'consents', component: ConsentsView },
      {
        path: '/choosemannequin',
        name: 'choosemannequin',
        component: ChooseMannequinView
      },
      {
        path: '/news',
        name: 'news',
        component: NewsView,
        beforeEnter: GuardsCheck([requireEnabledModule])
      },
      {
        path: '/trash',
        name: 'trash',
        component: TrashView,
        beforeEnter: GuardsCheck([requireEnabledModule])
      },
      {
        path: '/statusreport',
        name: 'statusReport',
        component: StatusReportView
      },
      {
        path: '/statusreport/graph',
        name: 'statusReportGraph',
        component: StatusReportSummaryGraphView
      },
      {
        path: '/statusreport/graph/:statusItem',
        name: 'statusReportItemGraph',
        component: StatusReportItemGraphView,
        props: true
      },
      {
        path: '/statusreport/followup',
        name: 'statusReportFollowup',
        component: StatusReportFollowupView
      },
      {
        path: '/statusreport/:statusreportid',
        name: 'statusReportReportGraph',
        props: true,
        component: StatusReportReportGraphView
      },
      {
        path: '/statusreport/:statusreportid/edit',
        name: 'statusReportEdit',
        props: true,
        component: StatusReportView
      },
      {
        path: '/auditlogs',
        name: 'auditlogs',
        component: AuditLogsView,
        beforeEnter: GuardsCheck([requireEnabledModule])
      },
      {
        path: '/auditlogs/:auditlogid',
        name: 'auditlog',
        component: AuditLogView,
        beforeEnter: GuardsCheck([requireEnabledModule]),
        props: true
      },
      {
        path: '/symptomreport',
        name: 'symptomReport',
        component: NewSymptomReportView
      },
      {
        path: '/symptomreport/edit/:symptomreportid?',
        name: 'symptomReportEdit',
        component: SymptomReportView,
        props: true
      },
      {
        path: '/symptomreport/followup/:symptomreportid',
        name: 'symptomReportFollowup',
        component: SymptomReportFollowupView,
        props: true
      },
      {
        path: '/symptomreport/:symptomreportid/draw',
        name: 'symptomReportDraw',
        component: SymptomReportDrawView,
        props: true,
        beforeEnter: GuardsCheck([requireEnabledModule])
      },
      {
        path: '/symptomreport/:symptomreportid/grade',
        name: 'symptomReportGrade',
        component: SymptomReportGradeView
      },
      {
        path: '/symptomreport/:symptomreportid/note',
        name: 'symptomReportNote',
        component: SymptomReportNoteView,
        props: true,
        beforeEnter: GuardsCheck([requireEnabledModule])
      },
      {
        path: '/symptom/select',
        name: 'symptomSelect',
        component: SymptomSelectView,
        props: true,
        beforeEnter: GuardsCheck([requireEnabledModule])
      },
      {
        path: '/graphs',
        name: 'graphList',
        component: GraphsListView,
        props: true,
        beforeEnter: GuardsCheck([requireEnabledModule])
      },
      {
        path: '/symptom/:symptomid',
        name: 'symptomDetails',
        component: SymptomDetailsView,
        props: true,
        beforeEnter: GuardsCheck([requireEnabledModule])
      },
      {
        path: '/promreports/prom/:promid',
        name: 'promReportsByProm',
        component: PromReportsByPromView,
        props: true
      },
      {
        path: '/promreport/edit/:promid/:promreportid*',
        name: 'promReportEdit',
        component: PromReportEditView,
        props: true,
        beforeEnter: GuardsCheck([requireEnabledModule])
      },
      {
        path: '/promreport/followup/:promreportid',
        name: 'promReportFollowupView',
        component: PromReportFollowupView,
        props: true
      },
      {
        path: '/proms',
        name: 'promReportList',
        component: PromReportListView,
        beforeEnter: GuardsCheck([requireEnabledModule])
      },
      {
        path: '/notes/:noteId?',
        name: 'notes',
        component: NotesView,
        props: true,
        beforeEnter: GuardsCheck([requireEnabledModule])
      },
      {
        path: '/search',
        name: 'search',
        component: SearchResultView,
        props: route => ({ query: route.query.q })
      },
      {
        path: 'history',
        name: 'history',
        beforeEnter: GuardsCheck([requireEnabledModule]),
        component: HistoryView
      },
      {
        path: 'bugreport',
        name: 'bugreport',
        component: BugReportView,
        beforeEnter: GuardsCheck([requireEnabledModule])
      },
      {
        path: 'invites',
        name: 'invites',
        beforeEnter: GuardsCheck([requireEnabledModule]),
        component: InvitesView
      },
      {
        path: 'shares',
        name: 'shares',
        beforeEnter: GuardsCheck([requireEnabledModule]),
        component: SharesView
      },
      {
        path: 'calendar',
        name: 'calendar',
        beforeEnter: GuardsCheck([requireEnabledModule]),
        component: CalendarView
      },
      {
        path: 'reminder',
        name: 'reminder',
        component: ReminderView
      },
      {
        path: '/group/:groupurlslug/prom/',
        name: 'groupPromView',
        props: true,
        component: GroupPromView,
        // beforeEnter: GuardsCheck([requireAuthIndependent]),
        meta: { noAuthRequired: true }
      },
      {
        path: '/symptomlist',
        name: 'symptomlist',
        props: true,
        component: SymptomSelectView
      }
    ]
  },
  {
    path: '/admin',
    component: () =>
      import(/* webpackChunkName: "admin" */ '@views/Admin/AppView'),
    beforeEnter: GuardsCheck([requireAuth]),
    redirect: '/admin/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'adminDashboard',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/Dashboard/DashboardView'
          )
      },
      {
        path: 'organizations/:organizationId',
        name: 'adminOrganization',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/Organizations/OrganizationView.vue'
          )
      },
      {
        path: 'organizations',
        name: 'adminOrganizations',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/Organizations/OrganizationsView.vue'
          )
      },
      {
        path: 'groups/:groupId',
        name: 'adminGroup',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/Groups/GroupView.vue'
          )
      },
      {
        path: 'groups',
        name: 'adminGroups',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/Groups/GroupsView.vue'
          )
      },
      {
        path: 'applications/:applicationId',
        name: 'adminApplication',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/Applications/ApplicationView.vue'
          )
      },
      {
        path: 'applications',
        name: 'adminApplications',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/Applications/ApplicationsView'
          )
      },
      {
        path: 'oauthtokens/:oAuthTokenId?',
        name: 'adminOauthtokens',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/OAuthTokens/OAuthTokensView.vue'
          )
      },
      {
        path: 'statistics',
        name: 'adminStatistics',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/Statistics/StatisticsView.vue'
          )
      },
      {
        path: 'news/:newsItemId?',
        name: 'adminNews',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/News/NewsView.vue'
          )
      },
      {
        path: 'symptom-definitions',
        name: 'adminSymptomDefinitions',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/SymptomDefinitions/SymptomDefinitionsView.vue'
          )
      },
      {
        path: 'settings',
        name: 'adminSettings',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/Settings/SettingsView.vue'
          )
      },
      {
        path: 'emails',
        name: 'adminEmails',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/Emails/EmailsView.vue'
          )
      },
      {
        path: 'users',
        name: 'adminUsers',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/Users/UsersView.vue'
          )
      },
      {
        path: 'users/:userId',
        name: 'adminUser',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/Users/UserView.vue'
          )
      },
      {
        path: 'proms',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/Proms/PromView.vue'
          ),
        children: [
          {
            path: '',
            name: 'adminPromList',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '@views/Admin/Proms/PromListView.vue'
              )
          },
          {
            path: 'edit/:promid?',
            name: 'adminPromEditMeta',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '@views/Admin/Proms/PromEdit/EditMetaView.vue'
              )
          },
          {
            path: 'edit/:promid/questions',
            name: 'adminPromEditQuestions',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '@views/Admin/Proms/PromEdit/EditQuestionsView.vue'
              )
          }
        ]
      },
      {
        path: 'invites',
        name: 'adminInvites',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/Invites/InvitesView.vue'
          )
      },
      {
        path: 'contracts/:contractId?',
        name: 'adminContracts',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/Contracts/ContractsView.vue'
          )
      },
      {
        path: '/language-strings',
        name: 'adminLanguageStrings',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/LanguageStrings/LanguageStringsView.vue'
          )
      },
      {
        path: '/language-strings/:stringId/:languageId',
        name: 'adminLanguageString',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/LanguageStrings/LanguageStringView.vue'
          )
      },
      {
        path: '/bug-reports/',
        name: 'adminBugReports',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/BugReports/BugReportsView.vue'
          )
      },
      {
        path: '/bug-reports/:bugReportId?',
        name: 'adminBugReport',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/BugReports/BugReportView.vue'
          )
      },
      {
        path: '/app-versions/:appVersionId?',
        name: 'adminAppVersions',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/AppVersions/AppVersionView.vue'
          )
      },
      {
        path: 'research',
        name: 'adminResearch',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/Research/ResearchView.vue'
          )
      },
      {
        path: 'groupsymptoms/:model?/:fromDate?/:toDate?',
        name: 'GroupSymptomsVisualizationView',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/Research/GroupSymptomsVisualizationView.vue'
          )
      },
      {
        path: 'patients',
        name: 'adminPatients',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/Patients/PatientsView.vue'
          )
      },
      {
        path: 'patients/:patientId',
        name: 'adminPatient',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@views/Admin/Patients/PatientView.vue'
          ),
        children: [
          {
            path: 'dashboard',
            name: 'adminPatientDashboard',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '@views/Admin/Patients/PatientDashboardView.vue'
              )
          },
          {
            path: 'overview',
            name: 'adminPatientHealthOverview',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '@views/Admin/Patients/PatientHealthOverviewView.vue'
              )
          },
          {
            path: 'overview/:statusItem',
            name: 'adminStatusReportItemGraph',
            component: () =>
              import(
                /* webpackChunkName: "admin" */
                '@views/Admin/Patients/StatusReports/PatientStatusReportItemGraphView.vue'
              ),
            props: true
          },
          {
            path: 'promreports/prom/:promid',
            name: 'adminPatientpromReportsByProm',
            component: () =>
              import(
                /* webpackChunkName: "admin" */
                '@views/Admin/Patients/PromReports/PatientPromReportsByPromView.vue'
              ),
            props: true
          },
          {
            path: 'promreports/:promreportid',
            name: 'adminPatientpromReport',
            component: () =>
              /* webpackChunkName: "admin" */
              import(
                '@views/Admin/Patients/PromReports/PatientPromReportView.vue'
              ),
            props: true
          },
          {
            path: 'proms',
            name: 'adminPatientproms',
            /* webpackChunkName: "admin" */
            component: () =>
              import('@views/Admin/Patients/PatientPromsView.vue')
          },
          {
            path: 'statusreports',
            name: 'adminPatientstatusreports',
            component: () =>
              /* webpackChunkName: "admin" */
              import('@views/Admin/Patients/PatientStatusReportsView.vue')
          },
          {
            path: 'symptomreports',
            name: 'adminPatientsymptomreports',
            component: () =>
              /* webpackChunkName: "admin" */
              import('@views/Admin/Patients/PatientSymptomReportsView.vue')
          },
          {
            path: 'symptomreport',
            name: 'adminPatientsymptomreport',
            props: true,
            component: () =>
              /* webpackChunkName: "admin" */
              import('@views/Admin/Patients/PatientSymptomReportView.vue')
          },
          {
            path: 'symptom/:symptomid',
            name: 'adminPatientsymptomDetails',
            props: true,
            component: () =>
              /* webpackChunkName: "admin" */
              import(
                '@views/Admin/Patients/Symptoms/PatientSymptomDetailsView.vue'
              )
          }
        ]
      },
      {
        path: 'prom-requests',
        name: 'adminPromRequests',
        /* webpackChunkName: "admin" */
        component: () =>
          import('@views/Admin/PromRequests/PromRequestsView.vue')
      }
    ]
  },
  {
    path: '/request/:promrequestid',
    name: 'fillPromRequest',
    component: PromRequestView,
    props: true,
    meta: { noAuthRequired: true }
  },
  {
    path: '/privacy',
    name: 'privacy-nologin',
    component: PrivacyNoLoginView,
    meta: { noAuthRequired: true }
  },
  {
    path: '/login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@views/Login/LoginView'),
    children: [
      {
        path: '/',
        name: 'loginForm',
        component: () =>
          import(/* webpackChunkName: "login" */ '@views/Login/LoginFormView')
      },
      {
        path: '/register/:inviteKey?',
        name: 'register',
        component: () =>
          import(
            /* webpackChunkName: "login" */ '@common/views/RegisterUserView'
          ),
        props: true
      },
      {
        path: '/verify-email/:verifyCode',
        name: 'verifyEmail',
        beforeEnter: verifyEmail
      },
      {
        path: '/reset/:resetcode',
        name: 'resetPasswordCode'
      },
      {
        path: '/reset-password/:resetKey?',
        name: 'reset-password',
        component: () =>
          import(
            /* webpackChunkName: "login" */ '@common/views/ResetPasswordView'
          ),
        props: true
      },
      {
        path: ':groupUrlSlug?',
        name: 'loginWithPurpose',
        component: () =>
          import(/* webpackChunkName: "login" */ '@views/Login/LoginFormView'),
        props: true
      }
    ]
  },
  {
    path: '/login-loading',
    name: 'login-loading',
    component: () =>
      import(
        /* webpackChunkName: "login" */ '@views/Login/LoginWithStoredCredentialsView'
      )
  },
  { path: '/logout', name: 'logout', beforeEnter: logout },

  { path: '/**', redirect: '/login' }
];

export default routes;
