const files = require.context('./', true, /\.json$/);
const language = { environment: {} };
files.keys().forEach(key => {
  if (key.match(/index/)) return;
  const fileName = key.split('/').pop().split('.').shift();
  if (key.match(/environment/)) {
    language.environment[fileName] = files(key);
  } else {
    language[fileName] = files(key);
  }
});

export default language;
