<template>
  <div data-cy="statusreport" v-if="report">
    <status-report-header
      v-if="!statusreportid"
      class="hidden-desktop"
    ></status-report-header>
    <div class="container status-report" v-if="report.Items.length">
      <template v-if="!statusreportid">
        <h4 class="page-header">
          {{ $t('questions.howDoYouFeelNow') }}
        </h4>
        <p v-text="$t('views.statusReport.changeIfImprovedOrWorsened')"></p>
      </template>
      <template v-else>
        <h4 class="header">
          {{ $t('pageTitles.statusReport') }}:
          {{ $filters.DateTimeFormat(report.CreatedDate) }}
          <div class="page-sub-header">
            {{ $t('views.statusReport.updateReport') }}
          </div>
        </h4>
      </template>
    </div>

    <template v-if="!statusreportid">
      <template v-if="continuationQuestionList.length">
        <div class="container" data-cy="status-report-list">
          <div class="grey-text">
            {{ $t('views.statusReport.updateAnswers') }}
          </div>

          <status-report-question-list-vue
            :list="continuationQuestionList"
            @update="data => handleUpdate(data.item, data.value, data.index)"
          ></status-report-question-list-vue>
        </div>
        <hr />
      </template>
      <div class="container">
        <div class="grey-text">
          <template v-if="continuationQuestionList.length">{{
            $t('views.statusReport.moreAnswers')
          }}</template>
          <template v-else>{{
            $t('views.statusReport.answerQuestions')
          }}</template>
        </div>
        <s-tooltip
          v-if="!statusreportid"
          style="left: 50.2%; top: 155px"
          :width="300"
          :show-icon="false"
          :show-tooltip="!$hasSeenTutorial('statusreport-scale')"
          :bounce="true"
          :click-callback="() => $setHasSeenTutorial('statusreport-scale')"
          >{{ $t('views.statusReport.clickTheScale') }}</s-tooltip
        >
        <status-report-question-list-vue
          :list="newQuestionList"
          @update="data => handleUpdate(data.item, data.value, data.index)"
        ></status-report-question-list-vue>
      </div>
    </template>

    <div class="container" data-cy="status-report-list" v-else>
      <div class="grey-text">
        {{ $t('views.statusReport.oldAnswers') }}
      </div>
      <status-report-question-list-vue
        :list="sortedItems"
        @update="data => handleUpdate(data.item, data.value, data.index)"
      ></status-report-question-list-vue>
    </div>

    <float-content>
      <s-button
        @click="cancel"
        id="ButtonCancel"
        element-name="statusreport-cancelbutton"
        :dark="true"
        :flat="true"
        class="underline"
        >{{ $t('generic.cancel') }}</s-button
      >
      <s-button
        @click="nextStep()"
        id="ButtonSave"
        element-name="statusreport-savebutton"
        :loading="saveLoading"
        :primary="true"
        :disabled="nextInactive"
        >{{ $t('generic.save') }}</s-button
      >
    </float-content>

    <modal-confirm
      :show="confirmCancel"
      :accept-callback="
        () => {
          confirmedCancel = true;
          cancel();
        }
      "
      :accept-text="$t('generic.leave')"
      :show-extras="false"
      :decline-text="$t('generic.stay')"
      @close="confirmCancel = false"
    >
      <template v-slot:title>
        <span v-text="$t('modals.confirmLeaveReportTitle')"></span>
      </template>
      {{ $t('modals.confirmLeaveReport') }}
    </modal-confirm>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { APP_GETTERS } from '@/store/modules/app';
import {
  CURRENT_USER_GETTERS,
  CURRENT_USER_ACTIONS
} from '@/store/modules/currentuser';
import {
  STATUSREPORT_ACTIONS,
  STATUSREPORT_GETTERS,
  STATUSREPORT_MUTATIONS
} from '@/store/modules/statusreports';
import { StatusReportAreaColors } from '@/constants';
import StatusReportHeader from './components/StatusReportHeader.vue';
import { SYMPTOMREPORT_ACTIONS } from '../../store/modules/symptomreports';
import StatusReportQuestionListVue from './components/StatusReportQuestionList.vue';

export default {
  components: {
    StatusReportHeader,
    StatusReportQuestionListVue
  },
  props: {
    statusreportid: String
  },
  data() {
    return {
      report: null,
      saveLoading: false,
      confirmCancel: false,
      confirmedCancel: false,
      redirectOnCancel: null,
      useOldValues: true,
      subscription: null,
      unsavedReport: false
    };
  },
  beforeRouteLeave(to, from, next) {
    if (
      this.changedItems.length > 0 &&
      this.confirmCancel === false &&
      this.confirmedCancel === false &&
      this.saveLoading === false
    ) {
      this.confirmCancel = true;
      this.redirectOnCancel = to;
      next(false);
    } else {
      next();
    }
  },
  watch: {
    statusreportid(id) {
      this.report = null;
      this.getReport();
    }
  },
  created() {
    this.getReport();

    this.subscription = this.$store.subscribeAction(action => {
      if (
        action.type === `statusreports/${STATUSREPORT_ACTIONS.SAVE_TEMP_REPORT}`
      ) {
        this.$setUnsavedReport(this.report);
      }
    });
  },
  methods: {
    ...mapMutations('statusreports', {
      $setUnsavedReport: STATUSREPORT_MUTATIONS.SET_UNSAVED_REPORT
    }),
    ...mapActions('currentuser', {
      $setHasSeenTutorial: CURRENT_USER_ACTIONS.SET_HAS_SEEN_TUTORIAL
    }),
    ...mapActions('statusreports', {
      $getNewReport: STATUSREPORT_ACTIONS.NEW_REPORT,
      $saveReport: STATUSREPORT_ACTIONS.SAVE_REPORT,
      $updateReport: STATUSREPORT_ACTIONS.UPDATE_REPORT,
      $getReport: STATUSREPORT_ACTIONS.GET_STATUS_REPORT
    }),
    ...mapActions('symptomreports', {
      $addOrUpdateSymptomReport: SYMPTOMREPORT_ACTIONS.ADD_OR_UPDATE_REPORT
    }),
    getReport() {
      if (!this.statusreportid) {
        if (this.$unsavedReport) {
          this.unsavedReport = true;
          this.report = this.$unsavedReport.__copy();
          return;
        }
        this.$getNewReport({ useOldValues: this.useOldValues }).then(report => {
          this.report = report;
        });
      } else {
        this.$getReport(this.statusreportid).then(report => {
          this.report = report.__copy();
        });
      }
    },
    handleUpdate(item, value, index) {
      this.scrollToQuestion(index + 1);
      this.$setHasSeenTutorial('statusreport-scale');
    },
    scrollToQuestion(index) {
      // if (!this.$autoScroll) {
      // return;
      // }
      // if (index >= this.sortedItems.length) {
      //   return;
      // }
      // // If we're updating a question and the next question
      // // has already been changed this session then we don't want to scroll...
      // if (this.sortedItems[index]._changed) {
      //   return;
      // }
      // setTimeout(() => {
      //   document.querySelector('#scale-' + index).scrollIntoView({
      //     behavior: 'smooth'
      //   });
      // }, 300);
    },
    nextStep() {
      let action;
      this.saveLoading = true;

      if (!this.statusreportid) {
        action = this.saveStatusReport;
      } else {
        action = this.updateStatusReport;
      }
      action()
        .catch(() => {
          this.$bus.$emit('toast.display', {
            message: this.$root.$t('generic.saveFailure'),
            status: 'failure'
          });
        })
        .finally(() => (this.saveLoading = false));
    },
    saveStatusReport() {
      const items = this.useOldValues ? this.report.Items : this.changedItems;
      return this.$saveReport(items)
        .then(() => {
          this.$router.replace({ name: 'statusReportFollowup' });
        })
        .catch(e => console.log(e));
    },
    updateStatusReport() {
      return this.$updateReport(this.report).then(() => {
        this.$router.replace({
          name: 'statusReportReportGraph',
          params: { statusreportid: this.report.Id }
        });
      });
    },
    cancel() {
      if (this.redirectOnCancel) {
        this.$router.push(this.redirectOnCancel.path);
      } else {
        this.$router.back();
      }
    },
    scaleColor(item) {
      return StatusReportAreaColors[item.Type];
    },
    scaleTitle(item) {
      const itemstr = `constants.StatusReportTypes.${item.Type}`;
      return this.$t(itemstr);
    }
  },
  computed: {
    ...mapGetters('currentuser', {
      $appVersion: CURRENT_USER_GETTERS.APP_VERSION,
      $profile: CURRENT_USER_GETTERS.PROFILE,
      $hasSeenTutorial: CURRENT_USER_GETTERS.HAS_SEEN_TUTORIAL,
      $autoScroll: CURRENT_USER_GETTERS.AUTO_SCROLL
    }),
    ...mapGetters('app', {
      $statusTypes: APP_GETTERS.STATUS_REPORT_TYPES_SORTED_ARRAY
    }),
    ...mapGetters('statusreports', {
      $invertedTypes: STATUSREPORT_GETTERS.INVERTED_TYPES,
      $findReport: STATUSREPORT_GETTERS.FIND_REPORT,
      $unsavedReport: STATUSREPORT_GETTERS.UNSAVED_REPORT
    }),
    changedItems() {
      if (!this.report) {
        return;
      }
      const changedItems = this.report.Items.filter(i => i._changed === true);
      if (this.statusreportid) {
        return changedItems;
      }
      return changedItems;
    },
    nextInactive() {
      if (!this.report) {
        return true;
      }
      if (this.useOldValues === false && this.changedItems.length === 0) {
        return true;
      }
      return false;
    },
    sortedItems() {
      if (!this.report) {
        return [];
      }
      return this.$statusTypes.map(type =>
        this.report.Items.find(i => i.Type === type)
      );
    },
    continuationQuestionList() {
      return this.sortedItems.filter(i => i._lastValue > 0 && !i._removed);
    },
    newQuestionList() {
      return this.sortedItems.filter(i => !i._lastValue || i._removed);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';

.status-report {
  margin-bottom: -50px;
}
h4 {
  margin-bottom: $padding;
}
.box {
  padding: 0;
  padding-top: 0.7em;
  border-radius: 0;

  &:not(:first-of-type) {
    border-top: 1px solid $symptoms-black;
  }

  &:first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-of-type {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}
</style>
<style lang="scss">
.status-report {
  .card__content {
    width: 100% !important;
  }
  .scale-container {
    margin-bottom: 0 !important;
  }
}
</style>
