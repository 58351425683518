<template>
  <card-container-vue
    v-if="item"
    :full-width="true"
    :data-num="itemNum"
    v-scroll-to-fixed="`${affixed}`"
    data-container=".content"
    :data-scroll-offset="isMobile() ? 49 : 68"
    :data-scroll-past="true"
    :data-full-width="true"
  >
    <card-vue :full-width="true">
      <template v-slot:content>
        <div class="info">
          <span v-html="$filters.NL2BR(toHtml(item.Text))"></span>
          <slot></slot>
          <slot name="cancel"></slot>
        </div>
      </template>
    </card-vue>
  </card-container-vue>
</template>

<script>
import CardContainerVue from '../Cards/CardContainer.vue';
import CardVue from '../Cards/Card.vue';
import { toHtml } from '@common/Helpers/markdown';
import { isMobile } from '../../Helpers/mobile';

export default {
  components: { CardContainerVue, CardVue },
  props: {
    item: Object,
    itemNum: Number,
    affixed: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    toHtml,
    isMobile
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';
$padding: 20px;
.info {
  // margin: 0.82rem 0 0.656rem;
  position: relative;
  color: #000;
  padding: $padding;
  border-radius: 6px;
  display: block;
  z-index: 10;

  span {
    font-size: 1.2rem;
    width: 100%;
    margin-bottom: 0;
    @media only screen and (min-width: $breakpoint-desktop) {
      font-size: 1.64rem;
      width: 70%;
    }
  }
}
</style>
