import { ACTIONS, MUTATIONS } from './definitions';
import { State } from './State'; //eslint-disable-line

export default {
  /**
   * @param {Object} store
   * @param {State} store.state
   * @param {State} store.state
   * @param {*} { interactable }
   */
  [ACTIONS.INIT]({ state, commit }, { interactable, rotate }) {
    commit(MUTATIONS.SET_INTERACTABLE, interactable);

    if (interactable === false && rotate === false) {
      commit(MUTATIONS.TOGGLE_CONTROLS, { camera: false });
    } else {
      commit(MUTATIONS.TOGGLE_CONTROLS, { camera: true });
    }
  }
};
