<template>
  <div>
    <div class="container" v-if="symptomreport">
      <status-message
        icon="mdi-check-circle"
        :message="$t('views.symptomReport.savedSuccessfully')"
        data-cy="symptomreport-followup-message-saved"
      ></status-message>
      <followup-actions
        :show-followups="showFollowups"
        :show-skip="false"
        @followups-available="val => (followupsAvailable = val)"
      ></followup-actions>
    </div>
    <float-content>
      <button-home
        :text="
          followupsAvailable
            ? $t('views.statusReport.noFollowup')
            : $t('components.menuOptions.home')
        "
        data-cy="symptomreport-followup-homebutton"
      ></button-home>
    </float-content>
  </div>
</template>

<script>
import StatusMessage from '@/views/components/StatusMessage';
import FollowupActions from '@/views/StatusReport/components/FollowupActions';
import {
  SYMPTOMREPORT_GETTERS,
  SYMPTOMREPORT_ACTIONS
} from '@/store/modules/symptomreports';
import { SYMPTOM_GETTERS } from '@/store/modules/symptoms';
import completedFollowupMixin from '@/views/StatusReport/mixins/completedFollowupMixin';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { sortByKey } from '@common/Helpers/sorters';
export default {
  components: { StatusMessage, FollowupActions },
  mixins: [completedFollowupMixin],
  props: {
    symptomreportid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      followupsAvailable: true
    };
  },
  mounted() {
    this.$getReport(this.symptomreportid);
    this.$_completedFollowupMixin_completedFollowup('pain');
  },
  methods: {
    ...mapActions('symptomreports', {
      $getReport: SYMPTOMREPORT_ACTIONS.GET_MY_SYMPTOM_REPORT
    })
  },
  computed: {
    ...mapGetters('symptomreports', {
      $findReport: SYMPTOMREPORT_GETTERS.FIND_SYMPTOM_REPORT
    }),
    ...mapGetters('symptoms', {
      $findSymptom: SYMPTOM_GETTERS.FIND_SYMPTOM,
      $graphData: SYMPTOM_GETTERS.SYMPTOM_GRAPH_DATA
    }),
    symptomreport() {
      return this.$findReport(this.symptomreportid);
    },
    graphData() {
      if (!this.symptomreport || this.symptomreport.Symptoms.length === 0) {
        return;
      }

      let data = this.symptomreport.Symptoms.map(s => this.$findSymptom(s.Id));
      data = data.map(symptom => ({
        data: sortByKey(this.$graphData(symptom), 'date')
      }));

      return data;
    },
    showFollowups() {
      return this.$route.query.followups ? true : false;
    }
  }
};
</script>

<style lang="scss" scoped>
.message {
  text-align: center;
  font-size: 1.4em;
  margin: 50px 0;
}
.container {
  height: 100%;
}
</style>
