<template>
  <div v-if="symptom" class="pull-to-top">
    <history-graph
      :graph-data="[{ data: graphData }]"
      :height="200"
    ></history-graph>

    <div class="container">
      <info-row-symptom
        :symptom="symptom"
        :hide-remove="true"
        :hide-eye="true"
      ></info-row-symptom>
    </div>

    <div class="container">
      <info-row-details-symptom-report
        v-for="report in symptom.Reports"
        :key="report.SymptomReportId"
        :report="report"
      ></info-row-details-symptom-report>
    </div>

    <div class="container" v-if="!symptom.isClosed">
      <s-button @click="confirmClose = true" :danger="true">{{
        $t('views.symptomReport.symptomIsOver')
      }}</s-button>
    </div>

    <float-content>
      <button-back></button-back>
      <s-button @click="updateReport" :primary="true">
        {{ $t('views.start.quickactions.reportSymptoms') }}</s-button
      >
    </float-content>

    <modal-confirm
      :show="confirmClose"
      :accept-callback="
        () => {
          this.closeSymptom();
        }
      "
      :accept-text="$t('generic.yes')"
      :show-extras="false"
      :decline-text="$t('generic.no')"
      @close="confirmClose = false"
      >{{ $t('modals.confirmCloseSymptom') }}</modal-confirm
    >
  </div>
</template>

<script>
import { SYMPTOM_GETTERS, SYMPTOM_ACTIONS } from '@/store/modules/symptoms';
import { sortByKey } from '@common/Helpers/sorters';
import { mapGetters, mapActions } from 'vuex';
import HistoryGraph from '@common/Components/Graphs/HistoryGraph';
import ModalConfirm from '@common/Components/Modals/ModalConfirm';
import InfoRowSymptom from '@/views/components/InfoRow/InfoRowSymptom.vue';
import InfoRowDetailsSymptomReport from '@/views/components/InfoRow/InfoRowDetailsSymptomReport.vue';

export default {
  components: {
    HistoryGraph,
    ModalConfirm,
    InfoRowDetailsSymptomReport,
    InfoRowSymptom
  },
  props: { symptomid: String },
  data() {
    return {
      confirmClose: false
    };
  },
  mounted() {
    this.$getMySymptom(this.symptomid);
  },
  methods: {
    ...mapActions('symptoms', {
      $closeSymptom: SYMPTOM_ACTIONS.CLOSE_SYMPTOM,
      $getMySymptom: SYMPTOM_ACTIONS.GET_MY_SYMPTOM
    }),
    closeSymptom() {
      this.$closeSymptom(this.symptom).then(() => {
        this.$bus.$emit('toast.display', {
          message: this.$root.$t('generic.symptomClosed'),
          status: 'success'
        });
        this.$router.replace({ name: 'start' });
      });
    },
    updateReport() {
      this.$router.push({
        name: 'symptomReport'
      });
    }
  },
  computed: {
    ...mapGetters('symptoms', {
      $findSymptom: SYMPTOM_GETTERS.FIND_SYMPTOM,
      $graphData: SYMPTOM_GETTERS.SYMPTOM_GRAPH_DATA
    }),
    symptom() {
      return this.$findSymptom(this.symptomid);
    },
    graphData() {
      if (!this.$findSymptom(this.symptomid)) {
        return [];
      }
      return sortByKey(this.$graphData(this.symptom), 'date');
    }
  }
};
</script>
