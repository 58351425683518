// @ts-check
export class BankIdAuthResponseModel {
  constructor({ OrderRef, AutoStartToken, QrImage }) {
    this._modelName = 'BankIdAuthResponse';
    this.OrderRef = OrderRef;
    this.AutoStartToken = AutoStartToken;
    this.QrImage = QrImage;
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }
}

export default BankIdAuthResponseModel;
