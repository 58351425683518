/** @type {import('vue/types/umd').DirectiveOptions} */
export const ScrollToFixedSimpleDirective = {
  /**
   * @param {HTMLElement} el
   * @param {*} binding
   */
  beforeMount: function (el, binding) {
    const FILLER = 'scroll-to-fixed-filler';
    const FIXED = 'affixed';
    const CONTAINER = el.dataset.container
      ? document.querySelector(el.dataset.container)
      : window;
    let SCROLLED;

    const scrollOffset = el.dataset.scrollOffset || 0;
    const margins = {
      left: parseFloat(window.getComputedStyle(el).marginLeft),
      right: parseFloat(window.getComputedStyle(el).marginRight)
    };
    const marginWidth = margins.left + margins.right;

    const scroll = function (event) {
      SCROLLED = el.dataset.container ? CONTAINER.scrollTop : window.scrollY;
      if (SCROLLED > scrollOffset) {
        const elBbox = el.getBoundingClientRect();
        el.style.position = 'fixed';
        el.style.left = `${elBbox.x}px`;
        el.style.top = `${scrollOffset}px`;
        el.classList.add(FIXED);

        if (!document.querySelector(`.${FILLER}`)) {
          const filler = document.createElement('div');
          filler.style.width = `${elBbox.width + marginWidth + 2}px`;
          filler.classList.add(FILLER);
          el.parentElement.appendChild(filler);
        }
      } else {
        el.style = {};
        el.classList.remove(FIXED);
        if (document.querySelector(`.${FILLER}`)) {
          document.querySelector(`.${FILLER}`).remove();
        }
      }
    };

    const resize = function (event) {
      if (document.querySelector(`.${FILLER}`)) {
        const filler = document.querySelector(`.${FILLER}`);
        const bbox = filler.getBoundingClientRect();
        el.style.left = `${bbox.left + margins.left}px`;
      }
    };

    CONTAINER.addEventListener('scroll', scroll);
    CONTAINER.addEventListener('resize', resize);
  }
};
