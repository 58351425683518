import { Bus } from '@common';
import Loader from '@common/Helpers/Loader';
import { TFARequired } from '@common/Interceptors/errors/tfa-required';
import axios from 'axios';
import { ResponseInterceptor } from '../response';
const errorQueue = [];
const RETRY_DELAY = 3000;

const service = axios.create();
service.interceptors.response.use(
  ResponseInterceptor,
  ErrorResponseInterceptor
);

export function ErrorResponseInterceptor(error) {
  Loader.done();

  if (error.code === 'ECONNABORTED' || !error.response) {
    const queueObjectIndex = errorQueue.findIndex(
      obj => obj.request.url === error.config.url
    );

    const queueObject =
      queueObjectIndex === -1
        ? {
            request: error.config,
            tries: 1
          }
        : errorQueue[queueObjectIndex];

    if (queueObject.tries === 1) {
      errorQueue.push(queueObject);
    }

    queueObject.tries++;

    if (queueObject.tries > 3) {
      window.offline = true;
      Bus.$emit('connection', false);
    }

    const retry = new Promise(resolve =>
      setTimeout(() => resolve(), RETRY_DELAY)
    );

    return Promise.resolve()
      .then(() => retry)
      .then(() => service(queueObject.request));
  }

  if (
    error &&
    error.hasOwnProperty('response') &&
    error.response.status === 400 &&
    error.response.data.hasOwnProperty('ErrorCode')
  ) {
    const code = error.response.data.ErrorCode;
    switch (code) {
      case 4006: {
        TFARequired(2);
        break;
      }
      case 504: {
        console.error('MISSING PATIENT');
      }
    }
  }

  console.log('~~~~~~~~~~~~~~~~~~ <Axios error handler> ~~~~~~~~~~~~~~~~~~~');
  console.error(error);
  console.log('~~~~~~~~~~~~~~~~~~ </Axios error handler> ~~~~~~~~~~~~~~~~~~~');

  return Promise.reject(error);
}
