// @ts-check
import { utcToLocal } from '@common/Helpers/time';
import moment from 'moment';
import SymptomReportModel from '@common/Models/SymptomReport/SymptomReportModel';
import PromReportModel from '@common/Models/PromReportModel';
import NoteModel from '@common/Models/NoteModel';

export class HistoryItemModel {
  constructor({
    ResourceId = null,
    ResourceType,
    CreatedDate = null,
    ModifiedDate = null,
    SymptomReport,
    PromReport,
    Note
  }) {
    this._modelName = ResourceType;
    this.ResourceId = ResourceId;
    this.ResourceType = ResourceType;
    this.CreatedDate = CreatedDate ? utcToLocal(CreatedDate) : moment();
    this.ModifiedDate = ModifiedDate ? utcToLocal(ModifiedDate) : moment();

    this.SymptomReport = SymptomReport
      ? new SymptomReportModel(SymptomReport)
      : null;
    this.PromReport = PromReport ? new PromReportModel(PromReport) : null;
    this.Note = Note ? new NoteModel(Note) : null;
  }

  get ItemObject() {
    var obj = null;

    switch (this.ResourceType) {
      case 'SymptomReport':
        obj = this.SymptomReport;
        break;
      case 'PromReport':
        obj = this.PromReport;
        break;
      case 'Note':
        obj = this.Note;
        break;
    }

    return obj;
  }

  static get validations() {
    return {};
  }

  __copy() {
    return new HistoryItemModel(JSON.parse(JSON.stringify(this)));
  }
}

export default HistoryItemModel;
