// @ts-check
import { utcToLocal } from '@common/Helpers/time';

export class StatusReportItemModel {
  constructor({
    Intensity,
    Type,
    CreatedDate,
    Trend,
    LanguageId,
    _changed,
    _removed
  }) {
    if (!isNaN(Intensity) && Intensity !== undefined) {
      this.Intensity = +Intensity;
    }

    this.Type = Type;
    this.Trend = Trend;

    this.CreatedDate = CreatedDate ? utcToLocal(CreatedDate) : null;
    this.LanguageId = LanguageId;
    this._changed = _changed === undefined ? false : _changed;
    this._lastValue = this.Intensity;
    this._removed = _removed === undefined ? false : _removed;
  }
}

export default StatusReportItemModel;
