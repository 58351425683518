<template>
  <modal
    :show="lostConnection"
    :sticky="true"
    @close="shouldShow = false"
    :buttons="false"
    height="auto"
  >
    <h3 class="center-text sub-header mb0">
      <i class="mdi mdi-lan-disconnect"></i>
      {{ $t('modals.connectionLost.information') }}
    </h3>
  </modal>
</template>

<script>
export default {
  props: {
    lostConnection: Boolean
  },
  data() {
    return {
      shouldShow: true
    };
  },
  computed: {
    show() {
      return this.lostConnection && this.shouldShow;
    }
  }
};
</script>

<style></style>
