<template>
  <div>
    <h4 class="container header ml20 hidden-mobile">
      {{ $t('generic.reminder') }}
    </h4>
    <div class="container">
      <new-reminder></new-reminder>
    </div>
  </div>
</template>

<script>
import NewReminder from './components/NewReminder.vue';

export default {
  components: {
    NewReminder
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {}
};
</script>
<style lang="scss" scoped></style>
