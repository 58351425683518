import SymptomReportModel from '@common/Models/SymptomReport/SymptomReportModel';

/**
 * Map remote symptom report to local
 * @param {object} dtoSymptomReport report from backend
 * @returns {SymptomReportModel}
 */
export const SymptomReportResponse = dtoSymptomReport => {
  return new SymptomReportModel(dtoSymptomReport);
};

/**
 * Map remote symptom reports to local
 * @param {object} dtoSymptomReports reports from backend
 * @returns {SymptomReportModel[]}
 */
export const SymptomReportsResponse = dtoSymptomReports => {
  return dtoSymptomReports.map(dto => SymptomReportResponse(dto));
};
