export const ANDROID = 'Android';
export const WINDOWS = 'Windows Phone';
export const IOS = 'iOS';
export const UNKNOWN = 'Unknown';

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return WINDOWS;
  }

  if (/android/i.test(userAgent)) {
    return ANDROID;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return IOS;
  }

  return UNKNOWN;
};

/**
 * Check if user is on mobile
 * @returns {boolean}
 */
export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

/**
 * Detect if device has touch capability
 * https://stackoverflow.com/a/4819886
 * @returns {Boolean}
 */
export const isTouchDevice = () => {
  var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  var mq = function (query) {
    return window.matchMedia(query).matches;
  };

  if (
    'ontouchstart' in window ||
    (window.DocumentTouch && document instanceof DocumentTouch)
  ) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
};

export const hasNotch = function () {
  let proceed = false;
  const div = document.createElement('div');
  if (CSS.supports('padding-bottom: env(safe-area-inset-bottom)')) {
    div.style.paddingBottom = 'env(safe-area-inset-bottom)';
    proceed = true;
  } else if (CSS.supports('padding-bottom: constant(safe-area-inset-bottom)')) {
    div.style.paddingBottom = 'constant(safe-area-inset-bottom)';
    proceed = true;
  }
  if (proceed) {
    document.body.appendChild(div);
    const calculatedPadding = parseInt(
      window.getComputedStyle(div).paddingBottom
    );
    document.body.removeChild(div);
    if (calculatedPadding > 0) {
      return true;
    }
  }
  return false;
};

export const isChromeOnAppleDevice = Boolean(
  navigator.userAgent.match(/CriOS/)
);
export const isFirefoxOnAppleDevice = Boolean(
  navigator.userAgent.match(/FxiOS/)
);
export const isEdgeOnAppleDevice = Boolean(navigator.userAgent.match(/EdgiOS/));
export const isOperaTouchOnAppleDevice = Boolean(
  navigator.userAgent.match(/OPT/)
);

export const isAndroid = Boolean(getMobileOperatingSystem() === ANDROID);
