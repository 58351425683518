<template>
  <div id="app" :class="[isLoggedIn ? 'logged-in' : 'not-logged-in']">
    <div
      id="reload-app"
      :class="{ 'header-toast--visible': newVersion }"
      class="header-toast header-toast--warning pointer"
    >
      {{ $t('information.updateAvailable') }}
    </div>
    <router-view />
    <div id="progress-bar" />
    <div id="modal" />
    <div id="menu" />
  </div>
</template>

<script>
import { AUTH_GETTERS, AUTH_MUTATIONS } from '@/store/modules/auth';
import { APP_GETTERS, APP_MUTATIONS } from '@/store/modules/app';
import { CURRENT_USER_ACTIONS } from '@/store/modules/currentuser';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import settings from '@/../settings.json';

export default {
  components: {},
  data() {
    return {
      newVersion: false,
      appSubscriptions: null,
      moduleSettings: settings.modules
    };
  },
  watch: {
    isLoggedIn(value) {
      this.setBodyClasses(value);
    },
    $showFloat(value) {
      this.toggleFloat();
    },
    $showModal(value) {
      this.toggleScroll();
    },
    $showLargeMenu(value) {
      this.toggleScroll();
    }
  },
  created() {},
  mounted() {
    this.setBodyClasses(this.$LOGGED_IN);
    this.$setLanguage();
    window.addEventListener('new-version', () => {
      this.newVersion = true;
    });
    const event = new Event('symptoms-loaded');
    window.dispatchEvent(event);

    window.$bus.$on('setClasses', this.setBodyClasses);
    window.$bus.$on('toggleHeader', this.toggleHeaderClass);

    this.appSubscriptions = this.$store.subscribe(mutation => {
      if (mutation.type === `auth/${AUTH_MUTATIONS.LOGIN_SUCCESS}`) {
        this.setBodyClasses(true);
      }
    });
  },

  methods: {
    ...mapActions('currentuser', {
      $setLanguage: CURRENT_USER_ACTIONS.SET_LANGUAGE
    }),
    ...mapMutations('app', {
      $setPageSettings: APP_MUTATIONS.SET_PAGE_SETTING
    }),
    setBodyClasses(loggedIn) {
      const body = document.querySelector('body');
      if (loggedIn === true || this.$route.meta.noAuthRequired) {
        body.classList.remove('not-logged-in');
        body.classList.add('logged-in');
      } else {
        body.classList.remove('logged-in');
        body.classList.add('not-logged-in');
      }

      if (window.navigator.standalone) {
        body.classList.add('standalone');
      }

      if (window.innerHeight < 500) {
        body.classList.add('small-viewport');
      }
    },
    toggleHeaderClass(state) {
      const body = document.querySelector('body');
      if (state === false) {
        body.classList.add('header-hidden');
      } else {
        body.classList.remove('header-hidden');
      }
    },
    toggleFloat() {
      const body = document.querySelector('body');
      if (this.$showFloat === true) {
        body.classList.add('float-visible');
      } else {
        body.classList.remove('float-visible');
      }
    },
    toggleScroll() {
      const body = document.querySelector('body');
      const container = document.querySelector('.modal-container');
      setTimeout(() => {
        if (
          (this.$showModal === true && container !== null) ||
          this.$showLargeMenu
        ) {
          body.style.overflow = 'hidden';
        } else {
          body.style.overflow = 'auto';
        }
      }, 0);
    }
  },

  computed: {
    ...mapGetters('auth', {
      $LOGGED_IN: AUTH_GETTERS.LOGGED_IN
    }),
    ...mapGetters('app', {
      $showFloat: APP_GETTERS.SHOW_FLOAT_CONTENT,
      $showModal: APP_GETTERS.SHOW_MODAL,
      $showLargeMenu: APP_GETTERS.SHOW_LARGE_MENU,
      $showNewVersion: APP_GETTERS.SHOW_NEW_VERSION
    }),
    notesEnabled() {
      return this.$root.moduleSettings.notes.enabled;
    },
    promsEnabled() {
      return this.$root.moduleSettings.proms.enabled;
    },
    promReportsEnabled() {
      return this.$root.moduleSettings.promReports.enabled;
    },
    symptomreportsEnabled() {
      return this.$root.moduleSettings.symptoms.enabled;
    },
    auditLogsEnabled() {
      return this.$root.moduleSettings.auditlogs.enabled;
    },
    historyEnabled() {
      return this.$root.moduleSettings.history.enabled;
    },
    trashEnabled() {
      return this.$root.moduleSettings.trash.enabled;
    },
    invitesEnabled() {
      return this.$root.moduleSettings.invites.enabled;
    },
    sharesEnabled() {
      return this.$root.moduleSettings.shares.enabled;
    },
    isLoggedIn() {
      return (
        Object.keys(this.$store.state.auth.tokenDetails).length > 0 &&
        this.$store.state.app.encryption_key !== null
      );
    }
  }
};
</script>

<style lang="scss">
@import '@sass/_variables.scss';
body {
  overflow: auto;
  background-color: $background-color;

  &.float-visible {
    .content {
      padding-bottom: 180px !important;
    }
  }

  &.header-hidden {
    background-color: #ffffff !important;
    .content {
      margin-top: 0;
      overflow-y: hidden !important;
      background-color: #ffffff !important;
    }
  }

  &.not-logged-in {
    .full-height {
      display: none;
    }
    #reload-app {
      left: 0;
      text-align: center;
      width: 100%;
      &.header-toast {
        &--visible {
          top: 0;
        }
      }
    }
    a:not(.btn) {
      text-align: left;
      margin-bottom: 10px;
      color: $text-color;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &.standalone {
    html,
    body {
      overflow: hidden;
    }
    #app {
      overflow: hidden;
    }
    .app-header {
      height: 60px !important;
      padding: 15px 20px 10px 20px !important;
    }
    .main-menu {
      height: 50px !important;
    }
    .content {
      max-height: calc(100vh - #{$header-height}) !important;
      min-height: calc(100vh - #{$header-height}) !important;
      padding-bottom: 50px !important;
    }
    &.standalone.float-visible {
      .content {
        padding-bottom: 170px !important;
      }
    }
  }
}
#app {
  min-height: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
}
</style>
