<template>
  <div>
    <prom-report
      v-if="report"
      :prom="prom"
      :prom-report="report"
      :previous-prom-report="previousReport"
      @set-unsaved="report => setUnsavedReport(report)"
      @save="report => postSave(report)"
      ref="promreport"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PROM_ACTIONS, PROM_GETTERS } from '@/store/modules/proms';
import {
  PROMREPORT_ACTIONS,
  PROMREPORT_GETTERS
} from '@/store/modules/promreports';
import { STATUSREPORT_ACTIONS } from '@/store/modules/statusreports';
import PromReport from '@views/PromReport/components/PromReport.vue';
import PromReportModel from '@common/Models/PromReportModel';
import PromModel from '@common/Models/PromModel';
import { randomString } from '@common/Helpers/strings';
import store from '@/store';

export default {
  name: 'promreporteditview',
  props: ['promid', 'promreportid'],
  components: {
    PromReport
  },
  data() {
    return {
      prom: null,
      report: null,
      previousReport: null
    };
  },
  beforeRouteLeave(to, from, next) {
    // Refs hack to trigger modal in promreport component...
    if (
      this.$refs.promreport.savable === true &&
      this.$refs.promreport.confirmCancel === false &&
      this.$refs.promreport.confirmedCancel === false
    ) {
      this.$refs.promreport.confirmCancel = true;
      this.$refs.promreport.redirectOnCancel = to;
      next(false);
    } else {
      this.$removeUnsavedReport();
      next();
    }
  },
  watch: {
    async promid(promid) {
      if (promid) {
        await this.loadProm();
        await this.loadPromReport();
      }
    }
  },
  async mounted() {
    await this.loadProm();
    await this.loadPromReport();
  },
  beforeRouteEnter(to, from, next) {
    /*
    Remove unsaved report unless we are coming from login-loading
    which should mean user has refreshed or is coming straight from login.

    Direct store call done instead of using mapActions since we don't have access
    to this.* in befoureRouteEnter method.
    */
    if (from.name != 'login-loading') {
      store.dispatch(`promreports/${PROMREPORT_ACTIONS.REMOVE_UNSAVED_REPORT}`);
    }

    next();
  },
  methods: {
    ...mapActions('promreports', {
      $getUnsavedReport: PROMREPORT_ACTIONS.GET_UNSAVED_REPORT,
      $setUnsavedReport: PROMREPORT_ACTIONS.SET_UNSAVED_REPORT,
      $removeUnsavedReport: PROMREPORT_ACTIONS.REMOVE_UNSAVED_REPORT,
      $getMyPromReport: PROMREPORT_ACTIONS.GET_MY_PROMREPORT,
      $getMyLatestPromReportByProm:
        PROMREPORT_ACTIONS.GET_MY_LATEST_PROMREPORT_BY_PROM
    }),
    ...mapActions('proms', {
      $getProm: PROM_ACTIONS.GET_PROM
    }),
    ...mapActions('statusreports', {
      $getPromSuggestions: STATUSREPORT_ACTIONS.GET_PROM_SUGGESTIONS
    }),
    async loadProm() {
      if (this.promid !== null) {
        this.prom = this.$findProm(this.promid);

        if (!this.prom || this.prom.shouldGetFullProm) {
          await this.$getProm(this.promid).then(prom => {
            this.prom = prom;
          });
        }
      }
    },
    async loadPromReport() {
      if (!this.promreportid) {
        this.$getUnsavedReport();

        if (this.prom.PrefillPreviousAnswers) {
          await this.getPreviousReport();
        }

        if (this.$unsavedReport) {
          this.report = this.$unsavedReport;
        } else {
          this.report = new PromReportModel({
            Id: randomString(),
            PromId: this.promid,
            LanguageId: this.prom.Language,
            QuestionCount: this.prom.QuestionCount
          });
        }
      } else {
        await this.$getMyPromReport(this.promreportid).then(report => {
          this.report = report;
        });
      }
    },
    async getPreviousReport() {
      await this.$getMyLatestPromReportByProm(this.promid).then(
        previousReport => {
          this.previousReport = previousReport;
        }
      );
    },
    setUnsavedReport(report) {
      this.$setUnsavedReport(report.__copy());
    },
    postSave(report) {
      this.$removeUnsavedReport(); // Needed in case we are redirected to same view but different PROM.

      if (!this.prom.RedirectUri) {
        this.$router.replace({
          name: 'promReportFollowupView',
          params: { promreportid: report.Id }
        });

        this.$getPromSuggestions();
      } else if (
        this.prom.RedirectUri &&
        !this.previousReport &&
        !this.promreportid
      ) {
        this.$router.replace({
          path: this.prom.RedirectUri
        });
      } else {
        this.$bus.$emit('toast.display', {
          message: this.$t('generic.saveSuccess'),
          status: 'success'
        });
        this.$router.replace({
          name: 'start'
        });
      }
    }
  },
  computed: {
    ...mapGetters('proms', {
      $findProm: PROM_GETTERS.FIND_PROM
    }),
    ...mapGetters('promreports', {
      $findPromReport: PROMREPORT_GETTERS.FIND_PROMREPORT,
      $unsavedReport: PROMREPORT_GETTERS.UNSAVED_REPORT
    })
  }
};
</script>

<style lang="scss" scoped></style>
