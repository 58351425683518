<template>
  <card-vue
    :active="active"
    @click="gotoSymptom()"
    color="white"
    :height="'fit-content'"
  >
    <template v-slot:content>
      <div class="top">
        <span
          :title="
            symptom.Name ||
            $t(`constants.SymptomTypes['${symptom.lastReport.Type}']`)
          "
          class="title"
          >{{
            symptom.Name ||
            $t(`constants.SymptomTypes['${symptom.lastReport.Type}']`)
          }}</span
        >
        <template v-if="symptom.lastReport.hasDrawing">
          <symptom-canvas
            :symptoms="[symptom.lastReport]"
            angle="front"
            :height="160"
            :width="140"
            :force-model="symptom.Model"
          ></symptom-canvas>
          <symptom-canvas
            :symptoms="[symptom.lastReport]"
            angle="back"
            :height="160"
            :width="140"
            :force-model="symptom.Model"
          ></symptom-canvas>
        </template>
      </div>
      <div class="bottom">
        <history-graph
          :graph-data="[{ data: graphData }]"
          :height="120"
          :buttons="false"
        ></history-graph>

        <div class="date">
          {{ $t('generic.latestReportMade') }}
          {{ $filters.SimpleDateFormat(symptom.lastReport.CreatedDate) }}
        </div>
      </div>
    </template>
  </card-vue>
</template>

<script>
import CardVue from '@common/Components/Cards/Card.vue';
import SymptomCanvas from '@common/Modules/quincy/src/Quincy/components/SymptomCanvas';
import HistoryGraph from '@common/Components/Graphs/HistoryGraph';
import SymptomMixin from './mixins/SymptomMixin';
import SymptomModel from '@common/Models/Symptom/SymptomModel';

export default {
  components: { CardVue, SymptomCanvas, HistoryGraph },
  mixins: [SymptomMixin],
  props: {
    symptom: {
      type: SymptomModel,
      required: true
    }
  },
  data() {
    return {
      active: true
    };
  },
  mounted() {},
  methods: {
    gotoSymptom() {
      if (this.active) {
        return this.$router.push({
          name: 'symptomReport',
          params: { symptomid: this.symptom.Id }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.top {
  text-align: center;
  margin-top: 10px;
  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 335px;
    color: black;
    font-weight: 700;
    display: block;
    font-size: 15px;
    padding: 0 10px;
  }
}
.bottom {
  padding-right: 10px;
}
.date {
  margin: 13px 0;
  color: rgba(18, 32, 51, 1);
  font-size: small;
  text-align: center;
}
.bottom {
  padding-right: 0 !important;
}
</style>
