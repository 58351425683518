// @ts-check
import { utcToLocal } from '@common/Helpers/time';
import { maxLength, required, url } from '@vuelidate/validators';

export class PromRequestModel {
  constructor({
    PromRequestId,
    PatientId,
    PromId,
    LanguageId,
    Url,
    Purpose,
    CreatedDate,
    ReportDate,
    OrganizationId,
    GroupId,
    ContextText = '',
    CallbackUrl,
    Version = 0
  }) {
    this._modelName = 'PromRequest';
    this.PromRequestId = PromRequestId;
    this.PatientId = PatientId;
    this.PromId = PromId;
    this.LanguageId = LanguageId;
    this.Url = Url;
    this.Purpose = Purpose;
    this.CreatedDate = CreatedDate ? utcToLocal(CreatedDate) : null;
    this.ReportDate = ReportDate ? utcToLocal(ReportDate) : null;
    this.OrganizationId = OrganizationId;
    this.GroupId = GroupId;
    this.ContextText = ContextText;
    this.CallbackUrl = CallbackUrl;
    this.Version = Version || 0;
  }

  static get validations() {
    return {
      Personnummer: {
        required
      },
      LanguageId: {
        required
      },
      PromId: {
        required
      },
      Purpose: {
        maxLength: maxLength(256),
        required
      },
      ContextText: {
        maxLength: maxLength(256)
      },
      CallbackUrl: {
        maxLength: maxLength(1024),
        url
      }
    };
  }
}

export default PromRequestModel;
