// @ts-check
import { utcToLocal } from '@common/Helpers/time';
import moment from 'moment';
import { required } from '@vuelidate/validators';

export class OAuthTokenModel {
  constructor({
    Id,
    ApplicationId,
    ApplicationDescription,
    ApplicationRole,
    GroupId,
    OrganizationId,
    UserId,
    Secret,
    CreatedDate,
    ModifiedDate
  }) {
    this._modelName = 'OAuthToken';
    this.Id = Id || null;
    this.ApplicationId = ApplicationId;
    this.ApplicationDescription = ApplicationDescription || null;
    this.ApplicationRole = ApplicationRole || null;
    this.GroupId = GroupId;
    this.OrganizationId = OrganizationId;
    this.UserId = UserId;
    this.Secret = Secret;
    this.CreatedDate = CreatedDate ? utcToLocal(CreatedDate) : moment();
    this.ModifiedDate = ModifiedDate
      ? utcToLocal(ModifiedDate)
      : this.CreatedDate;
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }

  static get validations() {
    return {
      ApplicationId: {
        required
      }
    };
  }
}

export default OAuthTokenModel;
