export const LANGUAGESTRING_MUTATIONS = {
  ADD_STRING: 'ADD_STRING',
  SET_STRINGS: 'SET_STRINGS',
  REMOVE_STRING: 'REMOVE_STRING'
};

export const LANGUAGESTRING_ACTIONS = {
  ADD_STRING: 'ADD_STRING',
  ADD_STRINGS: 'ADD_STRINGS',
  GET_STRINGS: 'GET_STRINGS',
  UPDATE_STRING: 'UPDATE_STRING',
  DELETE_STRING: 'DELETE_STRING',
  DELETE_STRING_VERSION: 'DELETE_STRING_VERSION'
};

export const LANGUAGESTRING_GETTERS = {
  STRINGS: 'STRINGS',
  FIND_STRING: 'FIND_STRING'
};
