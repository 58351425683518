import BankIdCertModel from './BankIdCertModel';
import BankIdDeviceModel from './BankIdDeviceModel';
import BankIdUserModel from './BankIdUserModel';

// @ts-check
export class BankIdCompletionDataModel {
  constructor({ signature, ocspResponse, user, device, cert }) {
    this._modelName = 'BankIdCompletionData';
    this.signature = signature;
    this.ocspResponse = ocspResponse;
    this.user = user ? new BankIdUserModel(user) : null;
    this.device = device ? new BankIdDeviceModel(device) : null;
    this.cert = cert ? new BankIdCertModel(cert) : null;
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }
}

export default BankIdCompletionDataModel;
