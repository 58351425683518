<template>
  <div v-if="newNote" class="new-note">
    <div
      v-if="displayText"
      class="info mb20"
      v-text="$t('views.createNote.description')"
    ></div>
    <div class="textarea">
      <textarea
        id="InputNote"
        data-cy="notes-form-text"
        v-model="newNote.Content"
        ref="inputnote"
        :data-length="v$.newNote.Content.maxLength.$params.max"
        :maxlength="v$.newNote.Content.maxLength.$params.max"
        @keyup.ctrl.enter="saveAction()"
      ></textarea>
      <errors :field="v$.newNote.Content" />

      <div>
        <div class="letter-counter">{{ letterCounter }}</div>
      </div>
    </div>
    <flex-grid class="pdtb20 buttons" :full-width="true" :force-row="true">
      <s-button
        @click="resetNote()"
        :disabled="cancelDisabled"
        id="ButtonCancel"
        :flat="true"
        :dark="true"
        element-name="notes-form-cancelbutton"
        >{{ cancelText }}</s-button
      >

      <s-button
        element-name="notes-form-savebutton"
        :primary="true"
        :loading="saveLoading"
        @click="saveAction()"
        >{{ isUpdate ? $t('generic.update') : $t('generic.save') }}</s-button
      >
    </flex-grid>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { mapActions } from 'vuex';
import { NOTES_ACTIONS } from '@/store/modules/notes';
import NoteModel from '@common/Models/NoteModel';
import FlexGrid from '@common/Components/Grids/FlexGrid.vue';
import { isMobile } from '@common/Helpers/mobile';

export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  components: { FlexGrid },
  props: {
    loadedNote: NoteModel,
    displayText: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      newNote: new NoteModel({}),
      saveLoading: false
    };
  },
  validations: {
    newNote: NoteModel.validations
  },
  watch: {
    loadedNote(note) {
      if (!note) {
        this.resetNote();
        return;
      }
      this.newNote = this.loadedNote.__copy();
    }
  },
  mounted() {
    this.$refs.inputnote.focus();
    if (this.loadedNote) {
      this.newNote = this.loadedNote.__copy();
    }
  },
  methods: {
    ...mapActions('notes', {
      $createNote: NOTES_ACTIONS.CREATE_NOTE,
      $updateNote: NOTES_ACTIONS.UPDATE_NOTE
    }),
    saveAction() {
      this.v$.$touch();

      if (this.v$.newNote.$invalid) {
        return;
      }

      let action;
      if (this.isUpdate) {
        action = this.updateNote();
      } else {
        action = this.createNote();
      }
      action.finally(() => (this.saveLoading = false));
    },
    updateNote() {
      this.saveLoading = true;
      return this.$updateNote(this.newNote)
        .then(response => {
          this.resetNote();
        })
        .catch(error => {
          this.$debug(error);
          this.$bus.$emit('toast.display', {
            message: this.$root.$t('generic.saveFailure'),
            status: 'failure'
          });
        });
    },
    createNote() {
      this.saveLoading = true;
      return this.$createNote(this.newNote)
        .then(response => {
          this.resetNote();
        })
        .catch(error => {
          this.$debug(error);
          this.$bus.$emit('toast.display', {
            message: this.$root.$t('generic.saveFailure'),
            status: 'failure'
          });
        });
    },
    resetNote() {
      this.v$.$reset();
      this.$emit('reset-note');
      this.newNote = new NoteModel({});
    }
  },
  computed: {
    letterCounter() {
      return (
        this.newNote.Content.length +
        ' / ' +
        this.v$.newNote.Content.maxLength.$params.max
      );
    },
    isUpdate() {
      return !!this.loadedNote;
    },
    cancelText() {
      if (this.loadedNote) {
        if (this.loadedNote.Content === this.newNote.Content) {
          return this.$t('generic.cancel');
        }
        return this.$t('generic.undo');
      }
      return this.$t('generic.cancel');
    },
    cancelDisabled() {
      if (isMobile()) {
        return false;
      }
      return this.loadedNote ? false : !this.newNote.Content.length;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';
.new-note {
  max-width: 100%;
  .buttons {
    justify-content: center !important;
  }
  @media only screen and (min-width: $breakpoint-desktop) {
    max-width: 60%;
    .buttons {
      justify-content: flex-end !important;
    }
  }
}
textarea {
  height: 135px;
  border: 2px solid #adb2b8;
  border-radius: 2px;
  padding-bottom: 20px;
}
.textarea {
  position: relative;
}
.letter-counter {
  text-align: right;
  font-size: 12px;
  color: $symptoms-grey;
  position: absolute;
  right: 20px;
  bottom: 10px;
}
</style>
