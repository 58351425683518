<template>
  <svg
    v-bind="$attrs"
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <g fill="none" fill-rule="evenodd" v-if="active">
      <circle
        cx="20"
        cy="20"
        r="19.389"
        stroke="#122033"
        stroke-width="1.222"
      />
      <g fill="#000" fill-rule="nonzero">
        <path
          d="M26.735 13.437h-3.954v-.989c0-.8-.647-1.448-1.448-1.448h-3.435c-.796 0-1.444.648-1.444 1.448v.989H12.5v.726h1.043l1.166 14.37c0 .8.648 1.447 1.449 1.447h6.969c.8 0 1.448-.647 1.448-1.448l1.152-14.369h1.008v-.726zm-9.56-.989c0-.4.327-.726.723-.726h3.435c.4 0 .721.326.721.726v.989h-4.878v-.989zm6.674 16.045v.04c0 .4-.327.726-.722.726h-6.97a.724.724 0 0 1-.721-.727v-.04l-1.171-14.329H25l-1.151 14.33z"
        />
        <path
          d="M19.271 15.745h1v11.912h-1zM22.781 15.745h-.722l-.528 11.912h.721zM17.171 15.745h-.717l.53 11.912h.72z"
        />
      </g>
    </g>
    <g fill="none" fill-rule="evenodd" v-else>
      <circle cx="20" cy="20" r="20" fill="#ADB2B8" opacity=".3" />
      <g fill="#000" fill-rule="nonzero" opacity=".4">
        <path
          d="M26.735 13.437h-3.954v-.989c0-.8-.647-1.448-1.448-1.448h-3.435c-.796 0-1.444.648-1.444 1.448v.989H12.5v.726h1.043l1.166 14.37c0 .8.648 1.447 1.449 1.447h6.969c.8 0 1.448-.647 1.448-1.448l1.152-14.369h1.008v-.726zm-9.56-.989c0-.4.327-.726.723-.726h3.435c.4 0 .721.326.721.726v.989h-4.878v-.989zm6.674 16.045v.04c0 .4-.327.726-.722.726h-6.97a.724.724 0 0 1-.721-.727v-.04l-1.171-14.329H25l-1.151 14.33z"
        />
        <path
          d="M19.271 15.745h1v11.912h-1zM22.781 15.745h-.722l-.528 11.912h.721zM17.171 15.745h-.717l.53 11.912h.72z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    active: Boolean
  }
};
</script>
