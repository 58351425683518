<template>
  <main-header-menu-popup-vue
    parent=".app-header__organization"
    :open="open"
    min-width="133px"
  >
    <ul>
      <li
        v-for="group in $myGroups"
        :key="`${group.OrganizationId}/${group.Id}`"
      >
        <div
          class="item"
          @click="setGroup(group)"
          :class="{
            active:
              $activeGroup &&
              group.OrganizationId === $activeGroup.OrganizationId &&
              group.Id == $activeGroup.Id
          }"
        >
          {{ group.DisplayName }}
        </div>
      </li>
    </ul>
  </main-header-menu-popup-vue>
</template>

<script>
import { getDefaultLandingPage } from '../../router/helpers';
import MainHeaderMenuPopupVue from '@common/Components/MainHeaderMenuPopup.vue';
import { mapGetters, mapActions } from 'vuex';
import {
  CURRENT_USER_ACTIONS,
  CURRENT_USER_GETTERS
} from '@/store/modules/currentuser';

export default {
  components: { MainHeaderMenuPopupVue },
  data() {
    return {
      open: false
    };
  },
  mounted() {},
  methods: {
    ...mapActions('currentuser', {
      $setActiveGroup: CURRENT_USER_ACTIONS.SET_ACTIVE_GROUP
    }),
    setGroup(group) {
      this.$setActiveGroup({ group }).then(() => {
        const path = getDefaultLandingPage();
        this.$router.replace({ path }).catch(e => {});
      });
    }
  },
  computed: {
    ...mapGetters('currentuser', {
      $myGroups: CURRENT_USER_GETTERS.MY_GROUPS,
      $activeGroup: CURRENT_USER_GETTERS.ACTIVE_GROUP
    })
  }
};
</script>

<style lang="scss" scoped>
.small .role {
  font-size: 11px;
  color: #ccc;
}

.active .small .role {
  font-size: 11px;
  color: rgb(124, 124, 124);
}
</style>
