export class PromSuggestionModel {
  constructor({ CreatedDate, PromId, StatusReportId, Weight }) {
    this.CreatedDate = CreatedDate;
    this.PromId = PromId;
    this.StatusReportId = StatusReportId;
    this.Weight = Weight;
  }
}

export default PromSuggestionModel;
