export default {
  data() {
    return {
      hiddenQuestions: []
    };
  },
  mounted() {
    this.$bus.$on('toggleSkippables', this.toggleSkippables);
  },
  methods: {
    isHidden(question) {
      return this.hiddenQuestions.findIndex(q => q.Id === question.Id) > -1;
    },
    toggleSkippables(fromQuestion, toQuestion, skip) {
      if (!this.prom.sortedItems) {
        return;
      }
      const toQuestionIndex =
        toQuestion === '0'
          ? 1000000000000000
          : this.getQuestionIndex(toQuestion);
      const questions = this.prom.sortedItems.filter(
        x => x._modelName === 'Question'
      );
      let hiddenQuestionCounter = 0;

      for (let i = 0; i < this.prom.sortedItems.length; i++) {
        const question = questions[i];
        if (
          question &&
          question.Index > fromQuestion &&
          question.Index < toQuestionIndex
        ) {
          if (skip === false) {
            this.showQuestion(question);
          } else if (skip === true && question.Index) {
            this.hideQuestion(question);
          }
          if (question.Answers.length > 0) {
            if (skip) {
              hiddenQuestionCounter++;
            } else {
              hiddenQuestionCounter--;
            }
          }
        }
      }

      this.$bus.$emit('promreport.hiddenQuestionCounter', {
        from: fromQuestion,
        count: hiddenQuestionCounter,
        to: toQuestionIndex,
        skip
      });
    },

    getQuestionIndex(toQuestion, callback) {
      if (this.prom.sortedItems) {
        const itemToGo = this.prom.sortedItems.find(
          item => item.Id === toQuestion
        );
        if (itemToGo) {
          return itemToGo.Index;
        }
      }
    },
    hideQuestion(question) {
      if (this.hiddenQuestions.findIndex(q => q.Id === question.Id) === -1) {
        this.setAnswer(question, null, false, true);

        this.hiddenQuestions.push(question);

        if (question.Answers.length > 0) {
          this.toggleAnsweredQuestionNotApplicable(question, true);
        }
      }
    },
    showQuestion(question) {
      const index = this.hiddenQuestions.findIndex(q => q.Id === question.Id);
      if (index > -1) {
        this.removeAnswer(question);

        this.hiddenQuestions.splice(index, 1);
        if (question.Answers.length > 0) {
          this.toggleAnsweredQuestionNotApplicable(question, false);
        }
      }
    },
    toggleAnsweredQuestionNotApplicable(question, skipped) {
      const answeredQuestion = this.report.Answers.find(
        a => a.QuestionId === question.Id
      );
      if (answeredQuestion) {
        answeredQuestion.NotApplicable = skipped;
        if (skipped) {
          answeredQuestion.AnswerId = null;
        }
      }
    }
  }
};
