import { randomString } from '@common/Helpers/strings';
import { utcToLocal } from '@common/Helpers/time';

export class ShareModel {
  constructor({
    ShareId = randomString(),
    OrganizationId = 0,
    GroupId = 0,
    ShareWithUserId = null,
    ShareWithUserFullName = null,
    SymptomReports = false,
    PromReports = false,
    StatusReports = false,
    Notes = false,
    Enabled = false,
    BankIdOrderRef = null,
    RequestedById = null,
    RequestedBy = null,
    RequestedDate = null,
    AcceptedDate = null,
    CreatedDate = null,
    ModifiedDate = null,
    PatientId = null,
    PatientName = null,
    PatientPersonnummer = null,
    Version = 0
  }) {
    this._modelName = 'Share';
    this.ShareId = ShareId;
    this.OrganizationId = OrganizationId;
    this.GroupId = GroupId;
    this.ShareWithUserId = ShareWithUserId;
    this.ShareWithUserFullName = ShareWithUserFullName;
    this.SymptomReports = SymptomReports;
    this.PromReports = PromReports;
    this.StatusReports = StatusReports;
    this.Notes = Notes;
    this.Enabled = Enabled;
    this.BankIdOrderRef = BankIdOrderRef;
    this.RequestedById = RequestedById;
    this.RequestedBy = RequestedBy;
    this.RequestedDate = RequestedDate ? utcToLocal(RequestedDate) : null;
    this.AcceptedDate = AcceptedDate ? utcToLocal(AcceptedDate) : null;
    this.CreatedDate = utcToLocal(CreatedDate);
    this.ModifiedDate = ModifiedDate
      ? utcToLocal(ModifiedDate)
      : this.CreatedDate;
    this.PatientId = PatientId;
    this.PatientName = PatientName;
    this.PatientPersonnummer = PatientPersonnummer;
    this.Version = Version;
  }
}

export default ShareModel;
