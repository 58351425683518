const disableBodyScroll = () =>
  document.querySelector('body').classList.add('no-scroll');
const enableBodyScroll = () =>
  document.querySelector('body').classList.remove('no-scroll');

export const toggleBodyScroll = state => {
  if (state === true) {
    enableBodyScroll();
  } else {
    disableBodyScroll();
  }
};

export function cleanObject(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
}

export const sleep = (ms = 1000) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};
