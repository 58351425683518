export const LANGUAGE_MUTATIONS = {
  SET_LANGUAGES: 'SET_LANGUAGES'
};

export const LANGUAGE_ACTIONS = {
  $PREINIT: '$PREINIT',
  GET_LANGUAGES: 'GET_LANGUAGES'
};

export const LANGUAGE_GETTERS = {
  LANGUAGES: 'LANGUAGES',
  LANGUAGES_SORTED: 'LANGUAGES_SORTED'
};
