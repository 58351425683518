import AppVersionModel from '@common/Models/AppVersionModel';

/**
 * Map remote AppVersion to local
 * @param {object} dtoAppVersion
 * @returns {AppVersionModel}
 */
export const AppVersionResponse = dtoAppVersion => {
  return new AppVersionModel(dtoAppVersion);
};

/**
 * Map remote AppVersions to local
 * @param {object[]} dtoAppVersions
 * @returns {AppVersionModel[]}
 */
export const AppVersionsResponse = dtoAppVersions => {
  return dtoAppVersions.map(dto => AppVersionResponse(dto));
};
