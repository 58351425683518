import GroupModel from '@common/Models/GroupModel';

/**
 * Map local Group to remote
 * @param {GroupModel} group
 * @return {GroupModel}
 */
export const GroupRequest = group => {
  if (group.InitialPromId === '') {
    group.InitialPromId = null;
  }

  if (group.SymptomDefinitions) {
    group.SymptomDefinitions.map((sd, index) => {
      sd.Index = index; // In case questions have been reordered
    });
  }

  if (group.UrlSlug === '') {
    group.UrlSlug = null;
  }

  if (group.GroupCardPromId === '') {
    group.GroupCardPromId = null;
  }

  return group;
};
