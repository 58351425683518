// @ts-check
export class SymptomPointModel {
  constructor({ X, Y, Z, Size }) {
    this._modelName = 'SymptomPoint';
    this.X = X;
    this.Y = Y;
    this.Z = Z;
    this.Size = Size || 1;
  }

  get id() {
    return `${this.X}-${this.Y}-${this.Z}`;
  }
}

export default SymptomPointModel;
