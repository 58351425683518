// @ts-check
export class BankIdDeviceModel {
  constructor({ ipAddress }) {
    this._modelName = 'BankIdDevice';
    this.ipAddress = ipAddress;
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }
}

export default BankIdDeviceModel;
