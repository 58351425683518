<template>
  <router-link :to="link" class="block" v-if="report">
    <info-row :background-color="!isCompleted ? 'yellow' : ''">
      <template v-slot:title>
        <i class="mdi mdi-progress-clock" v-if="!isCompleted"></i>
        <span data-cy="inforow-promreport-date">{{
          $filters.DateTimeFormat(report.CreatedDate)
        }}</span>
      </template>
      <template v-slot:buttons>
        <i
          data-cy="promreport-details-deletebutton"
          class="mdi mdi-trash-can-outline"
          @click.prevent.self="confirmRemove = true"
        ></i>

        <modal-confirm
          :show="confirmRemove"
          :accept-callback="() => removeReport()"
          :accept-text="$t('generic.deleteShort')"
          :show-extras="false"
          :decline-text="$t('generic.cancel')"
          @close="confirmRemove = false"
          >{{ $t('modals.confirmRemoveProm') }}</modal-confirm
        >
      </template>

      <template v-slot:meta
        >{{ answeredQuestions }}/{{ questionCount }}
        {{ $tc('plural.questions', prom.QuestionCount) }}</template
      >

      <template v-slot:intensity>
        <div class="intensity">
          <span data-cy="inforow-promreport-intensity">
            <template v-if="!prom.HideScore"
              >{{ report.NormalizedScore }}
            </template>
            <template v-else>
              <i class="mdi mdi-check-bold green-text"></i>
            </template>
          </span>
        </div>
      </template>
    </info-row>
  </router-link>
</template>

<script>
import InfoRow from '@common/Components/InfoRow/InfoRow';
import { PROM_GETTERS } from '@/store/modules/proms';
import { sortByKey } from '@common/Helpers/sorters';
import { mapGetters, mapActions } from 'vuex';
import PromReportModel from '@common/Models/PromReportModel';
import { PROMREPORT_ACTIONS } from '@/store/modules/promreports';

export default {
  components: {
    InfoRow
  },
  props: {
    report: {
      type: PromReportModel,
      required: true
    }
  },
  data() {
    return {
      confirmRemove: false,
      removeLoading: false
    };
  },
  methods: {
    ...mapActions('promreports', {
      $removeReport: PROMREPORT_ACTIONS.REMOVE_REPORT,
      $getMyPromReports: PROMREPORT_ACTIONS.GET_MY_PROMREPORTS
    }),
    removeReport() {
      this.removeLoading = true;

      this.$removeReport(this.report)
        .catch(error => {
          this.$getMyPromReports();
        })
        .finally(() => {
          this.removeLoading = false;
        });
    }
  },

  computed: {
    ...mapGetters('proms', {
      $findProm: PROM_GETTERS.FIND_PROM
    }),
    link() {
      return {
        name: 'promReportEdit',
        params: { promid: this.report.PromId, promreportid: this.report.Id }
      };
    },
    answeredQuestions() {
      return this.report.Answers.length || this.report.AnswerCount || 0;
    },
    questionCount() {
      return this.report.QuestionCount || this.prom.QuestionCount || 0;
    },
    prom() {
      return this.$findProm(this.report.PromId) || {};
    },
    showPopup() {
      return this.confirmRemove;
    },
    isCompleted() {
      if (!this.prom) {
        return;
      }
      return this.answeredQuestions >= this.questionCount;
    }
  }
};
</script>

<style lang="scss" scoped>
.intensity {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 70px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;

  span {
    font-size: 2.1em;
    align-self: center;
  }
}
</style>
