class Loader {
  constructor() {
    this.status = false;
  }

  loading() {
    this.status = true;
    window.$bus.$emit('loader.loading');
  }

  done() {
    this.status = false;
    window.$bus.$emit('loader.done');
  }
}

export default new Loader();
