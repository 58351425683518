import SymptomReportModel from '@common/Models/SymptomReport/SymptomReportModel'; //eslint-disable-line
import SymptomModel from '@common/Models/Symptom/SymptomModel'; //eslint-disable-line
import ManikinModel from '@common/Models/ManikinModel';

export class State {
  constructor() {
    /**
     * @type {SymptomReportModel}
     * @memberof State
     */
    this.report = null;
    this.interactable = true;
    this.modellLoaded = false;
    this.drawing = false;
    this.symptomType = 'generic';
    this.availableTypes = [];
    this.controls = {
      camera: true,
      draw: false,
      erase: false,
      play: false
    };
    /**
     * @type {SymptomModel}
     * @memberof State
     */
    this.activeSymptom = null;
    this.models = [
      new ManikinModel({
        Model: 'male.gltf',
        Text: 'Male',
        Id: 'male'
      }),
      new ManikinModel({
        Model: 'female.gltf',
        Text: 'Female',
        Id: 'female'
      })
    ];
    this.activeModel = this.models[0];
    /**
     * @type {any[]}
     * @memberof State
     */
    this.tempBalls = [];
    /** @type {'NORMAL'|'SIMPLE'} */
    this.mode = 'NORMAL';
  }
}
