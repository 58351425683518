import GroupModel from '@common/Models/GroupModel';

/**
 * Map remote group to local
 * @param {object} dtoGroup
 * @returns {GroupModel}
 */
export const GroupResponse = dtoGroup => {
  return new GroupModel(dtoGroup);
};

/**
 * Map remote groups to local
 * @param {object[]} dtoGroups
 * @returns {GroupModel[]}
 */
export const GroupsResponse = dtoGroups => {
  return dtoGroups.map(dto => GroupResponse(dto));
};
