export class StatusReportTypeModel {
  constructor({ Name, Threshold, Operator, PromId }) {
    this.Name = Name;
    this.Threshold = Threshold;
    this.Operator = Operator;
    this.PromId = PromId;
  }
}

export default StatusReportTypeModel;
