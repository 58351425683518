<template>
  <modal
    :show="show"
    :hide-left-button="true"
    accept-text="Ok"
    accept-classes
    @close="$emit('close')"
    v-bind="$attrs"
  >
    <h5 class="center-text sub-header">{{ $t('generic.information') }}</h5>
    <p>{{ $t('views.statusReport.infoText1') }}</p>

    <p>{{ $t('views.statusReport.infoText2') }}</p>

    <p>{{ $t('views.statusReport.infoText3') }}</p>
  </modal>
</template>

<script>
export default {
  props: {
    show: Boolean
  }
};
</script>
