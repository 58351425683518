import BaseService from '../BaseService';
import {
  BankIdAuthResponse,
  BankIdCollectResponse
} from './mappers/BankIdResponses';

class BankIdService extends BaseService {
  /**
   * Start a BankId Auth transaction -- identity
   * @param {string} personnummer
   * @param {string} organizationId
   * @param {string} groupId
   * @returns {Promise<BankIdAuthResponseModel>} promise
   * @memberof BankIdService
   */
  startBankIdAuth(organizationId, groupId, getQr) {
    return this.identity('post', `bankid/auth/`, {
      OrganizationId: organizationId,
      GroupId: groupId,
      GetQr: getQr
    }).then(response => BankIdAuthResponse(response.data));
  }

  /**
   * Collect BankId details after started Auth transaction -- identity
   * @param {string} orderRef
   * @returns {Promise<BankIdCollectResponseModel>} promise
   * @memberof BankIdService
   */
  collectBankId(orderRef) {
    return this.identity(`bankid/collect/${orderRef}`).then(response =>
      BankIdCollectResponse(response.data)
    );
  }
}

export default new BankIdService();
