<template>
  <div class="search-results row" v-if="query.length">
    <result
      v-for="result in results"
      :prom="result"
      :query="query"
      :key="result.Id"
    ></result>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PROM_ACTIONS, PROM_GETTERS } from '@/store/modules/proms';
import { PROMREPORT_ACTIONS } from '@/store/modules/promreports';
import Result from './Result';
export default {
  props: ['query'],
  components: { Result },
  data() {
    return {
      searchData: {}
    };
  },
  beforeMount() {
    this.$findProms();
  },
  methods: {
    ...mapActions('promreports', {
      $newReport: PROMREPORT_ACTIONS.NEW_REPORT
    }),
    ...mapActions('proms', {
      $findProms: PROM_ACTIONS.FIND_PROMS
    }),
    newPromReport(promid) {
      this.$newReport(promid).then(report =>
        this.$router.push({
          name: 'promReportEdit',
          params: { promreportid: report.Id }
        })
      );
    },
    searchTags(prom, query) {
      if (!prom.Tags) {
        return;
      }
      return prom.Tags.filter(tag => tag.match(query)).length ? true : false;
    },
    searchId(prom, query) {
      return prom.Id.match(query);
    },
    searchDescription(prom, query) {
      return prom.Description.match(query);
    }
  },
  computed: {
    ...mapGetters('proms', {
      $allProms: PROM_GETTERS.ALL_SHOWN_PROMS
    }),
    results() {
      const regexp = new RegExp(this.query, 'i');
      return this.$allProms.filter(prom => {
        return (
          this.searchTags(prom, regexp) ||
          this.searchId(prom, regexp) ||
          this.searchDescription(prom, regexp)
        );
      });
    }
  }
};
</script>

<style lang="scss">
.search-results {
  .report-summary {
    @media only screen and (max-width: 400px) {
      .type-icon {
        display: none;
      }
    }
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.autocomplete-content {
  position: absolute !important;
  left: 0;
  right: 0;
}
</style>
