export const TRASH_MUTATIONS = {
  SET_TRASH: 'SET_TRASH',
  PURGE_ALL: 'PURGE_ALL',
  RESTORE_ALL: 'RESTORE_ALL',
  RESTORE_ITEM: 'RESTORE_ITEM',
  PURGE_ITEM: 'PURGE_ITEM'
};

export const TRASH_ACTIONS = {
  GET_TRASH_ITEMS: 'GET_TRASH_ITEMS',
  PURGE_ALL: 'PURGE_ALL',
  RESTORE_ALL: 'RESTORE_ALL',
  PURGE_ITEM: 'PURGE_ITEM',
  RESTORE_ITEM: 'RESTORE_ITEM',
  REFETCH_RESTORED_ITEM: 'REFETCH_RESTORED_ITEM'
};

export const TRASH_GETTERS = {
  ALL_TRASH: 'ALL_TRASH',
  ALL_TRASH_BY_DATE: 'ALL_TRASH_BY_DATE'
};
