import PatientModel from '@common/Models/PatientModel';

/**
 * Map frontend profile to backend dto
 * @param {PatientModel} Profile
 * @returns {object}
 */
export const UpdatePatientRequest = Profile => {
  const updatedProfile = Object.assign({}, Profile);
  delete updatedProfile.Id;

  Object.keys(updatedProfile).forEach(key => {
    if (updatedProfile[key] && updatedProfile[key].length === 0) {
      updatedProfile[key] = null;
    }
  });

  return updatedProfile;
};
