let instance = null;

class Timer {
  constructor(startTime = 0, interval = 100) {
    if (!instance) {
      instance = this;
    }

    this._timer = null;
    this._interval = interval;
    this.time = startTime;

    return instance;
  }

  start() {
    this._timer = setTimeout(() => {
      this.time += this._interval;
      this.start();
    }, this._interval);
  }

  stop() {
    clearTimeout(this._timer);
    this._timer = null;
    this.time = 0;
  }

  reset() {
    this.stop();
    this.start();
  }
}

export default Timer;
