// @ts-check
import { isFromRemote } from '@common/Helpers/api';
import ParticipantModel from '@common/Models/ParticipantModel';
import SymptomModel from '@common/Models/Symptom/SymptomModel';
import { utcToLocal } from '@common/Helpers/time';
import moment from 'moment';
import { SymptomMapper } from '@common/Models/Symptom/mappers/SymptomMapper';
import { SYMPTOM_TYPE_COLORS } from '@common/constants';
import { maxLength, minLength } from '@vuelidate/validators';

export const MALE_Z_LIMIT = 0.2;
export const FEMALE_Z_LIMIT = 0.54;

export class SymptomReportSymptomModel {
  constructor({
    SymptomId,
    Type,
    PosX,
    PosY,
    PosZ,
    Size = 1,
    CameraPosition,
    Version,
    CreatedDate,
    SymptomPoints = [],
    Intensity,
    Note,
    Symptom,
    Participant, // TODO: Refactor this as it is not part of the SRS model in backend
    SymptomReportId,
    browserAgent,
    _modified = false, // So we can remember modified state between views...
    PatientModelChosenSide = ''
  }) {
    this._modelName = 'SymptomReportSymptom';
    this._icon = null;
    this.SymptomId = SymptomId;
    this.Type = Type || 'generic';
    this.PosX = PosX;
    this.PosY = PosY;
    this.PosZ = PosZ;
    this.Size = Size;
    this.CameraPosition = CameraPosition;
    this.Version = Version;
    this.CreatedDate = CreatedDate ? utcToLocal(CreatedDate) : moment();
    this.SymptomPoints = SymptomPoints.map(sp =>
      SymptomMapper.mapSymptomPointModel(sp)
    );
    this.Intensity = Intensity;
    this.Note = Note;
    this.Symptom = Symptom ? new SymptomModel(Symptom) : null;
    this.Participant = Participant ? new ParticipantModel(Participant) : null;
    this.SymptomReportId = SymptomReportId;
    this.browserAgent = browserAgent;
    this._lastValue = this.Intensity; // Original value stored so we can revert.
    this._modified = _modified;
    this._saved = false;
    this.PatientModelChosenSide = PatientModelChosenSide;
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }

  get isCurrent() {
    return this.isFromRemote && !this.Symptom.isClosed;
  }

  get isFromRemote() {
    return isFromRemote(this);
  }

  get _color() {
    return SYMPTOM_TYPE_COLORS[this.Type];
  }

  get _opacity() {
    let opacity = 0;
    if (this.Intensity === 0) {
      opacity = 0.05;
    } else {
      if (this.Intensity < 10) {
        opacity = Number(this.Intensity) / 10 + 0.05;
      } else {
        opacity = 1;
      }
    }
    return opacity;
  }

  replacePositionWithPoint(point) {
    const index = this.SymptomPoints.findIndex(
      p => p.X === point.X && p.Y === point.Y && p.Z === point.Z
    );
    this.PosX = point.X;
    this.PosY = point.Y;
    this.PosZ = point.Z;
    this.Size = point.Size;

    this.removePoint(index);
  }

  removePoint(index) {
    try {
      return this.SymptomPoints.splice(index, 1);
    } catch (e) {
      console.error('error removing symptom point');
    }
  }

  get hasDrawing() {
    return this.PosX && this.PosY && this.PosZ;
  }

  removeDrawing() {
    this.PosY = undefined;
    this.PosX = undefined;
    this.PosZ = undefined;
    this.SymptomPoints = [];
  }

  getBodyMarkersAngle(model, angle) {
    let markers;
    switch (angle) {
      case 'front':
        markers = this.frontMarkers(model);
        break;
      case 'back':
        markers = this.backMarkers(model);
        break;
    }
    return markers;
  }

  frontMarkers(model = 'male') {
    return this.bodyMarkers.filter(bm => {
      if (model === 'male' && bm.z < MALE_Z_LIMIT) {
        return true;
      } else if (model === 'female' && bm.z < FEMALE_Z_LIMIT) {
        return true;
      }
    });
  }

  backMarkers(model = 'male') {
    return this.bodyMarkers.filter(bm => {
      if (model === 'male' && bm.z > MALE_Z_LIMIT) {
        return true;
      } else if (model === 'female' && bm.z > FEMALE_Z_LIMIT) {
        return true;
      }
    });
  }

  get bodyMarkers() {
    const markers = [
      { x: this.PosX, y: this.PosY, z: this.PosZ, size: this.Size }
    ];
    return markers.concat(
      this.SymptomPoints.map(p => ({ x: p.X, y: p.Y, z: p.Z, size: p.Size }))
    );
  }

  static get validations() {
    return {
      Note: {
        minLength: minLength(2),
        maxLength: maxLength(2000)
      }
    };
  }
}

export default SymptomReportSymptomModel;
