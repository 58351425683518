import BaseService from '../BaseService';
import TrashItemModel from '@common/Models/TrashItemModel';
import { TrashItemsResponse } from './mappers/TrashItemResponses';

class TrashService extends BaseService {
  /**
   * Get all Trash items -- backend
   * @returns {Promise<TrashItemModel[]>}
   * @memberof TrashService
   */
  getTrash() {
    return this.backend('my/trash').then(response =>
      TrashItemsResponse(response.data)
    );
  }

  /**
   * Restore all trash items -- backend
   * @returns {Promise}
   * @memberof TrashService
   */
  restore() {
    return this.backend('post', 'my/trash/restore');
  }

  /**
   * Purge all trash items -- backend
   * @returns {Promise}
   * @memberof TrashService
   */
  purge() {
    return this.backend('post', 'my/trash/purge');
  }
}

export default new TrashService();
