import NewsItemModel from '@common/Models/NewsItemModel';

/**
 * Map remote NewsItem to local
 * @param {object} dtoNewsItem
 * @returns {NewsItemModel}
 */
export const NewsItemResponse = dtoNewsItem => {
  return new NewsItemModel(dtoNewsItem);
};

/**
 * Map remote NewsItems to local
 * @param {object[]} dtoNewsItems
 * @returns {NewsItemModel[]}
 */
export const NewsItemsResponse = dtoNewsItems => {
  return dtoNewsItems.map(dto => NewsItemResponse(dto));
};
