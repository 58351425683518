<template>
  <span>
    {{ $filters.DateTimeFormat(report.CreatedDate) }}
    <template v-if="+report.ModifiedDate !== +report.CreatedDate">
      <i
        class="mdi mdi-pen orange-text"
        :title="`${$t('generic.modified')}: ${$filters.DateTimeFormat(
          report.ModifiedDate
        )}`"
      ></i>
    </template>
  </span>
</template>

<script>
export default {
  props: {
    report: {
      type: Object,
      required: true
    }
  }
};
</script>

<style></style>
