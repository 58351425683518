import MappedExportModel from '@common/Models/MappedExportModel';
import PromReportModel from '@common/Models/PromReportModel';
import AnsweredQuestionModel from '@common/Models/AnsweredQuestionModel';

/**
 * Map remote PromReport to local
 * @param {object[]} dtoPromReport
 * @returns {PromReportModel[]}
 */
export const PromReportResponse = dtoPromReport => {
  const newReport = new PromReportModel(dtoPromReport);

  newReport.Answers = [];

  if (dtoPromReport.Answers) {
    Object.keys(dtoPromReport.Answers).forEach(questionid => {
      if (isNaN(questionid) !== true) {
        newReport.Answers.push(
          new AnsweredQuestionModel(dtoPromReport.Answers[questionid])
        );
      } else {
        const answer = {
          QuestionId: questionid,
          AnswerIds: dtoPromReport.Answers[questionid]
        };
        newReport.Answers.push(new AnsweredQuestionModel(answer));
      }
    });
  }

  if (dtoPromReport.Version > 0) {
    newReport.saved = true;
  }

  return newReport;
};

/**
 * Map remote PromReports to local
 * @param {object[]} dtoPromReport
 * @returns {PromReportModel[]}
 */
export const PromReportsResponse = dtoPromReports => {
  return dtoPromReports.map(dto => new PromReportModel(dto)); // TODO: Use PromReportResponse?
};

/**
 * Map remote MappedExport to local
 * @param {object[]} dtoMappedExport
 * @returns {MappedExportModel[]}
 */
export const MappedExportsResponse = dtoMappedExports => {
  return dtoMappedExports.map(dto => new MappedExportModel(dto));
};
