<template>
  <div class="center-text" data-cy="settings-form-model">
    <img
      v-for="model in $availableModels"
      :key="model.Id"
      :src="`/images/${model.Id}-front-small.png`"
      :class="{ active: modelValue == model.Id }"
      @click="chooseModel(model)"
      :data-model="model.Id"
      :data-cy="modelCy(model.Id)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { APP_GETTERS } from '@/store/modules/app';

export default {
  props: {
    modelValue: {
      type: String
    }
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    chooseModel(newModel) {
      this.$emit('update:modelValue', newModel.Id);
    },
    modelCy(id) {
      return `settings-form-model-${id}`;
    }
  },
  computed: {
    ...mapGetters('app', {
      $availableModels: APP_GETTERS.AVAILABLE_MODELS
    })
  }
};
</script>

<style lang="scss" scoped>
img {
  cursor: pointer;
  padding: 5px;
  border: 3px solid transparent;
  &:not(:last-of-type) {
    margin-right: 10px;
  }
}
.active {
  border: 3px solid black;
}
</style>
