<template>
  <div v-if="$suggestions.length" style="max-width: 100%">
    <h4 class="sub-header" v-text="$t('generic.suggestionsForYou')"></h4>

    <card-container>
      <card-scroll ref="scroller">
        <card-prom
          v-for="(suggestion, i) in $suggestions"
          :key="`suggestion-${i}`"
          :remove-function="() => hideSuggestion(suggestion)"
          :prom="suggestion.prom"
        ></card-prom>
      </card-scroll>
    </card-container>
  </div>
</template>

<script>
import {
  STATUSREPORT_GETTERS,
  STATUSREPORT_ACTIONS
} from '@/store/modules/statusreports';
import { mapGetters, mapActions } from 'vuex';
import CardProm from '@/views/components/Card/CardProm.vue';

export default {
  components: {
    CardProm
  },
  methods: {
    ...mapActions('statusreports', {
      $hideSuggestion: STATUSREPORT_ACTIONS.HIDE_SUGGESTIONS
    }),
    hideSuggestion(suggestion) {
      return this.$hideSuggestion({
        statusReportId: suggestion.StatusReportId,
        promIds: suggestion.prom.Id
      })
        .then(() => {
          if (this.$refs.cardscroller) {
            this.$refs.cardscroller.handleWindowResize();
          }
          this.$bus.$emit('toast.display', {
            message: `${this.$root.$t('toaster.hiddenSuggestion')}`,
            status: 'success'
          });
        })
        .catch(e => {
          this.$bus.$emit('toast.display', {
            message: e.message,
            status: 'error'
          });
        });
    }
  },
  computed: {
    ...mapGetters('statusreports', {
      $suggestions: STATUSREPORT_GETTERS.SUGGESTIONS
    })
  }
};
</script>

<style></style>
