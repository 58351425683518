<template>
  <component :is="divOrLink" :to="link" class="block" v-if="report">
    <info-row
      :popup="showPopup"
      :progress="(answeredQuestions / questionCount) * 100"
    >
      <template v-slot:image>
        <i class="mdi mdi-clipboard-text"></i>
      </template>

      <template v-slot:title>
        <span>{{ prom.Concept }}</span>
      </template>
      <template v-slot:meta>
        <template v-if="!isCompleted">
          <span class="orange-text"
            >{{ answeredQuestions }}/{{ prom.QuestionCount }}&nbsp;</span
          >
          <span class="hide-small-phone orange-text">{{
            $tc('plural.questions', prom.QuestionCount)
          }}</span>
        </template>
        <template v-else>
          <i class="mdi mdi-check"></i>
        </template>
        &bull; {{ prom.Id }} &bull;
        <created-date :report="report"></created-date>
      </template>

      <template v-slot:buttons v-if="!hideButtons">
        <i
          data-cy="promreport-deletebutton"
          class="mdi mdi-trash-can-outline"
          @click.prevent.self="confirmRemove = true"
        ></i>

        <modal-confirm
          :show="confirmRemove"
          :accept-callback="() => removeReport()"
          :accept-text="$t('generic.deleteShort')"
          :show-extras="false"
          :decline-text="$t('generic.cancel')"
          @close="confirmRemove = false"
          >{{ $t('modals.confirmRemoveReport') }}</modal-confirm
        >
      </template>
    </info-row>
  </component>
</template>

<script>
import InfoRow from '@common/Components/InfoRow/InfoRow';
import { mapGetters, mapActions } from 'vuex';
import { PROM_GETTERS } from '@/store/modules/proms';
import PromReportModel from '@common/Models/PromReportModel';
import { PROMREPORT_ACTIONS } from '@/store/modules/promreports';
import CreatedDate from '@/views/components/InfoRow/CreatedDate.vue';
import CardMixin from './mixins/CardMixin';

export default {
  mixins: [CardMixin],
  components: { CreatedDate, InfoRow },
  props: {
    report: {
      type: PromReportModel,
      required: true
    },
    hideButtons: {
      type: Boolean
    }
  },
  data() {
    return {
      removeLoading: false
    };
  },
  methods: {
    ...mapActions('promreports', {
      $removeReport: PROMREPORT_ACTIONS.REMOVE_REPORT,
      $getMyPromReports: PROMREPORT_ACTIONS.GET_MY_PROMREPORTS
    }),
    removeReport() {
      this.removeLoading = true;
      this.$removeReport(this.report)
        .then(() => {
          this.$emit('delete');
        })
        .catch(error => {
          this.$getMyPromReports();
        })
        .finally(() => {
          this.removeLoading = false;
          this.showPopup = false;
        });
    }
  },
  computed: {
    ...mapGetters('proms', {
      $findProm: PROM_GETTERS.FIND_PROM
    }),
    answeredQuestions() {
      return this.report.Answers.length || this.report.AnswerCount || 0;
    },
    questionCount() {
      return this.report.QuestionCount || this.prom.QuestionCount || 0;
    },
    prom() {
      return this.$findProm(this.report.PromId) || {};
    },
    isCompleted() {
      if (!this.prom) {
        return;
      }
      return this.answeredQuestions >= this.questionCount;
    },
    link() {
      return {
        name: 'promReportEdit',
        params: { promid: this.report.PromId, promreportid: this.report.Id }
      };
    }
  }
};
</script>

<style></style>
