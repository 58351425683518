<template>
  <span>
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    container: String
  },
  mounted() {
    this.init();
  },
  beforeUnmount() {
    this.remove();
  },
  methods: {
    init(elem) {
      this.element.addEventListener('scroll', this.scroll);
    },
    remove(elem) {
      this.element.removeEventListener('scroll', this.scroll);
    },
    scroll() {
      const docViewTop = this.element.scrollTop;
      const docViewBottom = docViewTop + this.element.clientHeight;
      const elemTop = this.$el.offsetTop;

      const elHeight = this.$el.getBoundingClientRect().height;
      const elemBottom = elemTop + elHeight;
      if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
        this.$emit('visible');
      }
    }
  },
  computed: {
    element() {
      let elem;
      if (this.container) {
        elem = document.querySelector(this.container);
      } else {
        elem = window;
      }
      return elem;
    }
  }
};
</script>

<style></style>
