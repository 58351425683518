export const PROMREPORT_MUTATIONS = {
  SET_REPORTS: 'SET_REPORTS',
  SET_UNSAVED_REPORT: 'SET_UNSAVED_REPORT',
  ADD_NEW_REPORT: 'ADD_NEW_REPORT',
  REMOVE_REPORT: 'REMOVE_REPORT',
  SAVE_REPORT_SUCCESS: 'SAVE_REPORT_SUCCESS',
  SAVE_REPORT_FAILURE: 'SAVE_REPORT_FAILURE',
  SET_REPORT_CLEAN: 'SET_REPORT_CLEAN',
  ATTACH_NOTE: 'ATTACH_NOTE'
};

export const PROMREPORT_ACTIONS = {
  $PREINIT: '$PREINIT',
  GET_UNSAVED_REPORT: 'GET_UNSAVED_REPORT',
  SET_UNSAVED_REPORT: 'SET_UNSAVED_REPORT',
  REMOVE_UNSAVED_REPORT: 'REMOVE_UNSAVED_REPORT',
  GET_MY_PROMREPORT: 'GET_MY_PROMREPORT',
  GET_MY_PROMREPORTS: 'GET_MY_PROMREPORTS',
  GET_MY_LATEST_PROMREPORT_BY_PROM: 'GET_MY_LATEST_PROMREPORT_BY_PROM',
  ADD_OR_UPDATE_REPORT: 'ADD_OR_UPDATE_REPORT',
  ATTACH_NOTE: 'ATTACH_NOTE',
  REMOVE_REPORT: 'REMOVE_REPORT',
  REPLACE_OLD_REPORTS_WITH_REPORT: 'REPLACE_OLD_REPORTS_WITH_REPORT',
  EXPORT_MAPPED_DATA: 'EXPORT_MAPPED_DATA',
  EXPORT_PROMREPORTS_BY_GROUP_CSV: 'EXPORT_PROMREPORTS_BY_GROUP_CSV',
  EXPORT_PROMREPORTS_BY_GROUP_JSON: 'EXPORT_PROMREPORTS_BY_GROUP_JSON',
  EXPORT_SYMPTOMS_BY_GROUP_CSV: 'EXPORT_SYMPTOMS_BY_GROUP_CSV',
  EXPORT_SUBJECT_INFORMATION_BY_GROUP_CSV:
    'EXPORT_SUBJECT_INFORMATION_BY_GROUP_CSV'
};

export const PROMREPORT_GETTERS = {
  UNSAVED_REPORT: 'UNSAVED_REPORT',
  FIND_PROMREPORT: 'FIND_PROMREPORT',
  FIND_PROMREPORTS_BY_PROMID: 'FIND_PROMREPORTS_BY_PROMID',
  FIND_LATEST_PROMREPORT_BY_PROMID: 'FIND_LATEST_PROMREPORT_BY_PROMID',
  PROM_REPORTS: 'PROM_REPORTS',
  PROM_REPORTS_GROUPED_BY_PROM: 'PROM_REPORTS_GROUPED_BY_PROM',
  PROM_GRAPH_DATA: 'PROM_GRAPH_DATA',
  FIND_INCOMPLETE_PROMREPORTS: 'FIND_INCOMPLETE_PROMREPORTS',
  FIND_INCOMPLETE_PROMREPORTS_BY_PROMID: 'FIND_INCOMPLETE_PROMREPORTS_BY_PROMID'
};
