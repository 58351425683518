/**
 * Retrieve nested item from object/array
 * @param {Object|Array} obj
 * @param {String} path dot separated
 * @param {*} def default value ( if result undefined )
 * @returns {*}
 */
export function path(obj, path, def) {
  let i, len;
  for (i = 0, path = path.split('.'), len = path.length; i < len; i++) {
    if (!obj || typeof obj !== 'object') return def;
    obj = obj[path[i]];
  }
  if (obj === undefined) return def;
  return obj;
}

export function removePrivateVariablesFromBody(body) {
  if (!body) {
    return body;
  }
  return removeFromObject(body);

  function removeFromObject(obj) {
    Object.keys(obj).forEach(property => {
      if (property.match(/^_.*/)) {
        return delete obj[property];
      }

      if (obj.hasOwnProperty(property) && obj[property] !== null) {
        if (Array.isArray(obj[property])) {
          for (var i = 0; i < obj[property].length; i++) {
            removeFromObject(obj[property][i]);
          }
        } else if (typeof obj[property] === 'object') {
          removeFromObject(obj[property]);
        }
      }
    });

    return obj;
  }
}
