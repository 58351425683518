import SymptomModel from '@common/Models/Symptom/SymptomModel';

/**
 * Map remote symptom to local
 * @param {object} dtoSymptom symptom from backend
 * @returns {SymptomModel}
 */
export const SymptomResponse = dtoSymptom => {
  return new SymptomModel(dtoSymptom);
};

/**
 * Map remote symptoms to local
 * @param {object} dtoSymptoms symptoms from backend
 * @returns {SymptomModel[]}
 */
export const SymptomsResponse = dtoSymptoms => {
  return dtoSymptoms.map(dto => SymptomResponse(dto));
};
