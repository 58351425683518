import PromModel from '@common/Models/PromModel';

/**
 * Maps Prom to backend dto
 * @param {PromModel} prom
 */
export const PromLanguageVersionRequest = prom => {
  const dtoProm = Object.assign({}, prom);

  if (!dtoProm.Introduction || !dtoProm.Introduction.length) {
    delete dtoProm.Introduction;
  }
  return dtoProm;
};
