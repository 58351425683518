<template>
  <card-container :full-width="true">
    <card color="white">
      <template v-slot:content>
        <div class="note-inputs">
          <form @keyup.enter="createShare" v-if="newShare">
            <div class="row">
              <div class="col s6">
                <s-text-input
                  v-model="newShare.ShareWithEmail"
                  :label="$t('views.shares.shareWithEmail')"
                  :ref="`email`"
                  id="InputEmail"
                ></s-text-input>
              </div>
              <div class="col s6">
                <s-text-input
                  v-model="newShare.ShareWithUnit"
                  :label="$t('views.shares.shareWithUnit')"
                  :ref="`email`"
                  id="InputEmail"
                ></s-text-input>
              </div>
            </div>

            <div class="row">
              <div class="col s12">
                <s-text-input
                  v-model="newShare.ShareWithPersonnummer"
                  @keyup="el => fixPersonnummer(el.target.value)"
                  :maxlength="13"
                  :label="$t('views.shares.shareWithPersonnummer')"
                  id="InputPersonnummer"
                  type="tel"
                  pattern="[0-9\-]+"
                ></s-text-input>
              </div>
            </div>

            <div class="row">
              <div class="col s12">
                <label class="mr10">
                  <input
                    type="checkbox"
                    class="filled-in"
                    v-model="newShare.SymptomReports"
                    :checked="newShare.SymptomReports"
                  />
                  <span v-text="$tc('plural.symptomReport', 2)"></span>
                </label>

                <label class="mr10">
                  <input
                    type="checkbox"
                    class="filled-in"
                    v-model="newShare.PromReports"
                    :checked="newShare.PromReports"
                  />
                  <span v-text="$tc('plural.promReport', 2)"></span>
                </label>

                <label class="mr10">
                  <input
                    type="checkbox"
                    class="filled-in"
                    v-model="newShare.StatusReports"
                    :checked="newShare.StatusReports"
                  />
                  <span v-text="$tc('plural.statusReport', 2)"></span>
                </label>

                <label>
                  <input
                    type="checkbox"
                    class="filled-in"
                    v-model="newShare.Notes"
                    :checked="newShare.Notes"
                  />
                  <span v-text="$tc('plural.note', 2)"></span>
                </label>
              </div>
            </div>

            <flex-grid
              class="pdtb20 buttons"
              :full-width="true"
              :force-row="true"
            >
              <s-button
                class="right"
                @click="createShare"
                :primary="true"
                :small="true"
                :loading="sendShareLoading"
                >{{ $t('generic.send') }}</s-button
              >
            </flex-grid>
          </form>
        </div>
      </template>
    </card>
  </card-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SHARE_ACTIONS, SHAREGETTERS } from '@/store/modules/shares';
import NewShareModel from '@common/Models/Share/NewShareModel';
import { fixPersonnummer } from '@common/Helpers/strings';
import FlexGrid from '@common/Components/Grids/FlexGrid.vue';

export default {
  components: { FlexGrid },
  data() {
    return {
      newShare: null,
      sendShareLoading: false
    };
  },
  beforeMount() {
    this.newShare = new NewShareModel({});
  },
  methods: {
    ...mapActions('shares', {
      $CREATESHARE: SHARE_ACTIONS.CREATE_SHARE
    }),
    async createShare() {
      this.sendShareLoading = true;
      await this.$CREATESHARE(this.newShare);
      this.sendShareLoading = false;
      this.newShare = new NewShareModel({});
    },
    fixPersonnummer(pnr) {
      this.newShare.ShareWithPersonnummer = fixPersonnummer(pnr);
    }
  }
};
</script>

<style></style>
