<template>
  <modal
    v-bind="$attrs"
    element-name="modal-confirm"
    :show="show"
    @close="$emit('close')"
    height="auto"
    :decline-classes="declineClasses"
    :decline-text="declineText"
    :center-text="true"
  >
    <template v-slot:title>
      <slot name="title"></slot>
    </template>
    <p>
      <slot></slot>
    </p>

    <template v-slot:extras>
      <div v-if="showExtras" v-text="helpText || $t('modals.continue')"></div>
    </template>

    <template v-slot:right-button>
      <div>
        <s-button
          element-name="modal-confirm-confirm"
          :extra-classes="acceptClasses"
          @click.prevent="
            acceptCallback();
            $emit('close');
          "
        >
          <span v-text="acceptText || $t('generic.yes')"></span>!
        </s-button>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from './Modal';
export default {
  components: { Modal },
  emits: ['close'],
  props: {
    acceptText: {
      type: String
    },
    acceptClasses: {
      type: String,
      default: 'button--warning'
    },
    acceptCallback: {
      type: Function
    },
    declineText: {
      type: String
    },
    declineClasses: {
      type: String,
      default: ''
    },
    declineCallback: {
      type: Function
    },
    show: {
      type: Boolean
    },
    showExtras: {
      type: Boolean,
      default: true
    },
    helpText: {
      type: String
    }
  }
};
</script>
