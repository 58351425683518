// @ts-check
import { isFromRemote } from '@common/Helpers/api';
import { randomString } from '@common/Helpers/strings';
import { utcToLocal } from '@common/Helpers/time';
import moment from 'moment';
import { maxLength, minLength, required } from '@vuelidate/validators';

export class NoteModel {
  constructor({
    Id = randomString(),
    Content = '',
    CreatedDate = null,
    ModifiedDate = null,
    Version = 0
  }) {
    this._modelName = 'Note';
    this.Id = Id;
    this.Content = Content;
    this.CreatedDate = CreatedDate ? utcToLocal(CreatedDate) : moment();
    this.ModifiedDate = ModifiedDate ? utcToLocal(ModifiedDate) : moment();
    this.Version = Version;

    this._saved = false || isFromRemote(this);
  }

  static get validations() {
    return {
      Content: {
        minLength: minLength(2),
        maxLength: maxLength(2000),
        required
      }
    };
  }

  __copy() {
    return new NoteModel(JSON.parse(JSON.stringify(this)));
  }
}

export default NoteModel;
