import moment from 'moment';
import Papa from 'papaparse';
import { DateTimeStringForFile } from '@common/Filters/Date';
import storage from '@common/Helpers/storage';
import NoteService from '@common/Services/Notes/NoteService';
import { NOTES_ACTIONS, NOTES_GETTERS, NOTES_MUTATIONS } from './definitions';
import NoteModel from '@common/Models/NoteModel';
import { sortByCreatedDate } from '@common/Helpers/sorters';

class State {
  constructor() {
    /** @type {NoteModel[]} array of notes */
    this.list = [];
    this.nextNotesPageEmpty = false;
    this.loading = false;
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  /**
   * Add notes
   * @param {NoteModel[]} notes
   */
  [NOTES_MUTATIONS.ADD_NOTES](state, notes) {
    state.list.push(...notes);
  },

  /**
   * Set nextNotesPageEmpty boolean
   * @param {boolean} value
   */
  [NOTES_MUTATIONS.SET_NEXT_NOTES_PAGE_EMPTY](state, value) {
    state.nextNotesPageEmpty = value;
  },

  /**
   * Set notes
   * @param {NoteModel[]} notes
   */
  [NOTES_MUTATIONS.SET_NOTES](state, notes) {
    state.list = notes;
    state.list = sortByCreatedDate(state.list).reverse();
  },

  /**
   * Add note
   * @param {NoteModel} note
   */
  [NOTES_MUTATIONS.ADD_NOTE](state, note) {
    const exists = state.list.find(n => n.Id === note.Id);

    if (!exists) {
      state.list.unshift(note);
    } else {
      Object.assign(exists, note);
    }
  },

  /**
   * Add note
   * @param {Object} payload
   * @param {NoteModel} payload.note
   * @param {NoteModel} payload.newNote
   */
  [NOTES_MUTATIONS.NOTE_SAVE_SUCCESS](state, payload) {
    state.loading = false;

    const index = state.list.findIndex(n => n.Id === payload.note.Id);
    if (index === -1) {
      state.list.unshift(payload.newNote);
    } else {
      state.list[index] = payload.newNote;
    }
  },

  /**
   * Save failed
   * @param {NoteModel} unsavedNote
   */
  [NOTES_MUTATIONS.NOTE_SAVE_FAILURE](state, unsavedNote) {
    state.loading = false;
  },

  /**
   * Remove note
   * @param {NoteModel} note
   */
  [NOTES_MUTATIONS.NOTE_REMOVED_SUCCESS](state, note) {
    const index = state.list.findIndex(n => n.Id === note.Id);
    state.list.splice(index, 1);
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [NOTES_ACTIONS.$PREINIT]({ commit }, notes) {
    const unsavedPatientNotes = storage.get('user/notes') || [];

    if (unsavedPatientNotes.length) {
      unsavedPatientNotes.forEach(note => {
        state.commit(NOTES_MUTATIONS.ADD_NOTE, note);
      });
    }

    if (notes) {
      commit(NOTES_MUTATIONS.SET_NOTES, notes);
    }
  },

  [NOTES_ACTIONS.GET_MY_NOTE]({ commit }, id) {
    return NoteService.findMyNote(id)
      .then(note => {
        commit(NOTES_MUTATIONS.ADD_NOTE, note);
        return note;
      })
      .catch(error => console.error(error));
  },

  [NOTES_ACTIONS.GET_MY_NOTES]({ commit, state }, { page, take }) {
    NoteService.getMyNotes(page, take).then(notes => {
      NoteService.getMyNotes(page + 1, take).then(notes => {
        if (notes.length == 0) {
          commit(NOTES_MUTATIONS.SET_NEXT_NOTES_PAGE_EMPTY, true);
        }
      });
      commit(NOTES_MUTATIONS.ADD_NOTES, notes);
    });
  },

  [NOTES_ACTIONS.CREATE_NOTE]({ commit }, note) {
    commit(NOTES_MUTATIONS.ADD_NOTE, note);

    return NoteService.addMyNote(note)
      .then(newNote => {
        commit(NOTES_MUTATIONS.NOTE_SAVE_SUCCESS, { note, newNote });
        return newNote;
      })
      .catch(error => {
        commit(NOTES_MUTATIONS.NOTE_SAVE_FAILURE);
        throw error;
      });
  },

  [NOTES_ACTIONS.UPDATE_NOTE]({ commit }, note) {
    return NoteService.updateNote(note).then(newNote =>
      commit(NOTES_MUTATIONS.NOTE_SAVE_SUCCESS, { note, newNote })
    );
  },

  [NOTES_ACTIONS.REMOVE_NOTE]({ commit }, note) {
    return NoteService.deleteMyNote(note.Id).then(() => {
      commit(NOTES_MUTATIONS.NOTE_REMOVED_SUCCESS, note);
      return note;
    });
  },

  [NOTES_ACTIONS.EXPORT_NOTES_BY_GROUP_CSV](context, requiredContracts) {
    return NoteService.getNotesExportDataByGroup(requiredContracts).then(
      notes => {
        if (notes.length === 0) {
          alert('Ingen data hittad för givet intervall.');
          return;
        }

        const csvDataArray = [];
        // Remap for csv
        notes.map(row => {
          let personnummer;
          // Find personnummer value
          for (let i = 0; i < row.Participant.Identifiers.length; i++) {
            if (row.Participant.Identifiers[i].Description == 'Personnummer') {
              personnummer = row.Participant.Identifiers[i].Value;
              personnummer = personnummer.replace('-', '');
            }
          }

          const csvData = {
            Personnummer: personnummer,
            Created: row.CreatedDate,
            Modified: row.ModifiedDate,
            Note: row.Content
          };
          csvDataArray.push(csvData);
        });

        const currentDateTime = moment().format(DateTimeStringForFile);
        const label = `notes_export_${currentDateTime}`;

        const bom = '\ufeff'; // BOM character to get correct åäö encoding
        const csvData = Papa.unparse(csvDataArray);
        const csv = `${bom}${csvData}`;

        const blob = new Blob([csv], {
          type: 'data:text/csv;charset=utf-8'
        });
        saveAs(blob, `${label}.csv`);
      }
    );
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [NOTES_GETTERS.NOTES](state) {
    return state.list;
  },

  [NOTES_GETTERS.NEXT_NOTES_PAGE_EMPTY](state) {
    return state.nextNotesPageEmpty;
  },

  [NOTES_GETTERS.SORTED_NOTES](state) {
    return state.list.slice().sort((a, b) => {
      return a.CreatedDate < b.CreatedDate;
    });
  },

  [NOTES_GETTERS.UNSAVED_NOTES](state) {
    return state.list.filter(note => note._saved === false);
  },

  [NOTES_GETTERS.FIND_NOTE]: state => noteId => {
    return state.list.find(n => n.Id === noteId);
  }
};

export { NOTES_MUTATIONS, NOTES_ACTIONS, NOTES_GETTERS } from './definitions';

export default store;
