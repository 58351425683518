export const SYMPTOMREPORT_MUTATIONS = {
  SET_REPORTS: 'SET_REPORTS',
  SET_SHOW_THANK_YOU_MODAL: 'SET_SHOW_THANK_YOU_MODAL',
  ADD_NEW_SYMPTOM_REPORT: 'ADD_NEW_SYMPTOM_REPORT',
  SET_UNSAVED_REPORT: 'SET_UNSAVED_REPORT',
  REMOVE_UNSAVED_REPORT: 'REMOVE_UNSAVED_REPORT',
  REMOVE_REPORT: 'REMOVE_REPORT'
};

export const SYMPTOMREPORT_ACTIONS = {
  $PREINIT: '$PREINIT',
  GET_MY_SYMPTOM_REPORTS: 'GET_MY_SYMPTOM_REPORTS',
  GET_MY_SYMPTOM_REPORT: 'GET_MY_SYMPTOM_REPORT',
  ADD_OR_UPDATE_REPORT: 'ADD_OR_UPDATE_REPORT',
  ATTACH_NOTE: 'ATTACH_NOTE',
  REMOVE_REPORT: 'REMOVE_REPORT'
};

export const SYMPTOMREPORT_GETTERS = {
  SYMPTOM_REPORTS: 'SYMPTOM_REPORTS',
  FIND_SYMPTOM_REPORT: 'FIND_SYMPTOM_REPORT',
  UNSAVED_REPORT: 'UNSAVED_REPORT',
  SHOW_THANK_YOU_MODAL: 'SHOW_THANK_YOU_MODAL'
};
