import { isRemoteId } from '@common/Helpers/api';
import storage from '@common/Helpers/storage';
import SymptomModel from '@common/Models/Symptom/SymptomModel';
import SymptomService from '@common/Services/Symptoms/SymptomService';
import { SymptomResponse } from '@common/Services/Symptoms/mappers/SymptomResponses';
import { CURRENT_USER_GETTERS } from '../currentuser';
import {
  SYMPTOM_ACTIONS,
  SYMPTOM_GETTERS,
  SYMPTOM_MUTATIONS
} from './definitions';

class State {
  constructor() {
    /** @type {SymptomModel[]} */
    this.list = [];
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  [SYMPTOM_MUTATIONS.SET_SYMPTOMS](state, symptoms) {
    state.list = symptoms.filter(x => x !== null);
  },
  [SYMPTOM_MUTATIONS.ADD_NEW_SYMPTOM](state, symptom) {
    const index = state.list.findIndex(s => s.Id === symptom.Id);

    if (index === -1) {
      state.list.push(symptom);
    } else {
      state.list[index] = symptom;
    }
  },
  [SYMPTOM_MUTATIONS.SAVE_SYMPTOM_SUCCESS](state, symptom) {
    const index = state.list.findIndex(s => s.Id === symptom.Id);
    state.list[index] = symptom;
  },
  [SYMPTOM_MUTATIONS.SAVE_SYMPTOM_FAILURE](state, symptom) {},
  [SYMPTOM_MUTATIONS.REMOVE_SYMPTOM](state, symptom) {
    const index = state.list.findIndex(s => s.Id === symptom.Id);
    if (index > -1) {
      state.list.splice(index, 1);
    }
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [SYMPTOM_ACTIONS.$PREINIT]({ commit, dispatch }, symptoms) {
    const unsavedSymptoms = storage.find('symptom', true);
    if (unsavedSymptoms.length > 0) {
      unsavedSymptoms
        .map(report => SymptomResponse(symptom))
        .forEach(symptom => commit(SYMPTOM_MUTATIONS.ADD_NEW_SYMPTOM, symptom));
    }
    if (symptoms) {
      commit(SYMPTOM_MUTATIONS.SET_SYMPTOMS, symptoms);
    } else {
      dispatch(SYMPTOM_ACTIONS.GET_MY_SYMPTOMS);
    }
  },

  [SYMPTOM_ACTIONS.GET_MY_SYMPTOMS]({ commit }) {
    return SymptomService.getMySymptoms()
      .then(symptoms => commit(SYMPTOM_MUTATIONS.SET_SYMPTOMS, symptoms))
      .catch(error => console.error(error));
  },

  [SYMPTOM_ACTIONS.GET_MY_SYMPTOM]({ commit }, id) {
    return SymptomService.findMySymptom(id)
      .then(symptom => {
        commit(SYMPTOM_MUTATIONS.ADD_NEW_SYMPTOM, symptom);
        return symptom;
      })
      .catch(error => console.error(error));
  },

  [SYMPTOM_ACTIONS.UPDATE_SYMPTOM]({ commit, dispatch }, symptom) {
    return SymptomService.updateMySymptom(symptom)
      .then(newsymptom => {
        commit(SYMPTOM_MUTATIONS.SAVE_SYMPTOM_SUCCESS, newsymptom);
        return newsymptom;
      })
      .catch(error => {
        commit(SYMPTOM_MUTATIONS.SAVE_SYMPTOM_FAILURE, {
          symptom,
          error: error.toString()
        });
        throw error;
      });
  },

  [SYMPTOM_ACTIONS.CLOSE_SYMPTOM]({ commit }, symptom) {
    return SymptomService.closeMySymptom(symptom).then(symptom => {
      commit(SYMPTOM_MUTATIONS.SAVE_SYMPTOM_SUCCESS, symptom);
    });
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [SYMPTOM_GETTERS.SYMPTOMS](state, getters, rootState, rootGetters) {
    return state.list.filter(
      report =>
        report.Model ===
        rootGetters[
          `currentuser/${CURRENT_USER_GETTERS.GET_ACTIVE_SYMPTOM_MODEL}`
        ].Id
    );
  },
  [SYMPTOM_GETTERS.SYMPTOMS_ALL](state) {
    return state.list;
  },
  /**
   * Open symptoms for current manikin model
   */
  [SYMPTOM_GETTERS.CURRENT_SYMPTOMS](state, getters) {
    const currentSymptoms = getters[SYMPTOM_GETTERS.SYMPTOMS].filter(
      s => !s.isClosed
    );

    return currentSymptoms;
  },
  /**
   * Open symptoms regardless of manikin model
   */
  [SYMPTOM_GETTERS.ACTIVE_SYMPTOMS](state, getters) {
    const activeSymptoms = getters[SYMPTOM_GETTERS.SYMPTOMS_ALL].filter(
      s => !s.isClosed
    );

    return activeSymptoms;
  },
  [SYMPTOM_GETTERS.FIND_SYMPTOM]: state => Id => {
    if (!isRemoteId(Id) && state.list.find(s => s.Id === Id)) {
      const savedSymptom = storage.getEncrypted(`symptom/${Id}`) || null;
      return savedSymptom;
    }
    return state.list.find(s => s.Id === Id) || null;
  },
  [SYMPTOM_GETTERS.SYMPTOM_GRAPH_DATA]: (state, getters) => symptom => {
    if (!symptom) {
      return null;
    }

    return symptom.Reports.map(report => {
      return {
        date: report.CreatedDate.toDate(),
        moment: report.CreatedDate,
        value: +report.Intensity
      };
    });
  }
};

export {
  SYMPTOM_ACTIONS,
  SYMPTOM_GETTERS,
  SYMPTOM_MUTATIONS
} from './definitions';

export default store;
