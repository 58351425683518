<template>
  <modal
    v-bind="$attrs"
    v-if="share"
    height="auto"
    :accept-text="$t('generic.show')"
    :decline-text="$t('generic.close')"
    :accept-callback="() => $router.push({ name: 'shares' })"
  >
    <template v-slot:title>
      <span>
        {{
          $t('alerts.share-request-created.title', {
            name: share.RequestedBy
          })
        }}
      </span>
    </template>
    {{
      $t('alerts.share-request-created.body', {
        name: share.RequestedBy
      })
    }}
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { SHARE_GETTERS } from '@/store/modules/shares';

export default {
  props: {
    shareId: String
  },
  computed: {
    ...mapGetters('shares', {
      $FINDSHARE: SHARE_GETTERS.FIND_SHARE
    }),
    share() {
      return this.$FINDSHARE(this.shareId);
    }
  }
};
</script>

<style></style>
