<template>
  <router-link
    :to="resumeOrNew"
    :id="`prom-${prom.Id}`"
    class="block"
    :class="{ loading: removeLoading }"
  >
    <info-row v-bind="$attrs" :remove-loading="removeLoading">
      <template v-slot:image>
        <i class="mdi mdi-clipboard-text"></i>
      </template>

      <template v-slot:title>
        <span>{{ prom.Concept }}</span>
      </template>

      <template v-slot:meta>
        <span>
          {{ prom.QuestionCount }}
          {{ $tc('plural.questions', prom.QuestionCount) }} &bull;
          {{ prom.Id }}
        </span>
      </template>

      <template v-slot:buttons>
        <template v-if="removeFunction">
          <i class="mdi" :class="removeIcon" @click.prevent.self="remove()"></i>
        </template>
      </template>
    </info-row>
  </router-link>
</template>

<script>
import InfoRow from '@common/Components/InfoRow/InfoRow';
import {
  PROMREPORT_GETTERS,
  PROMREPORT_ACTIONS
} from '@/store/modules/promreports';
import { mapGetters, mapActions } from 'vuex';
export default {
  components: { InfoRow },
  props: {
    useActive: {
      type: Boolean
    },
    prom: {
      type: Object,
      required: true
    },
    removeCallback: {
      type: Function
    },
    removeFunction: {
      type: Function
    },
    removeIcon: {
      type: String,
      default: 'mdi-cross'
    },
    routeTo: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      removeLoading: false
    };
  },
  methods: {
    ...mapActions('promreports', {
      $getMyPromReports: PROMREPORT_ACTIONS.GET_MY_PROMREPORTS
    }),
    remove() {
      if (!this.removeFunction) {
        return;
      }
      this.removeLoading = true;
      this.removeFunction()
        .catch(error => {
          this.$getMyPromReports();
        })
        .finally(() => {
          this.removeLoading = false;
        });
    }
  },
  computed: {
    ...mapGetters('promreports', {
      $incompletePromReports: PROMREPORT_GETTERS.FIND_INCOMPLETE_PROMREPORTS
    }),
    resumeOrNew() {
      if (this.routeTo) {
        return this.routeTo;
      }
      const newLink = {
        name: 'promReportEdit',
        params: { promid: this.prom.Id }
      };

      if (this.useActive === false) {
        return newLink;
      }

      const incompleteReport = this.$incompletePromReports.find(
        report => report.PromId === this.prom.Id
      );
      if (incompleteReport) {
        return {
          name: 'promReportEdit',
          params: { promid: this.prom.Id, promreportid: incompleteReport.Id }
        };
      } else {
        return newLink;
      }
    }
  }
};
</script>

<style scoped>
.loading {
  cursor: default;
  pointer-events: none;
}
</style>
