export const SYMPTOM_DEFINITION_MUTATIONS = {
  ADD_SYMPTOM_DEFINITION: 'ADD_SYMPTOM_DEFINITION',
  SET_SYMPTOM_DEFINITIONS: 'SET_SYMPTOM_DEFINITIONS',
  REMOVE_SYMPTOM_DEFINITION: 'REMOVE_SYMPTOM_DEFINITION'
};

export const SYMPTOM_DEFINITION_ACTIONS = {
  CREATE_SYMPTOM_DEFINITION: 'CREATE_SYMPTOM_DEFINITION',
  GET_MY_SYMPTOM_DEFINITIONS: 'GET_MY_SYMPTOM_DEFINITIONS',
  REMOVE_SYMPTOM_DEFINITION: 'REMOVE_SYMPTOM_DEFINITION'
};

export const SYMPTOM_DEFINITION_GETTERS = {
  SYMPTOM_DEFINITIONS: 'SYMPTOM_DEFINITIONS',
  SNOMED_SYMPTOM_DEFINITIONS: 'SNOMED_SYMPTOM_DEFINITIONS',
  NON_SNOMED_SYMPTOM_DEFINITIONS: 'NON_SNOMED_SYMPTOM_DEFINITIONS',
  HAS_SYMPTOM_DEFINITION: 'HAS_SYMPTOM_DEFINITION'
};
