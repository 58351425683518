import moment from 'moment';
import SymptomReportModel from '@common/Models/SymptomReport/SymptomReportModel';
import BaseService from '../BaseService';
import { SymptomReportRequest } from './mappers/SymptomReportRequests';
import {
  SymptomReportResponse,
  SymptomReportsResponse
} from './mappers/SymptomReportResponses';

class SymptomReportService extends BaseService {
  /**
   * Get current user's SymptomReports -- backend
   * @returns {Promise<SymptomReportModel[]>}
   * @memberof SymptomReportService
   */
  getMySymptomReportsFromLastMonth() {
    return this.backend('my/symptomreports').then(response =>
      SymptomReportsResponse(response.data)
    );
  }

  /**
   * Get current user's specific SymptomReport -- backend
   * @param {string} id SymptomReport Id
   * @returns {Promise<SymptomReportModel>}
   * @memberof SymptomReportService
   */
  findMySymptomReport(id) {
    return this.backend(`my/symptomreports/${id}`).then(response =>
      SymptomReportResponse(response.data)
    );
  }

  /**
   * Save SymptomReport -- backend
   * @param {SymptomReportModel} report SymptomReport
   * @returns {Promise<SymptomReportModel>}
   * @memberof SymptomReportService
   */
  addMySymptomReport(report) {
    return this.backend(
      'post',
      'my/symptomreports',
      SymptomReportRequest(report)
    ).then(response => SymptomReportResponse(response.data));
  }

  /**
   * Update SymptomReport -- backend
   * @param {SymptomReportModel} report SymptomReport
   * @returns {Promise<SymptomReportModel>}
   * @memberof SymptomReportService
   */
  updateMySymptomReport(report) {
    return this.backend(
      'put',
      `my/symptomreports/${report.Id}`,
      SymptomReportRequest(report)
    ).then(response => SymptomReportResponse(response.data));
  }
  /**
   * Delete SymptomReport -- backend
   * @param {string} guid SymptomReport Id
   * @returns {Promise}
   * @memberof SymptomReportService
   */
  deleteMySymptomReport(guid) {
    return this.backend('post', `my/symptomreports/${guid}/delete`);
  }

  /**
   * Restore deleted SymptomReport -- backend
   * @param {string} guid SymptomReport Id
   * @returns {Promise}
   * @memberof SymptomReportService
   */
  restoreMySymptomReport(guid) {
    return this.backend('post', `my/symptomreports/${guid}/restore`);
  }

  /**
   * Purge deleted SymptomReport -- backend
   * @param {string} guid SymptomReport Id
   * @returns {Promise}
   * @memberof SymptomReportService
   */
  purgeMySymptomReport(guid) {
    return this.backend('delete', `my/symptomreports/${guid}`);
  }

  /**
   * Get reports by patient id -- backend
   * @param {string} patientId patient Id
   * @returns {Promise<SymptomReportModel[]>}
   * @memberof SymptomReportService
   */
  getSymptomReportsByPatient(patientId) {
    return this.backend(`patients/${patientId}/symptomreports`).then(response =>
      SymptomReportResponse(response.data)
    );
  }

  getGroupSymptomReports(
    groupId,
    orgId,
    fromDate = moment().format('YYYY-MM-DD'),
    toDate = moment().format('YYYY-MM-DD')
  ) {
    return this.backend(
      `organizations/${orgId}/groups/${groupId}/symptomreports/${fromDate}/${toDate}`
    ).then(response => SymptomReportsResponse(response.data));
  }

  /**
   * Get specific SymptomReport -- backend
   * @param {string} id SymptomReport Id
   * @returns {Promise<SymptomReportModel>}
   * @memberof SymptomReportService
   */
  findSymptomReport(id) {
    return this.backend(`symptomreports/${id}`).then(response =>
      SymptomReportResponse(response.data)
    );
  }
}

export default new SymptomReportService();
