import moment from 'moment';
import aes from 'aes-js';
import config from '@common/config';

class Storage {
  /**
   * Stores content to localstorage
   * @param {string} key content key
   * @param {any} content content
   * @param {moment} expires date when it is to be removed
   * @param {number} expiresInDays remove in x days
   */
  set(key, content, expires = null, expiresInDays = null) {
    const savedContent = {};

    savedContent.content = content;
    savedContent.expires =
      expires ||
      moment()
        .add(expiresInDays || 2, 'days')
        .toString();

    localStorage[key] = JSON.stringify(savedContent);
  }

  /**
   * Stores encrypted content to localstorage
   * @param {string} key content key
   * @param {any} content content
   * @param {moment} expires date when it is to be removed
   */
  setEncrypted(key, content, expires) {
    content = encrypt(JSON.stringify(content), config.encryption_key);
    this.set(key, content, expires);
  }

  /**
   * Get content from localstorage
   * @param {string} key content key
   */
  get(key) {
    if (localStorage[key]) {
      const savedContent = JSON.parse(localStorage[key]);

      if (savedContent.expires && hasExpired(savedContent)) {
        this.remove(key);
        return false;
      }

      return savedContent.content;
    }
  }

  /**
   * Finds content by key
   * @param {string} query search query
   * @param {boolean} encrypted is it encrypted?
   */
  find(query, encrypted) {
    query = new RegExp(query, 'g');
    const matches = [];
    Object.keys(localStorage).forEach(key => {
      if (key.match(query)) {
        const value = encrypted ? this.getEncrypted(key) : this.get(key);
        if (value) {
          matches.push(value);
        }
      }
    });

    if (matches.length === 0) {
      return false;
    }

    return matches;
  }

  /**
   * Get encrypted content
   * @param {string} key content key
   */
  getEncrypted(key) {
    try {
      const savedContent = this.get(key);
      if (savedContent) {
        const decrypted = decrypt(savedContent, config.encryption_key);
        return decrypted;
      }
    } catch (e) {
      console.error(`Error decrypting ${key}, removing it.`);
      this.remove(key);
    }
  }

  /**
   * Removes content from localstorage
   * @param {string} key content key
   */
  remove(key) {
    delete localStorage[key];
  }

  /**
   * Clears localstorage
   */
  removeAll() {
    Object.keys(localStorage).forEach(key => {
      if (key.match(/edit|keep/)) {
        return;
      }
      this.remove(key);
    });
  }

  /**
   * Check if key exists in localstorage
   * @param {string} key content key
   */
  exists(key) {
    return !!localStorage[key];
  }
}

export default new Storage();

function encrypt(text, key) {
  key = aes.utils.utf8.toBytes(key);
  const textBytes = aes.utils.utf8.toBytes(text);
  const aesCtr = new aes.ModeOfOperation.ctr(key, new aes.Counter(5));
  const encryptedBytes = aesCtr.encrypt(textBytes);
  return aes.utils.hex.fromBytes(encryptedBytes);
}

function decrypt(encryptedHex, key) {
  key = aes.utils.utf8.toBytes(key);
  const encryptedBytes = aes.utils.hex.toBytes(encryptedHex);
  const aesCtr = new aes.ModeOfOperation.ctr(key, new aes.Counter(5));
  const decryptedBytes = aesCtr.decrypt(encryptedBytes);
  return JSON.parse(aes.utils.utf8.fromBytes(decryptedBytes));
}

function hasExpired(savedContent) {
  return moment().isAfter(new Date(savedContent.expires));
}
