import BaseService from '../BaseService';
import OrganizationModel from '@common/Models/OrganizationModel';
import {
  OrganizationResponse,
  OrganizationsResponse
} from './mappers/OrganizationResponses';

class OrganizationService extends BaseService {
  /**
   * Get organizations -- backend
   * @returns {Promise<OrganizationModel[]>}
   * @memberof OrganizationService
   */
  getOrganizations() {
    return this.backend('organizations').then(response =>
      OrganizationsResponse(response.data)
    );
  }

  /**
   * Get organization -- backend
   * @param {string} id
   * @returns {Promise<OrganizationModel>}
   * @memberof OrganizationService
   */
  getOrganization(id) {
    return this.backend(`organizations/${id}`).then(response =>
      OrganizationResponse(response.data)
    );
  }

  /**
   * Create organization -- backend
   * @param {OrganizationModel} organization
   * @returns {Promise<OrganizationModel>}
   * @memberof OrganizationService
   */
  createOrganization(organization) {
    return this.backend('post', `organizations`, organization).then(response =>
      OrganizationResponse(response.data)
    );
  }

  /**
   * Update organization -- backend
   * @param {string} id
   * @param {OrganizationModel} organization
   * @returns {Promise<OrganizationModel>}
   * @memberof OrganizationService
   */
  updateOrganization(id, organization) {
    return this.backend('put', `organizations/${id}`, organization).then(
      response => OrganizationResponse(response.data)
    );
  }

  /**
   * Remove organization -- backend
   * @param {string} id
   * @returns {Promise}
   * @memberof OrganizationService
   */
  deleteOrganization(id) {
    return this.backend('delete', `organizations/${id}`);
  }
}

export default new OrganizationService();
