import config from '@common/config';
import Pusher from 'pusher-js';

class PusherService {
  constructor() {
    this.socket = new Pusher(config.PusherServerAppKey, {
      cluster: config.PusherServerCluster,
      forceTLS: true
    });
    this.channel = null;
    this.channels = {};

    this.socket.bind_global((event, data) => {
      this._debug(`The event ${event} was triggered with data`, data);
    });
  }

  subscribe(channel, connectedCallback) {
    this.channels[channel] = this.socket.subscribe(channel);

    this._debug('Subscribed to', channel);
    if (connectedCallback) {
      connectedCallback();
    }
  }

  unsubscribe(channel, disconnectedCallback) {
    this.channels[channel].unsubscribe();
    this._debug('Unsubscribed from', channel);
    if (disconnectedCallback) {
      disconnectedCallback();
    }
  }

  bindEvent(channel, event, callback) {
    let events = [];
    if (typeof event === 'string') {
      events.push(event);
    } else if (Array.isArray(event)) {
      events = event;
    }
    events.forEach(name => {
      this.channels[channel].bind(name, callback);
      this._debug('New event bound', name);
    });
  }

  _debug() {
    if (config.PusherDebug === true) {
      console.log('[PUSHER/DEBUG]:', ...arguments);
    }
  }
}

export default new PusherService();
