<template>
  <div class="full-height" data-cy="start">
    <main-header v-show="showHeader"></main-header>
    <alerts-handler-vue></alerts-handler-vue>
    <div class="content" :class="{ 'menu-hidden': !$showMenu }">
      <toast></toast>
      <div class="router">
        <router-view v-slot="{ Component, route }" @toaster="showToaster">
          <transition name="fade-in-right" mode="out-in">
            <component :is="Component" :key="route.path" />
          </transition>
        </router-view>
      </div>
    </div>
    <transition name="slideup" mode="out-in">
      <main-menu v-show="$showMenu"></main-menu>
    </transition>

    <!-- ACCOUNT INACTIVE -->
    <modal
      :show="showAccountInactiveModal"
      :sticky="true"
      @close="showAccountInactiveModal = false"
      :accept-callback="
        () => {
          this.activateAccount();
        }
      "
      :accept-text="$t('modals.accountInactive.acceptButton')"
      :close-on-accept="false"
      :decline-text="$t('modals.accountInactive.declineButton')"
      decline-classes="btn-flat"
      :decline-callback="
        () => {
          this.showAccountInactiveModal = false;
          this.showCreatePatientModal = true;
        }
      "
      height="auto"
    >
      <h5 class="center-text sub-header">
        {{ $t('modals.accountInactive.accountDeactivated') }}
      </h5>
      <p>{{ $t('modals.accountInactive.enterReactivationCode') }}</p>
      <p v-if="$token.deactivated_date">
        {{ $t('modals.accountInactive.deactivatedAt') }}
        <u>{{ $filters.DateTimeFormat($token.deactivated_date) }}</u>
      </p>
      <s-text-input
        :label="$t('generic.activationCode')"
        v-model="activateAccountDetails.Salt"
        autocomplete="activation-code"
        name="activation-code"
      ></s-text-input>
      <p>{{ $t('modals.accountInactive.useSamePassword') }}</p>
      <s-text-input
        :label="$t('generic.password')"
        v-model="activateAccountDetails.Password"
        autocomplete="current-password"
        :type="showPassword ? 'text' : 'password'"
        :icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :icon-callback="
          () => {
            showPassword = !showPassword;
          }
        "
        :icon-title="
          showPassword
            ? $t('modals.accountInactive.hidePassword')
            : $t('modals.accountInactive.showPassword')
        "
      ></s-text-input>
    </modal>

    <!-- CREATE NEW PATIENT -->
    <modal
      :show="showCreatePatientModal"
      :sticky="true"
      @close="showCreatePatientModal = false"
      :accept-callback="
        () => {
          this.createNewPatient();
        }
      "
      :decline-callback="
        () => {
          this.showCreatePatientModal = false;
          this.showAccountInactiveModal = true;
        }
      "
      :accept-text="$t('modals.newPatient.acceptText')"
      accept-classes="red"
      :decline-text="$t('modals.newPatient.declineText')"
      height="auto"
    >
      <h5 class="center-text sub-header">
        {{ $t('modals.newPatient.header') }}
      </h5>

      <p>{{ $t('modals.newPatient.confirmation') }}</p>
      <p class="bold">{{ $t('modals.newPatient.dataLossWarning') }}</p>
    </modal>

    <!-- News -->
    <modal
      v-if="currentNewsItem !== null"
      :show="currentNewsItem !== null"
      @close="popNewsItem()"
      :buttons="true"
      :hide-left-button="true"
      :accept-text="newsAcceptText"
    >
      <div>
        <h3 class="sub-header mb0">
          <i class="mdi mdi-information-outline"></i>
          {{ currentNewsItem.Title }}
        </h3>
        <span v-html="toHtml(currentNewsItem.Content)"></span>
      </div>
      <template v-slot:extras>
        <div class="small-font">
          {{ $t('modals.news.publishedAt') }}
          {{ $filters.DateFormat(currentNewsItem.PublishDate) }}
        </div>
      </template>
    </modal>

    <!-- Connection lost -->
    <modal-no-connection-vue
      :lost-connection="!$connected"
    ></modal-no-connection-vue>

    <!-- Thank you modal after first symptomreport -->
    <modal-thank-you-vue
      :show="$showThankYouModal"
      @closed="$setShowThankYouModal(false)"
    ></modal-thank-you-vue>
  </div>
</template>

<script>
import { toHtml } from '@common/Helpers/markdown';
import MainMenu from './components/MainMenu.vue';
import MainHeader from './components/MainHeader.vue';
import Toast from './components/Toast';
import Loader from '@common/Helpers/Loader';
import { NEWS_ACTIONS, NEWS_GETTERS } from '@/store/modules/news';
import { SYMPTOMREPORT_GETTERS } from '@/store/modules/symptomreports';
import { AUTH_GETTERS } from '@/store/modules/auth';
import { APP_GETTERS } from '@/store/modules/app';
import { CURRENT_USER_ACTIONS } from '@/store/modules/currentuser';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { isMobile } from '@common/Helpers/mobile';
import ModalNoConnectionVue from './modals/ModalNoConnection.vue';
import ModalThankYouVue from './modals/ModalThankYou.vue';
import AlertsHandlerVue from './components/Alerts/AlertHandler.vue';
import { SYMPTOMREPORT_MUTATIONS } from '@/store/modules/symptomreports';

export default {
  name: 'StartView',
  components: {
    MainMenu,
    MainHeader,
    Toast,
    ModalNoConnectionVue,
    ModalThankYouVue,
    AlertsHandlerVue
  },
  data() {
    return {
      popup: false,
      user: null,
      bottomPopup: false,
      showHeader: true,
      showAccountInactiveModal: false,
      showCreatePatientModal: false,
      showLostConnection: false,
      showPassword: false,
      Loader,
      activateAccountDetails: {
        Password: null,
        Salt: null
      },
      unreadNewsItems: [],
      unreadNewsCount: 0,
      currentNewsCounter: 0,
      currentNewsItem: null
    };
  },
  watch: {
    $outstandingContracts: {
      handler: function (hasOutstandingContracts) {
        if (hasOutstandingContracts) {
          // If we have a redirect path defined already we probably come from login and
          // are going to either the initial Prom for the Group or the welcome view and
          // want to preserve the path.
          // If we don't have a redirect path defined then we might have received a 412
          // response on a backend call and use the path before we got sent to ToS view
          // as redirect path.
          const redirectPath = this.$route.query?.redirect
            ? this.$route.query?.redirect
            : this.$route.path;

          this.$router
            .push({
              name: 'tos',
              query: { redirect: redirectPath }
            })
            .catch(() => {});
        }
      },
      immediate: true
    },
    $connected(value) {
      this.showLostConnection = !value;
    },
    $accountActive: {
      handler: function (value) {
        if (this.$token && Object.keys(this.$token).length === 0) {
          return;
        }
        this.showAccountInactiveModal = !value;
      },
      immediate: true
    }
  },
  mounted() {
    this.$bus.$on('toggleHeader', this.toggleHeader);
    this.showLostConnection = !this.$connected;

    this.getNewsItems();
  },
  methods: {
    toHtml,
    isMobile,
    ...mapActions('currentuser', {
      $createNewPatient: CURRENT_USER_ACTIONS.CREATE_NEW_PATIENT,
      $activateAccount: CURRENT_USER_ACTIONS.ACTIVATE_ACCOUNT
    }),
    ...mapActions('news', {
      $getPublishedNewsItems: NEWS_ACTIONS.GET_PUBLISHED_NEWS_ITEMS
    }),
    ...mapMutations('symptomreports', {
      $setShowThankYouModal: SYMPTOMREPORT_MUTATIONS.SET_SHOW_THANK_YOU_MODAL
    }),
    activateAccount() {
      this.$activateAccount(this.activateAccountDetails);
    },
    createNewPatient() {
      this.$createNewPatient();
    },
    toggleHeader(state) {
      this.showHeader = state || !this.showHeader;
    },
    showToaster(d) {
      console.log(d);
    },
    getNewsItems() {
      this.$getPublishedNewsItems().then(() => {
        this.unreadNewsItems = this.$unreadVeryImportantNewsItems;
        this.unreadNewsCount = this.unreadNewsItems.length;
        this.popNewsItem();
      });
    },
    popNewsItem() {
      if (this.unreadNewsItems.length === 0) {
        this.currentNewsItem = null;
      } else {
        this.currentNewsItem = this.unreadNewsItems.pop();
        this.currentNewsCounter++;
      }
    }
  },
  computed: {
    ...mapGetters('auth', {
      $outstandingContracts: AUTH_GETTERS.OUTSTANDING_CONTRACTS,
      $accountActive: AUTH_GETTERS.ACCOUNT_ACTIVE,
      $token: AUTH_GETTERS.TOKEN
    }),
    ...mapGetters('app', {
      $connected: APP_GETTERS.CONNECTED,
      $showMenu: APP_GETTERS.SHOW_MENU
    }),
    ...mapGetters('news', {
      $unreadVeryImportantNewsItems: NEWS_GETTERS.UNREAD_VERY_IMPORTANT_NEWS
    }),
    ...mapGetters('symptomreports', {
      $showThankYouModal: SYMPTOMREPORT_GETTERS.SHOW_THANK_YOU_MODAL
    }),
    newsAcceptText() {
      if (this.unreadNewsCount === 1) {
        return this.$t('generic.close');
      }

      let label =
        this.currentNewsCounter < this.unreadNewsCount
          ? this.$t('generic.next')
          : this.$t('generic.close');
      label += ` (${this.currentNewsCounter}
                  ${this.$t('generic.of')}
                  ${this.unreadNewsCount})`;
      return label;
    }
  }
};
</script>
<style lang="scss">
.router {
  transition: opacity 0.1s ease-in-out;
  &.loading {
    opacity: 0.05;
  }
}
.mdi {
  display: inline !important;
}
</style>
