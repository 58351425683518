<template>
  <div class="input-field" style="margin: 0">
    <select @change="setAnswer" :disabled="disabled">
      <option value="">Välj ett alternativ</option>
      <option
        v-for="answ in question.Answers"
        :key="answ.Id"
        :value="answ.Id"
        :selected="answ.Id === chosenAnswer"
      >
        {{ answ.Text }}
      </option>
    </select>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import AnswerModel from '@common/Models/AnswerModel';
import { toHtml } from '@common/Helpers/markdown';
import FormSetup from '@common/Mixins/FormSetup';
export default {
  props: [
    'answer',
    'answerInput',
    'question',
    'disabled',
    'isAnswer',
    'isNotSavedAnswer',
    'type',
    'index'
  ],
  data() {
    return {
      chosenAnswer: this.answerInput ? this.answerInput.AnswerIds[0] : null
    };
  },
  watch: {
    question() {
      this.$_FormSetup_setupForm();
    },
    disabled() {
      this.$_FormSetup_setupForm();
    },
    answerInput(ans) {
      this.chosenAnswer = ans ? ans.AnswerIds[0] : null;
    }
  },
  mounted() {},
  mixins: [FormSetup],
  methods: {
    toHtml,
    setAnswer(evt) {
      const value = this.question.Answers.find(x => x.Id === evt.target.value);

      if (value) {
        const editedAnswer = new AnswerModel(this.answer.__copy());
        editedAnswer.Value = value;

        this.$emit('setAnswer', editedAnswer);
      } else {
        this.$emit('removeAnswer', this.answer);
      }
      this.chosenAnswer = value || null;
    }
  },
  computed: {
    answertext() {
      return this.answerInput ? this.answer.Value : '';
    }
  }
};
</script>

<style lang="css"></style>
