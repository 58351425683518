// @ts-check
import ParticipantIdentifierModel from '@common/Models/ParticipantIdentifierModel';

export class ParticipantModel {
  constructor({ Age, Gender, Identifiers }) {
    this._modelName = 'Participant';
    this.Age = Age;
    this.Gender = Gender;
    this.Identifiers = Identifiers.map(i => new ParticipantIdentifierModel(i));
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }
}

export default ParticipantModel;
