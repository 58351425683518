import { Bus } from '@common';
import axios from 'axios';

export const TokenRefreshInterceptor = error => {
  if (error && error.response && error.response.status === 401) {
    console.error('%cTOKEN HAS EXPIRED', 'color: RED; font-size: x-large');

    let res = null;
    const getToken = new Promise((resolve, reject) => {
      res = resolve;
    });

    Bus.$emit('refresh-token', res);

    return getToken.then(token => {
      error.config.headers.Authorization = `${token.access.type} ${token.access.token}`;
      return axios(error.config);
    });
  }
  return Promise.reject(error);
};
