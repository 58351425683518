<template>
  <card-container>
    <card color="white">
      <template v-slot:content>
        <div class="calendar">
          <div class="calendar__month-navigation">
            <span class="calendar__arrow previous" @click="previousMonth()">
              <i class="mdi mdi-chevron-left"></i>
            </span>
            <span class="calendar__month"
              >{{ currentMonth }} {{ currentYear }}</span
            >
            <span class="calendar__arrow next" @click="nextMonth()">
              <i class="mdi mdi-chevron-right"></i>
            </span>
          </div>

          <div class="calendar__headers">
            <div
              v-for="(weekday, index) in weekdays"
              :key="index"
              class="calendar__header"
            >
              {{ weekday.substr(0, 3) }}
            </div>
          </div>
          <div class="calendar__dates">
            <div
              v-for="(day, index) in displayedDates"
              :key="index"
              class="calendar__date-container"
            >
              <div
                :class="{ selected: isCurrentDate(day) }"
                @click="setCurrentDay(day)"
                class="calendar__date-bubble"
              >
                <span v-if="day">{{ $filters.DayOfMonthFormat(day) }}</span>
              </div>
              <div v-if="day">
                <span
                  v-for="report in uniqueReportTypesForDay(day)"
                  :key="report.Id"
                >
                  <i class="mdi" :class="report._icon"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </card>
  </card-container>
</template>

<script>
import moment from 'moment';
import { uniqBy } from 'lodash';
import { sortByKey } from '@common/Helpers/sorters';

export default {
  props: {
    reports: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      currentDate: moment(),
      weekdays: []
    };
  },
  mounted() {
    this.initWeekdays();
  },
  methods: {
    uniqueReportTypesForDay(momentDate) {
      const index = momentDate.format('YYYY-MM-DD');
      return uniqBy(this.reportsByDate[index], '_modelName');
    },
    initWeekdays() {
      const weekdays = moment.weekdays();
      // Make sunday last day of week, weekdays() does not have support for isoWeeks...
      const sunday = weekdays.shift();
      weekdays.push(sunday);
      this.weekdays = weekdays;
    },
    isCurrentDate(someDate) {
      if (!someDate) {
        return false;
      }

      return this.currentDate.isSame(someDate, 'day');
    },
    setCurrentDay(day) {
      if (!day) {
        return;
      }
      this.currentDate = day;
      this.$emit('datechanged', this.currentDate);
    },
    previousMonth() {
      const newDate = moment(this.currentDate).subtract(1, 'month');
      this.setCurrentDay(newDate);
    },
    nextMonth() {
      const newDate = moment(this.currentDate).add(1, 'month');
      this.setCurrentDay(newDate);
    }
  },
  computed: {
    displayedDates() {
      const weekdays = this.daysInMonth.map(d => d.isoWeekday());
      const offset = 7 - weekdays.indexOf(1);
      const displayedDates = [...this.daysInMonth];
      for (let i = 0; i < offset; i++) {
        // Fill up with dummy dates for days of first week in case Monday isn't the 1st day of month
        displayedDates.unshift(null);
      }

      return displayedDates;
    },
    daysInMonth() {
      const dates = [];

      for (
        let m = moment(this.startDate);
        m.isBefore(this.endDate);
        m.add(1, 'days')
      ) {
        dates.push(m.clone());
      }

      return dates;
    },
    startDate() {
      return moment(this.currentDate).startOf('month');
    },
    endDate() {
      return moment(this.currentDate).endOf('month');
    },
    currentMonth() {
      return this.currentDate.format('MMMM');
    },
    currentYear() {
      return this.currentDate.format('YYYY');
    },
    reportsByDate() {
      const reportsByDate = {};

      for (let i = 0; i < this.reports.length; i++) {
        if (
          reportsByDate[this.reports[i].CreatedDate.format('YYYY-MM-DD')] ===
          undefined
        ) {
          reportsByDate[this.reports[i].CreatedDate.format('YYYY-MM-DD')] = [];
        }
        reportsByDate[this.reports[i].CreatedDate.format('YYYY-MM-DD')].push(
          this.reports[i]
        );
      }
      return reportsByDate;
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@sass/_variables.scss';
.calendar {
  padding: 20px 10px;
  text-align: center;

  &__month-navigation {
    margin-bottom: 10px;
  }

  &__arrow {
    cursor: pointer !important;
    height: 0;
    width: 0;
    position: absolute;
    top: 10px;

    &.previous {
      left: 20px;
    }
    &.next {
      right: 50px;
    }
  }

  &__month {
    text-transform: capitalize;
  }

  &__dates,
  &__headers {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    font-size: 0.7em;
  }

  &__date-container,
  &__header {
    width: 14%;
    padding: 0.6em;
  }

  &__date-container {
    .mdi {
      font-size: 1em;
      color: $symptoms-blue;
    }
  }

  &__date-bubble {
    height: 25px;
    line-height: 25px;
    cursor: pointer;

    &.selected {
      background-color: $symptoms-blue;
      color: #fff;
      border: 1px solid none;
      border-radius: 50%;
    }
  }

  &__header {
    font-weight: normal;
    text-transform: uppercase;
  }
}
</style>
