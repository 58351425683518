import UserModel from '@common/Models/UserModel';

/**
 * Map local User to remote
 * @param {UserModel} User
 * @returns {object}
 */
export const NewUserRequest = User => {
  const dtoUser = Object.assign({}, User);
  if (dtoUser.Personnummer) {
    dtoUser.Personnummer = dtoUser.Personnummer.replace('-', '');
  }
  //! ! Remove this once proper group roles has been implemented
  dtoUser.Groups.forEach(g => {
    g.Roles = dtoUser.Roles;
  });
  delete dtoUser.Id;
  return dtoUser;
};
