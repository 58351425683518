export const NEWS_MUTATIONS = {
  ADD_NEWS_ITEM: 'ADD_NEWS_ITEM',
  SET_NEWS_ITEMS: 'SET_NEWS_ITEMS',
  REMOVE_NEWS_ITEM: 'REMOVE_NEWS_ITEM'
};

export const NEWS_ACTIONS = {
  GET_NEWS_ITEMS: 'GET_NEWS_ITEMS',
  GET_PUBLISHED_NEWS_ITEMS: 'GET_PUBLISHED_NEWS_ITEMS',
  CREATE_NEWS_ITEM: 'CREATE_NEWS_ITEM',
  UPDATE_NEWS_ITEM: 'UPDATE_NEWS_ITEM',
  DELETE_NEWS_ITEM: 'DELETE_NEWS_ITEM'
};

export const NEWS_GETTERS = {
  NEWS_ITEMS: 'NEWS_ITEMS',
  NEWS_ITEMS_AFTER: 'NEWS_ITEMS_AFTER',
  FIND_NEWS_ITEM: 'FIND_NEWS_ITEM',
  UNREAD_VERY_IMPORTANT_NEWS: 'UNREAD_VERY_IMPORTANT_NEWS'
};
