import moment from 'moment';
export default {
  mounted() {
    this.$_FormSetup_setupForm();
  },
  methods: {
    $_FormSetup_setupForm(timeout = 0) {
      setTimeout(() => {
        // Text field setup
        M.updateTextFields();
        document
          .querySelectorAll('textarea')
          .forEach(e => M.textareaAutoResize(e));

        // Select setup
        M.FormSelect.init(document.querySelectorAll('select'), {});

        // Datepicker setup
        const momentLocale = moment.localeData();
        const datePickerCloseCallback = this.$_FormSetup_datePickerCallback;
        const format = momentLocale._longDateFormat
          ? momentLocale._longDateFormat.L.toLowerCase()
          : 'yyyy-mm-dd';

        M.Datepicker.init(document.querySelectorAll('.datepicker'), {
          firstDay: 1,
          autoClose: true,
          format: format,
          onSelect: datePickerCloseCallback || null,
          i18n: {
            months: momentLocale._months,
            monthsShort: momentLocale._monthsShort,
            weekdays: momentLocale._weekdays,
            weekdaysShort: momentLocale._weekdaysShort,
            weekdaysAbbrev: momentLocale._weekdaysMin,
            cancel: this.$root.$t('generic.cancel')
          }
        });

        // Timepicker setup
        M.Timepicker.init(document.querySelectorAll('.timepicker'), {
          twelveHour: false
        });
      }, timeout);
    }
  }
};
