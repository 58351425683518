import { PUSHER_MESSAGES } from '@common/constants';
import PusherService from '@common/Services/PusherService';
import { ALERT_ACTIONS } from '../modules/alerts';
import { AUTH_MUTATIONS } from '../modules/auth';
import { SHARE_ACTIONS } from '../modules/shares';

export const pusherPlugin = store => {
  store.subscribe((mutation, state) => {
    const [namespace, type] = mutation.type.split('/');
    const patientId = state.auth.tokenDetails.pid;
    if (type === AUTH_MUTATIONS.PUSHER_CONNECTED) {
      bindEvents(patientId, store, state);
    }
  });
};

function bindEvents(patientId, store, state) {
  /* Share created */
  PusherService.bindEvent(
    patientId,
    PUSHER_MESSAGES.ShareCreated,
    function (data) {
      store.dispatch(`shares/${SHARE_ACTIONS.GET_SHARE}`, data.ShareId, {
        root: true
      });

      if (shouldDisplayAlert('shares', state)) {
        displayAlert(ALERT_ACTIONS.DISPLAY_NEW_SHAREREQUEST, data, store);
      }
    }
  );
  /* Prom request created */
  PusherService.bindEvent(
    patientId,
    PUSHER_MESSAGES.PromRequestCreated,
    function (data) {
      const requests = data.PromRequestIds || [];
      requests.forEach(request => {
        store.dispatch(
          `currentuser/${CURRENT_USER_ACTIONS.GET_PROMREQUEST}`,
          request,
          {
            root: true
          }
        );
      });
    }
  );
}

function shouldDisplayAlert(dontDisplayOn, state) {
  return state.route.name !== dontDisplayOn;
}

function displayAlert(name, data, store) {
  store.dispatch(`alerts/${name}`, data, {
    root: true
  });
}
