<template>
  <div class="edit-lock" v-bind="$attrs">
    <i
      @click="toggleLock"
      class="mdi pointer"
      :class="isLocked ? 'mdi-lock red-text' : 'mdi-lock-open green-text'"
    ></i>

    <modal-confirm
      :show="confirmUnlock"
      :accept-callback="
        () => {
          isLocked = false;
          confirmUnlock = false;
        }
      "
      :accept-text="$t('generic.yes')"
      :show-extras="false"
      :decline-text="$t('generic.no')"
      @close="confirmUnlock = false"
      >{{ text ? text : $t('modals.confirmUnlockReport') }}</modal-confirm
    >
  </div>
</template>

<script>
export default {
  props: {
    locked: {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      default: null
    },
    confirm: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      confirmUnlock: false,
      isLocked: this.locked
    };
  },
  watch: {
    locked(value) {
      this.isLocked = value;
    },
    isLocked(value) {
      this.$emit('change', value);
    }
  },
  methods: {
    toggleLock() {
      if (this.confirm && this.isLocked) {
        this.confirmUnlock = true;
      } else {
        this.isLocked = !this.isLocked;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-lock {
  display: inline-block;
  width: 30px;
  z-index: 101;
  position: absolute;
  right: 30px;
  top: 25px;
}
</style>
