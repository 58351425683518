import { sortByValueKey } from '@common/Helpers/sorters';
import GroupService from '@common/Services/Groups/GroupService';
import { GROUP_ACTIONS, GROUP_GETTERS, GROUP_MUTATIONS } from './definitions';
import GroupModel from '@common/Models/GroupModel';

class State {
  constructor() {
    /** @type {GroupModel[]} */
    this.list = [];
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  [GROUP_MUTATIONS.ADD_GROUP](state, group) {
    const exists = state.list.find(i => i.Id === group.Id);
    if (!exists) {
      state.list.push(group);
    } else {
      Object.assign(exists, group);
    }
  },
  [GROUP_MUTATIONS.REMOVE_GROUP](state, group) {
    const index = state.list.findIndex(x => x.Id === group.Id);
    if (index > -1) {
      state.list.splice(index, 1);
    }
  },
  [GROUP_MUTATIONS.SET_GROUPS](state, groups) {
    state.list = groups;
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [GROUP_ACTIONS.ADD_MY_ORGANIZATION_GROUP](context, group) {
    return GroupService.addMyOrganizationGroup(group).then(group => {
      context.commit(GROUP_MUTATIONS.ADD_GROUP, group);
      return group;
    });
  },
  [GROUP_ACTIONS.DELETE_MY_ORGANIZATION_GROUP](context, group) {
    return GroupService.deleteMyOrganizationGroup(group.Id).then(() =>
      context.commit(GROUP_MUTATIONS.REMOVE_GROUP, group)
    );
  },
  [GROUP_ACTIONS.GET_MY_ORGANIZATION_GROUP](context, groupId) {
    return GroupService.getMyOrganizationGroup(groupId).then(group => {
      context.commit(GROUP_MUTATIONS.ADD_GROUP, group);
      return group;
    });
  },
  [GROUP_ACTIONS.GET_MY_ORGANIZATION_GROUPS](context) {
    return GroupService.getMyOrganizationGroups().then(groups => {
      context.commit(GROUP_MUTATIONS.SET_GROUPS, groups);
      return groups;
    });
  },
  [GROUP_ACTIONS.UPDATE_MY_ORGANIZATION_GROUP](context, group) {
    return GroupService.updateMyOrganizationGroup(group.Id, group).then(
      group => {
        context.commit(GROUP_MUTATIONS.ADD_GROUP, group);
        return group;
      }
    );
  },
  [GROUP_ACTIONS.EXPORT](context, group) {
    const exportedGroup = new GroupModel(group);
    var moment = require('moment');
    const dateTime = moment().format('YYYYMMDD-HHmmss');

    const blob = new Blob([JSON.stringify(exportedGroup)], {
      type: 'application/json; charset=utf-8'
    });
    saveAs(
      blob,
      `${exportedGroup.OrganizationId}_${exportedGroup.Id}_${dateTime}.json`
    );
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [GROUP_GETTERS.GROUPS](state) {
    return sortByValueKey(state.list, 'Name');
  },
  [GROUP_GETTERS.FIND_GROUP]: state => groupId => {
    return state.list.find(a => a.Id === groupId);
  }
};

export { GROUP_ACTIONS, GROUP_GETTERS, GROUP_MUTATIONS } from './definitions';

export default store;
