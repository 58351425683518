<template>
  <i
    v-if="+object.ModifiedDate !== +object.CreatedDate"
    class="mdi mdi-pen orange-text"
    :title="`${$t('generic.modified')}: ${$filters.DateTimeFormat(
      object.ModifiedDate
    )}`"
  ></i>
</template>

<script>
export default {
  props: {
    object: {
      type: Object,
      required: true
    }
  }
};
</script>

<style></style>
