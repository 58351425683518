// @ts-check
import { isFromRemote, isRemoteId } from '@common/Helpers/api';
import { utcToLocal } from '@common/Helpers/time';
import {
  FEMALE_Z_LIMIT,
  MALE_Z_LIMIT
} from '@common/Models/SymptomReport/SymptomReportSymptomModel';
import moment from 'moment';
import SymptomReportSymptomModel from '@common/Models/SymptomReport/SymptomReportSymptomModel';

export class SymptomReportModel {
  constructor({
    Id,
    CreatedDate,
    ModifiedDate,
    Model,
    Symptoms = [],
    Version,
    _locked = false // So we can remember modified state between views...
  }) {
    this._modelName = 'SymptomReport';
    this._icon = 'mdi-human';
    this.Id = Id || null;
    this.CreatedDate = CreatedDate ? utcToLocal(CreatedDate) : moment();
    this.ModifiedDate = ModifiedDate ? utcToLocal(ModifiedDate) : moment();
    this.Model = Model || null;
    this.Symptoms = Symptoms.map(s => new SymptomReportSymptomModel(s));
    this.Version = Version || 0;

    this._saved = !!isRemoteId(Id);
    this._tempSymptom = null;
    this._lastAction = null;
    this._locked = _locked;
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }

  get hasModifiedSymptoms() {
    return this.Symptoms.some(s => s._modified);
  }

  backupSymptom(symptom) {
    this._tempSymptom = symptom;
  }

  findSymptom(symptom) {
    return this.Symptoms.find(s => s.SymptomId === symptom.SymptomId);
  }

  restoreSymptom() {
    const symptomIndex = this.Symptoms.findIndex(
      s => s.SymptomId === this._tempSymptom.SymptomId
    );

    if (symptomIndex === -1) {
      return;
    }
    this.Symptoms.splice(symptomIndex, 1, this._tempSymptom);
  }

  addSymptom(symptom) {
    const index = this.Symptoms.findIndex(
      s => s.SymptomId === symptom.SymptomId
    );
    if (index > -1) {
      return;
    }
    this.Symptoms.push(symptom);
  }

  removeSymptom(symptom) {
    const index = this.Symptoms.findIndex(
      s => s.SymptomId === symptom.SymptomId
    );
    this.Symptoms.splice(index, 1);
  }

  get savedSymptoms() {
    return this.Symptoms.filter(
      symptom => symptom._saved === true && !symptom.isFromRemote
    );
  }

  get editable() {
    return !this._locked;
  }

  // TODO: Not used?
  get frontSymptoms() {
    return this.Symptoms.filter(s => {
      if (this.Model === 'male' && s.PosZ < MALE_Z_LIMIT) {
        return true;
      } else if (this.Model === 'female' && s.PosZ < FEMALE_Z_LIMIT) {
        return true;
      }
    });
  }

  // TODO: Not used?
  get backSymptoms() {
    return this.Symptoms.filter(s => {
      if (this.Model === 'male' && s.PosZ > MALE_Z_LIMIT) {
        return true;
      } else if (this.Model === 'female' && s.PosZ > FEMALE_Z_LIMIT) {
        return true;
      }
    });
  }

  get isFromRemote() {
    return isFromRemote(this);
  }
}

export default SymptomReportModel;
