import { AUTH_GETTERS } from '@/store/modules/auth';
import { DateTimeStringForFile } from '@common/Filters/Date';
import { sortByValueKey } from '@common/Helpers/sorters';
import ContractModel from '@common/Models/ContractModel';
import ContractService from '@common/Services/Contracts/ContractService';
import moment from 'moment';
import {
  CONTRACT_ACTIONS,
  CONTRACT_GETTERS,
  CONTRACT_MUTATIONS
} from './definitions';

class State {
  constructor() {
    /** @type {ContractModel[]} */
    this.list = [];
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  /** @param {ContractModel} contract */
  [CONTRACT_MUTATIONS.ADD_CONTRACT](state, contract) {
    const exists = state.list.find(i => i.Id === contract.Id);
    if (!exists) {
      state.list.push(contract);
    } else {
      Object.assign(exists, contract);
    }
  },
  /** @param {ContractModel} contract */
  [CONTRACT_MUTATIONS.REMOVE_CONTRACT](state, contract) {
    const index = state.list.findIndex(x => x.Id === contract.Id);
    if (index > -1) {
      state.list.splice(index, 1);
    }
  },
  /** @param {ContractModel[]} contracts */
  [CONTRACT_MUTATIONS.SET_CONTRACTS](state, contracts) {
    state.list = contracts;
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [CONTRACT_ACTIONS.GET_CONTRACT](context, contractId) {
    ContractService.getContract(contractId).then(contract => {
      context.commit(CONTRACT_MUTATIONS.ADD_CONTRACT, contract);
    });
  },
  [CONTRACT_ACTIONS.GET_ALL_CONTRACTS](context) {
    return ContractService.getMyGroupContracts().then(contracts => {
      context.commit(CONTRACT_MUTATIONS.SET_CONTRACTS, contracts);
    });
  },
  [CONTRACT_ACTIONS.GET_CONTRACTS](context) {
    ContractService.getMyGroupContractsByLanguage().then(contracts =>
      context.commit(CONTRACT_MUTATIONS.SET_CONTRACTS, contracts)
    );
  },
  [CONTRACT_ACTIONS.CREATE_CONTRACT](context, contract) {
    return ContractService.createMyGroupContract(contract).then(contract =>
      context.commit(CONTRACT_MUTATIONS.ADD_CONTRACT, contract)
    );
  },
  [CONTRACT_ACTIONS.UPDATE_CONTRACT](context, contract) {
    return ContractService.updateContract(contract.Id, contract).then(
      contract => {
        context.commit(CONTRACT_MUTATIONS.ADD_CONTRACT, contract);
        return contract;
      }
    );
  },
  [CONTRACT_ACTIONS.DELETE_CONTRACT](context, contract) {
    ContractService.deleteContract(contract).then(() =>
      context.commit(CONTRACT_MUTATIONS.REMOVE_CONTRACT, contract)
    );
  },
  [CONTRACT_ACTIONS.EXPORT](context, contract) {
    const currentDateTime = moment().format(DateTimeStringForFile);
    const filename = `${contract.Title}_${contract.Version}_${currentDateTime}`;
    const blob = new Blob([JSON.stringify(contract, null, 2)], {
      type: 'application/json; charset=utf-8'
    });
    saveAs(blob, `${filename}.json`);
  },
  [CONTRACT_ACTIONS.IMPORT](context, contractBlob) {
    const contract = new ContractModel(contractBlob);

    return ContractService.createMyGroupContract(contract).then(contract => {
      context.commit(CONTRACT_MUTATIONS.ADD_CONTRACT, contract);
    });
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [CONTRACT_GETTERS.CONTRACTS](state) {
    return sortByValueKey(state.list, 'Description');
  },
  [CONTRACT_GETTERS.FIND_CONTRACT]: state => contractId => {
    return state.list.find(a => a.Id === contractId);
  }
};

export {
  CONTRACT_ACTIONS,
  CONTRACT_GETTERS,
  CONTRACT_MUTATIONS
} from './definitions';

export default store;
