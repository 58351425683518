<template>
  <svg
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.0"
    width="32px"
    height="32px"
    viewBox="0 0 128 128"
    xml:space="preserve"
  >
    <g>
      <path d="M59.6 0h8v40h-8V0z" :fill="color" fill-opacity="1" />
      <path
        d="M59.6 0h8v40h-8V0z"
        :fill="color"
        fill-opacity="0.2"
        transform="rotate(30 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        :fill="color"
        fill-opacity="0.2"
        transform="rotate(60 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        :fill="color"
        fill-opacity="0.2"
        transform="rotate(90 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        :fill="color"
        fill-opacity="0.2"
        transform="rotate(120 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        :fill="color"
        fill-opacity="0.3"
        transform="rotate(150 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        :fill="color"
        fill-opacity="0.4"
        transform="rotate(180 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        :fill="color"
        fill-opacity="0.5"
        transform="rotate(210 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        :fill="color"
        fill-opacity="0.6"
        transform="rotate(240 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        :fill="color"
        fill-opacity="0.7"
        transform="rotate(270 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        :fill="color"
        fill-opacity="0.8"
        transform="rotate(300 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        :fill="color"
        fill-opacity="0.9"
        transform="rotate(330 64 64)"
      />
      <animateTransform
        attributeName="transform"
        type="rotate"
        values="0 64 64;30 64 64;60 64 64;90 64 64;120 64 64;150 64 64;180 64 64;210 64 64;240 64 64;270 64 64;300 64 64;330 64 64"
        calcMode="discrete"
        dur="960ms"
        repeatCount="indefinite"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#faf5fa'
    }
  }
};
</script>

<style></style>
