import PromModel from '@common/Models/PromModel';
import BaseService from '../BaseService';
import {
  PromResponse,
  PromsResponse
} from '../PromMetas/mappers/PromResponses';
import { PromLanguageVersionRequest } from './mappers/PromLanguageVersionRequests';

class PromLanguageVersionService extends BaseService {
  /**
   * Get Proms for specified language -- backend
   * @param {string} [language='sv'] Language Id
   * @returns {Promise<PromModel[]>}
   * @memberof PromService
   */
  getProms(language = 'sv') {
    return this.backend(`proms/versions/${language}`).then(response =>
      PromsResponse(response.data)
    );
  }

  /**
   * Fetch LanguageVersion for specified prom -- backend
   * @param {string} promid Prom id
   * @param {string} language Language
   * @returns {Promise<PromModel>}
   * @memberof PromLanguageVersionService
   */
  findLanguageVersion(promid, language = 'sv') {
    return this.backend(`proms/${promid}/versions/${language}`).then(response =>
      PromResponse(response.data)
    );
  }

  /**
   * Get Prom related to PromRequest -- backend
   * @param {string} RequestId
   * @returns {Promise<PromModel>}
   * @memberof PromRequestService
   */
  getPromByPromRequest(RequestId) {
    return this.backend(`promrequests/${RequestId}/prom`).then(response =>
      PromResponse(response.data)
    );
  }

  /**
   * Save a new prom language version -- backend
   * @param {PromModel} prom New prom
   * @returns {Promise<PromModel>}
   * @memberof PromLanguageVersionService
   */
  addPromLanguageVersion(prom) {
    return this.backend(
      'post',
      `proms/${prom.Id}/versions`,
      PromLanguageVersionRequest(prom)
    ).then(response => PromResponse(response.data));
  }

  /**
   * Update existing prom -- backend
   * @param {PromModel} prom Prom to update
   * @returns {Promise<PromModel>}
   * @memberof PromLanguageVersionService
   */
  updatePromLanguageVersion(prom) {
    return this.backend(
      'put',
      `proms/${prom.Id}/versions/${prom.Language}`,
      PromLanguageVersionRequest(prom)
    ).then(response => PromResponse(response.data));
  }

  /**
   * Delete prom -- backend
   * @param {String} promId
   * @param {String} language
   * @returns {Promise}
   * @memberof PromLanguageVersionService
   */
  deletePromTranslation(promId, language) {
    return this.backend('delete', `proms/${promId}/${language}`);
  }
}

export default new PromLanguageVersionService();
