<template>
  <div v-if="graphData">
    <div class="center-text" id="radar-chart" data-cy="radarchart"></div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import moment from 'moment';
import { APP_VERSION_OPTIONS } from '@common/constants';

export default {
  name: 'Chart',
  props: {
    graphData: {
      type: Array,
      required: true,
      default: () => []
    },
    invert: {
      type: Boolean,
      default: true
    },
    chartStyle: {
      type: String,
      default: APP_VERSION_OPTIONS.StatusChartVersions.DOTS_STYLE
    },
    variableBalls: {
      type: Boolean,
      default: true
    }
  },
  unmounted() {
    d3.select(window).on('resize.radarChart', null);
    if (document.querySelector('.toolTip')) {
      document.querySelector('.toolTip').remove();
    }
  },
  watch: {
    graphData(values) {
      this.draw('#radar-chart', values);
    }
  },
  mounted() {
    if (this.graphData.length) {
      this.draw('#radar-chart', this.graphData);
    }
  },
  methods: {
    draw(id, inData) {
      if (!inData || inData.length === 0 || inData[0] === undefined) {
        return;
      }
      let gData = Object.assign([], inData);
      d3.select(window).on('resize.radarChart', () => {
        const gData = Object.assign([], inData);
        this.draw(id, gData);
      });

      gData = gData.map(item => {
        return {
          ...item,
          area: this.$t(`constants.StatusReportTypes.${item.area}`)
        };
      });

      const dataValues = [];
      const series = 0;
      const that = this;
      const cfg = this.getOptions(id);

      if (this.invert === true) {
        gData = gData.map(item => ({
          ...item,
          value: cfg.maxValue - item.value
        }));
      }

      const allAxis = gData.map(function (i, j) {
        return i.area;
      });
      const numberOfAxis = allAxis.length;
      const radius = cfg.factor * Math.min(cfg.width / 2, cfg.height / 2);
      const Format = d3.format('%');
      const width = cfg.width + cfg.ExtraWidthX;
      const height = cfg.height + cfg.ExtraWidthY;
      let tooltip;

      // Make sure the container does not have an SVG-element already
      d3.select(id).select('svg').remove();

      // Create chart group
      const chartGroup = d3
        .select(id)
        .append('svg')
        .attr('class', 'root-svg')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr(
          'transform',
          'translate(' + cfg.TranslateX + ',' + cfg.TranslateY + ')'
        );

      // Circular segments
      for (let j = 0; j < cfg.levels; j++) {
        const levelFactor = cfg.factor * radius * ((j + 1) / cfg.levels);
        chartGroup
          .selectAll('.levels')
          .data(allAxis)
          .enter()
          .append('line')
          .attr('x1', function (d, i) {
            return (
              levelFactor *
              (1 - cfg.factor * Math.sin((i * cfg.radians) / numberOfAxis))
            );
          })
          .attr('y1', function (d, i) {
            return (
              levelFactor *
              (1 - cfg.factor * Math.cos((i * cfg.radians) / numberOfAxis))
            );
          })
          .attr('x2', function (d, i) {
            return (
              levelFactor *
              (1 -
                cfg.factor * Math.sin(((i + 1) * cfg.radians) / numberOfAxis))
            );
          })
          .attr('y2', function (d, i) {
            return (
              levelFactor *
              (1 -
                cfg.factor * Math.cos(((i + 1) * cfg.radians) / numberOfAxis))
            );
          })
          .attr('class', 'line')
          .style('stroke', 'grey')
          .style('stroke-opacity', '0.75')
          .style('stroke-width', '0.3px')
          .attr(
            'transform',
            'translate(' +
              (cfg.width / 2 - levelFactor) +
              ', ' +
              (cfg.height / 2 - levelFactor) +
              ')'
          );
      }

      // Text indicating at what % each level is
      // for (var j = 0; j < cfg.levels; j++) {
      //   var levelFactor = cfg.factor * radius * ((j + 1) / cfg.levels);
      //   chartGroup
      //     .selectAll('.levels')
      //     .data([1]) //dummy data
      //     .enter()
      //     .append('text')
      //     .attr('x', function(d) {
      //       return levelFactor * (1 - cfg.factor * Math.sin(0));
      //     })
      //     .attr('y', function(d) {
      //       return levelFactor * (1 - cfg.factor * Math.cos(0));
      //     })
      //     .attr('class', 'legend')
      //     .style('font-family', 'sans-serif')
      //     .style('font-size', '10px')
      //     .attr(
      //       'transform',
      //       'translate(' +
      //         (cfg.width / 2 - levelFactor + cfg.ToRight) +
      //         ', ' +
      //         (cfg.height / 2 - levelFactor) +
      //         ')'
      //     )
      //     .attr('fill', '#737373')
      //     .text(() => {
      //       return ((j + 1) * 10) / cfg.levels;
      //     });
      // }

      var axis = chartGroup
        .selectAll('.axis')
        .data(allAxis)
        .enter()
        .append('g')
        .attr('class', 'axis');

      axis
        .append('line')
        .attr('x1', cfg.width / 2)
        .attr('y1', cfg.height / 2)
        .attr('x2', function (d, i) {
          return (
            (cfg.width / 2) *
            (1 - cfg.factor * Math.sin((i * cfg.radians) / numberOfAxis))
          );
        })
        .attr('y2', function (d, i) {
          return (
            (cfg.height / 2) *
            (1 - cfg.factor * Math.cos((i * cfg.radians) / numberOfAxis))
          );
        })
        .attr('class', 'line')
        .style('stroke', 'grey')
        .style('stroke-width', '1px');

      if (cfg.width > 600 || cfg.showLabels === true) {
        axis
          .append('text')
          .attr('class', 'legend')
          .text(function (d) {
            return d;
          })
          .style('font-family', 'sans-serif')
          .style('font-size', cfg.fontSize)
          .attr('text-anchor', 'middle')
          .attr('dy', '1.5em')
          .attr('transform', function (d, i) {
            return 'translate(0, -10)';
          })
          .attr('x', function (d, i) {
            return (
              (cfg.width / 2) *
                (1 -
                  cfg.factorLegend *
                    Math.sin((i * cfg.radians) / numberOfAxis)) -
              60 * Math.sin((i * cfg.radians) / numberOfAxis)
            );
          })
          .attr('y', function (d, i) {
            return (
              (cfg.height / 2) *
                (1 - Math.cos((i * cfg.radians) / numberOfAxis)) -
              20 * Math.cos((i * cfg.radians) / numberOfAxis)
            );
          });
      }

      function getPoint(value, position) {
        return [
          (cfg.width / 2) *
            (1 -
              (parseFloat(Math.max(value, 0)) / cfg.maxValue) *
                cfg.factor *
                Math.sin((position * cfg.radians) / numberOfAxis)),
          (cfg.height / 2) *
            (1 -
              (parseFloat(Math.max(value, 0)) / cfg.maxValue) *
                cfg.factor *
                Math.cos((position * cfg.radians) / numberOfAxis))
        ];
      }

      function getPolygonPoints(value, position) {
        const p1 = getPoint(value, position);
        const p2 = getPoint(value, position + 1);
        const p3 = [cfg.width / 2, cfg.height / 2];

        return `${p1.join(',')} ${p2.join(',')} ${p3.join(',')}`;
      }

      chartGroup.selectAll('.nodes').data(gData, (item, itemIndex) => {
        const newVals = getPoint(item.value, itemIndex);
        dataValues.push(newVals);
      });

      // Make it circular
      dataValues.push(dataValues[0]);

      if (this.chartStyle === this.styles.PIE_STYLE) {
        for (let i = 0, n = gData.length; i < n; i++) {
          chartGroup
            .selectAll('.area')
            .data([gData])
            .enter()
            .append('polygon')
            .attr('class', 'radar-pie radar-chart-serie' + series)
            .style('stroke-width', '0')
            .style('stroke', cfg.color)
            .attr('points', d => getPolygonPoints(gData[i].value, i))
            .style('fill-opacity', cfg.opacityArea - 0.3)
            .on('mouseover', function (d) {
              // Do nothing if hover is disabled
              if (!cfg.useHover) {
                return;
              }

              showText(d[i]);

              document.querySelectorAll('.radar-pie').forEach(area => {
                if (area !== this) {
                  area.style.opacity = '0.1';
                }
              });

              this.style.fill = d[i].color;
              this.style.fillOpacity = 1;
            })
            .on('mouseout', function (d) {
              document.querySelector('.radar-text').remove();
              document.querySelectorAll('.radar-pie').forEach(area => {
                area.style.opacity = '1';
              });
              this.style.fill = '';
              this.style.fillOpacity = cfg.opacityArea - 0.3;
            });
        }
      } else {
        chartGroup
          .selectAll('.area')
          .data([dataValues])
          .enter()
          .append('polygon')
          .attr('class', 'radar-chart-serie' + series)
          .style('stroke-width', '2px')
          .style('stroke', cfg.color)
          .attr('points', d => {
            let str = '';
            for (let idx = 0; idx < d.length; idx++) {
              str = str + d[idx][0] + ' ' + d[idx][1] + ' ';
            }
            return str;
          })
          .style('fill', cfg.color)
          .style('fill-opacity', cfg.opacityArea);
      }

      if (this.chartStyle === this.styles.DOTS_STYLE) {
        // Add circles
        const today = moment();
        const getRadiusFromDate = date => {
          if (!this.variableBalls) {
            return '3px';
          }
          const fresh = moment().subtract(3, 'days');
          if (date.isSameOrAfter(fresh)) {
            return '3px';
          }
          const semiFresh = moment().subtract(2, 'weeks');
          if (date.isSameOrAfter(semiFresh)) {
            return '4px';
          }
          return '5px';
        };
        const getStrokeFromDate = date => {
          if (!this.variableBalls) {
            return '6px';
          }
          const fresh = moment().subtract(3, 'days');
          if (date.isSameOrAfter(fresh)) {
            return '6px';
          }
          const semiFresh = moment().subtract(2, 'weeks');
          if (date.isSameOrAfter(semiFresh)) {
            return '4px';
          }
          return '2px';
        };

        chartGroup
          .selectAll('.nodes')
          .data(gData)
          .enter()
          .append('circle')
          .attr('class', 'radar-chart-serie' + series)
          .attr('data-cy', 'radarchart-circle')
          .attr('alt', j => {
            return Math.max(j.value, 0);
          })
          .attr('cx', (j, i) => {
            dataValues.push([
              (cfg.width / 2) *
                (1 +
                  (parseFloat(Math.max(j.value, 0)) / cfg.maxValue) *
                    cfg.factor *
                    Math.sin((i * cfg.radians) / numberOfAxis)),
              (cfg.height / 2) *
                (1 +
                  (parseFloat(Math.max(j.value, 0)) / cfg.maxValue) *
                    cfg.factor *
                    Math.cos((i * cfg.radians) / numberOfAxis))
            ]);
            return (
              (cfg.width / 2) *
              (1 -
                (Math.max(j.value, 0) / cfg.maxValue) *
                  cfg.factor *
                  Math.sin((i * cfg.radians) / numberOfAxis))
            );
          })
          .attr('cy', function (j, i) {
            return (
              (cfg.height / 2) *
              (1 -
                (Math.max(j.value, 0) / cfg.maxValue) *
                  cfg.factor *
                  Math.cos((i * cfg.radians) / numberOfAxis))
            );
          })
          .attr('data-id', function (j) {
            return j.area;
          })
          .style('fill', 'white')
          .attr('r', d => getRadiusFromDate(d.date))
          .style('stroke-width', d => getStrokeFromDate(d.date))
          .style('stroke', d => d.color)
          .style('fill-opacity', 0.9)
          .on('mouseover', function (event, d) {
            // Do nothing if hover is disabled
            if (!cfg.useHover) {
              return;
            }

            // Create tooltip element
            tooltip = d3.select('body').append('div').attr('class', 'toolTip');

            const domRect = this.getBoundingClientRect();
            tooltip
              .html(
                `${d.area}: ${
                  d.displayValue
                }<hr><span>${that.showTimeSinceReport(d.date)}</span>`
              )
              .style('display', 'inline-block');

            const tooltipElem = document.querySelector('.toolTip');

            const positions = {
              left: domRect.left + cfg.radius - tooltipElem.offsetWidth / 2,
              top:
                domRect.top -
                2 * cfg.radius -
                tooltipElem.offsetHeight +
                window.scrollY
            };

            tooltip
              .style('left', `${positions.left}px`)
              .style('top', `${positions.top}px`);
          })
          .on('mouseout', d => {
            if (cfg.useHover) {
              tooltip.remove();
              tooltip = undefined;
            }
          });
      }

      function showText(datapoint) {
        chartGroup
          .append('text')
          .attr('x', width / 2 - 10)
          .attr('y', '20px')
          .attr('text-anchor', 'middle')
          .style('font-size', '25px')
          .attr('class', 'radar-text')
          .text(`${datapoint.area}: ${datapoint.displayValue}`);
      }
    },
    showTimeSinceReport(date) {
      const nowDate = moment().format('YYYY-MM-DD');
      const thenDate = moment(date).format('YYYY-MM-DD');
      const hoursSince = moment().diff(date, 'hours');
      const daysSince = moment().diff(date, 'days');
      const monthsSince = moment().diff(date, 'months');

      if (nowDate === thenDate) {
        return hoursSince < 1
          ? this.$t('datetime.momentsAgo')
          : this.$t('datetime.today');
      }
      if (daysSince <= 1 && nowDate !== thenDate) {
        return this.$t('datetime.yesterday');
      }
      if (monthsSince === 0) {
        return daysSince + ' ' + this.$t('datetime.daysAgo');
      }
      if (monthsSince === 1) {
        return 1 + ' ' + this.$t('datetime.monthAgo');
      }
      return monthsSince + ' ' + this.$t('datetime.monthsAgo');
    },
    getOptions(id) {
      const chart = d3.select(id);
      const bbox = chart.node().getBoundingClientRect();

      let size = bbox.width - 40;
      if (size > 400) {
        size = 400;
      }

      const cfg = {
        radius: 3,
        width: size,
        height: size,
        factor: 1,
        factorLegend: 0.85,
        levels: 5,
        maxValue: 10,
        radians: 2 * Math.PI,
        opacityArea: 0.6,
        ToRight: 5,
        showLabels: false,
        useHover: true,
        TranslateX: 10,
        TranslateY: 9,
        ExtraWidthX: 20,
        ExtraWidthY: 25,
        color: '#02c2e8',
        fontSize: '15px'
      };

      return cfg;
    }
  },
  computed: {
    styles() {
      return APP_VERSION_OPTIONS.StatusChartVersions;
    }
  }

  // computed: {
  //   data() {
  //     return [
  //       { area: "Pain", value: 4, date: moment("2018-04-01"), color: ColorHelper.getColor(0) },
  //       { area: "Tiredness", value: 0, date: moment("2018-03-03"), color: ColorHelper.getColor(1) },
  //       { area: "Drowsiness", value: 0, date: moment("2018-01-03"), color: ColorHelper.getColor(2) },
  //       { area: "Nausea", value: 0, date: moment("2017-04-03"), color: ColorHelper.getColor(3) },
  //       { area: "Dizziness", value: 10, date: moment("2018-04-03"), color: ColorHelper.getColor(4) },
  //       { area: "Shortness of breath", value: 3, date: moment("2018-04-03"), color: ColorHelper.getColor(5) },
  //       { area: "Appetite", value: 7, date: moment("2018-04-03"), color: ColorHelper.getColor(6) },
  //       { area: "Physical functioning", value: 5, date: moment("2018-04-03"), color: ColorHelper.getColor(7) },
  //       { area: "Sexual functioning", value: 10, date: moment("2018-04-03"), color: ColorHelper.getColor(8) },
  //       { area: "Stomach trouble", value: 2, date: moment("2018-04-03"), color: ColorHelper.getColor(9) },
  //       { area: "Urinary tract disorders", value: 4, date: moment("2018-04-03"), color: ColorHelper.getColor(10) },
  //       { area: "Skin problems", value: 1, date: moment("2018-04-03"), color: ColorHelper.getColor(11) },
  //       { area: "Vision problems", value: 10, date: moment("2017-04-01"), color: ColorHelper.getColor(12) },
  //       { area: "Depression", value: 10, date: moment("2018-04-03"), color: ColorHelper.getColor(13) },
  //       { area: "Anxiety", value: 9, date: moment("2018-04-03"), color: ColorHelper.getColor(14) },
  //       { area: "Wellbeing", value: 3, date: moment("2018-04-03"), color: ColorHelper.getColor(15) },
  //       { area: "Quality of life", value: 7, date: moment("2018-04-03"), color: ColorHelper.getColor(16) }
  //     ];
  //   }
  // }
};
</script>

<style lang="scss" scoped>
.list-test {
  column-count: 1;
}

dt {
  width: 15px;
  height: 15px;
  display: inline-block;
}

dd {
  margin: 5px;
  display: inline-block;
}

dl {
  column-count: 2;
  margin: 5px;
}
</style>

<style lang="scss">
.toolTip {
  pointer-events: none;
  position: absolute;
  display: none;
  min-width: 50px;
  height: auto;
  background: none repeat scroll 0 0 #ffffff;
  padding: 9px 14px 6px 14px;
  border-radius: 2px;
  text-align: center;
  line-height: 1.3;
  color: #5b6770;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.15);
  z-index: 9999;
}

.toolTip:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid white;
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -12px;
}
.toolTip span {
  font-weight: 500;
  color: #081f2c;
}

.radar-pie {
  transition: opacity 0.3s ease-in;
}
</style>
