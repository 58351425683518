<template>
  <div class="center-text">
    <s-button
      icon="mdi-download"
      data-cy="profile-link-downloaddata"
      @click="$downloadData"
    >
      <span v-text="$t('views.settings.downloadAllData')"></span>
    </s-button>
  </div>
</template>

<script>
import { CURRENT_USER_ACTIONS } from '@/store/modules/currentuser';
import { mapActions } from 'vuex';
export default {
  methods: {
    ...mapActions('currentuser', {
      $downloadData: CURRENT_USER_ACTIONS.DOWNLOAD_DATA
    })
  }
};
</script>

<style></style>
