<template>
  <teleport to="#modal" v-if="show">
    <div class="modal-container" :data-cy="elementName" @click.self="close">
      <div class="modal-content-container" :style="styles">
        <!-- <i class="mdi mdi-close-circle pointer" @click="close" v-if="!sticky && !disableCloseIcon"></i> -->
        <div
          class="modal-scrolldown"
          :data-buttons="buttons"
          :data-extras="extras"
          v-if="scrollToAccept && !atBottom"
        >
          <div class="bounce-animation">
            <span class="bold">{{ $t('modals.readWholeTextToAccept') }}</span>
            <br />
            <i class="mdi mdi-chevron-down"></i>
          </div>
        </div>
        <div class="modal-title" v-if="hasTitle">
          <slot name="title"></slot>
        </div>
        <div
          class="modal-content"
          :class="[
            { 'modal-content--buttons': buttons },
            { 'modal-content--title': hasTitle },
            { 'modal-content--extras ': extras },
            { 'modal-content--center-text': centerText }
          ]"
          :style="{ overflow }"
          ref="content"
        >
          <slot></slot>
        </div>
        <div class="modal-extras clearfix" v-if="extras">
          <slot name="extras"></slot>
        </div>
        <div class="modal-buttons clearfix" v-if="buttons">
          <slot name="buttons">
            <div :class="declineClass" v-if="!hideLeftButton">
              <slot name="left-button">
                <s-button
                  :element-name="`${elementName}-decline`"
                  :color="declineClasses"
                  :dark="true"
                  :flat="true"
                  :small="true"
                  class="underline"
                  @click.prevent="declineCallback ? declineCallback() : close()"
                  >{{ declineText || $t('generic.cancel') }}</s-button
                >
              </slot>
            </div>
            <div :class="acceptClass" v-if="!hideAcceptButton">
              <slot name="right-button">
                <s-button
                  :element-name="`${elementName}-accept`"
                  :color="acceptClasses"
                  @click.prevent="acceptClick"
                  :disabled="isAcceptDisabled"
                  :loading="acceptLoading"
                  >{{ acceptText }}</s-button
                >
              </slot>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { mapMutations } from 'vuex';
import { APP_MUTATIONS, APP_GETTERS } from '@/store/modules/app';
import FormSetup from '@common/Mixins/FormSetup';

const ESCAPE_CODE = 27;

export default {
  mixins: [FormSetup],
  emits: ['close', 'destroyed'],
  props: {
    show: {
      type: Boolean
    },
    height: {
      type: String
    },
    width: {
      type: String
    },
    maxWidth: {
      type: String
    },
    buttons: {
      type: Boolean,
      default: true
    },
    sticky: {
      type: Boolean
    },
    hideLeftButton: {
      type: Boolean
    },
    hideAcceptButton: {
      type: Boolean
    },
    declineText: {
      type: String
    },
    declineClasses: {
      type: String,
      default: ''
    },
    declineCallback: {
      type: Function
    },
    acceptText: {
      type: String,
      default: 'Acceptera'
    },
    acceptClasses: {
      type: String,
      default: 'green'
    },
    acceptCallback: {
      type: Function
    },
    acceptDisabled: {
      type: Boolean
    },
    acceptLoading: {
      type: Boolean
    },
    closeOnAccept: {
      type: Boolean,
      default: true
    },
    scrollToAccept: {
      type: Boolean
    },
    elementName: {
      type: String,
      default: 'modal'
    },
    disableCloseIcon: {
      type: Boolean
    },
    reverseButtonOrder: {
      type: Boolean
    },
    overflow: {
      type: String,
      default: 'auto'
    },
    centerText: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      scrollHeight: 0,
      scrolled: 0,
      atBottom: false
    };
  },

  watch: {
    show(newValue, oldValue) {
      if (newValue) {
        this.$nextTick(() => this.init());
      }
      this.$nextTick(() => {
        this.toggleModal(newValue);
      });
    }
  },

  mounted() {},

  beforeUnmount() {
    this.$emit('destroyed');
    this.toggleModal(false);
    if (this.$refs.content) {
      this.$refs.content.removeEventListener('scroll', this.scrollChecker);
    }
  },

  methods: {
    ...mapMutations('app', {
      $setPageSettings: APP_MUTATIONS.SET_PAGE_SETTING
    }),
    toggleModal(state) {
      this.$setPageSettings({ option: 'show_modal', value: state });
    },
    init() {
      if (!this.$refs.content) {
        return;
      }

      window.addEventListener('keyup', this.closeOnEscape);

      this.$_FormSetup_setupForm();

      this.scrollHeight =
        this.$refs.content.scrollHeight - this.$refs.content.clientHeight;
      this.scrolled = 0;
      this.atBottom = false;
      this.$refs.content.addEventListener('scroll', this.scrollChecker);
    },
    scrollChecker() {
      this.scrolled = this.$refs.content.scrollTop;
      this.atBottom = this.scrolled > this.scrollHeight - 50;
    },
    acceptClick() {
      if (this.acceptCallback) {
        this.acceptCallback();
      }

      if (this.closeOnAccept) {
        this.$emit('close');
      }
    },
    close() {
      if (this.sticky === true) {
        return;
      }
      window.removeEventListener('keyup', this.closeOnEscape);
      this.$emit('close');
    },
    closeOnEscape(e) {
      if (e.keyCode === ESCAPE_CODE) {
        this.close();
      }
    }
  },
  computed: {
    isAcceptDisabled() {
      if (
        (this.scrollToAccept === true && !this.atBottom) ||
        this.acceptDisabled === true
      ) {
        return true;
      }
      return false;
    },
    extras() {
      return !!this.$slots.extras;
    },
    styles() {
      const styles = {};
      if (this.height) {
        styles.height = this.height;
      }
      if (this.width) {
        styles.width = this.width;
        if (!this.maxWidth) {
          styles['max-width'] = this.width;
        } else {
          styles['max-width'] = this.maxWidth;
        }
      }
      return styles;
    },
    declineClass() {
      return this.reverseButtonOrder ? 'right' : 'left';
    },
    acceptClass() {
      return this.reverseButtonOrder ? 'left' : 'right';
    },
    hasTitle() {
      return !!this.$slots.title;
    }
  }
};
</script>

<style lang="scss">
@import '@sass/_variables.scss';
.modal-content-container {
  a {
    color: #000;
  }

  .sub-header {
    overflow: hidden;
    font-size: 1.2em;
    margin-top: 0;
    padding: 5px 0;
    color: $symptoms-black;

    i.mdi {
      font-size: inherit;
    }
  }

  .button.green {
    background-color: $symptoms-green !important;
    color: #fff;
  }

  .button.red {
    background-color: $symptoms-red !important;
    color: #fff;
  }
}
</style>

<style lang="scss" scoped>
@import '@sass/_variables.scss';
@import '@sass/_animations.scss';

.modal-container {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  height: 100vh;
  width: 100vw;
  top: 0;
  position: fixed;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content-container {
  width: 99%;
  max-width: 400px;
  background-color: #fff;
  color: black;
  border-radius: 10px;
  height: 99%;
  padding: 15px;
  max-height: 700px;
  position: relative;

  @media only screen and (max-width: $breakpoint-phone) {
    height: calc(80vh - 40px);
    max-height: none;
    // border-radius: 0;
    width: calc(100vw - 40px);
    min-width: calc(100vw - 40px);
    max-width: none;
    padding-bottom: 20px;
  }

  .mdi-close-circle {
    position: absolute;
    top: -15px;
    right: -15px;
    color: #fff;
    text-shadow: 1px 1px 1px #000;

    @media only screen and (max-width: $breakpoint-phone) {
      right: 0;
      top: 0px;
      color: black;
      text-shadow: none;
    }
  }
}
.mdi {
  display: inline;
}
.modal-scrolldown {
  position: absolute;
  bottom: 110px;
  left: 0;
  right: 0;
  height: 80px;
  text-align: center;
  padding-top: 60px;
  pointer-events: none;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.7) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  @media only screen and (max-width: $breakpoint-phone) {
    bottom: 130px;
  }
}
.modal-title {
  font-size: 1.5em;
  text-align: center;
  font-weight: 700;
}
.modal-content {
  overflow: auto;
  height: 100%;
  font-size: 1.2em;

  @media only screen and (max-width: $breakpoint-phone) {
    max-height: 100%;
  }

  &--center-text {
    color: $symptoms-grey;
    text-align: center;
  }

  &--extras {
    height: calc(100% - 50px);
  }
  &--buttons {
    height: calc(100% - 50px);
    // @media only screen and (max-width: $breakpoint-phone) {
    //   height: auto;
    // }
  }

  .btn {
    padding: 0;
  }
}
.modal-content.modal-content--buttons.modal-content--title {
  height: calc(100% - 80px);
}
.modal-content.modal-content--extras.modal-content--buttons {
  height: calc(100% - 100px);
  @media only screen and (max-width: $breakpoint-phone) {
    // height: calc(100% - 120px);
  }
}
.standalone {
  .modal-content.modal-content--extras.modal-content--buttons {
    height: calc(100% - 100px);
  }
}
.modal-extras {
  padding: 10px 0 0 0;
}
.modal-buttons {
  padding-top: 10px;
}

.small-viewport {
  .modal-content-container {
    height: 100vh;
  }
  .modal-content {
    height: 65% !important;
    -webkit-overflow-scrolling: touch;
  }
}
.small-viewport.standalone {
  .modal-content-container {
    height: 100vh;
  }
  .modal-content {
    height: calc(100% - 100px) !important;
    -webkit-overflow-scrolling: touch;
  }
}
</style>
