import BugReportModel from '@common/Models/BugReportModel';

/**
 * Map local BugReport to remote
 * @param {BugReportModel} bugreport
 * @return {BugReportModel}
 */
export const BugReportRequest = bugreport => {
  if (bugreport.Screenshots.length) {
    bugreport.Screenshots = bugreport.Screenshots.map(s => {
      const data = s.Data.split('base64,').pop();
      return {
        ...s,
        Data: data
      };
    });
  }
  return bugreport;
};
