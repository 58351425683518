<template>
  <div>
    <div class="container">
      <h4 v-html="$t('pageTitles.news')" class="header ml20"></h4>
      <div v-if="$newsItems.length > 0">
        <news-item
          v-for="newsItem in $newsItems"
          :news-item="newsItem"
          :key="newsItem.Id"
          data-cy="news-item"
        >
          <template v-slot:content>
            <span v-html="$filters.marked(newsItem.Content)"></span>
          </template>
        </news-item>
      </div>
      <div v-else>Inga nyheter hittades.</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import NewsItem from '@views/News/components/NewsItem.vue';
import { NEWS_ACTIONS, NEWS_GETTERS } from '@/store/modules/news';

export default {
  components: {
    NewsItem
  },
  mounted() {
    this.$getPublishedNewsItems();
  },
  methods: {
    ...mapActions('news', {
      $getPublishedNewsItems: NEWS_ACTIONS.GET_PUBLISHED_NEWS_ITEMS
    })
  },
  computed: {
    ...mapGetters('news', {
      $newsItems: NEWS_GETTERS.NEWS_ITEMS
    })
  }
};
</script>

<style lang="css"></style>
