export const INVITE_MUTATIONS = {
  ADD_INVITE: 'ADD_INVITE',
  SET_ACTIVE_INVITES: 'SET_ACTIVE_INVITES',
  REMOVE_INVITE: 'REMOVE_INVITE'
};

export const INVITE_ACTIONS = {
  CREATE_INVITE: 'CREATE_INVITE',
  CREATE_INVITE_AS_ADMIN: 'CREATE_INVITE_AS_ADMIN',
  GET_ACTIVE_INVITES: 'GET_ACTIVE_INVITES',
  DELETE_INVITE: 'DELETE_INVITE'
};

export const INVITE_GETTERS = {
  INVITES: 'INVITES'
};
