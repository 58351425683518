// @ts-check
import moment from 'moment';
import { required, minLength } from '@vuelidate/validators';

export class ReminderModel {
  constructor({
    ReminderDate = moment().add(7, 'days').format('YYYY-MM-DD'),
    ReminderTime = moment().format('HH:mm'),
    IsRecurring
  }) {
    this._modelName = 'Reminder';
    this.ReminderDate = ReminderDate;
    this.ReminderTime = ReminderTime;
    this.IsRecurring = IsRecurring;
  }

  static get validations() {
    return {
      IsRecurring: {
        minLength: minLength(1)
      },
      ReminderTime: {
        required
      },
      ReminderDate: {
        required
      }
    };
  }

  get ReminderDateTime() {
    return `${this.ReminderDate} ${this.ReminderTime}`;
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }
}

export default ReminderModel;
