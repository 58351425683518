import PromModel from '@common/Models/PromModel';

/**
 * Map remote Prom to local
 * @param {object} dtoProm
 * @returns {PromModel}
 */
export const PromResponse = dtoProm => {
  return new PromModel(dtoProm);
};

/**
 * Map remote Proms to local
 * @param {object[]} dtoProms
 * @returns {PromModel[]}
 */
export const PromsResponse = dtoProms => {
  return dtoProms.map(dto => PromResponse(dto));
};
