export const APP_MUTATIONS = {
  SET_CONSTANTS: 'SET_CONSTANTS',
  SET_STATUSTYPES: 'SET_STATUSTYPES',
  SET_KEY: 'SET_KEY',
  SET_PAGE_SETTING: 'SET_PAGE_SETTING',
  SET_INITIALIZED: 'SET_INITIALIZED',
  SET_CONNECTION: 'SET_CONNECTION'
};

export const APP_ACTIONS = {
  $PREINIT: '$PREINIT',
  GET_CONSTANTS: 'GET_CONSTANTS',
  GET_STATUSTYPES: 'GET_STATUSTYPES',
  DISPLAY_TOAST: 'DISPLAY_TOAST'
};

export const APP_GETTERS = {
  AVAILABLE_MODELS: 'AVAILABLE_MODELS',
  CONSTANTS: 'CONSTANTS',
  CONNECTED: 'CONNECTED',
  STATUSTYPES: 'STATUSTYPES',
  SHOW_HEADER: 'SHOW_HEADER',
  SHOW_FLOAT_CONTENT: 'SHOW_FLOAT_CONTENT',
  SHOW_MODAL: 'SHOW_MODAL',
  SHOW_LARGE_MENU: 'SHOW_LARGE_MENU',
  SHOW_MENU: 'SHOW_MENU',
  SHOW_NEW_VERSION: 'SHOW_NEW_VERSION',
  LARGE_VIEWPORT: 'LARGE_VIEWPORT',
  STATUS_REPORT_TYPES: 'STATUS_REPORT_TYPES',
  STATUS_REPORT_TYPES_SORTED_ARRAY: 'STATUS_REPORT_TYPES_SORTED_ARRAY',
  SYMPTOM_TYPES: 'SYMPTOM_TYPES',
  ROLES: 'roles',
  ROLES_ARRAY: 'rolesArray',
  SCOPES: 'SCOPES',
  SCOPES_ARRAY: 'SCOPES_ARRAY'
};
