export const BUGREPORT_MUTATIONS = {
  ADD_BUG_REPORT: 'ADD_BUG_REPORT',
  SET_BUG_REPORTS: 'SET_BUG_REPORTS',
  REMOVE_BUG_REPORT: 'REMOVE_BUG_REPORT'
};

export const BUGREPORT_ACTIONS = {
  GET_BUG_REPORTS: 'GET_BUG_REPORTS',
  DELETE_BUG_REPORT: 'DELETE_BUG_REPORT'
};

export const BUGREPORT_GETTERS = {
  BUG_REPORTS: 'BUG_REPORTS',
  PROCESSED_BUG_REPORTS: 'PROCESSED_BUG_REPORTS',
  UNPROCESSED_BUG_REPORTS: 'UNPROCESSED_BUG_REPORTS',
  FIND_BUG_REPORT: 'FIND_BUG_REPORT'
};
