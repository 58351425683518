<template>
  <div
    class="symptom-report-note-view container"
    :class="{ 'symptom-report-note-view--bottom-popup': activeSymptom }"
    v-if="activeSymptom"
  >
    <h5 class="center-text header">
      {{ $t('views.symptomReportNote.describeTheSymptom') }}
    </h5>
    <p class="center-text mt0">
      {{ activeSymptom.Symptom.Name }}
    </p>

    <template v-if="report && report._saved">
      <edit-lock
        :locked="report._locked"
        @change="locked => setLocked(locked)"
      ></edit-lock>
    </template>

    <card-container :full-width="true">
      <card color="white">
        <template v-slot:content>
          <div class="symptom-report-note-view--note">
            <div
              v-html="
                $filters.NL2BR(
                  $t(
                    'views.symptomReportNote.describeTheSymptomAsDetailedAsYouCan'
                  )
                )
              "
            />
            <div
              v-html="
                $filters.NL2BR(
                  $t('views.symptomReportNote.descriptionExampleTest')
                )
              "
              class="symptom-report-note-view--note__description-example-text"
            />

            <div class="textarea">
              <textarea
                id="note"
                :disabled="report._locked"
                v-model="activeSymptom.Note"
                :data-length="v$.activeSymptom.Note.maxLength.$params.max"
                :maxlength="v$.activeSymptom.Note.maxLength.$params.max"
              />
              <errors :field="v$.activeSymptom.Note" />
            </div>
          </div>
        </template>
      </card>
    </card-container>

    <float-content>
      <s-button
        @click="goBack()"
        id="ButtonCancel"
        :flat="true"
        :dark="true"
        class="underline"
        element-name="symptomreport-cancelbutton"
        >{{ $t('generic.back') }}</s-button
      >

      <s-button
        v-if="report.editable"
        @click="saveReportLater"
        :primary="true"
        id="ButtonSave"
        element-name="symptomreport-nextbutton"
        >{{ $t('generic.next') }}</s-button
      >
    </float-content>

    <modal-confirm
      :show="confirmCancel"
      :accept-callback="
        () => {
          confirmedCancel = true;
          cancelReport();
        }
      "
      :accept-text="$t('generic.leave')"
      :show-extras="false"
      :decline-text="$t('generic.no')"
      @close="confirmCancel = false"
      >{{ $t('modals.confirmLeaveReport') }}</modal-confirm
    >
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import EditLock from '@/views/components/EditLock.vue';
import SymptomReportModel from '@common/Models/SymptomReport/SymptomReportModel';
import { mapGetters, mapMutations } from 'vuex';
import {
  SYMPTOMREPORT_MUTATIONS,
  SYMPTOMREPORT_GETTERS
} from '@/store/modules/symptomreports';
import SymptomReportSymptomModel from '@common/Models/SymptomReport/SymptomReportSymptomModel';

export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  props: {
    symptomreportid: {
      type: String
    }
  },
  components: { EditLock },
  data() {
    return {
      /** @type {SymptomReportModel} */
      report: null,
      confirmCancel: false,
      confirmedCancel: false
    };
  },
  beforeRouteLeave(to, from, next) {
    if (!this.report) {
      next();
      return;
    }

    const keepUnsavedReport = [
      'symptomReport',
      'symptomReportEdit',
      'symptomReportDraw',
      'symptomReportGrade',
      'symptomReportNote',
      'symptomSelect'
    ].includes(to.name);

    if (
      !keepUnsavedReport &&
      this.report?.hasModifiedSymptoms &&
      this.confirmCancel === false &&
      this.confirmedCancel === false
    ) {
      this.confirmCancel = true;
      this.redirectOnCancel = to;
      next(false);
    } else {
      this.$bus.$emit('toggleHeader', true);

      if (!keepUnsavedReport) {
        this.$removeUnsavedReport();
      }

      next();
    }
  },
  validations: {
    activeSymptom: SymptomReportSymptomModel.validations
  },
  watch: {
    symptomreportid(newValue, oldValue) {
      if (newValue !== undefined) {
        if (newValue !== oldValue) {
          this.loadOrCreateSymptomReport();
        } else {
          return;
        }
      }
      this.resetReport();
    },
    'activeSymptom.Note'(newVal, oldVal) {
      if (newVal && oldVal) {
        this.activeSymptom._modified = true;
      }
    }
  },
  mounted() {
    this.loadOrCreateSymptomReport();
  },
  methods: {
    ...mapMutations('symptomreports', {
      $setUnsavedReport: SYMPTOMREPORT_MUTATIONS.SET_UNSAVED_REPORT,
      $removeUnsavedReport: SYMPTOMREPORT_MUTATIONS.REMOVE_UNSAVED_REPORT
    }),
    cancelReport() {
      this.$removeUnsavedReport();

      if (this.redirectOnCancel) {
        this.$router.push(this.redirectOnCancel.path);
      } else {
        this.$router.replace({ name: 'start' }).catch(() => {});
      }
    },
    setLocked(locked) {
      this.report._locked = locked;
    },
    resetReport() {
      this.loadOrCreateSymptomReport();
    },
    loadOrCreateSymptomReport() {
      if (this.$unsavedReport) {
        this.report = new SymptomReportModel(this.$unsavedReport.__copy());
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    saveReportLater() {
      this.v$.$touch();

      if (this.v$.activeSymptom.$invalid) {
        return;
      }

      this.$setUnsavedReport(this.report);

      if (this.$route.query?.fromstatus) {
        if (this.report.isFromRemote) {
          this.$router.replace({
            name: 'symptomReportEdit',
            params: { symptomreportid: this.report.Id }
          });
        } else {
          this.$router.push({
            name: 'symptomReport'
          });
        }
      } else {
        this.$router.push({
          name: 'symptomReportGrade',
          params: {
            symptomreportid: this.report.Id
          },
          query: {
            symptomid: this.$route.query.symptomid,
            newSymptom: true
          }
        });
      }
    }
  },
  computed: {
    ...mapGetters('symptomreports', {
      $unsavedReport: SYMPTOMREPORT_GETTERS.UNSAVED_REPORT
    }),
    activeSymptom() {
      if (!this.report) {
        return null;
      }

      const srs = this.report.Symptoms.find(
        srs => srs.SymptomId === this.symptomIdToLoad
      );

      return srs;
    },
    symptomIdToLoad() {
      return this.$route.query.symptomid;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_animations.scss';
textarea {
  height: 135px;
  border: 2px solid #adb2b8;
  border-radius: 2px;
  padding-bottom: 20px;
}
.textarea {
  position: relative;
}
</style>

<style lang="scss">
@import '@sass/_variables.scss';

.symptom-report-note-view {
  position: relative;
  margin-top: -20px;

  &--note {
    padding: 20px;

    .textarea {
      margin-top: 10px;
    }

    &__description-example-text {
      font-style: italic;
    }
  }

  &--bottom-popup {
    height: 82%;
  }

  .title {
    text-align: center;
    font-weight: 700;
    background-color: #fff;
  }
}
</style>
