import { SYMPTOM_ACTIONS, SYMPTOM_GETTERS } from '@/store/modules/symptoms';
import { sortByKey } from '@common/Helpers/sorters';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      showEditPopup: false,
      showSymptomPopup: false,
      saveLoading: false,
      symptomLoading: false,
      newSymptomProperties: {
        Name: '',
        Description: ''
      },
      backups: {
        Name: '',
        Description: ''
      }
    };
  },
  watch: {
    showEditPopup(value) {
      if (value) {
        setTimeout(() => M.updateTextFields(), 10);
      }
    }
  },
  mounted() {
    this.backups.Name = this.symptom.Name;
    this.backups.Description = this.symptom.Description;
    this.newSymptomProperties.Name = this.symptom.Name;
    this.newSymptomProperties.Description = this.symptom.Description;
  },
  methods: {
    ...mapActions('symptoms', {
      $updateSymptom: SYMPTOM_ACTIONS.UPDATE_SYMPTOM,
      $closeSymptom: SYMPTOM_ACTIONS.CLOSE_SYMPTOM
    }),
    closeSymptom() {
      this.symptomLoading = true;
      this.$emit('delete-symptom', this.symptom);
    },
    removeSymptom() {
      this.showSymptomPopup = false;
      this.$emit('delete-symptom', this.symptom);
    },
    cancel() {
      this.showEditPopup = false;
      this.newSymptomProperties.Name = this.backups.Name;
      this.newSymptomProperties.Description = this.backups.Description;
    },
    updateSymptom() {
      if (this.symptom.isFromRemote) {
        this.saveLoading = true;
        const symptom = Object.assign(
          {},
          this.symptom,
          {
            Version: this.symptom.Version,
            Id: this.symptom.Id
          },
          this.newSymptomProperties
        );

        this.$updateSymptom(symptom).then(() => {
          this.saveLoading = false;
          this.showEditPopup = false;
        });
      } else {
        Object.assign(this.symptom, this.newSymptomProperties);
        this.showEditPopup = false;
      }
    },
    gotoSymptom() {
      this.$router.push({
        name: 'symptomDetails',
        params: { symptomid: this.symptom.Id }
      });
    }
  },
  computed: {
    ...mapGetters('symptoms', {
      $graphData: SYMPTOM_GETTERS.SYMPTOM_GRAPH_DATA,
      $findSymptom: SYMPTOM_GETTERS.FIND_SYMPTOM
    }),
    graphData() {
      return sortByKey(this.$graphData(this.symptom), 'date');
    },
    hasRecentData() {
      return this.graphData.some(y =>
        y.moment.isAfter(moment().subtract(30, 'day'))
      );
    },
    lastSymptom() {
      if (!this.graphData.length) {
        return;
      }
      return [...this.graphData].pop();
    },
    showPopup() {
      return this.showEditPopup || this.showSymptomPopup;
    }
  }
};
