// @ts-check
import GroupModel from '@common/Models/GroupModel';
import { required } from '@vuelidate/validators';

export class PromGroupModel {
  constructor({ OrganizationId, GroupId, PromId, Group = null }) {
    this._modelName = 'PromGroup';
    this.OrganizationId = OrganizationId;
    this.GroupId = GroupId;
    this.PromId = PromId;
    this.Group = Group ? new GroupModel(Group) : null;
  }

  static get validations() {
    return {
      PromId: {
        required
      }
    };
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }
}

export default PromGroupModel;
