// @ts-check
import { ssn } from '@common/Validators/ssn.js';
import { email, maxLength, required } from '@vuelidate/validators';

export class UserModel {
  constructor({
    Id = null,
    Personnummer,
    LanguageId,
    PatientId,
    Username,
    Email = null,
    EmailConfirmed = false,
    FirstName = '',
    LastName = '',
    Groups,
    OptOut,
    OptOutDate = null,
    PhoneNumber,
    Research,
    Roles = [],
    ReportCount = 0,
    LastActivity,
    Permissions = []
  }) {
    this._modelName = 'User';
    this.Id = Id;
    this.Personnummer = Personnummer;
    this.LanguageId = LanguageId;
    this.PatientId = PatientId;
    this.Username = Username;
    this.Email = Email;
    this.EmailConfirmed = EmailConfirmed;
    this.FirstName = FirstName;
    this.LastName = LastName;
    this.Groups = Groups;
    this.OptOut = OptOut;
    this.OptOutDate = OptOutDate;
    this.PhoneNumber = PhoneNumber;
    this.Research = Research;
    this.Roles = Roles;
    this.ReportCount = ReportCount;
    this.LastActivity = LastActivity;
    /** @type {String[]} */
    this.Permissions = Permissions;
  }

  get FullName() {
    if (!this.FirstName && !this.LastName) {
      return '';
    }
    return `${this.FirstName} ${this.LastName}`;
  }

  static get validations() {
    return {
      FirstName: {
        maxLength: maxLength(32)
      },
      LastName: {
        maxLength: maxLength(32)
      },
      Email: {
        email
      },
      Personnummer: { required, ssn },
      Password: {},
      Roles: {}
    };
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }
}

export default UserModel;
