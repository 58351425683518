export const PHONE_WIDTH = 460;
export const DESKTOP_WIDTH = 1025;
export const LANGUAGE_STORAGE = 'user/language.keep';

export const APP_VERSION_OPTIONS = {
  StatusChartVersions: {
    PIE_STYLE: 'Paj',
    DOTS_STYLE: 'Bollar'
  },
  StatusReportVersions: {
    SMILEYS_STYLE: 'Smileys',
    NUMBERS_STYLE: 'Siffror'
  },
  PromReportVersions: {
    CAT_STYLE: 'CAT',
    NORMAL_STYLE: 'Normal'
  },
  IntensityScaleVersions: {
    NORMAL_STYLE: 'Normal'
  }
};

export const NEWS_ITEM_PRIORITIES = [
  { display: 'Ej viktigt', value: 'not-important' },
  { display: 'Viktigt', value: 'important' },
  { display: 'Väldigt viktigt', value: 'very-important' }
];

export const OPERATORS = [
  { display: '<', value: -1 },
  { display: '==', value: 0 },
  { display: '>', value: 1 }
];

export const PROM_QUESTION_TYPES = [
  { display: 'Ett svar', value: 'radiobtn' },
  { display: 'Fler svar', value: 'chbox' },
  { display: 'Text', value: 'text' },
  { display: 'Datum', value: 'date' },
  { display: 'Dropdown', value: 'dropdown' },
  { display: 'Number', value: 'number' },
  { display: 'Slider', value: 'slider' }
];

export const PROM_SLIDER_STYLES = [{ display: 'VAS', value: 'vas' }];

export const PROM_RADIO_ANSWER_TYPES = [
  { display: 'Default', value: undefined },
  { display: 'Numbered', value: 'numbered' }
];

export const SYMPTOM_TYPE_COLORS = {
  generic: '0xf45642',
  pain: '0xf45642',
  rash: '0x00a86b',
  sensoryimpairment: '0xffbb3e'
};

export const SUBTYPES = [
  null,
  'Throbbing',
  'Pressing',
  'Dull',
  'Sharp',
  'Shooting',
  'Numbness',
  'Tingling',
  'Burning'
];

export const ALPHABET = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
];

export const StatusReportScaleColors = [
  '#dddddd',
  '#fae5e6',
  '#f5ccce',
  '#f1b2b6',
  '#ec999e',
  '#e87f86',
  '#e3666d',
  '#de4c55',
  '#da323d',
  '#d51925',
  '#d1000d'
];

export const StatusReportAreaColors = {
  anxiety: '#6f6f6f',
  appetite: '#4738f2',
  depression: '#e3e3e3',
  dizziness: '#d7c6cf',
  drowsiness: '#7b72b6',
  hearingdisorders: '#ffa9ef',
  nausea: '#d6ea3a',
  pain: '#ff0000',
  physicalfunctioning: '#7c9d45',
  qualityoflife: '#12b629',
  sexualfunctioning: '#990027',
  shortnessofbreath: '#aaceff',
  skinproblems: '#f9d5d5',
  stomachtrouble: '#d2b48c',
  stress: '#ffab43',
  tiredness: '#cda8d9',
  urinarytractdisorders: '#ffff66',
  visionproblems: '#00cccc',
  wellbeing: '#6ff521'
};

export const INVERTED_STATUS_REPORT_ITEMS = [
  'wellbeing',
  'qualityoflife',
  'physicalfunctioning',
  'sexualfunctioning',
  'appetite'
];

export const PERMISSIONS = {
  CanViewAdminDashboard: 'CanViewAdminDashboard',

  /*
   * BugReports
   */
  CanViewBugReports: 'CanViewBugReports',
  CanAddBugReports: 'CanAddBugReports',
  CanRemoveBugReports: 'CanRemoveBugReports',

  /*
   * Users / Patients
   */
  CanViewAllUsers: 'CanViewAllUsers',
  CanViewUsers: 'CanViewUsers',
  CanAddUsers: 'CanAddUsers',
  CanAddPatients: 'CanAddPatients',
  CanAddSystemUsers: 'CanAddSystemUsers',
  CanGenerateUsers: 'CanGenerateUsers',
  CanConnectUserToPatient: 'CanConnectUserToPatient',
  CanViewOwnUser: 'CanViewOwnUser',
  CanEditUsers: 'CanEditUsers',
  CanEditPatients: 'CanEditPatients',
  CanEditOwnUser: 'CanEditOwnUser',
  CanEditUserPasswords: 'CanEditUserPasswords',
  CanEditOwnPassword: 'CanEditOwnPassword',
  CanRemoveUsers: 'CanRemoveUsers',
  CanDeactivatePatients: 'CanDeactivatePatients',
  CanSetupTwoFactor: 'CanSetupTwoFactor',
  CanAdministerTwoFactor: 'CanAdministerTwoFactor',
  // TODO: Remove in production?
  CanAddUserData: 'CanAddUserData',
  CanEditUserData: 'CanEditUserData',
  CanRemoveUserData: 'CanRemoveUserData',
  // Physicians
  CanViewUserData: 'CanViewUserData',
  // System
  CanSeedData: 'CanSeedData',
  CanExtractAllData: 'CanExtractAllData',
  CanRemoveAllData: 'CanRemoveAllData',
  CanAnonymizeData: 'CanAnonymizeData',
  CanViewActivityData: 'CanViewActivityData',
  CanViewUserSession: 'CanViewUserSession',
  CanFetchUserByPersonnummer: 'CanFetchUserByPersonnummer',
  CanFetchUserByPatientId: 'CanFetchUserByPatientId',

  /*
   * Invites
   */
  CanViewInvitesToOwnGroup: 'CanViewInvitesToOwnGroup',
  CanInviteMinor: 'CanInviteMinor',
  CanAddInvites: 'CanAddInvites',
  CanAddInvitesToAllGroups: 'CanAddInvitesToAllGroups',
  CanAddInvitesToOwnGroup: 'CanAddInvitesToOwnGroup',
  CanRemoveInvites: 'CanRemoveInvites',
  CanViewInviteString: 'CanViewInviteString',

  /*
   * Applications - applications that interact with the API
   */
  CanViewApplications: 'CanViewApplications',
  CanAddApplications: 'CanAddApplications',
  CanEditApplications: 'CanEditApplications',
  CanRemoveApplications: 'CanRemoveApplications',

  /*
   * AppVersions - groups of settings for frontend
   */
  CanViewAppVersions: 'CanViewAppVersions',
  CanAddAppVersions: 'CanAddAppVersions',
  CanEditAppVersions: 'CanEditAppVersions',
  CanRemoveAppVersions: 'CanRemoveAppVersions',

  /*
   * OAuthTokens - generate OAuth tokens for interactions with API
   */
  CanViewOwnOAuthTokens: 'CanViewOwnOAuthTokens',
  CanAddOwnOAuthTokens: 'CanAddOwnOAuthTokens',
  CanEditOwnOAuthTokens: 'CanEditOwnOAuthTokens',
  CanRemoveOwnOAuthTokens: 'CanRemoveOwnOAuthTokens',

  /*
   * Sessions
   */
  CanViewOwnSessions: 'CanViewOwnSessions',
  CanRemoveOwnSessions: 'CanRemoveOwnSessions',

  /*
   * Organizations - e.g. a company or hospital
   */
  CanViewOrganizations: 'CanViewOrganizations',
  CanAddOrganizations: 'CanAddOrganizations',
  CanEditOrganizations: 'CanEditOrganizations',
  CanRemoveOrganizations: 'CanRemoveOrganizations',
  CanViewOrganizationMembers: 'CanViewOrganizationMembers',
  CanAddOrganizationMembers: 'CanAddOrganizationMembers',
  CanEditOrganizationMembers: 'CanEditOrganizationMembers',
  CanRemoveOrganizationMembers: 'CanRemoveOrganizationMembers',
  // Organization admins
  CanViewOwnOrganization: 'CanViewOwnOrganization',
  CanEditOwnOrganization: 'CanEditOwnOrganization',
  CanViewOwnOrganizationMembers: 'CanViewOwnOrganizationMembers',
  CanAddOwnOrganizationMembers: 'CanAddOwnOrganizationMembers',
  CanEditOwnOrganizationMembers: 'CanEditOwnOrganizationMembers',
  CanRemoveOwnOrganizationMembers: 'CanRemoveOwnOrganizationMembers',

  /*
   * Groups - e.g. a research study
   */
  CanViewGroupMembers: 'CanViewGroupMembers',
  CanAddGroupMembers: 'CanAddGroupMembers',
  CanEditGroupMembers: 'CanEditGroupMembers',
  CanRemoveGroupMembers: 'CanRemoveGroupMembers',
  CanViewGroups: 'CanViewGroups',
  CanAddGroups: 'CanAddGroups',
  CanEditGroups: 'CanEditGroups',
  CanRemoveGroups: 'CanRemoveGroups',
  // Organization admins
  CanViewOwnOrganizationGroups: 'CanViewOwnOrganizationGroups',
  CanAddOwnOrganizationGroups: 'CanAddOwnOrganizationGroups',
  CanEditOwnOrganizationGroups: 'CanEditOwnOrganizationGroups',
  CanRemoveOwnOrganizationGroups: 'CanRemoveOwnOrganizationGroups',
  // Group admins
  CanViewOwnGroupMembers: 'CanViewOwnGroupMembers',
  CanAddOwnGroupMembers: 'CanAddOwnGroupMembers',
  CanEditOwnGroupMembers: 'CanEditOwnGroupMembers',
  CanRemoveOwnGroupMembers: 'CanRemoveOwnGroupMembers',
  CanViewOwnGroup: 'CanViewOwnGroup',
  CanEditOwnGroup: 'CanEditOwnGroup',

  /*
   * LanguageStrings - e.g. text for invite mails
   */
  CanViewLanguageStrings: 'CanViewLanguageStrings',
  CanAddLanguageStrings: 'CanAddLanguageStrings',
  CanUpdateLanguageStrings: 'CanUpdateLanguageStrings',
  CanDeleteLanguageStrings: 'CanDeleteLanguageStrings',

  /*
   * Statistics - e.g. compare health outcomes for different hospitals
   */
  CanViewStatistics: 'CanViewStatistics',

  /*
   * Research - data added by research subjects
   */
  CanAddResearchData: 'CanAddResearchData',
  CanEditResearchData: 'CanEditResearchData',
  CanRemoveResearchData: 'CanRemoveResearchData',
  // Research admins
  CanAddResearchSubjects: 'CanAddResearchSubjects',
  CanViewResearchData: 'CanViewResearchData',
  // Research subjects
  CanAddOwnResearchData: 'CanAddOwnResearchData',
  CanViewOwnResearchData: 'CanViewOwnResearchData',

  /*
   * ER data - data generated at the emergency clinic
   */
  CanViewERData: 'CanViewERData',
  CanAddERData: 'CanAddERData',
  CanEditERData: 'CanEditERData',
  CanRemoveERData: 'CanRemoveERData',

  /*
   * Group content - being able to view content belonging to one's group
   */
  CanViewOwnGroupsContent: 'CanViewOwnGroupsContent',
  CanAddOwnGroupsContent: 'CanAddOwnGroupsContent',
  CanEditOwnGroupsContent: 'CanEditOwnGroupsContent',
  CanRemoveOwnGroupsContent: 'CanRemoveOwnGroupsContent',

  /*
   * Data - data generated by regular users/patients
   */
  CanViewOwnData: 'CanViewOwnData',
  CanAddOwnData: 'CanAddOwnData',
  CanEditOwnData: 'CanEditOwnData',
  CanRemoveOwnData: 'CanRemoveOwnData',

  /*
   * Content - basically being able to view the page
   */
  CanViewContent: 'CanViewContent',
  CanAddContent: 'CanAddContent',
  CanEditContent: 'CanEditContent',
  CanRemoveContent: 'CanRemoveContent',

  /*
   * Proms
   */
  CanAddProms: 'CanAddProms',
  CanEditProms: 'CanEditProms',
  CanRemoveProms: 'CanRemoveProms',
  CanRemoveAllProms: 'CanRemoveAllProms',
  CanAddPromLanguageVersions: 'CanAddPromLanguageVersions',
  CanEditPromLanguageVersions: 'CanEditPromLanguageVersions',
  CanRemovePromLanguageVersions: 'CanRemovePromLanguageVersions',
  CanRemoveAllPromLanguageVersions: 'CanRemoveAllPromLanguageVersions',

  /*
   * PromRequests
   */
  CanViewPromRequests: 'CanViewPromRequests',
  CanExportPromRequestAnswers: 'CanExportPromRequestAnswers',
  CanAddPromRequests: 'CanAddPromRequests',
  CanRemovePromRequests: 'CanRemovePromRequests',

  /*
   * Contracts
   */
  CanBypassContracts: 'CanBypassContracts',
  CanViewContracts: 'CanViewContracts',
  CanConsentToContract: 'CanConsentToContract',
  CanAddContracts: 'CanAddContracts',
  CanRemoveContracts: 'CanRemoveContracts'
};

export const PUSHER_MESSAGES = {
  ShareRequestCreated: 'share-request-created',
  ShareRequestDeleted: 'share-request-deleted',
  ShareRequestAccepted: 'share-request-accepted',
  ShareCreated: 'share-created',
  ShareUpdated: 'share-updated',
  ShareDeleted: 'share-deleted',

  SymptomReportCreated: 'symptom-report-created',
  SymptomReportUpdated: 'symptom-report-updated',
  SymptomReportDeleted: 'symptom-report-deleted',

  StatusReportCreated: 'status-report-created',
  StatusReportUpdated: 'status-report-updated',
  StatusReportDeleted: 'status-report-deleted',

  PromRequestCreated: 'prom-request-created',
  PromRequestDeleted: 'prom-request-deleted',
  PromRequestFilled: 'prom-request-filled',

  PromReportCreated: 'prom-report-created',
  PromReportUpdated: 'prom-report-updated',
  PromReportDeleted: 'prom-report-deleted',

  NoteCreated: 'note-created',
  NoteUpdated: 'note-updated',
  NoteDeleted: 'note-deleted'
};
