<template>
  <no-login-template-vue>
    <template v-if="!sent">
      <prom-report
        v-if="prom && report && promRequest"
        :prom="prom"
        :prom-report="report"
        :prom-request="promRequest"
        @save="report => postSave(report)"
      />
    </template>
    <template v-else>
      <div class="container">
        <div
          class="fullscreen-center"
          v-text="$t('views.promRequest.thanks')"
        ></div>
      </div>
    </template>
  </no-login-template-vue>
</template>

<script>
import PromReport from '@views/PromReport/components/PromReport.vue';
import PromRequestService from '@common/Services/PromRequests/PromRequestService';
import PromReportModel from '@common/Models/PromReportModel';
import { randomString } from '@common/Helpers/strings';
import { mapActions } from 'vuex';
import { PROM_ACTIONS } from '@/store/modules/proms';
import NoLoginTemplateVue from '@common/Templates/NoLoginTemplate.vue';

export default {
  props: ['promrequestid'],
  components: {
    NoLoginTemplateVue,
    PromReport
  },
  data() {
    return {
      prom: null,
      report: null,
      promRequest: null,
      sent: false
    };
  },
  async mounted() {
    await this.loadProm();
    await this.loadPromReport();
    await this.loadPromRequest();
  },
  methods: {
    ...mapActions('proms', {
      $getPromByPromRequest: PROM_ACTIONS.GET_PROM_BY_PROMREQUEST
    }),
    async loadPromRequest() {
      await PromRequestService.getRequest(this.promrequestid).then(
        promRequest => {
          this.promRequest = promRequest;
        }
      );
    },
    async loadProm() {
      await this.$getPromByPromRequest(this.promrequestid).then(prom => {
        this.prom = prom;
      });
    },
    async loadPromReport() {
      this.report = new PromReportModel({
        Id: randomString(),
        PromId: this.prom.Id,
        LanguageId: this.prom.Language,
        QuestionCount: this.prom.QuestionCount
      });
    },
    postSave() {
      this.sent = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
