export const SHARE_MUTATIONS = {
  SET_SHARES: 'SET_SHARES',
  ADD_SHARE: 'ADD_SHARE',
  REMOVE_SHARE: 'REMOVE_SHARE'
};

export const SHARE_ACTIONS = {
  $PREINIT: '$PREINIT',
  GET_SHARES: 'GET_SHARES',
  GET_SHARE: 'GET_SHARE',
  CREATE_SHARE: 'CREATE_SHARE',
  DELETE_SHARE: 'DELETE_SHARE',
  UPDATE_SHARE: 'UPDATE_SHARE',
  ENABLEDISABLE_SHARE: 'ENABLEDISABLE_SHARE',
  ACCEPT_SHARE: 'ACCEPT_SHARE',
  ENABLE_SHARE: 'ENABLE_SHARE',
  DISABLE_SHARE: 'DISABLE_SHARE',
  CREATE_SHARE_REQUEST: 'CREATE_SHARE_REQUEST'
};

export const SHARE_GETTERS = {
  SHARES: 'SHARES',
  REQUESTS: 'REQUESTS',
  ACTIVE: 'ACTIVE',
  FIND_SHARE: 'FIND_SHARE'
};
