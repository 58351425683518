import BaseService from '../BaseService';
import { PromResponse, PromsResponse } from './mappers/PromResponses';
import PromModel from '@common/Models/PromModel';

class PromService extends BaseService {
  /**
   * Fetch prom in group -- backend
   * @param {string} id Prom Id
   * @param {string} [lang='sv'] Language Id
   * @returns {Promise<PromModel>}
   * @memberof PromService
   */
  findMyGroupProm(id) {
    return this.backend(`my/group/proms/${id}`).then(response =>
      PromResponse(response.data)
    );
  }

  /**
   * Get proms in group -- backend
   * @returns {Promise<PromModel[]>}
   * @memberof PromService
   */
  getMyGroupProms() {
    return this.backend('my/group/proms').then(response =>
      PromsResponse(response.data)
    );
  }

  /**
   * Fetch Prom -- backend
   * @param {string} id Prom Id
   * @param {string} [lang='sv'] Language Id
   * @returns {Promise<PromModel>}
   * @memberof PromService
   */
  findProm(id) {
    return this.backend(`proms/${id}`).then(response =>
      PromResponse(response.data)
    );
  }

  /**
   * Get user's suggested proms -- backend
   * @returns {Promise<PromModel[]>}
   * @memberof PromService
   */
  getMySuggestedProms() {
    return this.backend('my/proms/suggested').then(response =>
      PromsResponse(response.data)
    );
  }

  /**
   * Get all proms -- backend
   * @returns {Promise<PromModel[]>}
   * @memberof PromService
   */
  getProms() {
    return this.backend('proms').then(response => PromsResponse(response.data));
  }

  /**
   * Add new prom
   * @param {PromModel} prom
   * @returns {Promise<PromModel>}
   * @memberof PromService
   */
  addProm(prom) {
    return this.backend('post', 'proms', prom).then(response =>
      PromResponse(response.data)
    );
  }

  /**
   * Delete prom
   * @param {PromModel} prom
   * @returns {Promise}
   * @memberof PromService
   */
  deleteProm(prom) {
    return this.backend('delete', `proms/${prom.Id}`);
  }
}

export default new PromService();
