import { Bus } from '@common';
import UserModel from '@common/Models/UserModel';
import UserService from '@common/Services/User/UserService';
import { USER_ACTIONS, USER_GETTERS, USER_MUTATIONS } from './definitions';

class State {
  constructor() {
    /** @type {UserModel[]} */
    this.list = [];
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  /** @param {UserModel} user */
  [USER_MUTATIONS.ADD_USER](state, user) {
    const exists = state.list.filter(u => !!u).find(u => u.Id === user.Id);
    if (!exists) {
      state.list.push(user);
    } else {
      Object.assign(new UserModel(exists), user);
    }
  },

  /** @param {UserModel} user */
  [USER_MUTATIONS.REMOVE_USER](state, user) {
    const index = state.list.findIndex(x => x.Id === user.Id);
    if (index > -1) {
      state.list.splice(index, 1);
    }
  },

  /**
   * User saved.
   * @param {UserModel} user
   */
  [USER_MUTATIONS.USER_SAVE_SUCCESS](state, user) {
    const exists = state.list.find(u => u.Id === user.Id);

    if (!exists) {
      state.list.unshift(user);
    } else {
      Object.assign(new UserModel(exists), user);
    }
  },

  /** @param {UserModel[]} users */
  [USER_MUTATIONS.SET_USERS](state, users) {
    state.list = users;
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  /** @param {UserModel} user */
  [USER_ACTIONS.DELETE_USER](context, user) {
    UserService.deleteUser(user).then(() =>
      context.commit(USER_MUTATIONS.REMOVE_USER, user)
    );
  },

  [USER_ACTIONS.GET_USER](context, userId) {
    return UserService.getUser(userId).then(user => {
      context.commit(USER_MUTATIONS.ADD_USER, user);
      return user;
    });
  },

  [USER_ACTIONS.GET_USERS](context) {
    UserService.getUsers().then(users => {
      context.commit(USER_MUTATIONS.SET_USERS, users);
    });
  },

  /** @param {UserModel} user */
  [USER_ACTIONS.UPDATE_USER](context, user) {
    const newUser = { ...user };
    return UserService.updateUser(newUser.Id, newUser).then(user => {
      context.commit(USER_MUTATIONS.USER_SAVE_SUCCESS, user);
      return user;
    });
  },

  /** @param {UserModel} user */
  [USER_ACTIONS.CREATE_USER](context, user) {
    const newUser = { ...user };
    return UserService.createUser(newUser).then(user => {
      context.commit(USER_MUTATIONS.USER_SAVE_SUCCESS, user);
    });
  },

  [USER_ACTIONS.DISABLE_2FA_FOR_USER](context, userId) {
    return UserService.disableTwoFactorForPatient(userId).then(() => {
      Bus.$emit('toast.display', {
        message: `2fa avstängd`,
        status: 'success'
      });
    });
  },

  [USER_ACTIONS.RESET_PASSWORD_FOR_USER](context, userId) {
    return UserService.resetPasswordForPatient(userId).then(() => {
      Bus.$emit('toast.display', {
        message: `E-mail för lösenordsåterställning skickat till användaren`,
        status: 'success'
      });
    });
  },

  [USER_ACTIONS.ERASE_ALL_DATA_FOR_USER](context, userId) {
    return UserService.eraseAllDataForPatient(userId).then(() => {
      Bus.$emit('toast.display', {
        message: `Kontot nollställt för användaren`,
        status: 'success'
      });
    });
  },

  [USER_ACTIONS.REMOVE_ACCOUNT_FOR_USER](context, userId) {
    return UserService.removeUser(userId).then(() => {
      context.commit(USER_MUTATIONS.REMOVE_USER, { Id: userId });
      Bus.$emit('toast.display', {
        message: `Användarens konto är borttaget`,
        status: 'success'
      });
    });
  },

  [USER_ACTIONS.ANONYMIZE_ACCOUNT_FOR_USER](context, userId) {
    return UserService.anonymizeAccountForPatient(userId).then(() => {
      context.commit(USER_MUTATIONS.REMOVE_USER, { Id: userId });
      Bus.$emit('toast.display', {
        message: `Användarens konto är anonymiserat`,
        status: 'success'
      });
    });
  },

  [USER_ACTIONS.UPDATE_ROLES_FOR_USER](context, { userId, newRoles }) {
    return UserService.updateUserRoles(userId, newRoles).then(() => {
      Bus.$emit('toast.display', {
        message: `Roles uppdaterade`,
        status: 'success'
      });
    });
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [USER_GETTERS.FIND_USER]: state => userId => {
    return state.list.find(u => u.Id === userId);
  },
  [USER_GETTERS.USERS](state) {
    return state.list;
  }
};

export { USER_ACTIONS, USER_GETTERS, USER_MUTATIONS } from './definitions';

export default store;
