import { AUTH_ACTIONS } from '@/store/modules/auth';
import store from '@/store';
import UserService from '@common/Services/User/UserService';
import { isAuthed } from '@common/Helpers/auth';

export const logout = (to, from, next) => {
  let toPath = { name: 'loginForm' };
  if (to.query.noRedir === 'true') {
    toPath = from.fullPath;
  }
  store.dispatch(`auth/${AUTH_ACTIONS.LOGOUT}`).finally(() => next(toPath));
};

export const verifyEmail = (to, from, next) => {
  const code = to.params.verifyCode;
  let toPath = 'start';
  if (isAuthed() === false) {
    toPath = 'login';
  }
  UserService.verifyEmail(code).then(() =>
    next({
      name: toPath,
      query: { verifiedemail: 1 }
    })
  );
};
