export const PROMREQUEST_MUTATIONS = {
  ADD_REQUEST: 'ADD_REQUEST',
  SET_REQUESTS: 'SET_REQUESTS',
  REMOVE_REQUEST: 'REMOVE_REQUEST'
};

export const PROMREQUEST_ACTIONS = {
  CREATE_REQUEST: 'CREATE_REQUEST',
  GET_REQUESTS: 'GET_REQUESTS',
  DELETE_REQUEST: 'DELETE_REQUEST'
};

export const PROMREQUEST_GETTERS = {
  REQUESTS: 'REQUESTS'
};
