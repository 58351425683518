<template>
  <main-header-menu-popup-vue
    parent=".app-header__roles"
    :open="open"
    min-width="133px"
  >
    <ul>
      <li v-for="{ Display, Value } in $activeUserRoles($roles)" :key="Value">
        <div
          class="item"
          @click="setRole(Value)"
          :class="{ active: $activeRole === Value }"
        >
          {{ Display }}
        </div>
      </li>
    </ul>
  </main-header-menu-popup-vue>
</template>

<script>
import { getDefaultLandingPage } from '../../router/helpers';
import MainHeaderMenuPopupVue from '@common/Components/MainHeaderMenuPopup.vue';
import { mapMutations, mapGetters, mapActions } from 'vuex';
import {
  AUTH_MUTATIONS,
  AUTH_GETTERS,
  AUTH_ACTIONS
} from '@/store/modules/auth';
import { APP_GETTERS } from '@/store/modules/app';
import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser';

export default {
  components: { MainHeaderMenuPopupVue },
  data() {
    return {
      open: false
    };
  },
  methods: {
    ...mapActions('auth', {
      $refreshToken: AUTH_ACTIONS.REQUEST_NEW_ACCESS_TOKEN,
      $setActiveRole: AUTH_MUTATIONS.SET_ACTIVE_ROLE
    }),
    setRole(roleId) {
      this.$setActiveRole(roleId).then(() => {
        const path = getDefaultLandingPage();
        this.$router.replace({ path }).catch(e => {});
      });
    }
  },
  computed: {
    ...mapGetters('app', {
      $roles: APP_GETTERS.ROLES
    }),
    ...mapGetters('auth', {
      $activeRole: AUTH_GETTERS.ACTIVE_ROLE,
      $activeUserRoles: AUTH_GETTERS.ACTIVE_ROLES
    })
  }
};
</script>
