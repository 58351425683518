<template>
  <card color="white">
    <template v-slot:content>
      <div class="share-content">
        <div class="buttons">
          <trash-vue
            data-cy="share-remove-button"
            @mouseenter="trashActive = true"
            @mouseleave="trashActive = false"
            @click="removeShareToggle = true"
            :active="trashActive"
          ></trash-vue>
        </div>
        <div class="meta">
          <span class="date">{{
            $filters.CalendarFormat(share.CreatedDate)
          }}</span>
          <span class="time mr10">{{
            $filters.TimeFormat(share.CreatedDate)
          }}</span>
          <span
            >{{
              $t('views.shares.shareRequestBy', { name: share.RequestedBy })
            }}.</span
          >
        </div>

        <div class="text">
          <template v-if="share.SymptomReports">
            <i
              class="mdi"
              :class="
                share.SymptomReports ? 'mdi-check green-text' : 'mdi-times'
              "
            ></i>
            <span v-text="$tc('plural.symptomReport', 2)"></span>
          </template>

          <template v-if="share.PromReports">
            <br />
            <i
              class="mdi"
              :class="share.PromReports ? 'mdi-check green-text' : 'mdi-times'"
            ></i>
            <span v-text="$tc('plural.promReport', 2)"></span>
          </template>

          <template v-if="share.StatusReports">
            <br />
            <i
              class="mdi"
              :class="
                share.StatusReports ? 'mdi-check green-text' : 'mdi-times'
              "
            ></i>
            <span v-text="$tc('plural.statusReport', 2)"></span>
          </template>

          <template v-if="share.Notes">
            <br />
            <i
              class="mdi"
              :class="share.Notes ? 'mdi-check green-text' : 'mdi-times'"
            ></i>
            <span v-text="$tc('plural.note', 2)"></span>
          </template>

          <div class="mt10">
            <s-button :primary="true" @click="acceptShareToggle = true">{{
              $t('generic.accept')
            }}</s-button>
          </div>
        </div>

        <modal-confirm
          :show="acceptShareToggle"
          :accept-callback="
            () => {
              acceptShare();
            }
          "
          :accept-text="$t('generic.accept')"
          :show-extras="false"
          :decline-text="$t('generic.cancel')"
          @close="acceptShareToggle = false"
        >
          <template v-slot:title>
            <span v-text="$t('modals.acceptShareTitle')"></span>
          </template>
          {{
            $t('modals.acceptShare', {
              name: share.ShareWithUserFullName || share.RequestedBy
            })
          }}
        </modal-confirm>

        <modal-confirm
          :show="removeShareToggle"
          :accept-callback="
            () => {
              removeShare();
            }
          "
          :accept-text="$t('generic.remove')"
          :show-extras="false"
          :decline-text="$t('generic.cancel')"
          @close="removeShareToggle = false"
        >
          <template v-slot:title>
            <span v-text="$t('modals.removeShareRequestTitle')"></span>
          </template>
          {{
            $t('modals.removeShareRequest', {
              name: share.ShareWithUserFullName || share.RequestedBy
            })
          }}
        </modal-confirm>
      </div>
    </template>
  </card>
</template>

<script>
import TrashVue from '@common/Components/SVG/Trash.vue';
import { mapGetters, mapActions } from 'vuex';
import { NOTES_ACTIONS } from '@/store/modules/notes';
import ShareModel from '@common/Models/Share/ShareModel';
import { SHARE_ACTIONS } from '../../../store/modules/shares';

export default {
  components: {
    TrashVue
  },
  props: {
    share: {
      type: ShareModel,
      required: true
    }
  },
  data() {
    return {
      trashActive: false,
      acceptLoading: false,
      acceptShareToggle: false,
      removeShareToggle: false,
      removeLoading: false
    };
  },
  mounted() {},
  methods: {
    ...mapActions('shares', {
      $acceptShare: SHARE_ACTIONS.ACCEPT_SHARE,
      $removeShare: SHARE_ACTIONS.DELETE_SHARE
    }),
    acceptShare() {
      this.acceptLoading = true;
      this.$acceptShare(this.share)
        .catch(error => {})
        .finally(() => {
          this.acceptLoading = false;
          this.showPopup = false;
        });
    },
    removeShare() {
      this.removeLoading = true;
      this.$removeShare(this.share)
        .catch(error => {})
        .finally(() => {
          this.removeLoading = false;
          this.showPopup = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';
.share-content {
  padding: 20px;
  position: relative;

  .buttons {
    position: absolute;
    right: 20px;
    top: 15px;
    svg {
      width: 35px;
      cursor: pointer;
      &:not(:last-of-type) {
        margin-right: 10px;
      }
      @media only screen and (max-width: $breakpoint-phone-small) {
        width: 30px;
        &:not(:last-of-type) {
          margin-right: 5px;
        }
      }
    }
  }

  .date {
    margin-right: 20px;
  }

  .time {
    color: $symptoms-grey;
  }

  .modified i {
    font-size: inherit;
  }

  .text {
    margin-top: 5px;
    i {
      font-size: inherit;
    }
  }
}
</style>
