<template>
  <div v-if="profile && user" data-cy="profile">
    <h4 class="container ml20 header">
      {{ $t('generic.profile') }}
    </h4>
    <form>
      <div class="container">
        <s-text-input
          v-model="user.Email"
          id="InputEmail"
          data-cy="profile-form-email"
          :label="
            user.EmailConfirmed
              ? $t('generic.email')
              : $t('generic.unconfirmedEmail')
          "
          :rounded="true"
        ></s-text-input>
        <errors :field="v$.user.Email" />

        <s-text-input
          v-model="user.Personnummer"
          :disabled="true"
          id="InputPersonnummer"
          data-cy="profile-form-personnummer"
          :label="$t('generic.personnummer')"
          :rounded="true"
        ></s-text-input>
        <errors :field="v$.user.Personnummer" />

        <s-text-input
          v-model.trim="profile.AreaCode"
          id="InputZipcode"
          type="tel"
          ref="zipcode"
          data-cy="profile-form-zipcode"
          :label="$t('views.profile.areacode')"
          maxlength="5"
          :rounded="true"
        ></s-text-input>
        <errors :field="v$.profile.AreaCode" />
      </div>

      <!-- <div class="container">
        <h4
          class="sub-header"
          v-text="$t('views.settings.language')"
          data-cy="profile-text-language"
        ></h4>
        <div class="box rounded">
          <language-picker :value.sync="profile.Language"></language-picker>
        </div>
      </div> -->

      <div class="container">
        <h4
          class="sub-header"
          v-text="$t('views.settings.symptomModel')"
          data-cy="profile-text-model"
        ></h4>
        <div class="box">
          <model-picker v-model="profile.Model"></model-picker>
        </div>
      </div>

      <!-- <div class="container">
        <h4
          class="sub-header"
          v-text="$t('generic.2fa')"
          data-cy="settings-text-twofactor"
        ></h4>
        <div class="box">
          <two-factor></two-factor>
        </div>
      </div> -->

      <div class="container">
        <h4
          class="sub-header"
          v-text="$t('views.settings.downloadData')"
          data-cy="profile-text-downloaddata"
        ></h4>
        <div class="box">
          <download-data></download-data>
        </div>
      </div>

      <div class="container">
        <h4
          class="sub-header"
          v-text="$t('views.settings.sessions')"
          data-cy="profile-text-sessions"
        ></h4>
        <div class="box">
          <sessions></sessions>
          <s-button
            icon="mdi-power-settings"
            :flat="true"
            :dark="true"
            id="ButtonLogoutOtherDevices"
            data-cy="profile-form-logoutotherdevicesbutton"
            @click="$logoutOtherDevices"
            >{{ $t('generic.logoutOtherDevices') }}</s-button
          >
        </div>
      </div>

      <div class="container">
        <h4
          class="sub-header"
          v-text="$t('views.settings.account')"
          data-cy="profile-text-removeaccount"
        ></h4>
        <div class="box center-text">
          <label>
            <input type="checkbox" class="filled-in" v-model="user.OptOut" />
            <span>{{ $t('views.profile.excludeMyDataFromStatistics') }}</span>
          </label>
        </div>
        <div class="box center-text">
          <s-button
            :danger="true"
            @click="removeAccountModal = true"
            data-cy="profile-form-removeaccountbutton"
          >
            {{ $t('views.settings.removeAccount') }}
          </s-button>
        </div>
      </div>
    </form>

    <float-content>
      <s-button
        @click="$router.push('/')"
        id="ButtonCancel"
        element-name="profile-cancelbutton"
        :dark="true"
        :flat="true"
        class="underline"
        data-cy="profile-form-cancelbutton"
        >{{ $t('generic.cancel') }}</s-button
      >
      <s-button
        :primary="true"
        @click="save"
        id="ButtonSave"
        :loading="loading"
        element-name="profile-form-savebutton"
        data-cy="profile-form-savebutton"
        >{{ $t('generic.save') }}</s-button
      >
    </float-content>

    <!-- REMOVE ACCOUNT FIRST VIEW -->

    <modal
      :show="removeAccountModal"
      @close="removeAccountModal = false"
      :accept-callback="
        () => {
          this.removeAccountModal = false;
          this.anonymiseAccountModal = true;
        }
      "
      :decline-callback="
        () => {
          this.removeAccountModal = false;
          this.deactivateAccountModal = true;
        }
      "
      height="auto"
      :accept-text="$t('generic.anonymize')"
      :decline-text="$t('generic.deactivate')"
      decline-classes="btn-flat"
    >
      <h5
        class="center-text sub-header"
        v-text="$t('views.settings.removeAccount')"
      ></h5>
      <p v-text="$t('information.anonymiseData')"></p>
    </modal>

    <!-- DEACTIVATE ACCOUNT -->

    <modal
      :show="deactivateAccountModal"
      @close="deactivateAccountModal = false"
      :accept-callback="
        () => {
          this.deactivateAccount();
        }
      "
      :decline-callback="
        () => {
          this.deactivateAccountModal = false;
          this.removeAccountModal = true;
        }
      "
      height="auto"
      :accept-text="$t('generic.deactivate')"
      :close-on-accept="false"
      :accept-disabled="v$.userPassword.$invalid"
    >
      <h5
        class="center-text sub-header"
        v-text="$t('views.settings.deactivateAccount')"
      ></h5>
      <p v-text="$t('views.settings.enterReactivationPassword')"></p>
      <p
        class="pointer underline"
        @click="
          deactivateAccountModal = false;
          removeAccountLastStepModal = true;
        "
        v-text="$t('views.settings.ifRemove')"
      ></p>
      <s-text-input
        :placeholder="$t('generic.password')"
        v-model="userPassword"
        autocomplete="current-password"
        :type="showDeactivateAccountPassword ? 'text' : 'password'"
        :icon="showDeactivateAccountPassword ? 'mdi-eye-off' : 'mdi-eye'"
        :icon-callback="
          () => {
            showDeactivateAccountPassword = !showDeactivateAccountPassword;
          }
        "
        :icon-title="
          showDeactivateAccountPassword
            ? this.$t('generic.hidePassword')
            : this.$t('generic.showPassword')
        "
      ></s-text-input>
    </modal>

    <modal
      :show="removeAccountLastStepModal"
      @close="removeAccountLastStepModal = false"
      :accept-callback="
        () => {
          this.removeAccount();
        }
      "
      :decline-callback="
        () => {
          this.removeAccountLastStepModal = false;
          this.deactivateAccountModal = true;
        }
      "
      height="auto"
      :accept-text="$t('generic.remove')"
      accept-classes="red"
      decline-classes="green"
    >
      <h5
        class="center-text sub-header"
        v-text="$t('views.settings.removeAccount')"
      ></h5>
      <p v-text="$t('information.dataDelete')"></p>
    </modal>

    <!-- ANONYMISE ACCOUNT -->

    <modal-anonymise-account
      :show="anonymiseAccountModal"
      @close="anonymiseAccountModal = false"
    ></modal-anonymise-account>
  </div>
</template>

<script>
import { required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import {
  CURRENT_USER_ACTIONS,
  CURRENT_USER_GETTERS
} from '@/store/modules/currentuser';
import { papapi } from '@common/Services/external/papapi';
import DownloadData from './components/DownloadData';
// import LanguagePicker from './components/LanguagePicker';
import ModalAnonymiseAccount from '@/views/modals/ModalAnonymiseAccount.vue';
import ModelPicker from './components/ModelPicker';
import Sessions from './components/Sessions';
// import TwoFactor from './components/TwoFactor.vue';
import FormSetup from '@common/Mixins/FormSetup';
import UserModel from '@common/Models/UserModel';
import PatientModel from '@common/Models/PatientModel';
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';

const { withAsync } = helpers;

export default {
  name: 'ProfileView',
  setup() {
    return {
      v$: useVuelidate({ $lazy: true })
    };
  },
  mixins: [FormSetup],
  components: {
    DownloadData,
    // LanguagePicker,
    ModalAnonymiseAccount,
    ModelPicker,
    Sessions
    // TwoFactor
  },
  data() {
    return {
      profile: null,
      user: null,
      loading: false,
      papapi: new papapi('69a8afd1a9ba993bd973825b574ecb4d62c5fe6a'),
      emailChanged: false,
      languageChanged: false,
      modelChanged: false,
      removeAccountModal: false,
      deactivateAccountModal: false,
      removeAccountLastStepModal: false,
      anonymiseAccountModal: false,
      showDeactivateAccountPassword: false,
      userPassword: null
    };
  },
  validations() {
    return {
      user: UserModel.validations,
      profile: {
        ...PatientModel.validations,
        AreaCode: {
          areaCode: withAsync(this.checkAreaCode)
        }
      },
      userPassword: {
        required
      }
    };
  },
  mounted() {
    if (this.$profile) {
      this.profile = this.$profile.__copy();
    }
    if (this.$user) {
      this.user = this.$user.__copy();
    }
  },
  beforeMount() {
    this.$getProfileView();
  },
  beforeUnmount() {
    this.profile = null;
    this.user = null;
  },
  watch: {
    $profile() {
      this.profile = this.$profile.__copy();
      this.$_FormSetup_setupForm();
    },
    $user() {
      this.user = this.$user.__copy();
      M.updateTextFields();
    },
    'user.Email'(newValue, oldValue) {
      this.emailChanged = newValue !== this.$user.Email;
    },
    'profile.Language'(newValue) {
      this.languageChanged = newValue !== this.$profile.Language;
    },
    'profile.Model'(newValue, oldValue) {
      this.modelChanged = newValue !== this.$profile.Model;
    }
  },
  methods: {
    async checkAreaCode(areaCode) {
      if (!areaCode || areaCode.length === 0) {
        return true;
      } else if (areaCode.length < 5) {
        return false;
      }

      return await this.papapi
        .zipcode(areaCode)
        .then(response => {
          const adresses = response.adresses;
          this.profile.County = adresses[0].state;
          this.profile.Municipal = adresses[0].municipality;
          this.profile.City = adresses[0].city;

          return true;
        })
        .catch(_err => {
          return false;
        });
    },
    ...mapActions('currentuser', {
      $changeLanguage: CURRENT_USER_ACTIONS.CHANGE_LANGUAGE,
      $changeModel: CURRENT_USER_ACTIONS.CHANGE_SYMPTOM_MODEL,
      $deactivateAccount: CURRENT_USER_ACTIONS.DEACTIVATE_ACCOUNT,
      $getProfileView: CURRENT_USER_ACTIONS.GET_PROFILE_VIEW,
      $logoutOtherDevices: CURRENT_USER_ACTIONS.LOGOUT_OTHER_DEVICES,
      $removeAccount: CURRENT_USER_ACTIONS.REMOVE_ACCOUNT,
      $updateProfile: CURRENT_USER_ACTIONS.UPDATE_PROFILE,
      $updateUser: CURRENT_USER_ACTIONS.UPDATE_USER
    }),
    async save() {
      await this.v$.$validate();

      if (this.v$.user.$invalid || this.v$.profile.$invalid) {
        return;
      }

      this.loading = true;

      this.saveUser()
        .then(() => this.saveProfile())
        .finally(() => (this.loading = false));
    },
    saveUser() {
      return this.$updateUser(this.user).catch(error => {
        this.$bus.$emit('toast.display', {
          message: `${this.$root.$t('generic.saveFailure')} - ${error.Message}`,
          status: 'failure'
        });
        throw error;
      });
    },
    saveProfile() {
      return this.$updateProfile(this.profile)
        .then(profile => {
          let message = this.$root.$t('generic.saveSuccess');
          if (this.emailChanged) {
            message = this.$root.$t('views.profile.emailChanged');
          }

          if (this.languageChanged) {
            this.$changeLanguage({ language: profile.Language });
          }

          if (this.modelChanged) {
            this.$changeModel({ newModel: profile.Model });
          }

          this.$bus.$emit('toast.display', {
            message: message,
            status: 'success',
            duration: 4000
          });
          this.profile = this.$profileCopy;
          this.v$.$reset();
        })
        .catch(error => {
          if (error.ErrorCode === 4003) {
            this.$getProfileView().then(() => {
              this.$bus.$emit('toast.display', {
                message: `${this.$root.$t('errors.profileConflict')}`,
                status: 'warning',
                duration: 4000
              });
            });
          } else {
            this.$bus.$emit('toast.display', {
              message: `${this.$root.$t('generic.saveFailure')} - ${
                error.Message
              }`,
              status: 'failure'
            });
          }
        });
    },
    deactivateAccount() {
      this.$deactivateAccount(this.userPassword)
        .then(() => this.$router.push({ name: 'logout' }))
        .catch(error => (this.deactivateAccountModal = false));
    },
    removeAccount() {
      this.$removeAccount().then(() => this.$router.push({ name: 'logout' }));
    }
  },
  computed: {
    ...mapGetters('currentuser', {
      $profile: CURRENT_USER_GETTERS.PROFILE,
      $user: CURRENT_USER_GETTERS.USER,
      $profileCopy: CURRENT_USER_GETTERS.PROFILE_COPY,
      $userCopy: CURRENT_USER_GETTERS.USER_COPY
    })
  }
};
</script>

<style lang="scss" scoped></style>
