<template>
  <info-row @click="gotoItemGraph()" v-if="item.displayValue !== undefined">
    <template v-slot:title>
      <span :class="{ pl: !useSmiley }">
        {{ $t(`constants.StatusReportTypes.${item.area}`) }}
      </span>
    </template>

    <template v-slot:meta>
      <span :class="{ pl: !useSmiley }">
        {{ item.displayValue }} {{ $t('generic.of') }} 10
      </span>
    </template>

    <template v-slot:intensity v-if="useSmiley">
      <div
        class="colored-background"
        :style="{
          'border-left': `5px solid ${item.color}`
        }"
      >
        <span class="small">
          <smiley
            :height="30"
            :width="30"
            :color="graphColors[item.value]"
            :number="item.value"
          ></smiley>
        </span>
      </div>
    </template>

    <template v-slot:intensity v-else>
      <div
        class="colored-background"
        :style="{ 'background-color': item.color, color }"
      >
        <span>{{ item.displayValue }}</span>
      </div>
    </template>
  </info-row>
</template>

<script>
import InfoRow from '@common/Components/InfoRow/InfoRow';
import tinycolor from 'tinycolor2';
import { StatusReportScaleColors } from '@common/constants';
import { mapGetters } from 'vuex';
import { STATUSREPORT_GETTERS } from '@/store/modules/statusreports';
import Smiley from '@common/Components/Shapes/Smiley.vue';
export default {
  components: {
    InfoRow,
    Smiley
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    useSmiley: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    gotoItemGraph() {
      this.$router.push({
        name: 'statusReportItemGraph',
        params: { statusItem: this.item.area }
      });
    },
    getColor(color) {
      color = tinycolor(color);
      return color.isDark() ? '#fff' : '#000';
    }
  },

  computed: {
    ...mapGetters('statusreports', {
      $areaGraphData: STATUSREPORT_GETTERS.AREA_GRAPH_DATA,
      $invertedTypes: STATUSREPORT_GETTERS.INVERTED_TYPES
    }),
    color() {
      const color = tinycolor(this.item.color);
      return color.isDark() ? '#fff' : '#000';
    },
    graphColors() {
      const isReversed = this.$invertedTypes.indexOf(this.statusItem) > -1;
      const colors = StatusReportScaleColors.slice();
      return isReversed ? colors.reverse() : colors;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';
@media only screen and (max-width: $breakpoint-phone) {
  .pl {
    padding-left: 10px;
  }
}
</style>
