import SymptomDefinitionModel from '@common/Models/SymptomDefinitionModel';

/**
 * Map remote SymptomDefinition to local
 * @param {object} dtoSymptomDefinition
 * @returns {SymptomDefinitionModel}
 */
export const SymptomDefinitionResponse = dtoSymptomDefinition => {
  return new SymptomDefinitionModel(dtoSymptomDefinition);
};

/**
 * Map remote SymptomDefinitions to local
 * @param {object[]} dtoSymptomDefinitions
 * @returns {SymptomDefinitionModel[]}
 */
export const SymptomDefinitionsResponse = dtoSymptomDefinitions => {
  return dtoSymptomDefinitions.map(dto => SymptomDefinitionResponse(dto));
};
