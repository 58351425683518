export const APPLICATION_MUTATIONS = {
  ADD_APPLICATION: 'ADD_APPLICATION',
  SET_APPLICATIONS: 'SET_APPLICATIONS',
  REMOVE_APPLICATION: 'REMOVE_APPLICATION'
};

export const APPLICATION_ACTIONS = {
  CREATE_APPLICATION: 'CREATE_APPLICATION',
  DELETE_APPLICATION: 'DELETE_APPLICATION',
  GET_APPLICATION: 'GET_APPLICATION',
  GET_APPLICATIONS: 'GET_APPLICATIONS',
  UPDATE_APPLICATION: 'UPDATE_APPLICATION'
};

export const APPLICATION_GETTERS = {
  APPLICATIONS: 'APPLICATIONS',
  FIND_APPLICATION: 'FIND_APPLICATION'
};
