<template>
  <modal
    :show="show"
    height="auto"
    :close-on-accept="false"
    @close="$emit('close')"
    :decline-text="declineText || $t('modals.symptomDetails.skip')"
    :accept-text="$t('modals.symptomDetails.save')"
    :accept-callback="() => this.save()"
    :accept-loading="saveLoading"
  >
    <div>
      <s-text-input
        v-model="editedSymptom.Name"
        :autofocus="true"
        :placeholder="$t('modals.symptomDetails.name')"
        @keyup.enter="save()"
        type="text"
        data-cy="symptomdetails-form-name"
      ></s-text-input>
      <errors :field="v$.editedSymptom.Name" />
    </div>
  </modal>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import SymptomModel from '@common/Models/Symptom/SymptomModel';
import { mapActions } from 'vuex';
import { SYMPTOM_ACTIONS } from '@/store/modules/symptoms';

export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  props: {
    show: Boolean,
    symptom: SymptomModel,
    declineText: {
      type: String
    }
  },
  data() {
    return {
      editedSymptom: new SymptomModel(this.symptom.__copy()),
      saveLoading: false
    };
  },
  mounted() {},
  validations: {
    editedSymptom: SymptomModel.validations
  },
  methods: {
    ...mapActions('symptoms', {
      $updateSymptom: SYMPTOM_ACTIONS.UPDATE_SYMPTOM
    }),
    save() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      if (this.editedSymptom.isFromRemote) {
        this.saveLoading = true;

        this.$updateSymptom(this.editedSymptom).finally(
          () => (this.saveLoading = false)
        );
      }

      this.$emit('close');
    }
  },
  computed: {},
  watch: {
    symptom(symptom) {
      this.editedSymptom = new SymptomModel(symptom.__copy());
    }
  }
};
</script>

<style></style>
