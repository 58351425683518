import { cleanObject } from '@common/Helpers/app';
import { createStore } from 'vuex';
import { busPlugin, debugPlugin, localstoragePlugin } from './plugins';
import { pusherPlugin } from './plugins/pusher';
import stores from './stores';

const debug = process.env.NODE_ENV !== 'production';

const store = createStore({
  plugins: [busPlugin, debugPlugin, localstoragePlugin, pusherPlugin],
  modules: cleanObject(stores),
  strict: debug
});

export default store;
