export const SYMPTOM_MUTATIONS = {
  SET_SYMPTOMS: 'SET_SYMPTOMS',
  ADD_NEW_SYMPTOM: 'ADD_NEW_SYMPTOM',
  SAVE_SYMPTOM_SUCCESS: 'SAVE_SYMPTOM_SUCCESS',
  SAVE_SYMPTOM_FAILURE: 'SAVE_SYMPTOM_FAILURE',
  REMOVE_SYMPTOM: 'REMOVE_SYMPTOM'
};

export const SYMPTOM_ACTIONS = {
  $PREINIT: '$PREINIT',
  GET_MY_SYMPTOMS: 'GET_MY_SYMPTOMS',
  GET_MY_SYMPTOM: 'GET_MY_SYMPTOM',
  UPDATE_SYMPTOM: 'UPDATE_SYMPTOM',
  CLOSE_SYMPTOM: 'CLOSE_SYMPTOM'
};

export const SYMPTOM_GETTERS = {
  SYMPTOMS: 'SYMPTOMS',
  SYMPTOMS_ALL: 'SYMPTOMS_ALL',
  CURRENT_SYMPTOMS: 'CURRENT_SYMPTOMS',
  GET_GROUP_SYMPTOM_REPORTS: 'GET_GROUP_SYMPTOM_REPORTS',
  ACTIVE_SYMPTOMS: 'ACTIVE_SYMPTOMS',
  FIND_SYMPTOM: 'FIND_SYMPTOM',
  SYMPTOM_GRAPH_DATA: 'SYMPTOM_GRAPH_DATA'
};
