export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    $checkRole(roleNeeded) {
      if (typeof roleNeeded === 'string') {
        roleNeeded = [roleNeeded];
      }
      return roleNeeded.includes(this.$_PERMISSIONS_userRole);
    },
    /**
     * @param {(String|String[])} permissionNeeded
     * @param {Number} method -1 = One or more roles match, 0 = old way (last perm in array is matched), 1 = All permissions must be present
     */
    $checkPermission(permissionNeeded, method = 0) {
      const userPermissions = this.$_PERMISSIONS_userPermissions;

      if (typeof permissionNeeded === 'string') {
        permissionNeeded = [permissionNeeded];
      }

      let permitted = false;

      if (method < 1) {
        permissionNeeded.forEach(permission => {
          if (method === -1 && permitted === true) {
            return;
          }
          permitted = userPermissions.includes(permission);
        });
      } else {
        const hasAllPermsNeeded =
          userPermissions.filter(x => permissionNeeded.includes(x)).length ===
          permissionNeeded.length;
        permitted = hasAllPermsNeeded;
      }

      return permitted;
    }
  },
  computed: {
    $_PERMISSIONS_user() {
      return this.$store.state.currentuser;
    },
    $_PERMISSIONS_userPermissions() {
      if (!this.$_PERMISSIONS_hasPermissions) {
        return [];
      }
      return this.$store.state.currentuser.user.Permissions;
    },
    $_PERMISSIONS_hasPermissions() {
      return (
        !!this.$_PERMISSIONS_user &&
        !!this.$_PERMISSIONS_user.user &&
        this.$_PERMISSIONS_user.user.Permissions
      );
    },

    /** Token */
    $_PERMISSIONS_authToken() {
      return this.$store.state.auth && this.$store.state.auth.tokenDetails
        ? this.$store.state.auth.tokenDetails
        : null;
    },
    $_PERMISSIONS_userRole() {
      if (!this.$_PERMISSIONS_authToken) {
        return null;
      }
      return this.$store.state.auth.activeRole;
    }
  }
};
