<template>
  <component :is="divOrLink" :to="link" class="block" v-if="report">
    <info-row :popup="showPopup">
      <template v-slot:image>
        <i class="mdi mdi-graphql"></i>
      </template>
      <template v-slot:title>
        <span>{{ $t('generic.StatusReport') }}</span>
      </template>

      <template v-slot:date>
        {{ symptoms }} {{ $tc('plural.symptom', symptoms) }} &bull;
        <created-date :report="report"></created-date>
      </template>

      <template v-slot:buttons>
        <i
          data-cy="statusreport-deletebutton"
          class="mdi mdi-trash-can-outline"
          @click.prevent="confirmRemove = true"
        ></i>
        <modal-confirm
          :show="confirmRemove"
          :accept-callback="() => removeReport()"
          :accept-text="$t('generic.deleteShort')"
          :show-extras="false"
          :decline-text="$t('generic.cancel')"
          @close="confirmRemove = false"
          >{{ $t('modals.confirmRemoveReport') }}</modal-confirm
        >
      </template>
    </info-row>
  </component>
</template>

<script>
import InfoRow from '@common/Components/InfoRow/InfoRow';
import { mapActions } from 'vuex';
import { STATUSREPORT_ACTIONS } from '@/store/modules/statusreports';
import StatusReportModel from '@common/Models/StatusReportModel';
import CreatedDate from '@/views/components/InfoRow/CreatedDate.vue';
import CardMixin from './mixins/CardMixin';

export default {
  mixins: [CardMixin],
  components: { CreatedDate, InfoRow },
  props: {
    report: {
      type: StatusReportModel,
      required: true
    }
  },
  data() {
    return {
      removeLoading: false
    };
  },
  methods: {
    ...mapActions('statusreports', {
      $removeReport: STATUSREPORT_ACTIONS.REMOVE_REPORT,
      $getMyStatusReports: STATUSREPORT_ACTIONS.GET_STATUS_REPORTS
    }),
    removeReport() {
      this.removeLoading = true;
      this.$removeReport(this.report)
        .catch(error => {
          this.$getMyStatusReports();
        })
        .finally(() => {
          this.removeLoading = false;
          this.showPopup = false;
        });
    }
  },
  computed: {
    link() {
      return {
        name: 'statusReportReportGraph',
        params: { statusreportid: this.report.Id }
      };
    },
    symptoms() {
      return this.report.Items.filter(i => i.Intensity > 0).length;
    }
  }
};
</script>

<style></style>
