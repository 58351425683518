export const ALERT_MUTATIONS = {
  SET_DISPLAYED: 'SET_DISPLAYED'
};

export const ALERT_ACTIONS = {
  $PREINIT: '$PREINIT',
  NEW_ALERT: 'NEW_ALERT',
  DISPLAY_NEW_SHAREREQUEST: 'DISPLAY_NEW_SHAREREQUEST'
};

export const ALERT_GETTERS = {};
