import ShareModel from '@common/Models/Share/ShareModel';

/**
 * Map remote share to local
 * @param {object} dtoShare
 * @returns {ShareModel}
 */
export const ShareResponse = dtoShare => {
  return new ShareModel(dtoShare);
};

/**
 * Map remote shares to local
 * @param {object[]} dtoShares
 * @returns {ShareModel}
 */
export const SharesResponse = dtoShares => {
  return dtoShares.map(dto => ShareResponse(dto));
};
