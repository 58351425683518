<template>
  <div class="settings__sessions black-text">
    <ul class="collection" v-if="$activeSessions.length">
      <session
        class="collection-item"
        :data="session"
        v-for="(session, index) in $activeSessions"
        :index="index"
        :data-session="index"
        :key="index"
      ></session>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  CURRENT_USER_ACTIONS,
  CURRENT_USER_GETTERS
} from '@/store/modules/currentuser';
import Session from './Session';
export default {
  components: { Session },
  mounted() {
    this.$getActiveSessions();
  },
  methods: {
    ...mapActions('currentuser', {
      $getActiveSessions: CURRENT_USER_ACTIONS.GET_ACTIVE_SESSIONS
    })
  },
  computed: {
    ...mapGetters('currentuser', {
      $activeSessions: CURRENT_USER_GETTERS.ACTIVE_SESSIONS
    })
  }
};
</script>

<style lang="css"></style>
