export const PROMEDIT_MUTATIONS = {
  ADD_IMPORTED_PROM: 'ADD_IMPORTED_PROM',
  SET_ACTIVE_PROM: 'SET_ACTIVE_PROM',
  TOGGLE_OPEN_ITEM: 'TOGGLE_OPEN_ITEM',
  UPDATE_META_PROPERTY: 'UPDATE_META_PROPERTY',
  UPDATE_STATUS_TYPES: 'UPDATE_STATUS_TYPES',
  ADD_QUESTION: 'ADD_QUESTION',
  REMOVE_QUESTION: 'REMOVE_QUESTION',
  UPDATE_QUESTION_PROPERTY: 'UPDATE_QUESTION_PROPERTY',
  ADD_INFO: 'ADD_INFO',
  REMOVE_INFO: 'REMOVE_INFO',
  UPDATE_INFO_PROPERTY: 'UPDATE_INFO_PROPERTY',
  UPDATE_ITEM_ORDER: 'UPDATE_ITEM_ORDER',
  ADD_ANSWER: 'ADD_ANSWER',
  REMOVE_ANSWER: 'REMOVE_ANSWER',
  UPDATE_ANSWER_PROPERTY: 'UPDATE_ANSWER_PROPERTY',
  UPDATE_ANSWER_ORDER: 'UPDATE_ANSWER_ORDER',
  COPY_ANSWERS: 'COPY_ANSWERS',
  UNDO: 'UNDO',
  SET_HISTORY_LENGTH: 'SET_HISTORY_LENGTH',
  ADD_CONDITIONALS: 'ADD_CONDITIONALS'
};

export const PROMEDIT_ACTIONS = {
  ADD_LANGUAGE_VERSION: 'ADD_LANGUAGE_VERSION',
  GET_ACTIVE_PROM: 'GET_ACTIVE_PROM',
  SAVE: 'SAVE',
  SAVE_META: 'SAVE_META',
  SAVE_LANGUAGE_VERSION: 'SAVE_LANGUAGE_VERSION',
  PASTE_ANSWERS: 'PASTE_ANSWERS',
  EXPORT: 'EXPORT',
  IMPORT: 'IMPORT'
};

export const PROMEDIT_GETTERS = {
  QUESTIONS: 'QUESTIONS',
  INFOS: 'INFOS',
  ITEMS: 'ITEMS',
  OPEN_ITEMS: 'OPEN_ITEMS',
  IS_ITEM_OPEN: 'IS_ITEM_OPEN',
  HAS_COPIED_ANSWERS: 'HAS_COPIED_ANSWERS',
  CAN_UNDO: 'CAN_UNDO',
  IS_META_LOCKED: 'IS_META_LOCKED'
};
