import { Bus } from '@common';

export const TFARequiredInterceptor = error => {
  if (error && error.response && error.response.status === 417) {
    TFARequired(3);
    setTimeout(() => {
      Bus.$emit('tfa-required', false);
    }, 500);
  }
  return Promise.reject(error);
};

/**
 * Trigger TFA login
 * @param {number} [level=0|1|2|3] 0 = off, 1 = closable, 2 = sticky, 3 = start at help
 */
export const TFARequired = (level = 1) => {
  console.error(
    '%cMUST SIGN IN WITH 2 FACTOR AUTHENTICATION',
    'color: RED; font-size: x-large'
  );
  Bus.$emit('tfa-required', level);
};
