import CameraControls from 'camera-controls';
const THREE = require('three');

export default {
  /** @type {HTMLElement} */
  container: null,
  /** @type {THREE.WebGLRenderer} */
  renderer: null,
  /** @type {THREE.PerspectiveCamera} */
  camera: null,
  /** @type {CameraControls} */
  controls: null,
  /** @type {THREE.Scene} */
  scene: null,
  /** @type {THREE.Object3D} */
  object: null,
  /** @type {THREE.Clock} */
  clock: null,
  /** @type {THREE.Box3} */
  boundingBox: null,
  /** @type {THREE.Raycaster} */
  raycaster: null,
  constants: {
    CAMERA_HEIGHT: 12,
    CAMERA_ZOOM: 35
  },
  /** @type {THREE.PerspectiveCamera} */
  initialCam: {},
  /** @type {number} */
  LEFT_MOUSEBUTTON: 0,
  /** @type {number} */
  RIGHT_MOUSEBUTTON: 2
};
