<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    :width="width"
    :height="height"
    viewBox="0 0 5.2916662 5.2916665"
    version="1.1"
    id="svg3719"
    sodipodi:docname="smiley.svg"
    inkscape:version="0.92.3 (2405546, 2018-03-11)"
  >
    <defs id="defs3713">
      <linearGradient inkscape:collect="always" id="linearGradient5900">
        <stop
          id="stop5898"
          offset="0"
          style="stop-color: #ffffff; stop-opacity: 1"
        />
        <stop
          id="stop5896"
          offset="1"
          style="stop-color: #999999; stop-opacity: 1"
        />
      </linearGradient>
      <radialGradient
        inkscape:collect="always"
        xlink:href="#linearGradient5900"
        id="radialGradient5902"
        cx="2.6458328"
        cy="294.35416"
        fx="2.6458328"
        fy="294.35416"
        r="2.6458333"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(2.2436865,0.0168693,-0.01249965,1.6625039,0.38873656,-195.05541)"
      />
    </defs>
    <sodipodi:namedview
      id="base"
      pagecolor="#ffffff"
      border-color="#666666"
      borderopacity="1.0"
      inkscape:pageopacity="0.0"
      inkscape:pageshadow="2"
      inkscape:zoom="16"
      inkscape:cx="10.865566"
      inkscape:cy="10.139351"
      inkscape:document-units="mm"
      inkscape:current-layer="layer1"
      showgrid="false"
      units="px"
      inkscape:window-width="1920"
      inkscape:window-height="1017"
      inkscape:window-x="1912"
      inkscape:window-y="-8"
      inkscape:window-maximized="1"
    />
    <metadata id="metadata3716">
      <rdf:RDF>
        <cc:Work rdf:about>
          <dc:format>image/svg+xml</dc:format>
          <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          <dc:title />
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <g
      inkscape:label="Layer 1"
      inkscape:groupmode="layer"
      id="layer1"
      transform="translate(0,-291.70833)"
    >
      <circle
        id="path3725"
        cx="2.6458328"
        cy="294.35416"
        style="stroke-width: 0.24043523; fill: url(#radialGradient5902)"
        r="2.6458333"
      />
      <ellipse
        style="
          opacity: 1;
          fill: #000;
          fill-opacity: 0.67159268;
          stroke: none;
          stroke-width: 0.25108957;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 0;
        "
        id="path5796"
        cx="1.6136397"
        cy="293.50961"
        rx="0.38587037"
        ry="0.53203338"
      />
      <ellipse
        ry="0.53203338"
        rx="0.38587037"
        cy="293.50961"
        cx="3.5429916"
        id="ellipse5813"
        style="
          opacity: 1;
          fill: #000;
          fill-opacity: 0.67159268;
          stroke: none;
          stroke-width: 0.25108957;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 0;
        "
      />

      <path
        v-if="score === 0"
        style="
          opacity: 1;
          fill: #000;
          fill-opacity: 0.67058825;
          stroke: none;
          stroke-width: 0.64104176;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 0.67058824;
        "
        id="path5986"
        sodipodi:type="arc"
        sodipodi:cx="2.6660135"
        sodipodi:cy="-295.93591"
        sodipodi:rx="1.5434815"
        sodipodi:ry="1.3213137"
        sodipodi:start="0"
        sodipodi:end="3.1415927"
        sodipodi:open="true"
        d="m 4.2036484,-295.76053 a 1.5434815,1.3213137 0 0 1 -0.7717407,1.14429 1.5434815,1.3213137 0 0 1 -1.5434815,0 1.5434815,1.3213137 0 0 1 -0.7717407,-1.14429"
        transform="scale(1,-1)"
      />

      <path
        v-if="score === 1"
        style="
          opacity: 1;
          fill: none;
          stroke: #000;
          stroke-width: 0.5291667;
          stroke-linecap: round;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 0.67058825;
        "
        d="m 1.372526,295.6829 c 1.1021128,-1.32176 1.8862638,-0.85651 2.596224,0"
        id="path5887"
        inkscape:connector-curvature="0"
      />
      <path
        v-if="score === 2"
        style="
          opacity: 1;
          fill: none;
          stroke: #000;
          stroke-width: 0.5291667;
          stroke-linecap: round;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 0.67058825;
        "
        d="m 1.372526,295.39424 c 0.9610752,-0.67299 1.8112571,-0.51148 2.596224,0"
        id="path5887"
        inkscape:connector-curvature="0"
      />
      <path
        v-if="score === 3"
        style="
          opacity: 1;
          fill: none;
          stroke: #000;
          stroke-width: 0.5291667;
          stroke-linecap: round;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 0.67058825;
        "
        d="m 1.384219,295.39501 c 0.8897191,-0.38757 1.7504995,-0.26845 2.596224,0"
        id="path5887"
        inkscape:connector-curvature="0"
      />
      <path
        v-if="score === 4"
        style="
          opacity: 1;
          fill: none;
          stroke: #000;
          stroke-width: 0.5291667;
          stroke-linecap: round;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 0.67058825;
        "
        d="m 1.3807942,295.4696 c 0.8897191,-0.17018 1.7504995,-0.13778 2.596224,0"
        id="path5887"
        inkscape:connector-curvature="0"
      />
      <path
        v-if="score === 5"
        style="
          opacity: 1;
          fill: none;
          stroke: #000;
          stroke-width: 0.5291667;
          stroke-linecap: round;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 0.67058825;
        "
        d="M 1.372526,295.42207 H 3.96875"
        id="path5887"
        inkscape:connector-curvature="0"
      />
      <path
        v-if="score === 6"
        style="
          opacity: 1;
          fill: none;
          stroke: #000;
          stroke-width: 0.5291667;
          stroke-linecap: round;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 0.67058825;
        "
        d="m 1.372526,295.30637 c 0.8897191,0.17018 1.7504995,0.13778 2.596224,0"
        id="path5887"
        inkscape:connector-curvature="0"
      />
      <path
        v-if="score === 7"
        style="
          opacity: 1;
          fill: none;
          stroke: #000;
          stroke-width: 0.5291667;
          stroke-linecap: round;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 0.67058825;
        "
        d="m 1.3807942,295.53927 c 0.8897191,0.38757 1.7504995,0.26845 2.596224,0"
        id="path5887"
        inkscape:connector-curvature="0"
      />
      <path
        v-if="score === 8"
        style="
          opacity: 1;
          fill: none;
          stroke: #000;
          stroke-width: 0.5291667;
          stroke-linecap: round;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 0.67058825;
        "
        d="m 1.3726975,295.38934 c 0.9610752,0.67299 1.8112571,0.51148 2.596224,0"
        id="path5887"
        inkscape:connector-curvature="0"
      />
      <path
        v-if="score === 9"
        style="
          opacity: 1;
          fill: none;
          stroke: #000;
          stroke-width: 0.5291667;
          stroke-linecap: round;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 0.67058825;
        "
        d="m 1.372526,295.12559 c 1.1021128,1.32176 1.8862638,0.85651 2.596224,0"
        id="path5887"
        inkscape:connector-curvature="0"
      />

      <path
        v-if="score === 10"
        style="
          opacity: 1;
          fill: #000;
          fill-opacity: 0.67159268;
          stroke: none;
          stroke-width: 0.64104176;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 0.67058824;
        "
        id="path5986"
        sodipodi:type="arc"
        sodipodi:cx="2.6893995"
        sodipodi:cy="294.88354"
        sodipodi:rx="1.5434815"
        sodipodi:ry="1.3213137"
        sodipodi:start="0"
        sodipodi:end="3.1415927"
        sodipodi:open="true"
        d="m 4.2328809,294.88354 a 1.5434815,1.3213137 0 0 1 -0.7717407,1.1443 1.5434815,1.3213137 0 0 1 -1.5434815,0 1.5434815,1.3213137 0 0 1 -0.7717407,-1.1443"
      />

      <circle
        r="2.6458333"
        :fill="color"
        style="fill-opacity: 0.5"
        cy="294.35416"
        cx="2.6458328"
        id="circle5949"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000'
    },
    height: {
      type: Number,
      default: 35
    },
    width: {
      type: Number,
      default: 35
    },
    number: {
      type: Number,
      required: true
    },
    reverse: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    score() {
      let score = this.number;
      if (!this.reverse) {
        score = 10 - this.number;
      }
      return score;
    }
  }
};
</script>
