<template>
  <div class="card-container" :class="{ 'full-width': fullWidth }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    fullWidth: Boolean
  }
};
</script>

<style lang="scss">
@import '@sass/_variables.scss';
.card-container {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  &.full-width {
    .card {
      max-width: none;
      min-width: 100%;
    }
  }

  @media only screen and (min-width: $breakpoint-phone) {
    .card:not(:only-of-type) {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
</style>

<style lang="scss">
.card-container.full-width .card {
  .card__content {
    margin: 0 auto;
    width: 100%;
    background-color: #fbfbfb;
    border-radius: 15px;
  }
  .card__bottom {
    // align-items: center;
    background-color: transparent;
    justify-content: center;
    // margin: 0 auto;
    flex-direction: column;
    width: 100%;
    margin: auto;

    .bottom-content {
      min-height: unset;
    }
  }
}
</style>
