<template>
  <div class="mb10">
    <card-container
      :full-width="true"
      v-for="(symptomReportSymptom, index) in sortedSymptomReportSymptoms"
      :key="symptomReportSymptom.SymptomId"
    >
      <card-symptom-update-vue
        :model="report.Model"
        :status-report-id="statusReportId"
        :symptom-report-id="report.Id"
        :symptom-report-symptom="symptomReportSymptom"
        :index="index"
        :disabled="disabled"
        @closed-symptom="symptom => removeSymptom(symptom)"
      ></card-symptom-update-vue>
    </card-container>
    <flex-grid v-if="!report._locked" align-items="center">
      <div>
        <s-button @click="selectSymptom()" class="button--border-blue">
          {{
            $t(
              report.Symptoms.length
                ? 'buttons.addMoreSymptoms'
                : 'buttons.addSymptom'
            )
          }}
        </s-button>
      </div>
    </flex-grid>
  </div>
</template>

<script>
import { orderByKeys } from '@common/Helpers/sorters';
import CardSymptomUpdateVue from '@/views/components/Card/CardSymptomUpdateNew.vue';
import FlexGrid from '@common/Components/Grids/FlexGrid.vue';

export default {
  components: {
    CardSymptomUpdateVue,
    FlexGrid
  },
  props: {
    statusReportId: String,
    editedReport: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      report: this.editedReport
    };
  },
  mounted() {},
  watch: {
    editedReport(value) {
      this.report = value;
    }
  },
  methods: {
    saveTempReport() {
      this.$emit('save-temp', true);
    },
    selectSymptom() {
      this.$router.push({
        name: 'symptomSelect',
        query: {
          symptomReportId: this.symptomReportId
        }
      });
    },
    addSymptom() {
      this.$router.push({
        name: 'symptomReportDraw',
        query: {
          symptomReportId: this.symptomReportId
        }
      });
    },
    removeSymptom(symptom) {
      const index = this.report.Symptoms.findIndex(
        s => s.SymptomId === symptom.Id
      );
      if (index > -1) {
        this.report.Symptoms.splice(index, 1);
      }
      this.$emit('closed-symptom');
    }
  },
  computed: {
    sortedSymptomReportSymptoms() {
      return orderByKeys(
        this.report.Symptoms,
        [srs => srs.Symptom.CreatedDate, srs => srs.Symptom.Name],
        ['desc', 'asc']
      );
    }
  }
};
</script>

<style></style>
