import SymptomModel from '@common/Models/Symptom/SymptomModel';
import LanguageModel from '@common/Models/LanguageModel';
import PatientModel from '@common/Models/PatientModel';
import StatusReportModel from '@common/Models/StatusReportModel';
import StatusTypeModel from '@common/Models/StatusTypeModel';
import SymptomReportModel from '@common/Models/SymptomReport/SymptomReportModel';
import UserModel from '@common/Models/UserModel';

/**
 * @param {EnvironmentViewDto} environment
 * @returns
 */
export const EnvironmentResponse = environment => {
  environment.Languages = environment.Languages.map(
    lang => new LanguageModel(lang)
  );
  environment.StatusTypes = environment.StatusTypes.map(
    type => new StatusTypeModel(type)
  );

  return environment;
};

/**
 * Map remote profile view objects to local
 * @param {ProfileViewDto} profile
 * @returns
 */
export const ProfileResponse = profile => {
  profile.Patient = new PatientModel(profile.Patient);
  profile.User = new UserModel(profile.User);
  return profile;
};

/**
 * @typedef {Object} EnvironmentViewDto
 * @property {Object[]} Constants
 * @property {LanguageModel[]} Languages
 * @property {StatusTypeModel[]} StatusTypes
 */

/**
 * @typedef {Object} ProfileViewDto
 * @property {PatientModel} Patient
 * @property {UserModel} User
 */
