export class NewShareModel {
  constructor({
    SymptomReports = true,
    PromReports = true,
    StatusReports = true,
    Notes = true,
    ShareWithPersonnummer = null,
    ShareWithEmail = null,
    ShareWithUnit = null,
    PatientId = null
  }) {
    this.SymptomReports = SymptomReports;
    this.PromReports = PromReports;
    this.StatusReports = StatusReports;
    this.Notes = Notes;
    this.ShareWithPersonnummer = ShareWithPersonnummer;
    this.ShareWithEmail = ShareWithEmail;
    this.ShareWithUnit = ShareWithUnit;
    this.PatientId = PatientId;
  }
}

export default NewShareModel;
