<template>
  <div class="shares">
    <div class="container">
      <h4 class="header ml20 hidden-mobile">
        {{ $tc('plural.share', 2) }}
      </h4>
      <create-share v-if="createShareActivated"></create-share>

      <div class="mt10" v-if="$REQUESTS && $REQUESTS.length">
        <h4 class="sub-header" v-text="$tc('plural.shareRequest', 2)"></h4>
        <card-container
          :full-width="true"
          v-for="(share, index) in $REQUESTS"
          :key="index"
          data-cy="shares-sharerequest"
        >
          <template>
            <card-share-request-vue :share="share"></card-share-request-vue>
          </template>
        </card-container>
      </div>

      <div class="mt10" v-if="$SHARES && $SHARES.length">
        <h4
          class="sub-header"
          v-text="$tc('plural.share', 2)"
          v-if="createShareActivated"
        ></h4>
        <template v-if="$SHARES.length">
          <card-container
            :full-width="true"
            v-for="(share, index) in $SHARES"
            :key="index"
            data-cy="shares-share"
          >
            <template>
              <card-share-vue :share="share"></card-share-vue>
            </template>
          </card-container>
        </template>
        <span v-else v-text="$t('views.shares.noSharesFound')"></span>
      </div>
      <div v-else>
        {{ $t('views.shares.noSharesFound') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SHARE_ACTIONS, SHARE_GETTERS } from '@/store/modules/shares';
import CreateShare from '@/views/Share/components/CreateShare.vue';
import CardShareVue from '../components/Card/CardShare.vue';
import CardShareRequestVue from '../components/Card/CardShareRequest.vue';

export default {
  components: {
    CreateShare,
    CardShareVue,
    CardShareRequestVue
  },
  data() {
    return {
      createShareActivated: false
    };
  },
  mounted() {
    if (!this.$SHARES.length) {
      this.$GETSHARES();
    }
  },
  methods: {
    ...mapActions('shares', {
      $GETSHARES: SHARE_ACTIONS.GET_SHARES
    })
  },
  computed: {
    ...mapGetters('shares', {
      $SHARES: SHARE_GETTERS.SHARES,
      $REQUESTS: SHARE_GETTERS.REQUESTS
    })
  }
};
</script>

<style></style>
