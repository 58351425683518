<template>
  <div class="scale-container clearfix" ref="scale">
    <div :id="`scale-${index}`" class="scale__anchor" />

    <div
      class="header-container"
      :class="{ pointer: gotHeaderClick }"
      @click="clickedHeader"
    >
      <div class="scale__title" v-html="title"></div>
      <div class="scale__headers clearfix" v-if="!displayNumbers">
        <div
          class="left"
          v-if="anchorLeft"
          v-text="anchorLeft ? anchorLeft : $t('generic.good')"
        ></div>
        <div class="middle">
          <slot></slot>
        </div>
        <div
          class="right"
          v-if="anchorRight"
          v-text="anchorRight ? anchorRight : $t('generic.bad')"
        ></div>
      </div>
    </div>
    <div
      class="scale range-field"
      :class="[
        { unset: indicateUnset && unset },
        { 'scale--smiley': scaleStyle === styles.SMILEYS_STYLE },
        { 'scale--numbers': scaleStyle === styles.NUMBERS_STYLE }
      ]"
    >
      <ul
        :data-cy="
          this.reverse
            ? 'statusreport-question-reverse'
            : 'statusreport-question-standard'
        "
        :class="{ reverse: reverse }"
      >
        <li
          v-for="number in 11"
          :key="number"
          :data-number="number - 1"
          @click="e => updateValue(e, number - 1)"
          :data-reverse="reverse"
          :data-cy="`statusreport-answer-${number - 1}`"
          :class="{
            selected: chosenValue === number - 1,
            disabled: chosenValue === number - 1 && disabled
          }"
        >
          <div class="noselect">
            <span
              :ref="`number${number - 1}`"
              :style="{
                visibility:
                  scaleStyle === styles.NUMBERS_STYLE ? 'visible' : 'hidden'
              }"
              >{{ number - 1 }}</span
            >
          </div>
        </li>
      </ul>
      <div
        :data-reverse="reverse"
        :class="{
          'scale__marker--unchanged': isUnchanged
        }"
        class="scale__marker"
        :style="{ left: `${markerX}px` }"
      >
        <smiley
          :color="colors[colorValue]"
          :number="chosenValue || 0"
          :reverse="reverse"
          v-if="scaleStyle === styles.SMILEYS_STYLE && chosenValue"
        ></smiley>
      </div>
    </div>
  </div>
</template>

<script>
import { hexToRgba } from '@common/Helpers/color';
import Smiley from '@common/Components/Shapes/Smiley.vue';
import { StatusReportScaleColors } from '@common/constants';
import { APP_VERSION_OPTIONS } from '@common/constants';

export default {
  components: { Smiley },
  props: {
    title: { type: String },
    headerClick: { type: Function },
    item: { type: Object, required: true },
    itemKey: { type: String, default: 'Intensity' },
    max: { type: Number, default: 10 },
    anchorLeft: {
      type: String,
      default: null
    },
    anchorRight: {
      type: String,
      default: null
    },
    reverse: Boolean,
    displayNumbers: {
      type: Boolean,
      default: false
    },
    scaleStyle: {
      type: String,
      default: APP_VERSION_OPTIONS.StatusReportVersions.NUMBERS_STYLE
    },
    index: Number,
    indicateUnset: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chosenValue: null,
      colors: StatusReportScaleColors,
      markerX: 0
    };
  },
  mounted() {
    if (this.item._removed) {
      if (this.reverse) {
        this.chosenValue = 10 - this.item.Intensity;
      } else {
        this.chosenValue = this.item.Intensity;
      }
      this.setMarkerX(this.$refs[`number${this.chosenValue}`]);
    } else if (
      this.item._lastValue !== undefined &&
      !isNaN(this.item._lastValue)
    ) {
      if (this.reverse) {
        this.chosenValue = 10 - this.item._lastValue;
      } else {
        this.chosenValue = this.item._lastValue;
      }
      this.setMarkerX(this.$refs[`number${this.chosenValue}`]);
    }
  },
  methods: {
    updateValue(e, value) {
      if (this.disabled) {
        return;
      }

      if (value === this.chosenValue && this.isUnchanged !== true) {
        this.reset();
        this.setMarkerX(this.$refs[`number${this.chosenValue}`]);
        return;
      }
      this.chosenValue = value;

      if (this.reverse === true) {
        value = 10 - value;
      }
      this.$emit('update:value', value);
      this.setMarkerX(e);
    },
    reset() {
      const value = this.item._lastValue;
      if (this.reverse === true) {
        this.chosenValue = 10 - value;
      } else {
        this.chosenValue = value;
      }
      this.$emit('update:value', value);
      this.item._changed = false; //eslint-disable-line
      this.item._modified = false; //eslint-disable-line
    },
    setMarkerX(e) {
      if (this.scaleStyle !== this.styles.SMILEYS_STYLE) {
        return;
      }
      if (e.length) {
        this.markerX =
          e[0].getBoundingClientRect().left - this.$refs.scale.offsetLeft - 21;
      } else {
        this.markerX = e.pageX - (this.$refs.scale.offsetLeft + 25);
      }
    },
    hexToRgba() {
      return hexToRgba(...arguments);
    },
    clickedHeader() {
      if (this.headerClick) {
        this.headerClick();
      }
    }
  },
  computed: {
    unset() {
      if (this.item._modelName === 'Symptom') {
        return !this.item._modified;
      }
      return this.item.hasOwnProperty('_changed') ? !this.item._changed : false;
    },
    colorValue() {
      const chosenValue = this.chosenValue || 0;
      return this.reverse ? 10 - chosenValue : chosenValue;
    },
    styles() {
      return APP_VERSION_OPTIONS.StatusReportVersions;
    },
    isUnchanged() {
      if (this.item._modelName === 'Symptom') {
        return !this.item._modified;
      }
      return !this.item._changed;
    },
    gotHeaderClick() {
      return !!this.headerClick;
    }
  }
};
</script>

<style lang="scss"></style>

<style lang="scss" scoped>
@import '@sass/_variables.scss';

.scale-container {
  border-radius: $cardBorderRadius;
  background-color: white;

  .scale__title {
    font-weight: 300;
    font-size: 1.4em;
    padding: 20px 20px 0 20px;
    font-style: italic;
    max-width: calc(100% - 130px);

    .mdi {
      font-size: inherit;
      line-height: inherit;
    }
  }

  .scale__headers {
    color: $symptoms-orange;
    margin: 20px 0 10px 0;
    padding: 0 20px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    justify-content: space-between;
    align-items: flex-end;
    .middle {
      white-space: nowrap;
      text-align: center;
    }
    .right {
      text-align: right;
    }
    @media only screen and (max-width: $breakpoint-phone) {
      margin: 10px 0 5px 0;
      // padding: 0 10px;
      .left,
      .right {
        font-size: 13px;
      }
    }
  }
}

.scale__anchor {
  position: absolute;
  top: -210px;
  left: 0;
}

.scale {
  &--smiley {
    ul {
      li {
        height: 20px !important;
        &:after {
          top: 45% !important;
        }
      }
    }

    .scale__headers {
      margin-top: 30px !important;
    }
  }
  &--numbers {
    padding: 0 20px 20px 20px;
    @media only screen and (max-width: $breakpoint-phone-small) {
      // padding: 0 5px 20px 5px;
    }
    ul {
      li {
        display: flex;
        justify-content: center;
        // height: 70px !important;
        span {
          cursor: pointer;
          height: 30px;
          width: 30px;
          display: flex;
          text-align: center;
          flex-direction: column;
          font-size: 0.8em;
          justify-content: center;
          border-radius: 50%;

          @media only screen and (max-width: $breakpoint-phone-small) {
            width: 22px;
            height: 22px;
          }

          @media only screen and (max-width: $breakpoint-phone-medium) {
            width: 22px;
            height: 22px;
          }
        }
        div {
          position: relative !important;
          top: unset !important;
        }
        &:after {
          border-top: 0 !important;
        }
        &.selected {
          span {
            background-color: $symptoms-blue;
            color: white;
          }
        }
        &.disabled {
          span {
            background-color: $button-disabled-color;
          }
        }
      }
    }
    &.unset {
      li.selected span {
        border: 1px solid $symptoms-blue;
        color: black;
        font-weight: 700;
        font-size: large;
        background-color: transparent;
      }
    }
  }
  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 10px 0 0 0;
    margin: 0;

    &.reverse {
      direction: rtl;

      li {
        &[data-number='0'] {
          text-align: right;
          .scale__marker {
            left: 22px;
          }
        }
        &[data-number='10'] {
          text-align: left;
          .scale__marker {
            right: -5px !important;
          }
        }
      }
    }

    li {
      // background-color: #ccc;
      width: 100%;
      // height: 60px;
      position: relative;
      text-align: center;
      &[data-number='0'] {
        width: 47%;
        text-align: left;

        .scale__marker {
          left: -4px;
        }
      }
      &[data-number='10'] {
        width: 47%;
        text-align: right;

        .scale__marker {
          right: 0px;
        }
      }

      @media only screen and (max-width: $breakpoint-phone-small) {
        &[data-number='0'] {
          width: 13%;
        }
        &[data-number='10'] {
          width: 13%;
        }
      }

      div {
        color: #888;
        position: absolute;
        top: 30px;
        left: 0;
        right: 0;
      }
      &:after {
        content: ' ';
        border-top: 1px solid #ccc;
        position: absolute;
        top: 25%;
        left: 0;
        right: 0;
      }
    }
  }
  .scale__marker {
    position: absolute;
    top: 2px;
    z-index: 11;

    &--unchanged {
      color: #ccc;
    }
  }
}
</style>
