import BaseService from '../BaseService';
import NoteModel from '@common/Models/NoteModel';
import { NoteResponse, NotesResponse } from './mappers/NoteResponses';

class NoteService extends BaseService {
  /**
   * Get all notes -- backend
   * @returns {Promise<NoteModel[]>}
   * @memberof NoteService
   */
  getMyNotes(page, take) {
    return this.backend(`my/notes?page=${page}&take=${take}`).then(response =>
      NotesResponse(response.data)
    );
  }

  /**
   * Find note -- backend
   * @returns {Promise<NoteModel>}
   * @memberof NoteService
   */
  findMyNote(guid) {
    return this.backend(`my/notes/${guid}`).then(response =>
      NoteResponse(response.data)
    );
  }

  /**
   * Create note -- backend
   * @param {NoteModel} note
   * @returns {Promise<NoteModel>}
   * @memberof NoteService
   */
  addMyNote(note) {
    return this.backend('post', 'my/notes', note).then(response =>
      NoteResponse(response.data)
    );
  }

  /**
   * Update note -- backend
   * @param {NoteModel} note
   * @returns {Promise<NoteModel>}
   * @memberof NoteService
   */
  updateNote(note) {
    return this.backend('put', `my/notes/${note.Id}`, note).then(response =>
      NoteResponse(response.data)
    );
  }

  /**
   * Delete current user's note -- backend
   * @param {string} guid Note id
   * @returns {Promise}
   * @memberof NoteService
   */
  deleteMyNote(guid) {
    return this.backend('post', `my/notes/${guid}/delete`);
  }

  /**
   * Restore current user's deleted note -- backend
   * @param {string} guid Note id
   * @returns {Promise}
   * @memberof NoteService
   */
  restoreMyNote(guid) {
    return this.backend('post', `my/notes/${guid}/restore`);
  }

  /**
   * Purge current user's deleted note -- backend
   * @param {string} guid Note id
   * @returns {Promise}
   * @memberof NoteService
   */
  purgeMyNote(guid) {
    return this.backend('delete', `my/notes/${guid}`);
  }

  /**
   * Get notes by patient -- backend
   * @param {string} patientId
   * @returns {Promise<NoteModel[]>}
   * @memberof NoteService
   */
  getNotes(patientId) {
    return this.backend(`patients/${patientId}/notes`).then(response =>
      NotesResponse(response.data)
    );
  }

  /**
   * Find note -- backend
   * @param {string} guid
   * @returns {Promise<NoteModel>}
   * @memberof NoteService
   */
  getNote(guid) {
    return this.backend(`notes/${guid}`).then(response =>
      NoteResponse(response.data)
    );
  }

  /**
   * Fetch prom reports export data by group -- backend
   * @returns {Promise<NoteModel[]>}
   * @memberof NoteService
   */
  getNotesExportDataByGroup(requiredContracts) {
    return this.backend('post', 'admin/notes/export', requiredContracts).then(
      response => response.data
    );
  }
}

export default new NoteService();
