import { createApp } from 'vue';
import App from './App.vue';
import { sync } from 'vuex-router-sync';
import router from './router';
import axios from 'axios';
import { Bus } from '@common';
import { i18n } from '@common/Helpers/language';

import { registerComponents } from './components';
import { registerDirectives } from './directives';
import { registerFilters } from './filters';
import { registerMixins } from './mixins';

import store from './store';

import { getMobileOperatingSystem } from '@common/Helpers/mobile';

import { ErrorHandler } from '@common/Handlers/ErrorHandler';
import { WarningHandler } from '@common/Handlers/WarningHandler';

require('../sass/app.scss');
require('@common/extensions');

require('@materializecss/materialize/dist/js/materialize.js');

window.$bus = Bus;
window.axios = axios;

const app = createApp(App);

app.config.globalProperties.$bus = Bus;

store.registerModule(
  'quincy',
  require('@common/Modules/quincy/src/store/quincy').default
);

app.config.globalProperties.$debug = (...text) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('debug:', ...text);
  }
};

app.config.performance = true;
app.config.errorHandler = ErrorHandler;
app.config.warnHandler = WarningHandler;

window.Device = getMobileOperatingSystem();

sync(store, router);

registerComponents(app);
registerDirectives(app);
registerFilters(app);
registerMixins(app);

app.use(i18n).use(router).use(store).mount('#app');
