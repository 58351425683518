import { StatusReportAreaColors } from '@/constants';
import { APP_GETTERS } from '@/store/modules/app';
import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser';
import { PROMREPORT_GETTERS } from '@/store/modules/promreports';
import { PROM_GETTERS } from '@/store/modules/proms';
import { sortByCreatedDate, sortByValueKey } from '@common/Helpers/sorters';
import storage from '@common/Helpers/storage';
import FollowupModel from '@common/Models/FollowupModel';
import PromSuggestionModel from '@common/Models/PromSuggestionModel';
import StatusReportModel from '@common/Models/StatusReportModel';
import StatusReportItemModel from '@common/Models/StatusReportItemModel';
import PromSuggestionService from '@common/Services/PromSuggestions/PromSuggestionService';
import StatusReportService from '@common/Services/StatusReports/StatusReportService';
import { StatusReportResponse } from '@common/Services/StatusReports/mappers/StatusReportResponses';
import moment from 'moment';
import {
  STATUSREPORT_ACTIONS,
  STATUSREPORT_GETTERS,
  STATUSREPORT_MUTATIONS
} from './definitions';

class State {
  constructor() {
    /** @type {StatusReportModel[]} */
    this.list = [];
    this.latest = '';
    /** @type {FollowupModel[]} */
    this.followups = [];
    /** @type {StatusReportItemModel[]} */
    this.summary = [];
    /** @type {PromSuggestionModel[]} */
    this.suggestions = [];
    this.invertedTypes = [
      'wellbeing',
      'qualityoflife',
      'physicalfunctioning',
      'sexualfunctioning',
      'appetite'
    ];
    this.ignoreInGraph = ['wellbeing', 'qualityoflife'];
    this.hasReportedStatus = false;
    /** @type {StatusReportModel} */
    this.tempReport = null;
  }
}

const store = {
  namespaced: true,
  state: new State(),

  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  /**
   * Set reports
   * @param {StatusReportModel[]} reports
   */
  [STATUSREPORT_MUTATIONS.SET_REPORTS](state, reports) {
    state.list = reports;
  },

  /**
   * Add report
   * @param {StatusReportModel} report
   */
  [STATUSREPORT_MUTATIONS.ADD_REPORT](state, report) {
    const exists = state.list.findIndex(r => r.Id === report.Id);
    if (exists === -1) {
      state.list.push(report);
    } else {
      state.list[exists] = report;
    }

    if (!report.isFromRemote) {
      storage.setEncrypted(`statusreport/${report.Id}`, report);
    }
  },

  /**
   * Add summary
   * @param {StatusReportItemModel[]} summary
   */
  [STATUSREPORT_MUTATIONS.ADD_SUMMARY](state, summary) {
    state.summary = summary;
  },

  /**
   * Save was successful
   * @param {StatusReportModel} report
   */
  [STATUSREPORT_MUTATIONS.SAVE_SUCCESSFUL](state, report) {
    storage.remove(`statusreport/${report.Id}`, report);
  },

  /**
   * Save failed
   * @param {StatusReportModel} report
   */
  [STATUSREPORT_MUTATIONS.SAVE_FAILED](state, report) {
    // storage.setEncrypted(`statusreport/${report.Id}`, report);
  },

  /**
   * Remove StatusReport
   * @param {StatusReportModel} report
   */
  [STATUSREPORT_MUTATIONS.REMOVE_REPORT](state, report) {
    const index = state.list.findIndex(r => r.Id === report.Id);

    if (index === -1) {
      return;
    }

    state.list.splice(index, 1);
    storage.remove(`statusreport/${report.Id}`);
  },

  /**
   * Set latest report id
   * @param {string} reportid StatusReport Id
   */
  [STATUSREPORT_MUTATIONS.SET_LATEST_REPORT](state, reportid) {
    state.latest = reportid;
  },

  /**
   * Set followup actions
   * @param {FollowupModel[]} followups
   */
  [STATUSREPORT_MUTATIONS.SET_POTENTIAL_FOLLOWUPS](state, followups) {
    state.followups = followups;
  },

  /** Clear followup actions */
  [STATUSREPORT_MUTATIONS.CLEAR_FOLLOWUPS](state) {
    state.followups = [];
  },

  /**
   * Remove specified followup
   * @param {string} id Followup Id
   */
  [STATUSREPORT_MUTATIONS.COMPLETE_FOLLOWUP](state, id) {
    const followupIndex = state.followups.findIndex(f => f.Id === id);
    if (followupIndex === -1) {
      return;
    }
    state.followups.splice(followupIndex, 1);
  },

  /**
   * Set PromSuggestions
   * @param {PromSuggestionModel[]} suggestions
   */
  [STATUSREPORT_MUTATIONS.SET_PROM_SUGGESTIONS](state, suggestions) {
    state.suggestions = suggestions;
  },

  /**
   * Add PromSuggestion
   * @param {PromSuggestionModel} suggestion
   */
  [STATUSREPORT_MUTATIONS.ADD_PROM_SUGGESTION](state, suggestion) {
    const exists = state.suggestions.find(s => s.PromId === suggestion.PromId);
    if (!exists) {
      state.suggestions.push(suggestion);
    }
  },

  /**
   * Remove suggestions with specified StatusReportId and PromIds
   * @param {Object} payload
   * @param {string} payload.statusReportId StatusReport Id
   * @param {string[]} payload.promIds Array of Prom Id
   */
  [STATUSREPORT_MUTATIONS.REMOVE_PROM_SUGGESTION](
    state,
    { statusReportId, promIds }
  ) {
    promIds.forEach(id => {
      const index = state.suggestions.findIndex(
        suggestion =>
          suggestion.StatusReportId === statusReportId &&
          suggestion.PromId === id
      );
      state.suggestions.splice(index, 1);
    });
  },

  [STATUSREPORT_MUTATIONS.SET_HAS_REPORTED_STATUS](state, bool) {
    state.hasReportedStatus = bool;
  },

  /** @param {StatusReportModel} report */
  [STATUSREPORT_MUTATIONS.SET_UNSAVED_REPORT](state, report) {
    report.Items.forEach(item => {
      item._lastValue = item.Intensity;
    });
    state.tempReport = report;
  },

  [STATUSREPORT_MUTATIONS.REMOVE_UNSAVED_REPORT](state) {
    state.tempReport = null;
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [STATUSREPORT_ACTIONS.$PREINIT](context, { reports, suggestions }) {
    context.dispatch(STATUSREPORT_ACTIONS.GET_STATUS_REPORTS, reports);

    if (suggestions) {
      context.dispatch(STATUSREPORT_ACTIONS.GET_PROM_SUGGESTIONS, suggestions);
    }
  },

  /**
   * Get StatusReport
   * @param {String} id
   */
  [STATUSREPORT_ACTIONS.GET_STATUS_REPORT](context, id) {
    return StatusReportService.findMyStatusReport(id).then(report => {
      context.commit(STATUSREPORT_MUTATIONS.ADD_REPORT, report);
      return report;
    });
  },

  /**
   * Get StatusReports
   */
  [STATUSREPORT_ACTIONS.GET_STATUS_REPORTS](context, reports) {
    if (reports) {
      return action(reports);
    }
    return StatusReportService.getMyStatusReports().then(reports =>
      action(reports)
    );

    function action(reports) {
      reports = sortByCreatedDate(reports);
      context.commit(STATUSREPORT_MUTATIONS.SET_REPORTS, reports);
      if (reports.length) {
        context.commit(
          STATUSREPORT_MUTATIONS.SET_LATEST_REPORT,
          reports.slice(-1)[0].Id
        );
      }
    }
  },

  /**
   * Get a new StatusReport
   * @returns {Promise<StatusReportModel>}
   */
  [STATUSREPORT_ACTIONS.NEW_REPORT](context, { useOldValues = true }) {
    return Promise.resolve(generateReport());

    function generateReport() {
      const types = Object.keys(context.getters.TYPES);
      const latest = context.getters[STATUSREPORT_GETTERS.LATEST_REPORT];

      return StatusReportResponse({
        Items: types.map(type => {
          let entry = null;
          if (latest) {
            entry = latest.Items.find(x => x.Type === type);
          }
          const LanguageId =
            context.rootGetters[
              `currentuser/${CURRENT_USER_GETTERS.ACTIVE_LANGUAGE}`
            ];

          let LastValue;
          let Intensity;

          if (entry) {
            LastValue = entry.Intensity;
            Intensity = entry.Intensity;
          } else {
            LastValue = 0;
            Intensity = 0;
          }

          if (useOldValues === false) {
            Intensity = 0;
            LastValue = undefined;
          }

          const item = new StatusReportItemModel({
            Type: type,
            Intensity,
            LanguageId
          });

          item._lastValue = LastValue;

          return item;
        })
      });
    }
  },

  [STATUSREPORT_ACTIONS.SAVE_TEMP_REPORT](context) {},

  /**
   * Remove a StatusReport
   * @param {StatusReportModel} report
   * @returns {Promise<null>}
   */
  [STATUSREPORT_ACTIONS.REMOVE_REPORT](context, report) {
    return StatusReportService.deleteMyStatusReport(report.Id).then(() => {
      context.commit(STATUSREPORT_MUTATIONS.REMOVE_REPORT, report);

      if (report.Id === state.latest && state.list.length) {
        context.commit(
          STATUSREPORT_MUTATIONS.SET_LATEST_REPORT,
          state.list.slice(-1)[0].Id
        );
      }

      context.dispatch(STATUSREPORT_ACTIONS.GET_PROM_SUGGESTIONS);
    });
  },

  /**
   * Save new StatusReport
   * @param {StatusReportItemModel[]} cluster
   * @returns {Promise<StatusReportModel>}
   */
  [STATUSREPORT_ACTIONS.SAVE_REPORT](context, cluster) {
    return StatusReportService.addMyStatusReport(cluster)
      .then(newReport => {
        context.commit(STATUSREPORT_MUTATIONS.ADD_REPORT, newReport);
        context.commit(STATUSREPORT_MUTATIONS.SET_LATEST_REPORT, newReport.Id);
        context.commit(STATUSREPORT_MUTATIONS.SET_HAS_REPORTED_STATUS, true);
        context.commit(STATUSREPORT_MUTATIONS.REMOVE_UNSAVED_REPORT);

        const incompleteProms = context.rootGetters[
          `promreports/${PROMREPORT_GETTERS.FIND_INCOMPLETE_PROMREPORTS}`
        ].map(p => p.PromId);

        const suggestions = newReport.PromSuggestions.filter(
          x => incompleteProms.indexOf(x.PromId) === -1
        );

        context.dispatch(
          STATUSREPORT_ACTIONS.GET_PROM_SUGGESTIONS,
          newReport.PromSuggestions
        );
        return newReport;
      })
      .catch(e => console.log(e));
  },

  /**
   * Update status report
   * @param {StatusReportModel} report
   * @returns {Promise<StatusReportModel>}
   */
  [STATUSREPORT_ACTIONS.UPDATE_REPORT](context, report) {
    return StatusReportService.updateMyStatusReport(report)
      .then(report => {
        context.commit(STATUSREPORT_MUTATIONS.ADD_REPORT, report);
      })
      .catch(e => console.log(e));
  },

  /**
   * Parse and set followups
   * @param {PromSuggestionModel[]} PromSuggestions
   */
  [STATUSREPORT_ACTIONS.GET_POTENTIAL_FOLLOWUPS](context, PromSuggestions) {
    const followups = [];

    if (context.getters[STATUSREPORT_GETTERS.LATEST_HAS_TYPES](['pain'], 1)) {
      followups.push(
        new FollowupModel({
          Name: 'pain',
          Id: 'pain',
          Type: 'pain',
          Weight: 100
        })
      );
    }

    if (PromSuggestions.length > 0) {
      PromSuggestions.forEach(suggestion => {
        const prom = context.rootGetters[
          `proms/${PROM_GETTERS.ALL_SHOWN_PROMS}`
        ].find(prom => prom.Id === suggestion.PromId);
        if (!prom) {
          return;
        }
        followups.push(
          new FollowupModel({
            Name: prom.Concept,
            Id: prom.Id,
            StatusReportId: suggestion.StatusReportId,
            Type: 'prom',
            Item: prom,
            Weight: suggestion.Weight
          })
        );
      });
    }

    context.commit(
      STATUSREPORT_MUTATIONS.SET_POTENTIAL_FOLLOWUPS,
      sortByValueKey(followups, 'Weight').reverse()
    );
  },

  /**
   * Get all PromSuggestions
   * @returns {Promise<PromSuggestionModel[]>}
   */
  [STATUSREPORT_ACTIONS.GET_PROM_SUGGESTIONS](context, suggestions) {
    if (suggestions) {
      return action(suggestions);
    }

    return PromSuggestionService.getOwnSuggestedProms().then(suggestions => {
      action(suggestions);
    });

    function action(suggestions) {
      const incompleteProms = context.rootGetters[
        `promreports/${PROMREPORT_GETTERS.FIND_INCOMPLETE_PROMREPORTS}`
      ].map(p => p.PromId);

      suggestions = suggestions.filter(
        x => incompleteProms.indexOf(x.PromId) === -1
      );
      context.commit(STATUSREPORT_MUTATIONS.SET_PROM_SUGGESTIONS, suggestions);
      context.dispatch(
        STATUSREPORT_ACTIONS.GET_POTENTIAL_FOLLOWUPS,
        suggestions
      );
    }
  },

  /**
   * Hide PromSuggestion
   * @param {Object} payload
   * @param {string} payload.statusReportId
   * @param {string[]} payload.promIds
   */
  [STATUSREPORT_ACTIONS.HIDE_SUGGESTIONS](
    context,
    { statusReportId, promIds }
  ) {
    if (typeof promIds === 'string') {
      promIds = [promIds];
    }
    return PromSuggestionService.hideSuggestedProm(
      statusReportId,
      promIds
    ).then(() => {
      context.commit(STATUSREPORT_MUTATIONS.REMOVE_PROM_SUGGESTION, {
        statusReportId,
        promIds
      });
    });
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  /**
   * Get StatusReports
   * @returns {StatusReportModel[]}
   */
  [STATUSREPORT_GETTERS.STATUS_REPORTS](state) {
    return state.list;
  },

  [STATUSREPORT_GETTERS.TYPES](state, getters, rootState, rootGetters) {
    if (!rootGetters[`app/${APP_GETTERS.STATUS_REPORT_TYPES}`]) {
      return [];
    }
    return rootGetters[`app/${APP_GETTERS.STATUS_REPORT_TYPES}`];
  },

  /**
   * Find StatusReport
   * @returns {StatusReportModel}
   */
  [STATUSREPORT_GETTERS.FIND_REPORT]: state => reportId => {
    return state.list.find(report => report.Id === reportId);
  },

  /**
   * Find StatusReports by type
   * @returns {StatusReportModel[]}
   */
  [STATUSREPORT_GETTERS.FIND_REPORTS_BY_TYPE]: state => type => {
    return state.list.filter(report => report.Type === type);
  },

  /**
   * Latest StatusReport
   * @returns {StatusReportModel}
   */
  [STATUSREPORT_GETTERS.LATEST_REPORT](state, getters) {
    if (!state.latest) {
      return null;
    }
    return getters[STATUSREPORT_GETTERS.FIND_REPORT](state.latest);
  },

  /**
   * Checks if latest report has type with intensity
   * @returns {boolean}
   */
  [STATUSREPORT_GETTERS.LATEST_HAS_TYPES]:
    (state, getters) =>
    (types = [], intensity) => {
      const latestReport = getters[STATUSREPORT_GETTERS.LATEST_REPORT];

      if (!latestReport) {
        return false;
      }

      const reports = latestReport.Items.filter(
        report =>
          types.indexOf(report.Type) > -1 && report.Intensity >= intensity
      );
      if (reports.length > 0) {
        return true;
      }
      return false;
    },

  /**
   * Get followups
   * @returns {FollowupModel[]}
   */
  [STATUSREPORT_GETTERS.FOLLOWUP_ACTIONS](state) {
    return state.followups;
  },

  /**
   * Get followups
   * @returns {FollowupModel[]}
   */
  [STATUSREPORT_GETTERS.REPORT_GRAPH_DATA]:
    (state, getters, rootState, rootGetters) => reportid => {
      const report = getters[STATUSREPORT_GETTERS.FIND_REPORT](reportid);
      if (!report) {
        return [];
      }
      const graphData = rootGetters[
        `app/${APP_GETTERS.STATUS_REPORT_TYPES_SORTED_ARRAY}`
      ]
        .slice(0)
        .map(area => ({ area, value: 0, date: moment() }));

      for (let i = 0, n = report.Items.length; i < n; i++) {
        const item = report.Items[i];
        const index = graphData.findIndex(x => x.area === item.Type);

        if (!item) {
          continue;
        }

        graphData[index] = {
          area: item.Type,
          value: item.Intensity,
          displayValue: state.invertedTypes.includes(item.Type)
            ? 10 - item.Intensity
            : item.Intensity,
          date: item.CreatedDate,
          color: StatusReportAreaColors[item.Type]
        };
      }
      return [graphData[0], ...graphData.slice(1).reverse()];
    },

  /**
   * Get summary graph data
   * @returns {Object[]}
   */
  [STATUSREPORT_GETTERS.SUMMARY_GRAPH_DATA](
    state,
    getters,
    rootState,
    rootGetters
  ) {
    if (!rootGetters[`app/${APP_GETTERS.STATUS_REPORT_TYPES}`]) {
      return;
    }

    const graphData = rootGetters[
      `app/${APP_GETTERS.STATUS_REPORT_TYPES_SORTED_ARRAY}`
    ]
      .slice(0)
      .map(area => ({ area, value: 0, date: moment() }));

    const latest = getters[STATUSREPORT_GETTERS.LATEST_REPORT];

    if (!latest) {
      return [];
    }

    for (let i = 0, n = latest.Items.length; i < n; i++) {
      const item = latest.Items[i];
      const index = graphData.findIndex(x => x.area === item.Type);

      if (!item) {
        continue;
      }

      graphData[index] = {
        area: item.Type,
        value: item.Intensity,
        displayValue:
          state.invertedTypes.indexOf(item.Type) === -1
            ? item.Intensity
            : 10 - item.Intensity,
        date: item.CreatedDate,
        color: StatusReportAreaColors[item.Type]
      };
    }
    return [graphData[0], ...graphData.slice(1).reverse()];
  },

  /**
   * Get summary graph data without ignored types
   * @returns {Object[]}
   */
  [STATUSREPORT_GETTERS.SUMMARY_GRAPH_DATA_WITHOUT_IGNORED](state, getters) {
    return getters[STATUSREPORT_GETTERS.SUMMARY_GRAPH_DATA].filter(
      x => !state.ignoreInGraph.includes(x.area)
    );
  },

  /**
   * Get item graph data
   * @returns {Object[]}
   */
  [STATUSREPORT_GETTERS.AREA_GRAPH_DATA]: state => area => {
    const graphData = [];

    for (let i = 0, n = state.list.length; i < n; i++) {
      const report = state.list[i];
      const item = report.Items.find(item => area === item.Type);

      if (!item) {
        continue;
      }

      graphData.push({
        value:
          state.invertedTypes.indexOf(item.Type) === -1
            ? item.Intensity
            : 10 - item.Intensity,
        date: item.CreatedDate.toDate(),
        moment: item.CreatedDate,
        color: StatusReportAreaColors[item.Type]
      });
    }
    return sortByCreatedDate(graphData);
  },

  /**
   * Check if graph has any values over 0
   * @returns {boolean}
   */
  [STATUSREPORT_GETTERS.SUMMARY_GRAPH_HAS_VALUES](state, getters) {
    if (!getters[STATUSREPORT_GETTERS.SUMMARY_GRAPH_DATA]) {
      return;
    }

    return !!getters[STATUSREPORT_GETTERS.SUMMARY_GRAPH_DATA].filter(
      d => d && d.value !== undefined
    ).length;
  },

  /**
   * Get inverted types
   * @returns {String[]}
   */
  [STATUSREPORT_GETTERS.INVERTED_TYPES](state) {
    return state.invertedTypes;
  },

  /**
   * Get PromSuggestions
   * @returns {PromSuggestionModel[]}
   */
  [STATUSREPORT_GETTERS.SUGGESTIONS](state, getters, rootState, rootGetters) {
    const suggestions = state.suggestions
      .filter(s => {
        return !!rootGetters[`proms/${PROM_GETTERS.ALL_SHOWN_PROMS}`].find(
          prom =>
            prom.Language ===
            rootGetters[`currentuser/${CURRENT_USER_GETTERS.ACTIVE_LANGUAGE}`]
        );
      })
      .map(suggestion => {
        return {
          ...suggestion,
          prom: rootGetters[`proms/${PROM_GETTERS.ALL_SHOWN_PROMS}`].find(
            prom =>
              prom.Id === suggestion.PromId &&
              prom.Language ===
                rootGetters[
                  `currentuser/${CURRENT_USER_GETTERS.ACTIVE_LANGUAGE}`
                ]
          )
        };
      })
      .filter(x => !!x.prom);

    return sortByValueKey(suggestions, 'Weight').reverse();
  },
  [STATUSREPORT_GETTERS.HAS_REPORTED_STATUS](state) {
    return state.hasReportedStatus;
  },
  [STATUSREPORT_GETTERS.UNSAVED_REPORT](state) {
    return state.tempReport;
  }
};

export {
  STATUSREPORT_ACTIONS,
  STATUSREPORT_GETTERS,
  STATUSREPORT_MUTATIONS
} from './definitions';

export default store;
