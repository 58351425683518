<template>
  <router-link
    :to="{ name: 'auditlog', params: { auditlogid: audit.Id } }"
    :id="`audit-${audit.Id}`"
    class="block"
  >
    <info-row>
      <template v-slot:intensity>
        <div
          class="colored-background action"
          :class="`action--${actionColor}`"
        >
          <span>
            <i class="mdi" :class="actionIcon" :title="audit.ActionType"></i>
          </span>
        </div>
      </template>
      <template v-slot:title>
        <div class="pl" v-text="$t(`generic.${action}`)"></div>
      </template>
      <template v-slot:date>
        <div class="pl">
          {{ who }} &bull;
          {{ $filters.DateTimeFormat(audit.TimeStamp) }}
        </div>
      </template>
    </info-row>
  </router-link>
</template>

<script>
import InfoRow from '@common/Components/InfoRow/InfoRow';
import { getters as promreportGetters } from '@/store/modules/promreports';
import { mapGetters } from 'vuex';
import AuditLogModel from '@common/Models/AuditLogModel';
export default {
  components: { InfoRow },
  props: {
    audit: AuditLogModel
  },
  computed: {
    who() {
      if (this.audit.ActorRole !== 'pat') {
        return `${this.audit.ActorName} (${this.audit.ActorRole})`;
      }

      let you = this.$t('generic.you').split('');
      you[0] = you[0].toUpperCase();
      you = you.join('');

      return you;
    },
    action() {
      let action = this.audit.ResourceType;
      if (action === 'Patient') {
        action = 'profile';
      }
      return action;
    },
    actionColor() {
      switch (this.audit.ActionType) {
        case 'Update': {
          return 'yellow';
        }
        case 'Create': {
          return 'green';
        }
        case 'Delete': {
          return 'red';
        }
        case 'Purge':
        case 'AutoPurge': {
          return 'dark-red';
        }
        case 'Restore': {
          return 'blue';
        }
        default: {
          return 'gray';
        }
      }
    },
    actionIcon() {
      switch (this.audit.ActionType) {
        case 'View': {
          return 'mdi-eye';
        }
        case 'Update': {
          return 'mdi-pencil';
        }
        case 'Create': {
          return 'mdi-plus-circle';
        }
        case 'Delete': {
          return 'mdi-delete';
        }
        case 'Purge': {
          return 'mdi-delete-forever';
        }
        case 'Restore': {
          return 'mdi-restore';
        }
        case 'AutoPurge': {
          return 'mdi-robot-vacuum';
        }
        default:
          return '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';
.action {
  span {
    font-size: 1.9em;
    align-self: center;
    .mdi {
      color: #fff;
    }
  }

  &--orange {
    background-color: $symptoms-orange;
  }

  &--yellow {
    background-color: $symptoms-yellow;
  }

  &--green {
    background-color: $symptoms-green;
  }

  &--dark-red {
    background-color: maroon;
  }
  &--red {
    background-color: $symptoms-red;
  }

  &--blue {
    background-color: $symptoms-blue;
  }

  &--gray {
    background-color: rgb(138, 138, 138);
  }
}
@media only screen and (max-width: $breakpoint-phone) {
  .pl {
    padding-left: 10px;
  }
}
</style>
