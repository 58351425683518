// @ts-check
export class BankIdUserModel {
  constructor({ personalNumber, name, givenName, surname }) {
    this._modelName = 'BankIdUser';
    this.personalNumber = personalNumber;
    this.name = name;
    this.givenName = givenName;
    this.surname = surname;
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }
}

export default BankIdUserModel;
