import DashboardStatisticModel from '@common/Models/DashboardStatisticModel';

/**
 * Map remote DashboardStatistic to local
 * @param {object} dtoDashboardStatistic
 * @returns {DashboardStatisticModel}
 */
export const DashboardStatisticResponse = dtoDashboardStatistic => {
  return new DashboardStatisticModel(dtoDashboardStatistic);
};

/**
 * Map remote DashboardStatistics to local
 * @param {object[]} dtoDashboardStatistics
 * @returns {DashboardStatisticModel[]}
 */
export const DashboardStatisticsResponse = dtoDashboardStatistics => {
  return dtoDashboardStatistics.map(dto => DashboardStatisticResponse(dto));
};
