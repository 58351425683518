export const USER_MUTATIONS = {
  ADD_USER: 'ADD_USER',
  REMOVE_USER: 'REMOVE_USER',
  USER_SAVE_SUCCESS: 'USER_SAVE_SUCCESS',
  SET_USERS: 'SET_USERS'
};

export const USER_ACTIONS = {
  DELETE_USER: 'DELETE_USER',
  GET_USER: 'GET_USER',
  GET_USERS: 'GET_USERS',
  UPDATE_USER: 'UPDATE_USER',
  CREATE_USER: 'CREATE_USER',
  DISABLE_2FA_FOR_USER: 'DISABLE_2FA_FOR_USER',
  RESET_PASSWORD_FOR_USER: 'RESET_PASSWORD_FOR_USER',
  UPDATE_ROLES_FOR_USER: 'UPDATE_ROLES_FOR_USER',
  ERASE_ALL_DATA_FOR_USER: 'ERASE_ALL_DATA_FOR_USER',
  REMOVE_ACCOUNT_FOR_USER: 'REMOVE_ACCOUNT_FOR_USER',
  ANONYMIZE_ACCOUNT_FOR_USER: 'ANONYMIZE_ACCOUNT_FOR_USER'
};

export const USER_GETTERS = {
  FIND_USER: 'FIND_USER',
  USERS: 'USERS'
};
