// @ts-check
export class FollowupModel {
  constructor({ Name, Id, StatusReportId, Type, Item = null, Weight }) {
    this.Name = Name;
    this.Id = Id;
    this.StatusReportId = StatusReportId;
    this.Type = Type;
    this.Item = Item;
    this.Weight = Weight;
  }
}

export default FollowupModel;
