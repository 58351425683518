export const MUTATIONS = {
  SET_INTERACTABLE: 'SET_INTERACTABLE',
  SET_SYMPTOMREPORT: 'SET_SYMPTOMREPORT',
  SET_ACTIVE_SYMPTOM: 'SET_ACTIVE_SYMPTOM',
  SET_ACTIVE_TYPE: 'SET_ACTIVE_TYPE',
  SET_AVAILABLE_TYPES: 'SET_AVAILABLE_TYPES',
  SET_INTENSITY: 'SET_INTENSITY',
  SET_MODEL: 'SET_MODEL',
  SAVE_ACTIVE_SYMPTOM: 'SAVE_ACTIVE_SYMPTOM',
  SAVE_SYMPTOM: 'SAVE_SYMPTOM',
  SET_SYMPTOM_NAME: 'SET_SYMPTOM_NAME',
  SET_SYMPTOM_DESCRIPTION: 'SET_SYMPTOM_DESCRIPTION',
  ADD_SYMPTOM: 'ADD_SYMPTOM',
  ADD_SYMPTOM_POINT: 'ADD_SYMPTOM_POINT',
  REMOVE_SYMPTOM: 'REMOVE_SYMPTOM',
  REMOVE_ENTIRE_SYMPTOM: 'REMOVE_ENTIRE_SYMPTOM',
  REMOVE_SYMPTOM_POINT: 'REMOVE_SYMPTOM_POINT',
  REMOVE_ACTIVE_SYMPTOM: 'REMOVE_ACTIVE_SYMPTOM',
  REMOVE_ENTIRE_DRAWING: 'REMOVE_ENTIRE_DRAWING',
  SET_REPORT_LOCKED: 'SET_REPORT_LOCKED',
  TOGGLE_DRAWING: 'TOGGLE_DRAWING',
  TOGGLE_CONTROLS: 'TOGGLE_CONTROLS',
  CANCEL_ACTION: 'CANCEL_ACTION',
  CLICK_SYMPTOM: 'CLICK_SYMPTOM',
  MARK_BODY: 'MARK_BODY',
  UNMARK_BODY: 'UNMARK_BODY',
  MODEL_LOADED: 'MODEL_LOADED',
  RESET: 'RESET',
  RESET_CAMERA: 'RESET_CAMERA',
  SET_MODE: 'SET_MODE'
};

export const ACTIONS = {
  INIT: 'INIT'
};

export const GETTERS = {
  REPORT: 'REPORT',
  SETTINGS: 'SETTINGS',
  ACTIVE_ACTION: 'ACTIVE_ACTION',
  ACTIVE_TYPE: 'ACTIVE_TYPE',
  ACTIVE_SYMPTOM: 'ACTIVE_SYMPTOM',
  ACTIVE_MODEL: 'ACTIVE_MODEL',
  DRAWING: 'DRAWING',
  MODE: 'MODE'
};
