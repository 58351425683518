// @ts-check

export class StatusTypeModel {
  constructor({ Name, Inverted, Index }) {
    this.Name = Name;
    this.Inverted = Inverted;
    this.Index = Index;
  }
}

export default StatusTypeModel;
