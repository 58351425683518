<template>
  <div data-cy="trash">
    <div class="container">
      <h4
        v-html="$t('generic.trash')"
        class="header pb20"
        data-cy="trash-text-trash"
      ></h4>
      <trash-item
        :data-cy="'trash-item-' + item.ResourceType"
        v-for="(item, index) in $allTrashByDate"
        :key="index"
        :item="item"
      ></trash-item>

      <div class="left-right-buttons mt20" v-if="$allTrashByDate.length">
        <s-button
          @click="purgeAllConfirmModal = true"
          element-name="trash-purgebutton"
          :danger="true"
          icon="mdi-delete-forever"
          :loading="purgeLoading"
          >{{ $t('views.trash.purgeAll') }}</s-button
        >
        <s-button
          @click="restoreAll()"
          element-name="trash-restorebutton"
          :primary="true"
          icon="mdi-restore"
          :loading="restoreLoading"
          >{{ $t('views.trash.restoreAll') }}</s-button
        >
      </div>

      <div class="row" v-else>{{ $t('views.trash.trashIsEmpty') }}</div>

      <modal-confirm
        :show="purgeAllConfirmModal"
        :accept-callback="
          () => {
            purgeAll();
          }
        "
        :decline-text="$t('generic.no')"
        :help-text="$t('modals.removeAllTrashItems')"
        @close="purgeAllConfirmModal = false"
      ></modal-confirm>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { TRASH_ACTIONS, TRASH_GETTERS } from '@/store/modules/trash';
import TrashItem from './TrashItem';

export default {
  components: {
    TrashItem
  },
  data() {
    return {
      purgeAllConfirmModal: false,
      purgeLoading: false,
      restoreLoading: false
    };
  },
  mounted() {
    this.$getTrashItems();
  },
  methods: {
    ...mapActions('trash', {
      $getTrashItems: TRASH_ACTIONS.GET_TRASH_ITEMS,
      $restoreItem: TRASH_ACTIONS.RESTORE_ITEM,
      $restoreAll: TRASH_ACTIONS.RESTORE_ALL,
      $purgeAll: TRASH_ACTIONS.PURGE_ALL
    }),
    purgeAll() {
      this.purgeLoading = true;
      this.$purgeAll().then(() => {
        this.purgeLoading = false;
        this.purgeAllConfirmModal = false;
      });
    },
    restoreAll() {
      this.restoreLoading = true;
      this.$restoreAll().then(() => {
        this.restoreLoading = false;
      });
    }
  },
  computed: {
    ...mapGetters('trash', {
      $allTrashByDate: TRASH_GETTERS.ALL_TRASH_BY_DATE
    })
  }
};
</script>
