import OAuthTokenModel from '@common/Models/OAuthTokenModel';

/**
 * Map remote OAuthToken to local
 * @param {object} dtoOAuthToken
 * @returns {OAuthTokenModel}
 */
export const OAuthTokenResponse = dtoOAuthToken => {
  return new OAuthTokenModel(dtoOAuthToken);
};

/**
 * Map remote OAuthTokens to local
 * @param {object[]} dtoOAuthTokens
 * @returns {OAuthTokenModel[]}
 */
export const OAuthTokensResponse = dtoOAuthTokens => {
  return dtoOAuthTokens.map(dto => OAuthTokenResponse(dto));
};
