<template>
  <div class="box">
    <form @keyup.enter="sendInvites">
      <div class="row" v-for="(invite, index) in newInvites" :key="index">
        <div class="col s6">
          <s-text-input
            v-model="invite.Email"
            :label="$t('generic.email')"
            :ref="`email${index}`"
          ></s-text-input>
        </div>

        <div class="col s5">
          <s-text-input
            v-model="invite.Personnummer"
            :keyup-callback="() => fixPersonnummer(invite)"
            :maxlength="13"
            :label="$t('generic.personnummer')"
            id="InputPersonnummer"
            type="tel"
            pattern="[0-9\-]+"
          ></s-text-input>
        </div>

        <div class="col s1 input-field">
          <s-button
            @click="removeInvite(index)"
            :flat="true"
            :dark="true"
            :small="true"
            icon="mdi-delete"
          ></s-button>
        </div>
      </div>

      <div class="clearfix">
        <s-button @click="addInvite" icon="mdi-plus" :small="true">{{
          $t('generic.new')
        }}</s-button>
        <s-button
          class="right"
          @click="sendInvites()"
          :primary="true"
          :small="true"
          :loading="sendInvitesLoading"
          >{{ $t('generic.send') }}</s-button
        >
      </div>
    </form>
  </div>
</template>

<script>
import InviteModel from '@common/Models/InviteModel';
import { mapActions } from 'vuex';
import { INVITE_ACTIONS } from '@/store/modules/invites';
export default {
  data() {
    return {
      newInvites: [],
      sendInvitesLoading: false
    };
  },
  mounted() {
    this.addInvite();
  },
  methods: {
    ...mapActions('invites', {
      $createInvite: INVITE_ACTIONS.CREATE_INVITE
    }),
    addInvite() {
      this.newInvites.push(new InviteModel({}));
    },
    sendInvites() {
      this.sendInvitesLoading = true;
      const promises = this.newInvites.map(invite => {
        return this.$createInvite(invite);
      });

      Promise.all(promises)
        .then(() => {
          this.newInvites = [];
          this.addInvite();
        })
        .finally(() => {
          this.sendInvitesLoading = false;
          this.$refs.email0[0].$refs.input.focus();
        });
    },
    removeInvite(index) {
      this.newInvites.splice(index, 1);
      if (this.newInvites.length === 0) {
        this.addInvite();
      }
    },
    fixPersonnummer(invite) {
      let pnr = invite.Personnummer;
      if (!pnr) {
        return;
      }

      // remove any non-numbers or dashes
      for (let i = pnr.length - 1; i >= 0; i--) {
        // dashes are ok at 9th character
        if (i == 8 && pnr[i] === '-') {
          continue;
        }
        if (!'0123456789'.includes(pnr[i])) {
          var result = pnr.split('');
          result.splice(i, i + 1);
          pnr = result.join('');
          invite.Personnummer = pnr;
        }
      }

      // replace personnummer with '19x' or '20x' as soon as we
      // know the user is not trying to fill out '19' or '20'
      if (pnr.length == 1) {
        if (pnr[0] == 0) {
          invite.Personnummer = '20' + pnr;
        } else if (pnr[0] > 2) {
          invite.Personnummer = '19' + pnr;
        }
        return;
      }

      // don't bother with strings that are too short or too long
      if (pnr.length < 2 || pnr.length > 10) {
        return;
      }

      // add dash at index 8 if it's missing
      if (pnr.length > 8 && !pnr.includes('-')) {
        invite.Personnummer = pnr =
          pnr.substring(0, 8) + '-' + pnr.substring(8);
      }

      // if personnummer starts with '19' or '20', there's no need to continue here
      if ((pnr[0] == 1 && pnr[1] == 9) || (pnr[0] == 2 && pnr[1] == 0)) {
        return;
      }

      // make substitutions at beginning of string to create '19...' or '20...' personnummer
      if (pnr[0] == 2 && pnr[1] != 0) {
        invite.Personnummer = '19' + pnr;
      } else if (pnr[0] == 1 && pnr[1] != 9) {
        invite.Personnummer = '20' + pnr;
      } else if (pnr[0] > 2) {
        invite.Personnummer = '19' + pnr;
      } else if (pnr[0] == 0) {
        invite.Personnummer = '20' + pnr;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.button--flat {
  padding: 15px !important;
}
</style>
