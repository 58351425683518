import BaseService from '../BaseService';
import HistoryItemModel from '@common/Models/HistoryItemModel';
import { HistoryItemsResponse } from './mappers/HistoryItemResponses';

class HistoryItemService extends BaseService {
  /**
   * Get all history items -- backend
   * @returns {Promise<HistoryItemModel[]>}
   * @memberof HistoryItemService
   */
  getMyHistoryItems() {
    return this.backend('my/historyitems').then(response =>
      HistoryItemsResponse(response.data)
    );
  }
}

export default new HistoryItemService();
