import { Bus } from '@common';
import { LANGUAGE_STORAGE } from '@common/constants';
import { getActiveRole, getStoredAuthorization } from '@common/Helpers/auth';
import Loader from '@common/Helpers/Loader';
import { removePrivateVariablesFromBody } from '@common/Helpers/object';
import storage from '@common/Helpers/storage';
import TokenModel from '@common/Models/TokenModel';
import moment from 'moment';
import { config } from '../';

export const RequestLoaderInterceptor = conf => {
  Loader.loading();
  return conf;
};

export const RequestAuthInterceptor = conf => {
  if (conf.url === config.tokenUri) {
    return conf;
  }

  let authData = getStoredAuthorization();

  if (!authData) {
    return conf;
  }

  authData = new TokenModel(authData);
  const activeLanguage = storage.get(LANGUAGE_STORAGE) || 'sv';

  const tokenExpiryDate = moment(authData['.expires']);
  const minutesUntilTokenExpiration = tokenExpiryDate.diff(moment(), 'minutes');

  if (minutesUntilTokenExpiration < 5) {
    Bus.$emit('refresh-token');
  }

  conf.headers = {
    Authorization: `${authData.token_type} ${authData.access_token}`,
    'X-OrganizationId': authData.organization,
    'X-GroupId': authData.group,
    'X-RoleId': getActiveRole(),
    'X-LanguageId': activeLanguage
  };

  return conf;
};

export const RequestRemovePrivateVariables = conf => {
  if (conf.method === 'get') {
    return conf;
  }
  try {
    const data = JSON.parse(JSON.stringify(conf.data));
    conf.data = removePrivateVariablesFromBody(data);
    return conf;
  } catch (e) {
    return conf;
  }
};
