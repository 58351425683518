import TrashItemModel from '@common/Models/TrashItemModel';

/**
 * Map remote trash item to local
 * @param {object} dtoTrashItem
 * @returns {TrashItemModel}
 */
export const TrashItemResponse = dtoTrashItem => {
  return new TrashItemModel(dtoTrashItem);
};

/**
 * Map remote trash items to local
 * @param {object[]} dtoTrashItems
 * @returns {TrashItemModel[]}
 */
export const TrashItemsResponse = dtoTrashItems => {
  return dtoTrashItems.map(dto => TrashItemResponse(dto));
};
