// @ts-check
import { utcToLocal } from '@common/Helpers/time';
import moment from 'moment';
import BugReportScreenshotModel from './BugReportScreenshotModel';
import { required } from '@vuelidate/validators';

export class BugReportModel {
  constructor({
    BugReportId,
    Name,
    Email,
    Description,
    CreatedDate,
    Device,
    OperatingSystem,
    UserAgent,
    Resolution,
    Processed,
    HasScreenshots,
    Screenshots = [],
    IssueId,
    Url,
    Anonymous = false,
    ReleaseVersion,
    FrontendVersion,
    BackendVersion,
    IdentityVersion,
    Status = 'new',
    ProcessedBy,
    Reason
  }) {
    this._modelName = 'BugReport';
    this.Id = BugReportId || null;
    this.Name = Name;
    this.Email = Email;
    this.Description = Description || null;
    this.CreatedDate = CreatedDate ? utcToLocal(CreatedDate) : moment();
    this.Device = Device || null;
    this.OperatingSystem = OperatingSystem || null;
    this.UserAgent = UserAgent || null;
    this.Resolution = Resolution;
    this.Processed = Processed;
    this.HasScreenshots = HasScreenshots;
    this.Screenshots = Screenshots.map(s => new BugReportScreenshotModel(s));
    this.IssueId = IssueId;
    this.Url = Url;
    this.Anonymous = Anonymous;
    this.ReleaseVersion = ReleaseVersion;
    this.FrontendVersion = FrontendVersion;
    this.BackendVersion = BackendVersion;
    this.IdentityVersion = IdentityVersion;
    this.Status = Status;
    this.ProcessedBy = ProcessedBy;
    this.Reason = Reason;
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }

  static get validations() {
    return {
      Name: {
        required
      },
      Description: {
        required
      },
      Device: {
        required
      },
      OperatingSystem: {
        required
      },
      UserAgent: {
        required
      }
    };
  }
}

export default BugReportModel;
