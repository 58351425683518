import { PROMREPORT_GETTERS } from '@/store/modules/promreports';
import { SYMPTOM_GETTERS } from '@/store/modules/symptoms';
import { sortByKey } from '@common/Helpers/sorters';
import { sortBy } from 'lodash';
import moment from 'moment';
import { HISTORY_GETTERS } from './definitions';

const store = {
  namespaced: true,
  state: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [HISTORY_GETTERS.GET_LATEST_STATUS](state, getters, rootState, rootGetters) {
    const history = [];

    if (rootState.hasOwnProperty('symptoms')) {
      const symptoms = sortBy(
        rootGetters[`symptoms/${SYMPTOM_GETTERS.ACTIVE_SYMPTOMS}`],
        x => moment(x.CreatedDate)
      );

      symptoms.forEach(symptom => {
        history.push({
          type: 'symptom',
          ModifiedDate: symptom.ModifiedDate,
          CreatedDate: symptom.CreatedDate,
          symptom: symptom
        });
      });
    }

    if (rootState.hasOwnProperty('proms')) {
      const promreports = sortBy(
        rootGetters[`promreports/${PROMREPORT_GETTERS.PROM_REPORTS}`],
        s => moment(s.ModifiedDate)
      ).reverse();

      promreports.forEach(report => {
        if (
          report.isCompleted &&
          report.isRecent &&
          history.findIndex(r => r.PromId === report.PromId) === -1
        ) {
          history.push({
            type: 'prom',
            PromId: report.PromId,
            ModifiedDate: report.ModifiedDate,
            CreatedDate: report.CreatedDate
          });
        }
      });
    }

    return sortBy(history, s => {
      return moment(s.CreatedDate);
    }).reverse();
  },
  [HISTORY_GETTERS.GET_LATEST_STATUS_ALL_SYMPTOMS](
    state,
    getters,
    rootState,
    rootGetters
  ) {
    const history = [];

    if (rootState.hasOwnProperty('symptoms')) {
      const symptoms = sortBy(
        rootGetters[`symptoms/${SYMPTOM_GETTERS.SYMPTOMS_ALL}`],
        x => moment(x.CreatedDate)
      );
      symptoms.forEach(symptom => {
        const lastReport = getLastReport(symptom);

        history.push({
          type: 'symptom',
          ModifiedDate: lastReport ? lastReport.ModifiedDate : null,
          CreatedDate: symptom.CreatedDate,
          symptom: symptom
        });
      });
    }

    if (rootState.hasOwnProperty('proms')) {
      const promreports = sortBy(
        rootGetters[`promreports/${PROMREPORT_GETTERS.PROM_REPORTS}`],
        s => moment(s.ModifiedDate)
      ).reverse();

      promreports.forEach(report => {
        if (
          report.isCompleted &&
          report.isRecent &&
          history.findIndex(r => r.PromId === report.PromId) === -1
        ) {
          history.push({
            type: 'prom',
            PromId: report.PromId,
            ModifiedDate: report.ModifiedDate,
            CreatedDate: report.CreatedDate
          });
        }
      });
    }

    return sortBy(history, s => {
      return moment(s.CreatedDate);
    }).reverse();
  }
};

export {
  HISTORY_ACTIONS,
  HISTORY_GETTERS,
  HISTORY_MUTATIONS
} from './definitions';

export default store;

const getLastReport = symptom => {
  const reports = sortByKey(symptom.Reports, 'CreatedDate');
  if (reports.length === 0) {
    return null;
  }

  const report = reports.slice().pop();
  return report;
};
