<template>
  <div>
    <div v-if="loading" class="center-text">
      <loader-vue color="black"></loader-vue>
      <div v-text="$t('generic.loading')"></div>
    </div>
    <div v-else>
      <template v-if="group.InitialPromId">
        <div
          class="container"
          v-if="!sent && group.WelcomeText"
          v-html="$filters.NL2BR(toHtml(group.WelcomeText))"
        ></div>

        <div v-if="!sent">
          <prom-report
            v-if="this.promReport"
            :prom="this.prom"
            :prom-report="this.promReport"
            @save="report => postSave(report)"
            ref="promreport"
          ></prom-report>
        </div>
        <div class="container mt20" v-else>
          <i18n path="forms.thank-you.title" tag="h4" for="thank you"></i18n>
          <br />
          <i18n path="forms.thank-you.body" for="thank you"></i18n>

          <div style="margin-top: 50px">
            <s-button
              :shadow="true"
              :to="{ name: 'start', query: { refreshToken: true } }"
            >
              {{ $t('forms.thank-you.toApp') }}
              <s-right-arrow style="margin-left: 3px"></s-right-arrow>
            </s-button>
          </div>

          <div style="margin-top: 20px">
            <s-button
              to="/logout"
              :dark="true"
              :flat="true"
              class="underline"
              >{{ $t('generic.logout') }}</s-button
            >
          </div>
        </div>
      </template>
      <template v-else>
        <div class="container">Couldn't find a group with that id :(</div>
      </template>
    </div>
  </div>
</template>

<script>
import GroupModel from '@common/Models/GroupModel';
import PromReportModel from '@common/Models/PromReportModel';
import { randomString } from '@common/Helpers/strings';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { APP_MUTATIONS } from '@/store/modules/app';
import GroupService from '@common/Services/Groups/GroupService.js';
import PromReport from '@views/PromReport/components/PromReport.vue';
import { toHtml } from '@common/Helpers/markdown';
import LoaderVue from '@common/Components/Loader.vue';
import {
  PROMREPORT_ACTIONS,
  PROMREPORT_GETTERS
} from '@/store/modules/promreports';
import { PROM_ACTIONS } from '@/store/modules/proms';

export default {
  props: { groupurlslug: String },
  components: {
    LoaderVue,
    PromReport
  },
  data() {
    return {
      group: {
        type: GroupModel,
        default: null
      },
      sent: false,
      loading: false,
      prom: null,
      promReport: null
    };
  },
  beforeRouteLeave(to, from, next) {
    // Refs hack to trigger modal in promreport component...
    if (
      this.$refs.promreport &&
      this.$refs.promreport.savable === true &&
      this.$refs.promreport.confirmCancel === false &&
      this.$refs.promreport.confirmedCancel === false
    ) {
      this.$refs.promreport.confirmCancel = true;
      this.$refs.promreport.redirectOnCancel = to;
      next(false);
    } else {
      next();
    }
  },
  watch: {},
  async mounted() {
    this.$setPageSettings({ option: 'show_header', value: true });

    this.loading = true;
    await GroupService.getGroupByUrlSlug(this.groupurlslug)
      .then(group => (this.group = group))
      .finally(() => (this.loading = false));

    // Redirect if we have already filled in PROM
    await this.$getMyPromReports().then(reports => {
      const promReports = this.$findPromReportsByPromId(
        this.group.InitialPromId
      );
      if (promReports.length > 0) {
        this.$router
          .replace({ name: 'start', query: { refreshToken: true } })
          .catch(e => console.log(e));
      }
    });

    this.$getProm(this.group.InitialPromId).then(prom => {
      this.prom = prom;

      this.promReport = new PromReportModel({
        Id: randomString(),
        PromId: this.prom.Id,
        LanguageId: this.prom.Language,
        QuestionCount: this.prom.QuestionCount
      });
    });
  },
  methods: {
    toHtml,
    ...mapMutations('app', {
      $setPageSettings: APP_MUTATIONS.SET_PAGE_SETTING
    }),
    ...mapActions('promreports', {
      $getMyPromReports: PROMREPORT_ACTIONS.GET_MY_PROMREPORTS
    }),
    ...mapActions('proms', {
      $getProm: PROM_ACTIONS.GET_PROM
    }),
    postSave() {
      if (this.prom.RedirectUri) {
        this.$router.push({ path: this.prom.RedirectUri });
      } else {
        this.sent = true;
      }
    }
  },
  computed: {
    ...mapGetters('promreports', {
      $findPromReportsByPromId: PROMREPORT_GETTERS.FIND_PROMREPORTS_BY_PROMID
    })
  }
};
</script>

<style lang="scss" scoped></style>
