<template>
  <div class="statusmessage">
    <i class="mdi" :class="icon" :style="`color: ${iconColor}`"></i>
    <span v-text="message"></span>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String
    },
    iconColor: {
      type: String
    },
    message: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';

.statusmessage {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  color: #000;
  padding: 10px;
  span {
    font-size: 1.4em;
    font-weight: 700;
    @media only screen and (max-width: $breakpoint-phone) {
      font-size: 1.3em;
    }
  }
  i {
    margin-right: 10px;
    display: inline-block;
    font-size: 2em;
    color: $symptoms-blue;
  }
}
</style>
