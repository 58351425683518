export const NOTES_MUTATIONS = {
  SET_NOTES: 'SET_NOTES',
  SET_NEXT_NOTES_PAGE_EMPTY: 'SET_NEXT_NOTES_PAGE_EMPTY',
  ADD_NOTE: 'ADD_NOTE',
  ADD_NOTES: 'ADD_NOTES',
  NOTE_SAVE_SUCCESS: 'NOTE_SAVE_SUCCESS',
  NOTE_SAVE_FAILURE: 'NOTE_SAVE_FAILURE',
  NOTE_REMOVED_SUCCESS: 'NOTE_REMOVED_SUCCESS'
};

export const NOTES_ACTIONS = {
  $PREINIT: '$PREINIT',
  GET_MY_NOTE: 'GET_MY_NOTE',
  GET_MY_NOTES: 'GET_MY_NOTES',
  CREATE_NOTE: 'CREATE_NOTE',
  UPDATE_NOTE: 'UPDATE_NOTE',
  REMOVE_NOTE: 'REMOVE_NOTE',
  EXPORT_NOTES_BY_GROUP_CSV: 'EXPORT_NOTES_BY_GROUP_CSV'
};

export const NOTES_GETTERS = {
  NOTES: 'NOTES',
  SORTED_NOTES: 'SORTED_NOTES',
  UNSAVED_NOTES: 'UNSAVED_NOTES',
  FIND_NOTE: 'FIND_NOTE',
  NEXT_NOTES_PAGE_EMPTY: 'NEXT_NOTES_PAGE_EMPTY'
};
