import NewsItemModel from '@common/Models/NewsItemModel';
import BaseService from '../BaseService';
import {
  NewsItemResponse,
  NewsItemsResponse
} from './mappers/NewsItemResponses';

class NewsItemService extends BaseService {
  /**
   * Get published news items -- Backend
   * @returns {Promise<NewsItemModel[]>}
   * @memberof NewsItemService
   */
  getPublishedNewsItems() {
    return this.backend('news').then(response =>
      NewsItemsResponse(response.data)
    );
  }

  /**
   * Create news item -- backend
   * @param {NewsItemModel} newsItem
   * @returns {Promise<NewsItemModel>}
   * @memberof NewsItemService
   */
  createNewsItem(newsItem) {
    return this.backend('post', '/admin/news', newsItem).then(response =>
      NewsItemResponse(response.data)
    );
  }

  /**
   * Get all news items -- Backend
   * @returns {Promise<NewsItemModel[]>}
   * @memberof NewsItemService
   */
  getNewsItems() {
    return this.backend('admin/news').then(response =>
      NewsItemsResponse(response.data)
    );
  }

  /**
   * Update news item -- Backend
   * @param {NewsItemModel} newsItem
   * @returns {Promise<NewsItemModel>}
   */
  updateNewsItem(newsItem) {
    return this.backend('put', `admin/news/${newsItem.Id}`, newsItem).then(
      response => NewsItemResponse(response.data)
    );
  }

  /**
   * Remove news item -- backend
   * @param {NewsItemModel} newsItem
   * @returns {Promise}
   * @memberof NewsItemService
   */
  deleteNewsItem(newsItem) {
    return this.backend('delete', `admin/news/${newsItem.Id}`);
  }
}

export default new NewsItemService();
