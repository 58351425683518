export const CONTRACT_MUTATIONS = {
  ADD_CONTRACT: 'ADD_CONTRACT',
  SET_CONTRACTS: 'SET_CONTRACTS',
  REMOVE_CONTRACT: 'REMOVE_CONTRACT'
};

export const CONTRACT_ACTIONS = {
  CREATE_CONTRACT: 'CREATE_CONTRACT',
  DELETE_CONTRACT: 'DELETE_CONTRACT',
  GET_CONTRACT: 'GET_CONTRACT',
  GET_CONTRACTS: 'GET_CONTRACTS',
  GET_ALL_CONTRACTS: 'GET_ALL_CONTRACTS',
  UPDATE_CONTRACT: 'UPDATE_CONTRACT',
  EXPORT: 'EXPORT',
  IMPORT: 'IMPORT'
};

export const CONTRACT_GETTERS = {
  CONTRACTS: 'CONTRACTS',
  FIND_CONTRACT: 'FIND_CONTRACT'
};
