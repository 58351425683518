import { State } from './State'; // eslint-disable-line
import { GETTERS } from './definitions';

export default {
  /** @param {State} state */
  [GETTERS.SETTINGS](state) {
    return {
      interactable: state.interactable,
      controls: state.controls
    };
  },

  /** @param {State} state */
  [GETTERS.REPORT](state) {
    return state.report;
  },

  /** @param {State} state
   *  @returns {'DRAWING','PANNING','ERASING'}
   */
  [GETTERS.ACTIVE_ACTION](state) {
    return state.symptomType;
  },

  /** @param {State} state */
  [GETTERS.ACTIVE_TYPE](state) {
    return state.symptomType;
  },

  /** @param {State} state */
  [GETTERS.ACTIVE_SYMPTOM](state) {
    return state.activeSymptom;
  },

  /** @param {State} state */
  [GETTERS.ACTIVE_MODEL](state) {
    return state.activeModel;
  },

  /** @param {State} state */
  [GETTERS.DRAWING](state) {
    return state.drawing;
  },

  /**
   * @param {State} state
   * @returns {'NORMAL'|'SIMPLE'}
   */
  [GETTERS.MODE](state) {
    return state.mode;
  }
};
