/** @type {import('vue/types/umd').DirectiveOptions} */
export const ScrollToFixedDirective = {
  /**
   * @param {HTMLElement} el
   * @param {*} binding
   */
  beforeMount: function (el, binding) {
    const valueIsUndefinedOrTrue =
      binding.value === undefined ||
      String(binding.value).toLowerCase() === 'true';
    // Only show directive if value in v-scroll-to-fixed="value" hasn't been specified or is true.
    if (!valueIsUndefinedOrTrue) {
      return;
    }

    setTimeout(() => {
      const INITIAL_POSITION = el.offsetTop;
      const INITIAL_WIDTH = window.getComputedStyle(el).width;

      const FILLER = 'scroll-to-fixed-filler';
      const FIXED = 'affixed';
      const CONTAINER = el.dataset.container
        ? document.querySelector(el.dataset.container)
        : window;
      const SCROLL_PAST_ELEMENT = el.dataset.scrollPast;
      const ELEM_HEIGHT = el.getBoundingClientRect().height;
      const FULL_WIDTH = el.dataset.fullWidth;

      const scrollOffset = el.dataset.scrollOffset || 0;
      const margins = {
        left: parseFloat(window.getComputedStyle(el).marginLeft),
        right: parseFloat(window.getComputedStyle(el).marginRight),
        top: parseFloat(window.getComputedStyle(el).marginTop)
      };

      let SCROLLED;

      const scroll = function (event) {
        SCROLLED = el.dataset.container ? CONTAINER.scrollTop : window.scrollY;
        if (!SCROLL_PAST_ELEMENT) {
          if (SCROLLED > scrollOffset) {
            fixElement(el);
          } else {
            unfixElement(el);
          }
        } else {
          const previousFixed = document.querySelector(`.${FIXED}`);
          const scrollPast = SCROLLED > INITIAL_POSITION + ELEM_HEIGHT / 2;
          if (scrollPast) {
            if (previousFixed) {
              unfixElement(previousFixed);
            }
            fixElement(el, INITIAL_POSITION);
          } else if (SCROLLED < INITIAL_POSITION + ELEM_HEIGHT / 2) {
            unfixElement(el);
          }
        }
      };

      const resize = function (event) {
        if (document.querySelector(`.${FILLER}`)) {
          const filler = document.querySelector(`.${FILLER}`);
          const bbox = filler.getBoundingClientRect();
          el.style.left = `${bbox.left + margins.left}px`;
        }
      };

      const fixElement = function (el, initialPosition) {
        const number = el.dataset.num;
        if (document.querySelector(`.${FIXED}[data-num="${number}"]`)) {
          return;
        }

        const elBbox = el.getBoundingClientRect();
        const cloned = el.cloneNode(true);
        cloned.style.position = 'fixed';
        cloned.style.left = `${elBbox.x}px`;
        cloned.style.top = `${scrollOffset}px`;
        cloned.style['z-index'] = 100;

        if (FULL_WIDTH) {
          cloned.style.width = INITIAL_WIDTH;
        }

        cloned.querySelector('.card').style.margin = 0;

        cloned.classList.add(FIXED);

        el.parentNode?.appendChild(cloned);
      };

      const unfixElement = function (el) {
        const number = el.dataset.num;
        if (document.querySelector(`.${FIXED}[data-num="${number}"]`)) {
          document.querySelector(`.${FIXED}[data-num="${number}"]`).remove();
        }
        if (document.querySelector(`.${FILLER}`)) {
          document.querySelector(`.${FILLER}`).remove();
        }
      };

      CONTAINER.addEventListener('scroll', scroll);
      CONTAINER.addEventListener('resize', resize);
    }, 200);
  },
  unbind: function (el, bindind) {
    if (el.parentNode) {
      const cloned = el.cloneNode(true);
      el.parentNode.replaceChild(cloned, el);
    }
  }
};
