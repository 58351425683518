<template>
  <div class="wrapper">
    <div class="vas-slider-wrapper">
      <input type="range" ref="range" :min="min" :max="max" step="1" />
      <div class="vas-slider" ref="scale">
        <div class="vas-slider__toplabel">
          {{ $t('components.vasSlider.bestHealthYouCanImagine') }}
        </div>
        <div class="vas-slider__bottomlabel">
          {{ $t('components.vasSlider.worstHealthYouCanImagine') }}
        </div>
        <div class="vas-slider__pointslabel">
          {{ $t('components.vasSlider.yourHealthToday') }} =
        </div>
        <div class="vas-slider__pointsbox">
          {{ chosenValue }}
        </div>
        <div class="vas-slider__line100">
          <div class="text">{{ max }}</div>
        </div>
        <div class="vas-slider__line50">
          <div class="text">{{ Math.round(max / 2, 0) }}</div>
        </div>
        <div class="vas-slider__line0">
          <div class="text">{{ min }}</div>
        </div>
        <div
          v-for="index in max + 1"
          :key="index"
          :data-number="index"
          class="vas-slider__line"
          :style="{ bottom: `${26 * ((index - 1) * tickSpace)}px` }"
        >
          <div class="text"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import AnswerModel from '@common/Models/AnswerModel';
import rangeSlider from 'rangeslider-pure';
import { toHtml } from '@common/Helpers/markdown';

export default {
  props: [
    'answer',
    'answerInput',
    'question',
    'disabled',
    'isAnswer',
    'isNotSavedAnswer',
    'type',
    'index'
  ],
  data() {
    return {
      editedAnswer: { Value: null },
      slider: null,
      chosenValue: this.answer.Value,
      min: this.question.MinValue,
      max: this.question.MaxValue
    };
  },
  mounted() {},
  watch: {
    answerInput: {
      handler: function (answeredQuestion) {
        if (answeredQuestion) {
          this.chosenValue = Number(answeredQuestion.Value);
        }

        if (this.slider) {
          this.slider.rangeSlider.destroy();
          this.slider = null;
          this.initSlider();
        } else {
          setTimeout(() => this.initSlider(), 0);
        }
      },
      immediate: true
    }
  },
  methods: {
    toHtml,
    setAnswer(value) {
      if (value !== null && value !== undefined) {
        const editedAnswer = new AnswerModel(this.answer.__copy());
        editedAnswer.Value = value;
        this.$emit('setAnswer', editedAnswer);
      } else {
        this.$emit('removeAnswer', this.answer);
      }
    },
    initSlider() {
      this.slider = this.$refs.range;
      rangeSlider.create(this.slider, {
        polyfill: true, // Boolean, if true, custom markup will be created
        rangeClass: 'range-slider',
        disabledClass: 'range-slider--disabled',
        fillClass: 'range-slider__fill',
        bufferClass: 'range-slider__buffer',
        handleClass: 'range-slider__handle',
        vertical: true, // Boolean, if true slider will be displayed in vertical orientation
        min: this.min, // Number
        max: this.max, // Number
        step: null, // Number, 1
        value: this.chosenValue, // Number, center of slider
        stick: null, // [Number stickTo, Number stickRadius] : use it if handle should stick to stickTo-th value in stickRadius
        borderRadius: 10, // Number, if you use buffer + border-radius in css for looks good,
        onInit: () => {},
        onSlide: (position, value) => {
          this.chosenValue = Number(position);
        },
        onSlideEnd: (position, value) => {
          this.setAnswer(this.chosenValue);
        }
      });
    }
  },
  computed: {
    tickSpace() {
      // This is to calculate the space between each tick.
      // Not sure why the magic number 10...
      return 10 / this.max;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';
@import '@sass/_animations.scss';
$scaleColor: #000;
.wrapper {
  padding: 4em;
  display: flex;
  flex: 1;
  -webkit-filter: none;
  filter: none;
  text-align: left;
  position: relative;
}

.vas-slider-wrapper {
  position: relative;
  height: 278px;
  right: 0;
  margin-left: auto;
}

.vas-slider {
  height: 100%;
  position: relative;
  bottom: 0;
  font-size: 0.9em;
  width: 2px;

  .min,
  .max {
    position: relative;
    left: 300px;
  }

  &__buffer {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 2px;
    background: #2c3e50;
    opacity: 0.2;
    z-index: 10;

    &::after {
      height: 2px;
      content: ' ';
      background: #2c3e50;
      position: absolute;
      top: -2px;
      left: -5px;
      width: 7px;
    }
  }

  &__toplabel {
    position: absolute;
    left: -3em;
    top: -3em;
    width: 8em;
  }

  &__bottomlabel {
    position: absolute;
    left: -3em;
    bottom: -4em;
    width: 8em;
  }

  &__pointslabel {
    position: absolute;
    top: 135px;
    left: -15em;
    width: 12em;
  }

  &__pointsbox {
    position: absolute;
    top: 128px;
    left: -4em;
    width: 50px;
    height: 40px;
    border: 1px solid #000;
    text-align: center;
    padding: 0.2em;
  }

  &__line100 {
    left: -1px;
    height: 2px;
    width: 18px;
    top: 16px;
    background-color: $scaleColor;
    color: #000;
    z-index: 9;
    position: absolute;

    .text {
      position: absolute;
      left: 1.4em;
      top: -0.8em;
    }
  }

  &__line50 {
    left: -1px;
    height: 2px;
    width: 18px;
    top: 146px;
    background-color: $scaleColor;
    color: #000;
    z-index: 9;
    position: absolute;

    .text {
      position: absolute;
      left: 1.8em;
      top: -0.8em;
    }
  }

  &__line0 {
    left: -1px;
    height: 2px;
    width: 18px;
    bottom: 0px;
    background-color: $scaleColor;
    color: #000;
    z-index: 9;
    position: absolute;

    .text {
      position: absolute;
      left: 2em;
      top: -0.8em;
    }
  }

  &__line {
    left: 4px;
    height: 1px;
    width: 8px;
    position: absolute;
    background-color: $scaleColor;
    z-index: 8;
    pointer-events: none;
    color: #000;

    .text {
      position: absolute;
      left: 10px;
      top: -7px;
      opacity: 0.4;
      &.active {
        opacity: 1;
      }
    }
  }
}
</style>

<style lang="scss">
@import '@sass/_variables.scss';
.range-slider {
  &__vertical {
    width: 16px;
    min-height: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
    top: 0;
    cursor: pointer;
  }

  &__fill {
    display: none;
  }

  &__handle {
    position: absolute;
    background-color: red;
    bottom: -8px;
    z-index: 10;
    border-radius: 10px;

    width: 16px;
    height: 16px;
    content: ' ';
  }
}
</style>
