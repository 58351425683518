import PatientModel from '@common/Models/PatientModel';
import BaseService from '../BaseService';
import { UpdatePatientRequest } from './mappers/PatientRequests';
import { PatientResponse } from './mappers/PatientResponses';

class PatientService extends BaseService {
  /**
   * Get current users profile -- backend
   * @returns {Promise<PatientModel>}
   * @memberof PatientService
   */
  getMyPatient() {
    return this.backend('my/patient').then(
      response => new PatientModel(response.data)
    );
  }

  /**
   * Update current users profile -- backend
   * @param {PatientModel} profile
   * @returns {Promise<PatientModel>}
   * @memberof PatientService
   */
  updateMyPatient(profile) {
    return this.backend(
      'put',
      'my/patient',
      UpdatePatientRequest(profile)
    ).then(response => new PatientModel(response.data));
  }

  listPatients() {
    return this.backend('admin/patients/shared').then(response =>
      response.data.map(p => new PatientModel(p))
    );
  }

  findPatient(id) {
    return this.backend(`admin/patients/${id}`).then(response =>
      PatientResponse(response.data)
    );
  }

  findPatientByPersonnummer(personnummer) {
    return this.backend(`admin/patients/${personnummer}`).then(response =>
      PatientResponse(response.data)
    );
  }

  getPatientsInGroup() {
    return this.backend('admin/patients/group').then(response =>
      response.data.map(p => new PatientModel(p))
    );
  }

  getPatients() {
    return this.backend('admin/patients').then(response =>
      response.data.map(p => new PatientModel(p))
    );
  }

  getPatientsActivity() {
    return this.backend('admin/patients/activity').then(
      response => response.data
    );
  }

  getAllPatientsData() {
    return this.backend('data').then(response => response.data);
  }

  updatePatient(id, info) {
    return this.backend('put', `admin/patients/${id}`, info).then(
      response => response.data
    );
  }

  deletePatient(id) {
    return this.backend('delete', `admin/patients/${id}`).then(
      response => response.data
    );
  }
}

export default new PatientService();
