import { getActiveRole } from '@common/Helpers/auth';

export function getDefaultLandingPage() {
  const role = getActiveRole();
  switch (role) {
    case 'phys':
    case 'tphys':
      return '/admin/patients';
    case 'admin':
    case 'tadm':
      return '/admin/dashboard';
    case 'mod':
    case 'tmod':
      return '/admin/news';
    case 'res':
    case 'tres':
      return '/admin/research';
    case 'dev':
    case 'tdev':
      return '/admin/oauthtokens';
    default:
      return '/welcome';
  }
}
