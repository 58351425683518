<template>
  <div>
    <div class="container">
      <card-container :full-width="true">
        <card image-height="70" image-color="#303538">
          <template v-slot:header>
            <div class="header">
              <div class="concept">
                {{ $t('components.menuOptions.bugreport') }}
              </div>
            </div>
          </template>
          <template v-slot:text>
            <div class="prom-info">
              <span class="info" v-text="$t('views.bugreport.help')"></span>

              <div class="mt10">
                <textarea
                  id="InputBugReport"
                  @keyup.ctrl.enter="sendable ? sendReport() : undefined"
                  v-model="bugreport.Description"
                  maxlength="1024"
                ></textarea>
                <flex-grid-vue justify="space-between" :full-width="true">
                  <div>
                    <input
                      type="file"
                      ref="files"
                      multiple
                      accept="image/png, image/jpeg"
                      @change="setupFiles"
                    />
                    <span
                      class="valign-wrapper pointer"
                      @click="triggerFileInput"
                    >
                      <i class="mdi mdi-file-upload green-text"></i>
                      {{ $t('views.bugreport.attachScreenshot') }}
                    </span>
                    <label>
                      <input
                        type="checkbox"
                        class="filled-in"
                        v-model="bugreport.Anonymous"
                      />
                      <span
                        v-text="$t('views.bugreport.sendAnonymously')"
                      ></span>
                    </label>
                  </div>
                  <div class="mt10 hidden-desktop"></div>
                  <s-button
                    :disabled="!sendable"
                    :small="true"
                    class="right"
                    :primary="true"
                    :loading="saveLoading"
                    @click="sendReport()"
                    >{{ $t('generic.send') }}</s-button
                  >
                </flex-grid-vue>
              </div>
            </div>
          </template>
        </card>
      </card-container>

      <div v-if="files.length" class="files">
        <h4
          class="sub-header"
          v-text="$tc('plural.attachment', files.length)"
        ></h4>
        <div class="file" v-for="image in files" :key="image.Id">
          <i
            class="mdi mdi-delete pointer"
            :title="$t('views.bugreport.removeScreenshot')"
            @click="removeAttachment(image)"
          ></i>
          <img :src="image.Data" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BugReportService from '@common/Services/BugReports/BugReportService';
import BugReportModel from '@common/Models/BugReportModel';
import BugReportScreenshotModel from '@common/Models/BugReportScreenshotModel';
import FlexGridVue from '@common/Components/Grids/FlexGrid.vue';
import { randomString } from '@common/Helpers/strings';
import storage from '@common/Helpers/storage';

export default {
  components: {
    FlexGridVue
  },
  data() {
    return {
      /** @type {BugReportModel} */
      bugreport: {},
      /** @type {BugReportScreenshotModel[]} */
      files: [],
      saveLoading: false
    };
  },
  watch: {
    bugreport: {
      handler: function (bugreport) {
        if (bugreport.Description) {
          storage.set('unsaved.bugreport', bugreport);
        } else {
          storage.remove('unsaved.bugreport');
        }
      },
      deep: true
    }
  },
  created() {
    if (storage.find('unsaved.bugreport')) {
      this.bugreport = new BugReportModel(storage.get('unsaved.bugreport'));
    } else {
      this.bugreport = new BugReportModel({});
    }
  },
  mounted() {
    const ratio = window.devicePixelRatio;
    const width = Math.round(window.screen.width * ratio);
    const height = Math.round(window.screen.height * ratio);
    this.bugreport.Resolution = `${width}x${height}`;
  },
  methods: {
    setupFiles() {
      const control = this.$refs.files;
      const files = control.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const name = file.name;
        const type = file.type;

        if (this.files.find(x => x.FileName === name)) {
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file, 'UTF-8');
        reader.onload = evt => {
          const base64 = evt.target.result;
          this.files.push(
            new BugReportScreenshotModel({
              Id: randomString(),
              FileName: name,
              MimeType: type,
              Data: base64
            })
          );
        };
      }
    },
    sendReport() {
      this.saveLoading = true;
      this.bugreport.Screenshots = this.files;
      BugReportService.sendReport(this.bugreport)
        .then(() => {
          storage.remove('unsaved.bugreport');
          this.$bus.$emit('toast.display', {
            message: this.$root.$t('views.bugreport.savedSuccessfully'),
            status: 'success'
          });
          this.$router.push('/');
        })
        .catch(() => {
          this.$bus.$emit('toast.display', {
            message: this.$root.$t('generic.saveFailure'),
            status: 'failure'
          });
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },
    removeAttachment(file) {
      const fIndex = this.files.findIndex(f => f.Id === file.Id);
      this.files.splice(fIndex, 1);

      console.log(this.$refs.files.files);
    },
    cancel() {
      this.$router.push('/');
    },
    triggerFileInput() {
      this.$refs.files.click();
    }
  },
  computed: {
    sendable() {
      if (!this.bugreport.Description) {
        return false;
      }
      return this.bugreport.Description.length > 7;
    }
  }
};
</script>

<style lang="scss" scoped>
textarea {
  height: 135px;
}
input[type='file'] {
  display: none;
}
.flex-grid {
  padding-left: 0;
  .button {
    height: fit-content;
  }
  label {
    margin-left: 5px;
    [type='checkbox'] + span:not(.lever) {
      padding-left: 26px;
    }
  }
}
.files {
  .file {
    position: relative;
    display: inline-block;
    &:not(:last-of-type) {
      margin-right: 10px;
    }
    i {
      color: red;
      position: absolute;
      top: -20px;
      right: -10px;
    }
    img {
      max-width: 200px;
    }
  }
}
</style>
