import UserModel from '@common/Models/UserModel';

/**
 * Map remote User to local
 * @param {object} dtoUser
 * @returns {UserModel}
 */
export const UserResponse = dtoUser => {
  return new UserModel(dtoUser);
};

/**
 * Map remote Users to local
 * @param {object[]} dtoUsers
 * @returns {UserModel[]}
 */
export const UsersResponse = dtoUsers => {
  return dtoUsers.map(dto => UserResponse(dto));
};
