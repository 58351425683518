export const PATIENT_MUTATIONS = {
  SET_PATIENTS: 'SET_PATIENTS',
  SET_CONNECTED_PATIENTS: 'SET_CONNECTED_PATIENTS',
  SET_ACTIVE_PATIENT: 'SET_ACTIVE_PATIENT',
  REMOVE_CONNECTED_PATIENT: 'REMOVE_CONNECTED_PATIENT',
  ADD_PATIENT: 'ADD_PATIENT',
  PUSHER_CONNECTED_PATIENT: 'PUSHER_CONNECTED_PATIENT',
  PUSHER_DISCONNECTED_PATIENT: 'PUSHER_DISCONNECTED_PATIENT'
};

export const PATIENT_ACTIONS = {
  $PREINIT: '$PREINIT',
  GET_PATIENTS: 'GET_PATIENTS',
  GET_CONNECTED_PATIENTS: 'GET_CONNECTED_PATIENTS',
  GET_PATIENT_BY_PERSONNUMMER: 'GET_PATIENT_BY_PERSONNUMMER',
  GET_PATIENTS_IN_GROUP: 'GET_PATIENTS_IN_GROUP',
  GET_PATIENT: 'GET_PATIENT',
  INIT_PUSHER_PATIENT: 'INIT_PUSHER_PATIENT',
  DISCONNECT_PUSHER: 'DISCONNECT_PUSHER'
};

export const PATIENT_GETTERS = {
  PATIENTS: 'PATIENTS',
  CONNECTED_PATIENTS: 'CONNECTED_PATIENTS',
  FIND_PATIENT: 'FIND_PATIENT',
  FIND_ACTIVE_PATIENT: 'FIND_ACTIVE_PATIENT',
  FIND_ACTIVE_PATIENT_SYMPTOM: 'FIND_ACTIVE_PATIENT_SYMPTOM',
  FIND_ACTIVE_PATIENT_SYMPTOMREPORTS_FOR_SYMPTOM:
    'FIND_ACTIVE_PATIENT_SYMPTOMREPORTS_FOR_SYMPTOM',
  PATIENT_STATUS_AREA_GRAPH: 'PATIENT_STATUS_AREA_GRAPH',
  PATIENT_SYMPTOM_GRAPH: 'PATIENT_SYMPTOM_GRAPH'
};
