import { PROMEDIT_MUTATIONS } from '@/store/modules/proms/edit';
import { PromResponse } from '@common/Services/PromMetas/mappers/PromResponses';

const saveOnNamespace = ['proms/edit'];
const skipSaveOn = [
  PROMEDIT_MUTATIONS.COPY_ANSWERS,
  PROMEDIT_MUTATIONS.TOGGLE_OPEN_ITEM,
  PROMEDIT_MUTATIONS.UNDO,
  PROMEDIT_MUTATIONS.SET_HISTORY_LENGTH
];
const history = [];
let saveTimeout;
let gotOriginal;

export const undoPlugin = store => {
  store.subscribe((mutation, state) => {
    // eslint-disable-next-line prefer-const
    let [full, namespace, type] = mutation.type.match(/([a-z/]+)([A-Z_]+)/);
    namespace = namespace.replace(/\/$/, '');

    if (type === PROMEDIT_MUTATIONS.UNDO) {
      return restore();
    }

    if (saveOnNamespace.includes(namespace) && !skipSaveOn.includes(type)) {
      if (
        (type === PROMEDIT_MUTATIONS.UPDATE_STATUS_TYPES &&
          mutation.payload.length === 0) ||
        (type === PROMEDIT_MUTATIONS.SET_ACTIVE_PROM && gotOriginal)
      ) {
        return;
      }

      if (saveTimeout) {
        window.clearTimeout(saveTimeout);
      }

      saveTimeout = saveVersion();
      gotOriginal = true;
    }

    function restore() {
      const restoredVersion = history[history.length - 2];
      history.pop();

      if (history.length === 0) {
        saveVersion();
      }
      store.commit(
        `proms/edit/${PROMEDIT_MUTATIONS.SET_ACTIVE_PROM}`,
        restoredVersion
      );
      store.commit(
        `proms/edit/${PROMEDIT_MUTATIONS.SET_HISTORY_LENGTH}`,
        history.length
      );
    }

    function saveVersion() {
      return setTimeout(() => {
        const activeProm = PromResponse(state.proms.edit.prom);
        history.push(activeProm);
        store.commit(
          `proms/edit/${PROMEDIT_MUTATIONS.SET_HISTORY_LENGTH}`,
          history.length
        );

        if (history.length > 5) {
          history.shift();
        }
      }, 800);
    }
  });
};
