<template>
  <info-row
    :image="false"
    :popup="showPopup"
    v-if="symptom"
    v-bind="$attrs"
    @click="
      $router.push({
        name: 'symptomDetails',
        params: { symptomid: symptom.Id }
      })
    "
    :button-styles="{ top: 'calc(50% - 22.5px)' }"
  >
    <template v-slot:title>{{
      symptom.Name || $t(`constants.SymptomTypes['${symptom.lastReport.Type}']`)
    }}</template>

    <template v-slot:meta>
      <span v-show="symptom.Description" v-text="symptom.Description"></span>
    </template>

    <template v-slot:images>
      <symptom-canvas
        :force-model="symptom.Model"
        :symptoms="symptom.lastReport ? [symptom.lastReport] : []"
        angle="front"
        :height="100"
        :width="85"
      ></symptom-canvas>

      <symptom-canvas
        :force-model="symptom.Model"
        :symptoms="symptom.lastReport ? [symptom.lastReport] : []"
        angle="back"
        :height="100"
        :width="85"
      ></symptom-canvas>

      <modal-symptom-details-vue
        :show="editSymptomDetails"
        v-if="editSymptomDetails"
        :symptom="symptom"
        :decline-text="$t('generic.cancel')"
        @close="symptom => this.saveSymptom()"
      ></modal-symptom-details-vue>

      <modal-confirm
        :show="confirmClose"
        :accept-callback="
          () => {
            confirmedCancel = true;
            !symptom.isClosed ? closeSymptom() : removeSymptom();
          }
        "
        :accept-text="
          !symptom.isClosed
            ? $t('generic.symptomOver')
            : $t('generic.deleteShort')
        "
        :show-extras="false"
        :decline-text="$t('generic.cancel')"
        @close="confirmClose = false"
        >{{
          !symptom.isClosed
            ? $t('modals.confirmCloseSymptom')
            : $t('modals.confirmRemoveSymptom')
        }}</modal-confirm
      >
    </template>

    <template v-slot:buttons v-if="!hideButtons">
      <i
        v-if="!hideRemove"
        data-cy="symptom-deletebutton"
        class="mdi"
        :class="
          !symptom.isClosed ? 'mdi-check-outline' : 'mdi-trash-can-outline'
        "
        @click.stop="confirmClose = true"
      ></i>

      <i class="mdi mdi-tag-text-outline" @click.stop="editSymptom()"></i>
      <i
        class="mdi mdi-eye-outline"
        v-if="!hideRemove && !hideEye"
        @click.stop="$emit('set-active', symptom)"
      ></i>
    </template>

    <template v-slot:date>
      {{ $filters.DayOfMonthFormat(symptom.lastReport?.CreatedDate) }}
      <template v-if="symptom.lastReport?.Intensity"
        >&bull; {{ $t('generic.intensity') }}:
        {{ symptom.lastReport?.Intensity }}</template
      >
      {{ symptom.isClosed ? ' &bull; ' : '' }}
      {{ symptom.isClosed ? $filters.DateTimeFormat(symptom.ClosedDate) : '' }}
    </template>
  </info-row>
</template>

<script>
import InfoRow from '@common/Components/InfoRow/InfoRow';
import SymptomCanvas from '@common/Modules/quincy/src/Quincy/components/SymptomCanvas';
import SymptomMixin from './mixins/SymptomMixin';
import SymptomModel from '@common/Models/Symptom/SymptomModel';
import ModalSymptomDetailsVue from '../../modals/ModalSymptomDetails.vue';
import { QUINCYMUTATIONS } from '@common/Modules/quincy/src/store/quincy';
import { mapMutations } from 'vuex';

export default {
  mixins: [SymptomMixin],
  components: {
    InfoRow,
    SymptomCanvas,
    ModalSymptomDetailsVue
  },
  props: {
    symptom: {
      type: SymptomModel,
      required: true
    },
    hideRemove: {
      type: Boolean
    },
    hideEye: {
      type: Boolean
    },
    hideButtons: {
      type: Boolean
    }
  },
  data() {
    return {
      editSymptomDetails: false,
      confirmClose: false
    };
  },
  methods: {
    ...mapMutations('quincy', {
      $saveSymptom: QUINCYMUTATIONS.SAVE_SYMPTOM
    }),
    editSymptom() {
      this.editSymptomDetails = true;
    },
    saveSymptom() {
      this.$saveSymptom(this.symptom);
      this.newSymptomProperties.Name = this.symptom.Name;
      this.newSymptomProperties.Description = this.symptom.Description;
      this.editSymptomDetails = false;
    }
  },
  computed: {},
  mounted() {}
};
</script>

<style></style>
