import StatusReportModel from '@common/Models/StatusReportModel';

/**
 * Map remote status report to local
 * @param {object} dtoStatusReport report from backend
 * @returns {StatusReportModel}
 */
export const StatusReportResponse = dtoStatusReport => {
  return new StatusReportModel(dtoStatusReport);
};

/**
 * Map remote status reports to local
 * @param {object} dtoStatusReports reports from backend
 * @returns {StatusReportModel}
 */
export const StatusReportsResponse = dtoStatusReports => {
  return dtoStatusReports.map(dto => StatusReportResponse(dto));
};
