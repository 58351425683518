<template>
  <div
    class="progress-bar"
    :class="{ sticky, 'full-width': fullWidth }"
    data-cy="progress-bar"
  >
    <div class="progress">
      <div
        class="bar"
        data-cy="progress-bar-bar"
        :style="{ width: progress + '%' }"
        :class="{ green: isComplete }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    progress: Number,
    sticky: {
      type: Boolean,
      default: true
    },
    loader: Boolean
  },
  computed: {
    isComplete() {
      return this.progress >= 100 ? true : false;
    },
    fullWidth() {
      return !!this.$route.meta.noAuthRequired;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';

.progress-bar {
  z-index: 9999;
  &.sticky {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
  }
  .progress {
    height: 8px;
    background-color: #ccc;
    margin: 0;
    .bar {
      height: 100%;
      transition: width 500ms ease-in-out;
      background-color: $symptoms-orange;
    }
  }
}
</style>
