<template>
  <div>
    <prom-requests></prom-requests>
    <prom-suggestions></prom-suggestions>

    <div style="max-width: 100%">
      <h4 class="sub-header" v-text="$t('views.promReport.allProms')"></h4>
      <template v-for="(group, groupIndex) in myOrderedGroups">
        <div
          v-if="getPromsByGroup(group.OrganizationId, group.Id).length > 0"
          :key="groupIndex"
        >
          <h4 class="sub-header">{{ group.DisplayName }}</h4>
          <card-container>
            <card-scroll>
              <card-prom
                v-for="(prom, promIndex) in getPromsByGroup(
                  group.OrganizationId,
                  group.Id
                )"
                :key="promIndex"
                :prom="prom"
              ></card-prom>
            </card-scroll>
          </card-container>
        </div>
      </template>
      <div
        class="row"
        v-if="!$allProms.length"
        v-text="$t('views.promReport.noPromsFound')"
      ></div>
    </div>

    <div v-if="$incompletePromReports.length" style="max-width: 100%">
      <h4 class="sub-header" v-text="$t('views.start.incompleteProms')"></h4>
      <card-container>
        <card-scroll>
          <card-prom-report
            v-for="report in $incompletePromReports"
            :key="report.Id"
            :report="report"
          ></card-prom-report>
        </card-scroll>
      </card-container>
    </div>
  </div>
</template>

<script>
import { orderByKeys } from '@common/Helpers/sorters';
import { PROMREPORT_GETTERS } from '@/store/modules/promreports';
import { mapGetters, mapActions } from 'vuex';
import { PROM_ACTIONS, PROM_GETTERS } from '@/store/modules/proms/definitions';
import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser/definitions';
import PromSuggestions from '@/views/Start/components/PromSuggestions';
import CardProm from '@/views/components/Card/CardProm.vue';
import CardPromReport from '@/views/components/Card/CardPromReport.vue';
import PromRequests from '../Start/components/PromRequests';

export default {
  components: {
    PromSuggestions,
    CardProm,
    CardPromReport,
    PromRequests
  },
  created() {
    this.$getProms();
  },
  methods: {
    ...mapActions('proms', {
      $getProms: PROM_ACTIONS.GET_PROMS
    }),
    getPromsByGroup(orgId, groupId) {
      return this.$allProms.filter(
        p =>
          p.PromMeta.PromGroups.find(
            pg => pg.Group.OrganizationId == orgId && pg.Group.Id == groupId
          ) && p.PromMeta.IsVisible
      );
    }
  },
  computed: {
    ...mapGetters('proms', {
      $allProms: PROM_GETTERS.ALL_SHOWN_PROMS
    }),
    ...mapGetters('promreports', {
      $incompletePromReports: PROMREPORT_GETTERS.FIND_INCOMPLETE_PROMREPORTS
    }),
    ...mapGetters('currentuser', {
      $myGroups: CURRENT_USER_GETTERS.MY_GROUPS
    }),
    myOrderedGroups() {
      return orderByKeys(
        this.$myGroups,
        [
          group => group.DisplayName !== 'Symptoms', // Show Symptoms on top
          group => group.DisplayName
        ],
        ['desc', 'asc']
      );
    }
  }
};
</script>

<style lang="css"></style>
