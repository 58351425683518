// @ts-check

export class ParticipantIdentifierModel {
  constructor({ Type, Description, Value }) {
    this._modelName = 'ParticipantIdentifier';
    this.Type = Type;
    this.Description = Description;
    this.Value = Value;
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }
}

export default ParticipantIdentifierModel;
