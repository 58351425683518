import { FocusDirective } from '@common/Directives/FocusDirective';
import { ReplaceIconDirective } from '@common/Directives/ReplaceIconDirective';
import { ScrollToFixedDirective } from '@common/Directives/ScrollToFixedDirective';
import { ScrollToFixedSimpleDirective } from '@common/Directives/ScrollToFixedSimpleDirective';
import { TippyDirective } from '@common/Directives/TippyDirective';
import { ClickOutsideDirective } from '@common/Directives/ClickOutsideDirective';

export const registerDirectives = app => {
  app.directive('focus', FocusDirective);
  app.directive('replace-icon', ReplaceIconDirective);
  app.directive('scroll-to-fixed', ScrollToFixedDirective);
  app.directive('scroll-to-fixed-simple', ScrollToFixedSimpleDirective);
  app.directive('tippy', TippyDirective);
  app.directive('click-outside', ClickOutsideDirective);
};
