<template>
  <card-container-vue v-if="prom" :full-width="true">
    <div class="prom-header">
      <card
        image-height="80"
        :progress="promReport.percentCompleted"
        :sticky-progress="true"
      >
        <template v-slot:header>
          <div class="pheader">
            <div class="concept" data-cy="prom-title">{{ prom.Concept }}</div>
            <div class="chips">
              <chip-vue>
                {{ promReport.percentCompleted }}%
                {{ $t('generic.completed').toLowerCase() }}
              </chip-vue>
              <chip-vue data-cy="prom-question-count" text-color="#f97c5a">
                {{ prom.questionCount }}
                {{ $tc('plural.question', prom.questionCount).toLowerCase() }}
              </chip-vue>
            </div>
            <div class="shortname" data-cy="prom-shortname">{{ prom.Id }}</div>
          </div>
        </template>
        <template v-slot:text>
          <div class="prom-info clearfix">
            <span class="info" v-html="promInfo"></span>
          </div>
        </template>
      </card>
    </div>
  </card-container-vue>
</template>

<script>
import CardContainerVue from '../Cards/CardContainer.vue';
import PromModel from '@common/Models/PromModel';
import PromReportModel from '@common/Models/PromReportModel';
import ChipVue from '../Chip.vue';
import marked from 'marked';
import { toHtml } from '../../Helpers/markdown';

export default {
  components: { CardContainerVue, ChipVue },
  props: {
    prom: {
      type: PromModel,
      required: true
    },
    promReport: {
      type: PromReportModel,
      required: true
    }
  },
  data() {
    return {
      infoFirst: Boolean,
      promInfo: ''
    };
  },
  watch: {
    'prom.itemsByIndex'(items) {
      if (this.prom.isFirstItemInfo) {
        this.infoFirst = true;
      }
    },
    prom: {
      handler: function (prom) {
        if (!prom) {
          return;
        }
        let info = this.prom.Introduction;
        if (!info && this.prom.itemsByIndex[0] && this.infoFirst) {
          info = this.prom.itemsByIndex[0].Text;
        }
        this.promInfo = toHtml(info);
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    toHtml
  },
  computed: {}
};
</script>

<style lang="scss">
@import '@sass/_variables.scss';
.prom-header {
  display: flex;
  flex: 1;

  .pheader {
    display: grid;
    grid-template-columns: [concept] auto [chips] 1fr;
    grid-auto-rows: [top] 1fr [ident] 0.5fr;
    padding: 0;

    @media only screen and (max-width: $breakpoint-phone) {
      grid-template-columns: auto !important;
      grid-auto-rows: 1fr auto auto !important;
    }

    .concept {
      font-size: 25px;
      font-weight: 700;
      margin-left: 0 !important;
    }
    .shortname {
      color: gray;
      font-weight: 300;
      font-size: 15px;
      margin-top: -5px;

      @media only screen and (max-width: $breakpoint-phone) {
        margin-top: 0;
      }
    }
  }

  .info {
    font-weight: normal;
  }
}
</style>

<style lang="scss">
@import '@sass/_variables.scss';

.prom-header .card {
  .card__image {
    background-color: rgb(48, 53, 56) !important;
  }
  .card__bottom {
    min-height: unset;
  }
}
.prom-header .chips {
  margin-left: 10px;
  @media only screen and (max-width: $breakpoint-phone) {
    margin-left: -2px;
    margin-top: -5px;
  }
  .chip:not(:last-of-type) {
    margin-right: 10px;
  }
}
</style>
