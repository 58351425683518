import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser';
import { Bus } from '@common';
import LanguageStringModel from '@common/Models/LanguageStringModel';
import LanguageStringService from '@common/Services/LanguageStrings/LanguageStringService';
import {
  LANGUAGESTRING_ACTIONS,
  LANGUAGESTRING_GETTERS,
  LANGUAGESTRING_MUTATIONS
} from './definitions';

class State {
  constructor() {
    /** @type {LanguageStringModel[]} */
    this.list = [];
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  /** @param {LanguageStringModel} string */
  [LANGUAGESTRING_MUTATIONS.ADD_STRING](state, string) {
    const exists = state.list.find(
      i => i.Id === string.Id && i.LanguageId === string.LanguageId
    );
    if (!exists) {
      state.list.push(string);
    } else {
      Object.assign(exists, string);
    }
  },
  /** @param {LanguageStringModel} string */
  [LANGUAGESTRING_MUTATIONS.REMOVE_STRING](state, string) {
    const index = state.list.findIndex(x => x.Id === string.Id);
    if (index > -1) {
      state.list.splice(index, 1);
    }
  },
  /** @param {LanguageStringModel[]} strings */
  [LANGUAGESTRING_MUTATIONS.SET_STRINGS](state, strings) {
    state.list = strings;
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  /** @param {LanguageStringModel[]} strings */
  [LANGUAGESTRING_ACTIONS.ADD_STRINGS](context, strings = []) {
    strings.forEach(string => {
      context.dispatch(LANGUAGESTRING_ACTIONS.ADD_STRING, string);
    });
  },
  /** @param {LanguageStringModel} string */
  [LANGUAGESTRING_ACTIONS.ADD_STRING](context, string) {
    return LanguageStringService.addLanguageString(string)
      .then(string =>
        context.commit(LANGUAGESTRING_MUTATIONS.ADD_STRING, string)
      )
      .catch(error => {
        Bus.$emit('toast.display', {
          message: error.Message,
          status: 'error'
        });
        throw error;
      });
  },
  /** @param {LanguageStringModel} string */
  [LANGUAGESTRING_ACTIONS.DELETE_STRING](context, string) {
    return LanguageStringService.deleteLanguageString(string).then(() => {
      context.commit(LANGUAGESTRING_MUTATIONS.REMOVE_STRING, string);
    });
  },
  [LANGUAGESTRING_ACTIONS.GET_STRINGS](context, language) {
    language =
      language ||
      context.rootGetters[
        `currentuser/${CURRENT_USER_GETTERS.ACTIVE_LANGUAGE}`
      ];

    return LanguageStringService.getMyGroupsLanguageStrings(language).then(
      strings => context.commit(LANGUAGESTRING_MUTATIONS.SET_STRINGS, strings)
    );
  },
  /** @param {LanguageStringModel} string */
  [LANGUAGESTRING_ACTIONS.UPDATE_STRING](context, string) {
    return LanguageStringService.updateLanguageString(string).then(() => {
      context.commit(LANGUAGESTRING_MUTATIONS.ADD_STRING, string);
    });
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  /** @returns {LanguageStringModel[]} */
  [LANGUAGESTRING_GETTERS.STRINGS](state) {
    return state.list;
  },
  /** @returns {LanguageStringModel} */
  [LANGUAGESTRING_GETTERS.FIND_STRING]: state => (stringId, languageId) => {
    return state.list.find(
      string => string.Id === stringId && string.LanguageId === languageId
    );
  }
};

export {
  LANGUAGESTRING_ACTIONS,
  LANGUAGESTRING_GETTERS,
  LANGUAGESTRING_MUTATIONS
} from './definitions';

export default store;
