import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser';
import storage from '@common/Helpers/storage';
import PromLanguageVersionService from '@common/Services/PromLanguageVersions/PromLanguageVersionService';
import PromService from '@common/Services/PromMetas/PromService';
import PromModel from '@common/Models/PromModel';
import { PromResponse } from '@common/Services/PromMetas/mappers/PromResponses';
import { isEqual, sortBy } from 'lodash';
import { PROM_ACTIONS, PROM_GETTERS, PROM_MUTATIONS } from './definitions';
import edit from './edit';

class State {
  constructor() {
    /** @type {PromModel[]} */
    this.list = [];
    /** @type {PromModel[]} */
    this.unsaved = [];
    this.loading = false;
  }
}

const store = {
  modules: { edit },
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  [PROM_MUTATIONS.ADD_PROM](state, prom) {
    storage.remove(`prom/${prom.Id}`);

    const exists = state.list.find(p => p.Id === prom.Id);

    if (!exists) {
      state.list.push(prom);
    } else if (prom.Questions.length) {
      Object.assign(exists, prom);
    }

    storage.set(`prom/${prom.Id}`, prom);
  },

  [PROM_MUTATIONS.SET_PROMS](state, { proms, stored = false }) {
    state.list = proms;
    if (stored === true) {
      proms.forEach(prom => storage.set(`prom/${prom.Id}`, prom));
    }
  },

  /** @param {PromModel} prom */
  [PROM_MUTATIONS.ADD_IMPORTED_PROM](state, prom) {
    const exists = state.unsaved.find(p => p.Id === prom.Id);
    if (!exists) {
      state.unsaved.push(prom);
    } else if (prom.Questions.length) {
      Object.assign(exists, prom);
    }
    storage.set(`proms/edit/${prom.Id}`, prom);
  },

  /** @param {PromModel} prom */
  [PROM_MUTATIONS.REMOVE_SAVED_PROM](state, prom) {
    const index = state.list.findIndex(p => p.Id === prom.Id);
    state.list.splice(index, 1);
  },

  /** @param {PromModel} prom */
  [PROM_MUTATIONS.REMOVE_UNSAVED_PROM](state, prom) {
    const index = state.unsaved.findIndex(p => p.Id === prom.Id);
    state.unsaved.splice(index, 1);
  },

  [PROM_MUTATIONS.REMOVE_UNMODIFIED_PROMS](state) {
    const unsavedProms = state.unsaved;
    const unsavedPromIds = unsavedProms.map(prom => prom.Id);
    const storedProms = state.list;
    const storedPromIds = storedProms.map(prom => prom.Id);

    unsavedPromIds.forEach(id => {
      if (!storedPromIds.includes(id)) {
        return;
      }

      const unsavedVersion = unsavedProms.find(p => p.Id === id);
      const savedVersion = storedProms.find(p => p.Id === id);

      if (isEqual(unsavedVersion, savedVersion)) {
        const index = state.unsaved.findIndex(p => p.Id === unsavedVersion.Id);
        state.unsaved.splice(index, 1);
        storage.remove(`proms/edit/${unsavedVersion.Id}`);
      }
    });

    unsavedProms.forEach(prom => {
      if (
        !prom.Title.length ||
        (prom.itemsByIndex.length && prom.Id.length === 21)
      ) {
        const index = state.unsaved.findIndex(p => p.Id === prom.Id);
        storage.remove(`proms/edit/${state.unsaved[index].Id}`);
        state.unsaved.splice(index, 1);
      }
    });
  },

  /** @param {PromModel[]} proms */
  [PROM_MUTATIONS.SET_UNSAVED_PROMS](state, proms) {
    state.unsaved = proms;
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [PROM_ACTIONS.$PREINIT]({ commit, dispatch }, proms) {
    let storedProms = storage.find('prom/');
    if (storedProms.length > 0) {
      storedProms = storedProms.map(prom => PromResponse(prom));
      commit(PROM_MUTATIONS.SET_PROMS, { proms: storedProms, stored: true });
    }
    return dispatch(PROM_ACTIONS.GET_PROMS, proms);
  },

  [PROM_ACTIONS.GET_PROMS](
    { commit, dispatch, getters, rootGetters },
    proms = []
  ) {
    const language =
      rootGetters[`currentuser/${CURRENT_USER_GETTERS.ACTIVE_LANGUAGE}`];

    if (proms && proms.length) {
      commit(PROM_MUTATIONS.SET_PROMS, { proms });
      return Promise.resolve(proms);
    }

    return PromLanguageVersionService.getProms(language)
      .then(proms => {
        commit(PROM_MUTATIONS.SET_PROMS, { proms });
        return proms;
      })
      .catch(error => console.error(String(error)));
  },

  [PROM_ACTIONS.GET_PROM_METAS](context) {
    return PromService.getProms()
      .then(proms => {
        context.commit(PROM_MUTATIONS.SET_PROMS, { proms });
        context.commit(PROM_MUTATIONS.REMOVE_UNMODIFIED_PROMS);
        return proms;
      })
      .catch(error => console.error(String(error)));
  },

  async [PROM_ACTIONS.GET_PROM]({ commit, rootGetters }, promid) {
    let localProm = storage.get(`prom/${promid}`);
    const language =
      rootGetters[`currentuser/${CURRENT_USER_GETTERS.ACTIVE_LANGUAGE}`];

    if (localProm) {
      localProm = PromResponse(localProm);
    }

    if (localProm && !localProm.shouldGetFullProm) {
      commit(PROM_MUTATIONS.ADD_PROM, localProm);
      return Promise.resolve(localProm);
    }

    const promMeta = await PromService.findMyGroupProm(promid);

    return PromLanguageVersionService.findLanguageVersion(promid, language)
      .then(prom => {
        prom.LanguageIds = promMeta.LanguageIds;
        commit(PROM_MUTATIONS.ADD_PROM, prom);
        return prom;
      })
      .catch(error => console.error(String(error)));
  },

  async [PROM_ACTIONS.GET_PROM_BY_LANGUAGE](
    context,
    { promid, language = 'sv' }
  ) {
    let localProm = storage.get(`prom/${promid}`);

    if (localProm) {
      localProm = PromResponse(localProm);
    }

    if (localProm && !localProm.shouldGetFullProm) {
      context.commit(PROM_MUTATIONS.ADD_PROM, localProm);
      return Promise.resolve(localProm);
    }

    // Assume new prom from id length
    if (promid.length >= 20 && promid.length <= 23 && !isRemoteId(promid)) {
      return Promise.reject('');
    }

    const promMeta = await PromService.findProm(promid);

    return PromLanguageVersionService.findLanguageVersion(
      promid,
      language
    ).then(prom => {
      prom.LanguageIds = promMeta.LanguageIds;
      context.commit(PROM_MUTATIONS.ADD_PROM, prom);
      return prom;
    });
  },

  [PROM_ACTIONS.GET_PROM_BY_PROMREQUEST]({ commit }, requestId) {
    return PromLanguageVersionService.getPromByPromRequest(requestId)
      .then(prom => {
        commit(PROM_MUTATIONS.ADD_PROM, prom);
        return prom;
      })
      .catch(error => console.error(String(error)));
  },

  [PROM_ACTIONS.LOAD_UNSAVED_PROMS](context) {
    let proms = storage.find('proms/edit');
    if (!proms) {
      return;
    }
    proms = proms.map(prom => PromResponse(prom));
    context.commit(PROM_MUTATIONS.SET_UNSAVED_PROMS, proms);
    context.commit(PROM_MUTATIONS.REMOVE_UNMODIFIED_PROMS);
  },

  [PROM_ACTIONS.REMOVE_SAVED_PROM](context, prom) {
    PromService.deleteProm(prom).then(() =>
      context.commit(PROM_MUTATIONS.REMOVE_SAVED_PROM, prom)
    );
  },

  [PROM_ACTIONS.REMOVE_UNSAVED_PROM](context, prom) {
    if (storage.find(`proms/edit/${prom.Id}`)) {
      storage.remove(`proms/edit/${prom.Id}`);
    }
    context.commit(PROM_MUTATIONS.REMOVE_UNSAVED_PROM, prom);
  },

  [PROM_ACTIONS.NEW_PROM](context) {
    return new PromModel({});
  },

  /** @param {PromModel} promBlob */
  [PROM_ACTIONS.IMPORT](context, promBlob) {
    // Set as new prom
    if (promBlob.Version === null || promBlob.Version === undefined) {
      promBlob.Version = 0;
    }
    promBlob._saved = false;

    const prom = PromResponse(promBlob);
    context.commit(PROM_MUTATIONS.ADD_IMPORTED_PROM, prom);
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [PROM_GETTERS.FIND_PROM]: state => promid => {
    return state.list.find(p => p.Id === promid);
  },

  [PROM_GETTERS.ALL_PROMS](state, getters, rootState) {
    const proms = state.list.filter(
      prom => state.unsaved.findIndex(up => up.Id === prom.Id) === -1
    );
    return sortBy(proms, 'Concept');
  },

  [PROM_GETTERS.ALL_SHOWN_PROMS](state, getters, rootState, rootGetters) {
    const proms = state.list.filter(
      prom =>
        prom.Language ===
        rootGetters[`currentuser/${CURRENT_USER_GETTERS.ACTIVE_LANGUAGE}`]
    );
    return sortBy(proms, 'Concept');
  },

  [PROM_GETTERS.ALL_UNSAVED_PROMS](state, getters) {
    return state.unsaved;
  },

  [PROM_GETTERS.PROM_STATUS_REPORT_TYPES]:
    (state, getters, rootState, rootGetters) => promid => {
      const group = rootGetters[`currentuser/${CURRENT_USER_GETTERS.GROUP}`];
      return group.StatusReportTypes.filter(type => type.PromId === promid);
    }
};

export { PROM_ACTIONS, PROM_GETTERS, PROM_MUTATIONS } from './definitions';

export default store;
