import { PROM_ACTIONS, PROM_MUTATIONS } from '@/store/modules/proms';
import { Bus } from '@common';
import { ALPHABET } from '@common/constants';
import { removePrivateVariablesFromBody } from '@common/Helpers/object';
import storage from '@common/Helpers/storage';
import AnswerModel from '@common/Models/AnswerModel';
import ConditionalModel from '@common/Models/ConditionalModel';
import InfoModel from '@common/Models/InfoModel';
import PromModel from '@common/Models/PromModel';
import QuestionModel from '@common/Models/QuestionModel';
import StatusReportTypeModel from '@common/Models/StatusReportTypeModel';
import PromLanguageVersionService from '@common/Services/PromLanguageVersions/PromLanguageVersionService';
import PromService from '@common/Services/PromMetas/PromService';
import { PromResponse } from '@common/Services/PromMetas/mappers/PromResponses';
import { saveAs } from 'file-saver';
import {
  PROMEDIT_ACTIONS,
  PROMEDIT_GETTERS,
  PROMEDIT_MUTATIONS
} from './definitions';

class State {
  constructor() {
    /** @type {PromModel} */
    this.prom = null;
    /** @type {Array<String>} */
    this.openItems = [];
    /** @type {Boolean} */
    this.loading = false;
    /** @type {String} */
    this.copiedAnswers = null;
    /** @type {Number} */
    this.history = 0;
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  /** @param {PromModel} prom */
  [PROMEDIT_MUTATIONS.SET_ACTIVE_PROM](state, prom) {
    state.prom = prom;
  },

  /** @param {*} item */
  [PROMEDIT_MUTATIONS.TOGGLE_OPEN_ITEM](state, item) {
    const index = state.openItems.indexOf(item._sortableKey);
    if (index === -1) {
      state.openItems.push(item._sortableKey);
    } else {
      state.openItems.splice(index, 1);
    }
  },

  [PROMEDIT_MUTATIONS.ADD_QUESTION](state) {
    const number = state.prom.Questions.filter(q => q.Level === 1).length + 1;
    const index = state.prom.itemsByIndex.length + 1;
    state.prom.Questions.push(
      new QuestionModel({
        Id: `Q${number}-`,
        SequenceId: number,
        Index: index * 1000,
        QuestionType: 'radiobtn'
      })
    );
  },

  [PROMEDIT_MUTATIONS.REMOVE_QUESTION](state, question) {
    const index = state.prom.Questions.findIndex(
      q => q._sortableKey === question._sortableKey
    );
    if (index > -1) {
      state.prom.Questions.splice(index, 1);
    }
  },

  [PROMEDIT_MUTATIONS.UPDATE_QUESTION_PROPERTY](
    state,
    { question, property, value }
  ) {
    if (property === 'Id') {
      if (!value.match(/^Q\d+[A-Z]?-/)) {
        value = `Q${question.SequenceId}-${value}`;
      }
    } else if (property === 'QuestionType') {
      let answerType;

      switch (value) {
        case 'chbox': {
          answerType = 'multi';
          break;
        }
        case 'dropdown':
        case 'radiobtn': {
          answerType = 'answer';
          break;
        }
        case 'date':
        case 'text': {
          answerType = 'string';
          break;
        }
        case 'number':
        case 'slider': {
          answerType = 'integer';
          break;
        }
      }
      question.Style = null;
      question.AnswerType = answerType;
    }

    question[property] = value;
  },

  [PROMEDIT_MUTATIONS.ADD_INFO](state) {
    const number = state.prom.Infos.length + 1;
    const index = state.prom.itemsByIndex.length + 1;
    state.prom.Infos.push(
      new InfoModel({
        Id: `I${number}-`,
        Index: index * 1000
      })
    );
  },

  /** @param {InfoModel} info */
  [PROMEDIT_MUTATIONS.REMOVE_INFO](state, info) {
    const index = state.prom.Infos.findIndex(
      i => i._sortableKey === info._sortableKey
    );
    if (index > -1) {
      state.prom.Infos.splice(index, 1);
    }
  },

  [PROMEDIT_MUTATIONS.UPDATE_INFO_PROPERTY](state, { info, property, value }) {
    if (property === 'Id') {
      if (!value.match(/^I\d+-/)) {
        value = `I${info.SequenceId}-${value}`;
      }
    }

    info[property] = value;
  },

  [PROMEDIT_MUTATIONS.UPDATE_ITEM_ORDER](state, newList) {
    if (newList) {
      state.prom.Questions = newList.filter(i => i._modelName === 'Question');
      state.prom.Infos = newList.filter(i => i._modelName === 'Info');
    } else {
      newList = state.prom.itemsByIndex;
    }

    // Reorder Questions
    state.prom.Questions.forEach((question, index) => {
      const number = index + 1;
      const previousQuestion = newList[index - 1];
      const itemsIndex = newList.findIndex(
        q => q._sortableKey === question._sortableKey
      );

      if (question.Level === 1 || (question.Level === 2 && !previousQuestion)) {
        question.SequenceId = number;
        // question.Id = question.Id.replace(/Q\d+[A-Z]?(.*)/, `Q${number}$1`);
        question.Index = (itemsIndex + 1) * 1000;
        question.Level = 1;
      } else if (question.Level === 2 && previousQuestion) {
        let letter = 'A';
        let parentQuestion;
        if (previousQuestion.Level === 2) {
          let questionsBefore = 0;

          do {
            const questionBefore = newList[index - 1 - questionsBefore];
            if (questionBefore.Level === 1) {
              parentQuestion = questionBefore;
            } else {
              questionsBefore++;
            }
          } while (!parentQuestion);

          letter = ALPHABET[questionsBefore];

          question.SequenceId = `${parentQuestion.SequenceId}${letter}`;
          question.Id = question.Id.replace(
            /Q[0-9A-Z]+(.*)/,
            `Q${parentQuestion.SequenceId}${letter}$1`
          );
        } else {
          question.SequenceId = `${previousQuestion.SequenceId}${letter}`;
          question.Id = question.Id.replace(
            /Q[0-9A-Z]+(.*)/,
            `Q${previousQuestion.SequenceId}${letter}$1`
          );
          question.Index = previousQuestion.Index + 10;
        }
      }

      question.Answers.forEach((answer, index) => {
        let idText = answer.Id.match(/^Q\d[A-Z]A\d-(.*)$/) || '';
        if (idText.length) {
          idText = idText[1];
        }
        if (question.Level === 1) {
          answer.Id = question.Id.replace(/(Q\d+)-/, `$1A${index + 1}-`);
        } else {
          answer.Id = question.Id.replace(/^(Q\d[A-Z]).*$/, `$1A${index + 1}-`);
        }
        answer.Id += idText;
        answer.Index = (index + 1) * 10 + question.Index;
      });
    });

    // Reorder Infos
    state.prom.Infos.forEach((info, index) => {
      const number = index + 1;
      const itemsIndex = newList.findIndex(
        i => i._sortableKey === info._sortableKey
      );
      info.Id = info.Id.replace(/I\d+(.*)/, `I${number}$1`);
      info.Index = (itemsIndex + 1) * 1000;
    });
  },

  [PROMEDIT_MUTATIONS.UPDATE_META_PROPERTY](state, { property, value }) {
    if (property === 'Id' && storage.find(`proms/edit/${state.prom.Id}`)) {
      storage.remove(`proms/edit/${state.prom.Id}`);
    } else if (property === 'StatusReportTypes') {
      value = value.map(v => new StatusReportTypeModel(v));
    }
    state.prom[property] = value;
  },

  [PROMEDIT_MUTATIONS.UPDATE_STATUS_TYPES](state, newReportTypes = []) {
    const currentReportTypes = state.prom.StatusReportTypes;

    if (newReportTypes.length === 0) {
      newReportTypes = currentReportTypes;
    }

    newReportTypes.forEach(type => {
      if (currentReportTypes.findIndex(t => t.Name === type) === -1) {
        state.prom.StatusReportTypes.push(
          new StatusReportTypeModel({ Name: type, PromId: state.prom.Id })
        );
      }
    });

    currentReportTypes.forEach((type, index) => {
      const typeHasBeenRemoved = newReportTypes.indexOf(type.Name) === -1;
      if (typeHasBeenRemoved) {
        state.prom.StatusReportTypes.splice(index, 1);
      }
    });
  },

  /**
   * @param {Object} payload
   * @param {QuestionModel} payload.question
   * @param {AnswerModel} payload.answer
   * @param {Boolean} payload.prefill
   */
  [PROMEDIT_MUTATIONS.ADD_ANSWER](state, { question, answer, prefill }) {
    const number = question.Answers.length + 1;
    let id;
    if (question.Level === 1) {
      id = question.Id.replace(/(Q\d+)-/, `$1A${number}-`);
    } else if (question.Level === 2) {
      id = question.Id.replace(/^(Q\d[A-Z]).*$/, `$1A${number}-`);
    }

    if (!answer) {
      answer = new AnswerModel({
        Id: id,
        Index: question.Index + number * 10,
        ExportValue: number
      });
      if (prefill) {
        answer.Text = number;
      }
    } else {
      answer.Id = question.Id.replace(/(Q\d+[A-Z])+-/, `$1A${number + 1}`);
      answer.Index = question.Index + number * 10;
    }
    question.Answers.push(answer);
  },

  [PROMEDIT_MUTATIONS.UPDATE_ANSWER_PROPERTY](
    state,
    { answer, property, value }
  ) {
    if (property === 'Id') {
      if (!value.match(/^Q\d[A-Z]A\d-.*$/)) {
        const question = state.prom.Questions.find(question =>
          question.Answers.find(a => a._sortableKey === answer._sortableKey)
        );
        const answerIndex = question.Answers.findIndex(
          a => a._sortableKey === answer._sortableKey
        );
        value = question.Id.replace(
          /(Q\d+[A-Z])-.*$/,
          `$1A${answerIndex + 1}-${value}`
        );
      }
    }

    answer[property] = value;
  },

  [PROMEDIT_MUTATIONS.REMOVE_ANSWER](state, { question, answer }) {
    const index = question.Answers.findIndex(a => a.Id === answer.Id);
    question.Answers.splice(index, 1);
  },

  [PROMEDIT_MUTATIONS.UPDATE_ANSWER_ORDER](state, { question, newOrder }) {
    if (newOrder) {
      question.Answers = newOrder;
    }
    question.Answers.forEach((answer, index) => {
      let idText = answer.Id.match(/^Q\d[A-Z]A\d-(.*)$/) || '';
      if (idText.length) {
        idText = idText[1];
      }
      if (question.Level === 1) {
        answer.Id = question.Id.replace(/(Q\d+)-/, `$1A${index + 1}-`);
      } else {
        answer.Id = question.Id.replace(/^(Q\d[A-Z]).*$/, `$1A${index + 1}-`);
      }
      answer.Id += idText;
      answer.Index = (index + 1) * 10 + question.Index;
    });
  },

  /**
   * @param {AnswerModel[]} answers
   */
  [PROMEDIT_MUTATIONS.COPY_ANSWERS](state, answers) {
    state.copiedAnswers = JSON.stringify(answers);
  },

  [PROMEDIT_MUTATIONS.UNDO](state, version) {},

  /** @param {Number} length */
  [PROMEDIT_MUTATIONS.SET_HISTORY_LENGTH](state, length) {
    state.history = length;
  },

  /**
   * @param {Object} obj
   * @param {QuestionModel} obj.question
   * @param {ConditionalModel[]} obj.conditionals
   */
  [PROMEDIT_MUTATIONS.ADD_CONDITIONALS](state, { question, conditionals }) {
    question.Conditionals = conditionals;
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [PROMEDIT_ACTIONS.GET_ACTIVE_PROM](context, { promid }) {
    if (promid) {
      if (storage.find(`proms/edit/${promid}`)) {
        const currentVersion = PromResponse(
          storage.get(`proms/edit/${promid}`)
        );
        return context.commit(
          PROMEDIT_MUTATIONS.SET_ACTIVE_PROM,
          currentVersion
        );
      } else {
        return context
          .dispatch(
            `proms/${PROM_ACTIONS.GET_PROM_BY_LANGUAGE}`,
            { promid },
            {
              root: true
            }
          )
          .then(prom => {
            context.commit(
              PROMEDIT_MUTATIONS.SET_ACTIVE_PROM,
              PromResponse(prom.__copy())
            );
          })
          .catch(e => {});
      }
    }
    return context.commit(
      PROMEDIT_MUTATIONS.SET_ACTIVE_PROM,
      new PromModel({ Language: 'sv' })
    );
  },

  /** @param {PromModel} prom */
  async [PROMEDIT_ACTIONS.SAVE](context, prom) {
    if (
      (prom.Version === 0 &&
        prom.LanguageIds.length > 0 &&
        prom.LanguageIds.includes(prom.Language)) ||
      prom.LanguageIds.length === 0
    ) {
      await context.dispatch(PROMEDIT_ACTIONS.SAVE_META, prom);
    }
    return await context.dispatch(PROMEDIT_ACTIONS.SAVE_LANGUAGE_VERSION, prom);
  },

  async [PROMEDIT_ACTIONS.SAVE_META](context, prom) {
    return await PromService.addProm(prom)
      .then(() => {
        Bus.$emit('toast.display', {
          message: 'Prom meta saved',
          status: 'success'
        });
      })
      .catch(error => {
        if (error.ErrorCode && error.ErrorCode === 4003) {
          return context.dispatch(PROMEDIT_ACTIONS.SAVE_LANGUAGE_VERSION, prom);
        }
        Bus.$emit('toast.display', {
          message: error.Message,
          status: 'error'
        });
      });
  },

  /** @param {PromModel} prom */
  async [PROMEDIT_ACTIONS.SAVE_LANGUAGE_VERSION](context, prom) {
    let promise;
    if (prom.Version === 0 || !prom.hasLanguageVersion(prom.Language)) {
      promise = PromLanguageVersionService.addPromLanguageVersion(prom);
    } else {
      promise = PromLanguageVersionService.updatePromLanguageVersion(prom);
    }

    return await promise
      .then(prom => {
        context.commit(`proms/${PROM_MUTATIONS.ADD_PROM}`, prom, {
          root: true
        });
        context.commit(`proms/${PROM_MUTATIONS.REMOVE_UNSAVED_PROM}`, prom, {
          root: true
        });
        storage.remove(`proms/edit/${prom.Id}`);
        Bus.$emit('toast.display', {
          message: 'Prom language version saved',
          status: 'success'
        });
      })
      .catch(error => {
        Bus.$emit('toast.display', {
          message: error.Message,
          status: 'failure'
        });
      });
  },

  [PROMEDIT_ACTIONS.EXPORT](context) {
    let exportedProm = new PromModel(context.state.prom);
    exportedProm.Version = 0;
    exportedProm.PromMeta = null;
    exportedProm = removePrivateVariablesFromBody(exportedProm);

    const blob = new Blob([JSON.stringify(exportedProm)], {
      type: 'application/json; charset=utf-8'
    });
    saveAs(blob, `${exportedProm.Id}.json`);
  },

  /** @param {QuestionModel} question */
  [PROMEDIT_ACTIONS.PASTE_ANSWERS](context, question) {
    const answers = JSON.parse(context.state.copiedAnswers).map(
      answer => new AnswerModel(answer)
    );

    answers.forEach(answer =>
      context.commit(PROMEDIT_MUTATIONS.ADD_ANSWER, { question, answer })
    );
    context.commit(PROMEDIT_MUTATIONS.UPDATE_ANSWER_ORDER, { question });
  },

  /** @param {String} language */
  [PROMEDIT_ACTIONS.ADD_LANGUAGE_VERSION](context, language) {
    context.commit(PROMEDIT_MUTATIONS.UPDATE_META_PROPERTY, {
      property: 'Language',
      value: language
    });
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [PROMEDIT_GETTERS.QUESTIONS](state) {
    if (!state.prom) {
      return [];
    }
    return state.prom.Questions;
  },

  [PROMEDIT_GETTERS.OPEN_ITEM](state) {
    return state.openItems;
  },

  [PROMEDIT_GETTERS.IS_ITEM_OPEN]: state => item => {
    return state.openItems.includes(item._sortableKey);
  },

  [PROMEDIT_GETTERS.ITEMS](state) {
    if (!state.prom) {
      return [];
    }
    return state.prom.itemsByIndex;
  },

  [PROMEDIT_GETTERS.HAS_COPIED_ANSWERS](state) {
    return !!state.copiedAnswers;
  },

  [PROMEDIT_GETTERS.CAN_UNDO](state) {
    return state.history > 1;
  },

  [PROMEDIT_GETTERS.IS_META_LOCKED](state) {
    if (!state.prom) {
      return false;
    }
    return state.prom.isMetaLocked;
  }
};

export {
  PROMEDIT_ACTIONS,
  PROMEDIT_GETTERS,
  PROMEDIT_MUTATIONS
} from './definitions';

export default store;
