<template>
  <div class="notes" data-cy="notes">
    <h4 class="container header ml20 hidden-mobile">
      {{ $tc('plural.note', 2) }}
    </h4>
    <div class="container" :class="{ 'hidden-mobile': !showCreateNote }">
      <card-container :full-width="true">
        <card color="white">
          <template v-slot:content>
            <div class="note-inputs">
              <h5
                class="header"
                v-text="$t('views.createNote.createNoteLabel')"
              ></h5>
              <new-note @reset-note="showCreateNote = false"></new-note>
            </div>
          </template>
        </card>
      </card-container>
    </div>

    <div
      class="container hidden-desktop center-text"
      data-cy="create-new-note-mobile-button"
      v-if="!showCreateNote"
    >
      <s-button :primary="true" @click="showCreateNote = true">{{
        $t('views.createNote.createNoteLabel')
      }}</s-button>
    </div>

    <div class="container" data-cy="notes-list">
      <h2 class="sub-header full-width" v-text="$t('views.notes.myNotes')"></h2>
      <card-container
        :full-width="true"
        v-for="(note, index) in $notes"
        :key="index"
        data-cy="notes-note"
        :data-noteid="note.Id"
      >
        <note
          v-if="note._saved"
          :note="note"
          :edit="loadedNote && loadedNote.Id === note.Id"
        ></note>
      </card-container>
      <div class="center">
        <s-button
          v-if="$notes.length != 0 && !$nextNotesPageEmpty"
          @click="loadMore()"
          :primary="true"
        >
          {{ $t('generic.loadMore') }}
        </s-button>
      </div>
      <div v-if="$notes.length === 0">{{ $t('views.notes.noNotesFound') }}</div>
    </div>
  </div>
</template>

<script>
import Note from '@views/components/Card/CardNote';
import { mapActions, mapGetters } from 'vuex';
import { NOTES_ACTIONS, NOTES_GETTERS } from '@/store/modules/notes';
import NewNote from './components/NewNote.vue';
import { smoothScroll } from '@common/Helpers/html';

export default {
  name: 'NotesView',
  components: {
    NewNote,
    Note
  },
  props: {
    noteId: String
  },
  data() {
    return {
      createNote: false,
      showCreateNote: false,
      page: 1,
      pageSize: 30
    };
  },

  watch: {
    noteId: {
      handler: function (noteId) {
        if (noteId && this.loadedNote) {
          this.createNote = true;
          setTimeout(() => this.scrollToNote(), 500);
        } else {
          this.createNote = false;
        }
      },
      immediate: true
    }
  },

  mounted() {
    if (this.$notes.length == 0) {
      this.$getMyNotes({ page: this.page, take: this.pageSize });
    }

    if (this.note !== undefined) {
      this.loadNote(this.noteId);
    }
  },

  methods: {
    ...mapActions('notes', {
      $getMyNotes: NOTES_ACTIONS.GET_MY_NOTES
    }),
    closeModal() {
      this.createNote = false;
      this.$router.replace({ name: 'notes' });
    },
    scrollToNote() {
      smoothScroll(document.querySelector(`[data-noteid="${this.noteId}"]`), {
        behavior: 'smooth'
      });
    },
    loadMore() {
      this.page++;
      this.$getMyNotes({ page: this.page, take: this.pageSize });
    }
  },
  computed: {
    ...mapGetters('notes', {
      $findNote: NOTES_GETTERS.FIND_NOTE,
      $notes: NOTES_GETTERS.NOTES,
      $nextNotesPageEmpty: NOTES_GETTERS.NEXT_NOTES_PAGE_EMPTY
    }),
    loadedNote() {
      if (this.noteId === undefined) {
        return undefined;
      }
      return this.$findNote(this.noteId);
    }
  }
};
</script>

<style lang="scss" scoped>
h4.header {
  margin-top: 3px;
}
.note-inputs {
  padding: 20px;
}
.center {
  text-align: center;
}

button {
  margin: 0 auto;
}
</style>
