// @ts-check
import MappedItemModel from '@common/Models/MappedItemModel';
import ParticipantModel from '@common/Models/ParticipantModel';

export class MappedExportModel {
  constructor({ Items = [], Participant = null }) {
    this._modelName = 'MappedExportModel';
    this.Items = Items.map(i => new MappedItemModel(i));
    this.Participant = Participant ? new ParticipantModel(Participant) : null;
  }

  static get validations() {
    return {};
  }

  __copy() {
    return new MappedExportModel(JSON.parse(JSON.stringify(this)));
  }
}

export default MappedExportModel;
