// @ts-check
import { randomString } from '@common/Helpers/strings';
import { required } from '@vuelidate/validators';

export class ContractParagraphModel {
  constructor({ Index, Text }) {
    this.Text = Text;
    this.Index = Index;

    this._sortableKey = randomString();
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }

  static get validations() {
    return {
      Text: {
        required
      },
      Index: {}
    };
  }
}

export default ContractParagraphModel;
