import PromModel from '@common/Models/PromModel';
import PromReportModel from '@common/Models/PromReportModel';
import PromRequestModel from '@common/Models/PromRequestModel';
import BaseService from '../BaseService';
import { NewPromReportRequest } from '../PromReports/mappers/PromReportRequests';
import {
  PromRequestResponse,
  PromRequestsResponse
} from './mappers/PromRequestResponses';

class PromRequestService extends BaseService {
  /**
   * Get current user's PromRequests -- backend
   * @returns {Promise<PromRequestModel[]>}
   * @memberof PromRequestService
   */
  getMyPromRequests() {
    return this.backend('my/promrequests').then(response =>
      PromRequestsResponse(response.data)
    );
  }

  /**
   * Get PromRequest -- backend
   * @param {string} RequestId
   * @returns {Promise<PromRequestModel>}
   * @memberof PromRequestService
   */
  getRequest(RequestId) {
    return this.backend(`promrequests/${RequestId}`).then(response =>
      PromRequestResponse(response.data)
    );
  }

  /**
   * Fill PromRequest -- backend
   * @param {string} requestId
   * @param {PromReportModel} report
   * @returns {Promise<PromRequestModel>}
   * @memberof PromRequestService
   */
  fillRequest(requestId, report) {
    return this.backend(
      'post',
      `promrequests/fill/${requestId}`,
      NewPromReportRequest(report)
    ).then(response => PromRequestResponse(response.data));
  }

  /**
   * Find PromRequest
   * @param {String} requestId
   * @returns {Promise<PromRequestModel>}
   */
  findPromRequest(requestId) {
    return this.backend(`admin/promrequests/${requestId}`).then(response =>
      PromRequestResponse(response.data)
    );
  }

  /**
   * Get all prom requests
   * @returns {Promise<PromRequestModel[]>}
   */
  getAllPromRequests() {
    return this.backend('admin/promrequests').then(response =>
      PromRequestsResponse(response.data)
    );
  }

  /**
   * Get all prom requests
   * @param {String} patientId
   * @returns {Promise<PromRequestModel[]>}
   */
  getPromRequestsByPatient(patientId) {
    return this.backend(`research/patients/${patientId}/promrequests`).then(
      response => PromRequestsResponse(response.data)
    );
  }

  /**
   * Create promrequest
   * @param {object} promRequest
   * @param {PromModel} promRequest.prom
   * @param {string} promRequest.LanguageId
   * @param {string} promRequest.Personnummer
   * @param {string} promRequest.Purpose
   * @returns {Promise<PromRequestModel>}
   */
  createPromRequest(promRequest) {
    if (promRequest.Personnummer) {
      promRequest.Personnummer = promRequest.Personnummer.replace('-', '');
    }

    return this.backend('post', 'research/promrequests', promRequest).then(
      response => PromRequestResponse(response.data)
    );
  }

  /**
   * delete PromRequest -- backend
   * @param {string} requestId
   * @returns {Promise}
   * @memberof PromRequestService
   */
  deleteRequest(requestId) {
    return this.backend('delete', `research/promrequests/${requestId}`);
  }
}

export default new PromRequestService();
