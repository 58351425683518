import PromRequestModel from '@common/Models/PromRequestModel';

/**
 * Map remote PromRequest to local
 * @param {object} dtoPromRequest
 * @returns {PromRequestModel}
 */
export const PromRequestResponse = dtoPromRequest => {
  return new PromRequestModel(dtoPromRequest);
};

/**
 * Map remote PromRequests to local
 * @param {object[]} dtoPromRequests
 * @returns {PromRequestModel[]}
 */
export const PromRequestsResponse = dtoPromRequests => {
  return dtoPromRequests.map(dto => PromRequestResponse(dto));
};
