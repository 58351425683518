import BaseService from '../BaseService';
import LanguageModel from '@common/Models/LanguageModel';
import { LanguagesResponse } from './mappers/LanguageResponses';

class LanguageService extends BaseService {
  /**
   * Get languages -- backend
   * @returns {Promise<LanguageModel[]>}
   * @memberof LanguageService
   */
  getLanguages() {
    return this.backend('languages').then(response =>
      LanguagesResponse(response.data)
    );
  }
}

export default new LanguageService();
