export const GetErrorCodeMessage = {
  methods: {
    $_GetErrorCodeMessage(type, error, defaultMessage) {
      let message = this.$t(`error-codes.${type}.${error.ErrorCode}`);
      if (!message) {
        message = defaultMessage;
      }
      return message;
    }
  }
};
