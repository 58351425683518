<template>
  <div
    class="card"
    :class="{
      active,
      pointer: hasClickFunction,
      'card--no-shadow': noShadow || flat
    }"
    :style="{
      'background-color': color || null,
      'max-width': maxWidth,
      height: height || null
    }"
    v-bind="$attrs"
  >
    <template v-if="hasOnlyContent">
      <div class="card__content" :style="{ 'background-color': color || null }">
        <div class="card__extra-buttons">
          <slot name="extra-buttons"></slot>
        </div>

        <div class="card__remove-button" v-if="removeFunction">
          <i class="mdi mdi-close" @click.stop="remove()"></i>
        </div>
        <slot name="content"></slot>
      </div>
    </template>

    <template v-else>
      <div
        class="card__image"
        :style="{ height: `${imageHeight}px`, backgroundColor: imageColor }"
      >
        <div class="card__remove-button" v-if="removeFunction">
          <i class="mdi mdi-close-circle" @click.stop="removeFunction"></i>
        </div>

        <div
          class="card__image-content"
          :style="{ height: `${imageHeight}px` }"
        >
          <slot name="image-content"></slot>
        </div>
        <div
          v-if="image"
          class="card__background"
          :style="{
            backgroundImage: backgroundImageCss,
            height: `${imageHeight}px`
          }"
        ></div>
      </div>

      <div class="card__progress" v-if="hasProgress">
        <progress-bar-vue
          :progress="progress"
          :sticky="false"
        ></progress-bar-vue>
      </div>

      <div
        class="card__bottom"
        :class="{ 'card__bottom--flat': flat }"
        v-if="!hasOnlyGraphContent"
      >
        <div class="card__header">
          <slot name="header"></slot>
        </div>
        <div class="bottom-content" v-if="hasBottomContent">
          <div class="bottom-text">
            <slot name="text"></slot>
          </div>

          <div class="bottom-button center-align" v-if="hasButton">
            <slot name="button"></slot>
          </div>

          <div class="bottom-meta" v-if="hasMeta">
            <slot name="meta"></slot>
          </div>
        </div>
      </div>

      <div class="card__graph" v-else>
        <slot name="graph"></slot>
      </div>
    </template>

    <teleport to="#progress-bar" v-if="!progressVisible">
      <progress-bar-vue
        :progress="progress"
        :full-width="fullWidth"
      ></progress-bar-vue>
    </teleport>

    <modal-confirm
      :show="confirmRemoveVisible"
      :accept-callback="
        () => {
          confirmRemoveVisible = false;
          removeFunction();
        }
      "
      :accept-text="$t('generic.yes')"
      :show-extras="false"
      accept-classes="button--blue"
      :decline-text="$t('generic.no')"
      @close="confirmRemoveVisible = false"
      >{{
        confirmRemoveText ? confirmRemoveText : $t('modals.areYouSure')
      }}</modal-confirm
    >
  </div>
</template>

<script>
import ProgressBarVue from '@common/Components/ProgressBar.vue';

export default {
  components: { ProgressBarVue },
  props: {
    image: String,
    imageHeight: String,
    height: String,
    imageColor: {
      type: String,
      default: 'transparent'
    },
    maxWidth: {
      type: String,
      default: '335px'
    },
    removeFunction: Function,
    confirmRemove: {
      type: Boolean,
      default: true
    },
    confirmRemoveText: {
      type: String,
      default: null
    },
    color: String,
    noShadow: Boolean,
    flat: Boolean,
    progress: Number,
    active: Boolean,
    stickyProgress: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      progressYPos: 0,
      progressVisible: false,
      confirmRemoveVisible: false,
      fullWidth: false
    };
  },
  mounted() {
    if (this.stickyProgress) {
      setTimeout(() => {
        this.setProgressYPos();
        this.scroll();
      }, 0);
      document
        .querySelector('.content')
        .addEventListener('scroll', this.scroll.bind(this));
    } else {
      this.progressVisible = true;
    }
    const content = document.querySelector('.content');
    const contentStyles = window.getComputedStyle(content, null);
    this.fullWidth = contentStyles.getPropertyValue('padding-left') === 0;
  },
  beforeUnmount() {
    if (!document.querySelector('.content')) {
      return;
    }
    document
      .querySelector('.content')
      .removeEventListener('scroll', this.scroll.bind(this));
  },
  methods: {
    remove() {
      if (this.disabled) {
        return;
      }

      if (this.confirmRemove) {
        this.confirmRemoveVisible = true;
      } else {
        this.removeFunction();
      }
    },
    setProgressYPos() {
      const bar = document.querySelector('.prom-header .card__progress');
      if (bar) {
        this.progressYPos = bar.getBoundingClientRect().top;
      }
    },
    scroll() {
      const scrollAmount = document.querySelector('.content').scrollTop;
      const headerHeight = document
        .querySelector('header.app-header')
        .getBoundingClientRect().height;
      if (scrollAmount + headerHeight > this.progressYPos) {
        this.progressVisible = false;
      } else {
        this.progressVisible = true;
      }
    }
  },
  computed: {
    backgroundImageCss() {
      if (!this.image) {
        return '';
      }
      return `url(${this.image})`;
    },
    hasOnlyContent() {
      return !!this.$slots.content;
    },
    hasButton() {
      return !!this.$slots.button;
    },
    hasText() {
      return !!this.$slots.text;
    },
    hasRemoveButton() {
      return !!this.$slots['remove-button'];
    },
    hasMeta() {
      return !!this.$slots.meta;
    },
    hasOnlyGraphContent() {
      return !!this.$slots.graph;
    },
    hasProgress() {
      return this.progress !== undefined;
    },
    hasClickFunction() {
      return this.$attrs.hasOwnProperty('onClick');
    },
    hasBottomContent() {
      return this.hasText || this.hasMeta || this.hasButton;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_animations.scss';
@import '@sass/_variables.scss';

$borderRadius: 15px;

.card {
  min-width: 335px;
  max-width: 335px;
  border-radius: 15px;
  box-shadow: 0 20px 25px 0 rgba(73, 73, 73, 0.1);
  background-color: $background-color;
  position: relative;
  display: flex;
  flex-direction: column;

  hr {
    border: 0;
    background-color: darken($background-color, 15%);
    height: 1px;
  }

  &--no-shadow {
    box-shadow: none;
  }

  &__content {
    border-radius: 15px;

    .header {
      font-weight: 700;
    }
  }

  &__image {
    background-color: $background-color;
    color: white;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    overflow: hidden;
    position: relative;
  }

  &__remove-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #ccc;
    z-index: 99;
    cursor: pointer;
    .mdi {
      line-height: 30px;
    }
  }

  &__extra-buttons {
    position: absolute;
    top: 5px;
    right: 50px;
    z-index: 99;
    cursor: pointer;
  }

  .card__image {
    .card__remove-button {
      top: 0px;
      right: 0px;
      color: #000;
    }
  }

  &__image-content {
    z-index: 2;
    display: flex;
    flex-direction: column;
    span {
      margin-top: 10px;
      font-family: 'Dosis', sans-serif;
      font-size: 32px;
      font-weight: 500;
      text-shadow: 2px 5px 5px rgba(0, 0, 0, 1);
    }
  }

  &__progress {
    position: relative;
  }

  &__background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-size: cover;
    background-position: center;
    // animation: fadein 1s linear;
  }

  &__bottom {
    background-color: #fff;
    color: black;
    padding: 20px 24px 17px 24px;
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    min-height: 166px;
    flex: 1;

    &--flat {
      background-color: $background-color;
    }

    .card__header {
      font-weight: 700;
      font-size: 25px;
      color: #122033;
      margin-bottom: 4px;
      overflow: hidden;
      text-overflow: ellipsis;

      @media only screen and (max-width: $breakpoint-phone) {
        font-size: 20px;
      }
    }

    .bottom-content {
      font-size: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 88px;
      flex: 1;
      white-space: normal;
    }

    .bottom-button {
      margin-top: 22px;
    }

    .bottom-meta {
      display: flex;
      opacity: 1;
      font-size: 11px;
    }
  }
}
@media only screen and (max-width: $breakpoint-phone) {
  .card {
    min-width: calc(100vw - 40px);
    max-width: calc(100vw - 40px);
  }
}
</style>

<style lang="scss">
@import '@sass/_variables.scss';

.card {
  &__extra-buttons {
    svg {
      width: 35px;
      cursor: pointer;
      &:not(:last-of-type) {
        margin-right: 10px;
      }
      @media only screen and (max-width: $breakpoint-phone-small) {
        width: 30px;
      }
    }

    .circle {
      fill: none;
      stroke: rgb(156, 156, 156);
    }
  }
}
</style>
