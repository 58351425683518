<template>
  <div class="input-field" style="margin: 0">
    <i class="mdi mdi-calendar-range prefix" style="color: #bababa"></i>
    <input
      :placeholder="toHtml(answer.Text)"
      :value="date"
      type="text"
      :disabled="disabled"
      class="datepicker"
      @change="setAnswer"
      :ref="id"
    />
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import AnswerModel from '@common/Models/AnswerModel';
import { toHtml } from '@common/Helpers/markdown';
import FormSetup from '@common/Mixins/FormSetup';
import * as moment from 'moment';
export default {
  props: [
    'answer',
    'answerInput',
    'question',
    'disabled',
    'isAnswer',
    'isNotSavedAnswer',
    'type',
    'index'
  ],
  data() {
    return {
      id: `datepicker-${Math.random()}`,
      date: this.answerInput ? this.answerInput.Value : null
    };
  },
  mixins: [FormSetup],
  watch: {
    answerInput() {
      this.date = this.answerInput ? this.answerInput.Value : '';
    }
  },
  methods: {
    toHtml,
    setAnswer(date) {
      const value = date.target ? date.target.value : date;

      if (String(value).length) {
        const editedAnswer = new AnswerModel(this.answer.__copy());

        editedAnswer.Value = moment(value).format('YYYY-MM-DD');
        this.$emit('setAnswer', editedAnswer, true);
      } else {
        this.$emit('removeAnswer', this.answer);
      }
    }
  }
};
</script>

<style lang="css"></style>
