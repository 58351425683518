<template>
  <div
    class="info-row"
    :class="[
      `info-row--background-${backgroundColor}`,
      {
        'info-row--no-image': !hasImage && !hasIntensity,
        'info-row--content-padding': contentPadding,
        'info-row--remove-loading': removeLoading,
        'info-row--active': active,
        pointer: hasClick,
        'info-row--progress': progress,
        selectable: selectable
      }
    ]"
    v-bind="$attrs"
  >
    <div class="info-row__image" v-if="hasImage">
      <slot name="image"></slot>
    </div>

    <div class="info-row__intensity" v-if="hasIntensity">
      <slot name="intensity"></slot>
    </div>

    <div class="info-row__content">
      <div
        class="info-row__title"
        :class="{
          'info-row__title--light': lightTitle,
          'info-row__title--standalone': !hasMeta && !hasDate
        }"
        data-cy="inforow-title"
      >
        <slot name="title"></slot>
      </div>
      <div class="info-row__meta truncate" v-show="hasMeta">
        <slot name="meta"></slot>
      </div>
      <div class="info-row__images" v-if="hasImages">
        <slot name="images"></slot>
      </div>

      <div class="info-row__date" v-if="hasDate" data-cy="inforow-date">
        <slot name="date"></slot>
      </div>

      <div class="info-row__info">
        <slot name="info"></slot>
      </div>

      <div class="info-row__buttons" :style="buttonStyles" v-if="icon">
        <slot name="buttons">
          <i class="mdi mdi-chevron-right"></i>
        </slot>
      </div>
    </div>

    <div class="info-row__progress" v-if="progress">
      <div class="bar" :style="{ width: progress + '%' }"></div>
    </div>

    <inline-modal
      class="info-row__popup"
      v-if="popup"
      :has-progress="!!progress"
    >
      <slot name="popup"></slot>
    </inline-modal>
  </div>
</template>

<script>
import InlineModal from '@common/Components/InlineModal.vue';
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    popup: {
      type: Boolean
    },
    icon: {
      type: Boolean,
      default: true
    },
    lightTitle: {
      type: Boolean
    },
    contentPadding: {
      type: Boolean
    },
    backgroundColor: {
      type: String
    },
    removeLoading: {
      type: Boolean
    },
    buttonStyles: {
      type: Object
    },
    progress: {
      type: Number
    },
    selectable: {
      type: Boolean,
      default: false
    }
  },
  components: { InlineModal },
  watch: {
    removeLoading(value) {
      console.log('remove', value);
    }
  },
  computed: {
    hasClick() {
      return !!this.$attrs.onClick;
    },
    hasMeta() {
      return !!this.$slots.meta;
    },
    hasImage() {
      return !!this.$slots.image;
    },
    hasIntensity() {
      return !!this.$slots.intensity;
    },
    hasImages() {
      return !!this.$slots.images;
    },
    hasDate() {
      return !!this.$slots.date;
    }
  }
};
</script>

<style lang="scss">
@import '@sass/_variables.scss';

.container a:first-of-type .info-row,
.container .info-row:first-of-type {
  margin-top: 0;
}
.container .info-row ~ a:first-of-type {
  margin-top: 20px;
}

.container a ~ .info-row {
  margin-bottom: 20px;
}

.info-row {
  background-color: #fff;
  color: black;
  display: flex;
  flex-direction: row;
  min-height: 70px;
  position: relative;
  transition: opacity 0.2s ease-out;
  box-shadow: 0 20px 25px 0 rgba(73, 73, 73, 0.1);
  overflow: hidden;
  margin-bottom: 20px;

  &:not(.info-row--progress) {
    border-radius: 15px;
  }

  &.info-row--progress {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  &--background-yellow {
    background-color: rgb(229, 233, 182);
  }

  &--background-green {
    background-color: rgb(192, 233, 182);
  }

  &--background-active {
    background-color: rgb(48, 53, 56);

    .info-row__title {
      color: #fff;
    }

    .info-row__date,
    .info-row__meta {
      color: rgb(236, 235, 235);
    }
  }

  .info-row__progress {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    .bar {
      height: 2px;
      background-color: $symptoms-orange;
    }
  }

  &--active {
    background-color: rgba(229, 233, 182, 0.3);
  }

  &--remove-loading {
    background-color: rgb(229, 233, 182);
    opacity: 0.3;
    pointer-events: none;
    cursor: default !important;
  }

  &--no-image {
    padding: $padding 0 $padding $padding;
  }

  i {
    color: $symptoms-blue;
    display: inline-flex;
  }

  &__image,
  &__icon {
    flex: 0.2;
    max-width: 50px;
    min-width: 50px;
    text-align: center;
  }

  &__intensity {
    width: 100%;
    min-width: 50px;
    height: 100%;
    font-family: Dosis;
    flex: 0.2;
    .colored-background {
      display: flex;
      align-content: center;
      justify-content: center;
      height: 70px;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      flex-direction: column;

      span {
        font-size: 1.9em;
        align-self: center;
      }

      span.small {
        font-size: 1em;
        line-height: 1em;
      }
    }
  }

  &__image,
  &__intensity,
  &__icon,
  &__content {
    align-self: center;
    justify-self: center;
    word-break: break-all;
  }

  &__info {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  &__buttons {
    position: absolute;
    right: 15px;
    top: 3px;
    i {
      color: rgb(145, 145, 145) !important;
    }

    .mdi-chevron-right {
      position: absolute;
      right: -5px;
      top: 0;
      color: rgb(197, 197, 197) !important;
    }

    * {
      &:hover {
        cursor: pointer;
      }
      &:not(:last-of-type) {
        margin-right: 15px;
      }
    }
  }

  &__content {
    flex: 2;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    max-width: calc(100% - 50px);
    // padding: 10px 0;
  }

  &__title {
    font-weight: 700;
    align-self: center;
    margin-right: 10px;
    word-break: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 30px);
    .mdi {
      font-size: inherit;
      line-height: inherit;
    }

    &--standalone {
      align-self: center;
      font-size: larger;
    }

    &--light {
      font-weight: normal;
    }

    &--extra {
      color: #999;
    }
  }

  &__date,
  &__meta {
    font-size: smaller;
    color: #777;

    @media only screen and (max-width: $breakpoint-phone) {
      font-size: 11px;
    }

    .mdi {
      font-size: inherit;
      line-height: inherit;
    }
  }

  @media only screen and (max-width: $breakpoint-phone) {
    // &__content {
    //   padding-left: 10px;
    // }
    // &__image {
    //   display: none;
    // }
    &__buttons {
      top: 8px;
      .mdi {
        font-size: 20px;
      }

      .mdi-chevron-right {
        // top: 15px;
      }
    }
  }
}

.info-row--content-padding {
  .info-row__content,
  .info-row__buttons {
    padding-top: 15px;
  }
  .info-row__date {
    padding-bottom: 15px;
  }
  .info-row__content {
    padding-right: 35px;
  }
}

.info-row--no-image {
  .info-row__content {
    max-width: 100%;
  }
}
</style>

<style lang="scss">
.container *:not(:last-child) .info-row {
  margin-bottom: 20px;
}
</style>
