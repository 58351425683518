import AuditLogModel from '@common/Models/AuditLogModel';

/**
 * Map remote AuditLog to local
 * @param {object} dtoAuditLog
 * @returns {AuditLogModel}
 */
export const AuditLogResponse = dtoAuditLog => {
  return new AuditLogModel(dtoAuditLog);
};

/**
 * Map remote AuditLogs to local
 * @param {object[]} dtoAuditLogs
 * @returns {AuditLogModel[]}
 */
export const AuditLogsResponse = dtoAuditLogs => {
  return dtoAuditLogs.map(dto => AuditLogResponse(dto));
};
