// @ts-check
export class AnsweredQuestionModel {
  constructor({
    Id = '',
    QuestionId,
    AnswerIds,
    Value = null,
    Milliseconds,
    saved,
    NotApplicable
  }) {
    this._modelName = 'AnsweredQuestion';
    this.Id = Id || null;
    this.QuestionId = QuestionId;
    this.AnswerIds = AnswerIds;
    this.Value = Value || '';
    this.CreatedDate = new Date();
    this.saved = saved !== undefined ? saved : true;
    this.Milliseconds = Milliseconds || 0;
    this.NotApplicable = NotApplicable || false;
  }
}

export default AnsweredQuestionModel;
