import { http } from '@common/Services/external/papapi/http.js';

export class papapi {
  constructor(token) {
    this._token = token;
    this._http = http(token);
  }

  _addSpace(string, index = 3) {
    const before = string.split('').slice(0, index).join('');
    const after = string.split('').slice(index).join('');
    return `${before} ${after}`;
  }

  zipcode(number) {
    const formattedNumber =
      number[3] === ' ' ? number : this._addSpace(String(number));
    return this._http
      .get(`?query=${encodeURI(Number(number))}`)
      .then(result => ({
        zip: formattedNumber,
        adresses: result.data.results
      }));
  }
}
