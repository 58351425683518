import { sortByCreatedDate } from '@common/Helpers/sorters';
import ShareModel from '@common/Models/Share/ShareModel';
import ShareService from '@common/Services/Shares/ShareService';
import { ALERT_ACTIONS } from '../alerts';
import { SHARE_ACTIONS, SHARE_GETTERS, SHARE_MUTATIONS } from './definitions';
import { ShareState } from './ShareState';

const store = {
  namespaced: true,
  state: new ShareState(),
  mutations: {},
  actions: {},
  getters: {}
};

/**  @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  /**
   * @param {ShareModel[]} shares
   */
  [SHARE_MUTATIONS.SET_SHARES](state, shares) {
    state.list = shares;
  },

  /**
   * @param {ShareModel} share
   */
  [SHARE_MUTATIONS.ADD_SHARE](state, share) {
    const idx = state.list.findIndex(s => s.ShareId === share.ShareId);
    if (idx > -1) {
      state.list[idx] = share;
    } else {
      state.list.push(share);
    }
  },

  /**
   * @param {ShareModel} share
   */
  [SHARE_MUTATIONS.REMOVE_SHARE](state, share) {
    const idx = state.list.findIndex(s => s.ShareId === share.ShareId);
    if (idx > -1) {
      state.list.splice(idx, 1);
    }
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [SHARE_ACTIONS.$PREINIT](store) {
    store.dispatch(SHARE_ACTIONS.GET_SHARES);
  },

  [SHARE_ACTIONS.GET_SHARES]({ commit }) {
    return ShareService.getMyShares()
      .then(shares => {
        commit(SHARE_MUTATIONS.SET_SHARES, shares);
        shares
          .filter(share => !share.AcceptedDate)
          .forEach(share => {
            this.dispatch(`alerts/${ALERT_ACTIONS.NEW_ALERT}`, share, {
              root: true
            });
          });
      })
      .catch(error => console.log(error));
  },

  [SHARE_ACTIONS.GET_SHARE]({ commit }, shareId) {
    return ShareService.getMyShareByShareId(shareId)
      .then(share => {
        commit(SHARE_MUTATIONS.ADD_SHARE, share);
      })
      .catch(error => console.log(error));
  },

  /**
   * @param {ShareModel} share
   */
  [SHARE_ACTIONS.CREATE_SHARE]({ commit }, share) {
    return ShareService.createShare(share).then(s => {
      commit(SHARE_MUTATIONS.ADD_SHARE, s);
      return s;
    });
  },

  /**
   * @param {ShareModel} share
   */
  [SHARE_ACTIONS.DELETE_SHARE]({ commit }, share) {
    return ShareService.deleteShare(share).then(s => {
      commit(SHARE_MUTATIONS.REMOVE_SHARE, share);
    });
  },

  /**
   * @param {ShareModel} share
   */
  [SHARE_ACTIONS.ACCEPT_SHARE]({ commit }, share) {
    return ShareService.acceptShare(share).then(s => {
      commit(SHARE_MUTATIONS.ADD_SHARE, s);
    });
  },

  /**
   * @param {ShareModel} share
   */
  [SHARE_ACTIONS.ENABLEDISABLE_SHARE](context, share) {
    if (share.Enabled) {
      return context.dispatch(SHARE_ACTIONS.DISABLE_SHARE, share);
    }
    return context.dispatch(SHARE_ACTIONS.ENABLE_SHARE, share);
  },

  /**
   * @param {ShareModel} share
   */
  [SHARE_ACTIONS.ENABLE_SHARE]({ commit }, share) {
    return ShareService.enableShare(share).then(s => {
      commit(SHARE_MUTATIONS.ADD_SHARE, s);
    });
  },

  /**
   * @param {ShareModel} share
   */
  [SHARE_ACTIONS.DISABLE_SHARE]({ commit }, share) {
    return ShareService.disableShare(share).then(s => {
      commit(SHARE_MUTATIONS.ADD_SHARE, s);
    });
  },

  /**
   * @param {ShareModel} share
   */
  [SHARE_ACTIONS.CREATE_SHARE_REQUEST]({ commit }, share) {
    return ShareService.createShareRequest(share).then(s => {
      commit(SHARE_MUTATIONS.ADD_SHARE, s);
      return s;
    });
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [SHARE_GETTERS.SHARES](state) {
    const shares = state.list.filter(s => s.RequestedDate && s.AcceptedDate);
    return sortByCreatedDate(shares).reverse();
  },
  [SHARE_GETTERS.ACTIVE](state) {
    const shares = state.list.filter(s => s.Enabled);
    return sortByCreatedDate(shares).reverse();
  },
  [SHARE_GETTERS.REQUESTS](state) {
    const shares = state.list.filter(
      s => !s.Enabled && s.RequestedDate && !s.AcceptedDate
    );
    return sortByCreatedDate(shares).reverse();
  },
  [SHARE_GETTERS.FIND_SHARE]: (state, getters) => shareId => {
    return state.list.find(share => share.ShareId === shareId);
  }
};

export { SHARE_ACTIONS, SHARE_GETTERS, SHARE_MUTATIONS } from './definitions';

export default store;
