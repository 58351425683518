<template>
  <svg
    v-bind="$attrs"
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <g fill="none" fill-rule="evenodd" v-if="active">
      <circle cx="20" cy="20" r="19" stroke="#122033" />
      <path
        fill="#000"
        fill-rule="nonzero"
        d="M29.178 12.807l-1.246-1.25a1.326 1.326 0 0 0-1.878 0l-.914.936-13.124 13.124a.402.402 0 0 0-.11.225l-.756 3.774 3.774-.756a.402.402 0 0 0 .225-.11l13.084-13.133.936-.936c.52-.515.524-1.354.01-1.874zm-1.873-.627l1.25 1.25a.442.442 0 0 1 0 .623l-.623.628-1.878-1.874.628-.627a.442.442 0 0 1 .623 0zM14.809 27.79l-1.878-1.877 12.5-12.483 1.874 1.874L14.809 27.79zm-2.232-.98l1.326 1.325-1.666.332.34-1.657z"
      />
    </g>
    <g fill="none" fill-rule="evenodd" v-else>
      <circle
        cx="20"
        cy="20"
        r="19"
        fill="#ADB2B8"
        opacity="0.3"
        class="circle"
      />
      <path
        fill="#000"
        fill-rule="nonzero"
        d="M29.178 12.807l-1.246-1.25a1.326 1.326 0 0 0-1.878 0l-.914.936-13.124 13.124a.402.402 0 0 0-.11.225l-.756 3.774 3.774-.756a.402.402 0 0 0 .225-.11l13.084-13.133.936-.936c.52-.515.524-1.354.01-1.874zm-1.873-.627l1.25 1.25a.442.442 0 0 1 0 .623l-.623.628-1.878-1.874.628-.627a.442.442 0 0 1 .623 0zM14.809 27.79l-1.878-1.877 12.5-12.483 1.874 1.874L14.809 27.79zm-2.232-.98l1.326 1.325-1.666.332.34-1.657z"
        opacity=".4"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    active: Boolean
  }
};
</script>
