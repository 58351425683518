// @ts-check
import { utcToLocal } from '@common/Helpers/time';
import moment from 'moment';

export class TokenModel {
  constructor(data) {
    this.access_token = data.access_token;
    this.token_type = data.token_type;
    this['.expires'] = moment(new Date(data['.expires']));
    this.expires_in = data.expires_in;

    this.refresh_token = data.refresh_token;
    this.refresh_token_secret = data.refresh_token_secret;
    this.expires = moment().add(120, 'days');

    this.pid = data.pid;
    this.deactivated = +data.deactivated || 0;
    this.deactivated_date = data.deactivated_date
      ? utcToLocal(data.deactivated_date)
      : null;

    this.role = data.role;
    this.scope = data.scope;
    /** @type {string} */
    this.groups = data.groups;
    this.group = data.group;
    this.organization = data.organization;
    this.session_id = data.session_id;
    this.consented_to_required_contracts = +data.consented_to_required_contracts || 0; // eslint-disable-line
    this.responded_to_optional_contracts = +data.responded_to_optional_contracts || 0; // eslint-disable-line
    this.twofactor_enabled = +data.twofactor_enabled || 0;
    this.twofactor_authenticated = +data.twofactor_authenticated || 0;
    this.twofactor = +data.twofactor || 0;
    this.use_notes = +data.use_notes;
    this.use_proms = +data.use_proms;
    this.use_status = +data.use_status;
    this.use_symptoms = +data.use_symptoms;
    this.last_login = data.last_login ? utcToLocal(data.last_login) : null;
  }

  /** @returns {OrganizationGroup[]} */
  get parsedGroups() {
    const allOrgGroups = [];
    JSON.parse(this.groups).forEach(g => {
      const [orgGroup, role] = g.split(':');
      const [org, group] = orgGroup.split('/');
      const index = allOrgGroups.findIndex(
        og => og.OrganizationId === org && og.GroupId === group
      );

      if (index === -1) {
        const orgObj = new OrganizationGroup(org, group, [role]);
        allOrgGroups.push(orgObj);
      } else {
        allOrgGroups[index].Roles.push(role);
      }
    });
    return allOrgGroups;
  }

  get access() {
    return {
      token: this.access_token,
      type: this.token_type,
      expires: this['.expires'],
      expires_in: this.expires_in
    };
  }

  get refresh() {
    return {
      token: this.refresh_token,
      token_secret: this.refresh_token_secret,
      expires: this.expires
    };
  }
}

export class OrganizationGroup {
  constructor(orgId, groupId, roles = []) {
    this.OrganizationId = orgId;
    this.GroupId = groupId;
    this.Roles = roles;
  }

  get orderedRoles() {
    return [...this.Roles].sort((a, b) => {
      return this.getRolePriority(a) - this.getRolePriority(b);
    });
  }

  get adminRoles() {
    const activeUserRoles = this.orderedRoles;
    const roles = [];
    activeUserRoles.forEach(role => {
      if (!role.match(/^t/) && !role.match(/pat|user/)) {
        roles.push(role);
      }
    });
    return roles;
  }

  getRolePriority(role) {
    switch (role) {
      case 'admin':
      case 'tadm':
        return 0;
      case 'dev':
      case 'tdev':
        return 1;
      case 'phys':
      case 'tphys':
        return 2;
      case 'res':
      case 'tres':
        return 3;
      case 'stat':
      case 'tstat':
        return 4;
      case 'pat':
      case 'test':
        return 5;
    }
  }
}

export default TokenModel;
