import { createRouter, createWebHashHistory } from 'vue-router';
import routes from './routes';
import store from '@/store';
import { APP_MUTATIONS, APP_GETTERS } from '@/store/modules/app';
import { HIDE_MENU, HIDE_MENU_MOBILE } from '@/constants';
import { isMobile } from '@common/Helpers/mobile';

const router = new createRouter({
  root: '/',
  routes,
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 1 };
    }
  }
});

/** @type {import('vue-router').NavigationGuard} */
router.beforeEach((to, from, next) => {
  const container = document.querySelector('.content');

  if (container) {
    container.scrollTop = 0;
  }

  store.commit(`app/${APP_MUTATIONS.SET_PAGE_SETTING}`, {
    option: 'show_large_menu',
    value: false
  });

  if (
    HIDE_MENU.includes(to.name) ||
    (HIDE_MENU_MOBILE.includes(to.name) && isMobile())
  ) {
    store.commit(`app/${APP_MUTATIONS.SET_PAGE_SETTING}`, {
      option: 'show_menu',
      value: false
    });
  } else {
    if (!store.getters[`app/${APP_GETTERS.SHOW_MENU}`]) {
      setTimeout(() => {
        store.commit(`app/${APP_MUTATIONS.SET_PAGE_SETTING}`, {
          option: 'show_menu',
          value: true
        });
      }, 200);
    }
  }

  next();
});

export default router;
