const percentColors = [
  { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
  { pct: 1.0, color: { r: 0xff, g: 0xff, b: 0 } }
  // { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } }
];

/**
 * Get a color from percent, green to red
 * @param {number} percent
 * @param {boolean} reverse
 * @returns {string} hexcolor
 */
export const getColorForPercentage = (pct, rev) => {
  if (rev === true) {
    pct = 1 - pct;
  }
  for (var i = 1; i < percentColors.length - 1; i++) {
    if (pct < percentColors[i].pct) {
      break;
    }
  }
  const lower = i === 0 ? percentColors[i] : percentColors[i - 1];
  const upper = i === 0 ? percentColors[i + 1] : percentColors[i];
  const range = upper.pct - lower.pct;
  const rangePct = (pct - lower.pct) / range;
  const pctLower = 1 - rangePct;
  const pctUpper = rangePct;
  const color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
  };

  return getHexCode(color);
};

/**
 * Get vas color, green to red
 * @param {number} percent
 * @returns {string} hexcolor
 */
export const getVasColorScale = pct => {
  return getColorForPercentage(pct, true);
};

export const hexToRgba = (hex, opacity) => {
  hex = hex.match(/0x/) ? hex.replace('0x', '#') : hex;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgb = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
  if (rgb === null) {
    return null;
  }
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
};

const getHexCode = rgbColor => {
  return ((1 << 24) + (rgbColor.r << 16) + (rgbColor.g << 8) + rgbColor.b)
    .toString(16)
    .slice(1);
};

export const spiderColors = num => {
  const colors = [
    '#e6194b',
    '#3cb44b',
    '#ffe119',
    '#0082c8',
    '#f58231',
    '#ff0094',
    '#911eb4',
    '#46f0f0',
    '#f032e6',
    '#d2f53c',
    '#fabebe',
    '#c45b23',
    '#008080',
    '#e6beff',
    '#aa6e28',
    '#808000',
    '#000080',
    '#808080',
    '#000000'
  ];

  if (num > colors.length - 1) {
    console.error('num greater than number of colors in spiderColors');
  }

  return colors[num];
};
