import { randomString } from '@common/Helpers/strings';
import SymptomModel from '@common/Models/Symptom/SymptomModel'; //eslint-disable-line
import SymptomReportModel from '@common/Models/SymptomReport/SymptomReportModel';
import SymptomReportSymptomModel from '@common/Models/SymptomReport/SymptomReportSymptomModel';
import core from './core';
import { MUTATIONS } from './definitions';
import { State } from './State'; //eslint-disable-line

export default {
  /**
   * @param {State} state
   * @param {Boolean} interactable
   */
  [MUTATIONS.SET_INTERACTABLE](state, interactable) {
    state.interactable = interactable;
  },

  /**
   * @param {State} state
   * @param {SymptomReportModel} report
   */
  [MUTATIONS.SET_SYMPTOMREPORT](state, report) {
    if (!report) {
      report = new SymptomReportModel({ Id: randomString() });
    }
    state.report = report;
  },

  /**
   * @param {State} state
   * @param {SymptomModel} symptom
   */
  [MUTATIONS.SET_ACTIVE_SYMPTOM](state, symptom) {
    if (!symptom) {
      // symptom = state.report.Symptoms[state.report.Symptoms.length - 1];
      symptom = state.activeSymptom;
    }

    if (symptom.CameraPosition) {
      core.controls.fitToBox(core.boundingBox, true, { paddingBottom: 0.5 });
      core.controls.setPosition(symptom.CameraPosition.x, symptom.CameraPosition.y, symptom.CameraPosition.z, true);
    }

    state.activeSymptom = symptom;
    state.activeSymptom.browserAgent = navigator.userAgent;

    state.report._tempSymptom = new SymptomReportSymptomModel(symptom.__copy());
    state.symptomType = symptom.Type;
  },

  /**
   * @param {State} state
   * @param {Object} obj
   * @param {String} obj.name
   * @param {SymptomModel} obj.symptom
   */
  [MUTATIONS.SET_SYMPTOM_NAME](state, { name, symptom }) {
    symptom.Name = name;
    symptom._saved = false;
  },

  /**
   * @param {State} state
   * @param {Object} obj
   * @param {String} obj.description
   * @param {SymptomModel} obj.symptom
   */
  [MUTATIONS.SET_SYMPTOM_DESCRIPTION](state, { description, symptom }) {
    symptom.Description = description;
    symptom._saved = false;
  },

  /**
   * @param {State} state
   * @param {String} type
   */
  [MUTATIONS.SET_ACTIVE_TYPE](state, type) {
    state.symptomType = type;
  },

  /**
   * @param {State} state
   * @param {String[]} types
   */
  [MUTATIONS.SET_AVAILABLE_TYPES](state, types) {
    state.availableTypes = types;
  },

  /**
   * @param {State} state
   */
  [MUTATIONS.SET_INTENSITY](state, intensity) {
    state.activeSymptom.Intensity = +intensity;
    state.activeSymptom._saved = false;
  },

  /**
   * @param {State} state
   * @param {String} modelId
   */
  [MUTATIONS.SET_MODEL](state, modelId) {
    const newModel = state.models.find(model => model.Id === modelId);
    state.activeModel = newModel || state.models[0];

    if (!newModel) {
      console.error(modelId, 'not found. Using: ', state.models[0]);
    }
  },

  /**
   * @param {State} state
   * @param {Object} payload
   * @param {Number} payload.time
   */
  [MUTATIONS.SAVE_ACTIVE_SYMPTOM](state, { time } = {}) {
    if (time) {
      state.activeSymptom.Milliseconds = time;
    }
    state.activeSymptom.CameraPosition = core.controls.getPosition();
    log(state.activeSymptom);
    state.activeSymptom._saved = true;
    state.report._lastAction = 'save';
    state.activeSymptom._modified = true;
    state.report._saved = false;
    state.activeSymptom = null;
  },

  /**
   * @param {State} state
   * @param {SymptomModel} symptom
   */
  [MUTATIONS.SAVE_SYMPTOM](state, symptom) {
    symptom._saved = true;
    if (state.report) {
      state.report._lastAction = 'save';
      state.report._saved = false;
    }
  },

  /**
   * @param {State} state
   */
  [MUTATIONS.TOGGLE_DRAWING](state, drawingState) {
    state.drawing = drawingState;
  },

  /** @param {State} state */
  [MUTATIONS.TOGGLE_CONTROLS](state, control) {
    const reset = () => {
      state.controls.camera = false;
      state.controls.draw = false;
      state.controls.erase = false;
      state.controls.play = false;
      core.controls.update(core.clock.getDelta());
    };

    reset();

    if (control.camera) {
      state.controls.camera = true;
      core.controls.enabled = true;
    } else if (control.draw) {
      state.controls.draw = true;
      core.controls.enabled = false;
    } else if (control.play) {
      state.controls.play = true;
      core.controls.enabled = false;
    } else if (control.erase) {
      state.controls.erase = true;
      core.controls.enabled = false;
    } else if (!Object.values(control).find(s => s === true)) {
      core.controls.enabled = false;
    }
  },

  /**
   * @param {State} state
   * @param {Object} obj
   * @param {SymptomModel} obj.symptom
   * @param {Function} obj.callback
   */
  [MUTATIONS.ADD_SYMPTOM](state, { symptom, callback } = {}) {
    let activeSymptom = state.activeSymptom;

    if (!symptom || !activeSymptom) {
      return;
    }

    activeSymptom.PosX = symptom.PosX;
    activeSymptom.PosY = symptom.PosY;
    activeSymptom.PosZ = symptom.PosZ;

    // if (symptoms.findIndex(s => s.Id === symptom.Id) > -1) {
    //   return;
    // }

    // symptom = copySymptom(symptom, state.report);
    // symptom.SymptomReportId = state.report.Id;
    // symptoms.push(symptom);

    if (callback) {
      callback(symptom);
    }
  },

  /**
   * @param {State} state
   * @param {Object} payload
   * @param {SymptomModel} payload.symptom
   * @param {Boolean} payload.entireSymptom
   * @param {Object} payload.userData
   */
  [MUTATIONS.REMOVE_SYMPTOM](state, payload) {
    if (payload.entireSymptom === true && payload) {
      if (
        state.activeSymptom &&
        payload.symptom.Id === state.activeSymptom.Id
      ) {
        this.activeSymptom = null;
      }
      state.report.removeSymptom(payload.symptom);
      return;
    }

    const symptom = findSymptomOrPoint(state, null, payload.userData.SymptomId);
    symptom._saved = false;

    if (symptom.SymptomPoints.length > 0) {
      symptom.replacePositionWithPoint(symptom.SymptomPoints[0]);
    } else {
      symptom.removeDrawing();
    }
  },

  /**
   * @param {State} state
   * @param {SymptomModel} symptom
   */
  [MUTATIONS.REMOVE_ENTIRE_SYMPTOM](state, symptom) {
    if (state.activeSymptom && symptom.Id === state.activeSymptom.Id) {
      state.activeSymptom = null;
    }
    state.report.removeSymptom(symptom);
  },

  /**
   * @param {State} state
   * @param {SymptomModel} symptom
   */
  [MUTATIONS.REMOVE_ENTIRE_DRAWING](state, symptom) {
    symptom.removeDrawing();
  },

  /** @param {State} state */
  [MUTATIONS.REMOVE_ACTIVE_SYMPTOM](state) {
    state.report.removeSymptom(state.activeSymptom);
    state.activeSymptom = null;
  },

  /** @param {State} state */
  [MUTATIONS.SET_REPORT_LOCKED](state, value) {
    state.report._locked = value;
  },

  /**
   * @param {State} state
   */
  [MUTATIONS.ADD_SYMPTOM_POINT](state, newSymptomPoint) {
    state.activeSymptom.SymptomPoints.push(newSymptomPoint);
    state.activeSymptom._saved = false;
    state.activeSymptom._modified = true;
  },

  /** @param {State} state */
  [MUTATIONS.REMOVE_SYMPTOM_POINT](state, payload) {
    const symptom = findSymptomOrPoint(state, null, payload.userData.SymptomId);

    if (symptom) {
      symptom.removePoint(findSymptomOrPoint(state, symptom, payload.position));
      symptom._saved = false;
      symptom._modified = true;
    }
  },

  /** @param {State} state */
  [MUTATIONS.CANCEL_ACTION](state) {
    const action = getCancelAction(state);
    if (action === 'RESTORE') {
      state.report.restoreSymptom();
      state.report._tempSymptom = null;
    } else if (action === 'REMOVE') {
      state.report.removeSymptom(state.activeSymptom);
    }
    state.activeSymptom = null;
    state.report._lastAction = 'cancel';
  },

  /** @param {State} state */
  [MUTATIONS.CLICK_SYMPTOM](state, symptomInfo) {
    const symptom = state.report.Symptoms.find(
      s => s.SymptomId === symptomInfo.SymptomId
    );

    if (!symptom || state.activeSymptom || state.report._saved === true) {
      return;
    }

    let symptomCopy = new SymptomReportSymptomModel(symptom.__copy());
    state.report.backupSymptom(symptomCopy);
    state.activeSymptom = symptom;

    if (state.availableTypes.includes(symptom.Type)) {
      state.symptomType = symptom.Type;
    }
  },

  [MUTATIONS.MODEL_LOADED]() {
    setTimeout(() => core.controls.saveState(), 500);
  },
  [MUTATIONS.MARK_BODY]() { },
  [MUTATIONS.UNMARK_BODY]() { },

  /** @param {State} state */
  [MUTATIONS.RESET](state) {
    Object.assign(state, new State());
  },
  /** @param {State} state */
  [MUTATIONS.RESET_CAMERA](state) {
    core.controls.reset(true);
  },
  /**
   * @param {State} state
   * @param {'NORMAL'|'SIMPLE'} mode
   */
  [MUTATIONS.SET_MODE](state, mode) {
    state.mode = mode;
  }
};

/**
 *
 * @param {State} state
 * @returns {'RESTORE'|'REMOVE'}
 */
function getCancelAction(state) {
  if (state.activeSymptom && state.activeSymptom.isCurrent === false) {
    if (state.activeSymptom._saved === false) {
      if (state.report._tempSymptom && state.report._tempSymptom._saved) {
        return 'RESTORE';
      } else {
        return 'REMOVE';
      }
    }
  } else if (state.activeSymptom && state.activeSymptom.isCurrent === true) {
    return 'RESTORE';
  }
}

function findSymptomOrPoint(state, symptom = null, query) {
  if (symptom === null) {
    return state.report.Symptoms.find(symptom => symptom.SymptomId === query);
  }
  return symptom.SymptomPoints.findIndex(
    point => point.X === query.x && point.Y === query.y && point.Z === query.z
  );
}
