<template>
  <canvas
    class="symptom-canvas"
    :style="{ width: `${width}px`, height: `${height}px` }"
    :width="defaultWidth"
    :height="defaultHeight"
    ref="symptoms"
  ></canvas>
</template>

<script>
import SymptomReportSymptomModel from '@common/Models/SymptomReport/SymptomReportSymptomModel';
import { hexToRgba } from '@common/Helpers/color';
import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser';
import { mapGetters } from 'vuex';

const OFFSET_MALE_X = 7.6;
const OFFSET_MALE_Z = 9;
const OFFSET_MALE_Y = 17.6;
const RATIO_MALE = 11.2;

const OFFSET_FEMALE_X = 7.65;
const OFFSET_FEMALE_Z = 8.7;
const OFFSET_FEMALE_Y = 17.6;
const RATIO_FEMALE = 11.2;

export default {
  props: {
    symptoms: {
      type: Array,
      validator: (prop) => prop.every(e => e instanceof SymptomReportSymptomModel),
      required: true,
      default() {
          return [];
        }
    },
    angle: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 140
    },
    height: {
      type: Number,
      default: 160
    },
    size: {
      type: String,
      default: 'small'
    },
    forceModel: String
  },
  data() {
    return {
      defaultWidth: 140,
      defaultHeight: 160,
      hidden: false,
      imageContent: null
    };
  },
  mounted() {
    this.insertImage();
  },
  watch: {
    symptoms: {
      handler: function(symptoms) {
        this.draw();
      },
      deep: true,
      immediate: true
    },
    model(model) {
      this.imageContent = null;
      this.insertImage();
    }
  },
  methods: {
    insertImage(img) {
      const self = this;
      if (this.imageContent) {
        return;
      }
      this.imageContent = new Image();
      this.imageContent.src = this.image;
      this.imageContent.onload = function() {
        self.draw(this);
      };
    },
    draw(img) {
      var canvas = this.$refs.symptoms;
      if (!canvas) {
        return;
      }

      canvas.width = this.imageContent.width;
      canvas.height = this.imageContent.height;

      var ctx = canvas.getContext('2d');
      // ctx.scale(1.4, 1.4);
      ctx.drawImage(
        this.imageContent,
        canvas.width / 2 - this.imageContent.width / 2,
        canvas.height / 2 - this.imageContent.height / 2,
        canvas.width,
        canvas.height
      );

      this.symptoms.forEach(symptom => {

        if (symptom === undefined || symptom == null) {
          return;
        }

        symptom.getBodyMarkersAngle(this.model, this.angle).forEach(marker => {
          let dotSize = canvas.width * 0.03;
          dotSize = dotSize * marker.size;
          if (dotSize < 1) {
            dotSize = 2;
          }

          ctx.fillStyle = `${hexToRgba(symptom._color, symptom._opacity)}`;
          ctx.fillRect(this.getX(marker), this.getY(marker), dotSize, dotSize);
        });
      });
    },
    getX(marker) {
      switch (this.model) {
        case 'male': {
          if (this.angle == 'front') {
            return ((marker.x - OFFSET_MALE_X) / -1) * RATIO_MALE;
          }
          if (this.angle == 'back') {
            return (marker.x + OFFSET_MALE_X) * RATIO_MALE;
          }
          if (this.angle == 'left') {
            return (marker.z + OFFSET_MALE_Z) * RATIO_MALE;
          }
          if (this.angle == 'right') {
            return ((marker.z - OFFSET_MALE_Z) / -1) * RATIO_MALE;
          }
          break;
        }
        case 'female': {
          if (this.angle == 'front') {
            return ((marker.x - OFFSET_FEMALE_X) / -1) * RATIO_FEMALE;
          }
          if (this.angle == 'back') {
            return (marker.x + OFFSET_FEMALE_X) * RATIO_FEMALE;
          }
          if (this.angle == 'left') {
            return (marker.z + OFFSET_FEMALE_Z) * RATIO_FEMALE;
          }
          if (this.angle == 'right') {
            return ((marker.z - OFFSET_FEMALE_Z) / -1) * RATIO_FEMALE;
          }
          break;
        }
      }
    },
    getY(marker) {
      switch (this.model) {
        case 'male': {
          return ((marker.y - OFFSET_MALE_Y) / -1) * RATIO_MALE;
        }
        case 'female': {
          return ((marker.y - OFFSET_FEMALE_Y) / -1) * RATIO_FEMALE;
        }
      }
    }
  },
  computed: {
    ...mapGetters('symptoms', {
      $findSymptom: 'FIND_SYMPTOM'
    }),
    ...mapGetters('currentuser', {
      $activeModel: CURRENT_USER_GETTERS.GET_ACTIVE_SYMPTOM_MODEL
    }),
    model() {
      if (this.forceModel) { return this.forceModel; }
      else{
        return this.$activeModel.Id;
      }
    },
    image() {
      return `/images/${this.model}-${this.angle}-${this.size}.png`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';
canvas {
  width: 100%;

  @media only screen and (max-width: $breakpoint-phone) {
    width: 30%;
  }
}
</style>

