<template>
  <div
    @click.stop="$emit('click')"
    style="
      height: 320px;
      width: 260px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    "
    :style="{ backgroundImage: `url(${image})` }"
  >
    <button
      v-if="showHandButtons"
      class="hand-button"
      style="left: -20px"
      @click="$emit('right-hand-click')"
    ></button>
    <button
      v-if="showHandButtons"
      class="hand-button"
      style="left: 160px"
      @click="$emit('left-hand-click')"
    ></button>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    model: {
      type: String
    },
    showHandButtons: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    image() {
      return `/images/${this.model}-front-small.png`;
    }
  }
};
</script>

<style lang="scss">
@import '@sass/_variables.scss';

.hand-button {
  border: none;
  background-color: $symptoms-blue;
  opacity: 0.15;
  position: relative;
  border-radius: 2em;
  top: 90px;
  width: 60px;
  height: 60px;
  cursor: pointer;
}
</style>
