import DashboardStatisticModel from '@common/Models/DashboardStatisticModel';
import StatisticModel from '@common/Models/StatisticModel';
import BaseService from '../BaseService';
import { StatisticsResponse } from './mappers/StatisticResponses';
import { DashboardStatisticResponse } from './mappers/DashboardStatisticResponses';

class StatisticService extends BaseService {
  /**
   * Get dashboard statistics -- backend
   * @returns {Promise<DashboardStatisticModel>}
   * @memberof StatisticService
   */
  getDashboardStatistic() {
    return this.backend('admin/dashboard').then(response =>
      DashboardStatisticResponse(response.data)
    );
  }

  /**
   * Get statistics -- backend
   * @returns {Promise<StatisticModel[]>}
   * @memberof StatisticService
   */
  getStatistics() {
    return this.backend('admin/statistics').then(response =>
      StatisticsResponse(response.data)
    );
  }
}

export default new StatisticService();
