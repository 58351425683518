// @ts-check
import { maxLength, minLength, required } from '@vuelidate/validators';

export class OrganizationModel {
  constructor({ Id, Name, Version }) {
    this._modelName = 'Organization';
    this.Id = Id || null;
    this.Name = Name;
    this.Version = Version;
  }

  get Saved() {
    return !!this.Version;
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }

  static get validations() {
    return {
      Id: {
        minLength: minLength(2),
        maxLength: maxLength(32),
        required
      },
      Name: {
        maxLength: maxLength(64),
        required
      },
      Version: {}
    };
  }
}

export default OrganizationModel;
