<template>
  <div class="full-height" data-cy="start">
    <header class="app-header clearfix">
      <div class="app-header__logo">
        <logo-vue data-cy="logo"></logo-vue>
      </div>
      <div class="app-header__user" v-if="$LOGGED_IN">
        <router-link to="/logout?noRedir=true">{{
          $t('generic.logout')
        }}</router-link>
      </div>
    </header>

    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import LogoVue from '@common/Components/Logo.vue';
import { mapGetters } from 'vuex';
import { AUTH_GETTERS } from '@/store/modules/auth';

export default {
  components: {
    LogoVue
  },
  computed: {
    ...mapGetters('auth', {
      $LOGGED_IN: AUTH_GETTERS.LOGGED_IN
    })
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';

.content {
  padding: 20px 0px;
  max-height: calc(100vh - 68px);
  min-height: calc(100vh - 68px);
  padding-bottom: 0 !important;
  overflow-y: auto;
  .container {
    padding: 0 50px;
  }
}
.app-header {
  grid-template-columns: 1fr 80px;
  padding: 13px 20px 20px 50px;

  @media only screen and (min-width: $breakpoint-desktop) {
    padding: 13px 50px 20px 50px;
  }
}
.app-header__logo {
  text-align: center;
  @media only screen and (min-width: $breakpoint-desktop) {
    margin-top: 9px;
    text-align: left;
  }
}

.app-header__user {
  grid-template-columns: 1fr;
  @media only screen and (min-width: $breakpoint-desktop) {
    margin-top: 9px;
  }

  a {
    color: #000;
    @media only screen and (min-width: $breakpoint-desktop) {
      color: white;
    }
  }
}
</style>
