/**
 * Check if id is generated from backend
 * @param {string} id
 * @returns {boolean}
 */
export const isRemoteId = id => {
  const dashesInId = id.match(/-/g);
  return !!dashesInId;
};

/**
 * Checks if model has been stored remotely
 * @param {object} Model
 * @returns {boolean}
 */
export const isFromRemote = model => {
  return model.Version > 0;
};
