import { sortByKey } from '@common/Helpers/sorters';
import StatisticService from '@common/Services/Statistics/StatisticService';
import { STAT_ACTIONS, STAT_GETTERS, STAT_MUTATIONS } from './definitions';
import DashboardStatisticModel from '@common/Models/DashboardStatisticModel';

class State {
  constructor() {
    /** @type {DashboardStatisticModel} */
    this.dashboardStats = {};
    /** @type {Stats[]} */
    this.stats = [];
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  [STAT_MUTATIONS.SET_DASHBOARD_STATS](state, dashboardStats) {
    state.dashboardStats = dashboardStats;
  },
  [STAT_MUTATIONS.SET_STATS](state, stats) {
    state.stats = stats;
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [STAT_ACTIONS.GET_DASHBOARD_STATS](context) {
    return StatisticService.getDashboardStatistic().then(dashboardStats =>
      context.commit(STAT_MUTATIONS.SET_DASHBOARD_STATS, dashboardStats)
    );
  },
  [STAT_ACTIONS.GET_STATS](context) {
    return StatisticService.getStatistics().then(stats =>
      context.commit(STAT_MUTATIONS.SET_STATS, stats)
    );
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [STAT_GETTERS.DASHBOARD_STATS](state) {
    return state.dashboardStats;
  },
  [STAT_GETTERS.STATS](state) {
    return state.stats;
  },
  [STAT_GETTERS.GRAPH_DATA]: state => field => {
    const graphData = state.stats.map(s => {
      if (!s.hasOwnProperty(field)) {
        throw "Invalid Stats model field used in GRAPH_DATA ('" + field + "').";
      }

      return {
        date: s.HourlyDate.toDate(),
        moment: s.HourlyDate,
        value: +s[field]
      };
    });

    return sortByKey(graphData, 'date');
  }
};

export { STAT_ACTIONS, STAT_GETTERS, STAT_MUTATIONS } from './definitions';

export default store;
