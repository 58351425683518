import LanguageStringModel from '@common/Models/LanguageStringModel';

/**
 * Map remote LanguageString to local
 * @param {object} dtoLanguageString
 * @returns {LanguageStringModel}
 */
export const LanguageStringResponse = dtoLanguageString => {
  return new LanguageStringModel(dtoLanguageString);
};

/**
 * Map remote LanguageStrings to local
 * @param {object[]} dtoLanguageStrings
 * @returns {LanguageStringModel[]}
 */
export const LanguageStringsResponse = dtoLanguageStrings => {
  return dtoLanguageStrings.map(dto => LanguageStringResponse(dto));
};
