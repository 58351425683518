const app = require('./modules/app/').default;
const applications = require('./modules/applications/').default;
const appversions = require('./modules/appversions/').default;
const auth = require('./modules/auth/').default;
const languages = require('./modules/languages/').default;
const shares = require('./modules/shares/').default;
const news = require('./modules/news/').default;
const alerts = require('./modules/alerts').default;
const currentuser = require('./modules/currentuser/').default;
const patient = require('./modules/patient').default;
const statusreports = require('./modules/statusreports/').default;
const consents = require('./modules/consents/').default;

const notes = require('./modules/notes/').default;
const symptomdefinitions = require('./modules/symptomdefinitions/').default;
const symptoms = require('./modules/symptoms/').default;
const symptomreports = require('./modules/symptomreports/').default;
const proms = require('./modules/proms/').default;
const promreports = require('./modules/promreports/').default;
const auditlogs = require('./modules/auditlogs/').default;
const history = require('./modules/history/').default;
const trash = require('./modules/trash/').default;
const invites = require('./modules/invites/').default;
const stats = require('./modules/stats/').default;
const oauthtokens = require('./modules/oauthtokens/').default;
const languagestrings = require('./modules/languagestrings/').default;
const users = require('./modules/users/').default;
const contracts = require('./modules/contracts/').default;
const bugreports = require('./modules/bugreports/').default;
const patients = require('./modules/patients/').default;
const promrequests = require('./modules/promrequests/').default;
const organizations = require('./modules/organizations/').default;
const groups = require('./modules/groups/').default;

export default {
  app,
  applications,
  appversions,
  auth,
  languages,
  notes,
  currentuser,
  patient,
  symptomdefinitions,
  symptoms,
  symptomreports,
  proms,
  promreports,
  auditlogs,
  history,
  trash,
  statusreports,
  consents,
  invites,
  shares,
  news,
  alerts,
  stats,
  oauthtokens,
  languagestrings,
  users,
  contracts,
  bugreports,
  patients,
  promrequests,
  organizations,
  groups
};
