// @ts-check
import { utcToLocal } from '@common/Helpers/time';
export class SessionModel {
  constructor({
    Id,
    Active,
    City,
    Country,
    Device,
    GroupId,
    LatestLogin,
    OperatingSystem,
    OrganizationId,
    Provider,
    RoleId,
    ScopeId,
    UserAgent
  }) {
    this.Id = Id;
    this.Active = Active;
    this.City = City;
    this.Country = Country;
    this.Device = Device;
    this.GroupId = GroupId;
    this.LatestLogin = LatestLogin ? utcToLocal(LatestLogin) : null;
    this.OperatingSystem = OperatingSystem;
    this.OrganizationId = OrganizationId;
    this.Provider = Provider;
    this.RoleId = RoleId;
    this.ScopeId = ScopeId;
    this.UserAgent = UserAgent;
  }
}

export default SessionModel;
