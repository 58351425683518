import { createI18n } from 'vue-i18n';
import moment from 'moment';

export const defaultLanguage = 'sv';

// switch (navigator.language) {
//   case 'en-US': {
//     defaultLanguage = 'en';
//     break;
//   }
//   case 'sv': {
//     defaultLanguage = 'sv';
//     break;
//   }
// }

const loadedLanguages = []; // our default language that is preloaded

const defaultMessages = require(`@/locales/${defaultLanguage}`).default;

const messages = {};
messages[defaultLanguage] = defaultMessages;

setMomentCalendarStrings(defaultLanguage, defaultMessages.moment);

export const i18n = createI18n({
  fallbackLocale: 'sv',
  locale: defaultLanguage,
  messages: messages,
  globalInjection: true
});

loadLanguageAsync(defaultLanguage);
export function setI18nLanguage(lang) {
  i18n.global.locale = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
}

export async function loadLanguageAsync(lang) {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      const local = await import(
        /* webpackChunkName: "lang-[request]" */ `@/locales/${lang}`
      );

      setMomentCalendarStrings(lang, local.default.moment);

      i18n.global.setLocaleMessage(lang, local.default);
      loadedLanguages.push(lang);
      return Promise.resolve(setI18nLanguage(lang));
    }
    return Promise.resolve(setI18nLanguage(lang));
  }
  return Promise.resolve(lang);
}

function setMomentCalendarStrings(lang, strings) {
  moment.updateLocale(lang, {
    calendar: strings.calendar,
    weekdaysMin: strings.weekdaysMin
  });
}
