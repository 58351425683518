import AppVersionModel from '@common/Models/AppVersionModel';
import BaseService from '../BaseService';
import {
  AppVersionResponse,
  AppVersionsResponse
} from './mappers/AppVersionResponses';

class AppVersionService extends BaseService {
  /**
   * Get app versions -- backend
   * @returns {Promise<AppVersionModel[]>}
   * @memberof AppVersionService
   */
  getAppVersions() {
    return this.backend('appversions').then(response =>
      AppVersionsResponse(response.data)
    );
  }

  /**
   * Create app version -- backend
   * @param {AppVersionModel} appVersion
   * @returns {Promise<AppVersionModel>}
   * @memberof AppVersionService
   */
  createAppVersion(appVersion) {
    return this.backend('post', 'appversions', appVersion).then(response =>
      AppVersionResponse(response.data)
    );
  }

  /**
   * Update app version -- backend
   * @param {AppVersionModel} appVersion
   * @returns {Promise<AppVersionModel>}
   * @memberof AppVersionService
   */
  updateAppVersion(appVersion) {
    return this.backend('put', 'appversions', appVersion).then(response =>
      AppVersionResponse(response.data)
    );
  }

  /**
   * Remove app version -- backend
   * @param {AppVersionModel} appVersion
   * @returns {Promise<AppVersionModel>}
   * @memberof AppVersionService
   */
  deleteAppVersion(appVersion) {
    return this.backend('delete', `appversions/${appVersion.Id}`);
  }
}
export default new AppVersionService();
