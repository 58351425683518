<template>
  <div class="inline-modal" :class="{ hasProgress }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    hasProgress: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';

.inline-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(30, 40, 54, 0.9);
  color: #fff;
  padding: $padding;
  z-index: 99999;
  cursor: default !important;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:not(.hasProgress) {
    border-radius: 15px;
  }

  &.hasProgress {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .button,
  input {
    pointer-events: all;
  }
  .input-field {
    margin: 0;
  }
  .buttons {
    margin-top: 10px;
  }
  .center {
    pointer-events: none;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }

  h5 {
    margin-top: 0;
  }
}
</style>
