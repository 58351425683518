import { utcToLocal } from '@common/Helpers/time';
import moment from 'moment';

export class StatisticModel {
  constructor({
    Date,
    Year,
    Month,
    Week,
    Day,
    DayOfWeek,
    DayOfYear,
    Hour,
    ActiveUsersToday,
    ActiveUsersLastWeek,
    ActiveUsersLastMonth,
    TotalUsers,
    NewUsersToday,
    NewUsersLastWeek,
    NewUsersLastMonth,
    NewPromReportsToday,
    NewPromReportsLastWeek,
    NewPromReportsLastMonth,
    TotalPromReports,
    NewStatusReportsToday,
    NewStatusReportsLastWeek,
    NewStatusReportsLastMonth,
    TotalStatusReports,
    NewSymptomReportsToday,
    NewSymptomReportsLastWeek,
    NewSymptomReportsLastMonth,
    TotalSymptomReports,
    OrganizationId,
    GroupId
  }) {
    this.Date = utcToLocal(Date);
    this.HourlyDate = utcToLocal(
      moment(`${Year}-${Month}-${Day} ${Hour}`, 'YYYY-M-D HH')
    );
    this.Year = Year;
    this.Month = Month;
    this.Week = Week;
    this.Day = Day;
    this.DayOfWeek = DayOfWeek;
    this.DayOfYear = DayOfYear;
    this.Hour = Hour;
    this.ActiveUsersToday = ActiveUsersToday;
    this.ActiveUsersLastWeek = ActiveUsersLastWeek;
    this.ActiveUsersLastMonth = ActiveUsersLastMonth;
    this.TotalUsers = TotalUsers;
    this.NewUsersToday = NewUsersToday;
    this.NewUsersLastWeek = NewUsersLastWeek;
    this.NewUsersLastMonth = NewUsersLastMonth;
    this.NewPromReportsToday = NewPromReportsToday;
    this.NewPromReportsLastWeek = NewPromReportsLastWeek;
    this.NewPromReportsLastMonth = NewPromReportsLastMonth;
    this.TotalPromReports = TotalPromReports;
    this.NewStatusReportsToday = NewStatusReportsToday;
    this.NewStatusReportsLastWeek = NewStatusReportsLastWeek;
    this.NewStatusReportsLastMonth = NewStatusReportsLastMonth;
    this.TotalStatusReports = TotalStatusReports;
    this.NewSymptomReportsToday = NewSymptomReportsToday;
    this.NewSymptomReportsLastWeek = NewSymptomReportsLastWeek;
    this.NewSymptomReportsLastMonth = NewSymptomReportsLastMonth;
    this.TotalSymptomReports = TotalSymptomReports;
    this.OrganizationId = parseInt(OrganizationId);
    this.GroupId = parseInt(GroupId);
  }
}

export default StatisticModel;
