/* eslint-disable */
import { defineAsyncComponent } from 'vue';

export const registerComponents = app => {
  app.component('modal', require('@common/Components/Modals/Modal').default);
  app.component('modal-confirm', defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components" */ '@common/Components/Modals/ModalConfirm'
    ))
  );
  app.component('s-datepicker', defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components" */ '@common/Components/Form/Datepicker'
    ))
  );
  app.component('s-text-input', defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components" */ '@common/Components/Form/TextInput'
    ))
  );
  app.component('s-textarea', defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components" */ '@common/Components/Form/Textarea'
    ))
  );
  app.component('s-select-input', defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components" */ '@common/Components/Form/SelectInput'
    ))
  );
  app.component('s-password-indicator', defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components" */ '@common/Components/Form/PasswordIndicator'
    ))
  );
  app.component('s-tooltip', defineAsyncComponent(() =>
    import(/* webpackChunkName: "components" */ '@common/Components/Form/Tooltip'))
  );
  app.component('float-content', defineAsyncComponent(() =>
    import(/* webpackChunkName: "components" */ '@common/Components/FloatContent'))
  );

  // Buttons
  app.component('s-button', defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components" */ '@common/Components/Buttons/Button.vue'
    ))
  );
  app.component('ButtonHome', defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components" */ '@common/Components/Buttons/ButtonHome.vue'
    ))
  );
  app.component('ButtonBack', defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components" */ '@common/Components/Buttons/ButtonBack.vue'
    ))
  );
  app.component('ButtonBackOrHome', defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components" */ '@common/Components/Buttons/ButtonBackOrHome.vue'
    ))
  );
  app.component('ButtonToggleAutoScroll', defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components" */ '@common/Components/Buttons/ButtonToggleAutoScroll.vue'
    ))
  );
  app.component('ButtonTfaRequired', defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components" */ '@common/Components/Buttons/ButtonTfaRequired.vue'
    ))
  );

  app.component('CardContainer', defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components" */ '@common/Components/Cards/CardContainer.vue'
    ))
  );
  app.component('Card', defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components" */ '@common/Components/Cards/Card.vue'
    ))
  );

  app.component('CardScroll', defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components" */ '@common/Components/Cards/CardScroll.vue'
    ))
  );

  app.component('s-right-arrow', defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components" */ '@common/Components/SVG/RightArrow.vue'
    ))
  );

  app.component('symptoms-quincy', defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components" */ '@common/Modules/quincy/src/Quincy/Quincy.vue'
    ))
  );

  app.component('content-placeholder', defineAsyncComponent(() =>
    import(/* webpackChunkName: "components" */ '@common/Components/ContentPlaceholder/ContentPlaceholder.vue'
    ))
  );

  app.component('mask-container', defineAsyncComponent(() =>
    import(/* webpackChunkName: "components" */ '@common/Components/ContentPlaceholder/MaskContainer.vue'
    ))
  );

  app.component('content-mask', defineAsyncComponent(() =>
    import(/* webpackChunkName: "components" */ '@common/Components/ContentPlaceholder/Mask.vue'
    ))
  );

  app.component('flex-grid', defineAsyncComponent(() =>
    import(/* webpackChunkName: "components" */ '@common/Components/Grids/FlexGrid.vue'
    ))
  );

  app.component('Errors', defineAsyncComponent(() =>
    import(/* webpackChunkName: "components" */ '@common/Components/Errors.vue'))
  );
};
