import { sortByKey } from '@common/Helpers/sorters';
import TrashService from '@common/Services/Trash/TrashService';
import { NOTES_ACTIONS } from '../notes';
import { PROMREPORT_ACTIONS } from '../promreports';
import { STATUSREPORT_ACTIONS } from '../statusreports';
import { SYMPTOMREPORT_ACTIONS } from '../symptomreports';
import { SYMPTOM_ACTIONS } from '../symptoms';
import { TRASH_ACTIONS, TRASH_GETTERS, TRASH_MUTATIONS } from './definitions';
import TrashItemModel from '@common/Models/TrashItemModel';

class State {
  constructor() {
    /** @type {TrashItemModel[]} */
    this.items = [];
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  [TRASH_MUTATIONS.SET_TRASH](state, items) {
    state.items = items;
  },
  [TRASH_MUTATIONS.PURGE_ALL](state, items) {
    state.items = [];
  },
  [TRASH_MUTATIONS.RESTORE_ALL](state, items) {
    state.items = [];
  },
  [TRASH_MUTATIONS.RESTORE_ITEM](state, item) {
    const index = state.items.findIndex(i => i.Id === item.Id);
    state.items.splice(index, 1);
  },
  [TRASH_MUTATIONS.PURGE_ITEM](state, item) {
    const index = state.items.findIndex(i => i.Id === item.Id);
    state.items.splice(index, 1);
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [TRASH_ACTIONS.GET_TRASH_ITEMS]({ commit }) {
    return TrashService.getTrash().then(items =>
      commit(TRASH_MUTATIONS.SET_TRASH, items)
    );
  },
  [TRASH_ACTIONS.PURGE_ALL]({ commit }) {
    return TrashService.purge().then(success =>
      commit(TRASH_MUTATIONS.PURGE_ALL)
    );
  },
  [TRASH_ACTIONS.RESTORE_ALL]({ commit, dispatch, getters: tgetters }) {
    const items = tgetters[TRASH_GETTERS.ALL_TRASH];

    return TrashService.restore().then(success => {
      commit(TRASH_MUTATIONS.RESTORE_ALL);
      items.forEach(item =>
        dispatch(TRASH_ACTIONS.REFETCH_RESTORED_ITEM, item)
      );
    });
  },
  [TRASH_ACTIONS.PURGE_ITEM]({ commit }, item) {
    return item.purge().then(() => commit(TRASH_MUTATIONS.PURGE_ITEM, item));
  },
  [TRASH_ACTIONS.RESTORE_ITEM]({ commit, dispatch }, item) {
    return item.restore().then(function () {
      commit(TRASH_MUTATIONS.RESTORE_ITEM, item);
      dispatch(TRASH_ACTIONS.REFETCH_RESTORED_ITEM, item);
    });
  },
  [TRASH_ACTIONS.REFETCH_RESTORED_ITEM]({ dispatch }, item) {
    switch (item.ResourceType) {
      case 'PromReport': {
        dispatch(
          `promreports/${PROMREPORT_ACTIONS.GET_MY_PROMREPORT}`,
          item.Id,
          {
            root: true
          }
        );
        break;
      }
      case 'SymptomReport': {
        dispatch(
          `symptomreports/${SYMPTOMREPORT_ACTIONS.GET_MY_SYMPTOM_REPORT}`,
          item.Id,
          { root: true }
        );

        dispatch(`symptoms/${SYMPTOM_ACTIONS.GET_MY_SYMPTOMS}`, null, {
          root: true
        });
        break;
      }
      case 'Note': {
        dispatch(`notes/${NOTES_ACTIONS.GET_MY_NOTE}`, item.Id, {
          root: true
        });
        break;
      }
      case 'StatusReport': {
        dispatch(
          `statusreports/${STATUSREPORT_ACTIONS.GET_STATUS_REPORT}`,
          item.Id,
          {
            root: true
          }
        );
        break;
      }
    }
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [TRASH_GETTERS.ALL_TRASH](state) {
    return state.items;
  },
  [TRASH_GETTERS.ALL_TRASH_BY_DATE](state) {
    return sortByKey(state.items, 'PurgeDate').reverse();
  }
};

export { TRASH_ACTIONS, TRASH_GETTERS, TRASH_MUTATIONS } from './definitions';

export default store;
