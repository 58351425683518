<template>
  <div
    class="symptom-report-view"
    :class="{ 'symptom-report-view--bottom-popup': activeSymptom }"
    data-cy="symptomreport"
    v-if="report"
  >
    <template v-if="report && report._saved">
      <edit-lock
        :locked="report._locked"
        :text="$t('modals.confirmUnlockSymptomReport')"
        :style="{ left: '37px', top: '20px' }"
        @change="
          locked => {
            this.setLocked(locked);
          }
        "
      ></edit-lock>
    </template>

    <div class="title">
      <template v-if="step === 1"
        >{{ $t('symptom-report.titles.rotate').toUpperCase() }}
      </template>
      <template v-else-if="step === 2">{{
        $t('symptom-report.titles.draw').toUpperCase()
      }}</template>
      <template v-else-if="step === 3">{{
        $t('symptom-report.titles.intensity').toUpperCase()
      }}</template>
    </div>
    <div
      class="explanatory-text"
      v-text="
        step === 1
          ? $t('generic.OnceFinishedClickNextOrThePenIconToDraw')
          : step === 2
            ? $t('generic.OnceFinishedClickNextToDescribeTheSymptom')
            : null
      "
    ></div>

    <div class="relative">
      <symptoms-quincy
        v-if="report"
        :symptomreport="report"
        @drawing="isDrawing"
        :active-symptom-types="symptomTypes"
        :model="report.Model || $getActiveSymptomModel.Id"
      ></symptoms-quincy>
    </div>

    <float-content>
      <s-button
        @click="cancel"
        id="ButtonCancel"
        :flat="true"
        :dark="true"
        class="underline"
        element-name="symptomreport-cancelbutton"
        >{{
          report.editable && step === 1
            ? $t('generic.cancel')
            : $t('generic.back')
        }}</s-button
      >

      <s-button
        @click="nextStep"
        :primary="true"
        :loading="loading"
        id="ButtonSave"
        element-name="symptomreport-nextbutton"
        >{{ nextStepText }}</s-button
      >
    </float-content>

    <modal-confirm
      :show="confirmCancel"
      :accept-callback="
        () => {
          confirmedCancel = true;
          cancelReport();
        }
      "
      :accept-text="$t('generic.leave')"
      :show-extras="false"
      :decline-text="$t('generic.no')"
      @close="confirmCancel = false"
      >{{ $t('modals.confirmLeaveReport') }}</modal-confirm
    >

    <modal
      :show="showDrawingConfirmModal"
      :accept-callback="
        () => {
          confirmViewDrawing = true;
          showDrawingConfirmModal = false;
        }
      "
      :accept-text="$t('generic.yes')"
      :show-extras="false"
      accept-classes="blue"
      height="auto"
      :decline-text="$t('generic.no')"
      @close="showDrawingModalClosed()"
    >
      <span>{{ $t('modals.patientDrawingModalBody') }}</span>
    </modal>
  </div>
</template>

<script>
import SymptomReportModel from '@common/Models/SymptomReport/SymptomReportModel';
import { randomString } from '@common/Helpers/strings';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  SYMPTOMREPORT_MUTATIONS,
  SYMPTOMREPORT_ACTIONS,
  SYMPTOMREPORT_GETTERS
} from '@/store/modules/symptomreports';
import { SYMPTOM_GETTERS } from '@/store/modules/symptoms';
import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser';
import { APP_GETTERS } from '@/store/modules/app';
import EditLock from '@/views/components/EditLock.vue';
import {
  QUINCYMUTATIONS,
  QUINCYGETTERS
} from '@common/Modules/quincy/src/store/quincy';
import { isMobile } from '@common/Helpers/mobile';

export default {
  props: {
    symptomreportid: {
      type: String
    }
  },
  components: {
    EditLock
  },
  data() {
    return {
      /** @type {SymptomReportModel} */
      report: null,
      loading: false,
      confirmCancel: false,
      confirmedCancel: false,
      confirmViewDrawing: false,
      drawing: false,
      subscription: null,
      step: 1,
      showDrawingConfirmModal: false
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name == 'symptomSelect') {
        vm.showDrawingConfirmModal = true;
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    if (!this.report) {
      this.$bus.$emit('toggleHeader', true);
      next();
      return;
    }

    const keepUnsavedReport = [
      'symptomReport',
      'symptomReportEdit',
      'symptomReportDraw',
      'symptomReportGrade',
      'symptomReportNote',
      'symptomSelect'
    ].includes(to.name);

    if (
      !keepUnsavedReport &&
      this.report?.hasModifiedSymptoms &&
      this.confirmCancel === false &&
      this.confirmedCancel === false &&
      this.loading === false
    ) {
      this.confirmCancel = true;
      this.redirectOnCancel = to;
      next(false);
    } else {
      this.$bus.$emit('toggleHeader', true);

      if (!keepUnsavedReport) {
        this.$removeUnsavedReport();
      }

      next();
    }
  },
  unmounted() {
    this.$RESET();
    this.subscription();
  },
  watch: {
    step(step) {
      switch (step) {
        case 1:
          return this.$TOGGLECONTROLS({ camera: true });
        case 2:
          return this.$TOGGLECONTROLS({ draw: true });
      }
    },
    symptomreportid(newValue, oldValue) {
      if (newValue !== undefined) {
        if (newValue !== oldValue) {
          this.loadOrCreateSymptomReport();
        } else {
          return;
        }
      }
      this.resetReport();
    },
    activeSymptom(value) {
      if (value) {
        this.$bus.$emit('toggleBottomPopup', { height: 208 });
      } else {
        this.$bus.$emit('toggleBottomPopup', false);
        this.drawing = false;
        if (this.$QUINCYSETTINGS.controls.camera === false) {
          this.$TOGGLECONTROLS({ camera: true });
        }
      }
    },
    $currentSymptoms: {
      handler: function (symptoms) {
        if (!symptoms || !this.report) {
          return;
        }
      },
      immediate: true
    }
  },
  mounted() {
    if (isMobile()) {
      this.$bus.$emit('toggleHeader', false);
    }
    this.loadOrCreateSymptomReport();
    this.subscription = this.$store.subscribe(mutation => {
      if (mutation.type === `quincy/${QUINCYMUTATIONS.SET_SYMPTOMREPORT}`) {
        if (this.symptomIdToLoad) {
          const symptom = this.$currentSymptoms.find(
            // TODO: Make this return a SymptomReportSymptom instead of Symptom?
            symptom => symptom.Id === this.symptomIdToLoad
          );

          this.$ADDSYMPTOM({ symptom });
        }
      }

      if (mutation.type === `quincy/${QUINCYMUTATIONS.TOGGLE_CONTROLS}`) {
        if (mutation.payload.hasOwnProperty('camera')) {
          this.step = 1;
        } else if (
          mutation.type === `quincy/${QUINCYMUTATIONS.TOGGLE_CONTROLS}` &&
          mutation.payload.hasOwnProperty('draw')
        ) {
          this.step = 2;
        }
      }

      if (mutation.type === `quincy/${QUINCYMUTATIONS.MODEL_LOADED}`) {
        if (this.symptomIdToLoad) {
          const activeSymptom = this.report.Symptoms.find(
            x => x.SymptomId === this.symptomIdToLoad
          );

          if (activeSymptom) {
            setTimeout(() => this.setActiveSymptom(activeSymptom), 500);
          }
        }
      }
    });
  },
  methods: {
    ...mapActions('symptomreports', {
      $getMySymptomReport: SYMPTOMREPORT_ACTIONS.GET_MY_SYMPTOM_REPORT
    }),
    ...mapMutations('symptomreports', {
      $setUnsavedReport: SYMPTOMREPORT_MUTATIONS.SET_UNSAVED_REPORT,
      $removeUnsavedReport: SYMPTOMREPORT_MUTATIONS.REMOVE_UNSAVED_REPORT
    }),
    ...mapMutations('quincy', {
      $CANCELACTION: QUINCYMUTATIONS.CANCEL_ACTION,
      $SETACTIVESYMPTOM: QUINCYMUTATIONS.SET_ACTIVE_SYMPTOM,
      $ADDSYMPTOM: QUINCYMUTATIONS.ADD_SYMPTOM,
      $RESET: QUINCYMUTATIONS.RESET,
      $SET_REPORT_LOCKED: QUINCYMUTATIONS.SET_REPORT_LOCKED,
      $TOGGLECONTROLS: QUINCYMUTATIONS.TOGGLE_CONTROLS
    }),
    cancelReport() {
      this.$removeUnsavedReport();

      if (this.redirectOnCancel) {
        this.$router.push(this.redirectOnCancel.path);
      } else {
        this.$router.replace({ name: 'start' }).catch(() => {});
      }
    },
    showDrawingModalClosed() {
      if (this.confirmViewDrawing == false) {
        this.$router.replace({
          name: 'symptomReportNote',
          params: {
            symptomreportid: this.report.Id
          },
          query: {
            newSymptom: true,
            symptomid: this.activeSymptom.SymptomId
          }
        });
      }
    },
    setLocked(locked) {
      this.$SET_REPORT_LOCKED(locked);
      this.$TOGGLECONTROLS({ camera: true });
    },
    resetReport() {
      this.loadOrCreateSymptomReport();
    },
    loadOrCreateSymptomReport() {
      this.report = this.$unsavedReport;

      if (this.report) {
        return;
      }

      if (!this.symptomreportid) {
        this.report = new SymptomReportModel({
          Id: randomString(),
          Model: this.$getActiveSymptomModel.Id
        });
        return;
      }

      const loadedReport = this.$findSymptomReport(this.symptomreportid);
      const reportIsNotLoaded = () =>
        loadedReport === null || loadedReport.hasOwnProperty('Symptoms');

      const loadReport = () => {
        this.$getMySymptomReport(this.symptomreportid).then(report => {
          this.report = report;
        });
      };

      if (reportIsNotLoaded) {
        loadReport();
      }
    },
    cancel() {
      if (this.step === 1) {
        this.$CANCELACTION();
        this.goToMySymptoms();
      } else if (this.step > 1) {
        this.step--;
      }
    },
    nextStep() {
      if (this.step === 1) {
        return (this.step = 2);
      } else if (this.step === 2) {
        return this.saveReportLater();
      }
    },
    goToMySymptoms() {
      if (this.report.isFromRemote) {
        this.$router.replace({
          name: 'symptomReportEdit',
          params: { symptomreportid: this.report.Id }
        });
      } else {
        this.$router.replace({
          name: 'symptomReport'
        });
      }
    },
    saveReportLater() {
      this.$setUnsavedReport(this.report);

      if (this.$route.query?.fromstatus) {
        if (this.report.isFromRemote) {
          this.$router.replace({
            name: 'symptomReportEdit',
            params: { symptomreportid: this.report.Id }
          });
        } else {
          this.$router.push({
            name: 'symptomReport'
          });
        }
      } else {
        this.$router.push({
          name: 'symptomReportNote',
          params: {
            symptomreportid: this.report.Id
          },
          query: {
            symptomid: this.activeSymptom.SymptomId,
            newSymptom: true
          }
        });
      }
    },
    isDrawing(status) {
      this.drawing = status;
    },
    setActiveSymptom(symptom) {
      this.$SETACTIVESYMPTOM(symptom);
    }
  },
  computed: {
    ...mapGetters('app', {
      $symptomTypes: APP_GETTERS.SYMPTOM_TYPES
    }),
    ...mapGetters('symptoms', {
      $currentSymptoms: SYMPTOM_GETTERS.CURRENT_SYMPTOMS
    }),
    ...mapGetters('symptomreports', {
      $findSymptomReport: SYMPTOMREPORT_GETTERS.FIND_SYMPTOM_REPORT,
      $unsavedReport: SYMPTOMREPORT_GETTERS.UNSAVED_REPORT
    }),
    ...mapGetters('currentuser', {
      $getActiveSymptomModel: CURRENT_USER_GETTERS.GET_ACTIVE_SYMPTOM_MODEL,
      $advancedManikin: CURRENT_USER_GETTERS.ADVANCED_MANIKIN
    }),
    ...mapGetters('quincy', {
      $ACTIVESYMPTOM: QUINCYGETTERS.ACTIVE_SYMPTOM,
      $QUINCYSETTINGS: QUINCYGETTERS.SETTINGS
    }),
    activeSymptom() {
      return this.$ACTIVESYMPTOM;
    },
    savable() {
      if (this.report) {
        return (
          this.report.editable && this.report.Symptoms.some(s => s._modified)
        );
      }
      return false;
    },
    nextStepText() {
      if (this.reportIsSavable) {
        return this.$t('generic.save');
      }
      return this.$t('generic.next');
    },
    reportIsSavable() {
      return (
        !this.activeSymptom &&
        this.report &&
        this.report._saved === false &&
        this.report.Symptoms.length > 0
      );
    },
    symptomTypes() {
      if (this.$advancedManikin === false) {
        return [
          {
            display: this.$t('constants.SymptomTypes.generic'),
            value: 'generic'
          }
        ];
      }
      return this.$symptomTypes.map(type => ({
        ...type,
        display: this.$t(`constants.SymptomTypes.${type.value}`)
      }));
    },
    symptomIdToLoad() {
      return this.$route.query.symptomid;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_animations.scss';
</style>

<style lang="scss">
@import '@sass/_variables.scss';

.symptom-report-view {
  position: relative;
  margin-top: -20px;

  &--bottom-popup {
    height: 82%;
  }

  .title {
    text-align: center;
    font-weight: 700;
    background-color: #fff;
  }

  .explanatory-text {
    text-align: center;
    font-weight: 70;
    background-color: #fff;
  }
}

.quincy-wrapper #canvas-container {
  &.draw {
    cursor: url('../../../public/images/brush-cursor.png'), auto;
  }

  &.not-editable,
  &.normal {
    cursor: default !important;
  }

  &.camera {
    cursor: pointer !important;
  }

  &.erase {
    cursor: url('../../../public/images/eraser-cursor.png'), auto !important;
  }
}
</style>
