<template>
  <div>
    <card-container
      v-for="(item, index) in list"
      :key="item.Type"
      :full-width="true"
      data-cy="status-report-item"
    >
      <card
        :remove-function="
          item._removed || item._lastValue === 0
            ? undefined
            : () => hideItem(item, index)
        "
        :confirm-remove="true"
        :confirm-remove-text="$t('views.statusReport.confirmRemoveAnswerText')"
      >
        <template v-slot:content>
          <scale
            :data-scale="item.Type"
            :index="index"
            :item="item"
            :title="scaleTitle(item)"
            @update:value="value => updateValue(item, value, index)"
            :reverse="$invertedTypes.includes(item.Type)"
            :scale-style="$appVersion.StatusReportVersion"
            @removeAnswer="removeAnswer"
            :anchor-left="$t(`scales.best.${item.Type}`)"
            :anchor-right="$t(`scales.worst.${item.Type}`)"
          ></scale>
        </template>
      </card>
    </card-container>
  </div>
</template>

<script>
import Scale from '@views/components/Scale/CheckScale';
import { StatusReportAreaColors } from '@common/constants';
import { mapGetters } from 'vuex';
import { STATUSREPORT_GETTERS } from '@/store/modules/statusreports';
import { CURRENT_USER_GETTERS } from '../../../store/modules/currentuser';

export default {
  props: {
    list: Array
  },
  components: {
    Scale
  },

  methods: {
    updateValue(item, value, index) {
      item.Intensity = value;
      item._changed = true;
      this.$emit('update', { item, value, index });
    },
    removeAnswer(item) {
      item.Intensity = 0;
      item._changed = false;
    },
    hideItem(item, index) {
      const value = 0;
      item.Intensity = value;
      item._changed = true;
      item._removed = true;
      this.$emit('update', { item, value, index });
    },
    scaleColor(item) {
      return StatusReportAreaColors[item.Type];
    },
    scaleTitle(item) {
      const itemstr = `constants.StatusReportTypes.${item.Type}`;
      return this.$t(itemstr);
    }
  },
  computed: {
    ...mapGetters('currentuser', {
      $appVersion: CURRENT_USER_GETTERS.APP_VERSION
    }),
    ...mapGetters('statusreports', {
      $invertedTypes: STATUSREPORT_GETTERS.INVERTED_TYPES
    })
  }
};
</script>

<style></style>
