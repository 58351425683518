import InviteModel from '@common/Models/InviteModel';
import BaseService from '../BaseService';
import { InviteResponse, InvitesResponse } from './mappers/InviteResponses';

class InviteService extends BaseService {
  /**
   * Get active invites in current user's group
   * @returns {Promise<InviteModel[]>}
   * @memberof InviteService
   */
  getActiveInvites() {
    return this.identity('my/group/invites').then(response =>
      InvitesResponse(response.data)
    );
  }

  /**
   * Delete an invite
   * @param {string} inviteId
   * @returns {Promise}
   * @memberof InviteService
   */
  deleteInvite(inviteId) {
    return this.identity('delete', `/invites/${inviteId}`);
  }

  /**
   * Create an invite in current user's group
   * @param {InviteModel} invite
   * @returns {Promise<InviteModel>}
   * @memberof InviteService
   */
  createInviteToMyGroup(invite) {
    return this.identity('post', '/my/group/invites', invite).then(response =>
      InviteResponse(response.data)
    );
  }

  /**
   * Create an invite
   * @param {InviteModel} invite
   * @returns {Promise<InviteModel>}
   * @memberof InviteService
   */
  createInvite(invite) {
    return this.identity('post', '/my/invites', invite).then(response =>
      InviteResponse(response.data)
    );
  }

  /**
   * Create an invite
   * @param {InviteModel} invite
   * @returns {Promise<InviteModel>}
   * @memberof InviteService
   */
  createInviteAsAdmin(invite) {
    return this.identity('post', '/invites', invite).then(response =>
      InviteResponse(response.data)
    );
  }
}

export default new InviteService();
