import { sortByKey } from '@common/Helpers/sorters';
import ContractModel from '@common/Models/ContractModel';

/**
 * Map remote Contract to local
 * @param {object} dtoContracts
 * @return {ContractModel}
 */
export const ContractResponse = dtoContract => {
  const contract = new ContractModel(dtoContract);
  contract._saved = true; // TODO: Fix other way of checking saved
  return contract;
};

/**
 * Map remote Contracts to local
 * @param {object[]} dtoContracts
 * @return {ContractModel[]}
 */
export const ContractsResponse = dtoContracts => {
  const contracts = dtoContracts.map(dto => ContractResponse(dto));
  return sortByKey(contracts, 'Index');
};
