// @ts-check

export class MappedItemModel {
  constructor({ Tag, Value }) {
    this._modelName = 'MappedItemModel';
    this.Tag = Tag;
    this.Value = Value;
  }

  static get validations() {
    return {};
  }

  __copy() {
    return new MappedItemModel(JSON.parse(JSON.stringify(this)));
  }
}

export default MappedItemModel;
