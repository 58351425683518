import { APP_VERSION_OPTIONS } from '@common/constants';
import AppVersionService from '@common/Services/AppVersion/AppVersionService';
import {
  APPVERSION_ACTIONS,
  APPVERSION_GETTERS,
  APPVERSION_MUTATIONS
} from './definitions';
import AppVersionModel from '@common/Models/AppVersionModel';

class State {
  constructor() {
    /** @type {AppVersionModel[]} */
    this.list = [];
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  [APPVERSION_MUTATIONS.ADD_APP_VERSION](state, appVersion) {
    const exists = state.list.find(i => i.Id === appVersion.Id);
    if (!exists) {
      state.list.push(appVersion);
    } else {
      Object.assign(exists, appVersion);
    }
  },
  [APPVERSION_MUTATIONS.REMOVE_APP_VERSION](state, appVersion) {
    const index = state.list.findIndex(x => x.Id === appVersion.Id);
    if (index > -1) {
      state.list.splice(index, 1);
    }
  },
  [APPVERSION_MUTATIONS.SET_APP_VERSIONS](state, appVersions) {
    state.list = appVersions;
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [APPVERSION_ACTIONS.GET_APP_VERSIONS](context) {
    return AppVersionService.getAppVersions().then(appVersions =>
      context.commit(APPVERSION_MUTATIONS.SET_APP_VERSIONS, appVersions)
    );
  },
  [APPVERSION_ACTIONS.DELETE_APP_VERSION](context, appVersion) {
    return AppVersionService.deleteAppVersion(appVersion).then(() =>
      context.commit(APPVERSION_MUTATIONS.REMOVE_APP_VERSION, appVersion)
    );
  },
  [APPVERSION_ACTIONS.CREATE_APP_VERSION](context, appVersion) {
    const newAppVersion = { ...appVersion };

    return AppVersionService.createAppVersion(newAppVersion).then(
      appVersion => {
        context.commit(APPVERSION_MUTATIONS.ADD_APP_VERSION, appVersion);
      }
    );
  },
  [APPVERSION_ACTIONS.UPDATE_APP_VERSION](context, appVersion) {
    const newAppVersion = { ...appVersion };
    return AppVersionService.updateAppVersion(newAppVersion).then(
      appVersion => {
        context.commit(APPVERSION_MUTATIONS.ADD_APP_VERSION, appVersion);
        return appVersion;
      }
    );
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [APPVERSION_GETTERS.APP_VERSIONS](state) {
    return state.list;
  },
  [APPVERSION_GETTERS.FIND_APP_VERSION]: state => appVersionId => {
    return state.list.find(b => b.Id === appVersionId);
  },
  [APPVERSION_GETTERS.APP_VERSION_OPTIONS]() {
    return APP_VERSION_OPTIONS;
  }
};

export {
  APPVERSION_ACTIONS,
  APPVERSION_GETTERS,
  APPVERSION_MUTATIONS
} from './definitions';

export default store;
