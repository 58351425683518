<template>
  <header
    class="app-header clearfix"
    v-show="$showHeader"
    :class="{ 'app-header--extra-content': true }"
  >
    <div
      class="app-header__back hidden-desktop waves-effect"
      @click="goBack"
      v-if="navigationEnabled && !hideArrow"
    >
      <i class="mdi mdi-chevron-left"></i>
    </div>
    <div class="app-header__logo">
      <router-link v-if="navigationEnabled" :to="{ name: 'start' }">
        <logo-vue data-cy="logo"></logo-vue>
      </router-link>

      <logo-vue v-if="!navigationEnabled" data-cy="logo"></logo-vue>
    </div>

    <!-- From admin -->
    <div class="app-header__extra-content">
      <!-- Group/Org menu -->
      <div
        v-if="$myGroups && hasAdminRole"
        class="app-header__dropdown app-header__organization pointer"
        @click="toggleOrgPopup"
        v-click-outside="() => (orgPopupOpen = false)"
      >
        <div class="relative">
          <i class="mdi mdi-alpha-o-circle"></i>
        </div>

        <div
          class="app-header__role relative hidden-mobile"
          data-cy="header-group"
        >
          {{ $activeGroup.DisplayName }}
        </div>
        <div class="hidden-mobile">
          <i
            class="mdi mdi-chevron-down"
            :class="{ 'mdi-chevron-up': orgPopupOpen }"
          ></i>
        </div>

        <main-header-group-org-popup-vue
          :open="orgPopupOpen"
          ref="orgPopup"
        ></main-header-group-org-popup-vue>
      </div>

      <!-- Role menu -->
      <div
        class="app-header__dropdown app-header__roles pointer"
        @click="toggleRolePopup"
        v-click-outside="() => (rolePopupOpen = false)"
        v-if="$user && !isPatientOnly"
      >
        <div class="relative">
          <i class="mdi mdi-alpha-r-circle"></i>
        </div>

        <div
          class="app-header__role relative hidden-mobile"
          data-cy="header-role"
        >
          {{ activeRoleDisplay }}
        </div>
        <div class="hidden-mobile">
          <i
            class="mdi mdi-chevron-down"
            :class="{ 'mdi-chevron-up': rolePopupOpen }"
          ></i>
        </div>
        <main-header-role-popup-vue
          :open="rolePopupOpen"
          ref="rolePopup"
        ></main-header-role-popup-vue>
      </div>
    </div>

    <!-- Profile menu -->
    <div
      v-if="$user"
      class="app-header__dropdown app-header__user pointer"
      @click="toggleProfilePopup"
      v-click-outside="() => (profilePopupOpen = false)"
      data-cy="header-menu-popup"
    >
      <div class="relative">
        <i class="mdi mdi-account-circle" data-cy="header-profile-image"></i>
        <badge-vue
          v-if="$stats"
          :badge="$stats.newsItemsSinceLastLogin"
          :top="1"
          :right="20"
        ></badge-vue>
      </div>

      <div
        class="app-header__username relative hidden-mobile"
        data-cy="header-name"
      >
        <span v-text="$user.FullName"></span>
      </div>
      <div class="hidden-mobile">
        <i
          class="mdi mdi-chevron-down"
          :class="{ 'mdi-chevron-up': profilePopupOpen }"
        ></i>
      </div>
      <main-header-popup
        parent=".app-header__user"
        :open="profilePopupOpen"
        ref="profilePopup"
      ></main-header-popup>
    </div>
  </header>
</template>

<script>
import settings from '@/../settings.json';
import { mapGetters } from 'vuex';
import { APP_GETTERS } from '@/store/modules/app';
import { AUTH_GETTERS } from '@/store/modules/auth';
import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser';
import { NEWS_GETTERS } from '@/store/modules/news';
import LogoVue from '@common/Components/Logo-NONBETA.vue';
import BadgeVue from '@common/Components/Badge.vue';
import MainHeaderPopup from '@views/components/MenuHeaderPopup';
import MainHeaderRolePopupVue from '@views/components/MainHeaderRolePopup.vue';
import MainHeaderGroupOrgPopupVue from '@views/components/MainHeaderGroupOrgPopup.vue';

export default {
  components: {
    BadgeVue,
    LogoVue,
    MainHeaderPopup,
    MainHeaderRolePopupVue,
    MainHeaderGroupOrgPopupVue
  },
  data() {
    return {
      settings,
      profilePopupOpen: false,
      rolePopupOpen: false,
      orgPopupOpen: false
    };
  },
  watch: {},
  mounted() {},
  methods: {
    goBack() {
      this.$router.back();
    },
    toggleProfilePopup() {
      this.profilePopupOpen = !this.profilePopupOpen;
    },
    toggleRolePopup() {
      this.rolePopupOpen = !this.rolePopupOpen;
    },
    toggleOrgPopup() {
      this.orgPopupOpen = !this.orgPopupOpen;
    }
  },
  computed: {
    ...mapGetters('app', {
      $roles: APP_GETTERS.ROLES,
      $showHeader: APP_GETTERS.SHOW_HEADER
    }),
    ...mapGetters('auth', {
      $activeRole: AUTH_GETTERS.ACTIVE_ROLE,
      $activeUserRoles: AUTH_GETTERS.ACTIVE_ROLES,
      $activeUserRolesArray: AUTH_GETTERS.ACTIVE_ROLES_ARRAY,
      $token: AUTH_GETTERS.TOKEN
    }),
    ...mapGetters('currentuser', {
      $user: CURRENT_USER_GETTERS.USER,
      $myGroups: CURRENT_USER_GETTERS.MY_GROUPS,
      $stats: CURRENT_USER_GETTERS.STATS,
      $activeGroup: CURRENT_USER_GETTERS.ACTIVE_GROUP
    }),
    ...mapGetters('news', {
      $newsItemsAfter: NEWS_GETTERS.NEWS_ITEMS_AFTER
    }),
    hasAdminRole() {
      return this.$myGroups.some(g => g.Roles.includes('admin'));
    },
    isPatientOnly() {
      return this.$user.Roles.length == 1 && this.$user.Roles.includes('pat');
    },
    hasVeryImportantNews() {
      const lastLogin = this.$stats.lastLogin;
      const newsItem = this.$newsItemsAfter(lastLogin).find(
        n => n.Priority === 'very-important'
      );
      return newsItem;
    },
    navigationEnabled() {
      const navigationDisabledViews = ['tos', 'groupPromView', 'symptomlist'];
      return !navigationDisabledViews.includes(this.$store.state.route.name);
    },
    hideArrow() {
      const arrowDisabledViews = ['start'];
      return arrowDisabledViews.includes(this.$store.state.route.name);
    },
    activeRoleDisplay() {
      return (
        this.$activeUserRoles(this.$roles).find(
          x => x.Value === this.$activeRole
        )?.Display || 'Role'
      );
    }
  }
};
</script>

<style lang="scss">
@import '@sass/_variables.scss';

.app-header {
  display: grid;
  grid-template-columns: 1fr 45px;
  grid-template-rows: 1fr;
  align-items: center;
  justify-content: center;
  color: black;
  padding: 0 10px;
  background-color: $background-color;
  transition: all 0.2s ease-out;
  position: fixed;
  top: 0;
  z-index: 999999;
  left: 0;
  right: 0;
  height: $header-height;

  &--extra-content {
    grid-template-columns: 2fr auto 45px;
    @media only screen and (min-width: $breakpoint-desktop) {
      grid-template-columns: 2fr auto auto !important;
    }
  }

  @media only screen and (min-width: $breakpoint-desktop) {
    grid-template-columns: 1fr 0.3fr;
    padding: 13px 20px 20px 50px;
    justify-content: flex-start;
    background-color: $symptoms-black;
    height: $header-height-desktop;
    &__logo {
      justify-self: flex-start !important;
      margin-left: 0 !important;
      svg {
        fill: #fff !important;
      }
    }
  }

  &__back {
    position: absolute !important;
    left: 10px;
    padding: 0px 5px;
    border-radius: 50%;
    i {
      line-height: normal;
    }
  }

  &__dropdown {
    justify-self: flex-end;
    color: #fff;
    display: inline-grid;
    align-items: center;
    position: relative;

    @media only screen and (min-width: $breakpoint-desktop) {
      grid-template-columns: 40px 1fr 40px;
    }

    i.mdi {
      display: block;
    }

    .mdi-chevron-down {
      transition: transform 0.2s ease-in-out;
      &.up {
        transform: scaleY(-1);
        filter: FlipV;
        -ms-filter: 'FlipV';
      }
    }
  }

  &__extra-content {
    justify-self: flex-end;
  }

  &__organization {
    .mdi-alpha-o-circle {
      color: $symptoms-orange;
    }
  }

  &__roles {
    .mdi-alpha-r-circle {
      color: $symptoms-red;
    }
  }

  &__user {
    .user-picture {
      width: 30px;
      height: 30px;
      img {
        border-radius: 50%;
        width: 30px;
        height: 30px;
      }
    }

    .mdi-account-circle {
      color: $symptoms-yellow;
    }
    .mdi-chevron-down {
      transition: transform 0.2s ease-in-out;
      &.up {
        transform: scaleY(-1);
        filter: FlipV;
        -ms-filter: 'FlipV';
      }
    }
  }

  &__username {
    min-width: fit-content;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__role {
    min-width: 70px;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__logo {
    line-height: 0;
    justify-self: center;
    margin-left: 45px;
    svg {
      fill: #000;
      max-width: 150px;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@sass/_variables.scss';

.standalone {
  .app-header {
    padding: 35px 10px 10px 10px;

    .add-note,
    .right-button {
      top: 23px;
    }

    .search-container {
      top: 10px;
    }
  }
}

.popup {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #fff;
  display: none;
  box-shadow: 0 20px 25px 0 rgba(73, 73, 73, 0.1);
  color: #000;
  border-radius: 10px;
  min-width: 200px;
  z-index: 999999;

  @media only screen and (min-width: $breakpoint-desktop) {
    right: 17px;
  }

  ul {
    margin: 0;
    li {
      &:first-of-type {
        a {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
      &:last-of-type {
        a {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
      a {
        padding: 10px 20px;
        display: block;
        &.router-link-exact-active {
          background-color: rgb(53, 53, 53);
          color: #fff;
          &:hover {
            background-color: rgb(53, 53, 53);
          }
        }
        &:hover {
          background-color: rgb(231, 231, 231);
        }
      }
      &.divider {
        border-bottom: 1px solid #ccc;
      }
    }
  }
  &.open {
    display: block;
  }
}
</style>
