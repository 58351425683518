export const GROUP_MUTATIONS = {
  ADD_GROUP: 'ADD_GROUP',
  SET_GROUPS: 'SET_GROUPS',
  REMOVE_GROUP: 'REMOVE_GROUP'
};

export const GROUP_ACTIONS = {
  ADD_MY_ORGANIZATION_GROUP: 'ADD_MY_ORGANIZATION_GROUP',
  DELETE_MY_ORGANIZATION_GROUP: 'DELETE_MY_ORGANIZATION_GROUP',
  GET_MY_ORGANIZATION_GROUP: 'GET_MY_ORGANIZATION_GROUP',
  GET_MY_ORGANIZATION_GROUPS: 'GET_MY_ORGANIZATION_GROUPS',
  UPDATE_MY_ORGANIZATION_GROUP: 'UPDATE_MY_ORGANIZATION_GROUP',
  EXPORT: 'EXPORT'
};

export const GROUP_GETTERS = {
  GROUPS: 'GROUPS',
  FIND_GROUP: 'FIND_GROUP'
};
