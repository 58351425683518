<template>
  <li
    :data-cy="
      this.activeSession === data.Id
        ? 'settings-session-active'
        : 'settings-session-inactive'
    "
    class="sessions__session"
    :class="activeSessionHighlight(data.Id)"
  >
    <span v-text="data.UserAgent"></span> &bull;
    <span v-text="when"></span>
    <i
      class="mdi mdi-delete-forever pointer"
      :data-remove-session="index"
      data-cy="settings-session-remove"
      @click="removeSession(data.Id)"
      :title="$t('views.settings.removeSession')"
    ></i>

    <modal-confirm
      :show="confirm"
      :accept-callback="
        () => {
          this.confirm = false;
          this.destroySession(sessionId);
        }
      "
      @close="confirm = false"
    >
      <p v-text="$t('views.settings.destroyCurrentSession')"></p>
    </modal-confirm>
  </li>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import { CURRENT_USER_ACTIONS } from '@/store/modules/currentuser';
import { DateTimeString } from '@common/Filters/Date';
export default {
  props: {
    data: Object,
    index: Number
  },
  data() {
    return {
      confirm: false,
      sessionId: null
    };
  },
  methods: {
    ...mapActions('currentuser', {
      $destroySession: CURRENT_USER_ACTIONS.DESTROY_SESSION
    }),
    removeSession(sessionId) {
      if (this.isActiveSession(sessionId)) {
        this.confirm = true;
        this.sessionId = sessionId;
      } else {
        this.destroySession(sessionId);
      }
    },
    destroySession(sessionId) {
      this.$destroySession(sessionId).then(response => {
        if (this.isActiveSession(sessionId)) {
          this.$router.push('/logout');
        } else {
          this.$emit('remove');
        }
      });
    },
    activeSessionHighlight(sessionId) {
      if (this.activeSession === sessionId) {
        return 'active';
      }
    },
    isActiveSession(sessionId) {
      return this.activeSession === sessionId;
    }
  },
  computed: {
    ...mapState({
      activeSession: state => state.auth.tokenDetails.session_id
    }),
    when() {
      return moment(this.data.LatestLogin).format(DateTimeString);
    }
  }
};
</script>

<style lang="css"></style>
