import PromReportModel from '@common/Models/PromReportModel';

/**
 * Transforms PromReport to backend dto
 * @param {PromReportModel} Report
 * @returns {object}
 */
export const NewPromReportRequest = Report => {
  const newReport = Object.assign({}, Report);

  newReport.Answers.forEach(answer => {
    delete answer.Id;
    if (answer.NotApplicable === true) {
      delete answer.AnswerIds;
    }
  });

  delete newReport.Id;

  return newReport;
};

/**
 * Transforms PromReport to backend update dto
 * @param {PromReportModel} Report
 * @returns {object}
 */
export const UpdatePromReportRequest = Report => {
  const updatedReport = Object.assign({}, Report);
  updatedReport.Answers.forEach(answer => {
    delete answer.Id;
    if (answer.NotApplicable === true) {
      delete answer.AnswerIds;
    }
  });

  delete updatedReport.Id;

  return updatedReport;
};
