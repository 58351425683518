<template>
  <div class="pull-to-top">
    <history-graph
      :graph-data="[{ data: graphData }]"
      :height="200"
      :color-scheme="graphColors"
      v-if="graphData.length"
    ></history-graph>
    <div class="container">
      <h4
        class="sub-header mt0"
        v-text="$t(`constants.StatusReportTypes.${statusItem}`)"
      ></h4>
      <info-row
        :icon="false"
        v-for="(item, index) in graphData.reverse()"
        :key="index"
      >
        <template v-slot:title>
          <span>
            <div class="pl">{{ $filters.TimeFormat(item.moment) }}</div>
          </span>
        </template>

        <template v-slot:date>
          <span>
            <div class="pl">{{ $filters.DateFormat(item.moment) }}</div>
          </span>
        </template>

        <template v-slot:intensity>
          <div
            class="colored-background"
            :style="{ 'background-color': graphColors[item.value], color }"
          >
            <span>{{ item.value }}</span>
          </div>
        </template>
      </info-row>
    </div>

    <float-content>
      <button-back></button-back>
      <s-button :to="{ name: 'statusReport' }" :primary="true">{{
        $t('generic.update')
      }}</s-button>
    </float-content>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { STATUSREPORT_GETTERS } from '@/store/modules/statusreports';
import HistoryGraph from '@common/Components/Graphs/HistoryGraph.vue';
import { StatusReportScaleColors } from '@common/constants';
import InfoRow from '@common/Components/InfoRow/InfoRow';
import tinycolor from 'tinycolor2';

export default {
  props: {
    statusItem: {
      type: String
    }
  },
  components: {
    HistoryGraph,
    InfoRow
  },
  methods: {
    color(value) {
      const color = tinycolor(this.graphColors[value]);
      return color.isDark() ? '#fff' : '#000';
    }
  },
  computed: {
    ...mapGetters('statusreports', {
      $areaGraphData: STATUSREPORT_GETTERS.AREA_GRAPH_DATA,
      $invertedTypes: STATUSREPORT_GETTERS.INVERTED_TYPES
    }),
    graphData() {
      return this.$areaGraphData(this.statusItem);
    },
    graphColors() {
      const isReversed = this.$invertedTypes.indexOf(this.statusItem) > -1;
      const colors = StatusReportScaleColors.slice();
      return isReversed ? colors.reverse() : colors;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';
@media only screen and (max-width: $breakpoint-phone) {
  .pl {
    padding-left: 10px;
  }
}
</style>
