import BaseService from '../BaseService';
import GroupModel from '@common/Models/GroupModel';
import { GroupRequest } from './mappers/GroupRequests';
import { GroupResponse, GroupsResponse } from './mappers/GroupResponses';

class GroupService extends BaseService {
  /**
   * Get active user's groups -- backend
   * @returns {Promise<GroupModel[]>}
   * @memberof GroupService
   */
  getMyGroups() {
    return this.backend('my/groups').then(response =>
      GroupsResponse(response.data)
    );
  }

  /**
   * Get group by URL slug -- backend
   * @param {string} UrlSlug
   * @returns {Promise<GroupModel>}
   * @memberof GroupService
   */
  getGroupByUrlSlug(urlSlug) {
    return this.backend(`groups/${urlSlug}`).then(response =>
      GroupResponse(response.data)
    );
  }

  /**
   * Get groups in current user's organization -- backend
   * @returns {Promise<GroupModel[]>}
   * @memberof GroupService
   */
  getMyOrganizationGroups() {
    return this.backend(`my/organization/groups`).then(response =>
      GroupsResponse(response.data)
    );
  }

  /**
   * Get group in current user's organization -- backend
   * @returns {Promise<GroupModel>}
   * @memberof GroupService
   */
  getMyOrganizationGroup(groupId) {
    return this.backend(`my/organization/groups/${groupId}`).then(response =>
      GroupResponse(response.data)
    );
  }

  /**
   * Add group in current user's organization -- backend
   * @param {GroupModel} group
   * @returns {Promise<GroupModel>}
   * @memberof GroupService
   */
  addMyOrganizationGroup(group) {
    return this.backend(
      'post',
      'my/organization/groups',
      GroupRequest(group)
    ).then(response => GroupResponse(response.data));
  }

  /**
   * Remove group in current user's organization -- backend
   * @param {string} groupId
   * @returns {Promise}
   * @memberof GroupService
   */
  deleteMyOrganizationGroup(groupId) {
    return this.backend('delete', `my/organization/groups/${groupId}`);
  }

  updateMyOrganizationGroup(groupId, group) {
    return this.backend(
      'put',
      `my/organization/groups/${groupId}`,
      GroupRequest(group)
    ).then(response => GroupResponse(response.data));
  }
}

export default new GroupService();
