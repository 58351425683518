<template>
  <div class="box">
    <div ref="container" class="graph" :class="{ 'graph--white': white }">
      <div
        class="pointinfo-container"
        v-if="ballClick && pointinfo"
        ref="pointinfo"
        :style="{ left: `${pointinfo.x - 72}px` }"
      >
        <div class="arrow" :style="{ top: 'calc(50% - 5px)' }"></div>
        <div :id="`pointinfo-${id}`" class="pointinfo">
          <i class="mdi mdi-close-circle pointer" @click="pointinfo = null"></i>
          <div class="date">
            {{ pointinfo.moment.format(pointinfo.format) }}
          </div>
          <dl v-for="(point, index) in pointinfo.points" :key="index">
            <dt
              v-text="
                point.moment.format(
                  resolution !== 'hour' && resolution !== 'day'
                    ? 'M/D HH:mm'
                    : 'HH:mm'
                )
              "
            ></dt>
            <dd v-text="point.value"></dd>
          </dl>
        </div>
      </div>
      <svg :id="`graph-${id}`" />
    </div>
    <div class="row graph-buttons" v-if="buttons">
      <div
        class="graph-buttons__button"
        :class="{ selected: selectedButton === 1 }"
        @click="
          setTimespan({ count: 24, resolution: 'hour' });
          selectedButton = 1;
        "
      >
        1 {{ $t('views.historyGraph.day') }}
      </div>
      <div
        class="graph-buttons__button"
        :class="{ selected: selectedButton === 2 }"
        @click="
          selectedButton = 2;
          setTimespan({ count: 7, resolution: 'day' });
        "
      >
        1 {{ $t('views.historyGraph.week') }}
      </div>
      <div
        class="graph-buttons__button"
        :class="{ selected: selectedButton === 3 }"
        @click="
          selectedButton = 3;
          setTimespan({ count: 30, resolution: 'day' });
        "
      >
        1 {{ $t('views.historyGraph.month') }}
      </div>
      <div
        class="graph-buttons__button"
        :class="{ selected: selectedButton === 4 }"
        @click="
          selectedButton = 4;
          setTimespan({ count: 3, resolution: 'month' });
        "
      >
        3 {{ $t('views.historyGraph.month') }}
      </div>
      <div
        class="graph-buttons__button"
        :class="{ selected: selectedButton === 5 }"
        @click="
          selectedButton = 5;
          setTimespan({ count: 6, resolution: 'month' });
        "
      >
        6 {{ $t('views.historyGraph.month') }}
      </div>
      <div
        class="graph-buttons__button"
        :class="{ selected: selectedButton === 6 }"
        @click="
          selectedButton = 6;
          setTimespan({ count: 12, resolution: 'month' });
        "
      >
        1 {{ $t('views.historyGraph.year') }}
      </div>
      <div
        class="graph-buttons__button"
        :class="{ selected: selectedButton === 7 }"
        @click="
          setTimespan({ count: 36, resolution: 'month' });
          selectedButton = 7;
        "
      >
        3 {{ $t('views.historyGraph.year') }}
      </div>
    </div>
  </div>
</template>

<script>
import { getColorForPercentage } from '@common/Helpers/color';
import { randomString } from '@common/Helpers/strings';
import { mapGetters } from 'vuex';
import { timeFormat } from 'd3-time-format';
import * as d3 from 'd3';
import moment from 'moment';
import storage from '@common/Helpers/storage';
import FormSetup from '../../Mixins/FormSetup';

export default {
  mixins: [FormSetup],
  props: {
    id: {
      type: String,
      required: false,
      default: () => randomString()
    },
    graphData: {
      type: Array,
      required: true
    },
    buttons: {
      type: Boolean,
      default: true
    },
    height: {
      type: Number,
      default: 300
    },
    ballShow: {
      type: Boolean,
      default: true
    },
    ballRadius: {
      type: Number,
      default: 5
    },
    ballColored: {
      type: Boolean,
      default: true
    },
    ballClick: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    differentColors: {
      type: Boolean,
      default: false
    },
    white: {
      type: Boolean
    },
    colorScheme: {
      type: Array
    }
  },
  data() {
    return {
      resolution: 'day',
      duration: {
        count: 30,
        resolution: 'day'
      },
      firstDateToShow: moment().endOf('day').subtract(30, 'days').toDate(),
      lastDateToShow: moment().endOf('day').toDate(),
      margins: { top: 20, right: 20, bottom: 10, left: 20 },
      graphId: `#graph-${this.id}`,
      pointinfo: null,
      selectedButton: 3,
      timespanSettings: {
        count: 30,
        resolution: 'day'
      },
      resolutionSettings: 'day'
    };
  },

  watch: {
    graphData(value) {
      if (!value) {
        return;
      }
      this.initGraph(false);
    }
  },

  mounted() {
    if (this.buttons === true && storage.get('historygraph.timespan')) {
      const settings = storage.get('historygraph.timespan');
      this.setTimespan(settings.timespan);
      this.selectedButton = settings.button;
    }
    this.initGraph();

    if (this.buttons === true) {
      this.$_FormSetup_setupForm();
    }

    window.$bus.$on('resized-window', () => this.initGraph(true));
  },

  unmounted() {
    d3.select(window).on('resize', null);
  },

  methods: {
    setResolution() {
      if (
        this.resolutionSettings === 'month' &&
        this.duration.resolution === 'day'
      ) {
        this.timespanSettings.resolution = 'year';
        this.timespanSettings.count = 1;
      }
      this.setTimespan();
      this.resolution = this.resolutionSettings;
      this.initGraph(false);
    },

    setTimespan(timespan) {
      if (timespan) {
        storage.set('historygraph.timespan', {
          timespan,
          button: this.selectedButton
        });
        this.timespanSettings = timespan;
      }
      this.resolution = this.timespanSettings.resolution;
      this.duration.count = this.timespanSettings.count;
      this.duration.resolution = this.timespanSettings.resolution;
      this.firstDateToShow = moment()
        .endOf('day')
        .subtract(this.timespanSettings.count, this.timespanSettings.resolution)
        .toDate();
      this.initGraph(false);
    },

    initGraph(resize) {
      this.pointinfo = null;
      let data = this.graphData.map(d => ({ ...d, moment: moment(d.date) }));
      if (data.length === 0) {
        return;
      }

      if (!this.$refs.container) {
        return;
      }
      this.containerWidth = this.$refs.container.clientWidth;
      this.containerWidth -= this.margins.left + this.margins.right;
      // TODO: Fix graph resize
      if (resize) {
        this.containerWidth += this.margins.left - 2;
      }

      document.querySelector(this.graphId).innerHTML = '';
      const width =
        this.containerWidth + this.margins.left + this.margins.right;
      const height = this.height - this.margins.top - this.margins.bottom;

      const x = d3
        .scaleTime()
        .rangeRound([0, width - this.margins.left - this.margins.right]);
      const y = d3.scaleLinear().range([height, 0]);
      const that = this;

      x.domain([this.firstDateToShow, this.lastDateToShow]);
      y.domain([0, this.getMax]);

      data = data.map(d => this.transformData(d.data, x));

      const parseTime = d3.timeParse('%a, %H %M');
      const svg = d3
        .select(this.graphId)
        .attr('width', width)
        .attr('height', height + this.margins.top + this.margins.bottom)
        .attr('data-length', data.length)
        .append('g')
        .attr(
          'transform',
          `translate(${this.margins.left}, ${this.margins.top})`
        )
        .attr('class', 'graph-outer');

      const yTicks = this.height > 200 ? 10 : 3;

      const yAxis = svg
        .append('g')
        .attr('class', 'axis yAxis')
        .attr('transform', 'translate(0, -10)')
        .call(d3.axisLeft(y).ticks(yTicks));

      yAxis
        .selectAll('.tick')
        .append('line')
        .style('stroke', 'rgb(236, 236, 236)')
        .style('stroke-width', '1')
        .style('stroke-dasharray', '2, 2')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', width - this.margins.right - this.margins.left)
        .attr('y2', 0);

      yAxis.select('.domain').remove();
      yAxis
        .append('line')
        .style('stroke', 'rgb(236, 236, 236)')
        .style('stroke-width', '1')
        .style('fill', 'none')
        .attr('x1', 1)
        .attr('y1', 0)
        .attr('x2', 1)
        .attr('y2', this.height - 24);

      const xAxis = svg
        .append('g')
        .attr('class', 'axis')
        .attr('transform', 'translate(0,' + (height - 10) + ')')
        .call(d3.axisBottom(x).ticks(4));

      xAxis.select('.domain').remove();
      xAxis
        .append('line')
        .style('stroke', 'rgb(236, 236, 236)')
        .style('stroke-width', '1')
        .style('fill', 'none')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', width - this.margins.right - this.margins.left)
        .attr('y2', 0);

      const ticks = d3.selectAll(`#graph-${this.id} .tick text`);
      ticks.attr('data-tick', (d, i) => {
        if (typeof d !== 'number') {
          return 'no';
        }
        return d;
      });

      let graphGroup;

      data.forEach(group => {
        const valueLine = d3
          .line(group)
          .curve(d3.curveLinear)
          .x(d => x(d.date))
          .y(d => y(d.avg));

        graphGroup = svg
          .append('g')
          .attr('transform', 'translate(0, -10)')
          .attr('class', 'graph-content');

        // Add a clippath to contain all inner data in the graph
        const clippath = graphGroup
          .append('clipPath')
          .attr('id', 'rect-clip' + this.graphId)
          .append('rect')
          .attr('width', this.containerWidth)
          .attr('height', height + 10)
          .attr('ry', 0)
          .attr('rx', 0)
          .attr('transform', 'translate(1, -10)');

        graphGroup
          .append('path')
          .data([group])
          .attr('clip-path', 'url(#rect-clip' + this.graphId + ')')
          .attr('class', 'line')
          .attr('d', valueLine);

        graphGroup
          .selectAll('lineTop')
          .data(group)
          .enter()
          .append('line')
          .attr('stroke', '#888')
          .attr('x1', d => x(d.date))
          .attr('x2', d => x(d.date))
          .attr('y1', d => y(d.min))
          .attr('y2', d => y(d.max));

        for (let i = 0, len = group.length; i < len; i++) {
          const current = group[i];
          if (current.min !== current.max) {
            graphGroup
              .append('line')
              .attr('stroke', '#888')
              .attr('x1', x(current.date) - 5)
              .attr('x2', x(current.date) + 5)
              .attr('y1', y(current.max))
              .attr('y2', y(current.max));

            graphGroup
              .append('line')
              .attr('stroke', '#888')
              .attr('x1', x(current.date) - 5)
              .attr('x2', x(current.date) + 5)
              .attr('y1', y(current.min))
              .attr('y2', y(current.min));
          }
        }

        if (this.ballShow === true) {
          graphGroup
            .selectAll('dot')
            .data(group)
            .enter()
            .append('circle')
            .attr('r', this.ballRadius)
            .style('fill', d => (this.ballColored === true ? d.color : 'black'))
            .attr('class', 'ball')
            .attr('clip-path', 'url(#rect-clip' + this.graphId + ')')
            .attr('cx', d => x(d.date))
            .attr('cy', d => y(d.avg));
        }
      });

      if (this.ballClick === true) {
        d3.select(this.graphId).on('click touch', function (event) {
          const coords = d3.pointer(event);
          const xcoord = coords[0] - that.margins.left;
          let clickOn;

          data.forEach(symptoms => {
            if (!clickOn) {
              clickOn = symptoms.find(
                x => x.xmin <= xcoord && x.xmax >= xcoord
              );
            }
          });

          if (!clickOn) {
            that.pointinfo = null;
            return;
          }

          clickOn.x = x(clickOn.date) + 10;
          clickOn.y = y(clickOn.avg) + 10;

          that.pointinfo = clickOn;
          that.$nextTick(() => {
            that.$refs.pointinfo.style.top = `${
              that.pointinfo.y - that.$refs.pointinfo.clientHeight / 2 + 10
            }px`;
          });
        });
      }

      d3.select(window).on('resize.historyGraph', this.emitResize);
    },

    transformData(data, xdom) {
      const result = [];
      const current = moment(this.firstDateToShow).startOf(this.resolution);
      const firstPointInGraph = data.find(x =>
        x.moment.isSameOrAfter(current, this.resolution)
      );
      const indexOfFirst = data.indexOf(firstPointInGraph);

      const getFormat = () => {
        if (this.resolution === 'hour') {
          return 'YYYY-MM-DD HH:mm';
        } else if (this.resolution === 'day') {
          return 'YYYY-MM-DD';
        } else if (this.resolution === 'week') {
          return 'YYYY [Week] W';
        } else if (this.resolution === 'month') {
          return 'YYYY-MM';
        }
      };

      if (indexOfFirst > 0) {
        const firstPoint = data[indexOfFirst - 1];
        const po = {
          max: firstPoint.value,
          min: firstPoint.value,
          avg: firstPoint.value,
          date: firstPoint.date,
          moment: firstPoint.moment,
          clickActive: false,
          xmin: xdom(firstPoint.date) - 7,
          xmax: xdom(firstPoint.date) + 7,
          count: 1,
          points: [firstPoint],
          color: this.colorScheme
            ? this.colorScheme[firstPoint.value]
            : getColorForPercentage(firstPoint.value / 10, true),
          format: getFormat()
        };
        result.push(po);
      }

      for (let i = 0; i <= this.stepsInFor; i++) {
        const points = data.filter(x =>
          moment(x.date).isSame(current, this.resolution)
        );
        if (points.length === 0) {
          current.add(1, this.resolution);
          continue;
        }
        const valueArr = points.map(x => x.value);
        const sum = valueArr.reduce((a, b) => a + b);
        const avg = sum / valueArr.length;
        const point = {
          max: Math.max.apply(null, valueArr),
          min: Math.min.apply(null, valueArr),
          avg: avg,
          date: current.toDate(),
          moment: current.clone(),
          xmin: xdom(current.toDate()) - 7,
          xmax: xdom(current.toDate()) + 7,
          clickActive: false,
          count: valueArr.length,
          points: points,
          color: this.colorScheme
            ? this.colorScheme[Math.round(avg)]
            : `#${getColorForPercentage(avg / 10, true)}`,
          format: getFormat()
        };
        result.push(point);
        current.add(1, this.resolution);
      }
      return result;
    },

    emitResize() {
      window.$bus.$emit('resized-window');
    }
  },

  computed: {
    getMax() {
      var max = 10;
      for (let i = 0; i < this.graphData.length; i++) {
        const valueArr = this.graphData[i].data.map(x => x.value);
        const maxInDataSet = Math.max.apply(null, valueArr);
        if (maxInDataSet > max) {
          max = maxInDataSet;
        }
      }
      return max;
    },
    stepsInFor() {
      switch (this.resolution) {
        case 'hour':
          if (this.duration.resolution === 'hour') {
            return this.duration.count;
          } else if (this.duration.resolution === 'day') {
            return this.duration.count * 24;
          } else if (this.duration.resolution === 'year') {
            return this.duration.count * 24 * 365;
          } else {
            console.error('StepsInFor error: not daily nor yearly');
            return 0;
          }
        case 'day':
          if (this.duration.resolution === 'hour') {
            return Math.ceil(this.duration.count / 24);
          } else if (this.duration.resolution === 'day') {
            return this.duration.count;
          } else if (this.duration.resolution === 'year') {
            return this.duration.count * 365;
          } else {
            console.error('StepsInFor error: not daily nor yearly');
            return 0;
          }
        case 'week':
          if (this.duration.resolution === 'hour') {
            return Math.ceil(this.duration.count / 24 / 7);
          } else if (this.duration.resolution === 'day') {
            return Math.ceil(this.duration.count / 7);
          } else if (this.duration.resolution === 'year') {
            return Math.ceil((this.duration.count * 365) / 7);
          } else {
            console.error('StepsInFor error: not daily nor yearly');
            return 0;
          }
        case 'month':
          if (this.duration.resolution === 'day') {
            console.error(
              'Montly resolution during 30 days is not a good idea'
            );
            return 0;
          } else if (this.duration.resolution === 'month') {
            return this.duration.count;
          } else if (this.duration.resolution === 'year') {
            return Math.ceil(this.duration.count * 12);
          } else {
            console.error('StepsInFor error: not daily nor yearly');
            return 0;
          }
        default:
          console.error('Resolution not supported:', this.resolution);
          return 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';
.box {
  padding: 0;
}
.graph-buttons {
  &__button {
    display: inline-block;
    width: calc(100% / 7);
    text-align: center;
    font-size: small;
    background-color: #eee;
    color: #aaa;
    padding: 5px 0;
    border: 1px solid #aaa;
    border-left: 0;
    cursor: pointer;
    &.selected {
      background-color: $symptoms-black;
      color: #fff;
    }
    &:not(.selected):hover {
      background-color: #dadada;
    }
    &:last-of-type {
      border-right: 0;
    }
  }
}
</style>

<style lang="scss">
.graph {
  padding: 10px;
  position: relative;
  .pointinfo-container {
    width: 85px;
    z-index: 9999;
    position: absolute;
    .mdi-close-circle {
      position: absolute;
      right: -5px;
      top: -14px;
      font-size: 16px;
    }
    .arrow {
      width: 0;
      height: 0;
      left: 100%;
      position: absolute;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid #ccc;
    }

    .pointinfo {
      background-color: #ccc;
      padding: 5px;
      font-size: 11px;
      border-radius: 5px;

      dl {
        margin: 0;
        clear: both;
        dd,
        dt {
          display: inline-block;
          margin: 0;
        }
        dd {
          float: right;
        }
      }

      .date {
        font-weight: 700;
      }
    }
  }

  &--white {
    .axis,
    .axis path {
      stroke: white;
      font-family: sans-serif;
      stroke-width: 1px;
    }
  }
}

path.line {
  fill: none;
  stroke: #888;
  stroke-width: 1px;
}
.ball {
  stroke: rgb(255, 255, 255);
  z-index: 10;
  &:hover {
    stroke-width: 2px;
  }
}
.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}

.grid path {
  stroke-width: 0;
}

.yAxis .tick:first-of-type {
  line:not([stroke='currentColor']) {
    display: none;
  }
}

.tick {
  line {
    // stroke-dasharray: 3, 3;
    stroke: rgb(236, 236, 236) !important;
    stroke-width: 1;
    fill: none;
  }
  text[data-tick] {
    font-family: Dosis;
  }
  text[data-tick]:not([data-tick='no']) {
    fill: #ccc;
  }
  text[data-tick='0'],
  text[data-tick='5'],
  text[data-tick='10'] {
    fill: black !important;
  }
}
</style>
