<template>
  <card
    v-if="group"
    :image="`/images/${getPartOfDay().toLowerCase()}.jpg`"
    :key="`${group.Id}/${group.OrganizationId}`"
  >
    <template v-slot:image-content>
      <span v-if="$user" class="greeting-text">
        {{ $t('views.start.hi', { name: $user.FirstName }) }}
      </span>
    </template>

    <template v-slot:header>{{ group.FormTitle }}</template>
    <template v-slot:text>{{
      showSymptomsReportButton
        ? $t('generic.globalGroupCardWithoutMultipleStudiesText')
        : $t('generic.globalGroupCardWithMultipleStudiesText')
    }}</template>

    <template v-slot:button>
      <div style="text-align: center" v-if="showSymptomsReportButton">
        <s-button
          data-cy="button-how-are-you-feeling"
          class="button--blue"
          @click="reportSymptoms(group)"
          >{{ $t('views.start.reportSymptoms') }}</s-button
        >
      </div>

      <div v-if="group.GroupCardPromId" style="text-align: center" class="mt20">
        <s-button
          data-cy="button-update-covid-form"
          class="button--blue mt-10"
          @click="reportProm(group)"
          >{{ group.FormButtonLabel }}</s-button
        >
      </div>
    </template>
  </card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getPartOfDay } from '@common/Helpers/time';
import {
  CURRENT_USER_ACTIONS,
  CURRENT_USER_GETTERS
} from '@/store/modules/currentuser';

export default {
  props: {
    group: {
      required: true
    },
    showSymptomsReportButton: {
      required: false,
      default: true
    }
  },
  data() {
    return {};
  },
  methods: {
    getPartOfDay,
    ...mapActions('currentuser', {
      $setActiveGroup: CURRENT_USER_ACTIONS.SET_ACTIVE_GROUP
    }),
    async setGroupIfNotAlreadySet(group) {
      if (
        this.$activeGroup.Id !== group.Id &&
        this.$activeGroup.OrganizationId !== group.OrganizationId
      ) {
        const role = 'pat';
        await this.$setActiveGroup({ group, role });
      }
    },
    reportSymptoms(group) {
      this.setGroupIfNotAlreadySet(group);
      this.$router.push({ name: 'symptomReport' });
    },
    reportProm(group) {
      this.setGroupIfNotAlreadySet(group);
      this.$router.push({
        name: 'promReportEdit',
        params: { promid: group.GroupCardPromId }
      });
    }
  },
  computed: {
    ...mapGetters('currentuser', {
      $activeGroup: CURRENT_USER_GETTERS.ACTIVE_GROUP,
      $user: CURRENT_USER_GETTERS.USER
    })
  }
};
</script>

<style lang="scss" scoped>
.greeting-text {
  text-transform: capitalize;
}
</style>
