export class AnonymizeAccountModel {
  constructor({
    Reference,
    Anonymize = false,
    Age = false,
    Gender = false,
    AreaCode = false,
    City = false,
    Region = false
  }) {
    this.Reference = Reference;
    this.Anonymize = Anonymize;
    this.Age = Age;
    this.Gender = Gender;
    this.AreaCode = AreaCode;
    this.City = City;
    this.Region = Region;
  }
}

export default AnonymizeAccountModel;
