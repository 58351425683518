import { BankIdAuthResponseModel } from '@common/Models/BankId/BankIdAuthResponseModel';
import { BankIdCollectResponseModel } from '@common/Models/BankId/BankIdCollectResponseModel';

/**
 * Map remote BankId auth response to local
 * @param {object} dtoBankIdAuth
 * @returns {BankIdAuthResponseModel}
 */
export const BankIdAuthResponse = dtoBankIdAuthResponse => {
  return new BankIdAuthResponseModel(dtoBankIdAuthResponse);
};

/**
 * Map remote BankId collect response to local
 * @param {object} dtoBankIdCollect
 * @returns {BankIdCollectResponseModel}
 */
export const BankIdCollectResponse = dtoBankIdCollectResponse => {
  return new BankIdCollectResponseModel(dtoBankIdCollectResponse);
};
