import { Bus } from '@common';

export const ConsentRequiredInterceptor = error => {
  if (error && error.response && error.response.status === 412) {
    console.error(
      '%cMUST AGREE TO CONTRACT',
      'color: blue; font-size: x-large'
    );
    Bus.$emit('must-accept-contract');
  }

  return Promise.reject(error);
};
