<template>
  <info-row :content-padding="true" :popup="showPopup">
    <template v-slot:image>
      <i class="mdi small" :class="item.icon"></i>
    </template>
    <template v-slot:title>
      <span>{{ title }}</span>
    </template>
    <template v-slot:date>
      <span>
        {{ item.ResourceType }} &bull;
        {{
          $t('generic.removedIn', {
            time: $filters.DaysFromNowFormat(item.PurgeDate),
            format: $tc('plural.day', 2)
          })
        }}
      </span>
    </template>

    <template v-slot:buttons>
      <i
        data-cy="trash-item-deletebutton"
        class="mdi mdi-delete-forever mr10 pointer"
        @click.prevent.self="confirmRemove = true"
      ></i>
      <i
        data-cy="trash-item-restorebutton"
        class="mdi mdi-restore pointer"
        @click="() => restoreItem()"
      ></i>
      <modal-confirm
        :show="confirmRemove"
        :accept-callback="() => purgeItem()"
        :accept-text="$t('generic.deleteShort')"
        :show-extras="false"
        :decline-text="$t('generic.cancel')"
        @close="confirmRemove = false"
        >{{ $t('modals.confirmRemoveGeneric') }}</modal-confirm
      >
    </template>
  </info-row>
</template>

<script>
import TrashItemModel from '@common/Models/TrashItemModel';
import InfoRow from '@common/Components/InfoRow/InfoRow';
import { mapActions } from 'vuex';
import { TRASH_ACTIONS } from '@/store/modules/trash';
export default {
  components: {
    InfoRow
  },
  props: {
    item: {
      type: TrashItemModel,
      required: true
    }
  },
  data() {
    return {
      showPopup: false,
      purgeLoading: false,
      confirmRemove: false
    };
  },
  methods: {
    ...mapActions('trash', {
      $restoreItem: TRASH_ACTIONS.RESTORE_ITEM,
      $purgeItem: TRASH_ACTIONS.PURGE_ITEM,
      $getTrashItems: TRASH_ACTIONS.GET_TRASH_ITEMS
    }),
    purgeItem() {
      this.purgeLoading = true;
      this.$purgeItem(this.item)
        .catch(error => {
          this.$getTrashItems();
        })
        .finally(() => {
          this.purgeLoading = false;
          this.showPopup = false;
        });
    },
    restoreItem() {
      this.$restoreItem(this.item)
        .catch(error => {
          this.$getTrashItems();
        })
        .finally(() => {});
    }
  },
  computed: {
    title() {
      switch (this.item.ResourceType) {
        case 'Note':
          return this.item.ResourceDescription;
        case 'PromReport':
          return this.item.ResourceDescription;
        case 'SymptomReport': {
          const numPains = parseInt(this.item.ResourceDescription);
          return numPains + ' ' + this.$tc('plural.pain', numPains);
        }
        case 'StatusReport': {
          const numSymptoms = parseInt(this.item.ResourceDescription);
          return `${numSymptoms} ${this.$tc('plural.symptom', numSymptoms)}`;
        }
        default:
          return '';
      }
    }
  }
};
</script>
