import config from '@common/config';
import SessionModel from '@common/Models/SessionModel';
import TokenModel from '@common/Models/TokenModel';
import BaseService from './BaseService';

class AuthService extends BaseService {
  /**
   * Login user -- identity
   * @param {string} username
   * @param {string} password
   * @param {('pat'|'admin')} [role='pat']
   * @param {number} [group=0]
   * @param {number} [org=0]
   * @returns {Promise<TokenModel>} promise
   * @memberof AuthService
   */
  login(username, password, group = 0, org = 0, role = null) {
    username = username.replace('-', '');
    username = encodeURIComponent(username);
    password = encodeURIComponent(password);
    let data = `grant_type=password&username=${username}&password=${password}&group=${group}&org=${org}`;

    if (role) {
      data += `&role=${role}`;
    }

    return this.identity('post', 'token', data)
      .then(response => response.data)
      .then(token => {
        config.encryption_key = token.encryption_key.replace(/-/g, '');
        token = new TokenModel(token);
        return token;
      });
  }

  /**
   * Login user -- identity
   * @param {string} personnummer
   * @param {string} orderRef
   * @param {('pat'|'admin')} [role='pat']
   * @param {number} [group=0]
   * @param {number} [org=0]
   * @returns {Promise<TokenModel>} promise
   * @memberof AuthService
   */
  loginWithBankId(personnummer, orderRef, role = null, group = 0, org = 0) {
    let data = `grant_type=bankid&personnummer=${personnummer}&orderRef=${orderRef}&group=${group}&org=${org}`;

    if (role) {
      data += `&role=${role}`;
    }

    return this.identity('post', 'token', data)
      .then(response => response.data)
      .then(token => {
        config.encryption_key = token.encryption_key.replace(/-/g, '');
        return new TokenModel(token);
      });
  }

  /**
   * Refresh access token -- identity
   * @param {string} token Refresh token
   * @param {string} secret Refresh token secret
   * @param {('pat'|'admin')} [role='pat']
   * @param {number} [group=0]
   * @param {number} [org=0]
   * @returns {Promise<TokenModel>} promise
   * @memberof AuthService
   */
  refreshToken(token, secret, role = null, group = 0, org = 0) {
    let body = `grant_type=refresh_token&group=${group}&org=${org}&refresh_token=${token}&client_secret=${secret}`;

    if (role) {
      body += `&role=${role}`;
    }

    return this.identity('post', 'token', body)
      .then(response => response.data)
      .then(token => {
        config.encryption_key = token.encryption_key.replace(/-/g, '');
        token = new TokenModel(token);
        return token;
      });
  }

  /**
   * Get current user's active session -- identity
   * @returns {Promise<SessionModel[]>} promise
   * @memberof AuthService
   */
  getActiveSessions() {
    return this.identity('get', 'sessions')
      .then(response => response.data)
      .then(sessions => sessions.map(session => new SessionModel(session)));
  }

  /**
   * Log user out from all other sessions -- identity
   * @param {string} currentSessionId
   * @returns {Promise} promise
   * @memberof AuthService
   */
  logOutFromOtherDevices(currentSessionId) {
    return this.identity('delete', `sessions/${currentSessionId}/clear`).then(
      response => response.data
    );
  }

  /**
   * Reset password -- identity
   * @param {string} email
   * @returns {Promise} promise
   * @memberof AuthService
   */
  resetPassword(email) {
    return this.identity('post', 'users/password/reset', {
      Email: email
    }).then(response => response.data);
  }

  /**
   * Verify password reset -- identity
   * @param {string} code Verification code
   * @returns {Promise} promise
   * @memberof AuthService
   */
  verifyPasswordReset(code) {
    return this.identity('post', `users/password/reset/${code}/verify`).then(
      response => response.data
    );
  }

  /**
   * Change password -- identity
   * @param {string} OldPassword
   * @param {string} NewPassword
   * @returns {Promise} promise
   * @memberof AuthService
   */
  changePassword(OldPassword, NewPassword) {
    return this.identity('post', 'my/user/password', {
      OldPassword,
      NewPassword
    }).then(response => response.data);
  }

  /**
   * Change password from reset -- identity
   * @param {string} code Reset code
   * @param {string} NewPassword New password
   * @returns {Promise}
   * @memberof AuthService
   */
  changePasswordFromReset(code, NewPassword) {
    return this.identity('post', `users/password/reset/${code}`, {
      NewPassword
    }).then(response => response.data);
  }

  /**
   * Destroy session -- identity
   * @param {string} sessionId
   * @returns {Promise} promise
   * @memberof AuthService
   */
  destroySession(sessionId) {
    return this.identity('delete', `sessions/${sessionId}`).then(
      response => response
    );
  }
}

export default new AuthService();
