export const PROM_MUTATIONS = {
  ADD_PROM: 'ADD_PROM',
  SET_PROMS: 'SET_PROMS',
  ADD_IMPORTED_PROM: 'ADD_IMPORTED_PROM',
  SET_UNSAVED_PROMS: 'SET_UNSAVED_PROMS',
  REMOVE_SAVED_PROM: 'REMOVE_SAVED_PROM',
  REMOVE_UNSAVED_PROM: 'REMOVE_UNSAVED_PROM',
  REMOVE_UNMODIFIED_PROMS: 'REMOVE_UNMODIFIED_PROMS'
};

export const PROM_ACTIONS = {
  $PREINIT: '$PREINIT',
  GET_PROM_METAS: 'GET_PROM_METAS',
  GET_PROMS: 'GET_PROMS',
  GET_PROM: 'GET_PROM',
  GET_PROM_BY_LANGUAGE: 'GET_PROM_BY_LANGUAGE',
  GET_PROM_BY_PROMREQUEST: 'GET_PROM_BY_PROMREQUEST',
  LOAD_UNSAVED_PROMS: 'LOAD_UNSAVED_PROMS',
  REMOVE_SAVED_PROM: 'REMOVE_SAVED_PROM',
  REMOVE_UNSAVED_PROM: 'REMOVE_UNSAVED_PROM',
  NEW_PROM: 'NEW_PROM',
  IMPORT: 'IMPORT'
};

export const PROM_GETTERS = {
  FIND_PROM: 'FIND_PROM',
  ALL_PROMS: 'ALL_PROMS',
  ALL_SHOWN_PROMS: 'ALL_SHOWN_PROMS',
  ALL_UNSAVED_PROMS: 'ALL_UNSAVED_PROMS',
  FIND_PROM_BY_TYPE: 'FIND_PROM_BY_TYPE',
  PROM_STATUS_REPORT_TYPES: 'PROM_STATUS_REPORT_TYPES'
};
