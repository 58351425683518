export const PATIENT_ACTIVE_MUTATIONS = {
  SET_ACTIVE_PATIENT: 'SET_ACTIVE_PATIENT',
  PUSHER_CONNECTED_PATIENT: 'PUSHER_CONNECTED_PATIENT',
  PUSHER_DISCONNECTED_PATIENT: 'PUSHER_DISCONNECTED_PATIENT'
};

export const PATIENT_ACTIVE_ACTIONS = {
  $PREINIT: '$PREINIT',
  INIT_PUSHER_PATIENT: 'INIT_PUSHER_PATIENT',
  DISCONNECT_PUSHER: 'DISCONNECT_PUSHER'
};

export const PATIENT_ACTIVE_GETTERS = {
  PATIENT: 'PATIENT',
  FIND_SYMPTOM: 'FIND_SYMPTOM',
  FIND_SYMPTOM_REPORT: 'FIND_SYMPTOM_REPORT',
  FIND_SYMPTOMREPORTS_FOR_SYMPTOM: 'FIND_SYMPTOMREPORTS_FOR_SYMPTOM',
  FIND_PROMREPORTS_BY_PROMID: 'FIND_PROMREPORTS_BY_PROMID',
  FIND_PROMREPORT: 'FIND_PROMREPORT',
  PROM_GRAPH: 'PROM_GRAPH',
  STATUS_AREA_GRAPH: 'STATUS_AREA_GRAPH',
  SYMPTOM_GRAPH: 'SYMPTOM_GRAPH'
};
