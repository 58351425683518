<template>
  <checkbox-vue
    ref="checkbox"
    :key="answer.Id"
    :name="question.Id"
    :id="answer.Id"
    :value="answer.Id"
    :disabled="disabled"
    :faked="fakeAnswer"
    :checked="isAnswer"
    :type="type"
    :color="(isAnswer || fakeAnswer) && !disabled ? 'orange' : undefined"
    :data-cy="isAnswer ? 'answer--unsaved' : 'answer'"
    @value="setAnswer($event)"
  >
    <div class="separator" v-if="!answer.isEmpty"></div>
    <p v-html="toHtml(answer.Text)"></p>
  </checkbox-vue>
</template>

<script>
import CheckboxVue from '../../Form/Checkbox.vue';
import { toHtml } from '../../../Helpers/markdown';

export default {
  props: [
    'answer',
    'question',
    'disabled',
    'isAnswer',
    'isNotSavedAnswer',
    'type',
    'fakeAnswer'
  ],
  components: { CheckboxVue },
  methods: {
    toHtml,
    setAnswer($event) {
      const checked = $event.target.checked;
      if (!checked && this.isAnswer && !this.isFakeAnswer) {
        this.$emit('removeAnswer', this.answer);
      } else {
        this.$emit('setAnswer', this.answer, true);
      }
    }
  },
  computed: {
    answerType() {
      return this.question.QuestionType === 'chbox' ? 'checkbox' : 'radio';
    }
  }
};
</script>

<style lang="scss" scoped>
.checkradio {
  vertical-align: text-top;
  width: 90%;
  position: relative;
  display: inline-block;
  label {
    display: flex;
    width: 100%;
    padding-left: 0;
    flex-direction: row;
    align-content: top;
    div {
      height: 100%;
      display: inline-block;
      max-width: calc(100% - 40px);
    }

    &:after {
      display: none !important;
    }
    &:before {
      border: 0;
    }
  }
  input:checked + label:before {
    display: none;
  }
}
</style>
