export const ORGANIZATION_MUTATIONS = {
  ADD_ORGANIZATION: 'ADD_ORGANIZATION',
  SET_ORGANIZATIONS: 'SET_ORGANIZATIONS',
  REMOVE_ORGANIZATION: 'REMOVE_ORGANIZATION'
};

export const ORGANIZATION_ACTIONS = {
  CREATE_ORGANIZATION: 'CREATE_ORGANIZATION',
  DELETE_ORGANIZATION: 'DELETE_ORGANIZATION',
  GET_ORGANIZATION: 'GET_ORGANIZATION',
  GET_ORGANIZATIONS: 'GET_ORGANIZATIONS',
  UPDATE_ORGANIZATION: 'UPDATE_ORGANIZATION'
};

export const ORGANIZATION_GETTERS = {
  ORGANIZATIONS: 'ORGANIZATIONS',
  FIND_ORGANIZATION: 'FIND_ORGANIZATION'
};
