import PromGroupModel from '@common/Models/PromGroupModel';
import { randomString } from '@common/Helpers/strings';
import { maxLength, minLength, required } from '@vuelidate/validators';

// @ts-check
export class PromMetaModel {
  constructor({
    Id = randomString(),
    About = '',
    HideScore = false,
    IsVisible = false,
    OriginalName = '',
    Image = null,
    QuestionCount = null,
    PromGroups = []
  }) {
    this._modelName = 'PromMeta';
    this.Id = Id;
    this.About = About;
    this.HideScore = HideScore;
    this.IsVisible = IsVisible;
    this.OriginalName = OriginalName;
    this.Image = Image;
    this.QuestionCount = QuestionCount;
    this.PromGroups = PromGroups.map(pg => new PromGroupModel(pg));
  }
  __copy() {
    return JSON.parse(JSON.stringify(this));
  }

  static get validations() {
    return {
      About: {
        required,
        maxLength: maxLength(1024)
      },
      Id: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(32)
      },
      OriginalName: {
        required,
        maxLength: maxLength(128)
      },
      Image: {
        maxLength: maxLength(64)
      },
      HideScore: {}
    };
  }
}

export default PromMetaModel;
