<template>
  <div data-cy="statusreport" v-if="report">
    <div class="container status-report">
      <template v-if="symptomreportid">
        <h4 class="header">
          {{ $t('pageTitles.statusReport') }}:
          {{ $filters.DateTimeFormat(report.CreatedDate) }}
          <div class="page-sub-header">
            {{ $t('views.statusReport.updateReport') }}
          </div>
        </h4>
        <edit-lock
          :locked="report._locked"
          @change="locked => setLocked(locked)"
        ></edit-lock>
        <symptom-list-vue
          :edited-report="report"
          ref="symptoms"
          :disabled="report._locked"
          @closed-symptom="saveTempReport()"
          @save-temp="saveTempReport"
        ></symptom-list-vue>
      </template>
    </div>

    <float-content v-if="!report._locked">
      <s-button
        @click="cancelReport"
        id="ButtonCancel"
        element-name="statusreport-cancelbutton"
        :dark="true"
        :flat="true"
        class="underline"
        >{{ $t('generic.cancel') }}</s-button
      >
      <div v-tippy="inactiveText">
        <s-button
          @click="nextStep()"
          id="ButtonSave"
          element-name="statusreport-savebutton"
          :loading="saveLoading"
          :primary="true"
          :disabled="nextInactive()"
          >{{ $t('generic.save') }}</s-button
        >
      </div>
    </float-content>

    <modal-confirm
      :show="confirmCancel"
      :accept-callback="
        () => {
          confirmedCancel = true;
          cancelReport();
        }
      "
      :accept-text="$t('generic.leave')"
      :show-extras="false"
      :decline-text="$t('generic.stay')"
      @close="confirmCancel = false"
    >
      <template v-slot:title>
        <span v-text="$t('modals.confirmLeaveReportTitle')"></span>
      </template>
      {{ $t('modals.confirmLeaveReport') }}
    </modal-confirm>
  </div>
</template>

<script>
import EditLock from '@/views/components/EditLock.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { APP_GETTERS, APP_MUTATIONS } from '@/store/modules/app';
import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser';
import SymptomListVue from './components/SymptomList.vue';
import {
  SYMPTOMREPORT_ACTIONS,
  SYMPTOMREPORT_GETTERS,
  SYMPTOMREPORT_MUTATIONS
} from '../../store/modules/symptomreports';
import SymptomReportModel from '@common/Models/SymptomReport/SymptomReportModel';
import { isMobile } from '@common/Helpers/mobile';

export default {
  components: {
    EditLock,
    SymptomListVue
  },
  props: {
    symptomreportid: String
  },
  data() {
    return {
      report: null,
      saveLoading: false,
      confirmCancel: false,
      confirmedCancel: false,
      redirectOnCancel: null,
      useOldValues: true,
      subscription: null,
      unsavedReport: false,
      inactiveText: null
    };
  },
  beforeRouteLeave(to, from, next) {
    const keepUnsavedReport = [
      'symptomReport',
      'symptomReportEdit',
      'symptomReportDraw',
      'symptomReportGrade',
      'symptomReportNote',
      'symptomSelect'
    ].includes(to.name);

    if (
      !keepUnsavedReport &&
      this.report?.hasModifiedSymptoms &&
      this.confirmCancel === false &&
      this.confirmedCancel === false &&
      this.saveLoading === false
    ) {
      this.confirmCancel = true;
      this.redirectOnCancel = to;
      next(false);
    } else {
      if (keepUnsavedReport) {
        this.$setUnsavedReport(this.report);
      } else {
        this.$removeUnsavedReport();
      }
      next();
    }
  },
  watch: {
    symptomreportid(id) {
      this.report = null;
      this.getReport();
    }
  },
  created() {
    this.getReport();
  },
  mounted() {},
  methods: {
    ...mapActions('symptomreports', {
      $addOrUpdateSymptomReport: SYMPTOMREPORT_ACTIONS.ADD_OR_UPDATE_REPORT,
      $getMySymptomReport: SYMPTOMREPORT_ACTIONS.GET_MY_SYMPTOM_REPORT
    }),
    ...mapMutations('app', {
      $setPageSettings: APP_MUTATIONS.SET_PAGE_SETTING
    }),
    ...mapMutations('symptomreports', {
      $setUnsavedReport: SYMPTOMREPORT_MUTATIONS.SET_UNSAVED_REPORT,
      $removeUnsavedReport: SYMPTOMREPORT_MUTATIONS.REMOVE_UNSAVED_REPORT
    }),
    getReport() {
      if (this.$unsavedSymptomsReport) {
        this.loadTempReport();
      } else if (this.symptomreportid) {
        this.$getMySymptomReport(this.symptomreportid).then(report => {
          this.report = new SymptomReportModel(report.__copy());
          this.report._locked = true;
        });
      }
    },
    loadTempReport() {
      const modded = this.report ? this.report._modified : false;
      this.report = new SymptomReportModel(
        this.$unsavedSymptomsReport?.__copy()
      );
      this.report._modified = modded;
    },
    saveTempReport() {
      this.report._modified = true;
      this.$setUnsavedReport(this.report);
      this.loadTempReport();
    },
    setLocked(locked) {
      this.report._locked = locked;

      this.displayMenuOnMobile(locked);
    },
    displayMenuOnMobile(display) {
      if (isMobile()) {
        this.$setPageSettings({ option: 'show_menu', value: display });
      }
    },
    nextStep() {
      this.saveLoading = true;
      this.$addOrUpdateSymptomReport(this.report).then(() => {
        return this.$router
          .replace({ name: 'start' })
          .then(() => {
            if (this.isFirstSymptomReport) {
              this.$bus.$emit('show-thank-you-modal', true);
            } else {
              this.$bus.$emit('toast.display', {
                message: this.$root.$t('generic.saveSuccess'),
                status: 'success'
              });
            }
            this.$removeUnsavedReport();
          })
          .catch(err => {
            console.log(err);
            const codeMsg = this.$root.$t(`error-codes.${err.ErrorCode}`);
            this.$bus.$emit('toast.display', {
              message: codeMsg.match('error-codes.')
                ? this.$root.$t('generic.saveFailure')
                : codeMsg,
              status: 'failure'
            });
          })
          .finally(() => (this.saveLoading = false));
      });
    },
    cancelReport() {
      this.$removeUnsavedReport();

      if (this.redirectOnCancel) {
        this.$router.push(this.redirectOnCancel.path);
      } else {
        this.$router.replace({ name: 'start' }).catch(() => {});
      }
    },
    scaleTitle(item) {
      const itemstr = `constants.StatusReportTypes.${item.Type}`;
      return this.$t(itemstr);
    },
    nextInactive() {
      if (!this.report) {
        this.inactiveText = this.$t('symptom-report.inactiveText.noReport');
        return true;
      }
      if (this.useOldValues === false && !this.report?.hasModifiedSymptoms) {
        this.inactiveText = this.$t('symptom-report.inactiveText.noChanges');
        return true;
      }
      if (this.report.Symptoms.some(x => x.Intensity == null)) {
        this.inactiveText = this.$t(
          'symptom-report.inactiveText.missingIntensity'
        );
        return true;
      }
      this.inactiveText = null;
      return false;
    }
  },
  computed: {
    ...mapGetters('currentuser', {
      $appVersion: CURRENT_USER_GETTERS.APP_VERSION,
      $getActiveSymptomModel: CURRENT_USER_GETTERS.GET_ACTIVE_SYMPTOM_MODEL,
      $profile: CURRENT_USER_GETTERS.PROFILE
    }),
    ...mapGetters('app', {
      $statusTypes: APP_GETTERS.STATUS_REPORT_TYPES_SORTED_ARRAY
    }),
    ...mapGetters('symptomreports', {
      $findReport: SYMPTOMREPORT_GETTERS.FIND_REPORT,
      $unsavedSymptomsReport: SYMPTOMREPORT_GETTERS.UNSAVED_REPORT
    }),
    newSymptom() {
      return this.$route.query.newSymptom;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';

.status-report {
  margin-bottom: -50px;
}
h4 {
  margin-bottom: $padding;
}
.box {
  padding: 0;
  padding-top: 0.7em;
  border-radius: 0;

  &:not(:first-of-type) {
    border-top: 1px solid $symptoms-black;
  }

  &:first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-of-type {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}
</style>
<style lang="scss">
.status-report {
  .card__content {
    width: 100% !important;
  }
  .scale-container {
    margin-bottom: 0 !important;
  }
}
</style>
