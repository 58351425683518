import { randomString } from '@common/Helpers/strings';
import { utcToLocal } from '@common/Helpers/time';
import { email, required } from '@vuelidate/validators';

export class InviteModel {
  constructor({
    Email,
    Description,
    ExpireDate,
    Id,
    Used,
    UsedDate,
    Roles = [],
    AppVersionId
  }) {
    this.Email = Email;
    this.Description = Description;
    this.ExpireDate = utcToLocal(ExpireDate);
    this.Id = Id;
    this.Used = Used;
    this.UsedDate = UsedDate ? utcToLocal(UsedDate) : null;
    this.Roles = Roles;
    this.AppVersionId = AppVersionId;

    this._sortableKey = randomString();
  }

  static get validations() {
    return {
      AppVersionId: {
        required
      },
      Email: {
        email,
        required
      },
      Description: {
        required
      },
      Roles: {}
    };
  }
}

export default InviteModel;
