import axios from 'axios';
import { config } from './';
import {
  ConsentRequiredInterceptor,
  ErrorResponseInterceptor,
  RequestAuthInterceptor,
  RequestLoaderInterceptor,
  RequestRemovePrivateVariables,
  ResponseInterceptor,
  TFARequiredInterceptor,
  TokenRefreshInterceptor
} from './Interceptors/';

const LATENCY = 0;

export class Http {
  constructor(service = 'backend') {
    this.service = service;

    this._backend = axios.create({
      baseURL: `${config.apiUri}${config.apiRoot}`,
      timeout: 300000
    });

    this._identity = axios.create({
      baseURL: `${config.identityServerUri}`,
      timeout: 300000
    });

    this._setBackendInterceptors();
    this._setIdentityInterceptors();
  }

  _setBackendInterceptors() {
    this._backend.interceptors.request.use(RequestAuthInterceptor);
    this._backend.interceptors.request.use(RequestLoaderInterceptor);
    this._backend.interceptors.request.use(RequestRemovePrivateVariables);

    this._backend.interceptors.response.use(
      ResponseInterceptor,
      ErrorResponseInterceptor
    );

    this._backend.interceptors.response.use(undefined, TokenRefreshInterceptor);
    this._backend.interceptors.response.use(undefined, TFARequiredInterceptor);
    this._backend.interceptors.response.use(
      undefined,
      ConsentRequiredInterceptor
    );
  }

  _setIdentityInterceptors() {
    this._identity.interceptors.request.use(RequestAuthInterceptor);
    this._identity.interceptors.response.use(
      undefined,
      TokenRefreshInterceptor
    );
    this._identity.interceptors.response.use(undefined, TFARequiredInterceptor);
  }

  _getUrl(payload) {
    return payload.url || payload.path;
  }

  get(payload) {
    return this[`_${this.service}`]({
      method: 'get',
      url: this._getUrl(payload),
      ...payload.options
    }).then(x => new Promise(resolve => setTimeout(() => resolve(x), LATENCY)));
  }

  post(payload) {
    return this[`_${this.service}`]({
      method: 'post',
      url: this._getUrl(payload),
      data: payload.data,
      ...payload.options
    });
  }

  patch(payload) {
    return this[`_${this.service}`]({
      url: this._getUrl(payload),
      headers: payload.headers,
      data: payload.data,
      method: 'patch',
      ...payload.options
    });
  }

  put(payload) {
    return this[`_${this.service}`]({
      url: this._getUrl(payload),
      headers: payload.headers,
      data: payload.data,
      method: 'put',
      ...payload.options
    });
  }

  delete(payload) {
    return this[`_${this.service}`]({
      url: this._getUrl(payload),
      data: payload.data,
      method: 'delete',
      ...payload.options
    });
  }
}
