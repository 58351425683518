import { APP_MUTATIONS } from '@/store/modules/app';
import {
  AUTH_ACTIONS,
  AUTH_GETTERS,
  AUTH_MUTATIONS
} from '@/store/modules/auth';
import { CURRENT_USER_MUTATIONS } from '@/store/modules/currentuser';
import { SYMPTOMREPORT_MUTATIONS } from '@/store/modules/symptomreports';
import { Bus } from '@common';

const refreshQueue = [];

export const busPlugin = store => {
  let initialStateCopy;

  setTimeout(() => {
    initialStateCopy = JSON.parse(JSON.stringify(store.state));
  }, 0);

  Bus.$on('reset-state', () => {
    store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)));
  });

  Bus.$on('must-accept-contract', () => {
    store.commit(`auth/${AUTH_MUTATIONS.CONSENTED_TO_REQUIRED_CONTRACTS}`, 0);
  });

  Bus.$on('connection', connected => {
    store.commit(`app/${APP_MUTATIONS.SET_CONNECTION}`, connected);
  });

  Bus.$on('show-thank-you-modal', show => {
    store.commit(
      `symptomreports/${SYMPTOMREPORT_MUTATIONS.SET_SHOW_THANK_YOU_MODAL}`,
      show
    );
  });

  Bus.$on('tfa-required', value => {
    store.commit(`currentuser/${CURRENT_USER_MUTATIONS.TOGGLE_2FA}`, value);
  });

  Bus.$on('refresh-token', request => {
    if (request) {
      refreshQueue.push(request);
    }
    if (store.state.auth.refreshingToken === true) {
      return;
    }
    store.dispatch(`auth/${AUTH_ACTIONS.REQUEST_NEW_ACCESS_TOKEN}`, {
      force: true
    });
  });

  store.subscribe((mutation, state) => {
    if (mutation.type === `auth/${AUTH_MUTATIONS.ACCESS_TOKEN_SUCCESS}`) {
      while (refreshQueue.length > 0) {
        refreshQueue[0](store.getters[`auth/${AUTH_GETTERS.TOKEN}`]);
        refreshQueue.shift();
      }
    }

    if (mutation.type === `auth/${AUTH_MUTATIONS.ACCESS_TOKEN_FAILURE}`) {
      store.dispatch(`auth/${AUTH_ACTIONS.LOGOUT}`, true, { root: true });
    }
  });
};
