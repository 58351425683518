// @ts-check
import { APP_VERSION_OPTIONS } from '@common/constants';
import { maxLength, required } from '@vuelidate/validators';

export class AppVersionModel {
  constructor({
    Id = null,
    Name,
    Description,
    Default,
    Version = 0,
    StatusReportVersion,
    StatusChartVersion,
    PromReportVersion,
    IntensityScaleVersion
  }) {
    this._modelName = 'AppVersion';
    this.Id = Id;
    this.Name = Name;
    this.Description = Description;
    this.Default = Default;
    this.version = Version;
    this.StatusReportVersion =
      StatusReportVersion ||
      APP_VERSION_OPTIONS.StatusReportVersions.NUMBERS_STYLE;
    this.StatusChartVersion =
      StatusChartVersion || APP_VERSION_OPTIONS.StatusChartVersions.DOTS_STYLE;
    this.PromReportVersion =
      PromReportVersion || APP_VERSION_OPTIONS.PromReportVersions.CAT_STYLE;
    this.IntensityScaleVersion =
      IntensityScaleVersion ||
      APP_VERSION_OPTIONS.IntensityScaleVersions.NORMAL_STYLE;
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }

  static get validations() {
    return {
      Description: {},
      IntensityScaleVersion: { required },
      Name: {
        maxLength: maxLength(16),
        required
      },
      PromReportVersion: { required },
      StatusChartVersion: { required },
      StatusReportVersion: { required }
    };
  }
}

export default AppVersionModel;
