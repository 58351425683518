<template>
  <div
    class="chip"
    :class="{ pointer: hasClick }"
    :style="{ color: textColor, backgroundColor }"
    v-bind="$attrs"
  >
    <slot></slot>
    <i
      v-if="removeFunction"
      @click="() => removeFunction()"
      class="mdi mdi-close-circle"
    ></i>
  </div>
</template>

<script>
export default {
  props: {
    textColor: {
      type: String,
      default: '#000'
    },
    backgroundColor: {
      type: String,
      default: '#fff'
    },
    removeFunction: Function
  },
  computed: {
    hasClick() {
      return !!this.$attrs.onClick;
    }
  }
};
</script>

<style lang="scss" scoped>
.chip {
  padding: 2px 10px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 0;
  background-color: #fff;
  color: #000;
  display: inline-flex;
  align-items: center;
  transition: opacity 0.2s ease-in-out;
}
.mdi {
  font-size: 16px;
  margin-left: 5px;
  color: #122033;
  opacity: 0.3;
  cursor: pointer;
}
</style>

<style lang="scss">
.chips {
  border-bottom: 0 !important;
  margin-bottom: 0 !important;
}
</style>
