<template>
  <div>
    <div v-if="!userSelectingSide">
      <div class="text pd20">
        {{ $t('generic.selectTheFigure') }}
      </div>

      <div class="container">
        <div class="manikin-selector">
          <manikin-selector model="male" @click="saveModel('male')" />
        </div>
        <div class="manikin-selector">
          <manikin-selector model="female" @click="saveModel('female')" />
        </div>
      </div>
    </div>

    <div v-else>
      <div class="parent-container">
        <div class="pd20">
          {{ $t('generic.ifYouHavePainMarkWhichSide') }}
        </div>

        <manikin-selector
          @left-hand-click="SaveChosenModelSide('left')"
          @right-hand-click="SaveChosenModelSide('right')"
          :model="profile.Model"
          :show-hand-buttons="true"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import {
  CURRENT_USER_ACTIONS,
  CURRENT_USER_GETTERS
} from '@/store/modules/currentuser';
import ManikinSelector from './components/ManikinSelector.vue';
export default {
  components: {
    ManikinSelector
  },
  data() {
    return {
      profile: null,
      loading: false,
      userSelectingSide: false
    };
  },
  mounted() {
    if (this.$profile) {
      this.profile = this.$profile.__copy();
    }
  },
  methods: {
    ...mapActions('currentuser', {
      $changeModel: CURRENT_USER_ACTIONS.CHANGE_SYMPTOM_MODEL,
      $getProfileView: CURRENT_USER_ACTIONS.GET_PROFILE_VIEW,
      $updateProfile: CURRENT_USER_ACTIONS.UPDATE_PROFILE
    }),
    saveModel(modelGender) {
      this.profile.model = modelGender;
      return this.$updateProfile(this.profile)
        .then(profile => {
          this.$changeModel({ newModel: modelGender });
          this.$bus.$emit('toast.display', {
            message: this.$root.$t('generic.saveSuccess'),
            status: 'success',
            duration: 4000
          });
          this.profile = this.$profileCopy;
          this.userSelectingSide = true;
        })
        .catch(error => {
          if (error.ErrorCode === 4003) {
            this.$getProfileView().then(() => {
              this.$bus.$emit('toast.display', {
                message: `${this.$root.$t('errors.profileConflict')}`,
                status: 'warning',
                duration: 4000
              });
            });
          } else {
            this.$bus.$emit('toast.display', {
              message: `${this.$root.$t('generic.saveFailure')} - ${
                error.Message
              }`,
              status: 'failure'
            });
          }
        });
    },
    SaveChosenModelSide(PatientModelChosenSide) {
      this.profile.PatientModelChosenSide = PatientModelChosenSide;
      return this.$updateProfile(this.profile)
        .then(profile => {
          this.$bus.$emit('toast.display', {
            message: this.$root.$t('generic.saveSuccess'),
            status: 'success',
            duration: 4000
          });
          this.profile = this.$profileCopy;
          this.userSelectingSide = false;

          if (this.redirectPath) {
            this.$router.replace({ path: this.redirectPath });
          } else {
            this.$router.replace({ name: 'start' });
          }
        })
        .catch(error => {
          if (error.ErrorCode === 4003) {
            this.$getProfileView().then(() => {
              this.$bus.$emit('toast.display', {
                message: `${this.$root.$t('errors.profileConflict')}`,
                status: 'warning',
                duration: 4000
              });
            });
          } else {
            this.$bus.$emit('toast.display', {
              message: `${this.$root.$t('generic.saveFailure')} - ${
                error.Message
              }`,
              status: 'failure'
            });
          }
        });
    }
  },
  watch: {
    $profile() {
      this.profile = this.$profile.__copy();
    }
  },
  computed: {
    ...mapGetters('currentuser', {
      $profile: CURRENT_USER_GETTERS.PROFILE,
      $profileCopy: CURRENT_USER_GETTERS.PROFILE_COPY
    }),
    redirectPath() {
      return this.$route.query?.redirect;
    }
  }
};
</script>

<style lang="scss" scoped>
.manikin-selector {
  cursor: pointer;
  margin: 1em;
}

.text {
  display: flex;
  font-size: 24px;
  justify-content: center;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
}
.parent-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

@media (max-width: 768px) {
  .container {
    padding: 20px;
  }
}
</style>
