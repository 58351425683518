import { randomString } from '@common/Helpers/strings';
import { required } from '@vuelidate/validators';

// @ts-check
export class AnswerModel {
  constructor({ Id, Text, Index, Version, ExportValue, Level }) {
    this._modelName = 'Answer';
    this.Id = Id;
    this.Text = Text;
    this.Index = Index;
    this.Version = Version || 0;
    this.ExportValue = ExportValue;
    this.Level = Level || 1;

    this._sortableKey = randomString();
  }

  get isEmpty() {
    return this.Text.replace(/\s/g, '').length === 0;
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }

  static get validations() {
    return {
      Id: {
        required
      },
      ExportValue: {
        required
      },
      Text: {
        required
      },
      Index: {
        required
      }
    };
  }
}

export default AnswerModel;
