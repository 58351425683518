<template>
  <div>
    <s-text-input
      :label="toHtml(answer.Text)"
      type="text"
      :model-value="answertext"
      :disabled="disabled"
      @blur="setAnswer($event)"
    />
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import AnswerModel from '@common/Models/AnswerModel';
import { toHtml } from '@common/Helpers/markdown';
import FormSetup from '@common/Mixins/FormSetup';
export default {
  props: [
    'answer',
    'answerInput',
    'question',
    'disabled',
    'isAnswer',
    'isNotSavedAnswer',
    'type',
    'index'
  ],
  mixins: [FormSetup],
  watch: {
    answertext() {
      this.$_FormSetup_setupForm();
    }
  },
  methods: {
    toHtml,
    setAnswer($event) {
      const value = $event.target.value;
      if (value.length) {
        const editedAnswer = new AnswerModel(this.answer.__copy());
        editedAnswer.Value = value;
        this.$emit('setAnswer', editedAnswer);
      } else {
        this.$emit('removeAnswer', this.answer);
      }
    }
  },
  computed: {
    answertext() {
      return this.answerInput ? this.answerInput.Value : '';
    }
  }
};
</script>

<style lang="css"></style>
