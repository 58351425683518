<template>
  <div class="audit-logs-summary" v-if="auditlog">
    <h4 class="container header ml20 hidden-mobile">
      {{ $tc('plural.auditLog', 1) }}
    </h4>
    <div class="container">
      <p>
        <span>{{ $t('generic.pointInTime') }}</span
        >: {{ when }}
      </p>
      <p>
        <span>{{ $t('generic.event') }}</span
        >: {{ who }} {{ action }} {{ $t('generic.one') }}
        {{ $t(`generic.${what}`).toLowerCase() }}
      </p>
    </div>

    <float-content>
      <button-back></button-back>
    </float-content>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import {
  AUDITLOGS_ACTIONS,
  AUDITLOGS_GETTERS
} from '@/store/modules/auditlogs';
import moment from 'moment';
import { DateTimeString } from '@common/Filters/Date';

export default {
  components: {},
  props: {
    auditlogid: String
  },
  mounted() {
    if (!this.currentAuditLog || !this.currentAuditLog.DataAfter) {
      this.$getAuditLog(this.auditlogid);
    }
  },
  watch: {
    currentAuditLog(value) {}
  },
  methods: {
    ...mapActions('auditlogs', {
      $getMyAuditLogs: AUDITLOGS_ACTIONS.GET_MY_AUDITLOGS,
      $getAuditLog: AUDITLOGS_ACTIONS.GET_AUDITLOG
    })
  },
  computed: {
    ...mapGetters('auditlogs', {
      $auditlogs: AUDITLOGS_GETTERS.AUDITLOGS,
      $auditlog: AUDITLOGS_GETTERS.AUDITLOG
    }),
    log() {
      return this.auditlog;
    },
    when() {
      return moment(this.log.TimeStamp).format(DateTimeString);
    },
    who() {
      if (this.log.ActorRole !== 'pat') {
        return `${this.log.ActorName} (${this.log.ActorRole})`;
      }

      let you = this.$t('generic.you').split('');
      you[0] = you[0].toUpperCase();
      you = you.join('');

      return you;
    },
    action() {
      if (!this.log.ActionType) {
        return;
      }
      return this.$t('events.' + this.log.ActionType.toLowerCase());
    },
    what() {
      let action = this.log.ResourceType;
      if (action === 'Patient') {
        action = 'profile';
      }
      return action;
    },
    auditlog() {
      return this.$auditlog(this.auditlogid);
    }
  }
};
</script>

<style lang="css"></style>
