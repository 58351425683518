// @ts-check

export class NewContractConsentModel {
  constructor({ ContractLanguageVersionId, Reference, Consented }) {
    this.ContractLanguageVersionId = ContractLanguageVersionId;
    this.Reference = Reference;
    this.Consented = Consented;
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }

  static get validations() {
    return {};
  }
}

export default NewContractConsentModel;
